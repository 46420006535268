//Marker for showing location on the google map
import React from 'react';
import PropTypes from 'prop-types';
import MapMarketIcon from '../../images/map-marker-icon.svg';
import ReactSVG from "react-svg";


const Marker = props => (
    <div className="marker" {...props.onClick ? { onClick: props.onClick } : {}}>
        <ReactSVG src={MapMarketIcon} alt=""/>
    </div>
);

Marker.defaultProps = {
    onClick: null,
};

Marker.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
};

export default Marker;