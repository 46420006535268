//Button for publish element and experience.
import React from 'react'
import {withTranslation} from "react-i18next";

class PublishButton extends React.Component{

    render() {
        let {active,classes,disable,handleSubmit,tooltipText,t} = this.props;
        return (
            <button type={handleSubmit ? "button" : "submit"} disabled={disable} className={`btn btn-primary ${classes}`}
                    onClick={handleSubmit} data-tooltip={disable ? tooltipText : false}>
                <span>{active ? t('AR Unpublish') : t('AR Publish')}</span>
            </button>
        )
    }
}

export default withTranslation('translations')(PublishButton);
