//Custom scrollbar component
import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ErrorBoundaryContainer from "./ErrorBoundaryContainer";


class CustomScrollBar extends React.Component {

    render() {
        let {className,id} = this.props;
        return (
            <ErrorBoundaryContainer>
                <OverlayScrollbarsComponent
                    className={`custom-scrollbar ${className}`}
                    id={`custom-scrollbar ${id}`}
                    {...this.props}
                >
                    {this.props.children}
                </OverlayScrollbarsComponent>
            </ErrorBoundaryContainer>
        )
    }
}


export default CustomScrollBar;
