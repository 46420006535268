import React from 'react';
import {withRouter} from "react-router-dom";
import InputWithButtons from "../../../../components/InputWithButtons";

function Fullname (props) {
    let {t,formik} = props;
    let {errors,values,handleBlur,handleChange,touched, resetForm} = formik;
    let first_name_invalid_class = errors.first_name && touched.first_name ? 'invalid' : '',
        last_name_invalid_class = errors.last_name && touched.last_name ? 'invalid' : '';


    const onBlurSave = (error,value,name) => {
        if(!error){
            // let user_updated = {[name]:value};
            props.handleFieldChange(name,value)
        }
    }
    return (
        <div className={`input-row ${props.classes}`}>
            <InputWithButtons handleSave={() => onBlurSave(errors.first_name,values.first_name,"first_name")}
                              handleDiscard={() => resetForm()}>
                <input
                    disabled={false}
                    size={values.first_name && values.first_name.length > 0 ? values.first_name.length +1 : t("First Name").length +1}
                    id="first_name"
                    name="first_name"
                    placeholder={t("First Name")}
                    type="text"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={(e) => {handleBlur(e)}}
                    className={`input-borderless profile__user-name ${first_name_invalid_class}`}/>
                {errors.first_name && touched.first_name && (
                    <div className="input-feedback">{errors.first_name}</div>
                )}
            </InputWithButtons>
            <InputWithButtons handleSave={() => onBlurSave(errors.last_name,values.last_name,"last_name")}
                              handleDiscard={() => resetForm()}>
                <input
                    disabled={false}
                    size={values.last_name.length > 0 ? values.last_name.length +1 : t("Last Name").length +1}
                    id="last_name"
                    name="last_name"
                    placeholder={t("Last Name")}
                    type="text"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={(e) => {handleBlur(e)}}
                    className={`input-borderless profile__user-name ${last_name_invalid_class}`}/>
                {errors.last_name && touched.last_name && (
                    <div className="input-feedback">{errors.last_name}</div>
                )}
            </InputWithButtons>
        </div>
    )
}

export default withRouter(Fullname)
