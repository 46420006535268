import React from 'react';
import {Trans} from "react-i18next";
import "../../styles/components/table.css";
import Quiz from './Quiz';

function QuizInfo(props) {
  let { dashboard_info } = props;
  if (!dashboard_info) return null;
  let quizz = dashboard_info.quizz;
  let quizz_count = quizz.length;

  return (
    <div className="profile-section profile-section__accent overflow-hidden">
      <div className="row">
        <div className="col-md-2 col-sm-12 profile-col">
          <div className="profile-section__title-bold">
            <Trans>Total Quiz's</Trans>{" "}
          </div>
          <div className="profile-section__body">
            <div
              className="profile-section__small-text"
              style={{ fontSize: "25px" }}
            >
              {quizz_count}
            </div>
          </div>
        </div>
        <div className="col-md-10 col-sm-12 profile-col">
          <div className="profile-section__title-bold">
            <Trans>Element Wise Quiz</Trans>{" "}
          </div>
          <div className="profile-section__body">
            <div className="profile-section__small-text">
              <div style={{ overflowX: "auto" }}>
                <table style={{ height: "200px" }}>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Element</th>
                      <th>Question</th>
                      <th>Points</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {quizz.map((object, i) => (
                    <Quiz
                      number={i}
                      quizdata={object}
                    />
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizInfo;