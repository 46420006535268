import React, {useEffect} from "react";
import JobCardInner from "./JobCard/JobCardInner";
import BidDetails from "../bids/BidDetails";
import Comments from "../comments/Comments";
import {Trans} from 'react-i18next';
import {scrollToTop} from "../../../common";

function JobDetailsSection(props) {
    useEffect(() => {
        props.bid_item && props.getBidComments({bid_id: props.bid_item.id})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onCreateComment = (message) => {
        return new Promise((resolve, reject) => {
            props.createBidComment(props.bid_item.id, message)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        })
    }
    return(
        <div className="job-details-section">
            <div className="job-card job-card_negative-margin">
                <JobCardInner item_info={props.job_item} useAsHeader/>
            </div>
            {props.bid_item && <div className="separator"/>}
            {<div className="bids-section__inner-offset">
                <ul className="nav nav-pills btn-group btn-group-toggle mb-4" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link btn btn-group-toggle__item active" id="bid-tab"
                           data-toggle="tab" href="#bid"
                           role="tab" aria-controls="bid" aria-selected="true"><Trans>Bid details</Trans></a>
                    </li>
                    {props.bid_comments && props.bid_comments.length > 0 && <li className="nav-item" role="presentation">
                        <a className="nav-link btn btn-group-toggle__item" id="comments-tab"
                           data-toggle="tab" href="#comments"
                           onClick={() => setTimeout(() => {
                               scrollToTop('comments-box')
                           },1000)}
                           role="tab" aria-controls="comments" aria-selected="false"><Trans>Comments</Trans></a>
                    </li>}
                </ul>
            </div>}
            <div className="tab-content d-flex flex-column flex-grow-1">
                <div className="tab-pane active flex-grow-1" id="bid" role="tabpanel"
                     aria-labelledby="bid-tab">
                    <BidDetails data={props.bid_item} item_info={props.job_item} {...props}/>
                </div>
                <div className="tab-pane" id="comments" role="tabpanel"
                     aria-labelledby="comments-tab">
                    <Comments comments={props.bid_comments}
                              onCreateComment={onCreateComment}
                              {...props}/>
                </div>
            </div>
        </div>
    )
}

export default JobDetailsSection;
