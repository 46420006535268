//AR experiences list screen with delete and create experience buttons
import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ElementCard from '../components/element/ElementCard.js'
import {
    getElements,
    setSelectedDeleteElements,
    setSelectedPrintElements,
    startLearningOfML, confirmPushIdetifiers, clearElementsList, createElement
} from "../store/elements";
import {
  setLoading,
  getMainContentLanguage,
  setMainContentLanguage,
  toggleUpgradePlanModal,
} from "../store/user";
import _ from "lodash";
import {getValidationRules, setLoginSchema} from "../store/validations";
import {withTranslation} from "react-i18next";
import TopPanel from "../components/top-panel/TopPanel";
import {getFilteredElements} from "../common";
import InfiniteScrollContainer from "../components/InfiniteScrollContainer";
import CustomScrollBar from "../components/CustomScrollBar";
import ErrorBoundaryContainer from "../components/ErrorBoundaryContainer";



 class AugmentedExperiencesList extends React.Component {
     constructor(props){
         super(props);
         this.state = {
             show_without_resources:false,
             isPushDataDisabled : false,
             searchTerm: '',
             selectedDeleteElements: [],
             selectedElements: [],
             printMode: false,
             deleteMode: false,
             page: 0,
             elements: [],
             hasMoreItems: true,
             filteredElements: [],
             total_count: 0,
             current_count: 0,
         }

     }

     componentDidMount() {
         if(!this.props.main_content_language) this.props.setMainContentLanguage('en');
         this.props.setLoading(true)
         this.props.getValidationRules('dashboard').then((res) => {
             setLoginSchema(res.data,this.state.isEmail,this.props.t)
         })
     }

     componentWillUnmount() {
         clearInterval(this.interval);
         this.props.clearElementsList()
     }

     static getDerivedStateFromProps(nextProps, prevState){
         let update = {};
         update.deleteMode = nextProps.delete_elements_mode;
         update.printMode = nextProps.print_elements_mode;
         update.filteredElements = getFilteredElements(nextProps,prevState,prevState.elements)
         update.current_count = update.filteredElements.length
         return Object.keys(update).length ? update : null;
     }

     handleInputChange = (event) => {
         const target = event.target;
         const value = target.type === 'checkbox' ? target.checked : target.value;
         const name = target.name;

         this.setState({
             [name]: value
         });
     }

     callbackCheck = (id,checked) => {
         let {printMode,selectedElements,selectedDeleteElements,deleteMode} = this.state;
         let items = printMode ? selectedElements : selectedDeleteElements;
         var match = _.find(items, {id});
         if(checked && !match) {
             items.push({id})
             this.setState({selectedElements: items})
         }
         if(!checked && match){
             let index = _.indexOf(items, match);
             items.splice(index, 1);
             this.setState({selectedElements: items})
         }
         if(deleteMode){
             this.props.setSelectedDeleteElements(_.map(selectedDeleteElements,'id'))
         }else if(printMode){
             this.props.setSelectedPrintElements(_.map(selectedElements,'id'))
         }
     };

     searchUpdated = (term) => {
         this.setState({searchTerm: term})
     }

     resetSelectedElements = () => {
         this.setState({selectedElements: [], selectedDeleteElements:[],deleteMode:false})
     }

     hadleStartLearning = () => {
         this.props.startLearningOfML()
     }

     handlePushIdentifier = () => {
         this.setState({isPushDataDisabled: true})
         this.props.confirmPushIdetifiers()
     }

     handleResetFilters = () => {
         this.setState({
             show_without_resources:false
         });
     }

     loadMoreElements = () => {
         let {page,elements} = this.state;
         let newPage = page +1;
         this.setState({page: newPage})
         this.props.getElements(undefined, newPage)
             .then((res) => {
                 this.props.setLoading(false)
                 let updatedElementsList = [...elements, ...res.data];
                 if (res.meta) {
                     this.setState({current_count: updatedElementsList.length, total_count: res.meta.total})
                     if (res.meta.current_page < res.meta.last_page) {
                         this.setState({hasMoreItems: true})
                     } else this.setState({hasMoreItems: false})
                 } else this.setState({hasMoreItems: false})
                 this.setState({
                     elements: [...elements, ...res.data],
                     filteredElements: getFilteredElements(this.props, this.state, updatedElementsList)
                 })
             })
             .catch(() => {
                 this.setState({hasMoreItems: false})
             })
     }

     deleteSelectedElementsFromState = (selected_delete_elements) => {
         let {total_count} = this.state;
         let toDelete = new Set(selected_delete_elements)
         let updatedElementsArray = this.state.elements.filter(element => !toDelete.has(element.id))
         this.setState({
             elements: updatedElementsArray,
             filteredElements: getFilteredElements(this.props,this.state,updatedElementsArray),
             current_count: updatedElementsArray.length,
             total_count: total_count - selected_delete_elements.length,
         })
     }

     render() {
         let {delete_elements_mode,t,sidebar_visible} = this.props,
         {show_without_resources,printMode,searchTerm,filteredElements,hasMoreItems,current_count,total_count} = this.state;
         if(show_without_resources) filteredElements.filter(element_item => element_item.assets_count === 0);
         let overflowVerticalOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};

         return(
             <ErrorBoundaryContainer>
                 <div>
                     <TopPanel {...this.props} t={t} searchUpdated={this.searchUpdated} resetSelectedElementsParentState={this.resetSelectedElements}
                               show_without_resources={show_without_resources} handleInputChange={this.handleInputChange}
                               handleResetFilters={this.handleResetFilters} searchTerm={searchTerm} sidebar_visible={sidebar_visible}
                               deleteSelectedElementsFromState={this.deleteSelectedElementsFromState} current_count={current_count}
                               total_count={total_count} title={'AR Elements'}/>
                     <CustomScrollBar className="split-screen__top element-list" options={overflowVerticalOptions}>
                         <InfiniteScrollContainer loadMore={this.loadMoreElements} hasMoreItems={hasMoreItems} classes="row" hasFilter={show_without_resources}>
                             {filteredElements.map((element_item, i) => {
                                 return <ElementCard element_item={element_item} key={i} printMode={printMode} main_content_language={this.props.main_content_language}
                                                     deleteMode={delete_elements_mode} callbackCheck={this.callbackCheck}/>
                             })}
                         </InfiniteScrollContainer>
                     </CustomScrollBar>
                 </div>
             </ErrorBoundaryContainer>
         )
     }
 }

const mapStateToProps = state => ({
    elements: state.elements.elements,
    selected_delete_elements: state.elements.selected_delete_elements,
    delete_elements_mode: state.elements.delete_elements_mode,
    print_elements_mode: state.elements.print_elements_mode,
    status_ml_learning: state.elements.status_ml_learning,
    status_ml_push: state.elements.status_ml_push,
    loading: state.user.loading,
    sidebar_visible: state.user.sidebar_visible,
    validation_rules: state.validations,
    main_content_language: state.user.main_content_language
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getElements,
      setSelectedDeleteElements,
      setSelectedPrintElements,
      setLoading,
      startLearningOfML,
      confirmPushIdetifiers,
      getValidationRules,
      getMainContentLanguage,
      setMainContentLanguage,
      clearElementsList,
      createElement,
      toggleUpgradePlanModal
    },
    dispatch
  );

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(AugmentedExperiencesList));

