//Button for multiple delete elements
//After first click on the button delete_elements_mode will active after second click will show confirm modal
//By click on close icon on the button delete_elements_mode will inactive

import React from 'react'
import {setDeleteElementsMode,deleteSelectedElements,setSelectedDeleteElements,setDeleteElementModalStatus} from "../../store/elements";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import Modal from "../modals/ConfirmElementDeleteModal";
import {withTranslation} from "react-i18next";
import DeleteIcon from "../../images/delete-icon.svg";
import SmallRoundButton from "../buttons/SmallRoundButton";
import ReactTooltip from "react-tooltip";

class DeleteElementsButton extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentWillUnmount() {
        this.resetSelectedElements()
        document.removeEventListener("keydown", this.escFunction, false);
    }

    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if(event.keyCode === 27) {
            this.resetSelectedElements(event)
        }
    }

    handleDelete = (e) => {
        let { selected_delete_elements,delete_elements_mode} = this.props;
        if(!delete_elements_mode){
            this.props.setSelectedDeleteElements([])
            this.props.setDeleteElementsMode(true)
            this.props.resetSelectedElementsParentState()
        }else {
            selected_delete_elements.length > 0 && this.props.setDeleteElementModalStatus(true)
        }
    };

    deleteElements = () => {
        let { selected_delete_elements} = this.props;
        this.props.deleteSelectedElements(selected_delete_elements).then((res) => {
            this.props.deleteSelectedElementsFromState(selected_delete_elements)
            this.resetSelectedElements()
        })
    }

    resetSelectedElements = (e) => {
        if(e) e.stopPropagation()
        this.props.setDeleteElementsMode(false);
        this.props.setSelectedDeleteElements([])
        this.props.resetSelectedElementsParentState()
    }

    render() {
        let { delete_elements_mode,selected_delete_elements,t} = this.props;
        let bigButtonClass = delete_elements_mode ? 'btn-action_big' : '';
        return (
            <SmallRoundButton activeStatus={delete_elements_mode} activeClass={'btn-small-round_delete_mode'} icon={DeleteIcon} classes={`mr-2 ${bigButtonClass}`}
                              onClick={this.handleDelete} data_tip={(t(`Delete Elements`))}>
                {delete_elements_mode && <span className={'btn-remove'} onClick={this.resetSelectedElements} />}
                <Modal handleDelete={this.deleteElements} isMultipleDelete={selected_delete_elements.length > 1}/>
                <ReactTooltip />
            </SmallRoundButton>
        );
    }
}

const mapStateToProps = state => ({
    elements: state.elements.elements,
    selected_delete_elements: state.elements.selected_delete_elements,
    delete_elements_mode: state.elements.delete_elements_mode,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setDeleteElementsMode,
    deleteSelectedElements,
    setSelectedDeleteElements,
    setDeleteElementModalStatus
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(DeleteElementsButton));

