import React, {Fragment} from 'react';
import CreateExperienceButton from "../experience/CreateExperienceButton";
import DeleteExperiencesButton from "../experience/DeleteExperiencesButton";

function ARButtons(props) {
    return(
        <Fragment>
            <DeleteExperiencesButton {...props}/>
            <CreateExperienceButton {...props}/>

        </Fragment>
    )
}

export default ARButtons;
