import React, { useState} from "react";
import ReactSVG from "react-svg";
import EditIcon from "../../images/edit-icon.svg";
import {Field} from "formik";
import FileUploader from "../../components/FileUploader";
import Loading from "../../components/Loading";

function BusinessPoster(props) {
    const {
        values,
        touched,
        errors,
        handleBlur,
        setFieldValue
    } = props.formik;

    const [uploadStatus,setUploadStatus] = useState(false);
    const [pending,setPending] = useState(false);
    const uploadImageStatus = () => {
        setUploadStatus(true)
    }

    const uploadImage = (values) => {
        setPending(true)
        props.uploadBusinessPoster(values.poster).then(() => {
            setUploadStatus(false)
            setPending(false)
        })
    }

    let {poster} = values;
    return(
        <div className="profile__business-poster">
            {pending && <Loading small activeSmallLoading={pending} isTransparent/>}
            <>
                {!uploadStatus && poster && <img src={poster.url} className="profile__business-poster-image" alt=""/>}
                {!uploadStatus && poster && <button type="button" className="btn btn-update-image" onClick={uploadImageStatus}>
                    <ReactSVG src={EditIcon}/>
                </button>}
                {(uploadStatus || !poster) && <div className="profile__upload-poster-wrapper">
                    <div className="profile-section__title">Upload business poster</div>
                    <Field
                        name="poster"
                        component={FileUploader}
                        setFieldValue={setFieldValue}
                        errorMessage={errors["poster"] ? errors["poster"] : undefined}
                        touched={touched["poster"]}
                        onBlur={handleBlur}
                    />
                    <button type="button" className="btn btn-primary"
                            disabled={pending}
                            onClick={() => uploadImage(values)}>Submit</button>
                    {uploadStatus && values.poster && <button type="button" className="btn btn-primary ml-3"
                                                              onClick={() => setUploadStatus(false)}>Reset</button>}
                </div>}
            </>
        </div>

    )
}

export default BusinessPoster;
