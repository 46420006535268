import React from 'react';
import ReactSVG from "react-svg";
import CheckIcon from '../images/correct-icon.svg';
import DiscardIcon from '../images/incorrect-icon.svg';

class InputWithButtons extends React.Component{
    constructor(props) {
        super(props);
        this.state  = {
            showButtons: false
        }
        this.buttonsContainer = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick,false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick,false);
    }

    handleClick = e => {
        if(this.buttonsContainer.current){
            if (!(this.buttonsContainer.current === e.target || this.buttonsContainer.current.contains(e.target))) {
                this.handleSave()
            }
        }
    };


handleSave = () => {
    this.setState({
        showButtons:false
    })
    this.props.handleSave()
}

handleDiscard = () => {
    this.setState({
        showButtons:false
    })
    this.props.handleDiscard()
}

render() {
    let {wrapClasses} = this.props;
    return (
        <div className={`form-group input-buttons-wrap ${wrapClasses}`} onClick={() => this.setState({showButtons:true})} onFocus={() => this.setState({showButtons:true})}>
            {this.props.children}
            {this.state.showButtons && <div className="input-buttons-wrap__container" ref={this.buttonsContainer}>
                <button type="button" className="btn input-buttons-wrap__button" onClick={this.handleSave}>
                    <ReactSVG src={CheckIcon}/>
                </button>
                <button type="button" className="btn  input-buttons-wrap__button" onClick={this.handleDiscard}>
                    <ReactSVG src={DiscardIcon}/>
                </button>
            </div>}
        </div>

    );
}

}

export default InputWithButtons;
