import React, {useEffect} from 'react';
import {sidebarTypes} from "../../../common";
import {withRouter} from "react-router-dom";
import OrdersList from "./OrdersList";
import TopSectionContractor from "../top-section/TopSectionContractor";
//import BidViewSidebar from "./BidViewSidebar";
import {Trans} from 'react-i18next';
import { color } from 'd3';
function Orders (props) {
    useEffect(() => {
        props.setSidebar(sidebarTypes.none)
        return () => {
            props.clearBids()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    let classToggle = props.sidebar_toggled || props.sidebar_type === sidebarTypes.none ? '' : 'sidebar-wrap_toggled'

    return (
      <div className={`sidebar-wrap sidebar-wrap_wide ${classToggle}`}>
        <div className="marketplace-wrap">
          <nav className="top-section">
            <div className="row">
              <div className="col">
                <span style={{ color: "black" }}>
                  <Trans>Orders</Trans>
                </span>
              </div>
            </div>
          </nav>
          <div className="bids-section">
            <div className="bids-section__inner-offset"></div>
            <div className="tab-content d-flex flex-column flex-grow-1">
              <OrdersList {...props} />
            </div>
          </div>
        </div>
      </div>
    );
}

export default withRouter(Orders);
