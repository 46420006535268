import React  from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavItem,
    NavLink,
    Nav,
    TabPane,
    TabContent
} from 'reactstrap';
import ReactSVG from "react-svg";
import StatsIcon from "../../../images/stats-icon.svg";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getElementAnalytics} from "../../../store/elements";
import classnames from "classnames";
import {resourceStatisticTypes, statisticViews} from "../../../common";
import StatisticsChart from "../../StatisticsChart";
import {Trans} from "react-i18next";

class ElementStatistics extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            activeTab: 1
        }
    }

    setDropdownOpen = () => {
        this.setState({dropdownOpen:!this.state.dropdownOpen})
    }

    toggle = () => this.setDropdownOpen();

    handleToggle(activeTab) {
        this.setState({activeTab})
    }

    renderStatistics(type){
        let {element_analytics} = this.props;
        if(!element_analytics) return null;
        let {totalWeekCount,
            totalDayCount,
            dayDiff,
            weekDiff,
            thisWeek,
            thisDay} = statisticViews(element_analytics);

        if(type === resourceStatisticTypes.daily){
            return(
                <div className="statistic-item statistic-item_transparent">
                    {weekDiff && <div className="statistic-item__description"><Trans>The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)</Trans></div>}
                    <div className="statistic-item__chart">
                        <StatisticsChart data={thisWeek} interval={0}/>
                    </div>
                </div>
            );
        }else if(type === resourceStatisticTypes.hourly){
            return(
                <div className="statistic-item statistic-item_transparent">
                    <div className="statistic-item__description"><Trans>The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)</Trans></div>
                    <div className="statistic-item__chart">
                        <StatisticsChart data={thisDay} interval={3}/>
                    </div>
                </div>
            );
        }
    }


    render() {
        let {activeTab} = this.state;

        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="filter-dropdown statistic-dropdown ml-3">
                <DropdownToggle className={`btn btn-small-round`}>
                    <ReactSVG className="btn-small-round__icon" src={StatsIcon}/>
                    <span className="btn-small-round__text pl-2 d-sm-inline-block d-none"><Trans>Statistics</Trans></span>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem header><Trans>Views statistics</Trans></DropdownItem>
                    <Nav tabs className='nav nav-tabs tabs-primary tabs-round mb-4'>
                        <NavItem className="mb-xl-0 mb-4">
                            <NavLink
                                className={classnames({ active: activeTab === 1 })}
                                onClick={() => { this.handleToggle(1); }}><Trans>Daily</Trans></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => { this.handleToggle(2); }}><Trans>Hourly</Trans></NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="">
                        <TabPane tabId={1} className="tab-pane">
                            {this.renderStatistics(resourceStatisticTypes.daily)}
                        </TabPane>
                        <TabPane tabId={2} className="tab-pane">
                            {this.renderStatistics(resourceStatisticTypes.hourly)}
                        </TabPane>
                    </TabContent>

                </DropdownMenu>
            </Dropdown>
        );
    }



}

const mapStateToProps = state => ({
    element_item: state.elements.element_item,
    element_analytics: state.elements.element_analytics
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getElementAnalytics
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ElementStatistics);