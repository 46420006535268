//Identifier item for element. Using on identifier data page
import React from 'react';
import MediaPlayer from "../player/MediaPlayer";
import {identifierTypes} from "../../common";
import CustomScrollBar from "../CustomScrollBar";
// import SmallRoundButton from "../buttons/SmallRoundButton";
// import InfoIcon from "../../images/info-icon.svg";
import ReactSVG from "react-svg";
import CloseIcon from "../../images/close-icon.svg";
import {Trans} from "react-i18next";
import ReactImageAnnotate from "react-image-annotate/dist";
import * as _ from "lodash";

function IdentifierItemView(props) {
    let {training_item,saveSubElement,sub_elements,deleteSubElement,handleSwitchTab,handleOpenSubElement,element_language,isCreated,element_active,handleCloseSubElement} = props;
    if(!training_item) return;
    let isImage = training_item.type_id === identifierTypes.image;
    let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
    let subElementsOfThisIdentifier = _.filter(sub_elements,['identifier_id',training_item.id]);
    let formattedSubElements = (sub_elements && subElementsOfThisIdentifier) ? subElementsOfThisIdentifier
        .filter(subEl => training_item.id === subEl.identifier_id)
        .map((sub_element) => {
            return {
                type: "box",
                id: sub_element.id,
                highlighted: false,
                x: sub_element.coordinates.x,
                y: sub_element.coordinates.y,
                w: sub_element.coordinates.w,
                h: sub_element.coordinates.h,
                visible: true,
                color: "#fff",
                regionName: sub_element.translations && sub_element.translations[element_language] ? sub_element.translations[element_language].name : ''
            }
    }) : [];


    return (
        <div className="element-half-section">
            <div className="row subheader">
                <div className="col-md-10 d-flex align-items-center">
                    <span className="subheader__title"><Trans>View Identifier</Trans></span>
                    {/*<SmallRoundButton icon={InfoIcon} classes={'ml-md-4 ml-sm-0 btn-small-round_dark'}/>*/}
                </div>
                <div className="col-md-2 d-flex justify-content-end">
                    <button type="button" className="btn btn-close" onClick={props.closeViewIdentifier}>
                        <ReactSVG className="btn-small-round__icon" src={CloseIcon}/>
                    </button>
                </div>
            </div>
            <CustomScrollBar options={overflowOptions}>
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="training-item-view">
                            {isImage && training_item.media_url &&
                                <ReactImageAnnotate
                                    // labelImages
                                    regionTagList={["1"]}
                                    regionClsList={["1"]}
                                    images={[
                                        {
                                            src: training_item.media_url,
                                            name: "Image 1",
                                            regions: formattedSubElements
                                        }
                                    ]}
                                    newRegions={formattedSubElements}
                                    enabledTools={['create-box']}
                                    customCloseRegion={(region) => {
                                        saveSubElement(region).then((data) => {
                                        })
                                    }}
                                    customDeleteRegion={(region_id) => isCreated(region_id) ? deleteSubElement(region_id) : undefined}
                                    disableClasses={true}
                                    disableTags={true}
                                    disableRegionType={true}
                                    disableTopNav={true}
                                    onLinkResource={(region_id) => isCreated(region_id) ? handleSwitchTab(2,region_id) : undefined}
                                    customOpenRegion={(region) => handleOpenSubElement(region)}
                                    customSelectRegion={(region) => handleOpenSubElement(region)}
                                    readOnly={element_active}
                                    customMouseDown={handleCloseSubElement}
                                />
                            }
                             {/*<img className="training-item-view__image" src={training_item.media_url} alt=""/>}*/}
                            {!isImage && training_item.media_url && <MediaPlayer media_url={training_item.media_url} autoPlay={false} hidden={false}/>}
                        </div>
                        {/*<div className="form-group">*/}
                        {/*    <input*/}
                        {/*        readOnly*/}
                        {/*        id="name"*/}
                        {/*        placeholder={`Enter identifier item name`}*/}
                        {/*        type="text"*/}
                        {/*        value={training_item.name}*/}
                        {/*        className={`form-control input-primary d-block`}/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </CustomScrollBar>
        </div>
    )
}
export default IdentifierItemView;


