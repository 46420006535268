//If user forget password he can send link for create new password, link will send to his email
import React  from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import {setCurrentResource} from "../../store/resources";
import {resetPassword, signIn} from "../../store/user";
import {getValidationRules, setResetPasswordSchema} from "../../store/validations";
import { Formik} from "formik";
import {Link} from "react-router-dom";
import {Trans, withTranslation} from "react-i18next";
import bigOverlayImage from '../../images/big-overlay.png';
import loginImage from '../../images/login-page-user-icon.svg'


class SendLink extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email:''
        }
    }

    componentDidMount() {
        this.props.getValidationRules('dashboard')
    }

    handleInputChange= (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    resetPassword = (values) => {
        let {email} = values;
        this.props.resetPassword(email)
    }



    render(){
        let language = this.props.i18n.language;
        let {t} = this.props;
        return (
          <div className="body row auth-wrap">
            <div className="title-side col-md-8 col-sm-12">
              <div className="big-overlay-left">
                  <img src={bigOverlayImage} draggable={false} alt="" />
              </div>
              <div className="auth__content">
                <div className="flex-grow-1 justify-content-center align-items-center d-flex flex-column">
                  <h1 className="title main-title text-center line-height-large">
                    <Trans>Forgot your password?</Trans>{" "}
                  </h1>
                  <h2 className="subtitle text-center">
                    <Trans>Receive the link to reset it in your inbox</Trans>
                  </h2>
                </div>
                <a
                  href="https://realmeta.ca/"
                  className="link link-primary bottom-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Visit</Trans> realmeta.ca
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <Formik
                initialValues={this.state}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    this.resetPassword(values);
                    setSubmitting(false);
                  }, 500);
                }}
                validationSchema={setResetPasswordSchema(
                  this.props.validation_rules,
                  t
                )}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  } = props;
                  let email_invalid_class =
                    errors.email && touched.email ? "invalid" : "";
                  return (
                    <form onSubmit={handleSubmit} className="form-primary">
                      <div className="px-5 flex-grow-1 justify-content-center align-items-center d-flex flex-column w-100">
                        <div className="user-image-container">
                          <img src={loginImage} alt=""/>
                        </div>
                        {/* <div className="title">
                          <Trans></Trans>
                        </div>
                        <div className="subtitle">
                          <Trans></Trans>
                        </div> */}
                        <div className="form-group">
                          {errors.email && touched.email && (
                            <div className="input-feedback">{errors.email}</div>
                          )}

                          <input
                            id="email"
                            placeholder={t("Enter email")}
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-control input-primary d-block ${email_invalid_class}`}
                          />

                        </div>
                        <button
                          type="submit"
                          className="sign-in-button sign-in-button-bot-margin"
                          disabled={isSubmitting}
                        >
                          <Trans>Send reset link</Trans>
                        </button>
                        <div>
                          <Link className="mt-3 btn-link" to={`/${language}/login`}>
                            {" "}
                            <Trans>Back To Login</Trans>
                          </Link>
                        </div>
                      </div>

                      <p className="copyright mb-0">
                        &copy; 2023 RealMeta <Trans>All Rights Reserved</Trans>
                      </p>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        );
    }

}


const mapStateToProps = state => ({
    user: state.user.user,
    validation_rules: state.validations
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCurrentResource,
    signIn,
    getValidationRules,
    resetPassword
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SendLink));

