import React from 'react'
import CustomScrollBar from "../../CustomScrollBar";
import {Field, Formik} from "formik";
import {setResourceSchema} from "../../../store/validations";
import {languages} from "../../../common";
import {TabPane} from "reactstrap";
import LanguageSelect from "../../LanguageSelect";
import FileUploader from "../../FileUploader";
import RequestCreationResourceButton from "./RequestCreationResourceButton";
import {Trans, withTranslation} from "react-i18next";
import WithTranslateFormErrors from "../../WithTranslateFormErrors";

function UploadAnimationResourceTab(props) {
    let {create_resources_mode,initialValues,validation_rules,handleSubmit,tabId,resource_type_id,t,selectResourceLanguage,media_type} = props;
    let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}}

    return(
        <TabPane tabId={tabId} className="tab-pane">
            <CustomScrollBar options={overflowOptions}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            handleSubmit(values);
                            setSubmitting(false)
                        }, 500);
                    }}
                    validationSchema={setResourceSchema(create_resources_mode,false,validation_rules,false,true,media_type,false,t)}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                            setFieldTouched
                        } = props;
                        let name_invalid_class = errors.name && touched.name ? 'invalid' : '';
                        return (
                            <WithTranslateFormErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                                <form onSubmit={handleSubmit} name="create-resource">
                                    <div className="row">
                                        <div className="col">
                                            <LanguageSelect data={languages} disabled={false} value={values.language}
                                                            onBlur={languages} setFieldValue={(name,value) => selectResourceLanguage(name,value)}
                                                            name={'language'} isDark/>
                                            <Field
                                                id="gif-file"
                                                name="gif"
                                                component={FileUploader}
                                                setFieldValue={setFieldValue}
                                                errorMessage={errors["gif"] ? errors["gif"] : undefined}
                                                touched={touched["gif"]}
                                                onBlur={handleBlur}
                                            />
                                            <div className="note-text mb-3"><Trans>Note, Please Upload only GIF File</Trans></div>
                                            <Field
                                                name="multimedia"
                                                component={FileUploader}
                                                setFieldValue={setFieldValue}
                                                errorMessage={errors["multimedia"] ? errors["multimedia"] : undefined}
                                                touched={touched["multimedia"]}
                                                onBlur={handleBlur}
                                            />
                                            <div className="note-text mb-3"><Trans>Note, Please Upload only Audio File</Trans></div>
                                            <div className="form-group">
                                                <input
                                                    id="anim-resource-name"
                                                    name="name"
                                                    placeholder={t(`Enter resource name`)}
                                                    type="text"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control input-primary d-block ${name_invalid_class}`}/>
                                                {errors.name && touched.name && (
                                                    <div className="input-feedback">{errors.name}</div>
                                                )}
                                            </div>
                                            {/*{!create_resources_mode && <TagsInput tags={this.state.tags || []} handleChange={this.handleTagsChange}/>}*/}
                                            <div className="d-flex flex-row justify-content-start align-items-center">
                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                    <span className=""><Trans>Save</Trans></span>
                                                </button>
                                                {resource_type_id && <RequestCreationResourceButton type_id={resource_type_id}/>}
                                            </div>

                                        </div>
                                    </div>

                                </form>
                            </WithTranslateFormErrors>
                        );
                    }}
                </Formik>
            </CustomScrollBar>
        </TabPane>
    )
}

export default withTranslation('translations')(UploadAnimationResourceTab);