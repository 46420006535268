import React from "react";
import {Trans} from 'react-i18next';

function TitleInput(props) {
    let title_invalid_class = props.formik.errors.title && props.formik.touched.title ? 'invalid' : '';
    return(
        <div className="form-group">
            <div className="sidebar__title sidebar__title_offset-free"><Trans>{props.title || 'Enter title'}</Trans></div>
            <input
                id="title"
                name="title"
                placeholder={props.placeholder || props.t(`Enter title`)}
                type="text"
                value={props.formik.values.title}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                className={`form-control input-primary d-block ${title_invalid_class}`}/>
            {props.formik.errors.title && props.formik.touched.title && (
                <div className="input-feedback">{props.formik.errors.title}</div>
            )}
        </div>
    )
}

export default TitleInput;
