//Button for publish element and experience.
import React, {useState} from 'react'
import {withTranslation} from "react-i18next";
import {Modal, ModalFooter, ModalHeader} from "reactstrap";

function VRPublishButton(props) {
    let {classes,element_item,t} = props;
    let disable = true;
    let element_name = element_item.translations[props.main_content_language] ? element_item.translations[props.main_content_language].name : null;
    if(element_item.vr_active){
        disable = false;
    }else disable = !!!element_name;
    let tooltipText = disable ? `${t("Required for vr enable an element")}: ${!element_name ? t('Element Name') : ''}` : null;
    const [modalShow,setModalShow] = useState(false);

    const handleSubmit = () => {
        let id = props.element_item.id,
            element_updated = {vr_active: !props.element_item.vr_active};
        if (props.element_item.vr_active) {
            if(props.element_item.vr_related){
                setModalShow(true)
            }else {
                props.updateElementData(id, element_updated).then(() => {
                    props.getElements().then(() => {
                        props.setLoading(false)
                    })
                })
            }
        } else {
            props.updateElementData(id, element_updated).then(() => {
                props.getElements().then(() => {
                    props.setLoading(false)
                })
            })
        }
    }
    return (
        <button type={'button'} disabled={disable} className={`btn btn-primary ${classes}`}
                onClick={handleSubmit} data-tooltip={disable ? tooltipText : false}>
            <span>{props.element_item.vr_active ? t('VR Disable') : t('VR Enable')}</span>
            <Modal isOpen={modalShow} toggle={() => setModalShow(!modalShow)} className="modal modal-resources-list" centered>
                <ModalHeader toggle={() => setModalShow(!modalShow)}>You can't disable this element because of its attached to experience.</ModalHeader>
                <ModalFooter className="d-block">
                    <div className="row">
                        <div className="col-12 mb-md-0 mb-3 text-center">
                            <button className="btn btn-primary btn-small btn-dark" onClick={() => setModalShow(false)}>Ok</button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </button>
    )
}

export default withTranslation('translations')(VRPublishButton);
