import React from "react";
import TimezoneSelect from 'react-timezone-select'
import {themes} from "../../../../common";
import {Trans} from 'react-i18next';

function Timezone(props) {
    const onChange = (data) => {
        props.formik.setFieldValue('timezone',data.value)
        props.handleFieldChange('timezone',data.value)
    }
    let theme = props.dark_mode ? themes['dark-mode'] : themes['light-mode']
    return (
        <div className='select-wrapper'>
            <div className="contractor-profile__title"><Trans>Timezone</Trans></div>
            <TimezoneSelect
                value={props.formik.values.timezone || ''}
                onChange={onChange}
                className="select-language"
                classNamePrefix="select-language"
                styles={{
                    option: (provided, state) => ({
                        ...provided,
                        border: 'none',
                        color: state.isSelected ? theme.text_color : theme.lang_select_text_inactive,
                        padding: 8,
                        display: 'flex',
                        alignItems: 'center'
                    }),
                    singleValue: (provided, state) => ({
                        ...provided,
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.text_color
                    }),
                    valueContainer: (provided, state) => ({
                        ...provided,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        padding: '2px 16px',
                        display: 'flex',
                        alignItems: 'center'

                    }),
                    control: (provided, state) => ({
                        ...provided,
                        backgroundColor: props.isDark ? theme.lang_select_bg_dark : theme.lang_select_bg,
                        borderWidth: 0,
                        borderRadius: '25px',
                        outline: 'none',
                        minHeight: '36px',
                        boxShadow: 'none',
                        display: 'inline-flex',
                        alignItems: 'center',
                        minWidth: '200px',
                        width: '100%'
                    }),
                    indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: 'none',
                    }),
                    dropdownIndicator: (provided, state) => ({
                        ...provided,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 0,
                        padding: '2px 16px',
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        width: 'auto',
                        minWidth: '200px',
                        backgroundColor: theme.lang_select_bg,
                    })
                }}
            />
        </div>
    )
}

export default Timezone;
