import React  from 'react';
import {ErrorMessage, Field} from "formik";
import ReactSVG from "react-svg";
import CorrectIcon from "../../../../images/correct-icon.svg";
import IncorrectIcon from "../../../../images/incorrect-icon.svg";

function QuizAnswer(props) {
    let {index,answer,values,arrayHelpers,onChangeAnswer,deleteAnswer,setFieldValue} = props;
    return(
        <div className="quiz-answer__wrapper" key={index}>
            <div className="quiz-answer">
                <div className="quiz-answer__inner">
                    <Field name={`answers.${index}.name`} value={answer.name}/>
                    {!answer.is_correct  && <button type="button" className="btn-remove" onClick={() => deleteAnswer(answer,arrayHelpers,index)}/>}
                </div>
                <button type="button" className="btn btn-quiz-correct" onClick={(e) => onChangeAnswer(answer,index,values.answers,setFieldValue)}>
                    {answer.is_correct ?
                        <ReactSVG src={CorrectIcon}/> :
                        <ReactSVG src={IncorrectIcon}/>
                    }
                </button>
            </div>
            <ErrorMessage name={`answers.${index}.name`} render={(str) => <div className="input-feedback">{str}</div>} />
            <ErrorMessage name={`answers.${index}.is_correct`} render={(str) => <div className="input-feedback">{str}</div>} />
        </div>
    )
}

export default QuizAnswer;