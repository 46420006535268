import React, { useState,useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactSVG from "react-svg";
import FilterIcon from '../../images/filter-icon.svg';
import {Trans, withTranslation} from "react-i18next";
import ShowWithoutResourcesCheckbox from "./ShowWithoutResourcesCheckbox";

const ElementFiltersDropdown = (props) => {
    const [dropdownOpen,setDropdownOpen] = useState(false);
    const [countFilter,setCounter] = useState(0);

    useEffect(() => {
        if(props.language && props.show_without_resources){
            setCounter(2)
        }else if(props.language || props.show_without_resources){
            setCounter(1)
        }else setCounter(0)
    },[props.language, props.show_without_resources])

    const toggle = () => setDropdownOpen(prevState => !prevState);

    function handleReset() {
        props.handleResetFilters()
        toggle();
    }

    let activeFilterClass = (props.language || props.show_without_resources) ? 'filter-dropdown__btn_active' : '';

    return (
        <Dropdown  className="filter-dropdown" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className={`btn filter-dropdown__btn ${activeFilterClass} custom-tooltip custom-tooltip_bottom`} data-tooltip={props.t("Filter")}>
                <ReactSVG src={FilterIcon}/>
                {countFilter > 0 && <span className="filter-dropdown__btn-count">{countFilter}</span>}
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem header><Trans>Filters</Trans></DropdownItem>
                <ShowWithoutResourcesCheckbox handleInputChange={props.handleInputChange} show_without_resources={props.show_without_resources}/>
                {/*            /!*<div className="form-group col-md-3">*!/*/}
                {/*            /!*    <select className="input-primary select-primary" defaultValue="Sort by">*!/*/}
                {/*            /!*        <option value="1">By date</option>*!/*/}
                {/*            /!*        <option value="2">By type</option>*!/*/}
                {/*            /!*        <option value="3">By alphabet</option>*!/*/}
                {/*            /!*    </select>*!/*/}
                {/*            /!*</div>*!/*/}
                <button className="btn btn-primary btn-outline w-100" onClick={() => handleReset(props,toggle)} type="button"><Trans>Reset filters</Trans></button>
            </DropdownMenu>
        </Dropdown>
    );
}

export default withTranslation('translations')(ElementFiltersDropdown);