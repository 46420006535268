import React, { Component } from "react";
import ReactSVG from "react-svg";
import AttachIcon from "../../images/attachment-icon.svg";

class AttachButton extends Component {
    handleFileUpload = event => {
        this.props.formik.setFieldValue('files',Array.from(event.target.files))
    };

    render() {
        return (
            <React.Fragment>
                <input
                    ref="fileInput"
                    onChange={this.handleFileUpload}
                    type="file"
                    style={{ display: "none" }}
                    multiple={true}
                />
                <button type="button" className="btn p-0 attach-button"
                        onClick={() => this.refs.fileInput.click()}>
                    <ReactSVG className="" src={AttachIcon}/>
                    {this.props.formik.values.files && <span className="attach-button-indicator">{this.props.formik.values.files.length}</span>}
                </button>
            </React.Fragment>
        );
    }
}

export default AttachButton;
