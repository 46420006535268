import React from "react";
import ResourceTypeSelect from "./ResourceTypeSelect";
import {Trans} from 'react-i18next';

function ResourceType(props) {
    const setResourceType = (name,value) => {
        props.formik.setFieldValue(name,value)
    }
    return(
        <>
            <div className="sidebar__title sidebar__title_offset-free"><Trans>Required resource type</Trans></div>
            <ResourceTypeSelect setFieldValue={setResourceType}
                                name={'resource_type_id'} isDark hideHelp classes="mb-4 resource-type-select" t={props.t}
                                value={props.formik.values.resource_type_id}/>
        </>
    )
}

export default ResourceType;
