//element view screen showing by clicking on element card
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";
import {
    attachSingleTextureToExperience,
    deleteSingleExperience,
    getExperienceItemById, getUnityTextures, mappingElementsForExperience, setCurrentExperience, setExperienceLanguage,
    updateExperienceData, updateExperienceField,
    uploadExperienceFile
} from "../store/experiences";
import {setModal, setSidebar, setSidebarToggle} from "../store/util";
import {sidebarTypes} from "../common";
import ExperienceSidebar from "../components/sidebar/ExperienceSidebar";
import {setLoading, toggleUpgradePlanModal} from "../store/user";
import {getValidationRules} from "../store/validations";
import ExperienceSectionView from "../components/experience/ExperienceSection/ExperienceSectionView";
import ElementsListSidebar from "../components/sidebar/ElementsListSidebar";
import {clearElementsList, getElements2, setElementImage} from "../store/elements";
import ExperienceToolsSidebar from "../components/sidebar/ExperienceToolsSidebar";
import {refreshUnity2} from "../components/experience/UnityFunctions";

class ExperienceView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.props.setSidebarToggle(false)
        this.props.getValidationRules('dashboard_vr')
        this.props.setSidebar(sidebarTypes.experience)
        let experience_id = this.props.match.params.id;
        if(experience_id){
            this.props.getExperienceItemById(experience_id)
                .then(() => {
                    refreshUnity2(experience_id)
                })
                .catch(() => {
                    this.props.history.push('/');
                })
        }
    }

    componentWillUnmount() {
        this.props.setCurrentExperience(undefined)
    }


    render() {
        let classToggle = this.props.sidebar_toggled ? '' : 'sidebar-wrap_toggled sidebar-wrap_wide';

        return (
            <div className={`sidebar-wrap ${classToggle}`}>
                <ExperienceSectionView {...this.props}/>
                <ExperienceSidebar {...this.props}/>
                <ElementsListSidebar {...this.props}/>
                <ExperienceToolsSidebar {...this.props}/>
                {/*<ChangeElementsOrderSidebar {...this.props}/>*/}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    validation_rules: state.validations,
    sidebar_type: state.util.sidebar_type,
    experience_item: state.experiences.experience_item,
    experiences: state.experiences.experiences,
    main_content_language: state.user.main_content_language,
    experience_language: state.experiences.experience_language,
    unity_textures: state.experiences.unity_textures,
    user_info: state.user.user_info,
    sidebar_toggled: state.util.sidebar_toggled,
    elements_for_experience: state.elements.elements_for_experience,

});

const mapDispatchToProps = dispatch => bindActionCreators({
    deleteSingleExperience,
    setSidebar,
    setModal,
    getExperienceItemById,
    uploadExperienceFile,
    updateExperienceData,
    setLoading,
    toggleUpgradePlanModal,
    setExperienceLanguage,
    updateExperienceField,
    getValidationRules,
    setSidebarToggle,
    getElements2,
    mappingElementsForExperience,
    getUnityTextures,
    attachSingleTextureToExperience,
    setElementImage,
    setCurrentExperience,
    clearElementsList

},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ExperienceView));
