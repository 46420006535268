import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {showFullSizeGallery} from "../store/resources";
import {resourceTypes, mediaTypes} from "../common";
import MediaPlayer from "./player/MediaPlayer";
import ResourceDegreeContentView from "./resource/EditResource/ResourceDegreeContentView";
import Resource3dModelView from "./resource/EditResource/Resource3dModelView";
import ReactSVG from "react-svg";
import CloseIcon from "../images/close-icon.svg";
import ZoomInIcon from "../images/zoom-in-icon.svg";
import ZoomOutIcon from "../images/zoom-out-icon.svg";
import ZoomResetIcon from "../images/zoom-reset-icon.svg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


class FullSizeGallery extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            format: '',
            preview_url: '',
            media_url: '',
            supplement_files: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let {translations,files,supplement_files} = nextProps.resource_item ? nextProps.resource_item : {};
        let update = {};
        let language = nextProps.resource_language;
        if(translations){
            if(translations[language]){
                update.name = translations[language].name ? translations[language].name : '';
            }else {
                update.name = '';
            }
        }
        if(files){
            if(files[language]){
                update.preview_url = files[language].preview_url ? files[language].preview_url : '';
                update.format = files[language].format ? files[language].format : '';
                update.media_url = files[language].media_url ? files[language].media_url : '';
            }else {
                update.preview_url = '';
                update.format = '';
                update.media_url = '';
            }
        }
        if(supplement_files){
            if(supplement_files[language]){
                update.supplement_files = supplement_files[language] ? supplement_files[language] : '';
            }
            else {
                update.supplement_files = '';
            }
        }

        return Object.keys(update).length ? update : null;
    }

    renderResourceMedia(){
        const {preview_url, media_url} = this.state;
        let types = (typeof this.props.resource_item.type_id === 'string') ? mediaTypes : resourceTypes;
        let {type_id} = this.props.resource_item;
        if(type_id === types.image){
            if(!preview_url) return null;
        }else if (!media_url) return null;

        switch (type_id) {
            case types.image: return <img src={media_url} className="fullsize-gallery__image" alt=""/>
            case types.video: return <MediaPlayer media_url={media_url} isFullscreen/>;
            case types.audio: return <MediaPlayer media_url={media_url} isFullscreen/>;
            case types.degreeImage: return <ResourceDegreeContentView degreeContent={<a-sky src={media_url} className="modal-resource__degree-image"/>}/> ;
            case types.degreeVideo: return <ResourceDegreeContentView degreeContent={<a-videosphere src={media_url} className="modal-resource__video"/>}/> ;
            case types.text: return null;
            case types.model: return <Resource3dModelView file={media_url}/>;
            case types.zip: return <div className="fullsize-gallery__image"><img src={preview_url} className="resource-item-view__image" alt=""/></div>;
            case types.animations: return <img src={preview_url} className="fullsize-gallery__image" alt=""/>;
            default: return null;
        }
    }

    render() {
        let {resource_item,showFullSizeGallery,supplement_files} = this.props;
        let {name,format} = this.state;
        let {type_id} = resource_item;
        let isImage = type_id === resourceTypes.image;
        let isAnimation = type_id === resourceTypes.animations;
        return (
            <div className="fullsize-gallery">
                <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={200}
                    defaultPositionY={100}
                    options={{disabled: !isImage,maxScale:10}}
                    enablePadding={false}
                    wheel={{disabled:true}}
                    limitToBounds={false}
                    scalePadding={{disabled:true}}
                    zoomIn={{step:5}}
                    zoomOut={{step:5}}
                >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                            <div className="fullsize-gallery__header">
                                <div className="fullsize-gallery__file-name">{name}.{format}</div>
                                <button className="btn btn-close" onClick={() => showFullSizeGallery(false)}>
                                    <ReactSVG src={CloseIcon} alt=""/>
                                </button>
                            </div>
                            <div className="fullsize-gallery__body">
                                {isImage && <TransformComponent>
                                    {this.renderResourceMedia()}
                                </TransformComponent>}
                                {!isImage && this.renderResourceMedia()}
                            </div>
                            <div className="fullsize-gallery__footer">
                                {isAnimation && supplement_files && <MediaPlayer media_url={supplement_files.media_url} autoPlay loop hidden isFullscreen/>}
                                {isImage && <div className="fullsize-gallery__tools">
                                    <button className="btn btn-zoom" onClick={zoomIn}>
                                        <ReactSVG src={ZoomInIcon} alt=""/>
                                    </button>
                                    <button className="btn btn-zoom" onClick={zoomOut}>
                                        <ReactSVG src={ZoomOutIcon} alt=""/>
                                    </button>
                                    <button className="btn btn-zoom" onClick={resetTransform}>
                                        <ReactSVG src={ZoomResetIcon} alt=""/>
                                    </button>
                                </div>}
                            </div>
                        </React.Fragment>
                    )}
                </TransformWrapper>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    show_fullsize_gallery: state.resources.show_fullsize_gallery,
    resource_item: state.resources.resource_item,
    main_content_language: state.user.main_content_language,
    resource_language: state.resources.resource_language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    showFullSizeGallery
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FullSizeGallery);