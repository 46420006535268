//Play/pause controls for media player
import React from 'react'
import { withMediaProps } from 'react-media-player';
import playIcon from '../../images/play-icon.svg';
import pauseIcon from "../../images/pause-icon.svg";
import ReactSVG from "react-svg";

class CustomPlayPause extends React.Component {
    shouldComponentUpdate({ media }) {
        return this.props.media.isPlaying !== media.isPlaying
    }

    _handlePlayPause = (e) => {
        e.stopPropagation()
        this.props.media.playPause()
    }

    render() {
        const { className, style, media } = this.props
        return (
            <button
                type="button"
                className={className + ' btn btn-play'}
                style={style}
                onClick={this._handlePlayPause}
            >
                <ReactSVG src={media.isPlaying ? pauseIcon : playIcon}/>
            </button>
        )
    }
}

export default withMediaProps(CustomPlayPause)