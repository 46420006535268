import React from 'react';
import CreateIcon from "../../images/create-icon.svg";
import SmallRoundButton from "../buttons/SmallRoundButton";

function CreateButton(props) {
    return(
        <SmallRoundButton {...props} icon={CreateIcon} classes={"btn-small-round"}
                          onClick={props.onClick} data_tip={props.t(`${props.title}`)}/>
    )
}

export default CreateButton;
