import React from "react";
import CardsList from "../CardsList";
import CustomScrollBar from "../../../components/CustomScrollBar";
import InfiniteScrollContainerNew from "../../../components/InfiniteScrollContainerNew";
import {userRoles} from "../../../common";
import BidCard, {bidStatuses} from "./BidCard";

function BidsList(props) {
    if(!props.user_info) return null;
    let overflowVerticalOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;

    const getItems = (newPage) => {
        let getItems = props.onlySubmitted ? props.getOnlySubmittedBids : props.getBids;
        let $submittedStatusId = bidStatuses.find(bid => bid.name === 'Submitted').id;
        let contractor_id = isContractor && props.user_info.id;
        let job_id = props.job_id;
        return new Promise((resolve,reject) => {
            getItems({contractor_id,job_id, page: newPage, status_id: props.onlySubmitted && $submittedStatusId})
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        })
    }

    return(
        <CustomScrollBar className="cards-list-offset" options={overflowVerticalOptions}>
            <InfiniteScrollContainerNew getItems={getItems}>
                <CardsList ItemComponent={BidCard} items={props.onlySubmitted ? props.submitted_bids : props.bids} {...props}/>
            </InfiniteScrollContainerNew>
        </CustomScrollBar>
    )
}

export default BidsList;
