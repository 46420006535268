import React from "react";
import {Trans} from 'react-i18next';

function MinutesInput(props) {
    let minutes_invalid_class = props.formik.errors.estimate_minutes && props.formik.touched.estimate_minutes ? 'invalid' : '';
    return(
        <div className="form-group">
            <div className="sidebar__title sidebar__title_offset-free">
                <Trans>{props.title || 'Proposed timeline'}</Trans>
            </div>
            <input
                id="estimate_minutes"
                name="estimate_minutes"
                placeholder={props.placeholder || props.t(`Enter estimate minutes`)}
                type="text"
                value={props.formik.values.estimate_minutes}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                className={`form-control input-primary d-block ${minutes_invalid_class}`}/>
            {props.formik.errors.estimate_minutes && props.formik.touched.estimate_minutes && (
                <div className="input-feedback">{props.formik.errors.estimate_minutes}</div>
            )}
        </div>
    )
}

export default MinutesInput;
