import React from 'react';
import InputWithButtons from "../../components/InputWithButtons";
import {Formik} from "formik";
import {Trans} from "react-i18next";
import {setUserSchema} from "../../store/validations";
import WithTranslateFormErrors from "../../components/WithTranslateFormErrors";
import BusinessAvatar from "./BusinessAvatar";
import ErrorBoundaryContainer from "../../components/ErrorBoundaryContainer";


class UserPersonalInfo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
        }

    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};

        if(nextProps.user_info){
            let {last_name,first_name} = nextProps.user_info;
            update.first_name = first_name;
            update.last_name = last_name;
        }
        return Object.keys(update).length ? update : null;
    }

    onBlurSave = (error,value,name) => {
        if(!error){
            if (this.state[name] === value) return;
            let user_updated = {[name]:value};
            this.props.updateUserInfo(user_updated)
        }
    }

    render() {
        let {user_info,validation_rules,t} = this.props;
        if(!user_info)return null;
        return(
            <ErrorBoundaryContainer>
                <div className="profile-section">
                    <div className="profile-section__header"><Trans>Personal information</Trans></div>
                    <Formik
                        enableReinitialize
                        initialValues={this.state}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                this.handleSubmit(values);
                                setSubmitting(false)
                            }, 500);
                        }}
                        validationSchema={setUserSchema(validation_rules,t)}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                resetForm,
                                setFieldTouched
                            } = props;
                            let first_name_invalid_class = errors.first_name && touched.first_name ? 'invalid' : '',
                                last_name_invalid_class = errors.last_name && touched.last_name ? 'invalid' : '';
                            return (
                                <WithTranslateFormErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                                    <form onSubmit={handleSubmit} name="" className="profile-section__body profile-section__body_centered w-100">
                                        <BusinessAvatar {...this.props}/>
                                        <div className="input-row">
                                            <InputWithButtons handleSave={() => this.onBlurSave(errors.first_name,values.first_name,"first_name")}
                                                              handleDiscard={() => resetForm()} >
                                                <input
                                                    disabled={false}
                                                    size={values.first_name.length > 0 ? values.first_name.length +1 : t("First Name").length +1}
                                                    id="first_name"
                                                    name="first_name"
                                                    placeholder={t("First Name")}
                                                    type="text"
                                                    value={values.first_name}
                                                    onChange={handleChange}
                                                    onBlur={(e) => {handleBlur(e)}}
                                                    className={`input-borderless profile__user-name text-right ${first_name_invalid_class}`}/>
                                                {errors.first_name && touched.first_name && (
                                                    <div className="input-feedback">{errors.first_name}</div>
                                                )}
                                            </InputWithButtons>
                                            <InputWithButtons handleSave={() => this.onBlurSave(errors.last_name,values.last_name,"last_name")}
                                                              handleDiscard={() => resetForm()}>
                                                <input
                                                    disabled={false}
                                                    size={values.last_name.length > 0 ? values.last_name.length +1 : t("Last Name").length +1}
                                                    id="last_name"
                                                    name="last_name"
                                                    placeholder={t("Last Name")}
                                                    type="text"
                                                    value={values.last_name}
                                                    onChange={handleChange}
                                                    onBlur={(e) => {handleBlur(e)}}
                                                    className={`input-borderless profile__user-name ${last_name_invalid_class}`}/>
                                                {errors.last_name && touched.last_name && (
                                                    <div className="input-feedback">{errors.last_name}</div>
                                                )}
                                            </InputWithButtons>
                                        </div>
                                        <div className="profile__user-email">{user_info.email}</div>
                                    </form>
                                </WithTranslateFormErrors>
                            )
                        }}
                    </Formik>
                </div>
            </ErrorBoundaryContainer>
        )
    }
}

export default UserPersonalInfo;
