import React, {Fragment, useEffect, useState} from 'react';
import {sidebarTypes} from "../../common";
import Sidebar from "./Sidebar";
import {Trans} from "react-i18next";
import ReactSVG from "react-svg";
import CloseIcon from "../../images/close-icon.svg";
import SelectToolDropdown from "../experience/SelectToolDropdown";

function ExperienceToolsSidebar(props) {
    useEffect(() => {

    },[])
    const [openedDropDown, setOpenedDropDown] = useState(false);

    return(
            <Sidebar show={props.sidebar_type === sidebarTypes.experienceTools} {...props}
                header={<Fragment>
                    <div className="d-flex justify-content-start align-items-center">
                        <Trans>Select settings</Trans>
                    </div>
                    <button type="button" className="btn btn-close py-0"
                            onClick={() => props.setSidebar(sidebarTypes.experience)}>
                        <ReactSVG className="btn-small-round__icon" src={CloseIcon}/>
                    </button>
                </Fragment>}
                wide
                     withoutFooter
            >

                <SelectToolDropdown openedDropDown={openedDropDown}
                                     setOpenedDropDown={setOpenedDropDown}
                                    title={'Floor Textures'}
                                    type={'floor'}
                                     {...props} />

                <SelectToolDropdown openedDropDown={openedDropDown}
                                    setOpenedDropDown={setOpenedDropDown}
                                    title={'Walls Textures'}
                                    type={'wall'}
                                    {...props} />

                <SelectToolDropdown openedDropDown={openedDropDown}
                                    setOpenedDropDown={setOpenedDropDown}
                                    title={'Ceiling Textures'}
                                    type={'ceiling'}
                                    {...props} />

                <SelectToolDropdown openedDropDown={openedDropDown}
                                    setOpenedDropDown={setOpenedDropDown}
                                    title={'Frames Textures'}
                                    type={'frame'}
                                    {...props} />


            </Sidebar>
    )
}

export default ExperienceToolsSidebar;
