//All reducers for nodes


import * as api from "../api";
import {handleErrors} from "./errors";


const SET_CREATE_NODE_MODE = 'SET_CREATE_NODE_MODE',
    SET_EDIT_NODE_MODE = 'SET_EDIT_NODE_MODE',
    SET_VIEW_NODES_MODE = 'SET_VIEW_NODES_MODE',
    SET_ELEMENT_NODES = 'SET_ELEMENT_NODES',
    SET_TMP_ELEMENT_NODES = 'SET_TMP_ELEMENT_NODES',
    SET_MODE_LOADING_MODE = 'SET_MODE_LOADING_MODE',
    EDIT_ELEMENT_NODES = 'EDIT_ELEMENT_NODES';


const initialState = {
    create_node_mode: false,
    edit_node_mode: false,
    view_nodes_mode: false,
    coordinates: [
        {
            position_x: 0,
            position_y: 0,
            position_z: -8,
            rotation_x: 0,
            rotation_y: 0,
            rotation_z: 0,
            scale_x: 0.2,
            scale_y: 0.2,
            scale_z: 0.2,
        },
        {
            position_x: 2,
            position_y: 0,
            position_z: -8,
            rotation_x: 0,
            rotation_y: 0,
            rotation_z: 0,
            scale_x: 0.2,
            scale_y: 0.2,
            scale_z: 0.2,
        }, {
            position_x: 0,
            position_y: -2,
            position_z: -8,
            rotation_x: 0,
            rotation_y: 0,
            rotation_z: 0,
            scale_x: 0.2,
            scale_y: 0.2,
            scale_z: 0.2,
        },
        {
            position_x: 2,
            position_y: -2,
            position_z: -8,
            rotation_x: 0,
            rotation_y: 0,
            rotation_z: 0,
            scale_x: 0.2,
            scale_y: 0.2,
            scale_z: 0.2,
        }
    ],
    nodes:[],
    node_item: undefined,
    tmp_nodes: [],
    node_loading_mode:false
};


export const getElementNodesById = (experience_id,scene_id,element_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetElementNodesById(experience_id,scene_id,element_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_ELEMENT_NODES,
                        nodes: responseJson.data
                    });
                    resolve(responseJson)
                })
        })
    };

export const createElementNodesById = (experience_id,scene_id,element_id,node_info) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCreateElementNode(experience_id,scene_id,element_id,node_info)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_TMP_ELEMENT_NODES,
                        tmp_nodes:[]
                    });
                    resolve(responseJson)
                })
        })
    };


export const createMultipleNodes = (experience_id,scene_id,element_id,nodes) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchMultipleNodes(experience_id,scene_id,element_id,nodes)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_TMP_ELEMENT_NODES,
                        tmp_nodes:[]
                    });
                    resolve(responseJson)
                })
        })
    };

export const editElementNodeById = (experience_id,scene_id,element_id,node_id,node_info) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchEditElementNode(experience_id,scene_id,element_id,node_id,node_info)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_TMP_ELEMENT_NODES,
                        tmp_nodes:[]
                    });
                    resolve(responseJson)
                })
        })
    };

export const deleteElementNodesFromScene = (experience_id,scene_id,element_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteElementNodesFromScene(experience_id,scene_id,element_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_ELEMENT_NODES,
                        nodes: []
                    });
                    resolve(responseJson)
                })
        })
    };



export const setCreateNodeMode = (create_node_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_CREATE_NODE_MODE,
            create_node_mode
        });
    };

export const setEditNodeMode = (edit_node_mode,node_item) =>
    (dispatch) => {
        dispatch({
            type: SET_EDIT_NODE_MODE,
            edit_node_mode,
            node_item
        });
    };

export const setViewNodesMode = (view_nodes_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_VIEW_NODES_MODE,
            view_nodes_mode,
        });
    };

export const setTmpNodes = (tmp_nodes) =>
    (dispatch) => {
        dispatch({
            type: SET_TMP_ELEMENT_NODES,
            tmp_nodes
        });
    };

export const setNodeLoadingMode = (node_loading_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_MODE_LOADING_MODE,
            node_loading_mode,
        });
    };

export const clearNodes = () =>
    (dispatch) => {
        dispatch({
            type: SET_ELEMENT_NODES,
            nodes:[]
        });
    };



//Actions for nodes
const ACTION_HANDLERS = {
    [SET_ELEMENT_NODES]: (state, action) => ({
        ...state,
        nodes: action.nodes,
    }),
    [SET_TMP_ELEMENT_NODES]: (state, action) => ({
        ...state,
        tmp_nodes: action.tmp_nodes,
    }),
    [EDIT_ELEMENT_NODES]: (state, action) => ({
        ...state,
        node_item: action.node_item,
    }),

    [SET_CREATE_NODE_MODE]: (state, action) => ({
        ...state,
        create_node_mode: action.create_node_mode,
    }),
    [SET_EDIT_NODE_MODE]: (state, action) => ({
        ...state,
        edit_node_mode: action.edit_node_mode,
        node_item: action.node_item,
    }),
    [SET_VIEW_NODES_MODE]: (state, action) => ({
        ...state,
        view_nodes_mode: action.view_nodes_mode,
    }),
    [SET_MODE_LOADING_MODE]: (state, action) => ({
        ...state,
        node_loading_mode: action.node_loading_mode,
    }),
};


export default function nodes(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}