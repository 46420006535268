//VR experiences list with create and delete buttons
import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {createFilter} from 'react-search-input'
import {setLoading} from "../store/user";
import _ from "lodash";
import ExperienceCard from "../components/experience/ExperienceCard";
import {
    clearExperiences,
    createExperience,
    deleteSelectedExperiences,
    getExperiences, setCurrentExperience, setDeleteExperienceModalStatus,
    setDeleteExperiencesMode,
    setSelectedDeleteExperiences
} from "../store/experiences";
import {setCurrentScene} from "../store/scenes";
import {setCurrentElement} from "../store/elements";
import TopPanel from "../components/top-panel/TopPanel";
import InfiniteScrollContainer from "../components/InfiniteScrollContainer";
import {withTranslation} from "react-i18next";
import { getFilteredExperiences} from "../common";
import CustomScrollBar from "../components/CustomScrollBar";
import {setSidebarToggle} from "../store/util";
import ErrorBoundaryContainer from "../components/ErrorBoundaryContainer";


class VirtualExperiencesList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            show_whithout_resources:false,
            searchTerm: '',
            selectedDeleteExperiences: [],
            deleteMode: false,
            page: 0,
            experiences: [],
            hasMoreItems: true,
            filteredExperiences: [],
            total_count: 0,
            current_count: 0,
        }

    }

    componentDidMount() {
        this.props.setSidebarToggle(true)
        this.props.setCurrentElement(undefined)
    }

    componentWillUnmount() {
        this.props.clearExperiences()
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        update.deleteMode = nextProps.delete_experiences_mode

        return Object.keys(update).length ? update : null;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    callbackCheck = (id,checked) => {
        let {selectedDeleteExperiences,deleteMode} = this.state;
        let items = selectedDeleteExperiences;
        var match = _.find(items, {id});
        if(checked && !match) {
            items.push({id})
            this.setState({selectedDeleteExperiences: items})
        }
        if(!checked && match){
            let index = _.indexOf(items, match);
            items.splice(index, 1);
            this.setState({selectedDeleteExperiences: items})
        }
        if(deleteMode){
            this.props.setSelectedDeleteExperiences(_.map(selectedDeleteExperiences,'id'))
        }
    };

    searchUpdated = (term) => {
        this.setState({searchTerm: term})
    }

    resetSelectedExperiences = () => {
        this.setState({selectedDeleteExperiences:[],deleteMode:false})
    }

    deleteSelectedExperiencesFromState = (selected_delete_experiences) => {
        let {total_count} = this.state;
        let toDelete = new Set(selected_delete_experiences)
        let updatedExperiencesArray = this.state.experiences.filter(experience => !toDelete.has(experience.id))
        this.setState({
            experiences: updatedExperiencesArray,
            filteredExperiences: getFilteredExperiences(this.props,this.state,updatedExperiencesArray),
            current_count: updatedExperiencesArray.length,
            total_count: total_count - selected_delete_experiences.length,
        })
    }

    loadMoreExperiences = () => {
        let {page,experiences} = this.state;
        let newPage = page +1;
        this.setState({page: newPage})
        this.props.getExperiences(undefined, newPage)
            .then((res) => {
                this.props.setLoading(false)
                let updatedExperiencesList = [...experiences, ...res.data];
                //if (res.meta) {
                    //this.setState({current_count: updatedExperiencesList.length, total_count: res.meta.total})
                    this.setState({
                      current_count: updatedExperiencesList.length,
                      total_count: updatedExperiencesList.length,
                    });
                    // if (res.meta.current_page < res.meta.last_page) {
                    //     this.setState({hasMoreItems: true})
                    // } else this.setState({hasMoreItems: false})
                //} else this.setState({hasMoreItems: false})
                this.setState({ hasMoreItems: false });
                this.setState({
                    experiences: [...experiences, ...res.data],
                    filteredExperiences: getFilteredExperiences(this.props, this.state, updatedExperiencesList)
                })
            })
            .catch(() => {
                this.setState({hasMoreItems: false})
            })
    }

    render() {
        let {experiences,delete_experiences_mode,sidebar_visible} = this.props,
            {show_without_resources,searchTerm,current_count,total_count,hasMoreItems} = this.state;
        const keys_to_filters = ['name', 'description','tags'],
            filteredExperiences = (experiences && experiences.length > 0) ? experiences.filter(createFilter(this.state.searchTerm, keys_to_filters)) : [];
        let overflowHorizontalOptions = {overflowBehavior : {x : "scroll", y : "hidden"}};
        return(
            <ErrorBoundaryContainer>
                <div className="experiences-wrap">
                    <TopPanel {...this.props} searchUpdated={this.searchUpdated} resetSelectedParentState={this.resetSelectedExperiences}
                              show_without_resources={show_without_resources} handleInputChange={this.handleInputChange}
                              handleResetFilters={this.handleResetFilters} searchTerm={searchTerm} sidebar_visible={sidebar_visible}
                              deleteSelectedExperiencesFromState={this.deleteSelectedExperiencesFromState} current_count={current_count}
                              total_count={total_count} isVR title={'VR Experiences'}/>
                    <CustomScrollBar className="split-screen__bottom experiences-list" options={overflowHorizontalOptions}>
                        <InfiniteScrollContainer isHorizontal loadMore={this.loadMoreExperiences} hasMoreItems={hasMoreItems}
                                                 classes="row flex-nowrap experiences-list-top-offset" hasFilter={show_without_resources}>
                            {filteredExperiences.map((experience_item, i) => {
                                return <ExperienceCard experience_item={experience_item} key={i} callbackCheck={this.callbackCheck}
                                                       deleteMode={delete_experiences_mode}
                                                       setCurrentExperience={this.props.setCurrentExperience}/>
                            })}
                        </InfiniteScrollContainer>
                    </CustomScrollBar>

                </div>
            </ErrorBoundaryContainer>


        )
    }
}

const mapStateToProps = state => ({
    experiences: state.experiences.experiences,
    experience_item: state.experiences.experience_item,
    selected_delete_experiences: state.experiences.selected_delete_experiences,
    delete_experiences_mode: state.experiences.delete_experiences_mode,
    loading: state.user.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getExperiences,
    setLoading,
    deleteSelectedExperiences,
    setDeleteExperiencesMode,
    setSelectedDeleteExperiences,
    setDeleteExperienceModalStatus,
    setCurrentScene,
    setCurrentElement,
    createExperience,
    setSidebarToggle,
    setCurrentExperience,
    clearExperiences
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(VirtualExperiencesList));
