import React from 'react';
import {withRouter} from "react-router-dom";
import Avatar from "../../components/Avatar";

function BusinessAvatar (props) {
    const uploadAvatar = (setAvatar,file) => {
        props.uploadUserAvatar(file).then(res => {
            setAvatar(res.avatar)
        })
    }

    const deleteAvatar = (setAvatar) => {
        props.deleteUserAvatar().then(res => {
            setAvatar(undefined)
        })
    }
    return (
        <Avatar uploadAvatar={uploadAvatar} deleteAvatar={deleteAvatar} avatar={props.user_info.avatar}/>
    )
}

export default withRouter(BusinessAvatar);
