import React from 'react'
import {
    addResourceImage,
    addResourceImageXHR, addTagsToResource,
    createResource, deleteTagsFromResource,
    getResources, getResourcesDegreeImages,
    getResourcesImages, getNonDegreeResources, setResourceLanguage, setCreateResourcesMode, setCurrentResource,
    updateResourceData, getResourceById
} from "../../../store/resources";
import {setLoading} from "../../../store/user";
import {getValidationRules} from "../../../store/validations";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ReactSVG from "react-svg";
import CloseIcon from "../../../images/close-icon.svg";
import {Nav, Progress, TabContent} from "reactstrap";
// import UploadFileIcon from "../../../images/resource-icon-tabs/upload-file-icon.svg";
import TakePhotoIcon from "../../../images/resource-icon-tabs/take-photo-icon.svg";
import TakeVideoIcon from "../../../images/resource-icon-tabs/take-video-icon.svg";
import RecordAudioIcon from "../../../images/resource-icon-tabs/record-audio-icon.svg";
import WriteTextIcon from "../../../images/resource-icon-tabs/write-text-icon.svg";
import ModelIcon from "../../../images/resource-icon-tabs/3d-model-icon.svg";
import UrlIcon from "../../../images/resource-icon-tabs/url-icon.svg";
import CreateAnimationIcon from "../../../images/resource-icon-tabs/create-animation-icon.svg";
import {resourceTypes, getLimit, languages, mediaTypes, packageOptions, isGltf, isZip, isGlb} from "../../../common";
import * as _ from "lodash";
import CreateMediaResourceTab from "./CreateMediaResourceTab";
import CreateTextResourceTab from "./CreateTextResourceTab";
import CreateUrlResourceTab from './CreateUrlResourceTab';
import UploadFileResourceTab from "./UploadFileResourceTab";
import ResourceNavtab from "./ResourceNavtab";
import UploadAnimationResourceTab from "./UploadAnimationResourceTab";
import {Trans, withTranslation} from "react-i18next";



class CreateResource extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            activeTab:2,
            name: '',
            name_invalid: false,
            file: '',
            tags:[],
            pending: false,
            recorded_data: undefined,
            language: props.main_content_language,
            gif: undefined,
            multimedia: undefined,
            text_content:undefined,
            file_text:undefined,
            type_id: resourceTypes.image
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        let selected_language = nextProps.resource_language;
        let language = nextProps.main_content_language;
        if(selected_language !== nextProps.main_content_language){
            language = selected_language
        }
        if(language !== prevState.language){
            update.language = language
        }
        if(!language) {
            nextProps.setResourceLanguage('en')
        }
        return Object.keys(update).length ? update : null;
    }

    closeCreateResource = () => {
        this.props.setCurrentResource(undefined)
        this.props.setCreateResourcesMode(false)
    }

    handleToggle = (activeTab,type_id) => {
        this.setState({activeTab,file: '',name: '',name_invalid: false,recorded_data: undefined,type_id})
    }

    handleSubmit = (values) => {
        let {name,text_content,language,gif, multimedia,file_text,type_id} = values;
        let $type_id = type_id;
        
        let file = file_text ? file_text : values.file;
        let newResourceData = text_content ? {name,text_content} : {name};
        let resource_updated = {
            translations: {
                [language]: newResourceData
            },
        };

        if($type_id === 10) {
            resource_updated.type_id = $type_id;
        }
        let isMultimediaAnimation = gif && multimedia;
        this.setState({pending:true})
        this.props.createResource(resource_updated,language).then((data) => {
            if(file){
                if(isGlb(file) || isGltf(file)){
                    $type_id = resourceTypes.model;
                }else if(isZip(file)) $type_id = resourceTypes.zip;
                this.props.addResourceImageXHR(file, data.id,false,$type_id,language)
                    .then(() => {
                        this.getSingleResource(data.id)
                    })
            }else if (isMultimediaAnimation){
                this.props.addResourceImageXHR(gif, data.id,false, type_id,language)
                    .then(() => {
                        this.props.addResourceImageXHR(multimedia, data.id,true,type_id,language)
                            .then(() => {
                                this.getSingleResource(data.id)
                            })
                    })
            }else {
                this.getSingleResource(data.id)
            }
        })
    }

    getSingleResource = (id) => {
        this.props.getResourceById(id).then((created_resource) => {
            this.props.setCurrentResource(created_resource)
            this.setState({pending:false})
            this.props.setCreateResourcesMode(false)
        })
    }

    selectResourceLanguage = (name,value) => {
        this.props.setResourceLanguage(_.find(languages,['id',value]).short_name)
    }



    render() {
        let {activeTab,pending} = this.state;
        let {create_resources_mode,progress_percent,user_info,t,main_content_language} = this.props;
        let {business_package} = user_info;
        let business_options = business_package.options;
        let text_resource_limit = getLimit(business_options,packageOptions.text);
        let isAnimationDisabled = process.env.REACT_APP_DISABLE_ANIMATION_RESOURCES === 'true';

        return (
            <div className="element-half-section equal">
                <div className="row subheader">
                    <div className="col-md-10 d-flex align-items-center">
                        <span className="subheader__title"><Trans>Add Resource</Trans></span>
                    </div>
                    <div className="col-md-2 d-flex justify-content-end">
                        <button type="button" className="btn btn-close" onClick={this.closeCreateResource}>
                            <ReactSVG className="btn-small-round__icon" src={CloseIcon}/>
                        </button>
                    </div>
                </div>
                {pending && progress_percent > 0  &&
                <div><p className="title progress-text mb-4"><Trans>Please wait, resource is uploading...</Trans></p>
                    <Progress animated value={progress_percent} />
                </div>}
                {!pending && <Nav tabs className='nav nav-tabs tabs-primary tabs-round tabs-icon'>
                    {/*<ResourceNavtab data_tooltip={'Upload File'} activeTab={activeTab} tabId={1} handleToggle={this.handleToggle} icon={UploadFileIcon}/>*/}
                    <ResourceNavtab data_tooltip={t('Capture Photo')} activeTab={activeTab} tabId={2} handleToggle={this.handleToggle} icon={TakePhotoIcon}
                                    type_id={resourceTypes.image}/>
                    <ResourceNavtab data_tooltip={t('Capture Video')} activeTab={activeTab} tabId={3} handleToggle={this.handleToggle} icon={TakeVideoIcon}
                                    type_id={resourceTypes.video}/>
                    <ResourceNavtab data_tooltip={t('Record Audio')} activeTab={activeTab} tabId={4} handleToggle={this.handleToggle} icon={RecordAudioIcon}
                                    type_id={resourceTypes.audio}/>
                    {text_resource_limit && <ResourceNavtab data_tooltip={t('Text')} activeTab={activeTab} tabId={5} handleToggle={this.handleToggle} icon={WriteTextIcon}
                                    type_id={resourceTypes.text}/>}
                    <ResourceNavtab data_tooltip={t('Upload 3D model')} activeTab={activeTab} tabId={7} handleToggle={this.handleToggle} icon={ModelIcon}
                                    type_id={resourceTypes.model}/>
                    {!isAnimationDisabled && <ResourceNavtab data_tooltip={t('Create Animation')} activeTab={activeTab} tabId={6} handleToggle={this.handleToggle} icon={CreateAnimationIcon}
                                                             type_id={resourceTypes.animations}/>}
                    <ResourceNavtab data_tooltip={t('Add URL')} activeTab={activeTab} tabId={8} handleToggle={this.handleToggle} icon={UrlIcon}
                                    type_id={resourceTypes.url}/> 
                </Nav>}
                {!pending && <TabContent activeTab={activeTab} className="resources-section">
                    {/*<UploadFileResourceTab create_resources_mode={create_resources_mode} initialValues={this.state}*/}
                    {/*                    validation_rules={this.props.validation_rules}*/}
                    {/*                    handleSubmit={this.handleSubmit} tabId={1} resource_type_id={resourceTypes.model}/>*/}
                    <CreateMediaResourceTab create_resources_mode={create_resources_mode} initialValues={this.state} id="photo-file"
                                         validation_rules={this.props.validation_rules} media_type={mediaTypes.image}
                                         handleSubmit={this.handleSubmit} getRecordedData={this.getRecordedData}
                                         tabId={2} activeTab={activeTab} main_content_language={main_content_language}
                                         selectResourceLanguage={this.selectResourceLanguage}/>
                    <CreateMediaResourceTab create_resources_mode={create_resources_mode} initialValues={this.state} id="video-file"
                                         validation_rules={this.props.validation_rules} media_type={mediaTypes.video}
                                         handleSubmit={this.handleSubmit} getRecordedData={this.getRecordedData}
                                         tabId={3} activeTab={activeTab} selectResourceLanguage={this.selectResourceLanguage}/>
                    <CreateMediaResourceTab create_resources_mode={create_resources_mode} initialValues={this.state} id="audio-file"
                                         validation_rules={this.props.validation_rules} media_type={mediaTypes.audio}
                                         handleSubmit={this.handleSubmit} getRecordedData={this.getRecordedData}
                                         tabId={4} activeTab={activeTab} selectResourceLanguage={this.selectResourceLanguage}/>
                    {text_resource_limit && <CreateTextResourceTab create_resources_mode={create_resources_mode} initialValues={this.state}
                                           validation_rules={this.props.validation_rules}
                                           handleSubmit={this.handleSubmit}
                                           tabId={5} media_type={mediaTypes.text}
                                           selectResourceLanguage={this.selectResourceLanguage}/>}
                    <CreateUrlResourceTab create_resources_mode={create_resources_mode} initialValues={this.state}
                                           validation_rules={this.props.validation_rules}
                                           handleSubmit={this.handleSubmit}
                                           tabId={8} media_type={mediaTypes.url}
                                           selectResourceLanguage={this.selectResourceLanguage}/>
                    <UploadFileResourceTab create_resources_mode={create_resources_mode} initialValues={this.state}
                                        validation_rules={this.props.validation_rules} media_type={mediaTypes.model}
                                        handleSubmit={this.handleSubmit} tabId={7} resource_type_id={resourceTypes.model}
                                        selectResourceLanguage={this.selectResourceLanguage}/>
                   {!isAnimationDisabled && <UploadAnimationResourceTab create_resources_mode={create_resources_mode} initialValues={this.state}
                                        validation_rules={this.props.validation_rules} handleSubmit={this.handleSubmit}
                                        tabId={6} resource_type_id={resourceTypes.animations} selectResourceLanguage={this.selectResourceLanguage}/>}
                </TabContent>}
            </div>
        );
    }
}


const mapStateToProps = state => ({
    resource_item: state.resources.resource_item,
    resources: state.resources.resources,
    show_resource_modal: state.resources.show_resource_modal,
    progress_percent: state.resources.progress_percent,
    create_resources_mode: state.resources.create_resources_mode,
    validation_rules: state.validations,
    user_info: state.user.user_info,
    main_content_language: state.user.main_content_language,
    resource_language: state.resources.resource_language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    updateResourceData,
    getResources,
    getResourcesImages,
    createResource,
    addResourceImage,
    addResourceImageXHR,
    setCurrentResource,
    setLoading,
    setCreateResourcesMode,
    getValidationRules,
    addTagsToResource,
    deleteTagsFromResource,
    getResourcesDegreeImages,
    getNonDegreeResources,
    setResourceLanguage,
    getResourceById
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(CreateResource));
