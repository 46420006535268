import React from 'react';
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import ResetPassword from "./ResetPassword";
import SendLink from "./SendLink";
import SignUp from "./SignUp";
import Login from "./Login";
import {checkToken} from "../../store/user";
import PublicRoute from "../PublicRoute";
import SignUpBusiness from "./SignUpBusiness";
import SignUpContractor from "./SignUpContractor";
import TermOfService from "./TermOfService";
import PrivacyPolicy from './PrivacyPolicy';
import DeleteAccount from './DeleteAccount';
class UnauthorizedContainer extends React.PureComponent{

    render() {
        let {language} = this.props.i18n;
        return (
          <Switch>
            <Route
              exact
              path={["/", `/${language}/`, `/${language}`]}
              render={() =>
                !checkToken() ? (
                  <Redirect to={`/${language}/login`} />
                ) : (
                  <Redirect to={`/`} />
                )
              }
            />
            <PublicRoute
              authed={checkToken()}
              path={[`/${language}/login`, "/login"]}
              component={Login}
              componentProps={this.props}
            />

            <PublicRoute
              authed={checkToken()}
              path={[`/${language}/register`, "/register"]}
              component={SignUp}
              componentProps={this.props}
            />

            <PublicRoute
              authed={checkToken()}
              path={[
                `/${language}/register-contractor`,
                "/register-contractor",
              ]}
              component={SignUpContractor}
              componentProps={this.props}
            />

            <PublicRoute
              authed={checkToken()}
              path={[`/${language}/register-museum`, "/register-museum"]}
              component={SignUpBusiness}
              componentProps={this.props}
            />

            <PublicRoute
              authed={checkToken()}
              path={[`/${language}/forgot-password`, "/forgot-password"]}
              component={SendLink}
              componentProps={this.props}
            />

            <PublicRoute
              authed={checkToken()}
              path={[
                `/${language}/reset-password-:token-:email`,
                "/reset-password-:token-:email",
              ]}
              component={ResetPassword}
              componentProps={this.props}
            />

            <PublicRoute
              authed={checkToken()}
              path={[`/${language}/term-of-service`, "/term-of-service"]}
              component={TermOfService}
              componentProps={this.props}
            />
            <PublicRoute
              authed={checkToken()}
              path={[`/${language}/privacy-policy`, "/privacy-policy"]}
              component={PrivacyPolicy}
              componentProps={this.props}
            />
            <PublicRoute
              authed={checkToken()}
              path={[`/${language}/delete-account`, "/delete-account"]}
              component={DeleteAccount}
              componentProps={this.props}
            />
          </Switch>
        );
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({

},dispatch)

export default withTranslation('translations')(withRouter(connect(mapStateToProps,mapDispatchToProps)(UnauthorizedContainer)));
