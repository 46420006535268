import React from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import ResourcesTabs from "../../resource/ResourcesTabs";
import {setLoading} from "../../../store/user";
import {
    addIdentifierToElement,
    clearIdentifiers,
    getIdentifiers,
    getDigitalPrints,
    setIdentifier, setLinkResourceToSubElementMode, setSubElement
} from "../../../store/elements";
import {getValidationRules} from "../../../store/validations";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import IdentifierDataList from "../../identifier/IdentifierDataList";
import DigitalPrintsList from '../../digitalprints/DigitalPrintsList';
import CreateResourceButton from "../../buttons/CreateResourceButton";
import DeleteResourcesButton from "../../buttons/DeleteResourcesButton";
import CreateIdentifierButton from "../../buttons/CreateIdentifierButton";
import CreateDigitalPrintButton from '../../buttons/CreateDigitalPrintButton';
import ResourceFiltersDropdown from "../../ResourceFiltersDropdown";
import ResourceSearchDropdown from "../../ResourceSearchDropdown";
import {Trans} from "react-i18next";
import AttentionIcon from '../../../images/attention-icon.svg'
import CloseIcon from '../../../images/close-icon.svg'
import ReactSVG from "react-svg";


class ResourcesAndIdentifiers extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            activeTab:1,
            language: undefined,
            show_paired_resources:false,
            show_sub_linked_resources:false,
            searchTerm: '',
            hasMoreIdentifiers:true,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(nextProps.link_resources_to_sub_elements_mode){
            update.activeTab = 2;
        }
        if(prevState.activeTab === 1 && nextProps.sub_element){
            update.activeTab = 2;
        }
        return Object.keys(update).length ? update : null;
    }

    handleToggle = (activeTab) => {
        this.props.setLinkResourceToSubElementMode(false,undefined)
        this.props.setSubElement(undefined,undefined)
        this.setState({activeTab})
    }

    searchUpdated = (term) => {
        this.setState({searchTerm: term})
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    handleLanguageChange = (name, value) => {
        this.setState({
            [name]: value,
        });
    }

    handleResetFilters = () => {
        this.setState({
            language:undefined,
            show_paired_resources:false,
            show_sub_linked_resources:false,
        });
    }

    renderActionButttons(){
        let {show_paired_resources,language,searchTerm,show_sub_linked_resources} = this.state;
        let {sub_element,sub_elements} = this.props;

        if(this.state.activeTab === 2){
            return(
                <div className="col-md-4 col-12 d-flex flex-row justify-content-md-end justify-content-start">
                    <ResourceFiltersDropdown handleInputChange={this.handleInputChange} handleLanguageChange={this.handleLanguageChange}
                                             language={language} handleResetFilters={this.handleResetFilters}
                                             show_paired_resources={show_paired_resources} show_sub_linked_resources={show_sub_linked_resources}
                                             sub_element={sub_element} sub_elements={sub_elements}/>
                    <ResourceSearchDropdown searchUpdated={this.searchUpdated} searchTerm={searchTerm}/>
                    <DeleteResourcesButton/>
                    <CreateResourceButton />
                </div>
            )
        } else if (this.state.activeTab === 3) {
            return (
              <div className="col-md-4 d-flex flex-row justify-content-md-end justify-content-start">
                {this.props.element_item && <CreateDigitalPrintButton />}
              </div>
            );
          } else {
            return (
              <div className="col-md-4 d-flex flex-row justify-content-md-end justify-content-start">
                {this.props.element_item && <CreateIdentifierButton />}
              </div>
            );
          }
    }


    render() {
        let {activeTab,show_paired_resources,language,searchTerm,show_sub_linked_resources} = this.state;
        let {
          classes,
          link_resources_to_sub_elements_mode,
          identifiers,
          element_item,
          sub_element,
          digital_prints,
        } = this.props;
        return (
          <div className={`element-half-section ${classes}`}>
            <div className="element-half-section__header">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-12 mb-md-0 mb-4">
                  <Nav tabs className="nav nav-tabs tabs-primary tabs-round">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 1 })}
                        onClick={() => {
                          this.handleToggle(1);
                        }}
                      >
                        <Trans>Identifiers</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem className="mb-xl-0 mb-4">
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                          this.handleToggle(2);
                        }}
                      >
                        <Trans>Resources</Trans>
                      </NavLink>
                    </NavItem>
                    <br />
                    <br />
                    <NavItem className="mb-xl-0 mb-4">
                      <NavLink
                        className={classnames({ active: activeTab === 3 })}
                        onClick={() => {
                          this.handleToggle(3);
                        }}
                      >
                        <Trans>Digital Print</Trans>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                {this.renderActionButttons()}
              </div>
            </div>
            <TabContent activeTab={activeTab} className="">
              <TabPane tabId={1} className="tab-pane">
                <IdentifierDataList
                  identifiers={identifiers}
                  element_item={element_item}
                />
              </TabPane>
              <TabPane tabId={2} className="tab-pane">
                {link_resources_to_sub_elements_mode && (
                  <div className="alert-box mb-4">
                    <ReactSVG src={AttentionIcon} />
                    <p className="alert-text">Link resource to sub element</p>
                    <button
                      className="btn alert-box__btn"
                      type="button"
                      onClick={this.props.handleDisableLinkSubelementsMode}
                    >
                      <ReactSVG src={CloseIcon} />
                    </button>
                  </div>
                )}
                {!link_resources_to_sub_elements_mode && sub_element && (
                  <div className="alert-box alert-box_transparent mb-4">
                    <p className="alert-text">
                      Note: Resources, linked to the sub element will be
                      highlighted.
                    </p>
                  </div>
                )}
                <ResourcesTabs
                  show_paired_resources={show_paired_resources}
                  language={language}
                  searchTerm={searchTerm}
                  show_sub_linked_resources={show_sub_linked_resources}
                />
              </TabPane>
              <TabPane tabId={3} className="tab-pane">
                <DigitalPrintsList
                  digital_prints={digital_prints}
                  element_item={element_item}
                />
              </TabPane>
            </TabContent>
          </div>
        );
    }
}


const mapStateToProps = state => ({
    element_item: state.elements.element_item,
    validation_rules: state.validations,
    progress_percent: state.elements.progress_percent,
    identifiers: state.elements.identifiers,
    digital_prints:state.elements.digital_prints,
    link_resources_to_sub_elements_mode: state.elements.link_resources_to_sub_elements_mode,
    sub_element: state.elements.sub_element,
    sub_elements: state.elements.sub_elements,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setLoading,
    getValidationRules,
    addIdentifierToElement,
    getIdentifiers,
    clearIdentifiers,
    setIdentifier,
    getDigitalPrints,
    setLinkResourceToSubElementMode,
    setSubElement
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesAndIdentifiers);

