import React from 'react';
import {Trans} from "react-i18next";

function ElementInfo(props) {
  let { dashboard_info, model, toggleUpgradePlanModal } = props;

  if (!dashboard_info) return null;
  let elements = model === "element" ? dashboard_info.total_elements : "";
  let published_elements =
    model === "element" ? dashboard_info.published_elements : "";
  let fav_name = dashboard_info.favourite_element_name;
  return (
    <div className="col-md-12 col-sm-12 profile-col">
      <div className="profile-section profile-section__accent overflow-hidden">
        <div className="row">
          <div className="col-md-4 col-sm-12 profile-col">
            <div className="profile-section__title-bold">
              <Trans>Total Active Elements</Trans>{" "}
            </div>
            <div className="profile-section__body">
              <div
                className="profile-section__small-text"
                style={{ fontSize: "25px" }}
              >
                {elements}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 profile-col">
            <div className="profile-section__title-bold">
              <Trans>Total Published Elements</Trans>{" "}
            </div>
            <div className="profile-section__body">
              <div
                className="profile-section__small-text"
                style={{ fontSize: "25px" }}
              >
                {published_elements}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 profile-col">
            <div className="profile-section__title-bold">
              <Trans>FAVOURITE ELEMENT</Trans>{" "}
            </div>
            <div className="profile-section__body">
              <div
                className="profile-section__small-text"
                style={{ fontSize: "25px" }}
              >
                {fav_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ElementInfo;