import React from 'react';
import {NavItem, NavLink} from "reactstrap";
import classnames from "classnames";
import {Trans} from "react-i18next";
import {resourceTypes} from "../../common";

function ResourceNavTab(props) {
    let {tabId,tabType,handleToggle,title,activeTab} = props;
    let isAnimationDisabled = process.env.REACT_APP_DISABLE_ANIMATION_RESOURCES === 'true';
    if(isAnimationDisabled && tabId === resourceTypes.animations) return null;
    return(
        <NavItem>
            <NavLink
                className={classnames({ active: tabId === activeTab })}
                onClick={() => handleToggle(tabId,tabType)}><Trans>{title}</Trans></NavLink>
        </NavItem>
    )
}

export default ResourceNavTab;