import React, {Fragment} from "react";
import ReactSVG from "react-svg";
import CloseIcon from "../../../images/close-icon.svg";
import Sidebar from "../../../components/sidebar/Sidebar";
import {getJobStatusModificator, sidebarTypes} from "../../../common";
import {Link} from "react-router-dom";
import Status from "../Status";
import {jobStatuses} from "./JobCard/JobCard";
import JobFiles from "./JobFiles";
import moment from 'moment';
import {Trans} from 'react-i18next';
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";

function JobViewSidebar(props) {
    if(!props.job_item) return null;
    let name = props.job_item && props.job_item.status_id ? jobStatuses.find(status => status.id === props.job_item.status_id).name : '';
    let overflowVerticalOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
    let $completedStatus = jobStatuses.find(item => item.name === 'Completed').id;
    let $assignedStatus = jobStatuses.find(item => item.name === 'Assigned').id;
    let isCompleted = props.job_item.status_id === $completedStatus;
    let isAssigned = props.job_item.status_id === $assignedStatus;
    let $openStatus = jobStatuses.find(item => item.name === 'Open for bidding').id;
    return(
        <ErrorBoundaryContainer>
            <Sidebar show={props.sidebar_type === sidebarTypes.view_job}
                     scrollOptions={overflowVerticalOptions}
                     {...props}
                     header={<Fragment>
                         <span className="sidebar__header-title"><Trans>View job</Trans></span>
                         <div className="sidebar__header-buttons">
                             <button type="button" className="btn btn-close" onClick={() => props.setSidebar(sidebarTypes.none)}>
                                 <ReactSVG src={CloseIcon} />
                             </button>
                         </div>
                     </Fragment>}
                     footer={
                         <>
                             {props.job_item && !props.hideButton && !props.job_item.has_bid && !isCompleted &&
                             <Link to={`/create-bid-for-job-${props.job_item.id}`}
                                   className="btn btn-primary w-100"
                                 // disabled={props.formik.isSubmitting}
                                   loading={props.pending}><Trans>Create a bid</Trans></Link>}
                             {props.job_item && !props.hideButton &&
                             ((props.job_item.has_bid && isAssigned) || isCompleted) &&
                             <Link to={`/bid-details-for-job-${props.job_item.id}`}
                                   className="btn btn-primary w-100"
                                 // disabled={props.formik.isSubmitting}
                                   loading={props.pending}><Trans>View details</Trans></Link>}

                         </>
                     }
                     wide
            >
                {props.job_item && <div className="sidebar__inner-offset">
                    <div className="sidebar__job-title">{props.job_item.title}</div>
                    <div className="sidebar__job-description">{props.job_item.description}</div>

                    <div className="row">
                        <div className="col-6">
                            <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free">
                                <Trans>Current status</Trans>
                            </div>
                            {props.job_item && <Status name={(props.job_item.has_bid && $openStatus === props.job_item.status_id) ? 'Bid sent' : name} isBig
                                                       modificator={getJobStatusModificator(props.job_item.status_id)}/>}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free">
                                <Trans>Required resource type</Trans>
                            </div>
                            <div className="sidebar__header-title">{props.job_item.resource_type_id}</div>
                        </div>
                        <div className="col">
                            <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free"><Trans>Languages</Trans></div>
                            <div className="bid-card__header-item-content">
                                {props.job_item.required_languages && props.job_item.required_languages.map((lng) =>
                                    <span className="bid-card__header-lng" key={`lang-${lng}`}> {lng} </span>)}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        {!process.env.REACT_APP_HIDE_JOB_BUDGET && <div className="col-6">
                            <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free">
                                <Trans>Budget</Trans></div>
                            <div className="sidebar__header-title">{props.job_item.max_bid}</div>
                        </div>}
                        <div className={!process.env.REACT_APP_HIDE_JOB_BUDGET ? "col-6" : "col-12"}>
                            <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free"><Trans>Due date</Trans></div>
                            <div
                                className="sidebar__header-title">{moment(props.job_item.due_at).format('MM/DD/YYYY')}</div>
                        </div>
                    </div>
                    {props.job_attachments && <JobFiles t={props.t}
                                                        job_attachments={props.job_attachments}
                                                        readOnly
                                                        title={'Visual expectations'}
                                                        titleClass="sidebar__title_bold"
                                                        showFullSizeGallery={props.showFullSizeGallery}
                                                        job_id={props.job_item.id}/>}
                </div>}
            </Sidebar>
        </ErrorBoundaryContainer>
    )
}

export default JobViewSidebar;
