import React from 'react';
import {getJobStatusModificator} from "../../common";
import Status from "./Status";
import {jobStatuses} from "./jobs/JobCard/JobCard";

function WorkHistoryItem(props) {
    let {title,status_id} = props.item_info;
    let name = status_id ? jobStatuses.find(status => status.id === status_id).name : '';
    return(
        <div className="work-history-item">
            <div className="work-history-item__header">
                <div className="work-history-item__title">{title}</div>
                <Status name={name} modificator={getJobStatusModificator(status_id)}/>
            </div>
            <div className="work-history-item__date">April 12, 2021 - May 29, 2021</div>
        </div>
    )
}

export default WorkHistoryItem;
