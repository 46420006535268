import React from "react";
import CardsList from "../CardsList";
import CustomScrollBar from "../../../components/CustomScrollBar";
import InfiniteScrollContainerNew from "../../../components/InfiniteScrollContainerNew";
//import {userRoles} from "../../../common";
import OrderCard, { orderStatuses } from "./OrderCard";

function OrdersList(props) {
    if(!props.user_info) return null;
    let overflowVerticalOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
    
    const getItems = (newPage) => {
        let getItems = props.getOrders;
        let $submittedStatusId = orderStatuses.find(
          (bid) => bid.name === "Submitted"
        ).id;
        let user_id = props.user_info.id;
        let business_id = props.user_info.business_id;

        return new Promise((resolve,reject) => {
            getItems({user_id,business_id, page: newPage, status_id: props.onlySubmitted && $submittedStatusId})
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        })
    }

    return (
      <CustomScrollBar
        className="cards-list-offset"
        options={overflowVerticalOptions}
      >
        <InfiniteScrollContainerNew getItems={getItems}>
          <CardsList
            ItemComponent={OrderCard}
            items={props.orders}
            {...props}
          />
        </InfiniteScrollContainerNew>
      </CustomScrollBar>
    );
}

export default OrdersList;
