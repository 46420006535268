//Digital Print item for element. Using on Digital Print data page
import React from 'react';
import CustomScrollBar from "../CustomScrollBar";
import ReactSVG from "react-svg";
import CloseIcon from "../../images/close-icon.svg";
import {Field, Formik} from "formik";
import {getValidationRules, setDigitalPrintSchema} from "../../store/validations";
import FileUploader from "../FileUploader";
import {isImage, elementTypes} from "../../common";
import {setLoading} from "../../store/user";
import {
  addDigitalPrintToElement,
  clearDigitalPrints,
  getElementItemById,
  getDigitalPrints,
  setCreateDigitalPrintMode,
  setDigitalPrint,
} from "../../store/elements";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import videoPreview from "../../images/audio-preview.png";
import {Progress} from "reactstrap";
import {Trans, withTranslation} from "react-i18next";


class CreateDigitalPrints extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            type: 1,
            name:'',
            description:'',
            price:'',
            quantity:'',
            file: undefined,
            preview_url:undefined,
            digital_prints: [],
            emptyFileUploader: undefined,
            pending:false
        }

    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if (nextProps.digital_prints !== prevState.digital_prints) {
          update.digital_prints = nextProps.digital_prints;
        }
        return Object.keys(update).length ? update : null;
    }

    handleSubmit = (values) => {
        let { element_item, addDigitalPrintToElement } = this.props,
          { name, description, file, price, quantity } = values;
        if(file){
            this.setState({file,pending:true})
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    preview_url: isImage(file) ? reader.result : videoPreview,
                });
            };
            reader.readAsDataURL(file);
            addDigitalPrintToElement(element_item.id, name, description, file, price, quantity).then(() => {
              let digital_prints_state = this.state.digital_prints;
              console.log(digital_prints_state);
              // this.setState({
              //   digital_prints: digital_prints_state.unshift({
              //     name: file.name,
              //     preview_url: this.state.preview_url,
              //     format: file.format,
              //   }),
              //   file: undefined,
              //   preview_url: undefined,
              //   pending: false,
              // });
              this.props.getElementItemById(this.props.element_item.id);
              this.props.getDigitalPrints(this.props.element_item.id);
              this.props.setDigitalPrint(undefined);
              this.props.setCreateDigitalPrintMode(false);
            });
        }
    }

    closeCreateDigitalPrint = () => {
        this.props.setDigitalPrint(undefined)
        this.props.setCreateDigitalPrintMode(false);
    }

    render() {
        let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}}
        const {element_item,progress_percent,t} = this.props;
        const {pending} = this.state;
        if(!element_item) return null;
        let is2dElement = element_item.type_id === elementTypes.element2d;
        let helpText = is2dElement ? 'Supported Image Formats are .jpg / .jpeg and .png' : 'Supported Video Formats are .mp4 and .mov';
        return (
            <div className="element-half-section">
                <div className="row subheader">
                    <div className="col-md-10 d-flex align-items-center">
                        <span className="subheader__title"><Trans>Add Digital Print</Trans></span>
                        {/* <SmallRoundButton icon={InfoIcon} classes={'ml-md-4 ml-sm-0 btn-small-round_dark'}/> */}
                    </div>
                    <div className="col-md-2 d-flex justify-content-end">
                        <button type="button" className="btn btn-close" onClick={this.closeCreateDigitalPrint}>
                            <ReactSVG className="btn-small-round__icon" src={CloseIcon}/>
                        </button>
                    </div>
                </div>
                {pending && progress_percent > 0  &&
                <div><p className="title mb-4 progress-text"><Trans>Please wait, digital print is uploading...</Trans></p>
                    <Progress animated value={progress_percent} />
                </div>}
                {!pending && <CustomScrollBar options={overflowOptions}>
                    <div className="row mb-5">
                        <div className="col">
                            <div className="training__details">
                                <p className="training__details-title">{helpText}</p>
                                {<Formik
                                    enableReinitialize
                                    initialValues={this.state}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            this.handleSubmit(values);
                                            setSubmitting(false)
                                        }, 500);
                                    }}
                                    validationSchema={setDigitalPrintSchema(this.props.validation_rules, element_item.type_id,t)}
                                >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            setFieldValue
                                        } = props;
                                        
                                        let name_invalid_class = errors.name && touched.name ? "invalid" : "";
                                        let description_invalid_class =
                                          errors.description &&
                                          touched.description
                                            ? "invalid"
                                            : "";
                                        let price_invalid_class = errors.price && touched.price ? "invalid" : "";
                                        let quantity_invalid_class = errors.quantity && touched.quantity ? "invalid" : "";

                                        return (
                                          <form
                                            onSubmit={handleSubmit}
                                            name="digital-print-form"
                                          >
                                            <input
                                              id="text-resource-name"
                                              name="name"
                                              placeholder={t(`Enter Name`)}
                                              type="text"
                                              value={values.name}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              className={`form-control input-primary d-block ${name_invalid_class}`}
                                            />
                                            {errors.name && touched.name && (
                                              <div className="input-feedback">
                                                {errors.name}
                                              </div>
                                            )}
                                            <br/>  
                                            <textarea
                                              id="text-resource-name"
                                              name="description"
                                              placeholder={t(
                                                `Enter Description`
                                              )}
                                              type="text"
                                              value={values.description}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              className={`form-control input-primary d-block ${description_invalid_class}`}
                                            />
                                            {errors.description &&
                                              touched.description && (
                                                <div className="input-feedback">
                                                  {errors.description}
                                                </div>
                                              )}
                                            <br />
                                            <Field
                                              name="file"
                                              component={FileUploader}
                                              setFieldValue={setFieldValue}
                                              errorMessage={
                                                errors["file"]
                                                  ? errors["file"]
                                                  : undefined
                                              }
                                              touched={touched["file"]}
                                              onBlur={handleBlur}
                                              classes={
                                                "text-lg-center text-left"
                                              }
                                              progress_percent={
                                                progress_percent
                                              }
                                            />
                                            <br />
                                            <input
                                              name="price"
                                              placeholder={t(
                                                "Value Of Digital Print"
                                              )}
                                              type="text"
                                              value={values.price}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              className={`form-control input-primary d-block ${price_invalid_class}`}
                                            />
                                            {errors.price && touched.price && (
                                              <div className="input-feedback">
                                                {errors.price}
                                              </div>
                                            )}
                                            <br />
                                            <input
                                              name="quantity"
                                              placeholder={t(
                                                "Number Of Available Digital Print"
                                              )}
                                              type="text"
                                              value={values.quantity}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              className={`form-control input-primary d-block ${quantity_invalid_class}`}
                                            />
                                            {errors.quantity &&
                                              touched.quantity && (
                                                <div className="input-feedback">
                                                  {errors.quantity}
                                                </div>
                                              )}
                                            <br />
                                            <button
                                              type="submit"
                                              className="btn btn-primary"
                                              disabled={isSubmitting}
                                            >
                                              <Trans>Submit</Trans>
                                            </button>
                                          </form>
                                        );
                                    }}
                                </Formik>}
                            </div>
                        </div>
                    </div>
                </CustomScrollBar>}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    element_item: state.elements.element_item,
    validation_rules: state.validations,
    progress_percent: state.elements.progress_percent,
    digitalprints: state.elements.digitalprints,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoading,
      getValidationRules,
      addDigitalPrintToElement,
      getDigitalPrints,
      clearDigitalPrints,
      setDigitalPrint,
      setCreateDigitalPrintMode,
      getElementItemById,
    },
    dispatch
  );

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(CreateDigitalPrints)
);


