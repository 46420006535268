//Search location with google map component
//Was removed, now not using
import React, { Component, Fragment } from 'react';
import isEmpty from 'lodash.isempty';

import Marker from './Marker';

import GoogleMap from './GoogleMap';
import AutoComplete from './AutoCompleteLocation';
import {connect} from "react-redux";
import LocationIcon from '../../images/location.svg'
import ReactSVG from "react-svg";
import {getCity, getCountry, getState} from "../../common";


class Autocomplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mapApiLoaded: false,
            mapInstance: null,
            mapApi: null,
            places: [],
            isSearchFocused:false,
            coordinates: [34.0522, -118.2437]
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(nextProps.location){
            let {longitude,latitude} = nextProps.location;
            if(longitude && latitude){
                update.coordinates = [latitude, longitude];
            }
            if(prevState.mapInstance){
                prevState.mapInstance.setZoom(17)
                prevState.mapInstance.setCenter(new prevState.mapApi.LatLng(latitude,longitude))
            }
        }else{
            if(nextProps.experience_item || nextProps.item){
                let {location} = nextProps.experience_item ? nextProps.experience_item : nextProps.item;
                if(location) {
                    let {longitude,latitude} = nextProps.item.location;
                    update.coordinates = [latitude, longitude];
                }
            }

        }
        return Object.keys(update).length ? update : null;
    }

    componentDidMount() {
        let map = document.getElementById('google-map');
        if(map) {
            map.addEventListener('mousedown', (e) => {
                e.preventDefault()
            })
        }
    }
    componentWillUnmount(){
        let map = document.getElementById('google-map');
        if(map){
            map.removeEventListener("mousedown",(e) => {
                e.preventDefault()
            });
        }
    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });
    };

    addPlace = (place,location) => {
        this.setState({ places: [place] });
        this.props.changePlace(location)

    };

    handleSearchFocus = (event) => {
        let eventType = event.type;
        let isSearchFocused = eventType === 'focus';
        this.setState({isSearchFocused})
    }

    getCurrentLoccation = () => {
        const {mapInstance, mapApi} = this.state;

        navigator.geolocation.getCurrentPosition(
            success => {
                let geocoder = new mapApi.Geocoder();
                let LatLng = new mapApi.LatLng(success.coords.latitude,success.coords.longitude);
                mapInstance.setCenter(new mapApi.LatLng(success.coords.latitude,success.coords.longitude));
                mapInstance.setZoom(17);
                geocoder.geocode({ location:  LatLng}, (results, status) => {
                    let location = {
                        latitude: success.coords.latitude,
                        longitude: success.coords.longitude,
                        address_line_1: results[0].formatted_address,
                        country: getCountry(results[0]),
                        state: getState(results[0]),
                        city: getCity(results[0]),
                    }
                    this.props.changePlace(location)
                });
            }
        )
    }

    render() {
        const {
            places, mapApiLoaded, mapInstance, mapApi,
        } = this.state;
        return (
            <Fragment>
                {mapApiLoaded && (
                    <div className="profile__location-input">
                        <AutoComplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace}
                                      handleSearchFocus={this.handleSearchFocus} onBlurSave={this.props.onBlurSave}
                                      defaultValue={this.props.location ? this.props.location.address_line_1 : ''}/>
                        <button className="btn location-button" type="button" onClick={this.getCurrentLoccation}>
                            <ReactSVG src={LocationIcon}/>
                        </button>
                    </div>
                )}
                <GoogleMap
                    defaultZoom={10}
                    center={this.state.coordinates}
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_API_KEY,
                        libraries: ['places', 'geometry'],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                    isSearchFocused={true}
                    classes={"google-map-container_fullsize"}
                >
                    {!isEmpty(places) &&
                    places.map(place => (
                        <Marker
                            key={place.id}
                            text={place.name}
                            lat={place.geometry.location.lat()}
                            lng={place.geometry.location.lng()}
                        />
                    ))}
                    {this.props.location &&
                    <Marker
                        key={this.props.location.address_line_1}
                        text={'custom-marker'}
                        lat={this.props.location.latitude}
                        lng={this.props.location.longitude}
                    />}
                </GoogleMap>


            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    experience_item: state.experiences.experience_item,
});

export default connect(mapStateToProps, null)(Autocomplete);
