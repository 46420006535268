import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {checkToken} from "./store/user";
const options = {
    // order and from where user language should be detected
    order: checkToken() ? ['localStorage'] : ['path','localStorage'],
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    // caches: ['localStorage'],
};

i18n.use(LanguageDetector).init({
    detection: options,
    // we init with resources
    resources: {
        en: {
            translations: {
                "Canvas language": "Canvas language",
                "Russian": "Russian",
                "English": "English",
                "French": "French",
                "Chinese": "Chinese",
                "German": "German",
                "Korean": "Korean",
                "Portuguese": "Portuguese",
                "Arabic":"Arabic",
                "Show only without resources": "Show only without resources",
                "Get Help": "Get Help",
                "Delete Elements": "Delete Elements",
                "Create Element": "Create Element",
                "has {{resources_count}} resources": "has {{resources_count}} resources",
                "has {{trainings_length}} identifiers": "has {{trainings_length}} identifiers",
                "Search": "Search",
                "Edit Element": "Edit Element",
                "Gamification": "Gamification",
                "Statistics": "Statistics",
                "Element": "Element",
                "Views statistics": "Element Statistics",
                "Daily": "Daily",
                "Hourly": "Hourly",
                "The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)": "The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)",
                "The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)": "The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)",
                "Edit": "Edit",
                "Create A": "Create A",
                "quiz": "quiz",
                "Quiz": "Quiz",
                "Please enter question": "Please enter question",
                "Please enter answer explanation": "Please enter answer explanation",
                "Answers": "Answers",
                "Add new answer": "Add new answer",
                "Delete": "Delete",
                "Reset": "Reset",
                "Save": "Save",
                "Create": "Create",
                "Required for publishing an element": "Required for publishing an element",
                "Additional/ Detailed information about the correct answer, that you want the user to know": "Additional/ Detailed information about the correct answer, that you want the user to know",
                "Element Name": "Element Name",
                "Element Description": "Element Description",
                "Element Identifier": "Element Identifier",
                "Unpublish": "Unpublish",
                "Publish": "Publish",
                "Enter Name": "Enter Name",
                "Enter Description": "Enter Description",
                "Resources": "Resources",
                "Identifiers": "Identifiers",
                "Filters": "Filters",
                "Filter": "Filter",
                "Reset filters": "Reset filters",
                "Amount of the gems which user will earn after selecting correct answer": "Amount of the gems which user will earn after selecting correct answer",
                "Please enter points": "Please enter points",
                "Add new tag": "Add New tag",
                "Create resource in this particular language.": "Create resource in this particular language.",
                "Select language": "Select language",
                "Show only linked resources": "Show only linked resources",
                "Searching": "Searching",
                "Reset search": "Reset search",
                "Delete Resource": "Delete Resource",
                "Yes": "Yes",
                "No": "No",
                "Create Resource": "Create Resource",
                "All": "All",
                "Images": "Images",
                "Image": "Image",
                "Videos": "Videos",
                "Audios": "Audios",
                "Texts": "Texts",
                "3D models": "3d models",
                "Animations": "Animations",
                "Select a Resource or identifier to see the details here": "Select a Resource or identifier to see the details here",
                "Create Identifier": "Create Identifier",
                "Link": "Link",
                "Linked": "Linked",
                "Add Resource": "Add Resource",
                "Capture Photo": "Capture Photo",
                "Capture Video": "Capture Video",
                "Record Audio": "Record Audio",
                "Upload 3d model": "Upload 3D model",
                "Text": "Text",
                "Create Animation": "Create Animation",
                "Choose file": "Choose file",
                "Choose File": "Choose File",
                "Enter resource name": "Enter resource name",
                "To use pre existing files as resources, please upload here...": "To use pre existing files as resources, please upload here...",
                "Or create a resource from your device": "Or create a resource from your device",
                "Request Creation": "Request Creation",
                "Note, Please Upload only Audio File": "Note : Please Upload only Audio File",
                "Note, Please Upload only GIF File": "Note : Please Upload only GIF File",
                "Edit Resource": "Edit Resource",
                "Language": "Language",
                "The resource was discovered {{totalWeekCount}} times last week.": "The resource was discovered {{totalWeekCount}} times last week.",
                "The resource was discovered {{totalDayCount}} times last day.": "The resource was discovered {{totalDayCount}} times last day.",
                "from the previous week": "from the previous week",
                "Hourly views": "Hourly views",
                "Daily views": "Daily views",
                "View Identifier": "View Identifier",
                "Active": "Active",
                "Are you sure you want to sign out?": "Are you sure you want to sign out?",
                "Home": "Home",
                "Profile": "Profile",
                "Theme": "Theme",
                "Logout": "Logout",
                "Published": "Published",
                "Draft": "Draft",
                "Status": "Status",
                "more": "more",
                "Element and Resources Primary language": "Element and Resources Primary language",
                "Business information": "Business information",
                "Invite user": "Invite user",
                "Business Name": "Business Name",
                "First Name": "First Name",
                "Last Name": "Last Name",
                "Enter A Location": "Enter A Location",
                "There is your current package.": "There is your current package.",
                "package": "package",
                "Personal information": "Personal information",
                "Upgrade": "Upgrade",
                "Are you sure you want to request creation?": "Are you sure you want to request creation?",
                "Select role": "Select role",
                "Bussiness members": "Bussiness members",
                "Invite": "Invite",
                "Invite users to your bussiness": "Invite users to your bussiness",
                "Please Enter Email": "Please Enter Email",
                "Back To Login": "Back To Login",
                "Visit": "Visit",
                "Login into ELIAR": "Login into Canvas",
                "Your potion to create magic!": "Your potion to create magic!",
                "Enter Email": "Enter Username or Email",
                "Enter Password": "Enter Password",
                "Submit": "Submit",
                "Forgot Password": "Forgot Password",
                "LogIn": "LogIn",
                "Login": "Login",
                "Don’t have an account yet?": "Don’t have an account yet?",
                "Register": "Register",
                "now!": "now!",
                "Getting started with ELIAR": "Getting started with Canvas",
                "Enter email": "Enter email",
                "Repeat Password": "Repeat Password",
                "Let’s prepare to <br/>create your accont": "Let’s prepare to <br/>create your accont",
                "It’s only a few clicks away!": "It’s only a few clicks away!",
                "Welcome back to": "Welcome back to",
                "Content Creation platform": "Content Creation platform",
                "Reset password": "Reset password",
                "All Rights Reserved": "All Rights Reserved",
                "Already have an account?": "Already have an account?",
                "Click to get help on Gamification": "Click to get help on Gamification",
                "Add Identifier": "Add Identifier",
                "Please wait, identifier is uploading...": "Please wait, identifier is uploading...",
                "Upload": "Upload",
                "uploaded": "uploaded",
                "Details": "Details",
                "Single": "Single",
                "Resource": "Resource",
                "Up to 10": "Up to 10",
                "elements": "elements",
                "resource per element": "resource per element",
                "Up to 50": "Up to 50",
                "Multiple": "Multiple",
                "Custom": "Custom",
                "Services": "Services",
                "Level up your experience": "Level up your experience",
                "Upgrade now to get full access to eliAR and enhance your experiences": "Upgrade now to get full access to Canvas and enhance your experiences",
                "Need help with selecting a package?": "Need help with selecting a package?",
                "Please contact us": "Please contact us",
                "Exhibit": "Exhibit",//
                "Audio": "Audio",
                "Video": "Video",
                "Gallery": "Gallery",
                "per month / element": "per month / element",
                "per month": "per month",
                "3d models": "3d models",
                "Multimedia Animation": "Multimedia Animation",
                "Basic White Label": "Basic White Label",
                "Museum": "Creator",
                "<0>Custom</0> element": "<0>Custom</0> element",
                "<0>Multiple</0> resource per element": "<0>Multiple</0> resource per element",
                "<0>Custom</0> resource": "<0>Custom</0> resource",
                "<0>Custom</0> Services": "<0>Custom</0> Services",
                "Passcode for the day": "Passcode for the day",
                "Generate Passcode for the day": "Generate Passcode for the day",
                "views": "views",
                "Required": "Required",
                "Too Short!": "Too Short!",
                "Too Long!": "Too Long!",
                "Unsupported Format": "Unsupported Format",
                "File too large": "File too large",
                "Passwords don't match": "Passwords don't match",
                "Password confirm is required": "Password confirm is required",
                "Text of answer is required": "Text of answer is required",
                "Are you sure you want to delete these resources?": "Are you sure you want to delete these resources?",
                "Are you sure you want to delete this resource?": "Are you sure you want to delete this resource?",//
                "Open": "Open",
                "My jobs": "My jobs",
                "Post new job": "Post new job",
                "Post a job": "Post a job",
                "Enter title": "Enter title",
                "Enter job description": "Enter job description",
                "Enter a budget": "Enter a budget",
                "Enter job budget": "Enter job budget",
                "Enter due date": "Enter due date",
                "Current status": "Current status",
                "Required resource type": "Required resource type",
                "Resource type required": "Resource type required",
                "Upload visual expectation": "Upload visual expectation",
                "Assigned to": "Assigned to",
                "Budget": "Budget",
                "Languages": "Languages",
                "Posted {{$created_at}}": "Posted {{$created_at}}",
                "See details": "See details",
                "Edit job": "Edit job",
                "Bids": "Bids",
                "Bid details": "Bid details",
                "Comments": "Comments",
                "Approve job": "Approve job",
                "Cover letter": "Cover letter",
                "Proposed timeline": "Proposed timeline",
                "Proposed budget": "Proposed budget",
                "Open Jobs": "Open Jobs",
                "View job": "View job",
                "Create a bid": "Create a bid",
                "Visual expectations": "Visual expectations",
                "Due date": "Due date",
                "Jobs": "Jobs",
                "My bids": "My bids",
                "Enter cover letter": "Enter cover letter",
                "Language offered": "Language offered",
                "Attachments": "Attachments",
                "View bid": "View bid",
                "Submitted": "Submitted",
                "Approve": "Approve",
                "Decline": "Decline",
                "Type your comment": "Type your comment",
                "Verified for": "Verified for",
                "Not verified yet": "Not verified yet",
                "Availability (h/week)": "Availability (h/week)",
                "Enter availability": "Enter availability",
                "Location": "Location",
                "Member since": "Member since",
                "Timezone": "Timezone",
                "Languages you speak": "Languages you speak",
                "Description": "Description",
                "Work history": "Work history",
                "Portfolio": "Portfolio",
                "Create portfolio item": "Create portfolio item",
                "Enter portfolio item description": "Enter portfolio item description",
                "Portfolio item description": "Portfolio item description",
                "Open for bidding": "Open for bidding",
                "Closed for bidding": "Closed for bidding",
                "Feedback pending": "Feedback pending",
                "Change acceptance pending": "Change acceptance pending",
                "Completed": "Completed",
                "Cancelled": "Cancelled",
                "Partially completed": "Partially completed",
                "Partially cancelled": "Partially cancelled",
                "Approved": "Approved",
                "Rejected": "Rejected",
                "Loading": "Loading",
                "Model": "Model",
                "Upload a file": "Upload a file",
                "Enter job due date": "Enter job due date",
                "Select resource type": "Select resource type",
                "Updated at": "Updated at",
                "Created at": "Created at",
                "Delete User Account": "Delete User Account",
                "Get OTP":"Get OTP",
                "AR Elements": "AR Elements",
                "VR Experiences":"VR Experiences",
                "Welcome to RealMeta's":"Welcome to RealMeta's",
                "Content Creation Canvas!":"Content Creation Canvas!",
                "Unsubscribe from account":"Unsubscribe from account",
                "Unsubscribe":"Unsubscribe",
                "Register now!":"Register now!",
                "Forgot Password?":"Forgot Password?",
                "Forgot your password?":"Forgot your password?",
                "Receive the link to reset it in your inbox":"Receive the link to reset it in your inbox",
                "Send reset link": "Send reset link",
                "Showing": "Showing",
                "items out of":"items out of",
                "No more data":"No more data",
                "Create Experience":"Create Experience",
                "Upload Unity Space File":"Upload Unity Space File",
                "Help":"Help",
                "AR Publish":"AR Publish",
                "AR Unpublish":"AR Unpublish",
                "VR Enable":"VR Enable",
                "VR Disable":"VR Disable",
                "Business Description": "Business Description",
                "Business External Url": "Business External Url",
                "Add URL": "Add URL",
                "Enter url": "Enter Url",
                "Url": "Url",
                "Add New tag": "Add New tag",
                "Log In": "Log In",
                "Enter Username or Email":"Enter Username or Email",
                "Artist Package":"Artist Package",
                "App Analytics":"App Analytics",
                "Access to RealMeta Digital Studio Team":"Access to RealMeta Digital Studio Team",
                "Contact Us":"Contact Us",
                "Content Creation Tutorial":"Content Creation Tutorial",
                "Collaborate with RealMeta's Grant Application Team **":"Collaborate with RealMeta's Grant Application Team **",
                "Dedicated Project Management Team":"Dedicated Project Management Team",
                "Everything from Emerging Creator":"Everything from Emerging Creator",
                "Emerging creator":"Emerging creator",
                "Established Creator":"Established Creator",
                "Everything from Professional Creator":"Everything from Professional Creator",
                "Free Updates and Upgrades":"Free Updates and Upgrades",
                "Free Creator":"Free Creator",
                "Free augmentation for 25 Exhibits":"Free augmentation for 25 Exhibits",
                "Free Training and Support":"Free Training and Support",
                "Free Museum Migration":"Free Museum Migration",
                "Free Listing on MetaVisitAR":"Free Listing on MetaVisitAR",
                "Gift Shop Integration":"Gift Shop Integration",
                "Minimum 1 Year Commitment":"Minimum 1 Year Commitment",
                "Marketing on RealMeta Social Media":"Marketing on RealMeta Social Media",
                "Object Recognition":"Object Recognition",
                "Professional Creator":"Professional Creator",
                "Pre-qualification for a minimum 3 Year Commitment":"Pre-qualification for a minimum 3 Year Commitment",
                "Real MetaVerse Content Creation for VR":"Real MetaVerse Content Creation for VR",
                "Scripting on Media Creation":"Scripting on Media Creation",
                "Ticketing Integration":"Ticketing Integration",
                "There is your current package":"There is your current package",
                "Unlimited Augmented Immersive Experiences":"Unlimited Augmented Immersive Experiences",
                "Unlimited Visitors on MetaVisitAR *":"Unlimited Visitors on MetaVisitAR *",
                "(USD) per month":"(USD) per month",
                "3D Model creation of up to 3 Gallery spaces":"3D Model creation of up to 3 Gallery spaces",
                "30 Days Free Trial":"30 Days Free Trial",
                "Hindi":"Hindi",
                "Elements without resources":"Elements without resources",
                "Delete Experiences":"Delete Experiences",
                "Digital Prints":"Digital Prints",
                "Digital Print":"Digital Print",
                "30 Days Free Trail":"30 Days Free Trail",
                "Create Digital Print":"Create Digital Print",
                "Add Digital Print":"Add Digital Print",
                "Value Of Digital Print":"Value Of Digital Print",
                "Number Of Available Digital Print":"Number Of Available Digital Print",
                "Orders":"Orders",
                "Order ID":"Order ID",
                "Order Placed":"Order Placed",
                "Product Name":"Product Name",
                "Quantity":"Quantity",
                "Price":"Price",
                "Order By": "Order By",
                "Total Price": "Total Price"

            }
        },
        fr: {
            translations: {
                "Canvas language": "Langage de canevas",
                "Russian": "Russe",
                "English": "Anglaise",
                "French": "Française",
                "Chinese": "Chinoise",
                "Portuguese": "Portugais",
                "German": "Allemand",
                "Korean": "Coréen",
                "Arabic":"arabe",
                "Show only without resources": "Montrer sans ressources",
                "Get Help": "Obtenir de l'aide",
                "Delete Elements": "Supprimer les éléments",
                "Create Element": "Créer des éléments",
                "has {{resources_count}} resources": "A des {{resources_count}} ressources",
                "has {{trainings_length}} identifiers": "A des {{trainings_length}} identifiants",
                "Search": "Chercher",
                "Edit Element": "Modifier les éléments",
                "Gamification": "Gamification",
                "Statistics": "Statistiques",
                "Element": "Élément",
                "Views statistics": "éléments les statistiques",
                "Daily": "Quotidien",
                "Hourly": "Horaire",
                "The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)": "La élément a été découverte {{totalWeekCount}} fois la semaine dernière. <br/>({{weekDiff}}% from the previous week)",
                "The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)": "La élément a été découverte {{totalWeekCount}} fois la journée dernière. <br/>({{dayDiff}}% from the previous day)",
                "Edit": "Modifier",
                "Create a": "Créer Un",
                "quiz": "quiz",
                "Quiz": "Quiz",
                "Please enter question": "Soumettre une question",
                "Please enter answer explanation": "Soumettre l'explication de la réponse",
                "Answers": "Réponses",
                "Add new answer": "Ajouter une nouvelle réponse",
                "Delete": "Effacer",
                "Reset": "Recomposer",
                "Save": "Sauver",
                "Create": "Créer",
                "Required for publishing an element": "Requis pour publier un élément",
                "Additional/ Detailed information about the correct answer, that you want the user to know": "Informations supplémentaires / détaillées sur la bonne réponse, que vous voulez que l'utilisateur connaisse",
                "Element Name": "Nom de l'élément",
                "Element Description": "Description de l'élément",
                "Element Identifier": "identifiant d'élément",
                "Unpublish": "Annuler la publication",
                "Publish": "Publier",
                "Enter Name": "Entrez le Nom",
                "Enter Description": "Entrez la description",
                "Resources": "Ressources",
                "Identifiers": "Identifiants",
                "Filters": "Filtres",
                "Filter": "Filtre",
                "Reset filters": "Réinitialiser les filtres",
                "Amount of the gems which user will earn after selecting correct answer": "Quantité de gemmes que l'utilisateur gagnera après avoir sélectionné la bonne réponse",
                "Please enter points": "Entrer des points",
                "Add new tag": "Ajouter une nouvelle balise",
                "Create resource in this particular language.": "Créez une ressource dans cette langue particulière.",
                "Select language": "Choisir la langue",
                "Show only linked resources": "Afficher uniquement les ressources liées",
                "Searching": "Recherche",
                "Reset search": "Réinitialiser la recherche",
                "Delete Resource": "Supprimer la ressource",
                "Yes": "Oui",
                "No": "Non",
                "Create Resource": "Créer une ressource",
                "All": "Toute",
                "Images": "Images",
                "Image": "Image",
                "Videos": "Vidéos",
                "Audios": "Audios",
                "Texts": "Des textes",
                "3D models": "Modèles 3D",
                "Animations": "Animations",
                "Animation": "Animation",
                "Select a Resource or identifier to see the details here": "Sélectionnez une ressource ou un identifiant pour voir les détails ici",
                "Create Identifier": "Créer un identifiant",
                "Link": "Lien",
                "Linked": "Lié",
                "Add Resource": "Ajouter une ressource",
                "Capture Photo": "Capturer une photo",
                "Capture Video": "Capturer la vidéo",
                "Record Audio": "Enregistrer l'audio",
                "Upload 3d model": "Télécharger le modèle 3D",
                "Text": "Texte",
                "Create Animation": "Créer une animation",
                "Choose file": "Choisir le fichier",
                "Choose File": "Choisir Le Fichier",
                "Enter resource name": "Entrez le nom de la ressource",
                "To use pre existing files as resources, please upload here...": "Pour utiliser des fichiers préexistants comme ressources, les télécharger ici ...",
                "Or create a resource from your device": "Ou créez une ressource depuis votre appareil",
                "Request Creation": "Demande de création",
                "Note, Please Upload only Audio File": "Remarque, veuillez télécharger uniquement le fichier audio",
                "Note, Please Upload only GIF File": "Remarque, veuillez télécharger uniquement le fichier GIF",
                "Edit Resource": "Modifier la ressource",
                "Language": "Langue",
                "The resource was discovered {{totalWeekCount}} times last week.": "La ressource a été découverte {{totalWeekCount}} fois la semaine dernière. ",
                "The resource was discovered {{totalDayCount}} times last day.": "La ressource a été découverte {{totalDayCount}} fois dernier jour.",
                "from the previous week": "de la semaine précédente",
                "Hourly views": "Vues chaque heur",
                "Daily views": "Vues chaque jour",
                "View Identifier": "Voir identifiant",
                "Active": "Actif",
                "Are you sure you want to sign out?": "Êtes-vous certain de vouloir vous déconnecter?",
                "Home": "D'acceuil",
                "Profile": "Profil",
                "Theme": "Thème",
                "Logout": "Se déconnecter",
                "Published": "Publié",
                "Draft": "Brouillon",
                "Status": "Statut",
                "more": "plus",
                "from the previous day": "de la veille",
                "Element and Resources Primary language": "Élément et ressources Langue principale",
                "Business information": "Informations d'affaires",
                "Invite user": "Inviter un utilisateur",
                "Business name": "Nom De L'entreprise",
                "First Name": "Prénom",
                "Last Name": "Nom De Famille",
                "Enter a location": "Entrez Un Emplacement",
                "There is your current package.": "Il y a votre package actuel.",
                "package": "paquet",
                "Personal information": "Renseignements personnels",
                "Upgrade": "Améliorer",
                "Are you sure you want to request creation?": "Voulez-vous vraiment demander la création?",
                "Select role": "Rôle",
                "Bussiness members": "Membres entreprises",
                "Invite": "Inviter",
                "Invite users to your bussiness": "Invitez des utilisateurs dans votre entreprise",
                "Please enter email": "Entrez l'email",
                "Back To Login": "Retour Connexion",
                "Visit": "Visite",
                "Login into ELIAR": "Connectez-vous à CANVAS",
                "Your potion to create magic!": "Votre potion pour créer de la magie!",
                "Enter Email": "Entrez votre Username ou Email",
                "Enter Password": "Entrer Le Mot De Passe",
                "Submit": "Soumettre",
                "Forgot Password": "Mot De Passe Oublié",
                "LogIn": "S'identifier",
                "Login": "S'identifier",
                "Don’t have an account yet?": "Vous n'avez pas encore de compte?",
                "Register": "S'inscrire",
                "now!": "maintenant!",
                "Getting started with ELIAR": "Premiers pas avec CANVAS",
                "Enter email": "Entrez l'email",
                "Repeat Password": "Répéter Le Mot De Passe",
                "Let’s prepare to <br/>create your accont": "Préparons-nous à<br/> créez votre compte",
                "It’s only a few clicks away!": "C'est seulement en quelques clics!",
                "Welcome back to": "Bon retour à",
                "Content Creation platform": "Plateforme de création de contenu",
                "Reset password": "Réinitialiser le mot de passe",
                "All Rights Reserved": "Tous les droits sont réservés",
                "Already have an account?": "Vous avez déjà un compte?",
                "Send reset link": "Réinitialisez le mot de passe",
                "Click to get help on Gamification": "Cliquez pour obtenir de l'aide sur la gamification",
                "Add Identifier": "Ajouter un identifiant",
                "Please wait, identifier is uploading...": "Veuillez patienter, l'identifiant est en cours de téléchargement ...",
                "Upload": "Télécharger",
                "uploaded": "téléversé",
                "Details": "Détails",
                "Please Enter Email": "Veuillez saisir un e-mail",
                "Single": "Célibataire",//
                "Resource": "Ressource",
                "Up to 10": "Jusqu'à 10",
                "elements": "éléments",
                "resource per element": "ressource par élément",
                "Up to 50": "Jusqu'à 50",
                "Multiple": "Plusieurs",
                "Custom": "Douane",
                "Services": "Prestations de service",
                "Level up your experience": "Améliorez votre expérience",
                "Upgrade now to get full access to eliAR and enhance your experiences": "Mettez à niveau maintenant pour obtenir un accès complet à eliAR et améliorer vos expériences",
                "Need help with selecting a package?": "Besoin d'aide pour sélectionner un forfait?",
                "Please contact us": "Contactez nous s'il vous plait",
                "Exhibit": "Exposition",
                "Audio": "l'audio",
                "Video": "Vidéo",
                "Gallery": "Galerie",
                "per month / element": "par mois / élément",
                "per month": "par mois",
                "3d models": "Modèles 3D",
                "Multimedia Animation": "Animation Multimédia",
                "Basic White Label": "Marque blanche de base",
                "Museum": "Créateur",
                "<0>Multiple</0> resource per element": "<0>Plusieurs</0> ressources par élément",
                "<0>Custom</0> element": "<0>Personnalisé</0> élément",
                "<0>Custom</0> resource": "<0>Personnalisé</0> ressource",
                "<0>Custom</0> Services": "<0>Personnalisés</0> services",
                "Passcode for the day": "Code d'accès pour la journée",
                "Generate Passcode for the day": "Générer un code pour la journée",
                "views": "vues",
                "Required": "Obligatoire",
                "Too Short!": "Too Short!",
                "Too Long!": "Too Long!",
                "Unsupported Format": "Unsupported Format",
                "File too large": "File too large",
                "Passwords don't match": "Les mots de passe ne correspondent pas",
                "Password confirm is required": "La confirmation du mot de passe est requise",
                "Text of answer is required": "Le texte de la réponse est requis",
                "Are you sure you want to delete these resources?": "Voulez-vous vraiment supprimer ces ressources?",
                "Are you sure you want to delete this resource?": "Voulez-vous vraiment supprimer cette ressource?",
                "Open": "Ouvert",
                "My jobs": "Mes métiers",
                "Post new job": "Publier un nouvel emploi",
                "Post a job": "Publier une offre",
                "Enter title": "Entrez le titre",
                "Enter job description": "Entrez la description du poste",
                "Enter a budget": "Entrez un budget",
                "Enter job budget": "Entrez le budget de la tâche",
                "Enter due date": "Entrez la date d'échéance",
                "Current status": "Statut actuel",
                "Required resource type": "Type de ressource requis",
                "Resource type required": "Type de ressource requis",
                "Upload visual expectation": "Télécharger l'attente visuelle",
                "Assigned to": "Assigné à",
                "Budget": "Budget",
                "Languages": "Langages",
                "Posted {{$created_at}}": "Publié {{$created_at}}",
                "See details": "Voir les détails",
                "Edit job": "Modifier le travail",
                "Bids": "Offres",
                "Bid details": "Détails de l'offre",
                "Comments": "commentaires",
                "Approve job": "Approuver le travail",
                "Cover letter": "Lettre de motivation",
                "Proposed timeline": "Calendrier proposé",
                "Proposed budget": "Budget proposé",
                "Open Jobs": "Emplois ouverts",
                "View job": "Voir le poste",
                "Create a bid": "Créer une offre",
                "Visual expectations": "Attentes visuelles",
                "Due date": "Date d'échéance",
                "Jobs": "Emplois",
                "My bids": "Mes enchères",
                "Enter cover letter": "Entrez la lettre de motivation",
                "Language offered": "Langue offerte",
                "Attachments": "Pièces jointes",
                "View bid": "Afficher l'enchère",
                "Submitted": "Soumis",
                "Approve": "Approuver",
                "Decline": "Déclin",
                "Type your comment": "Tapez votre commentaire",
                "Verified for": "Vérifié pour",
                "Not verified yet": "Pas encore vérifié",
                "Availability (h/week)": "Disponibilité (h / semaine)",
                "Enter availability": "Entrez la disponibilité",
                "Location": "Emplacement",
                "Member since": "Membre depuis",
                "Timezone": "Fuseau horaire",
                "Languages you speak": "Langues que vous parlez",
                "Description": "La description",
                "Work history": "Antécédents de travail",
                "Portfolio": "Portefeuille",
                "Create portfolio item": "Créer un élément de portefeuille",
                "Enter portfolio item description": "Entrez la description de l'élément de portefeuille",
                "Portfolio item description": "Description de l'élément de portefeuille",
                "Open for bidding": "Ouvert aux enchères",
                "Closed for bidding": "Fermé aux enchères",
                "Feedback pending": "Commentaires en attente",
                "Change acceptance pending": "Acceptation des modifications en attente",
                "Completed": "Complété",
                "Cancelled": "Annulé",
                "Partially completed": "Partiellement achevée",
                "Partially cancelled": "Partiellement annulé",
                "Approved": "Approuvé",
                "Rejected": "Rejeté",
                "Loading": "Chargement",
                "Model": "Modèle",
                "Upload a file": "Télécharger un fichier",
                "Enter job due date": "Entrez la date d'échéance de l'emploi",
                "Select resource type": "Sélectionnez le type de ressource",
                "dragndrop": "Glissez-déposez ou <1> cliquez pour parcourir </1> votre fichier",
                "Updated at": "Mis à jour à",
                "Created at": "Créé à",
                "Delete User Account": "Supprimer le compte utilisateur",
                "Get OTP":"Obtenez OTP",
                "AR Elements": "Éléments AR",
                "VR Experiences":"Expériences VR",
                "Welcome to RealMeta's":"Bienvenue chez RealMeta",
                "Content Creation Canvas!":"Toile de création de contenu !",
                "Unsubscribe from account":"Se désinscrire du compte",
                "Unsubscribe":"Se désabonner",
                "Register now!":"S'inscrire maintenant!",
                "Forgot Password?":"Mot de passe oublié?",
                "Forgot your password?":"Mot de passe oublié?",
                "Receive the link to reset it in your inbox":"Recevez le lien pour le réinitialiser dans votre boîte de réception",
                "Showing": "Montrant",
                "items out of":"articles sortis",
                "No more data":"Plus de données",
                "Create Experience":"Créer de l'expérience",
                "Upload Unity Space File":"Téléchargez le fichier Unity Space",
                "Help":"Aide",
                "AR Publish":"Publication AR",
                "AR Unpublish":"AR Dépublier",
                "VR Enable":"Activer la réalité virtuelle",
                "VR Disable":"Désactivation de la réalité virtuelle",
                "Business Description": "Description de l'activité",
                "Business External Url": "URL externe de l'entreprise",
                "Add URL": "Ajouter l'URL",
                "Enter url": "Entrer l'URL",
                "Url": "URL",
                "Add New tag": "Ajouter une nouvelle balise",
                "Log In": "Se connecter",
                "Enter Username or Email":"Entrez le nom d'utilisateur ou l'e-mail",
                "Artist Package":"Forfait Artiste",
                "App Analytics":"Analyse d'applications",
                "Access to RealMeta Digital Studio Team":"Accès à l'équipe RealMeta Digital Studio",
                "Contact Us":"Contactez-nous",
                "Content Creation Tutorial":"Tutoriel de création de contenu",
                "Collaborate with RealMeta's Grant Application Team **":"Collaborer avec l'équipe de demande de subvention de RealMeta **",
                "Dedicated Project Management Team":"Équipe de gestion de projet dédiée",
                "Everything from Emerging Creator":"Tout de Créateur émergent",
                "Emerging creator":"Créateur émergent",
                "Established Creator":"Créateur établi",
                "Everything from Professional Creator":"Tout du créateur professionnel",
                "Free Updates and Upgrades":"Mises à jour et mises à niveau gratuites",
                "Free Creator":"Créateur libre",
                "Free augmentation for 25 Exhibits":"Augmentation gratuite pour 25 expositions",
                "Free Training and Support":"Formation et assistance gratuites",
                "Free Museum Migration":"Migration gratuite des musées",
                "Free Listing on MetaVisitAR":"Annonce gratuite sur MetaVisitAR",
                "Gift Shop Integration":"Intégration de boutique de cadeaux",
                "Minimum 1 Year Commitment":"Engagement minimum d'un an",
                "Marketing on RealMeta Social Media":"Marketing sur les réseaux sociaux RealMeta",
                "Object Recognition":"Reconnaissance d'objets",
                "Professional Creator":"Créateur professionnel",
                "Pre-qualification for a minimum 3 Year Commitment":"Pré-qualification pour un engagement minimum de 3 ans",
                "Real MetaVerse Content Creation for VR":"Création de contenu réel MetaVerse pour VR",
                "Scripting on Media Creation":"Scripting sur la création de médias",
                "Ticketing Integration":"Intégration de billetterie",
                "There is your current package":"Voilà votre forfait actuel",
                "Unlimited Augmented Immersive Experiences":"Expériences immersives augmentées illimitées",
                "Unlimited Visitors on MetaVisitAR *":"Visiteurs illimités sur MetaVisitAR*",
                "(USD) per month":"(USD) par mois",
                "3D Model creation of up to 3 Gallery spaces":"Création de modèles 3D jusqu'à 3 espaces Galerie",
                "30 Days Free Trial":"30 jours d'essai gratuit",
                "Hindi":"hindi",
                "Elements without resources":"Éléments sans ressources",
                "Delete Experiences":"Supprimer des expériences",
                "Digital Prints":"Impressions numériques",
                "Digital Print":"Impression numérique",
                "30 Days Free Trail":"30 jours de parcours gratuit",
                "Create Digital Print":"Créer une impression numérique",
                "Add Digital Print":"Ajouter une impression numérique",
                "Value Of Digital Print":"Valeur de l'impression numérique",
                "Number Of Available Digital Print":"Nombre d'impressions numériques disponibles",
                "Orders":"Ordres",
                "Order ID":"Numéro de commande",
                "Order Placed":"Commande passée",
                "Product Name":"Nom du produit",
                "Quantity":"Quantité",
                "Price":"Prix",
                "Order By": "Commandé par",
                "Total Price": "Prix ​​total"
            }
        },
        ru: {
            translations: {
                "Canvas language": "Язык дашборда",
                "Russian": "Русский",
                "English": "Английский",
                "French": "Французский",
                "Chinese": "Китайский",
                "Portuguese": "португальский",
                "German": "Немецкий",
                "Korean": "Корейский язык",
                "Arabic":"арабский",
                "Show only without resources": "Показать только без ресурсов",
                "Get Help": "Помощь",
                "Delete Elements": "Удалить элементы",
                "Create Element": "Создать элемент",
                "has {{resources_count}} resources": "имеет {{resources_count}} ресурсов",
                "has {{trainings_length}} identifiers": "имеет {{trainings_length}} идентификаторов",
                "Search": "Поиск",
                "Edit Element": "Редактировать Элемент",
                "Gamification": "Геймификация",
                "Statistics": "Статистика",
                "Element": "Элемент",
                "Views statistics": "Статистика Элемент",
                "Daily": "Ежедневно",
                "Hourly": "Каждый час",
                "The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)": "Элемент был просмотрен {{totalWeekCount}} раз на неделе. <br/>({{weekDiff}}% по сравнению с предыдущей неделей)",
                "The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)": "Элемент был просмотрен {{totalWeekCount}} раз за день. <br/>({{dayDiff}}% по сравнению с предыдущим днем)",
                "Edit": "Редактировать",
                "Create A": "Создать",
                "quiz": "опрос",
                "Quiz": "Опрос",
                "Please enter question": "Введите вопрос",
                "Please enter answer explanation": "Введите более полный  ответ",
                "Answers": "Ответы",
                "Add new answer": "Добавить новый ответ",
                "Delete": "Удалить",
                "Reset": "Сбросить",
                "Save": "Сохранить",
                "Create": "Создать",
                "Required for publishing an element": "Для публикации элемента необходимо",
                "Additional/ Detailed information about the correct answer, that you want the user to know": "Дополнительная/детальная информация о правильном ответе, которую вы хотели бы донести до юзера",
                "Element Name": "Имя Элемента",
                "Element Description": "Описание Элемента",
                "Element Identifier": "Идентификатор Элемента",
                "Unpublish": "Скрыть в черновик",
                "Publish": "Опубликовать",
                "Enter Name": "Введите имя",
                "Enter Description": "Введите  описание",
                "Resources": "Ресурсы",
                "Identifiers": "Идентификаторы",
                "Filters": "Фильтры",
                "Filter": "Фильтр",
                "Reset filters": "Сбросить фильтры",
                "Amount of the gems which user will earn after selecting correct answer": "Количество жемчужин, которые юзер получит после выбора правильного ответа",
                "Please enter points": "Введите количество очков",
                "Add new tag": "Добавить новый тэг",
                "Create resource in this particular language.": "Создайте ресурс на этом языке.",
                "Select language": "Выбрать язык",
                "Show only linked resources": "Показать только связанные ресурсы",
                "Searching": "Поиск",
                "Reset search": "Сбросить поиск",
                "Delete Resource": "Удалить Ресурс",
                "Yes": "Да",
                "No": "Нет",
                "Create Resource": "Создать Ресурс",
                "All": "Все",
                "Images": "Фото",
                "Image": "Фото",
                "Videos": "Видео",
                "Audios": "Аудио",
                "Texts": "Текст",
                "3D models": "3Д модели",
                "Animations": "Анимации",
                "Animation": "Анимация",
                "Select a Resource or identifier to see the details here": "Выберете ресурс или идентификатор, чтобы увидеть его здесь",
                "Create Identifier": "Создать Идентификатор",
                "Link": "Связать",
                "Linked": "Связан",
                "Add Resource": "Добавить ресурс",
                "Capture Photo": "Сделать фото",
                "Capture Video": "Записать видео",
                "Record Audio": "Записать Аудио",
                "Upload 3d model": "Загрузить 3Д модель",
                "Text": "Текст",
                "Create Animation": "Создать Анимацию",
                "Choose file": "Выбрать файл",
                "Choose File": "Выбрать Файл",
                "Enter resource name": "Редактировать имя ресурса",
                "To use pre existing files as resources, please upload here...": "Чтобы использовать существующие файлы в качестве ресурсов, пожалуйста загрузите их здесь...",
                "Or create a resource from your device": "Или создайте ресурс с этого устройства",
                "Request Creation": "Запрос на создание",
                "Note, Please Upload only Audio File": "Примечание, Пожалуйста Загрузите Только Аудио Файл",
                "Note, Please Upload only GIF File": "Примечание, Пожалуйста Загрузите Только GIF Файл",
                "Edit Resource": "Редактировать Ресурс",
                "Language": "Язык",
                "The resource was discovered {{totalWeekCount}} times last week.": "Ресурс был просмотрен {{totalWeekCount}} раз за неделю.",
                "The resource was discovered {{totalDayCount}} times last day.": "Ресурс был просмотрен {{totalDayCount}} раз за день.",
                "from the previous week": "по сравнению с предыдущей неделей",
                "Hourly views": "Ежечасные просмотры",
                "Daily views": "Ежедневные просмотры",
                "View Identifier": "Просмотр Идентификатора",
                "Active": "Активен",
                "Are you sure you want to sign out?": "Вы уверены, что хотите выйти?",
                "Home": "Домой",
                "Profile": "Профиль",
                "Theme": "Тема",
                "Logout": "Выйти",
                "Published": "Опубликован",
                "Draft": "Черновик",
                "Status": "Статус",
                "more": "больше",
                "from the previous day": "по сравнению с предыдущим днем",
                "Element and Resources Primary language": "Основной язык элементов и ресурсов",
                "Business information": "Информация о бизнесе",
                "Invite user": "Пригласить",
                "Business Name": "Название Бизнеса",
                "First Name": "Имя",
                "Last Name": "Фамилия",
                "Enter a location": "Введите Место",
                "There is your current package.": "Это ваш текущий план.",
                "package": "план",
                "Personal information": "Личная информация",
                "Upgrade": "Повысить",
                "Are you sure you want to request creation?": "Вы уверены, что хотите сделать запрос на создание ресурса?",
                "Select role": "Роль",
                "Bussiness members": "Участники бизнеса",
                "Invite": "Пригласить",
                "Invite users to your bussiness": "Пригласите пользователей в ваш бизнес",
                "Please enter email": "Введите email",
                "Back To Login": "Вернуться На Страницу Авторизации",
                "Visit": "Посетите",
                "Login into ELIAR": "Авторизоваться в CANVAS",
                "Your potion to create magic!": "Ваше зелье для создания магии!",
                "Enter Email": "Введите имеил",
                "Enter Password": "Введите Пароль",
                "Submit": "Отправить",
                "Forgot Password": "Не Помню Пароль",
                "LogIn": "Войти",
                "Login": "Войти",
                "Don’t have an account yet?": "Еще нет аккаунта?",
                "Register": "Зарегистрируйтесь",
                "now!": "сейчас!",
                "Getting started with ELIAR": "Начните с CANVAS",
                "Enter email": "Введите имеил",
                "Repeat Password": "Повторите Пароль",
                "Let’s prepare to <br/>create your accont": "Приготовьтесь<br/> создать ваш аккаунт",
                "It’s only a few clicks away!": "Это займет всего пару кликов!",
                "Welcome back to": "Добро пожаловать",
                "Content Creation platform": "Платформа для создания контента",
                "Reset password": "Сбросить пароль",
                "All Rights Reserved": "Все права защищены",
                "Already have an account?": "Уже есть аккаунт?",
                "Send reset link": "Сбросить пароль",
                "Click to get help on Gamification": "Нажмите, чтобы получить помощь в геймификации ",
                "Add Identifier": "Добавить Идентификатор",
                "Please wait, identifier is uploading...": "Пожалуйста подождите, идентификатор загружается",
                "Upload": "Загрузить",
                "uploaded": "Загружено",
                "Details": "Детали",
                "Please Enter Email": "Пожалуйста введите имейд",
                "Single": "Один",//
                "Resource": "Ресурс",
                "Up to 10": "До 10",
                "elements": "элементов",
                "resource per element": "ресурс для элемента",
                "Up to 50": "До 50",
                "Multiple": "Много",
                "Custom": "Персональный",
                "Services": "Сервисы",
                "Level up your experience": "Повысьте уровень своего опыта",
                "Upgrade now to get full access to Canvas and enhance your experiences": "Повысьте план сейчас, чтобы получить полный доступ к eliAR и расширить свои опыты",
                "Need help with selecting a package?": "Нужна помощь в выборе плана?",
                "Please contact us": "Пожалуйста свяжитесь с нами",
                "Exhibit": "Экспонат",
                "Audio": "Аудио",
                "Video": "Видео",
                "Gallery": "Галерея",
                "per month / element": "в месяц / за элемент",
                "per month": "в месяц",
                "3d models": "3д модели",
                "Multimedia Animation": "Мультимедия анимации",
                "Basic White Label": "Базовый White Label",
                "Museum": "Создатель",
                "<0>Multiple</0> resource per element": "<0>Много</0> ресурсов для элемента",
                "<0>Custom</0> element": "<0>Персональный</0> элемент",
                "<0>Custom</0> resource": "<0>Персональный</0> ресурс",
                "<0>Custom</0> Services": "<0>Персональные</0> Сервисы",
                "Passcode for the day": "Код доступа на день",
                "Generate Passcode for the day": "Создать код доступа на день",
                "views": "просмотров",
                "Required": "Обязательное поле",
                "Too Short!": "Слишком короткое значение!",
                "Too Long!": "Слишком длинное значение!",
                "Unsupported Format": "Не поддерживаемый формат",
                "File too large": "Файл слишком большой",
                "Passwords don't match": "Пароли не совпадают",
                "Password confirm is required": "Требуется подтверждение пароля",
                "Text of answer is required": "Текст ответа обязателен",
                "Are you sure you want to delete these resources?": "Вы уверены, что хотите удалить эти ресурсы?",
                "Are you sure you want to delete this resource?": "Вы уверены, что хотите удалить этот ресурс?",//
                "Open": "Открытые",
                "My jobs": "Мои работы ",
                "Post new job": "Разместить новую работу",
                "Post a job": "Создание работы",
                "Enter title": "Введите заголовок",
                "Enter job description": "Введите описание",
                "Enter a budget": "Введите бюджет",
                "Enter job budget": "Введите бюджет",
                "Enter due date": "Введите дату выполнения работы",
                "Current status": "Текущий статус",
                "Required resource type": "Требуемый тип ресурса",
                "Resource type required": "Требуемый тип ресурса",
                "Upload visual expectation": "Загрузите визуальные ожидания",
                "Assigned to": "Назначено на",
                "Budget": "Бюджет",
                "Languages": "Языки",
                "Posted {{$created_at}}": "Размещено {{$created_at}}",
                "See details": "Посмотреть детали",
                "Edit job": "Редактировать работу",
                "Bids": "Отклики",
                "Bid details": "Детали отклика",
                "Comments": "Комментарии",
                "Approve job": "Принять работу",
                "Cover letter": "Сопровождающее письмо",
                "Proposed timeline": "Предлагаемый график",
                "Proposed budget": "Предлагаемый бюджет",
                "Open Jobs": "Открытые работы",
                "View job": "Просмотр работы",
                "Create a bid": "Откликнуться",
                "Visual expectations": "Визуальные ожидания",
                "Due date": "Дата выполнения",
                "Jobs": "Работы",
                "My bids": "Мои отклики",
                "Enter cover letter": "Введите сопровождающее письмо",
                "Language offered": "Предлагаемый язык",
                "Attachments": "Вложения",
                "View bid": "Просмотр отклика",
                "Submitted": "Отправленные",
                "Approve": "Принять",
                "Decline": "Отклонить",
                "Type your comment": "Введите ваш комментарий",
                "Verified for": "Верифицирован для",
                "Not verified yet": "Еще не верифицирован",
                "Availability (h/week)": "Доступность (ч/неделю)",
                "Enter availability": "Введите доступность",
                "Location": "Локация",
                "Member since": "Зарегистрирован с",
                "Enter A Location": "Введите локацию",
                "Timezone": "Таймзона",
                "Languages you speak": "Языки для общения",
                "Description": "Описание",
                "Work history": "История работы",
                "Portfolio": "Портфолио",
                "Create portfolio item": "Добавить работу в портфолио",
                "Enter portfolio item description": "Введите описание работы",
                "Portfolio item description": "Описание работы",
                "Open for bidding": "Открыто для откликов",
                "Closed for bidding": "Закрыто для откликов",
                "Feedback pending": "Ожидается обратная связь",
                "Change acceptance pending": "Ожидается принятия изменений",
                "Completed": "Завершено",
                "Cancelled": "Закрыто",
                "Partially completed": "Частично завершено",
                "Partially cancelled": "Частично закрыто",
                "Approved": "Одобрено",
                "Rejected": "Отклонено",
                "Loading": "Загрузка",
                "Model": "Модель",
                "Upload a file": "Загрузить файл",
                "Enter job due date": "Введите дату выполнения работы",
                "Select resource type": "Выберете тип ресурса",
                "Updated at": "Обновлено",
                "Created at": "Создано",
                "Delete User Account": "Удалить учетную запись пользователя",
                "Get OTP":"Получить ОТП",
                "AR Elements": "AR Элементы",
                "VR Experiences":"VR-опыт",
                "Welcome to RealMeta's":"Добро пожаловать в RealMeta",
                "Content Creation Canvas!":"Холст для создания контента!",
                "Unsubscribe from account":"Отписаться от аккаунта",
                "Unsubscribe":"Отписаться",
                "Register now!":"Зарегистрироваться!",
                "Forgot Password?":"Забыли пароль?",
                "Forgot your password?":"Забыли пароль?",
                "Receive the link to reset it in your inbox":"Получите ссылку для сброса на свой почтовый ящик",
                "Showing": "Показывая",
                "items out of":"предметы из",
                "No more data":"Больше нет данных",
                "Create Experience":"Создать опыт",
                "Upload Unity Space File":"Загрузите файл пространства Unity",
                "Help":"Помощь",
                "AR Publish":"AR Публиковать",
                "AR Unpublish":"AR Отменить публикацию",
                "VR Enable":"VR Давать возможность",
                "VR Disable":"VR отключить",
                "Business Description": "Описание деятельности",
                "Business External Url": "Внешний URL-адрес компании",
                "Add URL": "Добавить URL",
                "Enter url": "Введите URL",
                "Url": "URL",
                "Add New tag": "Добавить новый тег",
                "Enter Username or Email":"Введите имя пользователя или адрес электронной почты",
                "Log In": "Авторизоваться",
                "Artist Package":"Пакет художника",
                "App Analytics":"Аналитика приложений",
                "Access to RealMeta Digital Studio Team":"Доступ к команде RealMeta Digital Studio",
                "Contact Us":"Связаться с нами",
                "Content Creation Tutorial":"Учебник по созданию контента",
                "Collaborate with RealMeta's Grant Application Team **":"Сотрудничать с командой по подаче заявок на гранты RealMeta**",
                "Dedicated Project Management Team":"Выделенная команда управления проектом",
                "Everything from Emerging Creator":"Все от «Нового Создателя»",
                "Emerging creator":"Начинающий творец",
                "Established Creator":"Создатель",
                "Everything from Professional Creator":"Все от Professional Creator",
                "Free Updates and Upgrades":"Бесплатные обновления и обновления",
                "Free Creator":"Свободный Создатель",
                "Free augmentation for 25 Exhibits":"Бесплатное расширение для 25 Экспонатов",
                "Free Training and Support":"Бесплатное обучение и поддержка",
                "Free Museum Migration":"Бесплатная миграция музеев",
                "Free Listing on MetaVisitAR":"Бесплатный листинг на MetaVisitAR",
                "Gift Shop Integration":"Интеграция магазина подарков",
                "Minimum 1 Year Commitment":"Обязательство минимум на 1 год",
                "Marketing on RealMeta Social Media":"Маркетинг в социальных сетях RealMeta",
                "Object Recognition":"Распознавание объектов",
                "Professional Creator":"Профессиональный создатель",
                "Pre-qualification for a minimum 3 Year Commitment":"Предварительная квалификация для минимального трехлетнего обязательства",
                "Real MetaVerse Content Creation for VR":"Создание реального контента MetaVerse для виртуальной реальности",
                "Scripting on Media Creation":"Сценарии создания медиа",
                "Ticketing Integration":"Интеграция билетов",
                "There is your current package":"Вот ваш текущий пакет",
                "Unlimited Augmented Immersive Experiences":"Неограниченные возможности расширенного погружения",
                "Unlimited Visitors on MetaVisitAR *":"Неограниченное количество посетителей на MetaVisitAR*",
                "(USD) per month":"(долларов США) в месяц",
                "3D Model creation of up to 3 Gallery spaces":"Создание 3D-моделей до 3 пространств галереи",
                "30 Days Free Trial":"30-дневная бесплатная пробная версия",
                "Hindi":"хинди",
                "Elements without resources":"Элементы без ресурсов",
                "Delete Experiences":"Удалить опыт",
                "Digital Prints":"Цифровая печать",
                "Digital Print":"Цифровая печать",
                "30 Days Free Trail":"30-дневный бесплатный маршрут",
                "Create Digital Print":"Создать цифровую печать",
                "Add Digital Print":"Добавить цифровую печать",
                "Value Of Digital Print":"Ценность цифровой печати",
                "Number Of Available Digital Print":"Количество доступных цифровых отпечатков",
                "Orders":"Заказы",
                "Order ID":"Номер заказа",
                "Order Placed":"Заказ размещен",
                "Product Name":"Наименование товара",
                "Quantity":"Количество",
                "Price":"Цена",
                "Order By": "Сортировать по",
                "Total Price": "Итоговая цена"
            }
        },
        zh: {
            translations: {
                "Canvas language":"畫布語言",
                "Russian":"俄語",
                "English":"英語",
                "French":"法語",
                "Chinese":"中國人",
                "Portuguese":"葡萄牙語",
                "German": "德语",
                "Korean": "韩国人",
                "Arabic":"阿拉伯",
                "Show only without resources":"僅顯示無資源",
                "Get Help":"得到幫助",
                "Delete Elements":"刪除元素",
                "Create Element":"創建元素",
                "has {{resources_count}} resources":"擁有 {{resources_count}} 資源",
                "has {{trainings_length}} identifiers":"有 {{trainings_length}} 標識符",
                "Search":"搜尋",
                "Edit Element":"編輯元素",
                "Gamification":"遊戲化",
                "Statistics":"統計數據",
                "Element":"元素",
                "Element Statistics":"元素統計",
                "Daily":"日常的",
                "Hourly":"每小時",
                "The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)":"該元素上週被發現了 {{totalWeekCount}} 次。<br/>（與上週相比為 {{weekDiff}}%）",
                "The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)":"該元素昨天被發現了 {{totalDayCount}} 次。<br/>（與前一天相比為 {{dayDiff}}%）",
                "Edit":"編輯",
                "Create A":"創建一個",
                "quiz":"測驗",
                "Quiz":"測驗",
                "Please enter question":"請輸入問題",
                "Please enter answer explanation":"請輸入答案說明",
                "Answers":"答案",
                "Add new answer":"新增答案",
                "Delete":"刪除",
                "Reset":"重置",
                "Save":"節省",
                "Create":"創造",
                "Required for publishing an element":"發布元素所必需的",
                "Additional/ Detailed information about the correct answer, that you want the user to know":"您希望用戶知道的有關正確答案的附加/詳細資訊",
                "Element Name":"元素名稱",
                "Element Description":"元素描述",
                "Element Identifier":"元素標識符",
                "Unpublish":"取消發布",
                "Publish":"發布",
                "Enter Name":"輸入名字",
                "Enter Description":"輸入描述",
                "Resources":"資源",
                "Identifiers":"身份識別",
                "Filters":"過濾器",
                "Filter":"篩選",
                "Reset filters":"重置過濾器",
                "Amount of the gems which user will earn after selecting correct answer":"選擇正確答案後用戶將獲得的寶石數量",
                "Please enter points":"請輸入積分",
                "Add New tag":"新增標籤",
                "Create resource in this particular language.":"用這種特定的語言創造資源",
                "Select language":"選擇語言",
                "Show only linked resources":"僅顯示連結的資源",
                "Searching":"尋找",
                "Reset search":"重置搜尋",
                "Delete Resource":"刪除資源",
                "Yes":"是的",
                "No":"不",
                "Create Resource":"創建資源",
                "All":"全部",
                "Images":"圖片",
                "Image":"圖像",
                "Videos":"影片",
                "Audios":"音訊",
                "Texts":"文字",
                "3d models":"3D模型",
                "Animations":"動畫",
                "Select a Resource or identifier to see the details here":"選擇資源或標識符以在此處查看詳細資訊",
                "Create Identifier":"建立標識符",
                "Link":"關聯",
                "Linked":"連結",
                "Add Resource":"添加資源",
                "Capture Photo":"拍攝照片",
                "Capture Video":"捕捉影片",
                "Record Audio":"錄製音訊",
                "Upload 3D model":"上傳3D模型",
                "Text":"文字",
                "Create Animation":"創建動畫",
                "Choose file":"選擇檔案",
                "Choose File":"選擇檔案",
                "Enter resource name":"輸入資源名稱",
                "To use pre existing files as resources, please upload here...":"要使用現有文件作為資源，請在此處上傳...",
                "Or create a resource from your device":"或從您的裝置建立資源",
                "Request Creation":"請求創建",
                "Note : Please Upload only Audio File":"注意：請僅上傳音訊檔案",
                "Note : Please Upload only GIF File":"注意：請僅上傳 GIF 檔案",
                "Edit Resource":"編輯資源",
                "Language":"語言",
                "The resource was discovered {{totalWeekCount}} times last week.":"上週該資源被發現了 {{totalWeekCount}} 次。",
                "The resource was discovered {{totalDayCount}} times last day.":"該資源昨天被發現了 {{totalDayCount}} 次。",
                "from the previous week":"從上週開始",
                "Hourly views":"每小時瀏覽次數",
                "Daily views":"每日觀點",
                "View Identifier":"查看標識符",
                "Active":"積極的",
                "Are you sure you want to sign out?":"您確定要退出嗎？",
                "Home":"家",
                "Profile":"輪廓",
                "Theme":"主題",
                "Logout":"登出",
                "Published":"已發布",
                "Draft":"草稿",
                "Status":"地位",
                "more":"更多的",
                "Element and Resources Primary language":"元素和資源主要語言",
                "Business information":"商業資訊",
                "Invite user":"邀請用戶",
                "Business Name":"企業名稱",
                "First Name":"名",
                "Last Name":"姓",
                "Enter A Location":"輸入地點",
                "There is your current package.":"這是你目前的包裹。",
                "package":"包裹",
                "Personal information":"個人資訊",
                "Upgrade":"升級",
                "Are you sure you want to request creation?":"你確定要請求創作嗎？",
                "Select role":"選擇角色",
                "Bussiness members":"企業會員",
                "Invite":"邀請",
                "Invite users to your bussiness":"邀請用戶加入您的企業",
                "Please Enter Email":"請輸入郵箱",
                "Back To Login":"回到登入",
                "Visit":"訪問",
                "Login into CANVAS":"登入畫布",
                "Your potion to create magic!":"你的魔法藥水！",
                "Enter Username or Email":"輸入使用者名稱或電子郵件",
                "Enter Password":"輸入密碼",
                "Submit":"提交",
                "Forgot Password":"忘記密碼",
                "LogIn":"登入",
                "Login":"登入",
                "Don’t have an account yet?":"還沒有帳戶嗎？",
                "Register":"登記",
                "now!":"現在！",
                "Getting started with CANVAS":"畫布入門",
                "Enter email":"輸入電子郵件",
                "Repeat Password":"重複輸入密碼",
                "Let’s prepare to <br/>create your accont":"讓我們準備好<br/>建立您的帳戶",
                "It’s only a few clicks away!":"只需點擊幾下！",
                "Welcome back to":"歡迎回來",
                "Content Creation platform":"內容創作平台",
                "Reset password":"重設密碼",
                "All Rights Reserved":"版權所有",
                "Already have an account?":"已經有帳戶了？",
                "Click to get help on Gamification":"點擊獲取有關遊戲化的幫助",
                "Add Identifier":"新增標識符",
                "Please wait, identifier is uploading...":"請稍等標識符正在上傳...",
                "Upload":"上傳",
                "uploaded":"已上傳",
                "Details":"細節",
                "Single":"單身的",
                "Resource":"資源",
                "Up to 10":"高達10",
                "elements":"元素",
                "resource per element":"每個元素的資源",
                "Up to 50":"高達50",
                "Multiple":"多種的",
                "Custom":"風俗",
                "Services":"服務",
                "Level up your experience":"提升你的經驗",
                "Upgrade now to get full access to eliAR and enhance your experiences":"立即升級即可完全訪問 eliAR 並增強您的體驗",
                "Need help with selecting a package?":"需要協助選擇套餐嗎？",
                "Please contact us":"請聯絡我們",
                "Exhibit":"展示",
                "Audio":"聲音的",
                "Video":"影片",
                "Gallery":"畫廊",
                "per month / element":"每月/元素",
                "per month":"每月",
                "Multimedia Animation":"多媒體動畫",
                "Basic White Label":"基本白標",
                "Museum":"博物館",
                "<0>Custom</0> element":"<0>自訂</0>元素",
                "<0>Multiple</0> resource per element":"每個元素<0>多個</0>資源",
                "<0>Custom</0> resource":"<0>自訂</0>資源",
                "<0>Custom</0> Services":"<0>客製化</0>服務",
                "Passcode for the day":"當日密碼",
                "Generate Passcode for the day":"產生當天的密碼",
                "views":"意見",
                "Required":"必需的",
                "Too Short!":"過短！",
                "Too Long!":"太長！",
                "Unsupported Format":"不支援的格式",
                "File too large":"文件過大",
                "Passwords don't match":"密碼不符",
                "Password confirm is required":"需要密碼確認",
                "Text of answer is required":"需要回答文字",
                "Are you sure you want to delete these resources?":"您確定要刪除這些資源嗎？",
                "Are you sure you want to delete this resource?,//":"您確定要刪除該資源嗎？",
                "Open":"打開",
                "My jobs":"我的工作",
                "Post new job":"發布新工作",
                "Post a job":"發布職位",
                "Enter title":"輸入標題",
                "Enter job description":"輸入職位說明",
                "Enter a budget":"輸入預算",
                "Enter job budget":"輸入工作預算",
                "Enter due date":"輸入截止日期",
                "Current status":"當前狀態",
                "Required resource type":"所需資源類型",
                "Resource type required":"所需資源類型",
                "Upload visual expectation":"上傳視覺期望",
                "Assigned to":"分配給",
                "Budget":"預算",
                "Languages":"語言",
                "Posted {{$created_at}}":"發佈於 {{$created_at}}",
                "See details":"查看具體資訊",
                "Edit job":"編輯工作",
                "Bids":"出價",
                "Bid details":"投標詳情",
                "Comments":"評論",
                "Approve job":"批准工作",
                "Cover letter":"求職信",
                "Proposed timeline":"建議的時間表",
                "Proposed budget":"擬議預算",
                "Open Jobs":"開放職位",
                "View job":"查看工作",
                "Create a bid":"創建出價",
                "Visual expectations":"視覺期望",
                "Due date":"到期日",
                "Jobs":"工作",
                "My bids":"我的出價",
                "Enter cover letter":"輸入求職信",
                "Language offered":"提供的語言",
                "Attachments":"附件",
                "View bid":"查看出價",
                "Submitted":"已提交",
                "Approve":"批准",
                "Decline":"衰退",
                "Type your comment":"輸入您的評論",
                "Verified for":"已驗證",
                "Not verified yet":"尚未核實",
                "Availability (h/week)":"可用性（小時/週）",
                "Enter availability":"輸入可用性",
                "Location":"地點",
                "Member since":"會員自",
                "Timezone":"時區",
                "Languages you speak":"你說的語言",
                "Description":"描述",
                "Work history":"工作經驗",
                "Portfolio":"資料夾",
                "Create portfolio item":"創建投資組合項目",
                "Enter portfolio item description":"輸入投資組合項目說明",
                "Portfolio item description":"投資組合項目說明",
                "Open for bidding":"公開招標",
                "Closed for bidding":"已停止競標",
                "Feedback pending":"反饋待處理",
                "Change acceptance pending":"變更接受待定",
                "Completed":"完全的",
                "Cancelled":"取消",
                "Partially completed":"部分完成",
                "Partially cancelled":"部分取消",
                "Approved":"得到正式認可的",
                "Rejected":"拒絕",
                "Loading":"載入中",
                "Model":"模型",
                "Upload a file":"上傳一個文件",
                "Enter job due date":"輸入工作截止日期",
                "Select resource type":"選擇資源類型",
                "Updated at":"更新於",
                "Created at":"創建於",
                "Delete User Account":"刪除用戶帳戶",
                "Get OTP":"獲取一次性密碼",
                "AR Elements": "AR 元素",
                "VR Experiences":"VR 体验",
                "Welcome to RealMeta's":"欢迎来到 RealMeta",
                "Content Creation Canvas!":"内容创建画布！",
                "Unsubscribe from account":"取消订阅帐户",
                "Unsubscribe":"取消订阅",
                "Register now!":"现在注册！",
                "Forgot Password?":"忘记密码？",
                "Forgot your password?":"忘记密码了吗？",
                "Receive the link to reset it in your inbox":"在您的收件箱中接收重置链接",
                "Send reset link": "发送重置链接",
                "Showing": "显示",
                "items out of":"项目出",
                "No more data":"没有更多数据",
                "Create Experience":"创造经验",
                "Upload Unity Space File":"上传Unity空间文件",
                "Help":"帮助",
                "AR Publish":"AR 发布",
                "AR Unpublish":"AR 取消发布",
                "VR Enable":"VR 使能够",
                "VR Disable":"VR 禁用",
                "Business Description": "業務描述",
                "Business External Url": "業務外部網址",
                "Add URL": "新增網址",
                "Enter url": "輸入網址",
                "Url": "網址",
                "Log In": "登录",
                "Artist Package":"艺术家套餐",
                "App Analytics":"应用程序分析",
                "Access to RealMeta Digital Studio Team":"访问 RealMeta Digital Studio 团队",
                "Contact Us":"联系我们",
                "Content Creation Tutorial":"内容创建教程",
                "Collaborate with RealMeta's Grant Application Team **":"与 RealMeta 的拨款申请团队合作**",
                "Dedicated Project Management Team":"专门的项目管理团队",
                "Everything from Emerging Creator":"一切都来自新兴创造者",
                "Emerging creator":"新兴创作者",
                "Established Creator":"创立者",
                "Everything from Professional Creator":"一切都来自专业创作者",
                "Free Updates and Upgrades":"免费更新和升级",
                "Free Creator":"自由创作者",
                "Free augmentation for 25 Exhibits":"免费扩充25个展品",
                "Free Training and Support":"免费培训和支持",
                "Free Museum Migration":"免费博物馆迁移",
                "Free Listing on MetaVisitAR":"MetaVisitAR 上的免费列表",
                "Gift Shop Integration":"礼品店整合",
                "Minimum 1 Year Commitment":"至少 1 年承诺",
                "Marketing on RealMeta Social Media":"RealMeta 社交媒体营销",
                "Object Recognition":"物体识别",
                "Professional Creator":"专业创作者",
                "Pre-qualification for a minimum 3 Year Commitment":"至少 3 年承诺的资格预审",
                "Real MetaVerse Content Creation for VR":"真正的 MetaVerse VR 内容创作",
                "Scripting on Media Creation":"媒体创作脚本",
                "Ticketing Integration":"票务集成",
                "There is your current package":"有你当前的包裹",
                "Unlimited Augmented Immersive Experiences":"无限的增强沉浸式体验",
                "Unlimited Visitors on MetaVisitAR *":"MetaVisitAR 的无限访问者*",
                "(USD) per month":"（美元）每月",
                "3D Model creation of up to 3 Gallery spaces":"创建最多 3 个画廊空间的 3D 模型",
                "30 Days Free Trial":"30 天免费试用",
                "Hindi":"印地语",
                "Elements without resources":"沒有資源的元素",
                "Delete Experiences":"刪除經歷",
                "Digital Prints":"數位印刷",
                "Digital Print":"數位印刷",
                "30 Days Free Trail":"30 天免費試用",
                "Create Digital Print":"創建數位印刷",
                "Add Digital Print":"新增數位列印",
                "Value Of Digital Print":"數位印刷的價值",
                "Number Of Available Digital Print":"可用數字列印數量",
                "Orders":"命令",
                "Order ID":"訂單編號",
                "Order Placed":"訂單已下",
                "Product Name":"產品名稱",
                "Quantity":"數量",
                "Price":"價格",
                "Order By": "訂購依據",
                "Total Price": "總價"
            }
        },
        pt: {
            translations: {
                "Canvas language": "Idioma da tela",
                "Russian": "Russo",
                "English": "Inglês",
                "French": "Francês",
                "Chinese": "Chinês",
                "German": "Alemão",
                "Korean": "Coreano",
                "Arabic":"árabe",
                "Portuguese": "Português",
                "Show only without resources": "Mostrar apenas sem recursos",
                "Get Help": "Obter Ajuda",
                "Delete Elements": "Excluir Elementos",
                "Create Element": "Criar Elemento",
                "has {{resources_count}} resources": "tem {{resources_count}} recursos",
                "has {{trainings_length}} identifiers": "tem {{trainings_length}} identificadores",
                "Search": "Pesquisar",
                "Edit Element": "Editar Elemento",
                "Gamification": "Gamificação",
                "Statistics": "Estatísticas",
                "Element": "Elemento",
                "Views statistics": "Estatísticas do Elemento",
                "Daily":"Diário",
                "Hourly":"De hora em hora",
                "The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)":"O elemento foi descoberto {{totalWeekCount}} vezes na semana passada. <br/>({{weekDiff}}% da semana anterior)",
                "The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)":"O elemento foi descoberto {{totalDayCount}} vezes no dia passado. <br/>({{dayDiff}}% do dia anterior)",
                "Edit":"Editar",
                "Create A":"Criar uma",
                "quiz":"questionário",
                "Quiz":"Questionário",
                "Please enter question":"Por favor insira a pergunta",
                "Please enter answer explanation":"Por favor, insira a explicação da resposta",
                "Answers":"Respostas",
                "Add new answer":"Adicionar nova resposta",
                "Delete":"Excluir",
                "Reset":"Reiniciar",
                "Save":"Salvar",
                "Create":"Criar",
                "Required for publishing an element":"Obrigatório para publicar um elemento",
                "Additional/ Detailed information about the correct answer, that you want the user to know":"Informações adicionais/detalhadas sobre a resposta correta que você deseja que o usuário saiba",
                "Element Name":"Nome do Elemento",
                "Element Description":"Descrição do Elemento",
                "Element Identifier":"Identificador do Elemento",
                "Unpublish":"Cancelar publicação",
                "Publish":"Publicar",
                "Enter Name":"Insira o nome",
                "Enter Description":"Inserir descrição",
                "Resources":"Recursos",
                "Identifiers":"Identificadores",
                "Filters":"Filtros",
                "Filter":"Filtro",
                "Reset filters":"Redefinir filtros",
                "Amount of the gems which user will earn after selecting correct answer":"Quantidade de joias que o usuário ganhará após selecionar a resposta correta",
                "Please enter points":"Por favor insira pontos",
                "Add New tag":"Adicionar nova etiqueta",
                "Create resource in this particular language.":"Crie recursos neste idioma específico.",
                "Select language":"Selecione o idioma",
                "Show only linked resources":"Mostrar apenas recursos vinculados",
                "Searching":"Procurando",
                "Reset search":"Redefinir pesquisa",
                "Delete Resource":"Excluir recurso",
                "Yes":"Sim",
                "No":"Não",
                "Create Resource":"Criar recurso",
                "All":"Todos",
                "Images":"Imagens",
                "Image":"Imagem",
                "Videos":"Vídeos",
                "Audios":"Áudios",
                "Texts":"Texto:% s",
                "3d models":"modelos 3D",
                "Animations":"Animações",
                "Select a Resource or identifier to see the details here":"Selecione um recurso ou identificador para ver os detalhes aqui",
                "Create Identifier":"Criar identificador",
                "Link":"Link",
                "Linked":"Vinculado",
                "Add Resource":"Adicionar recurso",
                "Capture Photo":"Capturar foto",
                "Capture Video":"Capturar vídeo",
                "Record Audio":"Gravar audio",
                "Upload 3D model":"Carregar modelo 3D",
                "Text":"Texto",
                "Create Animation":"Criar Animação",
                "Choose file":"Escolher arquivo",
                "Choose File":"Escolher arquivo",
                "Enter resource name":"Insira o nome do recurso",
                "To use pre existing files as resources, please upload here...":"Para usar arquivos pré-existentes como recursos, faça upload aqui...",
                "Or create a resource from your device":"Ou crie um recurso do seu dispositivo",
                "Request Creation":"Solicitar criação",
                "Note : Please Upload only Audio File":"Observação: faça upload apenas do arquivo de áudio",
                "Note : Please Upload only GIF File":"Observação: faça upload apenas do arquivo GIF",
                "Edit Resource":"Editar recurso",
                "Language":"Linguagem",
                "The resource was discovered {{totalWeekCount}} times last week.":"O recurso foi descoberto {{totalWeekCount}} vezes na semana passada.",
                "The resource was discovered {{totalDayCount}} times last day.":"O recurso foi descoberto {{totalDayCount}} vezes no dia passado.",
                "from the previous week":"da semana anterior",
                "Hourly views":"Visualizações por hora",
                "Daily views":"Visualizações diárias",
                "View Identifier":"Ver identificador",
                "Active":"Ativo",
                "Are you sure you want to sign out?":"Você tem certeza que deseja sair?",
                "Home":"Lar",
                "Profile":"Perfil",
                "Theme":"Tema",
                "Logout":"Sair",
                "Published":"Publicados",
                "Draft":"Rascunho",
                "Status":"Status",
                "more":"mais",
                "Element and Resources Primary language":"Idioma primário de elementos e recursos",
                "Business information":"Informação de negócios",
                "Invite user":"Convidar Usuário",
                "Business Name":"Nome da empresa",
                "First Name":"Primeiro nome",
                "Last Name":"Sobrenome",
                "Enter A Location":"Insira um local",
                "There is your current package.":"Aí está o seu pacote atual.",
                "package":"pacote",
                "Personal information":"Informações pessoais",
                "Upgrade":"Atualizar",
                "Are you sure you want to request creation?":"Tem certeza de que deseja solicitar a criação?",
                "Select role":"Selecionar função",
                "Bussiness members":"Membros de negócios",
                "Invite":"Convidar",
                "Invite users to your bussiness":"Convide usuários para o seu negócio",
                "Please Enter Email":"Por favor, insira o e-mail",
                "Back To Login":"Volte ao login",
                "Visit":"Visita",
                "Login into CANVAS":"Entrar no CANVAS",
                "Your potion to create magic!":"Sua poção para criar magia!",
                "Enter Username or Email":"Digite o nome de usuário ou e-mail",
                "Enter Password":"Digite a senha",
                "Submit":"Enviar",
                "Forgot Password":"Esqueceu sua senha",
                "LogIn":"Conecte-se",
                "Login":"Conecte-se",
                "Don’t have an account yet?":"Não tem uma conta ainda?",
                "Register":"Registro",
                "now!":"agora!",
                "Getting started with CANVAS":"Introdução ao CANVAS",
                "Enter email":"Insira o e-mail",
                "Repeat Password":"Repita a senha",
                "Let’s prepare to <br/>create your accont":"Vamos nos preparar para <br/>criar sua conta",
                "It’s only a few clicks away!":"Estão a apenas alguns cliques de distância!",
                "Welcome back to":"Bem-vindo de volta",
                "Content Creation platform":"Plataforma de criação de conteúdo",
                "Reset password":"Redefinir senha",
                "All Rights Reserved":"Todos os direitos reservados",
                "Already have an account?":"Já tem uma conta?",
                "Click to get help on Gamification":"Clique para obter ajuda sobre Gamificação",
                "Add Identifier":"Adicionar identificador",
                "Please wait, identifier is uploading...":"Aguarde o upload do identificador...",
                "Upload":"Carregar",
                "uploaded":"carregado",
                "Details":"Detalhes",
                "Single":"Solteiro",
                "Resource":"Recurso",
                "Up to 10":"Até 10",
                "elements":"elementos",
                "resource per element":"recurso por elemento",
                "Up to 50":"Até 50",
                "Multiple":"Múltiplo",
                "Custom":"Personalizado",
                "Services":"Serviços",
                "Level up your experience":"Aumente o nível da sua experiência",
                "Upgrade now to get full access to eliAR and enhance your experiences":"Atualize agora para obter acesso total ao eliAR e aprimorar suas experiências",
                "Need help with selecting a package?":"Precisa de ajuda para selecionar um pacote?",
                "Please contact us":"Por favor, entre em contato conosco",
                "Exhibit":"Exibir",
                "Audio":"Áudio",
                "Video":"Vídeo",
                "Gallery":"Galeria",
                "per month / element":"por mês/elemento",
                "per month":"por mês",
                "Multimedia Animation":"Animação Multimídia",
                "Basic White Label":"Etiqueta Branca Básica",
                "Museum":"Museu",
                "<0>Custom</0> element":"Elemento <0>Personalizado</0>",
                "<0>Multiple</0> resource per element":"<0>Vários</0> recursos por elemento",
                "<0>Custom</0> resource":"Recurso <0>Personalizado</0>",
                "<0>Custom</0> Services":"Serviços<0>Personalizados</0>",
                "Passcode for the day":"Senha do dia",
                "Generate Passcode for the day":"Gerar senha do dia",
                "views":"Visualizações",
                "Required":"Obrigatório",
                "Too Short!":"Muito curto!",
                "Too Long!":"Demasiado longo!",
                "Unsupported Format":"Formato não suportado",
                "File too large":"Arquivo muito grande",
                "Passwords don't match":"As senhas não coincidem",
                "Password confirm is required":"A confirmação da senha é necessária",
                "Text of answer is required":"O texto da resposta é obrigatório",
                "Are you sure you want to delete these resources?":"Tem certeza de que deseja excluir estes recursos?",
                "Are you sure you want to delete this resource?":"Tem certeza de que deseja excluir este recurso?",
                "Open":"Abrir",
                "My jobs":"Meus trabalhos",
                "Post new job":"Publicar novo emprego",
                "Post a job":"Publicar um emprego",
                "Enter title":"Insira o título",
                "Enter job description":"Insira a descrição do trabalho",
                "Enter a budget":"Insira um orçamento",
                "Enter job budget":"Insira o orçamento do trabalho",
                "Enter due date":"Insira a data de vencimento",
                "Current status":"Status atual",
                "Required resource type":"Tipo de recurso necessário",
                "Resource type required":"Tipo de recurso necessário",
                "Upload visual expectation":"Carregar expectativa visual",
                "Assigned to":"Atribuído a",
                "Budget":"Orçamento",
                "Languages":"Línguas",
                "Posted {{$created_at}}":"Postado {{$created_at}}",
                "See details":"Veja detalhes",
                "Edit job":"Editar trabalho",
                "Bids":"Lances",
                "Bid details":"Detalhes do lance",
                "Comments":"Comentários",
                "Approve job":"Aprovar trabalho",
                "Cover letter":"Carta de apresentação",
                "Proposed timeline":"Cronograma proposto",
                "Proposed budget":"Orçamento proposto",
                "Open Jobs":"Empregos Abertos",
                "View job":"Ver trabalho",
                "Create a bid":"Criar um lance",
                "Visual expectations":"Expectativas visuais",
                "Due date":"Data de vencimento",
                "Jobs":"Empregos",
                "My bids":"Meus lances",
                "Enter cover letter":"Inserir carta de apresentação",
                "Language offered":"Idioma oferecido",
                "Attachments":"Anexos",
                "View bid":"Ver lance",
                "Submitted":"Submetido",
                "Approve":"Aprovar",
                "Decline":"Declínio",
                "Type your comment":"Digite seu comentário",
                "Verified for":"Verificado para",
                "Not verified yet":"Ainda não verificado",
                "Availability (h/week)":"Disponibilidade (h/semana)",
                "Enter availability":"Insira a disponibilidade",
                "Location":"Localização",
                "Member since":"Membro desde",
                "Timezone":"Fuso horário",
                "Languages you speak":"Idiomas que você fala",
                "Description":"Descrição",
                "Work history":"Histórico de trabalho",
                "Portfolio":"Portfólio",
                "Create portfolio item":"Criar item de portfólio",
                "Enter portfolio item description":"Insira a descrição do item do portfólio",
                "Portfolio item description":"Descrição do item do portfólio",
                "Open for bidding":"Aberto para licitação",
                "Closed for bidding":"Fechado para licitação",
                "Feedback pending":"Feedback pendente",
                "Change acceptance pending":"Aceitação da alteração pendente",
                "Completed":"Concluído",
                "Cancelled":"Cancelado",
                "Partially completed":"Parcialmente completo",
                "Partially cancelled":"Parcialmente cancelado",
                "Approved":"Aprovado",
                "Rejected":"Rejeitado",
                "Loading":"Carregando",
                "Model":"Modelo",
                "Upload a file":"Enviar um arquivo",
                "Enter job due date":"Insira a data de vencimento do trabalho",
                "Select resource type":"Selecione o tipo de recurso",
                "Updated at":"Atualizado em",
                "Created at":"Criado em",
                "Delete User Account":"Excluir conta de usuário",
                "Get OTP":"Obter OTP",
                "AR Elements": "AR Elementos",
                "VR Experiences":"VR Experiências de",
                "Welcome to RealMeta's":"Bem-vindo ao RealMeta",
                "Content Creation Canvas!":"Tela de criação de conteúdo!",
                "Unsubscribe from account":"Cancelar inscrição da conta",
                "Unsubscribe":"Cancelar subscrição",
                "Register now!":"Registrar agora!",
                "Forgot Password?":"Esqueceu sua senha?",
                "Forgot your password?":"Esqueceu sua senha?",
                "Receive the link to reset it in your inbox":"Receba o link para redefini-lo em sua caixa de entrada",
                "Send reset link": "Enviar link de redefinição",
                "Showing": "Mostrando",
                "items out of":"itens fora de",
                "No more data":"Não há mais dados",
                "Create Experience":"Criar Experiência",
                "Upload Unity Space File":"Carregar arquivo de espaço do Unity",
                "Help":"Ajuda",
                "AR Publish":"AR Publicar",
                "AR Unpublish":"AR Cancelar publicação",
                "VR Enable":"VR Habilitar",
                "VR Disable":"VR Desativar",
                "Business Description": "Descrição do negócio",
                "Business External Url": "URL externo comercial",
                "Add URL": "Adicione URL",
                "Enter url": "Insira o URL",
                "Url": "URL",
                "Log In": "Conecte-se",
                "Artist Package":"Pacote Artista",
                "App Analytics":"Análise de aplicativos",
                "Access to RealMeta Digital Studio Team":"Acesso à equipe do RealMeta Digital Studio",
                "Contact Us":"Contate-nos",
                "Content Creation Tutorial":"Tutorial de criação de conteúdo",
                "Collaborate with RealMeta's Grant Application Team **":"Colabore com a equipe de solicitação de subsídios da RealMeta **",
                "Dedicated Project Management Team":"Equipe dedicada de gerenciamento de projetos",
                "Everything from Emerging Creator":"Tudo do Criador Emergente",
                "Emerging creator":"Criador emergente",
                "Established Creator":"Criador Estabelecido",
                "Everything from Professional Creator":"Tudo do Criador Profissional",
                "Free Updates and Upgrades":"Atualizações e upgrades gratuitos",
                "Free Creator":"Criador Livre",
                "Free augmentation for 25 Exhibits":"Aumento gratuito para 25 exposições",
                "Free Training and Support":"Treinamento e suporte gratuitos",
                "Free Museum Migration":"Migração Gratuita de Museus",
                "Free Listing on MetaVisitAR":"Listagem gratuita no MetaVisitAR",
                "Gift Shop Integration":"Integração com loja de presentes",
                "Minimum 1 Year Commitment":"Compromisso mínimo de 1 ano",
                "Marketing on RealMeta Social Media":"Marketing nas mídias sociais RealMeta",
                "Object Recognition":"Reconhecimento de objeto",
                "Professional Creator":"Criador Profissional",
                "Pre-qualification for a minimum 3 Year Commitment":"Pré-qualificação para um compromisso mínimo de 3 anos",
                "Real MetaVerse Content Creation for VR":"Criação de conteúdo Real MetaVerse para VR",
                "Scripting on Media Creation":"Scripting na criação de mídia",
                "Ticketing Integration":"Integração de ingressos",
                "There is your current package":"Aí está o seu pacote atual",
                "Unlimited Augmented Immersive Experiences":"Experiências imersivas aumentadas ilimitadas",
                "Unlimited Visitors on MetaVisitAR *":"Visitantes ilimitados no MetaVisitAR *",
                "(USD) per month":"(USD) por mês",
                "3D Model creation of up to 3 Gallery spaces":"Criação de modelo 3D de até 3 espaços de galeria",
                "30 Days Free Trial":"30 dias de teste gratuito",
                "Hindi":"hindi",
                "Elements without resources":"Elementos sem recursos",
                "Delete Experiences":"Excluir experiências",
                "Digital Prints":"Impressões Digitais",
                "Digital Print":"Impressão digital",
                "30 Days Free Trail":"Trilha Gratuita de 30 Dias",
                "Create Digital Print":"Criar impressão digital",
                "Add Digital Print":"Adicionar impressão digital",
                "Value Of Digital Print":"Valor da impressão digital",
                "Number Of Available Digital Print":"Número de impressões digitais disponíveis",
                "Orders":"Pedidos",
                "Order ID":"ID do pedido",
                "Order Placed":"Pedido feito",
                "Product Name":"Nome do Produto",
                "Quantity":"Quantidade",
                "Price":"Preço",
                "Order By": "Ordenar por",
                "Total Price": "Preço total"
            }
        },
        de: {
            translations: {
                "Canvas language":"Leinwandsprache",
                "Russian":"Russisch",
                "English":"Englisch",
                "French":"Französisch",
                "Chinese":"Chinesisch",
                "Portuguese":"Portugiesisch",
                "German": "Deutsch",
                "Korean": "Koreanisch",
                "Arabic":"Arabisch",
                "Show only without resources":"Nur ohne Ressourcen anzeigen",
                "Get Help":"Hilfe bekommen",
                "Delete Elements":"Elemente löschen",
                "Create Element":"Element erstellen",
                "has {{resources_count}} resources":"hat {{resources_count}} Ressourcen",
                "has {{trainings_length}} identifiers":"hat {{trainings_length}} Identifikatoren",
                "Search":"Suchen",
                "Edit Element":"Element bearbeiten",
                "Gamification":"Gamifizierung",
                "Statistics":"Statistiken",
                "Element":"Element",
                "Element Statistics":"Elementstatistik",
                "Daily":"Täglich",
                "Hourly":"Stündlich",
                "The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)":"Das Element wurde letzte Woche {{totalWeekCount}} Mal entdeckt. <br/>({{weekDiff}} % gegenüber der Vorwoche)",
                "The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)":"Das Element wurde letzten Tag {{totalDayCount}} Mal entdeckt. <br/>({{dayDiff}} % vom Vortag)",
                "Edit":"Bearbeiten",
                "Create A":"Ein ... kreieren",
                "quiz":"Quiz",
                "Quiz":"Quiz",
                "Please enter question":"Bitte Frage eingeben",
                "Please enter answer explanation":"Bitte geben Sie eine Antworterklärung ein",
                "Answers":"Antworten",
                "Add new answer":"Neue Antwort hinzufügen",
                "Delete":"Löschen",
                "Reset":"Zurücksetzen",
                "Save":"Speichern",
                "Create":"Erstellen",
                "Required for publishing an element":"Erforderlich für die Veröffentlichung eines Elements",
                "Additional/ Detailed information about the correct answer, that you want the user to know":"Zusätzliche/detaillierte Informationen zur richtigen Antwort, die der Benutzer wissen soll",
                "Element Name":"Elementname",
                "Element Description":"Elementbeschreibung",
                "Element Identifier":"Element-Identifikator",
                "Unpublish":"Veröffentlichung aufheben",
                "Publish":"Veröffentlichen",
                "Enter Name":"Name eingeben",
                "Enter Description":"Beschreibung eingeben",
                "Resources":"Ressourcen",
                "Identifiers":"Identifikatoren",
                "Filters":"Filter",
                "Filter":"Filter",
                "Reset filters":"Filter zurücksetzen",
                "Amount of the gems which user will earn after selecting correct answer":"Anzahl der Edelsteine, die der Benutzer erhält, nachdem er die richtige Antwort ausgewählt hat",
                "Please enter points":"Bitte Punkte eingeben",
                "Add New tag":"Neues Tag hinzufügen",
                "Create resource in this particular language.":"Ressource in dieser bestimmten Sprache erstellen.",
                "Select language":"Sprache auswählen",
                "Show only linked resources":"Nur verknüpfte Ressourcen anzeigen",
                "Searching":"Suchen",
                "Reset search":"Suche zurücksetzen",
                "Delete Resource":"Ressource löschen",
                "Yes":"Ja",
                "No":"NEIN",
                "Create Resource":"Ressource erstellen",
                "All":"Alle",
                "Images":"Bilder",
                "Image":"Bild",
                "Videos":"Videos",
                "Audios":"Audios",
                "Texts":"Texte",
                "3d models":"3D-Modelle",
                "Animations":"Animationen",
                "Select a Resource or identifier to see the details here":"Wählen Sie eine Ressource oder einen Bezeichner aus, um die Details hier anzuzeigen.",
                "Create Identifier":"Bezeichner erstellen",
                "Link":"Verknüpfung",
                "Linked":"Verlinkt",
                "Add Resource":"Ressource hinzufügen",
                "Capture Photo":"Foto aufnehmen",
                "Capture Video":"Video aufnehmen",
                "Record Audio":"Ton aufnehmen",
                "Upload 3D model":"3D-Modell hochladen",
                "Text":"Text",
                "Create Animation":"Animation erstellen",
                "Choose file":"Datei wählen",
                "Choose File":"Datei wählen",
                "Enter resource name":"Ressourcennamen eingeben",
                "To use pre existing files as resources, please upload here...":"Um bereits vorhandene Dateien als Ressourcen zu verwenden, laden Sie sie bitte hier hoch …",
                "Or create a resource from your device":"Oder erstellen Sie eine Ressource von Ihrem Gerät aus",
                "Request Creation":"Anforderungserstellung",
                "Note : Please Upload only Audio File":"Hinweis: Bitte laden Sie nur Audiodateien hoch.",
                "Note : Please Upload only GIF File":"Hinweis: Bitte laden Sie nur GIF-Dateien hoch",
                "Edit Resource":"Ressource bearbeiten",
                "Language":"Sprache",
                "The resource was discovered {{totalWeekCount}} times last week.":"Die Ressource wurde letzte Woche {{totalWeekCount}} Mal entdeckt.",
                "The resource was discovered {{totalDayCount}} times last day.":"Die Ressource wurde letzten Tag {{totalDayCount}} Mal entdeckt.",
                "from the previous week":"aus der Vorwoche",
                "Hourly views":"Stündliche Ansichten",
                "Daily views":"Tägliche Ansichten",
                "View Identifier":"Bezeichner anzeigen",
                "Active":"Aktiv",
                "Are you sure you want to sign out?":"Sind Sie sicher, dass Sie sich abmelden möchten?",
                "Home":"Heim",
                "Profile":"Profil",
                "Theme":"Thema",
                "Logout":"Ausloggen",
                "Published":"Veröffentlicht",
                "Draft":"Entwurf",
                "Status":"Status",
                "more":"mehr",
                "Element and Resources Primary language":"Element- und Ressourcen-Primärsprache",
                "Business information":"Geschäftsinformationen",
                "Invite user":"Benutzer einladen",
                "Business Name":"Firmenname",
                "First Name":"Vorname",
                "Last Name":"Familienname, Nachname",
                "Enter A Location":"Geben Sie einen Ort ein",
                "There is your current package.":"Da ist Ihr aktuelles Paket.",
                "package":"Paket",
                "Personal information":"Persönliche Angaben",
                "Upgrade":"Aktualisierung",
                "Are you sure you want to request creation?":"Sind Sie sicher, dass Sie die Erstellung anfordern möchten?",
                "Select role":"Rolle auswählen",
                "Bussiness members":"Geschäftsmitglieder",
                "Invite":"Einladen",
                "Invite users to your bussiness":"Laden Sie Benutzer zu Ihrem Unternehmen ein",
                "Please Enter Email":"Bitte geben Sie Ihre E-Mail-Adresse ein",
                "Back To Login":"Zurück zur Anmeldung",
                "Visit":"Besuchen",
                "Login into CANVAS":"Bei CANVAS anmelden",
                "Your potion to create magic!":"Dein Trank, um Magie zu erschaffen!",
                "Enter Username or Email":"Benutzernamen oder E-Mail-Adresse eingeben",
                "Enter Password":"Passwort eingeben",
                "Submit":"Einreichen",
                "Forgot Password":"Passwort vergessen",
                "LogIn":"Anmeldung",
                "Login":"Anmeldung",
                "Don’t have an account yet?":"Sie haben noch kein Konto?",
                "Register":"Registrieren",
                "now!":"Jetzt!",
                "Getting started with CANVAS":"Erste Schritte mit CANVAS",
                "Enter email":"Email eingeben",
                "Repeat Password":"Passwort wiederholen",
                "Let’s prepare to <br/>create your accont":"Lassen Sie uns die <br/>Erstellung Ihres Kontos vorbereiten.",
                "It’s only a few clicks away!":"Es ist nur ein paar Klicks entfernt!",
                "Welcome back to":"Willkommen zurück zu",
                "Content Creation platform":"Content-Creation-Plattform",
                "Reset password":"Passwort zurücksetzen",
                "All Rights Reserved":"Alle Rechte vorbehalten",
                "Already have an account?":"Sie haben bereits ein Konto?",
                "Click to get help on Gamification":"Klicken Sie hier, um Hilfe zu Gamification zu erhalten",
                "Add Identifier":"Bezeichner hinzufügen",
                "Please wait, identifier is uploading...":"Bitte warten, die Kennung wird hochgeladen …",
                "Upload":"Hochladen",
                "uploaded":"hochgeladen",
                "Details":"Einzelheiten",
                "Single":"Einzel",
                "Resource":"Ressource",
                "Up to 10":"Bis zu 10",
                "elements":"Elemente",
                "resource per element":"Ressource pro Element",
                "Up to 50":"Bis zu 50",
                "Multiple":"Mehrere",
                "Custom":"Brauch",
                "Services":"Dienstleistungen",
                "Level up your experience":"Steigern Sie Ihr Erlebnis",
                "Upgrade now to get full access to eliAR and enhance your experiences":"Führen Sie jetzt ein Upgrade durch, um vollen Zugriff auf eliAR zu erhalten und Ihre Erfahrungen zu verbessern.",
                "Need help with selecting a package?":"Benötigen Sie Hilfe bei der Auswahl eines Pakets?",
                "Please contact us":"Bitte kontaktieren Sie uns",
                "Exhibit":"Ausstellungsstück",
                "Audio":"Audio",
                "Video":"Video",
                "Gallery":"Galerie",
                "per month / element":"pro Monat/Element",
                "per month":"pro Monat",
                "Multimedia Animation":"Multimedia-Animation",
                "Basic White Label":"Basis-White-Label",
                "Museum":"Museum",
                "<0>Custom</0> element":"<0>Benutzerdefiniertes</0> Element",
                "<0>Multiple</0> resource per element":"<0>Mehrere</0> Ressource pro Element",
                "<0>Custom</0> resource":"<0>Benutzerdefinierte</0> Ressource",
                "<0>Custom</0> Services":"<0>Benutzerdefinierte</0> Dienste",
                "Passcode for the day":"Passcode für den Tag",
                "Generate Passcode for the day":"Passcode für den Tag generieren",
                "views":"Ansichten",
                "Required":"Erforderlich",
                "Too Short!":"Zu kurz!",
                "Too Long!":"Zu lang!",
                "Unsupported Format":"Nicht unterstütztes Format",
                "File too large":"Datei zu groß",
                "Passwords don't match":"Passwörter stimmen nicht überein",
                "Password confirm is required":"Passwortbestätigung ist erforderlich",
                "Text of answer is required":"Antworttext ist erforderlich",
                "Are you sure you want to delete these resources?":"Sind Sie sicher, dass Sie diese Ressourcen löschen möchten?",
                "Are you sure you want to delete this resource?,//":"Sind Sie sicher, dass Sie diese Ressource löschen möchten?",
                "Open":"Offen",
                "My jobs":"Meine Jobs",
                "Post new job":"Neuen Job veröffentlichen",
                "Post a job":"Einen Job veröffentlichen",
                "Enter title":"Titel eingeben",
                "Enter job description":"Stellenbeschreibung eingeben",
                "Enter a budget":"Geben Sie ein Budget ein",
                "Enter job budget":"Jobbudget eingeben",
                "Enter due date":"Fälligkeitsdatum eingeben",
                "Current status":"Aktueller Status",
                "Required resource type":"Erforderlicher Ressourcentyp",
                "Resource type required":"Ressourcentyp erforderlich",
                "Upload visual expectation":"Visuelle Erwartungen hochladen",
                "Assigned to":"Zugewiesen an",
                "Budget":"Budget",
                "Languages":"Sprachen",
                "Posted {{$created_at}}":"Gepostet am {{$created_at}}",
                "See details":"Siehe Einzelheiten",
                "Edit job":"Job bearbeiten",
                "Bids":"Gebote",
                "Bid details":"Gebotsdetails",
                "Comments":"Kommentare",
                "Approve job":"Auftrag genehmigen",
                "Cover letter":"Motivationsschreiben",
                "Proposed timeline":"Vorgeschlagener Zeitplan",
                "Proposed budget":"Vorgeschlagener Haushalt",
                "Open Jobs":"Offene Stellen",
                "View job":"Job ansehen",
                "Create a bid":"Gebot erstellen",
                "Visual expectations":"Visuelle Erwartungen",
                "Due date":"Fälligkeitsdatum",
                "Jobs":"Arbeitsplätze",
                "My bids":"Meine Gebote",
                "Enter cover letter":"Anschreiben eingeben",
                "Language offered":"Sprachangebot",
                "Attachments":"Anhänge",
                "View bid":"Gebot ansehen",
                "Submitted":"Eingereicht",
                "Approve":"Genehmigen",
                "Decline":"Abfall",
                "Type your comment":"Geben Sie Ihren Kommentar ein",
                "Verified for":"Verifiziert für",
                "Not verified yet":"Noch nicht verifiziert",
                "Availability (h/week)":"Verfügbarkeit (Std./Woche)",
                "Enter availability":"Verfügbarkeit eingeben",
                "Location":"Standort",
                "Member since":"Mitglied seit",
                "Timezone":"Zeitzone",
                "Languages you speak":"Sprachen, die du sprichst",
                "Description":"Beschreibung",
                "Work history":"Arbeitsgeschichte",
                "Portfolio":"Portfolio",
                "Create portfolio item":"Portfolioelement erstellen",
                "Enter portfolio item description":"Beschreibung des Portfolioelements eingeben",
                "Portfolio item description":"Beschreibung des Portfolioartikels",
                "Open for bidding":"Zur Ausschreibung geöffnet",
                "Closed for bidding":"Zur Ausschreibung geschlossen",
                "Feedback pending":"Feedback ausstehend",
                "Change acceptance pending":"Änderungsannahme ausstehend",
                "Completed":"Vollendet",
                "Cancelled":"Abgesagt",
                "Partially completed":"Zum Teil fertiggestellt",
                "Partially cancelled":"Teilweise abgesagt",
                "Approved":"Genehmigt",
                "Rejected":"Abgelehnt",
                "Loading":"Wird geladen",
                "Model":"Modell",
                "Upload a file":"Eine Datei hochladen",
                "Enter job due date":"Job-Fälligkeitsdatum eingeben",
                "Select resource type":"Ressourcentyp auswählen",
                "Updated at":"Aktualisiert am",
                "Created at":"Hergestellt in",
                "Delete User Account":"Benutzerkonto löschen",
                "Get OTP":"OTP erhalten",
                "AR Elements": "AR-Elemente",
                "VR Experiences":"VR-Erlebnisse",
                "Welcome to RealMeta's":"Willkommen bei RealMeta",
                "Content Creation Canvas!":"Leinwand zur Inhaltserstellung!",
                "Unsubscribe from account":"Vom Konto abmelden",
                "Unsubscribe":"Abbestellen",
                "Register now!":"Jetzt registrieren!",
                "Forgot Password?":"Passwort vergessen?",
                "Forgot your password?":"Haben Sie Ihr Passwort vergessen?",
                "Receive the link to reset it in your inbox":"Erhalten Sie den Link zum Zurücksetzen in Ihrem Posteingang",
                "Send reset link": "Link zum Zurücksetzen senden",
                "Showing": "Zeigt",
                "items out of":"Gegenstände aus",
                "No more data":"Keine Daten mehr",
                "Create Experience":"Erlebnis schaffen",
                "Upload Unity Space File":"Laden Sie die Unity Space-Datei hoch.",
                "Help":"Helfen",
                "AR Publish":"AR Veröffentlichen",
                "AR Unpublish":"AR Veröffentlichung aufheben",
                "VR Enable":"VR Aktivieren",
                "VR Disable":"VR Deaktivieren",
                "Business Description": "Geschäftsbeschreibung",
                "Business External Url": "Externe Unternehmens-URL",
                "Add URL": "URL hinzufügen",
                "Enter url": "URL eingeben",
                "Url": "URL",
                "Log In": "Anmeldung",
                "Artist Package":"Künstlerpaket",
                "App Analytics":"App-Analyse",
                "Access to RealMeta Digital Studio Team":"Zugriff auf das RealMeta Digital Studio-Team",
                "Contact Us":"Kontaktiere uns",
                "Content Creation Tutorial":"Tutorial zur Inhaltserstellung",
                "Collaborate with RealMeta's Grant Application Team **":"Arbeiten Sie mit dem Grant Application Team von RealMeta zusammen**",
                "Dedicated Project Management Team":"Engagiertes Projektmanagement-Team",
                "Everything from Emerging Creator":"Alles von Emerging Creator",
                "Emerging creator":"Aufstrebender Schöpfer",
                "Established Creator":"Etablierter Schöpfer",
                "Everything from Professional Creator":"Alles von Professional Creator",
                "Free Updates and Upgrades":"Kostenlose Updates und Upgrades",
                "Free Creator":"Freier Schöpfer",
                "Free augmentation for 25 Exhibits":"Kostenlose Erweiterung für 25 Exponate",
                "Free Training and Support":"Kostenlose Schulung und Support",
                "Free Museum Migration":"Kostenlose Museumsmigration",
                "Free Listing on MetaVisitAR":"Kostenloser Eintrag auf MetaVisitAR",
                "Gift Shop Integration":"Geschenkeladen-Integration",
                "Minimum 1 Year Commitment":"Mindestens 1-Jahres-Verpflichtung",
                "Marketing on RealMeta Social Media":"Marketing auf RealMeta Social Media",
                "Object Recognition":"Objekterkennung",
                "Professional Creator":"Professioneller Schöpfer",
                "Pre-qualification for a minimum 3 Year Commitment":"Vorqualifikation für eine mindestens 3-jährige Verpflichtung",
                "Real MetaVerse Content Creation for VR":"Echte MetaVerse-Inhaltserstellung für VR",
                "Scripting on Media Creation":"Scripting zur Medienerstellung",
                "Ticketing Integration":"Ticketing-Integration",
                "There is your current package":"Da ist Ihr aktuelles Paket",
                "Unlimited Augmented Immersive Experiences":"Unbegrenzte erweiterte immersive Erlebnisse",
                "Unlimited Visitors on MetaVisitAR *":"Unbegrenzte Besucherzahl auf MetaVisitAR*",
                "(USD) per month":"(USD) pro Monat",
                "3D Model creation of up to 3 Gallery spaces":"3D-Modellerstellung von bis zu 3 Galerieräumen",
                "30 Days Free Trial":"30 Tage kostenlose Testversion",
                "Hindi":"Hindi",
                "Elements without resources":"Elemente ohne Ressourcen",
                "Delete Experiences":"Erlebnisse löschen",
                "Digital Prints":"Digitaldrucke",
                "Digital Print":"Digitalerdruck",
                "30 Days Free Trail":"30 Tage kostenloser Test",
                "Create Digital Print":"Digitaldruck erstellen",
                "Add Digital Print":"Digitaldruck hinzufügen",
                "Value Of Digital Print":"Wert des Digitaldrucks",
                "Number Of Available Digital Print":"Anzahl der verfügbaren Digitaldrucke",
                "Orders":"Aufträge",
                "Order ID":"Auftragsnummer",
                "Order Placed":"Bestellung aufgegeben",
                "Product Name":"Produktname",
                "Quantity":"Menge",
                "Price":"Preis",
                "Order By": "Sortieren nach",
                "Total Price": "Gesamtpreis"
            }
        },
        ko: {
            translations: {
                "Canvas language":"캔버스 언어",
                "Russian":"러시아인",
                "English":"영어",
                "French":"프랑스 국민",
                "Chinese":"중국인",
                "Portuguese":"포르투갈 인",
                "German": "독일 사람",
                "Korean": "한국인",
                "Arabic":"아라비아 말",
                "Show only without resources":"리소스 없이만 표시",
                "Get Help":"도움을 받으세요",
                "Delete Elements":"요소 삭제",
                "Create Element":"요소 생성",
                "has {{resources_count}} resources":"리소스가 {{resources_count}}개 있습니다.",
                "has {{trainings_length}} identifiers":"{{trainings_length}}개의 식별자가 있습니다",
                "Search":"찾다",
                "Edit Element":"요소 편집",
                "Gamification":"게이미피케이션",
                "Statistics":"통계",
                "Element":"요소",
                "Element Statistics":"요소 통계",
                "Daily":"일일",
                "Hourly":"매시간",
                "The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)":"지난주에 요소가 {{totalWeekCount}}번 발견되었습니다. <br/>(지난주 대비 {{weekDiff}}%)",
                "The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)":"어제 요소가 {{totalDayCount}}번 발견되었습니다. <br/>(전날 대비 {{dayDiff}}%)",
                "Edit":"편집하다",
                "Create A":"A 만들기",
                "quiz":"퀴즈",
                "Quiz":"퀴즈",
                "Please enter question":"질문을 입력해주세요",
                "Please enter answer explanation":"답변설명을 입력해주세요",
                "Answers":"답변",
                "Add new answer":"새 답변 추가",
                "Delete":"삭제",
                "Reset":"초기화",
                "Save":"구하다",
                "Create":"만들다",
                "Required for publishing an element":"요소 게시에 필요",
                "Additional/ Detailed information about the correct answer, that you want the user to know":"사용자에게 알려주고 싶은 정답에 대한 추가/상세 정보",
                "Element Name":"요소 이름",
                "Element Description":"요소 설명",
                "Element Identifier":"요소 식별자",
                "Unpublish":"게시 취소",
                "Publish":"게시",
                "Enter Name":"이름을 입력하시오",
                "Enter Description":"설명 입력",
                "Resources":"자원",
                "Identifiers":"식별자",
                "Filters":"필터",
                "Filter":"필터",
                "Reset filters":"필터 재설정",
                "Amount of the gems which user will earn after selecting correct answer":"사용자가 정답을 선택하면 얻을 수 있는 보석의 양",
                "Please enter points":"포인트를 입력해주세요",
                "Add New tag":"새 태그 추가",
                "Create resource in this particular language.":"이 특정 언어로 리소스를 생성하세요.",
                "Select language":"언어 선택",
                "Show only linked resources":"연결된 리소스만 표시",
                "Searching":"수색",
                "Reset search":"검색 재설정",
                "Delete Resource":"리소스 삭제",
                "Yes":"예",
                "No":"아니요",
                "Create Resource":"리소스 생성",
                "All":"모두",
                "Images":"이미지",
                "Image":"영상",
                "Videos":"동영상",
                "Audios":"오디오",
                "Texts":"텍스트",
                "3d models":"3D 모델",
                "Animations":"애니메이션",
                "Select a Resource or identifier to see the details here":"여기에서 세부정보를 보려면 리소스 또는 식별자를 선택하세요.",
                "Create Identifier":"식별자 생성",
                "Link":"링크",
                "Linked":"링크됨",
                "Add Resource":"리소스 추가",
                "Capture Photo":"사진 캡처",
                "Capture Video":"비디오 캡처",
                "Record Audio":"오디오 녹음",
                "Upload 3D model":"3D 모델 업로드",
                "Text":"텍스트",
                "Create Animation":"애니메이션 만들기",
                "Choose file":"파일을 선택",
                "Choose File":"파일을 선택",
                "Enter resource name":"리소스 이름을 입력하세요",
                "To use pre existing files as resources, please upload here...":"기존 파일을 리소스로 사용하려면 여기에 업로드하십시오...",
                "Or create a resource from your device":"또는 장치에서 리소스를 생성하세요",
                "Request Creation":"요청 생성",
                "Note : Please Upload only Audio File":"참고: 오디오 파일만 업로드하세요",
                "Note : Please Upload only GIF File":"주의사항 : GIF 파일만 업로드해주세요",
                "Edit Resource":"리소스 편집",
                "Language":"언어",
                "The resource was discovered {{totalWeekCount}} times last week.":"리소스가 지난주에 {{totalWeekCount}}번 발견되었습니다.",
                "The resource was discovered {{totalDayCount}} times last day.":"어제 리소스가 {{totalDayCount}}번 발견되었습니다.",
                "from the previous week":"지난주부터",
                "Hourly views":"시간별 조회수",
                "Daily views":"일일 조회수",
                "View Identifier":"식별자 보기",
                "Active":"활동적인",
                "Are you sure you want to sign out?":"정말로 로그아웃하시겠습니까?",
                "Home":"집",
                "Profile":"프로필",
                "Theme":"주제",
                "Logout":"로그 아웃",
                "Published":"게시됨",
                "Draft":"초안",
                "Status":"상태",
                "more":"더",
                "Element and Resources Primary language":"요소 및 리소스 기본 언어",
                "Business information":"비즈니스 정보",
                "Invite user":"사용자 초대",
                "Business Name":"비즈니스 이름",
                "First Name":"이름",
                "Last Name":"성",
                "Enter A Location":"위치를 입력하세요",
                "There is your current package.":"현재 패키지가 있습니다.",
                "package":"패키지",
                "Personal information":"개인 정보",
                "Upgrade":"업그레이드",
                "Are you sure you want to request creation?":"정말 생성을 요청하시겠습니까?",
                "Select role":"역할 선택",
                "Bussiness members":"비즈니스 회원",
                "Invite":"초대하다",
                "Invite users to your bussiness":"당신의 비즈니스에 사용자를 초대하세요",
                "Please Enter Email":"이메일을 입력해주세요",
                "Back To Login":"로그인으로 돌아가기",
                "Visit":"방문하다",
                "Login into CANVAS":"CANVAS에 로그인",
                "Your potion to create magic!":"마법을 창조하는 물약!",
                "Enter Username or Email":"사용자 이름 또는 이메일을 입력하세요",
                "Enter Password":"암호를 입력",
                "Submit":"제출하다",
                "Forgot Password":"비밀번호를 잊으 셨나요",
                "LogIn":"로그인",
                "Login":"로그인",
                "Don’t have an account yet?":"아직 계정이 없나요?",
                "Register":"등록하다",
                "now!":"지금!",
                "Getting started with CANVAS":"캔버스 시작하기",
                "Enter email":"이메일을 입력하세요",
                "Repeat Password":"비밀번호를 반복하세요",
                "Let’s prepare to <br/>create your accont":"계정 만들기를 <br/>준비하세요.",
                "It’s only a few clicks away!":"클릭 몇 번이면 됩니다!",
                "Welcome back to":"돌아온 것을 환영합니다",
                "Content Creation platform":"콘텐츠 창작 플랫폼",
                "Reset password":"암호를 재설정",
                "All Rights Reserved":"판권 소유",
                "Already have an account?":"이미 계정이 있나요?",
                "Click to get help on Gamification":"게이미피케이션에 대한 도움을 받으려면 클릭하세요.",
                "Add Identifier":"식별자 추가",
                "Please wait, identifier is uploading...":"식별자를 업로드하는 중입니다...",
                "Upload":"업로드",
                "uploaded":"업로드됨",
                "Details":"세부",
                "Single":"하나의",
                "Resource":"자원",
                "Up to 10":"10까지",
                "elements":"강요",
                "resource per element":"요소당 리소스",
                "Up to 50":"최대 50",
                "Multiple":"다수의",
                "Custom":"관습",
                "Services":"서비스",
                "Level up your experience":"당신의 경험을 레벨업하세요",
                "Upgrade now to get full access to eliAR and enhance your experiences":"지금 업그레이드하여 eliAR에 대한 전체 액세스 권한을 얻고 경험을 향상시키십시오",
                "Need help with selecting a package?":"패키지 선택에 도움이 필요하신가요?",
                "Please contact us":"문의하시기 바랍니다",
                "Exhibit":"전시하다",
                "Audio":"오디오",
                "Video":"동영상",
                "Gallery":"갤러리",
                "per month / element":"월/요소당",
                "per month":"달마다",
                "Multimedia Animation":"멀티미디어 애니메이션",
                "Basic White Label":"베이직 화이트 라벨",
                "Museum":"박물관",
                "<0>Custom</0> element":"<0>맞춤</0> 요소",
                "<0>Multiple</0> resource per element":"요소당 <0>여러</0> 리소스",
                "<0>Custom</0> resource":"<0>맞춤</0> 리소스",
                "<0>Custom</0> Services":"<0>맞춤</0> 서비스",
                "Passcode for the day":"오늘의 비밀번호",
                "Generate Passcode for the day":"오늘의 비밀번호 생성",
                "views":"견해",
                "Required":"필수의",
                "Too Short!":"너무 짧아!",
                "Too Long!":"너무 오래!",
                "Unsupported Format":"지원되지 않는 형식",
                "File too large":"파일이 너무 큽니다",
                "Passwords don't match":"비밀번호가 일치하지 않습니다.",
                "Password confirm is required":"비밀번호 확인이 필요합니다",
                "Text of answer is required":"답변 텍스트가 필요합니다",
                "Are you sure you want to delete these resources?":"이 리소스를 삭제하시겠습니까?",
                "Are you sure you want to delete this resource?":"이 리소스를 삭제하시겠습니까?",
                "Open":"열려 있는",
                "My jobs":"내 직업",
                "Post new job":"새 일자리 게시",
                "Post a job":"채용 게시",
                "Enter title":"제목을 입력하세요",
                "Enter job description":"직업 설명을 입력하세요",
                "Enter a budget":"예산을 입력하세요",
                "Enter job budget":"작업 예산 입력",
                "Enter due date":"마감일을 입력하세요",
                "Current status":"현재 상태",
                "Required resource type":"필수 리소스 유형",
                "Resource type required":"리소스 유형이 필요합니다.",
                "Upload visual expectation":"시각적 기대 업로드",
                "Assigned to":"할당",
                "Budget":"예산",
                "Languages":"언어",
                "Posted {{$created_at}}":"게시됨 {{$created_at}}",
                "See details":"자세히보다",
                "Edit job":"작업 편집",
                "Bids":"입찰",
                "Bid details":"입찰 세부정보",
                "Comments":"코멘트",
                "Approve job":"작업 승인",
                "Cover letter":"자기소개서",
                "Proposed timeline":"제안된 타임라인",
                "Proposed budget":"제안된 예산",
                "Open Jobs":"오픈 채용",
                "View job":"작업 보기",
                "Create a bid":"입찰 생성",
                "Visual expectations":"시각적 기대",
                "Due date":"마감일",
                "Jobs":"일자리",
                "My bids":"내 입찰가",
                "Enter cover letter":"자기소개서를 입력하세요",
                "Language offered":"제공되는 언어",
                "Attachments":"첨부파일",
                "View bid":"입찰 보기",
                "Submitted":"제출된",
                "Approve":"승인하다",
                "Decline":"감소",
                "Type your comment":"댓글을 입력하세요",
                "Verified for":"확인 대상",
                "Not verified yet":"아직 확인되지 않았습니다",
                "Availability (h/week)":"가용성(시간/주)",
                "Enter availability":"사용 가능 여부 입력",
                "Location":"위치",
                "Member since":"회원 가입일",
                "Timezone":"시간대",
                "Languages you speak":"당신이 말하는 언어",
                "Description":"설명",
                "Work history":"직업 이력",
                "Portfolio":"포트폴리오",
                "Create portfolio item":"포트폴리오 항목 만들기",
                "Enter portfolio item description":"포트폴리오 항목 설명을 입력하세요",
                "Portfolio item description":"포트폴리오 아이템 설명",
                "Open for bidding":"입찰 개시",
                "Closed for bidding":"입찰 마감되었습니다",
                "Feedback pending":"피드백 보류 중",
                "Change acceptance pending":"변경 승인 보류 중",
                "Completed":"완전한",
                "Cancelled":"취소 된",
                "Partially completed":"일부 완료",
                "Partially cancelled":"일부 취소되었습니다",
                "Approved":"승인됨",
                "Rejected":"거부됨",
                "Loading":"로드 중",
                "Model":"모델",
                "Upload a file":"파일을 올리다",
                "Enter job due date":"작업 기한을 입력하세요",
                "Select resource type":"리소스 유형 선택",
                "Updated at":"업데이트 시간",
                "Created at":"생성 날짜",
                "Delete User Account":"사용자 계정 삭제",
                "Get OTP":"OTP 받기",
                "AR Elements": "AR 요소",
                "VR Experiences":"VR 체험",
                "Welcome to RealMeta's":"RealMeta에 오신 것을 환영합니다.",
                "Content Creation Canvas!":"콘텐츠 제작 캔버스!",
                "Unsubscribe from account":"계정 탈퇴",
                "Unsubscribe":"구독 취소",
                "Register now!":"지금 등록하세요!",
                "Forgot Password?":"비밀번호를 잊으 셨나요?",
                "Forgot your password?":"비밀번호를 잊어 버렸습니까?",
                "Receive the link to reset it in your inbox":"받은 편지함에서 재설정할 수 있는 링크를 받습니다",
                "Send reset link": "재설정 링크 보내기",
                "Showing": "전시",
                "items out of":"밖으로 나온 항목들",
                "No more data":"더 이상 데이터가 없습니다",
                "Create Experience":"경험을 창조하고",
                "Upload Unity Space File":"Unity Space 파일 업로드",
                "Help":"돕다",
                "AR Publish":"AR 게시",
                "AR Unpublish":"AR 게시 취소",
                "VR Enable":"VR 할 수 있게 하다",
                "VR Disable":"VR 장애를 입히다",
                "Business Description": "사업 내용",
                "Business External Url": "비즈니스 외부 URL",
                "Add URL": "URL 추가",
                "Enter url": "URL을 입력하세요",
                "Url": "URL",
                "Log In": "로그인",
                "Artist Package":"아티스트 패키지",
                "App Analytics":"앱 분석",
                "Access to RealMeta Digital Studio Team":"RealMeta 디지털 스튜디오 팀에 대한 액세스",
                "Contact Us":"문의하기",
                "Content Creation Tutorial":"콘텐츠 제작 튜토리얼",
                "Collaborate with RealMeta's Grant Application Team **":"RealMeta의 보조금 신청 팀**과 협력합니다.",
                "Dedicated Project Management Team":"전담 프로젝트 관리팀",
                "Everything from Emerging Creator":"Emerging Creator의 모든 것",
                "Emerging creator":"신흥 크리에이터",
                "Established Creator":"설립된 크리에이터",
                "Everything from Professional Creator":"Professional Creator의 모든 것",
                "Free Updates and Upgrades":"무료 업데이트 및 업그레이드",
                "Free Creator":"무료 창작자",
                "Free augmentation for 25 Exhibits":"25개 전시물 무료 증설",
                "Free Training and Support":"무료 교육 및 지원",
                "Free Museum Migration":"무료 박물관 이전",
                "Free Listing on MetaVisitAR":"MetaVisitAR 무료 등록",
                "Gift Shop Integration":"선물 가게 통합",
                "Minimum 1 Year Commitment":"최소 1년 약정",
                "Marketing on RealMeta Social Media":"RealMeta 소셜 미디어 마케팅",
                "Object Recognition":"객체 인식",
                "Professional Creator":"전문 크리에이터",
                "Pre-qualification for a minimum 3 Year Commitment":"최소 3년 약정에 대한 사전 자격",
                "Real MetaVerse Content Creation for VR":"VR을 위한 실제 MetaVerse 콘텐츠 제작",
                "Scripting on Media Creation":"미디어 생성에 대한 스크립팅",
                "Ticketing Integration":"티켓팅 통합",
                "There is your current package":"현재 패키지가 있습니다.",
                "Unlimited Augmented Immersive Experiences":"무제한 증강 몰입형 경험",
                "Unlimited Visitors on MetaVisitAR *":"MetaVisitAR의 무제한 방문자 *",
                "(USD) per month":"(USD)/월",
                "3D Model creation of up to 3 Gallery spaces":"최대 3개의 갤러리 공간에 대한 3D 모델 생성",
                "30 Days Free Trial":"30일 무료 평가판",
                "Hindi":"힌디 어",
                "Elements without resources":"리소스가 없는 요소",
                "Delete Experiences":"경험 삭제",
                "Digital Prints":"디지털 인쇄",
                "Digital Print":"디지털 인쇄",
                "30 Days Free Trail":"30일 무료 트레일",
                "Create Digital Print":"디지털 인쇄 만들기",
                "Add Digital Print":"디지털 인쇄 추가",
                "Value Of Digital Print":"디지털 인쇄의 가치",
                "Number Of Available Digital Print":"사용 가능한 디지털 인쇄 수",
                "Orders":"명령",
                "Order ID":"주문 아이디",
                "Order Placed":"주문 완료",
                "Product Name":"상품명",
                "Quantity":"수량",
                "Price":"가격",
                "Order By": "주문",
                "Total Price": "총 가격"
            }
        },
        ar: {
            translations: {
                "Canvas language":"لغة القماش",
                "Russian":"الروسية",
                "English":"إنجليزي",
                "French":"فرنسي",
                "Chinese":"صينى",
                "Portuguese":"البرتغالية",
                "German": "ألمانية",
                "Korean": "الكورية",
                "Arabic":"عربي",
                "Show only without resources":"عرض فقط بدون موارد",
                "Get Help":"احصل على مساعدة",
                "Delete Elements":"حذف العناصر",
                "Create Element":"إنشاء عنصر",
                "has {{resources_count}} resources":"لديه {{resources_count}} من الموارد",
                "has {{trainings_length}} identifiers":"يحتوي على معرفات {{trainings_length}}.",
                "Search":"يبحث",
                "Edit Element":"تحرير العنصر",
                "Gamification":"التلعيب",
                "Statistics":"إحصائيات",
                "Element":"عنصر",
                "Element Statistics":"إحصائيات العناصر",
                "Daily":"يوميًا",
                "Hourly":"ساعيا",
                "The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)":"تم اكتشاف العنصر {{totalWeekCount}} مرة في الأسبوع الماضي. <br/>({{weekDiff}}% عن الأسبوع السابق)",
                "The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)":"تم اكتشاف العنصر {{totalDayCount}} مرة في اليوم الماضي. <br/>({{dayDiff}}% عن اليوم السابق)",
                "Edit":"يحرر",
                "Create A":"إنشاء",
                "quiz":"اختبار",
                "Quiz":"اختبار",
                "Please enter question":"الرجاء إدخال السؤال",
                "Please enter answer explanation":"الرجاء إدخال تفسير الإجابة",
                "Answers":"الإجابات",
                "Add new answer":"أضف إجابة جديدة",
                "Delete":"يمسح",
                "Reset":"إعادة ضبط",
                "Save":"يحفظ",
                "Create":"يخلق",
                "Required for publishing an element":"مطلوب لنشر عنصر",
                "Additional/ Detailed information about the correct answer, that you want the user to know":"معلومات إضافية/تفصيلية حول الإجابة الصحيحة التي تريد أن يعرفها المستخدم",
                "Element Name":"اسم العنصر",
                "Element Description":"وصف العنصر",
                "Element Identifier":"معرف العنصر",
                "Unpublish":"إلغاء النشر",
                "Publish":"ينشر",
                "Enter Name":"أدخل الاسم",
                "Enter Description":"أدخل الوصف",
                "Resources":"موارد",
                "Identifiers":"معرفات",
                "Filters":"المرشحات",
                "Filter":"منقي",
                "Reset filters":"إعادة تعيين المرشحات",
                "Amount of the gems which user will earn after selecting correct answer":"مقدار الجواهر التي سيكسبها المستخدم بعد اختيار الإجابة الصحيحة",
                "Please enter points":"الرجاء إدخال النقاط",
                "Add New tag":"إضافة علامة جديدة",
                "Create resource in this particular language.":"قم بإنشاء مورد بهذه اللغة بالذات.",
                "Select language":"اختار اللغة",
                "Show only linked resources":"إظهار الموارد المرتبطة فقط",
                "Searching":"يبحث",
                "Reset search":"إعادة ضبط البحث",
                "Delete Resource":"حذف المورد",
                "Yes":"نعم",
                "No":"لا",
                "Create Resource":"إنشاء الموارد",
                "All":"الجميع",
                "Images":"الصور",
                "Image":"صورة",
                "Videos":"أشرطة فيديو",
                "Audios":"صوتيات",
                "Texts":"النصوص",
                "3d models":"نماذج ثلاثية الأبعاد",
                "Animations":"الرسوم المتحركة",
                "Select a Resource or Identifier to see the details here":"حدد موردًا أو معرفًا لرؤية التفاصيل هنا",
                "Create Identifier":"إنشاء معرف",
                "Link":"وصلة",
                "Linked":"مرتبط",
                "Add Resource":"إضافة الموارد",
                "Capture Photo":"التقاط الصورة",
                "Capture Video":"التقاط الفديو",
                "Record Audio":"تسجيل صوتي",
                "Upload 3D model":"تحميل نموذج ثلاثي الأبعاد",
                "Text":"نص",
                "Create Animation":"إنشاء الرسوم المتحركة",
                "Choose file":"اختر ملف",
                "Choose File":"اختر ملف",
                "Enter resource name":"أدخل اسم المورد",
                "To use pre existing files as resources, please upload here...":"لاستخدام الملفات الموجودة مسبقًا كموارد، يرجى تحميلها هنا...",
                "Or create a resource from your device":"أو قم بإنشاء مورد من جهازك",
                "Request Creation":"طلب إنشاء",
                "Note : Please Upload only Audio File":"ملحوظة: يرجى تحميل الملف الصوتي فقط",
                "Note : Please Upload only GIF File":"ملحوظة: يرجى تحميل ملف GIF فقط",
                "Edit Resource":"تحرير المورد",
                "Language":"لغة",
                "The resource was discovered {{totalWeekCount}} times last week.":"تم اكتشاف المورد {{totalWeekCount}} مرة في الأسبوع الماضي.",
                "The resource was discovered {{totalDayCount}} times last day.":"تم اكتشاف المورد {{totalDayCount}} مرة في اليوم الماضي.",
                "from the previous week":"من الاسبوع السابق",
                "Hourly views":"مشاهدات كل ساعة",
                "Daily views":"مشاهدات يومية",
                "View Identifier":"عرض المعرف",
                "Active":"نشيط",
                "Are you sure you want to sign out?":"هل أنت متأكد أنك تريد الخروج؟",
                "Home":"بيت",
                "Profile":"حساب تعريفي",
                "Theme":"سمة",
                "Logout":"تسجيل خروج",
                "Published":"نشرت",
                "Draft":"مسودة",
                "Status":"حالة",
                "more":"أكثر",
                "Element and Resources Primary language":"العنصر والموارد اللغة الأساسية",
                "Business information":"معلومات العمل",
                "Invite user":"دعوة المستخدم",
                "Business Name":"الاسم التجاري",
                "First Name":"الاسم الأول",
                "Last Name":"اسم العائلة",
                "Enter A Location":"أدخل الموقع",
                "There is your current package.":"هناك الحزمة الحالية الخاصة بك.",
                "package":"طَرد",
                "Personal information":"معلومات شخصية",
                "Upgrade":"يرقي",
                "Are you sure you want to request creation?":"هل أنت متأكد أنك تريد طلب الإنشاء؟",
                "Select role":"حدد الدور",
                "Bussiness members":"أعضاء الأعمال",
                "Invite":"يدعو",
                "Invite users to your bussiness":"دعوة المستخدمين إلى عملك",
                "Please Enter Email":"الرجاء إدخال البريد الإلكتروني",
                "Back To Login":"العودة إلى تسجيل الدخول",
                "Visit":"يزور",
                "Login into CANVAS":"قم بتسجيل الدخول إلى CANVAS",
                "Your potion to create magic!":"جرعتك لخلق السحر!",
                "Enter Username or Email":"أدخل اسم المستخدم أو البريد الإلكتروني",
                "Enter Password":"أدخل كلمة المرور",
                "Submit":"يُقدِّم",
                "Forgot Password":"هل نسيت كلمة السر",
                "LogIn":"تسجيل الدخول",
                "Login":"تسجيل الدخول",
                "Don’t have an account yet?":"لا تملك حسابا حتى الآن؟",
                "Register":"يسجل",
                "now!":"الآن!",
                "Getting started with CANVAS":"البدء مع CANVAS",
                "Enter email":"أدخل البريد الإلكتروني",
                "Repeat Password":"اعد كلمة السر",
                "Let’s prepare to <br/>create your accont":"فلنستعد <br/>لإنشاء حسابك",
                "It’s only a few clicks away!":"إنها على بعد نقرات قليلة فقط!",
                "Welcome back to":"مرحبا بكم مرة أخرى في",
                "Content Creation platform":"منصة صناعة المحتوى",
                "Reset password":"إعادة تعيين كلمة المرور",
                "All Rights Reserved":"كل الحقوق محفوظة",
                "Already have an account?":"هل لديك حساب؟",
                "Click to get help on Gamification":"انقر للحصول على مساعدة بشأن Gamification",
                "Add Identifier":"إضافة معرف",
                "Please wait, identifier is uploading...":"الرجاء الانتظار جاري تحميل المعرف...",
                "Upload":"رفع",
                "uploaded":"تم الرفع",
                "Details":"تفاصيل",
                "Single":"أعزب",
                "Resource":"الموارد",
                "Up to 10":"ما يصل إلى 10",
                "elements":"عناصر",
                "resource per element":"الموارد لكل عنصر",
                "Up to 50":"ما يصل إلى 50",
                "Multiple":"عديد",
                "Custom":"مخصص",
                "Services":"خدمات",
                "Level up your experience":"ارفع مستوى تجربتك",
                "Upgrade now to get full access to eliAR and enhance your experiences":"قم بالترقية الآن لتتمتع بإمكانية الوصول الكامل إلى eliAR وتعزيز تجاربك",
                "Need help with selecting a package?":"هل تحتاج إلى مساعدة في اختيار الحزمة؟",
                "Please contact us":"الرجاء التواصل معنا",
                "Exhibit":"يعرض",
                "Audio":"صوتي",
                "Video":"فيديو",
                "Gallery":"صالة عرض",
                "per month / element":"شهريا / عنصر",
                "per month":"كل شهر",
                "Multimedia Animation":"الوسائط المتعددة الرسوم المتحركة",
                "Basic White Label":"العلامة البيضاء الأساسية",
                "Museum":"متحف",
                "<0>Custom</0> element":"عنصر <0>مخصص</0>",
                "<0>Multiple</0> resource per element":"<0>متعدد</0> مورد لكل عنصر",
                "<0>Custom</0> resource":"مورد <0>مخصص</0>",
                "<0>Custom</0> Services":"خدمات <0>مخصصة</0>",
                "Passcode for the day":"رمز المرور لهذا اليوم",
                "Generate Passcode for the day":"إنشاء رمز المرور لهذا اليوم",
                "views":"الآراء",
                "Required":"مطلوب",
                "Too Short!":"قصير جدا!",
                "Too Long!":"طويل جدا!",
                "Unsupported Format":"صيغة غير مدعومة",
                "File too large":"الملف كبير جدًا",
                "Passwords don't match":"كلمات المرور غير متطابقة",
                "Password confirm is required":"مطلوب تأكيد كلمة المرور",
                "Text of answer is required":"نص الرد مطلوب",
                "Are you sure you want to delete these resources?":"هل أنت متأكد أنك تريد حذف هذه الموارد؟",
                "Are you sure you want to delete this resource?":"هل أنت متأكد أنك تريد حذف هذا المورد؟",
                "Open":"يفتح",
                "My jobs":"وظائفي",
                "Post new job":"نشر وظيفة جديدة",
                "Post a job":"انشر وظيفة",
                "Enter title":"أدخل العنوان",
                "Enter job description":"أدخل الوصف الوظيفي",
                "Enter a budget":"أدخل الميزانية",
                "Enter job budget":"أدخل ميزانية الوظيفة",
                "Enter due date":"أدخل تاريخ الاستحقاق",
                "Current status":"الحالة الحالية",
                "Required resource type":"نوع المورد المطلوب",
                "Resource type required":"نوع المورد مطلوب",
                "Upload visual expectation":"تحميل التوقع البصري",
                "Assigned to":"مخصص ل",
                "Budget":"ميزانية",
                "Languages":"اللغات",
                "Posted {{$created_at}}":"تم النشر {{$created_at}}",
                "See details":"انظر التفاصيل",
                "Edit job":"تحرير الوظيفة",
                "Bids":"العطاءات",
                "Bid details":"تفاصيل العطاء",
                "Comments":"تعليقات",
                "Approve job":"الموافقة على الوظيفة",
                "Cover letter":"غطاء الرسالة",
                "Proposed timeline":"الجدول الزمني المقترح",
                "Proposed budget":"الميزانية المقترحة",
                "Open Jobs":"وظائف مفتوحة",
                "View job":"عرض الوظيفة",
                "Create a bid":"قم بإنشاء عرض",
                "Visual expectations":"التوقعات البصرية",
                "Due date":"تاريخ الاستحقاق",
                "Jobs":"وظائف",
                "My bids":"عطاءاتي",
                "Enter cover letter":"أدخل رسالة الغلاف",
                "Language offered":"اللغة المقدمة",
                "Attachments":"المرفقات",
                "View bid":"عرض العطاء",
                "Submitted":"مُقَدَّم",
                "Approve":"يعتمد",
                "Decline":"انخفاض",
                "Type your comment":"اكتب تعليقك",
                "Verified for":"تم التحقق ل",
                "Not verified yet":"لم يتم التحقق منها بعد",
                "Availability (h/week)":"التوفر (ساعة/أسبوع)",
                "Enter availability":"أدخل التوفر",
                "Location":"موقع",
                "Member since":"عضو منذ",
                "Timezone":"وحدة زمنية",
                "Languages you speak":"اللغات التي تتحدثها",
                "Description":"وصف",
                "Work history":"تاريخ العمل",
                "Portfolio":"مَلَفّ",
                "Create portfolio item":"إنشاء عنصر المحفظة",
                "Enter portfolio item description":"أدخل وصف عنصر المحفظة",
                "Portfolio item description":"وصف عناصر المحفظة",
                "Open for bidding":"مفتوحة للمزايدة",
                "Closed for bidding":"مغلق للمزايدة",
                "Feedback pending":"التعليقات في انتظار المراجعة",
                "Change acceptance pending":"قبول التغيير معلق",
                "Completed":"مكتمل",
                "Cancelled":"ألغيت",
                "Partially completed":"أنجزت جزئيا",
                "Partially cancelled":"ألغيت جزئيا",
                "Approved":"موافقة",
                "Rejected":"مرفوض",
                "Loading":"تحميل",
                "Model":"نموذج",
                "Upload a file":"تحميل ملف",
                "Enter job due date":"أدخل تاريخ استحقاق الوظيفة",
                "Select resource type":"حدد نوع المورد",
                "Updated at":"تم التحديث في",
                "Created at":"أنشئت في",
                "Delete User Account":"حذف حساب المستخدم",
                "Get OTP":"احصل على كلمة مرور لمرة واحدة",
                "AR Elements":"عناصر الواقع المعزز",
                "VR Experiences":"تجارب الواقع الافتراضي",
                "Welcome to RealMeta's":"مرحبًا بك في RealMeta",
                "Content Creation Canvas!":"قماش إنشاء المحتوى!",
                "Unsubscribe from account":"إلغاء الاشتراك من الحساب",
                "Unsubscribe":"إلغاء الاشتراك",
                "Register now!":"سجل الان!",
                "Forgot Password?":"هل نسيت كلمة السر؟",
                "Forgot your password?":"نسيت كلمة السر؟",
                "Receive the link to reset it in your inbox":"احصل على الرابط لإعادة تعيينه في صندوق الوارد الخاص بك",
                "Send reset link":"إرسال رابط إعادة التعيين",
                "Showing":"عرض",
                "items out of":"العناصر خارج",
                "No more data":"لا مزيد من البيانات",
                "Create Experience":"إنشاء الخبرة",
                "Upload Unity Space File":"تحميل ملف مساحة الوحدة",
                "Help":"يساعد",
                "AR Publish":"نشر AR",
                "AR Unpublish":"إلغاء النشر بالواقع المعزز",
                "VR Enable":"تمكين الواقع الافتراضي",
                "VR Disable":"تعطيل الواقع الافتراضي",
                "Business Description":"وصف العمل",
                "Business External Url":"عنوان URL الخارجي للأعمال",
                "Add URL":"إضافة رابط",
                "Enter url":"إدخال عنوان الموقع",
                "Url":"عنوان URL",
                "Log In": "تسجيل الدخول",
                "Artist Package":"حزمة الفنان",
                "App Analytics":"تحليلات التطبيقات",
                "Access to RealMeta Digital Studio Team":"الوصول إلى فريق RealMeta Digital Studio",
                "Contact Us":"اتصل بنا",
                "Content Creation Tutorial":"دروس إنشاء المحتوى",
                "Collaborate with RealMeta's Grant Application Team **":"تعاون مع فريق طلبات المنح الخاص بـ RealMeta **",
                "Dedicated Project Management Team":"فريق إدارة المشروع المخصص",
                "Everything from Emerging Creator":"كل شيء من المبدع الناشئ",
                "Emerging creator":"المبدع الناشئ",
                "Established Creator":"مبدع مؤسس",
                "Everything from Professional Creator":"كل شيء من المبدع المحترف",
                "Free Updates and Upgrades":"تحديثات وترقيات مجانية",
                "Free Creator":"منشئ مجاني",
                "Free augmentation for 25 Exhibits":"زيادة مجانية لـ 25 معرضًا",
                "Free Training and Support":"التدريب والدعم المجاني",
                "Free Museum Migration":"هجرة المتحف الحرة",
                "Free Listing on MetaVisitAR":"قائمة مجانية على MetaVisitAR",
                "Gift Shop Integration":"تكامل متجر الهدايا",
                "Minimum 1 Year Commitment":"الحد الأدنى للالتزام لمدة عام واحد",
                "Marketing on RealMeta Social Media":"التسويق على وسائل التواصل الاجتماعي RealMeta",
                "Object Recognition":"التعرف على الأشياء",
                "Professional Creator":"المبدع المحترف",
                "Pre-qualification for a minimum 3 Year Commitment":"التأهيل المسبق لالتزام لا يقل عن 3 سنوات",
                "Real MetaVerse Content Creation for VR":"إنشاء محتوى حقيقي لـ MetaVerse للواقع الافتراضي",
                "Scripting on Media Creation":"البرمجة النصية لإنشاء الوسائط",
                "Ticketing Integration":"تكامل التذاكر",
                "There is your current package":"هناك الحزمة الحالية الخاصة بك",
                "Unlimited Augmented Immersive Experiences":"تجارب غامرة معززة غير محدودة",
                "Unlimited Visitors on MetaVisitAR *":"عدد غير محدود من الزوار على MetaVisitAR *",
                "(USD) per month":"(دولار أمريكي) شهريًا",
                "3D Model creation of up to 3 Gallery spaces":"إنشاء نموذج ثلاثي الأبعاد لما يصل إلى 3 مساحات للمعرض",
                "30 Days Free Trial":"تجربة مجانية لمدة 30 يومًا",
                "Hindi":"الهندية",
                "Elements without resources":"عناصر بلا موارد",
                "Delete Experiences":"حذف الخبرات",
                "Digital Prints":"المطبوعات الرقمية",
                "Digital Print":"طباعة رقمية",
                "30 Days Free Trail":"رحلة مجانية لمدة 30 يومًا",
                "Create Digital Print":"إنشاء الطباعة الرقمية",
                "Add Digital Print":"أضف الطباعة الرقمية",
                "Value Of Digital Print":"قيمة الطباعة الرقمية",
                "Number Of Available Digital Print":"عدد الطباعة الرقمية المتاحة",
                "Orders":"طلبات",
                "Order ID":"رقم التعريف الخاص بالطلب",
                "Order Placed":"تم الطلب",
                "Product Name":"اسم المنتج",
                "Quantity":"كمية",
                "Price":"سعر",
                "Order By": "ترتيب حسب",
                "Total Price": "السعر الكلي"
            }
        },
        hi: {
            translations: {
                "Canvas language":"कैनवास भाषा",
                "Russian":"रूसी",
                "English":"अंग्रेज़ी",
                "French":"फ़्रेंच",
                "Chinese":"चीनी",
                "Portuguese":"पुर्तगाली",
                "Show only without resources":"बिना संसाधनों के ही दिखाएँ",
                "Get Help":"मदद लें",
                "Delete Elements":"तत्व हटाएँ",
                "Create Element":"तत्व बनाएं",
                "has {{resources_count}} resources":"{{resources_count}} संसाधन हैं",
                "has {{trainings_length}} identifiers":"{{trainings_length}} पहचानकर्ता हैं",
                "Search":"खोज",
                "Edit Element":"तत्व संपादित करें",
                "Gamification":"सरलीकरण",
                "Statistics":"सांख्यिकी",
                "Element":"तत्व",
                "Element Statistics":"तत्व सांख्यिकी",
                "Daily":"दैनिक",
                "Hourly":"प्रति घंटा",
                "The element was discovered {{totalWeekCount}} times last week. <br/>({{weekDiff}}% from the previous week)":"तत्व को पिछले सप्ताह {{totalWeekCount}} बार खोजा गया था। <br/>(पिछले सप्ताह से {{weekDiff%)%)",
                "The element was discovered {{totalDayCount}} times last day. <br/>({{dayDiff}}% from the previous day)":"तत्व को पिछले दिन {{totalDayCount}} बार खोजा गया था। <br/>(पिछले दिन से {{dayDiff}}%)",
                "Edit":"संपादन करना",
                "Create A":"एक बनाने के",
                "quiz":"प्रश्नोत्तरी",
                "Quiz":"प्रश्नोत्तरी",
                "Please enter question":"कृपया प्रश्न दर्ज करें",
                "Please enter answer explanation":"कृपया उत्तर स्पष्टीकरण दर्ज करें",
                "Answers":"उत्तर",
                "Add new answer":"नया उत्तर जोड़ें",
                "Delete":"मिटाना",
                "Reset":"रीसेट",
                "Save":"बचाना",
                "Create":"बनाएं",
                "Required for publishing an element":"किसी तत्व को प्रकाशित करने के लिए आवश्यक है",
                "Additional/ Detailed information about the correct answer, that you want the user to know":"सही उत्तर के बारे में अतिरिक्त/विस्तृत जानकारी जो आप उपयोगकर्ता को बताना चाहते हैं",
                "Element Name":"तत्व का नाम",
                "Element Description":"तत्व विवरण",
                "Element Identifier":"तत्व पहचानकर्ता",
                "Unpublish":"अप्रकाशित करें",
                "Publish":"प्रकाशित करें",
                "Enter Name":"नाम दर्ज करें",
                "Enter Description":"विवरण दर्ज करें",
                "Resources":"संसाधन",
                "Identifiers":"पहचानकर्ता",
                "Filters":"फिल्टर",
                "Filter":"फ़िल्टर",
                "Reset filters":"फ़िल्टर रीसेट करें",
                "Amount of the gems which user will earn after selecting correct answer":"रत्नों की वह राशि जो उपयोगकर्ता सही उत्तर चुनने के बाद अर्जित करेगा",
                "Please enter points":"कृपया अंक दर्ज करें",
                "Add New tag":"नया टैग जोड़ें",
                "Create resource in this particular language.":"इस विशेष भाषा में संसाधन बनाएं।",
                "Select language":"भाषा चुने",
                "Show only linked resources":"केवल लिंक किए गए संसाधन दिखाएं",
                "Searching":"खोज कर",
                "Reset search":"खोज रीसेट करें",
                "Delete Resource":"संसाधन हटाएँ",
                "Yes":"हाँ",
                "No":"नहीं",
                "Create Resource":"संसाधन बनाएँ",
                "All":"सभी",
                "Images":"इमेजिस",
                "Image":"छवि",
                "Videos":"वीडियो",
                "Audios":"ऑडियो",
                "Texts":"ग्रंथ",
                "3d models":"3डी मॉडल",
                "Animations":"एनिमेशन",
                "Select a Resource or Identifier to see the details here":"यहां विवरण देखने के लिए एक संसाधन या पहचानकर्ता का चयन करें",
                "Create Identifier":"पहचानकर्ता बनाएं",
                "Link":"जोड़ना",
                "Linked":"जुड़े हुए",
                "Add Resource":"संसाधन जोड़ें",
                "Capture Photo":"फ़ोटो कैप्चर करें",
                "Capture Video":"वीडियो बनाओ",
                "Record Audio":"ध्वनि रिकॉर्ड करें",
                "Upload 3D model":"3D मॉडल अपलोड करें",
                "Text":"मूलपाठ",
                "Create Animation":"एनीमेशन बनाएं",
                "Choose file":"फाइलें चुनें",
                "Choose File":"फाइलें चुनें",
                "Enter resource name":"संसाधन नाम दर्ज करें",
                "To use pre existing files as resources, please upload here...":"पहले से मौजूद फ़ाइलों को संसाधन के रूप में उपयोग करने के लिए कृपया यहां अपलोड करें...",
                "Or create a resource from your device":"या अपने डिवाइस से एक संसाधन बनाएं",
                "Request Creation":"निर्माण का अनुरोध करें",
                "Note : Please Upload only Audio File":"नोट: कृपया केवल ऑडियो फ़ाइल अपलोड करें",
                "Note : Please Upload only GIF File":"नोट: कृपया केवल GIF फ़ाइल अपलोड करें",
                "Edit Resource":"संसाधन संपादित करें",
                "Language":"भाषा",
                "The resource was discovered {{totalWeekCount}} times last week.":"संसाधन को पिछले सप्ताह {{totalWeekCount}} बार खोजा गया था।",
                "The resource was discovered {{totalDayCount}} times last day.":"पिछले दिन संसाधन को {{totalDayCount}} बार खोजा गया था।",
                "from the previous week":"पिछले सप्ताह से",
                "Hourly views":"प्रति घंटा दृश्य",
                "Daily views":"दैनिक दृश्य",
                "View Identifier":"पहचानकर्ता देखें",
                "Active":"सक्रिय",
                "Are you sure you want to sign out?":"क्या आप द्वारा साइन आउट किया जाना सुनिश्चित है?",
                "Home":"घर",
                "Profile":"प्रोफ़ाइल",
                "Theme":"विषय",
                "Logout":"लॉग आउट",
                "Published":"प्रकाशित",
                "Draft":"मसौदा",
                "Status":"स्थिति",
                "more":"अधिक",
                "Element and Resources Primary language":"तत्व और संसाधन प्राथमिक भाषा",
                "Business information":"व्यवसाय जानकारी",
                "Invite user":"उपयोगकर्ता को आमंत्रित करें",
                "Business Name":"व्यवसाय का नाम",
                "First Name":"पहला नाम",
                "Last Name":"उपनाम",
                "Enter A Location":"एक स्थान दर्ज करें",
                "There is your current package.":"आपका वर्तमान पैकेज है।",
                "package":"पैकेट",
                "Personal information":"व्यक्तिगत जानकारी",
                "Upgrade":"उन्नत करना",
                "Are you sure you want to request creation?":"क्या आप वाकई सृजन का अनुरोध करना चाहते हैं?",
                "Select role":"भूमिका चुनें",
                "Bussiness members":"व्यवसाय सदस्य",
                "Invite":"आमंत्रित करना",
                "Invite users to your bussiness":"उपयोगकर्ताओं को अपने व्यवसाय में आमंत्रित करें",
                "Please Enter Email":"कृपया ईमेल दर्ज करें",
                "Back To Login":"लॉगिन पर वापस जाएं",
                "Visit":"मिलने जाना",
                "Login into CANVAS":"कैनवास में लॉगिन करें",
                "Your potion to create magic!":"जादू पैदा करने की आपकी औषधि!",
                "Enter Username or Email":"उपयोगकर्ता नाम या ईमेल दर्ज करें",
                "Enter Password":"पास वर्ड दर्ज करें",
                "Submit":"जमा करना",
                "Forgot Password":"पासवर्ड भूल गए",
                "LogIn":"लॉग इन करें",
                "Login":"लॉग इन करें",
                "Don’t have an account yet?":"अब तक कोई खाता नहीं है?",
                "Register":"पंजीकरण करवाना",
                "now!":"अब!",
                "Getting started with CANVAS":"कैनवास के साथ शुरुआत करना",
                "Enter email":"ईमेल दर्ज करें",
                "Repeat Password":"पासवर्ड दोहराएं",
                "Let’s prepare to <br/>create your accont":"चलिए अपना अकाउंट बनाने के लिए तैयारी करें",
                "It’s only a few clicks away!":"यह केवल कुछ ही क्लिक दूर है!",
                "Welcome back to":"वापसी पर स्वागत है",
                "Content Creation platform":"सामग्री निर्माण मंच",
                "Reset password":"पासवर्ड रीसेट",
                "All Rights Reserved":"सर्वाधिकार सुरक्षित",
                "Already have an account?":"क्या आपके पास पहले से एक खाता मौजूद है?",
                "Click to get help on Gamification":"Gamification पर सहायता प्राप्त करने के लिए क्लिक करें",
                "Add Identifier":"पहचानकर्ता जोड़ें",
                "Please wait identifier is uploading...":"कृपया प्रतीक्षा करें पहचानकर्ता अपलोड हो रहा है...",
                "Upload":"डालना",
                "uploaded":"अपलोड किया गया",
                "Details":"विवरण",
                "Single":"अकेला",
                "Resource":"संसाधन",
                "Up to 10":"10 तक",
                "elements":"तत्व",
                "resource per element":"प्रति तत्व संसाधन",
                "Up to 50":"50 तक",
                "Multiple":"एकाधिक",
                "Custom":"रिवाज़",
                "Services":"सेवाएँ",
                "Level up your experience":"अपने अनुभव का स्तर बढ़ाएँ",
                "Upgrade now to get full access to eliAR and enhance your experiences":"eliAR तक पूर्ण पहुंच प्राप्त करने और अपने अनुभवों को बढ़ाने के लिए अभी अपग्रेड करें",
                "Need help with selecting a package?":"पैकेज चुनने में मदद चाहिए?",
                "Please contact us":"कृपया हमसे संपर्क करें",
                "Exhibit":"दिखाना",
                "Audio":"ऑडियो",
                "Video":"वीडियो",
                "Gallery":"गैलरी",
                "per month / element":"प्रति माह/तत्व",
                "per month":"प्रति महीने",
                "Multimedia Animation":"मल्टीमीडिया एनिमेशन",
                "Basic White Label":"बेसिक व्हाइट लेबल",
                "Museum":"संग्रहालय",
                "<0>Custom</0> element":"<0>कस्टम</0> तत्व",
                "<0>Multiple</0> resource per element":"प्रति तत्व <0>एकाधिक</0> संसाधन",
                "<0>Custom</0> resource":"<0>कस्टम</0> संसाधन",
                "<0>Custom</0> Services":"<0>कस्टम</0> सेवाएँ",
                "Passcode for the day":"दिन के लिए पासकोड",
                "Generate Passcode for the day":"दिन के लिए पासकोड जनरेट करें",
                "views":"विचार",
                "Required":"आवश्यक",
                "Too Short!":"बहुत छोटा!",
                "Too Long!":"बहुत लंबा!",
                "Unsupported Format":"असमर्थित प्रारूप",
                "File too large":"बहुत बड़ी फाइल",
                "Passwords don't match":"पासवर्ड मेल नहीं खाते",
                "Password confirm is required":"पासवर्ड पुष्टि आवश्यक है",
                "Text of answer is required":"उत्तर का पाठ आवश्यक है",
                "Are you sure you want to delete these resources?":"क्या आप वाकई इन संसाधनों को हटाना चाहते हैं?",
                "Are you sure you want to delete this resource?":"क्या आप वाकई इस संसाधन को हटाना चाहते हैं?",
                "Open":"खुला",
                "My jobs":"मेरे काम",
                "Post new job":"नई नौकरी पोस्ट करें",
                "Post a job":"एक नौकरी पोस्ट करो",
                "Enter title":"शीर्षक दर्ज करें",
                "Enter job description":"नौकरी विवरण दर्ज करें",
                "Enter a budget":"एक बजट दर्ज करें",
                "Enter job budget":"नौकरी का बजट दर्ज करें",
                "Enter due date":"देय तिथि दर्ज करें",
                "Current status":"वर्तमान स्थिति",
                "Required resource type":"आवश्यक संसाधन प्रकार",
                "Resource type required":"संसाधन प्रकार आवश्यक",
                "Upload visual expectation":"दृश्य अपेक्षा अपलोड करें",
                "Assigned to":"को सौंपना",
                "Budget":"बजट",
                "Languages":"भाषाएँ",
                "Posted {{$created_at}}":"{{$created_at}} पोस्ट किया गया",
                "See details":"विस्तृत जानकारी देखें",
                "Edit job":"कार्य संपादित करें",
                "Bids":"बोली",
                "Bid details":"बोली विवरण",
                "Comments":"टिप्पणियाँ",
                "Approve job":"नौकरी स्वीकृत करें",
                "Cover letter":"कवर लेटर",
                "Proposed timeline":"प्रस्तावित समयरेखा",
                "Proposed budget":"प्रस्तावित बजट",
                "Open Jobs":"नौकरियाँ खोलें",
                "View job":"नौकरी देखें",
                "Create a bid":"एक बोली बनाएं",
                "Visual expectations":"दृश्य अपेक्षाएँ",
                "Due date":"नियत तारीख",
                "Jobs":"नौकरियां",
                "My bids":"मेरी बोली",
                "Enter cover letter":"कवर लेटर दर्ज करें",
                "Language offered":"भाषा की पेशकश की",
                "Attachments":"अनुलग्नक",
                "View bid":"बोली देखें",
                "Submitted":"प्रस्तुत",
                "Approve":"मंज़ूरी देना",
                "Decline":"गिरावट",
                "Type your comment":"अपनी टिप्पणी टाइप करें",
                "Verified for":"के लिए सत्यापित",
                "Not verified yet":"अभी तक सत्यापित नहीं",
                "Availability (h/week)":"उपलब्धता (घंटे/सप्ताह)",
                "Enter availability":"उपलब्धता दर्ज करें",
                "Location":"जगह",
                "Member since":"से सदस्य",
                "Timezone":"समय क्षेत्र",
                "Languages you speak":"भाषाएँ आप बोलते हैं",
                "Description":"विवरण",
                "Work history":"कार्य इतिहास",
                "Portfolio":"पोर्टफोलियो",
                "Create portfolio item":"पोर्टफोलियो आइटम बनाएं",
                "Enter portfolio item description":"पोर्टफोलियो आइटम विवरण दर्ज करें",
                "Portfolio item description":"पोर्टफोलियो आइटम विवरण",
                "Open for bidding":"बोली के लिए खुला",
                "Closed for bidding":"बोली लगाने के लिए बंद",
                "Feedback pending":"प्रतिक्रिया लंबित है",
                "Change acceptance pending":"परिवर्तन स्वीकृति लंबित है",
                "Completed":"पुरा होना",
                "Cancelled":"रद्द",
                "Partially completed":"आंशिक रूप से पूरा",
                "Partially cancelled":"आंशिक रूप से रद्द",
                "Approved":"अनुमत",
                "Rejected":"अस्वीकार कर दिया",
                "Loading":"लोड हो रहा है",
                "Model":"नमूना",
                "Upload a file":"एक फ़ाइल अपलोड करें",
                "Enter job due date":"नौकरी की देय तिथि दर्ज करें",
                "Select resource type":"संसाधन प्रकार चुनें",
                "Updated at":"पर अद्यतन किया गया",
                "Created at":"पर बनाया गया",
                "Delete User Account":"उपयोगकर्ता खाता हटाएं",
                "Get OTP":"ओटीपी प्राप्त करें",
                "AR Elements":"एआर तत्व",
                "VR Experiences":"वीआर अनुभव",
                "Welcome to RealMeta's":"RealMeta में आपका स्वागत है",
                "Content Creation Canvas!":"सामग्री निर्माण कैनवास!",
                "Unsubscribe from account":"खाते से सदस्यता समाप्त करें",
                "Unsubscribe":"सदस्यता समाप्त करें",
                "Register now!":"अभी पंजीकरण करें!",
                "Forgot Password?":"पासवर्ड भूल गए?",
                "Forgot your password?":"अपना कूट शब्द भूल गए?",
                "Receive the link to reset it in your inbox":"इसे रीसेट करने के लिए लिंक अपने इनबॉक्स में प्राप्त करें",
                "Send reset link":"रीसेट लिंक भेजें",
                "Showing":"दिखा रहा हूँ",
                "items out of":"आइटम बाहर",
                "No more data":"कोई और डेटा नहीं",
                "Create Experience":"अनुभव बनाएँ",
                "Upload Unity Space File":"यूनिटी स्पेस फ़ाइल अपलोड करें",
                "Help":"मदद करना",
                "AR Publish":"एआर प्रकाशित",
                "AR Unpublish":"एआर अप्रकाशित",
                "VR Enable":"वीआर सक्षम करें",
                "VR Disable":"वीआर अक्षम करें",
                "Business Description":"व्यापार विवरण",
                "Business External Url":"व्यवसाय बाहरी यूआरएल",
                "Add URL":"यूआरएल जोड़ें",
                "Enter url":"यू आर एल दर्ज करो",
                "Url":"यूआरएल",
                "Log In":"लॉग इन करें",
                "Artist Package":"कलाकार पैकेज",
                "App Analytics":"ऐप एनालिटिक्स",
                "Access to RealMeta Digital Studio Team":"RealMeta डिजिटल स्टूडियो टीम तक पहुंच",
                "Contact Us":"संपर्क करें",
                "Content Creation Tutorial":"सामग्री निर्माण ट्यूटोरियल",
                "Collaborate with RealMeta's Grant Application Team **:":"RealMeta की अनुदान आवेदन टीम के साथ सहयोग करें **:",
                "Dedicated Project Management Team":"समर्पित परियोजना प्रबंधन टीम",
                "Everything from Emerging Creator":"उभरते निर्माता से सब कुछ",
                "Emerging creator":"उभरते रचनाकार",
                "Established Creator":"स्थापित निर्माता",
                "Everything from Professional Creator":"पेशेवर निर्माता से सब कुछ",
                "Free Updates and Upgrades":"मुफ़्त अपडेट और अपग्रेड",
                "Free Creator":"स्वतंत्र निर्माता",
                "Free augmentation for 25 Exhibits":"25 प्रदर्शनियों के लिए निःशुल्क वृद्धि",
                "Free Training and Support":"निःशुल्क प्रशिक्षण और सहायता",
                "Free Museum Migration":"मुफ़्त संग्रहालय प्रवास",
                "Free Listing on MetaVisitAR":"MetaVisitAR पर मुफ़्त लिस्टिंग",
                "Gift Shop Integration":"उपहार की दुकान एकीकरण",
                "Minimum 1 Year Commitment":"न्यूनतम 1 वर्ष की प्रतिबद्धता",
                "Marketing on RealMeta Social Media":"रियलमेटा सोशल मीडिया पर मार्केटिंग",
                "Object Recognition":"वस्तु मान्यता",
                "Professional Creator":"पेशेवर निर्माता",
                "Pre-qualification for a minimum 3 Year Commitment":"न्यूनतम 3 वर्ष की प्रतिबद्धता के लिए पूर्व योग्यता",
                "Real MetaVerse Content Creation for VR":"वीआर के लिए वास्तविक मेटावर्स सामग्री निर्माण",
                "Scripting on Media Creation":"मीडिया निर्माण पर स्क्रिप्टिंग",
                "Ticketing Integration":"टिकटिंग एकीकरण",
                "There is your current package":"आपका वर्तमान पैकेज है",
                "Unlimited Augmented Immersive Experiences":"असीमित संवर्धित इमर्सिव अनुभव",
                "Unlimited Visitors on MetaVisitAR *":"MetaVisitAR* पर असीमित विज़िटर",
                "(USD) per month":"(USD) प्रति माह",
                "3D Model creation of up to 3 Gallery spaces":"अधिकतम 3 गैलरी स्थानों का 3डी मॉडल निर्माण",
                "30 Days Free Trial":"30 दिन का निःशुल्क परीक्षण",
                "Hindi":"हिंदी",
                "Elements without resources":"संसाधन विहीन तत्व",
                "Delete Experiences":"अनुभव हटाएँ",
                "Digital Prints":"डिजिटल प्रिंट",
                "Digital Print":"अंकीय छाप, संगणकीय छपाई",
                "30 Days Free Trail":"30 दिन निःशुल्क ट्रेल",
                "Create Digital Print":"डिजिटल प्रिंट बनाएं",
                "Add Digital Print":"डिजिटल प्रिंट जोड़ें",
                "Value Of Digital Print":"डिजिटल प्रिंट का मूल्य",
                "Number Of Available Digital Print":"उपलब्ध डिजिटल प्रिंट की संख्या",
                "Orders":"आदेश",
                "Order ID":"आदेश कामतत्व",
                "Order Placed":"आदेश रखा",
                "Product Name":"प्रोडक्ट का नाम",
                "Quantity":"मात्रा",
                "Price":"कीमत",
                "Order By": "द्वारा आदेश",
                "Total Price": "कुल कीमत"
            }
        }      
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;
