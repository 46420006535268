//Button for showing create resource modal

import React from 'react'
import {setCurrentResource, updateResourceData,setCreateResourcesMode} from "../../store/resources";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import CreateIcon from "../../images/create-icon.svg";
import SmallRoundButton from "./SmallRoundButton";
import {setCreateIdentifierMode, setIdentifier} from "../../store/elements";
import {withTranslation} from "react-i18next";



class CreateResourceButton extends React.Component {

    handleClick = () => {
        this.props.setIdentifier(undefined)
        this.props.setCreateIdentifierMode(false)
        this.props.setCurrentResource(undefined)
        this.props.setCreateResourcesMode(true)
    }

    render() {
        return (
            <SmallRoundButton icon={CreateIcon} classes={"btn-small-round_dark custom-tooltip custom-tooltip_bottom"} onClick={this.handleClick}
                              data_tooltip={this.props.t("Create Resource")}/>
        );
    }
}

const mapStateToProps = state => ({
    resource_item: state.resources.resource_item,
    resources: state.resources.resources_non_degree,
    user_info: state.user.user_info,
    create_resources_mode: state.resources.create_resources_mode,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCurrentResource,
    updateResourceData,
    setCreateResourcesMode,
    setCreateIdentifierMode,
    setIdentifier
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(CreateResourceButton));

