import React from 'react';
import {Modal, ModalFooter, ModalHeader} from "reactstrap";
import {modalTypes} from "../../common";


function PublishModal(props) {
    const handleOk = () => {
        props.setModal(modalTypes.none)
        props.history.push("/")
    }
    return(
        <Modal isOpen={props.modal_type === modalTypes.publish_modal} className="modal modal-resources-list" centered>
            <ModalHeader>Please wait upto 24 hours for your {props.item_name} to be available on the mobile app</ModalHeader>
            <ModalFooter className="d-block">
                <div className="row">
                    <div className="col-12 mb-md-0 mb-3 text-center">
                        <button className="btn btn-primary btn-small btn-dark" onClick={handleOk}>Ok</button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default PublishModal;