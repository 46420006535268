//some functions and const which reusing
import _ from 'lodash';
import {createFilter} from "react-search-input";
import * as THREE from 'three';
import {toast, Zoom} from 'react-toastify';
import * as moment from "moment";
import React from "react";
import OverlayScrollbars from "overlayscrollbars";

export const hotspotIcons = {
    default: '#hotspot-icon',
    image: '#hotspot-icon-image',
    video: '#hotspot-icon-video',
    audio: '#hotspot-icon-audio',
    text: '#hotspot-icon-text',
    navigation: '#hotspot-icon-navigation',
    close: '#hotspot-icon-close',
}

export const experienceTypes = {
    videosphere: 1,
    photosphere: 2,
    hybrid: 3
};

export const experienceTypesArray = [
    {id: 1, name: 'Videosphere'},
    {id: 2, name: 'Photosphere'},
    {id: 3, name: 'Hybrid'},
]

export const hotspotTypesArray = [
    {id: 'hotspot', name: 'Hotspot'},
    {id: 'nav', name: 'Navigation hotspot'},
]

export const experienceCategories = {
    user_submitted: 1,
    museums: 2,
    real_estate: 3,
};

export function getHotspotIcon(type_id) {
    let hotspotIcon = hotspotIcons.default;
    if(type_id === resourceTypes.image){
        hotspotIcon = hotspotIcons.image
    }else if(type_id === resourceTypes.video){
        hotspotIcon = hotspotIcons.video
    }else if(type_id === resourceTypes.audio){
        hotspotIcon = hotspotIcons.audio
    }else if(type_id === hotspotTypes.text){
        hotspotIcon = hotspotIcons.text
    }else if(type_id === hotspotTypes.navigation){
        hotspotIcon = hotspotIcons.navigation
    }else if(type_id === hotspotTypes.url){
        hotspotIcon = hotspotIcons.url
    }else {
        hotspotIcon = hotspotIcons.default
    }
    return hotspotIcon;
}

//create float error message
export function notify(content,type){
    if(type === 'error'){
        return toast.error(content)
    }else if(type === 'success'){
        return toast.success(content,{transition: Zoom})
    }else if(type === 'warning'){
        return toast.warning(content)
    }
    return toast(content)
}

export const mediaTypes = {
         video: "video",
         audio: "audio",
         image: "image",
         text: "text",
         model: "model",
         animation: "animation",
         url: "url",
       };

export function getFilteredResources(props,state,type,withoutDegree,withoutModel,withoutTexts,resources_state) {
    const {element_item,language,show_unpaired_resources,show_paired_resources,show_sub_linked_resources} = props;
    let resources = resources_state ? resources_state : props.resources;
    let searchTerm = props.searchTerm ? props.searchTerm : state.searchTerm;

    // let {sortType,
    // } = state;
    let $resourceType = type;

    let keys_to_filters = ['translations.en.name', 'translations.fr.name', 'translations.zh.name','translations.ru.name', 'translations.en.description', 'translations.fr.description', 'translations.zh.description','translations.ru.description', 'tags'],
        filteredResources = resources.filter(createFilter(searchTerm, keys_to_filters));
    if(parseInt($resourceType) !== resourceTypes.all){
        if($resourceType === resourceTypes.zip){
            filteredResources = [...filteredResources.filter(resource => resource.type_id === parseInt($resourceType)),...filteredResources.filter(resource => resource.type_id === resourceTypes.model)];
        }else filteredResources = filteredResources.filter(resource => resource.type_id === parseInt($resourceType));
    }
    if(withoutDegree){
        filteredResources = filteredResources.filter(resource => (resource.type_id !== parseInt(resourceTypes.degreeVideo) && resource.type_id !== parseInt(resourceTypes.degreeImage)));
    }
    if(withoutModel){
        filteredResources = filteredResources.filter(resource => resource.type_id !== parseInt(resourceTypes.model));
    }
    if(withoutTexts){
        filteredResources = filteredResources.filter(resource => resource.type_id !== parseInt(resourceTypes.text));
    }
    //will return when we enable sort options for resources for now alphabetical sort as default
    // if(sortType === 'alphabet'){
    //     filteredResources = _.orderBy(filteredResources, [resource => resource.name.toLowerCase()])
    // }
    if(show_unpaired_resources){
        filteredResources = filteredResources.filter(resource => !element_item.paired_resources.includes(resource.id));
    }
    if(show_paired_resources){
        filteredResources = filteredResources.filter(resource => element_item.paired_resources.includes(resource.id));
    }
    if(show_sub_linked_resources){
        if (filteredResources && props.sub_elements && props.sub_element) {
            let sub_element = _.find(props.sub_elements, ['id', props.sub_element.id]);
            let ids = _.map(sub_element.resources, 'id');
            filteredResources = filteredResources.filter(resource => ids.includes(resource.id));
        }
    }
    if(language){
        let locale = _.find(languages,['id',language]).short_name;
        filteredResources = filteredResources.filter(resource => resource.translations && resource.translations[locale]);
    }
    // if(element_item && element_item.active){
    //     if(element_item.paired_resources){
    //         filteredResources = filteredResources.filter(resource => element_item.paired_resources.includes(resource.id));
    //     }else filteredResources = [];
    // }
    filteredResources = _.orderBy(filteredResources, [resource => resource.name ? resource.name.toLowerCase() : ''])

    return filteredResources;
}

export function getFilteredElements(props,state,elements_state) {
    let elements = elements_state ? elements_state : props.elements;
    let { searchTerm,show_without_resources } = state;

    let keys_to_filters = ['translations.en.name', 'translations.fr.name', 'translations.zh.name', 'translations.en.description', 'translations.fr.description', 'translations.zh.description', 'tags'],
        filteredElements = elements.filter(createFilter(searchTerm, keys_to_filters));

    if(show_without_resources){
        filteredElements = filteredElements.filter(element_item => element_item.resources_count === 0);
    }
    return filteredElements;
}

export function validateFileFormat(file){
    return !( !isImage(file) && !isVideo(file) && !isAudio(file) && !isText(file) && !isZip(file) && !isGlb(file) && !isGltf(file));
}

export function isImage(file){
    if(!file) return null;
    let type = file.type.split('/')[0];
    return type === mediaTypes.image;
}
export function isVideo(file){
    if(!file) return null;
    let type = file.type.split('/')[0];
    return type === mediaTypes.video || checkVideoExtension(file);
}

export function checkVideoExtension(file) {
    if(!file) return null;
    let extension = file.name.split('.')[1];
    return extension === 'mkv'
}
export function isAudio(file){
    if(!file) return null;
    let type = file.type.split('/')[0];
    return type === mediaTypes.audio;
}

export function isText(file){
    if(!file) return null;
    let type = file.type.split('/')[0];
    return type === mediaTypes.text;
}

export function isGlb(file){
    if(!file) return null;
    let extension = file.name.split('.')[1];
    return extension === 'glb'
}
export function isGltf(file){
    if(!file) return null;
    let extension = file.name.split('.')[1];
    return extension === 'gltf'
}
export function isZip(file){
    if(!file) return null;
    let extension = file.name.split('.')[1];
    return extension === 'zip'
}

export function difference(element, base) {
    function changes(element, base) {
        return _.transform(element, function(result, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] = (_.isObject(value) && _.iselement(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(element, base);
}

export function isElementEmpty(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object
}

export function getNumCostOfExperience(cost) {
    let isCostNum = (typeof cost === 'number');
    if(!cost) return 0
    if(isCostNum) {
        return cost;
    }else {
        return parseInt(cost.replace(/\D/g,''));
    }
}

export const experienceStatuses = {
    draft:1,
    published:2,
    suspended:3,
    deleted:4,
}

export function getBooleanStatus(status) {
    return status === experienceStatuses.published;
}

export function getResourceTypesQueryString(types) {
    if(!types) return '';
    if(types.length > 0){
        let queryString = '';
        types.forEach((item) => {
            queryString += `&types[]=${item}`
        });
        return queryString;
    }
}

export const resourceTypes = {
         all: 0,
         image: 1,
         video: 2,
         audio: 3,
         degreeImage: 4,
         degreeVideo: 5,
         text: 6,
         model: 7,
         zip: 8,
         animations: 9,
         url: 10,
       };

export const resourceTabsArray = [
         { type: 0, id: 1, title: "All" },
         { type: 1, id: 2, title: "Images" },
         { type: 2, id: 3, title: "Videos" },
         { type: 3, id: 4, title: "Audios" },
         { type: 6, id: 5, title: "Texts" },
         { type: 8, id: 6, title: "3D models" },
         { type: 9, id: 7, title: "Animations" },
         { type: 10, id: 8, title: "Url" }
       ];

export const hotspotTypes = {
         image: 1,
         video: 2,
         audio: 3,
         navigation: 4,
         text: 6,
         url: 10,
       };

export const identifierTypes = {
    image:1,
    video:2,
}

export function getCameraPosition() {
    let position = document.getElementById('hotspotItemStatic').element3d.getWorldPosition(new THREE.Vector3());
    return position;
}

export function getCameraPositionForNode() {
    let el = document.getElementById('nodePosition')
    if(el){
        return el.element3d.getWorldPosition(new THREE.Vector3());
    }
}

export function worldToLocal(){
    var vec3 = new THREE.Vector3();
    let el = document.getElementById('hotspotItemStatic');

    return el.parentNode.element3d.worldToLocal(vec3);

}

export function getCameraRotation(){
    let hotspotItem = document.getElementById('hotspotItemStatic');
    let quaternion = new THREE.Quaternion();
    hotspotItem.element3d.getWorldQuaternion( quaternion );
    let rotation = new THREE.Euler();
    rotation.setFromQuaternion(quaternion);

    return rotation;

}

export const default_hotspot_data = {
    icon:'#hotspot-icon',
    position_x:0,
    position_y:0,
    position_z:0,
    rotation_x:0,
    rotation_y:0,
    rotation_z:0,
    scale_x:1,
    scale_y:1,
    scale_z:1,
    scale_hotspot_icon_x:0.2,
    scale_hotspot_icon_y:0.2,
    scale_hotspot_icon_z:0.2,

}

export const versionNames = {
    combined:'combined',
    vr:'vr',
    ar:'ar',
}
export const elementTypes = {
    element2d: 1,
    element3d: 2,
}

export function pauseVideo(id,classname) {
    //pause aframe 360 video
    let video = document.getElementById(id);
    let videoList = Array.from(document.getElementsByClassName(classname));
    if(video){
        video.currentTime = 0;
        video.muted = true;
        video.pause()
    }
    if(videoList){
        videoList.map((videoItem) => {
            videoItem.currentTime = 0;
            videoItem.muted = true;
            videoItem.pause()
            return videoItem;
        })
    }
}

export function playVideo(id,first_playing) {
    //play aframe 360 video
    let video = document.getElementById(id);
    if(video){
        if(first_playing){
            video.play()
        }else {
            video.play()
            if(video.muted) video.muted = false;
        }
    }
}

export function increaseVideoVolume(id,first_playing) {
    let video = document.getElementById(id);
    if(video){
        if(video.muted){
            video.muted = false;
        }
    }
}

export function playMediaHotspot(hotspot_item_id) {
    let hotspotMedia = document.getElementById(`hotspot-media-${hotspot_item_id}`)
    if(hotspotMedia){
        hotspotMedia.play()
    }
}

export function pauseMediaHotspot(hotspot_item_id) {
    let hotspotMedia = document.getElementById(`hotspot-media-${hotspot_item_id}`)
    if(hotspotMedia){
        hotspotMedia.pause()
        hotspotMedia.currentTime = 0;
    }
}

export const relation = {
    scene: 'scenes-id'
}

export function isElementAttached(attached_to_scenes,current_scene_id) {
    if(!attached_to_scenes) return false;
    if(_.find(attached_to_scenes,['id',current_scene_id])) return true;
}


export const nodeNumber = {
    first: 'first',
    second: 'second',
    third: 'third',
    fourth: 'fourth',
    current : 'current',
}

export const packageOptions = {
    element: 1,
    resource: 2,
    audio: 3,
    video: 4,
    model: 5,
    animation: 6,
    gamification: 7,
    multilanguage: 8,
    text: 9,
    experience: 10,
};

export function getLimit(business_options,id) {
    let option = _.find(business_options, ['id', id]);
    if(option) return option.value;
}


export const languages = [
         {
           id: 1,
           name: "English",
           short_name: "en",
         },
         {
           id: 2,
           name: "French",
           short_name: "fr",
         },
         {
           id: 3,
           name: "Chinese",
           short_name: "zh",
         },
         {
           id: 4,
           name: "Russian",
           short_name: "ru",
         },
         {
           id: 5,
           name: "Portuguese",
           short_name: "pt",
         },
         {
           id: 6,
           name: "German",
           short_name: "de",
         },
         {
           id: 7,
           name: "Korean",
           short_name: "ko",
         },
         {
           id: 8,
           name: "Arabic",
           short_name: "ar",
         },
         {
           id: 9,
           name: "Hindi",
           short_name: "hi",
         }
       ];

export const canvasLanguages = [
         {
           id: 1,
           name: "English",
           short_name: "en",
         },
         {
           id: 2,
           name: "French",
           short_name: "fr",
         },
         {
           id: 3,
           name: "Chinese",
           short_name: "zh",
         },
         {
           id: 4,
           name: "Russian",
           short_name: "ru",
         },
         {
           id: 5,
           name: "Portuguese",
           short_name: "pt",
         },
         {
           id: 6,
           name: "German",
           short_name: "de",
         },
         {
           id: 7,
           name: "Korean",
           short_name: "ko",
         },
         {
           id: 8,
           name: "Arabic",
           short_name: "ar",
         },
         {
           id: 9,
           name: "Hindi",
           short_name: "hi",
         },
       ];

export const resourceStatisticTypes = {
    daily: 1,
    hourly: 2,
}

export const getDiffViewsPercent = (percent,previousCount,thisCount) => {
    if(previousCount === 0 && thisCount === 0){
        return `+${0}`
    }else if(previousCount === 0){
        return `+${100}`
    }else if(thisCount === 0){
        return `-${100}`
    }else{
        return percent <= 100 ? `+${100 - percent}` : `-${percent - 100}`
    }
}

export const statisticViews = (analytics) => {
    if(!analytics) return null;
    let {daily, hourly} = analytics;
    let dailyFormatted = daily.map( (day) => { return {...day,date:moment.parseZone(day.date,moment.ISO_8601).format("MMM D")  } } );
    let hourlyFormatted = hourly.map( (hour) => { return {...hour,date:moment.parseZone(hour.date,moment.ISO_8601).format("HH:mm")  } } );
    let thisDay = hourlyFormatted.slice(24),
        previousDay = hourlyFormatted.slice(0,24),
        thisWeek = dailyFormatted.slice(7),
        previousWeek = dailyFormatted.slice(0,7);
    let totalWeekCount = _.sumBy(['count'], _.partial(_.sumBy, thisWeek));
    let totalPreviousWeekCount = _.sumBy(['count'], _.partial(_.sumBy, previousWeek));
    let totalDayCount = _.sumBy(['count'], _.partial(_.sumBy, thisDay));
    let totalPreviousDayCount = _.sumBy(['count'], _.partial(_.sumBy, previousDay));
    let dayPercent = Math.floor((totalPreviousDayCount * 100) / totalDayCount);
    let dayDiff = getDiffViewsPercent(dayPercent,totalPreviousDayCount,totalDayCount);
    let weekPercent = Math.floor((totalPreviousWeekCount * 100) / totalWeekCount);
    let weekDiff = getDiffViewsPercent(weekPercent,totalPreviousWeekCount,totalWeekCount);

    return {
        totalWeekCount,
        totalPreviousWeekCount,
        totalDayCount,
        totalPreviousDayCount,
        dayDiff,
        weekDiff,
        thisWeek,
        thisDay
    };
}


export const themes = {
    'light-mode': {
        text_color: '#332C51',
        lang_select_bg: '#fff',
        lang_select_bg_dark: '#fff',
        role_select_bg: '#F2F3F3',
        lang_select_text_inactive: 'rgba(51,44,81,0.7)',
    },
    'dark-mode': {
        text_color: '#fff',
        lang_select_bg: '#2c2f32',
        lang_select_bg_dark: '#2c2f32',
        role_select_bg: '#2c2f32',
        lang_select_text_inactive: 'rgba(255,255,255,0.7)',
    }
};


export function getCountry(place) {
    if(place){
        let filtered_array = place.address_components.filter(function(address_component){
            return address_component.types.includes("country");
        });
        return filtered_array.length ? filtered_array[0].long_name : "";
    }
}

export function  getCity(place){
    if(place){
        let filtered_array = place.address_components.filter(function(address_component){
            return address_component.types.includes("locality");
        });
        return filtered_array.length ? filtered_array[0].long_name : "";
    }
}

export function getState(place) {
    if(place){
        let filtered_array = place.address_components.filter(function(address_component){
            return address_component.types.includes("administrative_area_level_1");
        });
        return filtered_array.length ? filtered_array[0].long_name : "";
    }
}

export function compare(a, b) {
    if (a > b) return 1;
    if (b > a) return -1;

    return 0;
}

export const businessUserRoles = [
    {
        id:1,
        name:'Owner'
    },
    {
        id:2,
        name:'Content creator'
    },
    {
        id:3,
        name:'Model creator'
    },
]

export function getName(data_item,language) {
    let {translations} = data_item;
    let name;
    if(translations){
        if(translations[language]){
            name = translations[language].name
        }else {
            Object.entries(translations).forEach(([k, v]) => {
                if(k !== language && v.name && !name){
                    name = v.name;
                }
            })
        }
    }
    return name;
}

export const sidebarTypes = {
    none: 0,
    element: 1,
    resources: 2,
    gamification: 3,
    statistic: 4,
    experience: 5,
    create_resource: 6,
    edit_resource: 7,
    edit_job: 8,
    view_job: 9,
    edit_bid: 10,
    view_bid: 11,
    elements_list: 12,
    change_elements_order: 13,
    experienceTools: 14,
}

export const modalTypes = {
    none: 0,
    publish_modal: 1,
}

export function checkPairedStatus(resource_item,element_item) {
    let paired = false;
    if(!element_item || !resource_item) return false;
    element_item.paired_resources && element_item.paired_resources.forEach((id) => {
        if (resource_item.id === id) paired = true;
    });
    return paired;
}

export function checkSubLinkedStatus(sub_element_id,sub_elements,resource_item){
    let sub_element = _.find(sub_elements, ['id',sub_element_id]);
    let pairedResources = sub_element ? sub_element.resources : [];
    return pairedResources &&_.find(pairedResources,['id',resource_item.id])
}


export function getSupportedResourcesFormats(rules,media_type,isVR) {
    const {
      RESOURCE_SUPPORTED_FORMATS,
      RESOURCE_SUPPORTED_FORMATS_VR,
      ANIMATION_RESOURCE_FORMATS,
      // MULTIMEDIA_RESOURCE_FORMATS,
      RESOURCE_IMAGE_SUPPORTED_FORMATS,
      RESOURCE_VIDEO_SUPPORTED_FORMATS,
      RESOURCE_AUDIO_SUPPORTED_FORMATS,
      RESOURCE_TEXT_SUPPORTED_FORMATS,
      RESOURCE_MODEL_SUPPORTED_FORMATS,
      RESOURCE_URL_SUPPORTED_FORMATS,
    } = rules;

    let supported_formats = isVR ? RESOURCE_SUPPORTED_FORMATS_VR : RESOURCE_SUPPORTED_FORMATS;
    if(media_type) {
        switch (media_type) {
            case resourceTypes.image:
            case 'image': supported_formats = RESOURCE_IMAGE_SUPPORTED_FORMATS; break;
            case resourceTypes.video:
            case 'video': supported_formats = RESOURCE_VIDEO_SUPPORTED_FORMATS; break;
            case resourceTypes.audio:
            case 'audio': supported_formats = RESOURCE_AUDIO_SUPPORTED_FORMATS; break;
            case resourceTypes.text:
            case 'text': supported_formats = RESOURCE_TEXT_SUPPORTED_FORMATS; break;
            case resourceTypes.zip:
            case resourceTypes.model:
            case 'zip': supported_formats = RESOURCE_MODEL_SUPPORTED_FORMATS; break;
            case resourceTypes.animations:
            case 'animation': supported_formats = (ANIMATION_RESOURCE_FORMATS); break;
             case resourceTypes.url:
            case 'url': supported_formats = RESOURCE_URL_SUPPORTED_FORMATS; break;
            default : supported_formats = RESOURCE_SUPPORTED_FORMATS_VR;
        }
    }

    return supported_formats;
}

export function getSupportedElementFormats(rules,data_type) {
    const {
        IDENTIFIER_2D_DATA_SUPPORTED_FORMATS,
        IDENTIFIER_3D_DATA_SUPPORTED_FORMATS,
    } = rules;
    let is2D = data_type === elementTypes.element2d;

    return is2D ? IDENTIFIER_2D_DATA_SUPPORTED_FORMATS : IDENTIFIER_3D_DATA_SUPPORTED_FORMATS;
}


export const getFormatIcon = (format,formats) => {
    let $format = _.find(formats,['name',format]);
    if(!$format) return null;
    return $format.icon;
}

export const getExtension = (file) => {
    if(!file) return null;
    return file.name.split('.')[1];
}

export function updateListById(list,id,responseJson){
    let index = _.findIndex(list, {id: id});
    if(index >= 0) list.splice(index, 1, responseJson.data);
}

export function addToList(list,responseJson){
    list.push(responseJson.data)
}

export function deleteFromListById(list,id){
    let index = _.findIndex(list, {id: id});
    list.splice(index, 1);
}

export function getFilteredExperiences(props,state,experiences_state) {
    let experiences = experiences_state ? experiences_state : props.experiences;
    let { searchTerm,show_without_resources } = state;

    let keys_to_filters = ['translations.en.name', 'translations.fr.name', 'translations.zh.name', 'translations.en.description', 'translations.fr.description', 'translations.zh.description', 'tags'],
        filteredExperiences = experiences.filter(createFilter(searchTerm, keys_to_filters));

    if(show_without_resources){
        filteredExperiences = filteredExperiences.filter(experience_item => experience_item.resources_count === 0);
    }
    return filteredExperiences;
}

export const experienceTemplates = [
    {id: 1, name: '5'},
    {id: 2, name: '15'},
]
export function getJobStatusModificator(id) {
    switch (id){
        case 0: return  'status-label_draft';
        case 1: return 'status-label_open';
        case 2: return 'status-label_assigned';
        case 3: return 'status-label_pending';
        case 4: return 'status-label_completed';
        default: return 'status-label_draft';
    }
}

export function getBidStatusModificator(id) {
    switch (id){
        case 0: return  'status-label_submitted';
        case 1: return 'status-label_approved';
        case 2: return 'status-label_rejected';
        default: return 'status-label_submitted';
    }
}

export function getTimeline(estimate_minutes) {
    let hours = Math.floor(estimate_minutes / 60);
    let hoursText = hours > 1 ? 'hours' : 'hour'
    let minutes = estimate_minutes % 60;
    let minutesText = minutes > 1 ? 'mins' : 'min';
    return(
        <span>
            {hours > 0 && `${hours} ${hoursText}`} &nbsp;
            {minutes > 0 && `${minutes} ${minutesText}`}
        </span>
    )
}

export const userRoles = {
    admin: 1,
    business_user: 2,
    customer: 3,
    contractor: 4,
}

export const scrollToTop = (id) => {
    let instance = OverlayScrollbars(document.getElementById(id));
    instance.scroll({ y : "100%"  })
}
