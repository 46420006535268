//Button for delete single element

import React, {useState} from 'react';
import Modal from "../modals/ConfirmExperienceDeleteModal";
import {withTranslation} from "react-i18next";
import DeleteIcon from "../../images/delete-icon.svg";
import SmallRoundButton from "../buttons/SmallRoundButton";
import ReactTooltip from "react-tooltip";

function DeleteSingleExperienceButton(props) {
    const [showModal, setShowModal] = useState(false);
    let {t} = props;

    const deleteSingleExperience = () => {
        props.deleteSingleExperience(props.experience_item.id).then(() => {
            props.history.push("/")
        })
    }

    return (
        <SmallRoundButton icon={DeleteIcon} classes={`mr-2`}
                          onClick={() => setShowModal(true)} data_tip={(t(`Delete Element`))}>
            <ReactTooltip />
            <Modal handleDelete={deleteSingleExperience}
                   show={showModal} setShowModal={setShowModal}/>
        </SmallRoundButton>
    );
}

export default withTranslation('translations')(DeleteSingleExperienceButton);

