import React, { useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import WorkHistory from "../../WorkHistory";
import ReactSVG from "react-svg";
import CloseIcon from "../../../../images/close-icon.svg";
import {useFormik} from "formik";
import {setProfileSchema} from "../../../../store/validations";
import TopSectionContractor from "../../top-section/TopSectionContractor";
import FormWrapper from "../../../../components/FormWrapper";
import Fullname from "./Fullname";
import Availability from "./Availability";
import MultipleLanguageSelect from "../../MultipleLanguageSelect";
import Timezone from "./Timezone";
import LocationInput from "./LocationInput";
import moment from "moment";
import DescriptionInput from "../../DescriptionInput";
import InputWithButtons from "../../../../components/InputWithButtons";
import VerifiedResources from "./VerifiedResources";
import Portfolio from "./Portfolio";
import CreateIcon from "../../../../images/create-icon.svg";
import SmallRoundButton from "../../../../components/buttons/SmallRoundButton";
import CreatePortfolioItem from "./CreatePortfolioItem";
import ContractorAvatar from "./ContractorAvatar";
import {canvasLanguages} from "../../../../common";
import LanguageSelect from "../../../../components/LanguageSelect";
import * as _ from "lodash";
import {Trans} from 'react-i18next';
import ErrorBoundaryContainer from "../../../../components/ErrorBoundaryContainer";

function ContractorProfile (props) {

    let formik = useFormik({
        initialValues: {
            first_name: props.contractor_item ? props.contractor_item.first_name : '',
            last_name: props.contractor_item ? props.contractor_item.last_name : '',
            description: props.contractor_item ? props.contractor_item.description : '',
            availability: props.contractor_item ? props.contractor_item.availability : null,
            languages: props.contractor_item ? props.contractor_item.languages : [],
            timezone: props.contractor_item ? props.contractor_item.timezone : '',
            location: props.contractor_item ? {
                address_line_1: props.contractor_item.address_line,
                longitude: props.contractor_item.longitude,
                latitude:props.contractor_item.latitude
            } : '',
            avatar: props.contractor_item ? props.contractor_item.avatar : undefined,
        },
        enableReinitialize: true,
        validationSchema: setProfileSchema(props.validation_rules,props.t),
    });

    useEffect(() => {
        if(props.user_info){
            props.getContractorInfo(props.user_info.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [createPortfolioMode,setCreatePortfolioMode] = useState(false)
    const [language,setLanguage] = useState('en')

    if(!props.contractor_item) return null;
    const handleFieldChange = (name,val) => {
        let updated_data = {[name]: val}
        props.updateContractorInfo(props.contractor_item.id,updated_data)
    }
    let commonProps = {
        contractor_item: props.contractor_item,
        formik: formik,
        t: props.t,
        handleFieldChange
    }
    const handleLanguageChange = (name,value) => {
        setLanguage(value)
        let newlang = _.find(canvasLanguages,['id',value]).short_name;
        props.i18n.changeLanguage(newlang);
        props.updateUserInfo({locale:newlang})
    }

    return(
        <>
            <TopSectionContractor {...props}/>
            <div className={`contractor-profile`}>
                <button type="button" className="btn btn-close"
                        onClick={() => props.history.goBack()}>
                    <ReactSVG src={CloseIcon}/>
                </button>
                <ErrorBoundaryContainer>
                    <FormWrapper formik={formik} formName={'edit-resource'}>
                        <div className="contractor-info contractor-profile__header">
                            <ContractorAvatar {...props}/>
                            <div className="contractor-info__details w-100">
                                <Fullname classes="input-row_left" {...commonProps}/>
                                <div className="row">
                                    <div className="col contractor-profile__col">
                                        <VerifiedResources verified_resource_types={props.contractor_item.verified_resource_types}/>
                                    </div>
                                    <div className="col contractor-profile__col">
                                        <Availability {...commonProps}/>
                                    </div>
                                    <div className="col contractor-profile__col">
                                        <LocationInput {...commonProps} updateContractorInfo={props.updateContractorInfo}/>
                                    </div>
                                    <div className="col contractor-profile__col">
                                        <div className="contractor-profile__title"><Trans>Member since</Trans></div>
                                        <div className="contractor-profile__text">
                                            {moment(props.contractor_item.created_at).format('MMMM DD, YYYY')}
                                        </div>
                                    </div>
                                    <div className="col contractor-profile__col">
                                        <Timezone {...commonProps}/>
                                    </div>
                                    <div className="col contractor-profile__col">
                                        <MultipleLanguageSelect {...commonProps}
                                                                name={'languages'} title={"Languages you speak"}
                                                                titleClassName={'contractor-profile__title'}/>
                                    </div>
                                    <div className="col contractor-profile__col">
                                        <div className="contractor-profile__title"><Trans>Canvas language</Trans></div>
                                        <LanguageSelect data={canvasLanguages} disabled={false} value={language}
                                                        setFieldValue={handleLanguageChange}
                                                        name={'language'} hideHelp withoutLimits/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormWrapper>
                    <div className="separator"/>
                    <div className="contractor-profile__body">
                        <InputWithButtons handleSave={() => handleFieldChange("description", formik.values.description)}
                                          handleDiscard={() => formik.resetForm()}>
                            <DescriptionInput {...commonProps} placeholder={'Description'} title={'Description'}/>
                        </InputWithButtons>
                        {!createPortfolioMode && <div className="d-flex flex-row justify-content-between mt-5">
                            <ul className="nav nav-pills btn-group btn-group-toggle mb-4" id="work-historyTab"
                                role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link btn btn-group-toggle__item active" id="portfolio-tab"
                                       data-toggle="tab" href="#portfolio"
                                       role="tab" aria-controls="portfolio" aria-selected="true"><Trans>Portfolio</Trans></a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link btn btn-group-toggle__item" id="work-history-tab"
                                       data-toggle="tab" href="#work-history"
                                       role="tab" aria-controls="work-history" aria-selected="false"><Trans>Work history</Trans></a>
                                </li>
                            </ul>
                            <SmallRoundButton icon={CreateIcon} classes={"btn-small-round_dark"}
                                              onClick={() => setCreatePortfolioMode(true)}
                                              data_tip={props.t("Create Portfolio Item")}/>
                        </div>}
                        {!createPortfolioMode && <div className="tab-content d-flex flex-column flex-grow-1">
                            <div className="tab-pane active flex-grow-1" id="portfolio" role="tabpanel"
                                 aria-labelledby="portfolio-tab">
                                <Portfolio {...props}/>
                            </div>
                            <div className="tab-pane flex-grow-1" id="work-history" role="tabpanel"
                                 aria-labelledby="work-history-tab">
                                <WorkHistory {...props}/>
                            </div>
                        </div>}
                        {createPortfolioMode && <CreatePortfolioItem {...props}
                                                                     setCreatePortfolioMode={setCreatePortfolioMode}/>}
                    </div>
                </ErrorBoundaryContainer>
            </div>
        </>
    )
}

export default withRouter(ContractorProfile)
