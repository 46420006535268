//Component for showing preview image of element.

import React from 'react';
import {resourceTypes} from "../../../common";
import EmptyPreview from "./EmptyPreview";

function TrainingAsPreview(props) {
    if(!props.element_item) return null;
    let {identifiers} = props.element_item;
    if(identifiers && identifiers.length > 0) {
        let training_item = identifiers[0];
        let training_type = training_item.type_id;
        if (training_type === resourceTypes.image) {
            return <img src={training_item.media_url} alt="" className="element-item__preview"/>
        } else if (training_type === resourceTypes.video) {
            // return <video autoPlay loop muted src={training_item.media_url} className="element-item__preview"/>
            return <video className="element-item__preview" preload="metadata">
                <source src={training_item.media_url + "#t=0.5"}/>
            </video>
        } else return <EmptyPreview/>;
    } else return <EmptyPreview/>;
}

export default TrainingAsPreview;

