//Google map component, do not working without REACT_APP_GOOGLE_API_KEY
import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';



const GoogleMap = ({ children, ...props }) => {
    let showClass = props.isSearchFocused ? 'google-map-container_show' : '';
    return (
        <div className={`google-map-container ${showClass} ${props.classes}`} id="google-map">
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_API_KEY,
                }}
                {...props}
            >
                {children}
            </GoogleMapReact>
        </div>
    )
};

GoogleMap.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};

GoogleMap.defaultProps = {
    children: null,
};

export default GoogleMap;