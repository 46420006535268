import React, {Fragment} from 'react';
import {languages, sidebarTypes} from "../../common";
import LanguageSelect from "../LanguageSelect";
import Sidebar from "./Sidebar";
import ExperienceName from "../experience/ExperienceName";
import DeleteSingleExperienceButton from "../experience/DeleteSingleExperienceButton";
import PublishButton from "../experience/PublishButton";
import _ from "lodash";
import Arrow from "../../images/arrow.svg";
import ReactSVG from "react-svg";
import {refreshUnity2} from "../experience/UnityFunctions";
import RefreshIcon from "../../images/refresh-icon.svg";
import ReactTooltip from "react-tooltip";
import SmallRoundButton from "../buttons/SmallRoundButton";

function ExperienceSidebar(props) {
    // const [frames,setFrames] = useState(props.element_item && props.element_item.frames);
    const handleSelectElements = () => {
        props.setSidebar(sidebarTypes.elements_list)
    }

    const handleTools = () => {
        props.setSidebar(sidebarTypes.experienceTools)
    }

    const onPublish = () => {
        props.updateExperienceData(props.experience_item.id, {active:true})
    }

    // const onChangeTemplate = (name,value) => {
    //     setFrames(value)
    //     // props.updateExperienceData(props.experience_item.id, {frames:value})
    // }

    // let theme = props.dark_mode ? themes['dark-mode'] : themes['light-mode']

    return(
            <Sidebar show={props.sidebar_type === sidebarTypes.experience} {...props}
                header={<ExperienceName {...props}/>}
                footer={<Fragment>
                    {props.experience_item &&
                    <DeleteSingleExperienceButton
                        {...props}
                        deleteSingleExperience={props.deleteSingleExperience}
                        tooltipSide={"top"}
                    />}
                    <SmallRoundButton icon={RefreshIcon} classes={`mr-2`}
                                      onClick={() => refreshUnity2(props.experience_item.id)} data_tip={(props.t(`Refresh Unity`))}>
                        <ReactTooltip />
                    </SmallRoundButton>
                    <PublishButton
                        classes={`w-100`}
                        handleSubmit={onPublish}
                        tooltipSide={"top"}
                        {...props}/>
                </Fragment>}
                wide
            >
                <div className="sidebar__static-info">
                    <div className="sidebar__title sidebar__title_offset-free">Select language </div>
                    <LanguageSelect data={languages} disabled={false}
                                    onBlur={languages}
                                    setFieldValue={(name,value) => props.setExperienceLanguage(_.find(languages,['id',value]).short_name)}
                                    name={'language'} isDark hideHelp classes="mb-4"/>

                    {/*<div className="sidebar__title sidebar__title_offset-free">Amount of elements</div>*/}
                    {/*<CustomSelect data={experienceTemplates} value={frames}*/}
                    {/*              setFieldValue={onChangeTemplate}*/}
                    {/*              name={'frames'}*/}
                    {/*              className="select-language mb-5"*/}
                    {/*              styles={{*/}
                    {/*                  option: (provided, state) => ({*/}
                    {/*                      ...provided,*/}
                    {/*                      border: 'none',*/}
                    {/*                      color: state.isSelected ? theme.text_color : theme.lang_select_text_inactive,*/}
                    {/*                      padding: 8,*/}
                    {/*                      display: 'flex',*/}
                    {/*                      alignItems: 'center'*/}
                    {/*                  }),*/}
                    {/*                  singleValue: (provided, state) => ({*/}
                    {/*                      ...provided,*/}
                    {/*                      display: 'flex',*/}
                    {/*                      alignItems: 'center',*/}
                    {/*                      color: theme.text_color*/}
                    {/*                  }),*/}
                    {/*                  valueContainer: (provided, state) => ({*/}
                    {/*                      ...provided,*/}
                    {/*                      backgroundColor: 'transparent',*/}
                    {/*                      borderWidth: 0,*/}
                    {/*                      padding: '2px 16px',*/}
                    {/*                      display: 'flex',*/}
                    {/*                      alignItems: 'center'*/}

                    {/*                  }),*/}
                    {/*                  control: (provided, state) => ({*/}
                    {/*                      ...provided,*/}
                    {/*                      backgroundColor: props.isDark ? theme.lang_select_bg_dark : theme.lang_select_bg,*/}
                    {/*                      borderWidth: 0,*/}
                    {/*                      borderRadius: '25px',*/}
                    {/*                      outline: 'none',*/}
                    {/*                      minHeight: '36px',*/}
                    {/*                      boxShadow: 'none',*/}
                    {/*                      display: 'inline-flex',*/}
                    {/*                      alignItems: 'center',*/}
                    {/*                      minWidth: '200px'*/}
                    {/*                  }),*/}
                    {/*                  indicatorSeparator: (provided, state) => ({*/}
                    {/*                      ...provided,*/}
                    {/*                      display: 'none',*/}
                    {/*                  }),*/}
                    {/*                  dropdownIndicator: (provided, state) => ({*/}
                    {/*                      ...provided,*/}
                    {/*                      display: 'flex',*/}
                    {/*                      justifyContent: 'center',*/}
                    {/*                      alignItems: 'center',*/}
                    {/*                      margin: 0,*/}
                    {/*                      padding: '2px 16px',*/}
                    {/*                  }),*/}
                    {/*                  menu: (provided, state) => ({*/}
                    {/*                      ...provided,*/}
                    {/*                      width: 'auto',*/}
                    {/*                      minWidth: '200px',*/}
                    {/*                      backgroundColor: theme.lang_select_bg,*/}
                    {/*                  })*/}
                    {/*              }}/>*/}

                    <button className="btn btn-primary w-100" type="button" onClick={handleSelectElements}>Select elements</button>
                </div>
                <div className="sidebar__inner-offset m-0">
                    <button className="btn sidebar-btn-arrow w-100" type="button" onClick={handleTools}>
                        Settings
                        <ReactSVG className="sidebar-btn-arrow__icon" src={Arrow}/>
                    </button>
                </div>
            </Sidebar>
    )
}

export default ExperienceSidebar;
