import React from "react";
//import Status from "../Status";
// import {getBidStatusModificator, getTimeline} from "../../../common";
// import { orderStatuses } from "./OrderCard";
import {Trans} from 'react-i18next';

function OrderCardHeader(props) {
    let {order_id, quantity,price,digital_print_name, file_name,business_ids, element_ids, created_date} = props.item_info;
    let createddate = created_date.split(",");
    let product_name = digital_print_name.split(",");
    let product_price = price.split(",");
    let product_quantity = quantity.split(",");
    let product_file = file_name.split(",");
    let product_business = business_ids.split(",");
    let product_element = element_ids.split(",");

    return (
      <div className="bid-card__header">
        <div className="bid-card__header-item">
          <div className="bid-card__header-item-title">
            <Trans>Order ID</Trans>
          </div>
          <div className="bid-card__header-item-content">{order_id}</div>
          <div className="bid-card__header-item-title">
            <Trans>Order Placed</Trans>
          </div>
          <div className="bid-card__header-item-content">{createddate[0]}</div>
        </div>
        <div className="bid-card__header-item">
          <div className="bid-card__header-item-title">
            <Trans>Product Name</Trans>
          </div>
          {product_file &&
            product_file.map((file, i) => (
              <div
                className="bid-card__header-item-content"
                key={`file-${file}`}
              >
                {/* {path = process.env.REACT_APP_API_ROOT`/public/`{product_business[i]}`/digitalprint/`{product_element[i]}."/".{product_file[i]}; */}
                <img
                  src={`${process.env.REACT_APP_API_ROOT}/public/${product_business[i]}/digitalprint/${product_element[i]}/${product_file[i]}`}
                  alt={`img-${i}`}
                  height={30}
                  width={30}
                />
                {product_name[i]}
              </div>
            ))}
        </div>
        <div className="bid-card__header-item">
          <div className="bid-card__header-item-title">
            <Trans>Quantity</Trans>
          </div>
          {product_quantity &&
            product_quantity.map((qty) => (
              <div className="bid-card__header-item-content" key={`qty-${qty}`}>
                {qty}
              </div>
            ))}
        </div>
        <div className="bid-card__header-item">
          <div className="bid-card__header-item-title">
            <Trans>Price</Trans>
          </div>
          {product_price &&
            product_price.map((prs) => (
              <div className="bid-card__header-item-content" key={`prs-${prs}`}>
                $ {prs}
              </div>
            ))}
        </div>
      </div>
    );
}

export default OrderCardHeader;
