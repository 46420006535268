import React from "react";
import DropZone from "../../../components/DropZone";
import {getSupportedResourcesFormats} from "../../../common";
import {Trans} from 'react-i18next';

function BidFiles(props) {
    let supported_formats = props.validation_rules && props.element_item && getSupportedResourcesFormats(props.validation_rules,props.element_item.type_id);
    const upload = (file) => {
        props.formik.setFieldValue('files',file)
    }
    // const deleteFile = (id) => {
    //     props.deleteJobAttachment(props.job_id,id)
    // }
    return(
        <div className="form-group">
            <div className="sidebar__title sidebar__title_offset-free"><Trans>Attachments</Trans></div>
            {/*{props.job_attachments && <div className="d-flex flex-wrap w-100 justify-content-between mb-3">*/}
            {/*    {props.job_attachments.map((file) => {*/}
            {/*        return <RenderFile data={file} showFullSizeGallery={props.showFullSizeGallery}*/}
            {/*                           deleteFile={deleteFile}/>*/}
            {/*    })}*/}
            {/*</div>}*/}
            <DropZone {...props}
                      formik={props.formik}
                      title={props.t("Upload a file")}
                      acceptedFormats={supported_formats}
                      upload={upload}
                      classes={"w-100 mb-3"}
                      errorMessage={props.formik.errors["files"] ? props.formik.errors["files"] : undefined}
                      supported_formats={supported_formats}
                      field={props.formik.values.files}
                      maxFiles={6}/>


        </div>
    )
}

export default BidFiles;
