//All reducers for user
import * as api from "../api";
import {languages, notify} from "../common";
import * as _ from "lodash";
import EnglishFlag from "../images/flags/english-flag.svg";
import FrenchFlag from "../images/flags/french-flag.svg";
import ChineseFlag from "../images/flags/chinese-flag.svg";
import RussianFlag from "../images/flags/russian-flag.svg";
import PortugueseFlag from "../images/flags/portuguese-flag.svg";
import GermanFlag from "../images/flags/german-flag.svg";
import KoreanFlag from "../images/flags/korean-flag.svg";
import ArabicFlag from "../images/flags/arabic-flag.svg";
import HindiFlag from "../images/flags/hindi-flag.svg";
import {handleErrors} from "./errors";

const SIGNIN = "SIGNIN",
  SIGNOUT = "SIGNOUT",
  CHECK_SIGNED_IN = "CHECK_SIGNED_IN",
  SET_USER_INFO = "SET_USER_INFO",
  SET_BUSINESS_INFO = "SET_BUSINESS_INFO",
  SET_DASHBOARD_INFO = "SET_DASHBOARD_INFO",
  TOGGLE_UPGRADE_PLAN_MODAL = "TOGGLE_UPGRADE_PLAN_MODAL",
  SET_DARK_MODE = "SET_DARK_MODE",
  SET_SIGN_OUT_CONFIRMATION_MODAL = "SET_SIGN_OUT_CONFIRMATION_MODAL",
  SET_MAIN_CONTENT_LANGUAGE = "SET_MAIN_CONTENT_LANGUAGE",
  SET_LOADING = "SET_LOADING",
  SET_CURRENT_USER = "SET_CURRENT_USER",
  SET_COUPON_CODE_FOR_BUSINESS = "SET_COUPON_CODE_FOR_BUSINESS";

const initialState = {
    user_info: undefined,
    business_info: undefined,
    dashboard_info: undefined,
    signed_in: false,
    loading: false,
    show_upgrade_plan_modal: false,
    dark_mode: false,
    sign_out_confirmation_modal: false,
    main_content_language: 'en',
    coupon_code_for_business: '000000',
};

export const signIn = (userData) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchSignIn(userData)
                .then((res) => handleErrors(res,false,dispatch))
                .then((responseJson) => {
                    if(responseJson.access_token && responseJson.refresh_token){
                        dispatch({
                            type: SIGNIN,
                            user_info:responseJson,
                            signed_in:true
                        });
                        setAccessToken(responseJson.access_token)
                        setRefreshToken(responseJson.refresh_token)
                        setTokenExpirationDate(responseJson.expires_in)
                        resolve(responseJson)
                    }else reject(responseJson)

                })
                .catch((err) => reject(err))
        })

    };

export const signUp = (userData) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchSignUp(userData)
                .then((res) => handleErrors(res,false,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SIGNIN,
                        user_info:responseJson,
                        signed_in:true
                    });
                    setAccessToken(responseJson.access_token)
                    setRefreshToken(responseJson.refresh_token)
                    setTokenExpirationDate(responseJson.expires_in)
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };
export const signUpContractor = (userData) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchSignUpContractor(userData)
                .then((res) => handleErrors(res,false,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SIGNIN,
                        user_info:responseJson,
                        signed_in:true
                    });
                    setAccessToken(responseJson.access_token)
                    setRefreshToken(responseJson.refresh_token)
                    setTokenExpirationDate(responseJson.expires_in)
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };

function setCurrentUserAction(user_info){
    return {
        type: SET_CURRENT_USER,
        user_info
    }
}

export const signOut = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SIGNOUT,
                user_info: undefined,
                signed_in: false
            });
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('client_secret');
            localStorage.removeItem('client_id');
            localStorage.removeItem('expiration_date_sec');
            resolve()
        })
    };

export const checkSignedIn = (signed_in) =>
    (dispatch) => {
        return dispatch({
            type: CHECK_SIGNED_IN,
            signed_in
        });
    };

export const checkExistEmail = (email) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCheckExistEmail(email)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.message)
                })
                .catch((err) => reject(err))
        })

    };

export const sendOtp = (email) => (dispatch) => {
    return new Promise((resolve, reject) => {
    api
        .fetchSendOtp(email)
        .then((res) => handleErrors(res, true, dispatch))
        .then((responseJson) => {
        resolve(responseJson);
        })
        .catch((err) => reject(err));
    });
};

export const checkOtp = (email, otp) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .fetchCheckOtp(email, otp)
      .then((res) => handleErrors(res, true, dispatch))
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((err) => reject(err));
  });
};

export const sendEmailToUpgradePlan = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchSendEmailToUpgradePlan()
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.message)
                })
                .catch((err) => reject(err))
        })

    };

export const checkExistUsername = (username) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCheckExistUsername(username)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.message)
                })
                .catch((err) => reject(err))
        })
    };

export const resetPassword = (email) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchResetPassword(email)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    if(responseJson.message.code === 1){
                        notify(responseJson.message.description,'error')
                    }else {
                        notify(responseJson.message.description,'success')
                        resolve(responseJson.message.code !== 1)
                    }
                })
                .catch((err) => reject(err))
        })

    };

export const checkTokenForResetPassword = (token,email) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCheckTokenForResetPassword(token,email)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    if(responseJson.message.code === 1){
                        notify(responseJson.message.description,'error')
                    }else {
                        resolve(responseJson.message.code !== 1)
                    }
                })
                .catch((err) => reject(err))
        })

    };


export const submitNewPassword = (token,email,password,password_confirmation) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchNewPassword(token,email,password,password_confirmation)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };

export const getUserInfo = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetUserInfo()
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_USER_INFO,
                        user_info:responseJson.data
                    });
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };

export const updateUserInfo = (data) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateUserInfo(data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_USER_INFO,
                        user_info:responseJson.data
                    });
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };

export const updateBusinessInfo = (data) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateBusinessInfo(data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_BUSINESS_INFO,
                        business_info:responseJson.data
                    });
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };

export const getBusinessInfo = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetBusinessInfo()
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_BUSINESS_INFO,
                        business_info:responseJson.data
                    });
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };
export const getDashboardInfo = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .fetchGetDashboardInfo()
      .then((res) => handleErrors(res, true, dispatch))
        .then((responseJson) => {
        dispatch({
          type: SET_DASHBOARD_INFO,
          dashboard_info: responseJson.data,
        });
        resolve(responseJson);
      })
      .catch((err) => reject(err));
  });
};

export const uploadUserAvatar = (avatar) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchUploadUserAvatar(avatar)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let user_info = getState().user.user_info;
                    user_info.avatar = responseJson.data;
                    dispatch(setCurrentUserAction(user_info))
                    resolve(responseJson.data)
                })
        })
    }

export const deleteUserAvatar = () =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchDeleteUserAvatar()
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let user_info = getState().user.user_info;
                    user_info.avatar = responseJson.data;
                    dispatch(setCurrentUserAction(user_info))
                    resolve(responseJson.data)
                })
        })
    }

export function setAuthorizationHeader() {
    if (getAccessToken()) {
        return `Bearer ${getAccessToken()}`;
    }
    return null;
}

export function setLanguageHeader(language_id) {
    if(!language_id) return 'en';
    let language = _.find(languages,['id', language_id]);
    if(language.short_name){
        return language.short_name
    }
    return 'en'
}


export function renderFlag(lang_id) {
    switch (lang_id) {
      case 1:
        return EnglishFlag;
      case 2:
        return FrenchFlag;
      case 3:
        return ChineseFlag;
      case 4:
        return RussianFlag;
      case 5:
        return PortugueseFlag;
      case 6:
        return GermanFlag;
      case 7:
        return KoreanFlag;
      case 8:
        return ArabicFlag;
      case 9:
        return HindiFlag;
      default:
        return EnglishFlag;
    }
}


export function renew() {
    if(checkToken()){
        const token = getRefreshToken();
        if (token && isTokenExpired()) {
            refreshAuthentication(token);
        }else if(!token) signOut();
    }else signOut();

}

export function refreshAuthentication(refresh_token) {
    return new Promise((resolve, reject) => {
        api.fetchRefreshToken(refresh_token)
            .then((res) => handleErrors(res,true))
            .then((responseJson) => {
                setTokenExpirationDate(responseJson.expires_in)
                resolve(responseJson);
            })
            .catch((err) => reject(err))
    });
}

export function checkToken() {
    const token = getAccessToken();
    return !!token && !isTokenExpired();
}


export function setAccessToken(access_token) {
    localStorage.setItem('access_token', access_token);
}

export function setRefreshToken(refresh_token) {
    localStorage.setItem('refresh_token', refresh_token);
}

export function getAccessToken() {
    return localStorage.getItem('access_token');
}

export function getRefreshToken() {
    return localStorage.getItem('refresh_token');
}

export function getTokenExpirationDate() {
    return localStorage.getItem('expiration_date_sec');
}

export function setTokenExpirationDate(expires_in) {
    let todayInSeconds = new Date().getTime() / 1000;
    let expirationDateInSeconds = todayInSeconds + expires_in;
    if (!expirationDateInSeconds) return null;
    localStorage.setItem('expiration_date_sec', expirationDateInSeconds)
}

export function isTokenExpired() {
    let todayInSeconds = new Date().getTime() / 1000;
    return getTokenExpirationDate() < todayInSeconds;
}


export const setLoading = (loading) =>
    (dispatch) => {
        dispatch({
            type: SET_LOADING,
            loading
        })
    };



export const toggleUpgradePlanModal = (status) =>
    (dispatch) => {
        dispatch({
            type: TOGGLE_UPGRADE_PLAN_MODAL,
            show_upgrade_plan_modal:status
        });
    };

export const toggleSignUPConfirmationModal = (status) =>
    (dispatch) => {
        dispatch({
            type: SET_SIGN_OUT_CONFIRMATION_MODAL,
            sign_out_confirmation_modal:status
        });
    };

export function setDarkModeToStorage(dark_mode) {
    localStorage.setItem('dark_mode', dark_mode)
}

export function getDarkModeFromStorage() {
    return localStorage.getItem('dark_mode');
}

export const setDarkMode = (dark_mode) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            setDarkModeToStorage(dark_mode)
            document.body.classList.remove(dark_mode ? 'light-mode' : 'dark-mode');
            document.body.classList.add(dark_mode ? 'dark-mode' : 'light-mode');
            dispatch({
                type: SET_DARK_MODE,
                dark_mode
            });
            resolve()
        })
    };

export const getDarkMode = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            let dark_mode = getDarkModeFromStorage();
            document.body.classList.remove(dark_mode === 'true' ? 'light-mode' : 'dark-mode');
            document.body.classList.add(dark_mode === 'true' ? 'dark-mode' : 'light-mode');
            dispatch({
                type: SET_DARK_MODE,
                dark_mode: dark_mode === 'true'
            });
        })
    };

export const setMainContentLanguage2 = (main_content_language) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            localStorage.setItem('main_content_language', main_content_language)
            dispatch({
                type: SET_MAIN_CONTENT_LANGUAGE,
                main_content_language
            });
        })
    };

export const getMainContentLanguage2 = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SET_MAIN_CONTENT_LANGUAGE,
                main_content_language:localStorage.getItem('main_content_language')
            });
        })
    };

export const setMainContentLanguage = (locale) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateBusinessInfo({locale:locale})
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_MAIN_CONTENT_LANGUAGE,
                        main_content_language:responseJson.data.locale
                    });
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };

export const getMainContentLanguage = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetBusinessInfo()
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    if(responseJson.data){
                        dispatch({
                            type: SET_MAIN_CONTENT_LANGUAGE,
                            main_content_language:responseJson.data.locale || 'en'
                        });
                    }
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };



export const getCouponCodeForBusiness = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetCouponCodeForBusiness()
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_COUPON_CODE_FOR_BUSINESS,
                        coupon_code_for_business:responseJson.data[0]
                    });
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };

export const updateCouponCodeForBusiness = (expiry_minutes) =>
    (dispatch) => {

        return new Promise((resolve, reject) => {
            api.fetchUpdateCouponCodeForBusiness(expiry_minutes)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_COUPON_CODE_FOR_BUSINESS,
                        coupon_code_for_business:responseJson.data

                    }
                    );
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })

    };

export const uploadBusinessPoster = (poster) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUploadBusinessPoster(poster)
                .then((data) => {
                    dispatch({
                        type: SET_BUSINESS_INFO,
                        business_info:data
                    });
                    resolve(data)
                })
                .catch((err) => reject(err))
        })
    };

//Actions for user
const ACTION_HANDLERS = {
  [SIGNIN]: (state, action) => ({
    ...state,
    user_info: action.user_info,
    signed_in: action.signed_in,
  }),
  [SET_CURRENT_USER]: (state, action) => ({
    ...state,
    user_info: action.user_info,
  }),
  [SIGNOUT]: (state, action) => ({
    ...state,
    user_info: action.user_info,
    signed_in: action.signed_in,
  }),
  [CHECK_SIGNED_IN]: (state, action) => ({
    ...state,
    signed_in: action.signed_in,
  }),
  [SET_USER_INFO]: (state, action) => ({
    ...state,
    user_info: action.user_info,
  }),
  [SET_BUSINESS_INFO]: (state, action) => ({
    ...state,
    business_info: action.business_info,
  }),
  [SET_DASHBOARD_INFO]: (state, action) => ({
    ...state,
    dashboard_info: action.dashboard_info,
  }),
  [SET_LOADING]: (state, action) => ({
    ...state,
    loading: action.loading,
  }),
  [TOGGLE_UPGRADE_PLAN_MODAL]: (state, action) => ({
    ...state,
    show_upgrade_plan_modal: action.show_upgrade_plan_modal,
  }),
  [SET_DARK_MODE]: (state, action) => ({
    ...state,
    dark_mode: action.dark_mode,
  }),
  [SET_SIGN_OUT_CONFIRMATION_MODAL]: (state, action) => ({
    ...state,
    sign_out_confirmation_modal: action.sign_out_confirmation_modal,
  }),
  [SET_MAIN_CONTENT_LANGUAGE]: (state, action) => ({
    ...state,
    main_content_language: action.main_content_language,
  }),
  [SET_COUPON_CODE_FOR_BUSINESS]: (state, action) => ({
    ...state,
    coupon_code_for_business: action.coupon_code_for_business,
  }),
};


export default function user(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

