import React from 'react';
import {Trans} from "react-i18next";

function EmptyHalfSection(props) {
    return (
      <div
        className={`element-half-section d-xl-flex justify-content-center align-items-center ${props.classes}`}
      >
        <p className="element-half-section__empty-title">
          <Trans>Select a Resource or identifier to see the details here</Trans>
        </p>
      </div>
    );
}


export default EmptyHalfSection;
