//All reducers for hotspots
import * as api from "../api";
import {handleErrors} from "./errors";

const SET_NEW_HOTSPOT = 'SET_NEW_HOTSPOT',
      SET_HOTSPOTS_ITEMS = 'SET_HOTSPOTS_ITEMS',
      SET_CURRENT_HOTSPOT = 'SET_CURRENT_HOTSPOT',
      SET_CREATE_HOTSPOT_MODE = 'SET_CREATE_HOTSPOT_MODE',
      SET_VIEW_MODE = 'SET_VIEW_MODE',
      SET_EDIT_HOTSPOT_MODE = 'SET_EDIT_HOTSPOT_MODE',
      SHOW_HOTSPOT_MODAL = 'SHOW_HOTSPOT_MODAL',
      SET_SCALE = 'SET_SCALE',
      SET_ROTATION = 'SET_ROTATION';

const initialState = {
    hotspots: [],
    hotspot_item: undefined,
    new_hotspot_data:{
        icon:'#hotspot-icon',
        position_x:0,
        position_y:0,
        position_z:-5,
        rotation_x:0,
        rotation_y:0,
        rotation_z:0,
        scale_x:1,
        scale_y:1,
        scale_z:1,
        tmp_scale:undefined,
        tmp_rotation:undefined,
    },
    create_hotspot_mode:false,
    view_mode:false,
    edit_hotspot_mode:false,
    show_hotspot_modal:false
};

export const getHotspots = (experience_id,scene_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetHotspots(experience_id,scene_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_HOTSPOTS_ITEMS,
                        hotspots: responseJson.data
                    });
                    resolve()
                })
                .catch( err => {
                    reject()
                })
        })

    };

export const createHotspot = (experience_id,scene_id,hotspot) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCreateHotspot(experience_id,scene_id,hotspot)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CURRENT_HOTSPOT,
                        hotspot_item: responseJson.data
                    });
                    resolve()
                })
                .catch( err => {
                    reject()
                })
        })

    };

export const updateHotspot = (experience_id,scene_id,hotspot_id,data) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateHotspot(experience_id,scene_id,hotspot_id,data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve()
                })
                .catch( err => {
                    reject()
                })
        })

    };

export const deleteHotspot = (experience_id,scene_id,ids) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteHotspot(experience_id,scene_id,ids)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_HOTSPOTS_ITEMS,
                        hotspots: responseJson.data
                    });
                    resolve()
                })
                .catch( err => {
                    reject()
                })
        })

    };


export const setNewHotspot = (new_hotspot_data) =>
    (dispatch) => {
        dispatch({
            type: SET_NEW_HOTSPOT,
            new_hotspot_data
        });
    };

export const setCurrentHotspot = (hotspot_item) =>
    (dispatch) => {
        dispatch({
            type: SET_CURRENT_HOTSPOT,
            hotspot_item
        });
    };

export const setCreateHotspotMode = (create_hotspot_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_CREATE_HOTSPOT_MODE,
            create_hotspot_mode
        });
    };

export const setViewMode = (view_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_VIEW_MODE,
            view_mode
        });
    };

export const setEditHotspotMode = (edit_hotspot_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_EDIT_HOTSPOT_MODE,
            edit_hotspot_mode
        });
    };

export const showHotspotModal = (show_hotspot_modal) =>
    (dispatch) => {
        dispatch({
            type: SHOW_HOTSPOT_MODAL,
            show_hotspot_modal
        });
    };

export const setScaleForHotspotContent = (tmp_scale) =>
    (dispatch) => {
        dispatch({
            type: SET_SCALE,
            tmp_scale
        });
    };

export const setRotationForHotspotContent = (tmp_rotation) =>
    (dispatch) => {
        dispatch({
            type: SET_ROTATION,
            tmp_rotation
        });
    };

export const clearHotspots = () =>
    (dispatch) => {
        dispatch({
            type: SET_HOTSPOTS_ITEMS,
            hotspots:[]
        });
    };
//Actions for hotspots
const ACTION_HANDLERS = {
    [SET_NEW_HOTSPOT]: (state, action) => ({
        ...state,
        new_hotspot_data: action.new_hotspot_data,
    }),
    [SET_HOTSPOTS_ITEMS]: (state, action) => ({
        ...state,
        hotspots: action.hotspots,
    }),
    [SET_CURRENT_HOTSPOT]: (state, action) => ({
        ...state,
        hotspot_item: action.hotspot_item,
    }),
    [SET_CREATE_HOTSPOT_MODE]: (state, action) => ({
        ...state,
        create_hotspot_mode: action.create_hotspot_mode,
    }),
    [SET_VIEW_MODE]: (state, action) => ({
        ...state,
        view_mode: action.view_mode,
    }),
    [SET_EDIT_HOTSPOT_MODE]: (state, action) => ({
        ...state,
        edit_hotspot_mode: action.edit_hotspot_mode,
    }),
    [SHOW_HOTSPOT_MODAL]: (state, action) => ({
        ...state,
        show_hotspot_modal: action.show_hotspot_modal,
    }),
    [SET_SCALE]: (state, action) => ({
        ...state,
        tmp_scale: action.tmp_scale,
    }),
    [SET_ROTATION]: (state, action) => ({
        ...state,
        tmp_rotation: action.tmp_rotation,
    }),


};


export default function scenes(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

