//View 3d model in resource modal
import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import '@google/model-viewer';


class Resource3dModelView extends React.Component {
    render() {
        let {file} = this.props;
        if(!file) return;
        return (
            <model-viewer src={file}
                          background-color="#332C51"
                          style={{width:'100%', height:'100%',borderRadius: 16,minHeight: '200px'}}
                          shadow-intensity="0.2"
                          autoplay
                          camera-controls
                          interaction-prompt="auto"
                          auto-rotate
                          ar
                          magic-leap />

        )
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
},dispatch);



export default connect(mapStateToProps, mapDispatchToProps)(Resource3dModelView);