import React from "react";
import ErrorBoundaryContainer from "../../components/ErrorBoundaryContainer";

function CardsList(props) {
    let {items,ItemComponent,isFavorites, classes} = props;
    return(
        <ErrorBoundaryContainer>
            <div className={`row ${classes}`}>
                {items.map((item_info,i) => {
                    if(isFavorites && !item_info.favorite) return null;
                    return <ItemComponent item_info={item_info} key={i+'ItemComponent'} {...props}/>
                })}
            </div>
        </ErrorBoundaryContainer>
    )
}

export default CardsList;
