//All reducers for experiences
import * as api from "../api";
import {handleErrors, handleErrorsFromCatch} from "./errors";

const SET_EXPERIENCE_ITEMS = "SET_EXPERIENCE_ITEMS",
  SET_CURRENT_EXPERIENCE = "SET_CURRENT_EXPERIENCE",
  SET_FIELD_EXPERIENCE = "SET_FIELD_EXPERIENCE",
  SET_SELECTED_DELETE_EXPERIENCES = "SET_SELECTED_DELETE_EXPERIENCES",
  SET_DELETE_EXPERIENCES_MODE = "SET_DELETE_EXPERIENCES_MODE",
  SET_DELETE_EXPERIENCE_MODAL_STATUS = "SET_DELETE_EXPERIENCE_MODAL_STATUS",
  SET_EDIT_EXPERIENCE_MODAL_STATUS = "SET_EDIT_EXPERIENCE_MODAL_STATUS",
  SET_VR_MODE = "SET_VR_MODE",
  SET_EXPERIENCE_LANGUAGE = "SET_EXPERIENCE_LANGUAGE",
  SET_UNITY_TEXTURES = "SET_UNITY_TEXTURES",
  SET_UNITY_DATA = "SET_UNITY_DATA",
  SET_EXPERIENCE_DATA = "SET_EXPERIENCE_DATA",
  SET_EXPERIENCE_CATEGORIES = "SET_EXPERIENCE_CATEGORIES";

const initialState = {
    experiences: [],
    experience_item: undefined,
    selected_delete_experiences: [],
    delete_experiences_mode: false,
    show_delete_experience_modal: false,
    show_edit_experience_modal: false,
    experience_categories: false,
    vr_mode: false,
    unity_textures: false,
    experience_language: 'en',
};

export const getExperiences = (relation,page) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetExperiences(relation,page)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setExperiencesAction(responseJson.data));
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })

    };

export const clearExperiences = () =>
    (dispatch) => {
        dispatch(setExperiencesAction(initialState.experiences))
    }

function setExperiencesAction(experiences) {
    return {
        type: SET_EXPERIENCE_ITEMS,
        experiences
    }
}

export const mappingElementsForExperience = (experience_id,items) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchMappingElementsForExperience(experience_id,items)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })

    };

export const createExperience = (experience) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCreateExperience(experience)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setExperienceItemAction(responseJson.data));
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })

    };

export const getExperienceItemById = (id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetExperienceItem(id)
                .then((res) => handleErrors(res,false,dispatch))
                .then((responseJson) => {
                    dispatch(setExperienceItemAction(responseJson.data));
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error,false,reject))
        })
    };

export function setExperienceItemAction(experience_item) {
    return {
        type: SET_CURRENT_EXPERIENCE,
        experience_item
    }
}

export const getUnityTextures = (type) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetUnityTextures(type)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_UNITY_TEXTURES,
                        unity_textures: responseJson.data
                    });
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const attachTexturesToExperience = (experience_id,ids) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchAttachTexturesToExperience(experience_id,ids)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    // dispatch({
                    //     type: SET_UNITY_TEXTURES,
                    //     unity_textures: responseJson.data
                    // });
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const attachSingleTextureToExperience = (experience_id,id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchAttachSingleTextureToExperience(experience_id,id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const setCurrentExperience = experience_item =>
    (dispatch) => {
        dispatch({
            type: SET_CURRENT_EXPERIENCE,
            experience_item
        });
    };

export const updateExperienceData = (id, experience_updated) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateExperienceData(id, experience_updated)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CURRENT_EXPERIENCE,
                        experience_item: responseJson.data
                    });
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const updateExperienceField = (id, experience_updated,field_name,language) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateExperienceData(id, experience_updated)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_FIELD_EXPERIENCE,
                        name:[field_name],
                        value:responseJson.data.translations[language] ? responseJson.data.translations[language][field_name] : null,
                        language:[language]
                    });
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const deleteSelectedExperiences = (selected_experiences) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteExperiences(selected_experiences)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const deleteSingleExperience = (id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteSingleExperience(id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CURRENT_EXPERIENCE,
                        experience_item:undefined
                    });
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const addTagsToExperience = (experience_id,names) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchAddHashtagsToExperience(experience_id,names)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })

    };

export const deleteTagsFromExperience = (experience_id,names) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteHashtagsFromExperience(experience_id,names)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })

    };

export const uploadExperienceFile = (id,file) =>
    (dispatch) => {
        return new Promise((resolve,reject) => {
            api.fetchUploadExperienceFile(id,file)
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    }

export const getUnityFile = (experience_id,business_id) => (dispatch) => {
         return new Promise((resolve, reject) => {
           api
             .getUnityFile(experience_id, business_id)
             .then((res) => handleErrors(res, true, dispatch))
             .then((responseJson) => {
               dispatch({
                 type: SET_UNITY_DATA,
                 unity_data: responseJson.data,
               });
               resolve(responseJson.data);
             })
             .catch((error) => handleErrorsFromCatch(error));
         });
       };

export const updateExperienceStatus = (id, experience_status) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .fetchUpdateExperienceStatus(id, experience_status)
      .then((res) => handleErrors(res, true, dispatch))
      .then((responseJson) => {
        dispatch({
          type: SET_EXPERIENCE_DATA,
          experience_item: responseJson.data,
        });
        resolve(responseJson);
      })
      .catch((error) => handleErrorsFromCatch(error));
  });
};

export const setSelectedDeleteExperiences = (selected_delete_experiences) =>
    (dispatch) => {
        dispatch({
            type: SET_SELECTED_DELETE_EXPERIENCES,
            selected_delete_experiences
        });
    };

export const setDeleteExperiencesMode = (delete_experiences_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_DELETE_EXPERIENCES_MODE,
            delete_experiences_mode
        });
    };

export const setDeleteExperienceModalStatus = (status) =>
    (dispatch) => {
        dispatch({
            type: SET_DELETE_EXPERIENCE_MODAL_STATUS,
            show_delete_experience_modal:status
        });
    };

export const setEditExperienceDetailsModalStatus = (status) =>
    (dispatch) => {
        dispatch({
            type: SET_EDIT_EXPERIENCE_MODAL_STATUS,
            show_edit_experience_modal:status
        });
    };

export const setVRMode = (vr_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_VR_MODE,
            vr_mode
        });
    };

export const setExperienceLanguage = (experience_language) =>
    (dispatch) => {
        dispatch({
            type: SET_EXPERIENCE_LANGUAGE,
            experience_language
        });
    };

//Actions for experiences
const ACTION_HANDLERS = {
  [SET_EXPERIENCE_ITEMS]: (state, action) => ({
    ...state,
    experiences: action.experiences,
  }),

  [SET_CURRENT_EXPERIENCE]: (state, action) => ({
    ...state,
    experience_item: action.experience_item,
  }),
  [SET_SELECTED_DELETE_EXPERIENCES]: (state, action) => ({
    ...state,
    selected_delete_experiences: action.selected_delete_experiences,
  }),
  [SET_DELETE_EXPERIENCES_MODE]: (state, action) => ({
    ...state,
    delete_experiences_mode: action.delete_experiences_mode,
  }),
  [SET_DELETE_EXPERIENCE_MODAL_STATUS]: (state, action) => ({
    ...state,
    show_delete_experience_modal: action.show_delete_experience_modal,
  }),
  [SET_EDIT_EXPERIENCE_MODAL_STATUS]: (state, action) => ({
    ...state,
    show_edit_experience_modal: action.show_edit_experience_modal,
  }),
  [SET_EXPERIENCE_CATEGORIES]: (state, action) => ({
    ...state,
    experience_categories: action.experience_categories,
  }),
  [SET_FIELD_EXPERIENCE]: (state, action) => ({
    ...state,
    experience_item: {
      ...state.experience_item,
      translations: {
        ...state.experience_item.translations,
        [action.language]: {
          ...state.experience_item.translations[action.language],
          [action.name]: action.value,
        },
      },
    },
  }),
  [SET_VR_MODE]: (state, action) => ({
    ...state,
    vr_mode: action.vr_mode,
  }),
  [SET_EXPERIENCE_LANGUAGE]: (state, action) => ({
    ...state,
    experience_language: action.experience_language,
  }),
  [SET_UNITY_TEXTURES]: (state, action) => ({
    ...state,
    unity_textures: action.unity_textures,
  }),
  [SET_UNITY_DATA]: (state, action) => ({
    ...state,
    unity_data: action.unity_data,
  }),
  [SET_EXPERIENCE_DATA]: (state, action) => ({
    ...state,
    experience_data: action.experience_data,
  }),
};


export default function experiences(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
