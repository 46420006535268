import React, {useEffect} from 'react';
import {Trans} from "react-i18next";
import {sidebarTypes, userRoles} from "../../../common";
import JobDetailsSection from "../jobs/JobDetailsSection";
import {withRouter} from "react-router-dom";
import TopSectionContractor from "../top-section/TopSectionContractor";
import BidsList from "./BidsList";
import TopSection from "../top-section/TopSection";

function JobBids (props) {
    useEffect(() => {
        let job_id = props.match.params.id;
        props.getJobItem(job_id).then((res) => {
            if(res.accepted_bid_id){
                props.getBidItem(res.accepted_bid_id)
                props.getBidComments({bid_id:res.accepted_bid_id})
            }else if(props.bid_item) props.getBidComments({bid_id:props.bid_item.id})
        })
        props.setSidebar(sidebarTypes.none)
        return () => {
            props.clearBids()
            props.clearBidComments()
            props.setCurrentBid(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    let classToggle = props.sidebar_toggled || props.sidebar_type === sidebarTypes.none ? '' : 'sidebar-wrap_toggled'
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;

    return(
        <div className={`sidebar-wrap sidebar-wrap_wide ${classToggle}`}>
            <div className="marketplace-wrap">
                {isContractor ? <TopSectionContractor {...props}/> : <TopSection {...props}/>}
                {props.job_item && props.job_item.accepted_bid_id ?
                    <JobDetailsSection {...props}/>
                :
                    <div className="row">
                        <div className="col-md-5">
                            <div className="bids-section">
                                <div className="bids-section__inner-offset">
                                    <div className="bids-section__header-inner">
                                        <Trans>Bids</Trans>
                                    </div>
                                </div>
                                <div className="bids-section__list">
                                    <BidsList {...props} onlySubmitted job_id={props.match.params.id}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <JobDetailsSection {...props}/>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default withRouter(JobBids);
