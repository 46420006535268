//All API calls
import {setAuthorizationHeader} from './store/user'
import {getResourceTypesQueryString} from "./common";
export const client_id = process.env.REACT_APP_CLIENT_ID,
    client_secret = process.env.REACT_APP_CLIENT_SECRET,
    api_root = process.env.REACT_APP_API_ROOT,
    mobile_api_root = process.env.REACT_APP_API_END_POINT;

export const API_ROOT = api_root,
         API_URL = API_ROOT + "/api",
         MOBILE_API_URL = mobile_api_root + "mobile-api",
         FETCH_AUTH_PATH = API_ROOT + "/oauth/token",
         FETCH_REGISTER_PATH = API_URL + "/register",
         FETCH_USER_PATH = API_URL + "/user",
         FETCH_USER_AVATAR_PATH = FETCH_USER_PATH + "/avatar",
         FETCH_BUSINESS_PATH = API_URL + "/business",
         FETCH_DASHBOARD_PATH = API_URL + "/dashboard",
         FETCH_BUSINESS_POSTER_PATH = FETCH_BUSINESS_PATH + "/posters",
         FETCH_BUSINESS_PACKAGE_UPGRADE_PATH =
           API_URL + "/business-package-upgrade",
         FETCH_RESET_PASSWORD_PATH = API_URL + "/password/reset",
         FETCH_EXIST_EMAIL_PATH = FETCH_REGISTER_PATH + "/exist-email",
         FETCH_EXIST_USERNAME_PATH = FETCH_REGISTER_PATH + "/exist-username",
         FETCH_ELEMENTS_PATH = API_URL + "/elements",
         FETCH_SUB_ELEMENT_PATH = "/sub-elements",
         FETCH_AUTO_TRANSLATE_PATH = "/translate",
         FETCH_IDENTIFIER_PATH = "/identifiers",
         FETCH_DIGITALPRINT_PATH = "/digitalprint",
         FETCH_IDENTIFIER_LEARNING_CONFIRM_PATH =
           API_URL + "/training-learn-confirm",
         FETCH_IDENTIFIER_LEARNING_STATUS_PATH =
           API_URL + "/training-learn-status",
         FETCH_IDENTIFIER_CONFIRM_PUSH_PATH =
           API_URL + "/training-push-confirm",
         FETCH_RESOURCES_PATH = "/resources",
         FETCH_SUPPLEMENT_FILES_PATH = "/supplement-files",
         FETCH_FILES_PATH = API_URL + "/files",
         FETCH_PAINTING_IMAGES_PATH = "/painting-image",
         FETCH_VALIDATION_RULES_PATH = API_URL + "/validation-rules",
         FETCH_EXPERIENCES_PATH = API_URL + "/experiences",
         FETCH_VREXPERIENCES_PATH = API_URL + "/vrexperiences",
         FETCH_UNITY_TEXTURES_PATH = "/unity-textures",
         FETCH_HOTSPOTS_PATH = "/hotspots",
         FETCH_HOTSPOT_ITEM = "/hotspot",
         FETCH_SCENES_PATH = "/scenes",
         FETCH_SCENE_ITEM_PATH = "/scene",
         FETCH_METADATA_PATH = "/metadata",
         FETCH_REORDER_SCENES_PATH = "/scene-position",
         FETCH_TAGS_PATH = "/tags",
         FETCH_ANALYTICS_PATH = "/analytics",
         FETCH_QUIZZES_PATH = "/quizzes",
         FETCH_MULTIPLE_ANSWERS_PATH = "/multiple-answers",
         FETCH_ANSWERS_PATH = "/answers",
         FETCH_REQUEST_CREATION_RESOURCE_PATH = "/business-request-resource",
         FETCH_EXPERIENCE_CATEGORIES_PATH = API_URL + "/experience-categories",
         FETCH_COUPON_CODE_FOR_BUSINESS =
           API_URL + "/paywall/business-passcodes";

const API_MARKETPLACE_URL = API_ROOT + '/marketplace-api',
    JOBS_PATH = '/jobs',
    CONTRACTORS_PATH = '/contractors',
    ATTACHMENTS_PATH = '/attachments',
    COMMENTS_PATH = '/comments',
    BIDS_PATH = '/bids',
    ORDERS_PATH = '/orders',
    PORTFOLIO_PATH = '/portfolio',
    AVATAR_PATH = '/avatar',
    CONFIRM_BID_PATH = '/confirm-bid',
    REJECT_BID_PATH = '/reject-bid';
//hotspots
export function fetchGetHotspots(experience_id,scene_id) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENE_ITEM_PATH + `/${scene_id}` + FETCH_HOTSPOTS_PATH, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}

export function fetchCreateHotspot(experience_id,scene_id,hotspot) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENE_ITEM_PATH + `/${scene_id}` + FETCH_HOTSPOT_ITEM, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(hotspot)
    })
}

export function fetchUpdateHotspot(experience_id,scene_id,hotspot_id,data) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENE_ITEM_PATH + `/${scene_id}` + FETCH_HOTSPOT_ITEM + `/${hotspot_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(data)
    })
}

export function fetchDeleteHotspot(experience_id,scene_id,ids) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENE_ITEM_PATH + `/${scene_id}` + FETCH_HOTSPOTS_PATH, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify({ids})
    })
}
//end hotspots
//experiences
export function fetchGetExperiences() {
    return fetch(FETCH_VREXPERIENCES_PATH + "?limit=1000", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: setAuthorizationHeader(),
      },
    });
}

export function fetchMappingElementsForExperience(experience_id,items) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}/elements`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify({items})
    })
}

export function fetchCreateExperience(experience) {
    return fetch(FETCH_EXPERIENCES_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(experience)
    })
}

export function fetchGetExperienceItem(id) {
    return fetch(FETCH_VREXPERIENCES_PATH + `/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: setAuthorizationHeader(),
      },
    });
}

export function fetchUpdateExperienceStatus(id, experience_status) {
         return fetch(FETCH_VREXPERIENCES_PATH + `/${id}`, {
           method: "PUT",
           headers: {
             "Content-Type": "application/json",
             Accept: "application/json",
             Authorization: setAuthorizationHeader(),
           },
           body: JSON.stringify(experience_status),
         });
       }

export function fetchGetUnityTextures(type) {
    let $type = `?type[]=${type}`;

    return fetch(API_URL + FETCH_UNITY_TEXTURES_PATH + `/${$type}`, {
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}

export function fetchAttachTexturesToExperience(experience_id,ids) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_UNITY_TEXTURES_PATH, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify({ids})
    })
}

export function fetchAttachSingleTextureToExperience(experience_id,id) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_UNITY_TEXTURES_PATH, {
        method:'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify({id})
    })
}

export function fetchUpdateExperienceData(id,experience_updated) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${id}`,{
        method:'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(experience_updated)
    })
}


export function fetchSetElementImage(id,file) {
    let formdata = new FormData();
    formdata.append('file', file);
    return fetch(FETCH_ELEMENTS_PATH + `/${id}/preview`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: formdata
    })
}

export function fetchDeleteExperiences(selected_experiences) {
    return fetch(FETCH_VREXPERIENCES_PATH, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: setAuthorizationHeader(),
      },
      body: JSON.stringify({ ids: selected_experiences }),
    });
}

export function fetchAddHashtagsToExperience(experience_id,names) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_TAGS_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({names})
    })
}

export function fetchDeleteHashtagsFromExperience(experience_id,names) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_TAGS_PATH,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({names})
    })
}
export function fetchGetExperienceCategories() {
    return fetch(FETCH_EXPERIENCE_CATEGORIES_PATH,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}
export function fetchDeleteSingleExperience(id) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${id}`,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}
export function fetchUploadExperienceFile(id,file) {
    return new Promise((resolve,reject) => {
        let formdata = new FormData();
        formdata.append('file', file);
        var xhr = new XMLHttpRequest()
        xhr.onload = function() {
            resolve(JSON.parse(xhr.responseText).data);
        }
        xhr.onerror = (err) => console.log(err,'err')
        // xhr.upload.onprogress = function (event) {
        //     if (event.lengthComputable) {
        //         var percent = Math.round((event.loaded / event.total) * 100)
        //         dispatch({
        //             type: SET_FILE_PROGRESS,
        //             progress_percent:percent
        //         });
        //     }
        // }
        xhr.open('POST', FETCH_EXPERIENCES_PATH + `/${id}/file`)
        xhr.setRequestHeader('Accept', 'application/json')
        xhr.setRequestHeader('Authorization', setAuthorizationHeader())
        xhr.send(formdata)
    })
}
//end experiences

//scenes
export function fetchGetScenes(experience_id) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENES_PATH + '?limit=1000', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}

export function fetchReorderScenes(experience_id,ids) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_REORDER_SCENES_PATH, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify({ids})

    })
}

export function fetchGetSceneItem(experience_id,scene_id) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENE_ITEM_PATH + `${scene_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}

export function fetchCreateScene(experience_id,scene) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENE_ITEM_PATH, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(scene)
    })
}

export function fetchDeleteScenes(experience_id,scenes_ids) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENES_PATH,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({ids:scenes_ids})
    })
}

export function fetchUpdateScene(experience_id,scene_id,updated_scene) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENE_ITEM_PATH + `/${scene_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(updated_scene)
    })
}
export function fetchCreateSceneInitialView(experience_id,scene_id,initial_view_data) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENE_ITEM_PATH + `/${scene_id}` + FETCH_METADATA_PATH, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(initial_view_data)
    })
}

export function fetchGetSceneInitialView(experience_id,scene_id) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}` + FETCH_SCENE_ITEM_PATH + `/${scene_id}` + FETCH_METADATA_PATH, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}
//end scenes

// elements
export function fetchGetElements(relation,page) {
    let $relation = `&relations[]=${relation}`;
    let $page = page? `&page=${page}` : '';
    return fetch(FETCH_ELEMENTS_PATH + `?limit=12${$page}${$relation}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}

export function fetchGetElements2(params) {
    let {relation,page,vr_active} = params;
    let $relation = relation ? `&relations[]=${relation}` : '';
    let $vr_active = vr_active ? `&vr_active=${vr_active}` : '';
    let $page = page? `&page=${page}` : '';
    return fetch(FETCH_ELEMENTS_PATH + `?limit=12${$page}${$relation}${$vr_active}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}
export function fetchGetElementItem(id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${id}`, {
        method:'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}

export function fetchUpdateElementData(id,element_updated) {
    return fetch(FETCH_ELEMENTS_PATH + `/${id}`,{
        method:'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(element_updated)
    })
}

export function fetchAttachFirstResourceImageToElementPreview(element_id,resource_id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_PAINTING_IMAGES_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify({resource_id})
    })
}

export function fetchCreateElement(element) {
    return fetch(FETCH_ELEMENTS_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(element)
    })
}

export function fetchDeleteElements(selected_elements) {
    return fetch(FETCH_ELEMENTS_PATH,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({ids:selected_elements})
    })
}

export function fetchDeleteSingleElement(element_id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}`,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchAutoTranslateElement(element_id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_AUTO_TRANSLATE_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}
export function fetchAddHashtags(element_id,names) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_TAGS_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({names})
    })
}

export function fetchDeleteHashtags(element_id,names) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_TAGS_PATH,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({names})
    })
}

export function fetchDeleteIdentifier(element_id,identifier_id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_IDENTIFIER_PATH + `/${identifier_id}`,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchDeleteDigitalPrint(element_id,digital_print_id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_DIGITALPRINT_PATH + `/${digital_print_id}`,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchGetIdentifiers(element_id,page) {
    let $page = page? `page=${page}` : '';
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_IDENTIFIER_PATH + `?${$page}&limit=30`,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchGetDigitalPrints(element_id,page) {
    let $page = page? `page=${page}` : '';
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_DIGITALPRINT_PATH + `?${$page}&limit=30`,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchCheckStatusOfLearningML() {
    return fetch(FETCH_IDENTIFIER_LEARNING_STATUS_PATH,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchStartLearningOfML() {
    return fetch(FETCH_IDENTIFIER_LEARNING_CONFIRM_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchConfirmPushIdetifiers() {
    return fetch(FETCH_IDENTIFIER_CONFIRM_PUSH_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}


export function fetchGetElementAnalytics(element_id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_ANALYTICS_PATH + `?data[0][interval]=hourly&data[0][range]=48&data[1][interval]=daily&data[1][range]=14`,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchGetElementQuiz(element_id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_QUIZZES_PATH ,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchCreateElementQuiz(element_id,quiz_data) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_QUIZZES_PATH ,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify(quiz_data)
    })
}

export function fetchDeleteElementQuiz(element_id,quiz_id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_QUIZZES_PATH +`/${quiz_id}`,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        }
    })
}

export function fetchActiveElementQuiz(quiz_id) {
  return fetch(FETCH_DASHBOARD_PATH, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: setAuthorizationHeader(),
    },
    body: JSON.stringify({"quiz_id": quiz_id}),
  });
}

export function fetchUpdateElementQuiz(element_id,quiz_id,quiz_data) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_QUIZZES_PATH +`/${quiz_id}`,{
        method:'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify(quiz_data)
    })
}

export function fetchAutoTranslateQuiz(quiz_id) {
    return fetch(API_URL + FETCH_QUIZZES_PATH + `/${quiz_id}` + FETCH_AUTO_TRANSLATE_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}

export function fetchDeleteSingleQuizAnswer(quiz_id,answer_id) {
    return fetch(API_URL + FETCH_QUIZZES_PATH + `/${quiz_id}` + FETCH_ANSWERS_PATH +`/${answer_id}`,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        }
    })
}

export function fetchUpdateSingleQuizAnswer(quiz_id,answer_id,answer_data) {
    return fetch(API_URL + FETCH_QUIZZES_PATH + `/${quiz_id}` + FETCH_ANSWERS_PATH +`/${answer_id}`,{
        method:'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify(answer_data)
    })
}

export function fetchCreateQuizAnswers(quiz_id,answers) {
    return fetch(API_URL + FETCH_QUIZZES_PATH +`/${quiz_id}` + FETCH_MULTIPLE_ANSWERS_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({data:answers})
    })
}
//elements end

//nodes
export function fetchGetElementNodesById(experience_id,scene_id,element_id) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}/scene/${scene_id}/elements/${element_id}/nodes`,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchCreateElementNode(experience_id,scene_id,element_id,node_info) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}/scene/${scene_id}/elements/${element_id}/nodes`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({...node_info})
    })
}

export function fetchMultipleNodes(experience_id,scene_id,element_id,nodes) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}/scene/${scene_id}/elements/${element_id}/multiple-nodes`,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({nodes})
    })
}

export function fetchEditElementNode(experience_id,scene_id,element_id,node_id,node_info) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}/scene/${scene_id}/elements/${element_id}/nodes/${node_id}`,{
        method:'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({...node_info})
    })
}

export function fetchDeleteElementNodesFromScene(experience_id,scene_id,element_id) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}/scene/${scene_id}/elements/${element_id}`,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchElementsFromScene(experience_id,scene_id) {
    return fetch(FETCH_EXPERIENCES_PATH + `/${experience_id}/scene/${scene_id}/elements`,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}
//nodes end

//resources
export function fetchGetResources(types,element_id,limit,page) {
    let element_id_param = element_id ? `&element_id=${element_id}` : '';
    let limit_param = limit ? `limit=${limit}` : 'limit=8';
    let $page = page? `&page=${page}` : '';
    return fetch(API_URL + FETCH_RESOURCES_PATH + `?${limit_param}${$page}${getResourceTypesQueryString(types)}${element_id_param}`,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        }
    })
}

export function fetchUpdateResourceData(id,resource_updated) {
    return fetch(API_URL + FETCH_RESOURCES_PATH + `/${id}`,{
        method:'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(resource_updated)
    })
}

export function fetchAddResourceImage(file,resource_id) {
    let formdata = new FormData();
    formdata.append('file', file);
    formdata.append('resource_id', resource_id);
    return fetch(FETCH_FILES_PATH,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: formdata
    })
}

export function fetchCreateResource(resource_data) {
    return fetch(API_URL + FETCH_RESOURCES_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
        body: JSON.stringify(resource_data)
    })
}

export function fetchGetResourceById(resource_id) {
    return fetch(API_URL + FETCH_RESOURCES_PATH + `/${resource_id}`,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader(),
        },
    })
}
//Multiple attach resources to element
export function fetchBindResources(element_id,ids) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}`+ FETCH_RESOURCES_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({ids})
    })
}

export function fetchUnBindResources(element_id,ids) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}`+ FETCH_RESOURCES_PATH,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({ids})
    })
}

export function fetchDeleteResources(selected_resources) {
    return fetch(API_URL + FETCH_RESOURCES_PATH,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({ids:selected_resources})
    })
}

export function fetchDeleteSingleResource(resource_id) {
    return fetch(API_URL + FETCH_RESOURCES_PATH + `/${resource_id}`,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchAddHashtagsToResource(resource_id,names) {
    return fetch(API_URL + FETCH_RESOURCES_PATH + `/${resource_id}` + FETCH_TAGS_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({names})
    })
}

export function fetchDeleteHashtagsFromResource(resource_id,names) {
    return fetch(API_URL + FETCH_RESOURCES_PATH + `/${resource_id}` + FETCH_TAGS_PATH,{
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({names})
    })
}

export function fetchGetResourceAnalytics(resource_id) {
    return fetch(API_URL + FETCH_RESOURCES_PATH + `/${resource_id}` + FETCH_ANALYTICS_PATH + `?data[0][interval]=hourly&data[0][range]=48&data[1][interval]=daily&data[1][range]=14`,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchRequestCreationResource(type_id) {
    return fetch(API_URL + FETCH_REQUEST_CREATION_RESOURCE_PATH ,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({type_id})
    })
}
//resources end

//user
export function fetchSignIn(userData) {
    let grant_type = "password";

    return fetch(FETCH_AUTH_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            grant_type,
            client_id,
            client_secret,
            ...userData
        })
    })
}

export function fetchSignUp(userData) {
    return fetch(FETCH_REGISTER_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(userData)
    })
}

export function fetchRefreshToken(refresh_token) {
    let grant_type = "refresh_token";
    return fetch(FETCH_AUTH_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            grant_type,
            client_id,
            client_secret,
            refresh_token
        })
    })
}

export function fetchValidationRules(group_name) {
    let param = group_name ? `?group_name=${group_name}` : '';
    return fetch(FETCH_VALIDATION_RULES_PATH + param,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchCheckExistEmail(email) {
    return fetch(FETCH_EXIST_EMAIL_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({email})
    })
}

export function fetchSendOtp(email) {
  return fetch(MOBILE_API_URL + `/user/sendotp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email }),
  });
}

export function fetchCheckOtp(email, otp) {
  return fetch(MOBILE_API_URL + `/user/checkotp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email, otp }),
  });
}

export function fetchCheckExistUsername(username) {
    return fetch(FETCH_EXIST_USERNAME_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({username})
    })
}

export function fetchUploadUserAvatar(avatar){
    let formdata = new FormData();
    formdata.append("avatar", avatar);
    return fetch(FETCH_USER_PATH + AVATAR_PATH, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: formdata
    })
}

export function fetchDeleteUserAvatar(){
    return fetch(FETCH_USER_PATH + AVATAR_PATH, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}
export function fetchResetPassword(email) {
    return fetch(FETCH_RESET_PASSWORD_PATH,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({email})
    })
}

export function fetchCheckTokenForResetPassword(token,email) {
    return fetch(FETCH_RESET_PASSWORD_PATH + `/${token}?email=${email}`,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    })
}

export function fetchNewPassword(token,email,password,password_confirmation) {
    return fetch(FETCH_RESET_PASSWORD_PATH + `/${token}`,{
        method:'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({email,password,password_confirmation})
    })
}

export function fetchGetUserInfo() {
    return fetch(FETCH_USER_PATH,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchUpdateUserInfo( data) {
    return fetch(FETCH_USER_PATH,{
        method:'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({...data})

    })
}

export function fetchUploadBusinessPoster( poster) {
    return new Promise((resolve,reject) => {
        let formdata = new FormData();
        formdata.append('poster', poster);
        var xhr = new XMLHttpRequest()
        xhr.onload = function() {
            resolve(JSON.parse(xhr.responseText).data);
        }
        xhr.onerror = (err) => console.log(err,'err')
        // xhr.upload.onprogress = function (event) {
        //     if (event.lengthComputable) {
        //         var percent = Math.round((event.loaded / event.total) * 100)
        //         dispatch({
        //             type: SET_FILE_PROGRESS,
        //             progress_percent:percent
        //         });
        //     }
        // }
        xhr.open('POST', FETCH_BUSINESS_POSTER_PATH)
        xhr.setRequestHeader('Accept', 'application/json')
        xhr.setRequestHeader('Authorization', setAuthorizationHeader())
        xhr.send(formdata)
    })
}


export function fetchUpdateBusinessInfo( data) {
    return fetch(FETCH_BUSINESS_PATH,{
        method:'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify({...data})

    })
}

export function fetchGetBusinessInfo( data) {
    return fetch(FETCH_BUSINESS_PATH,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchGetDashboardInfo() {
    return fetch(FETCH_DASHBOARD_PATH, {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: setAuthorizationHeader(),
    },
    });
}
export function fetchSendEmailToUpgradePlan() {
    return fetch(FETCH_BUSINESS_PACKAGE_UPGRADE_PATH,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}
//user end

//Coupon Code Begin
export function fetchGetCouponCodeForBusiness() {
    return fetch(FETCH_COUPON_CODE_FOR_BUSINESS,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchUpdateCouponCodeForBusiness( expires_at) {
    return fetch(FETCH_COUPON_CODE_FOR_BUSINESS,{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()

        },
        body: JSON.stringify({expires_at})

    })
}
//Coupon Code End

//sub elements
export function fetchCreateSubElement(element_id,identifier_id,data) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_SUB_ELEMENT_PATH, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()

        },
        body: JSON.stringify({identifier_id,...data})
    })
}

export function fetchEditSubElement(element_id,sub_element_id,data) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_SUB_ELEMENT_PATH + `/${sub_element_id}`, {
        method:'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()

        },
        body: JSON.stringify({...data})
    })
}

export function fetchGetSubElements(element_id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_SUB_ELEMENT_PATH, {
        method:'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()

        },
    })
}

export function fetchDeleteSubElement(element_id,sub_element_id) {
    return fetch(FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_SUB_ELEMENT_PATH + `/${sub_element_id}`, {
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()

        },
    })
}

export function fetchLinkResourceToSubElement(sub_element_id,ids) {
    return fetch(API_URL + FETCH_SUB_ELEMENT_PATH + `/${sub_element_id}/resources`, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()

        },
        body: JSON.stringify(({ids}))
    })
}

export function fetchUnLinkResourceFromSubElement(sub_element_id,resource_id) {
    return fetch(API_URL + FETCH_SUB_ELEMENT_PATH + `/${sub_element_id}/resources/${resource_id}`, {
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()

        },
    })
}
//end sub elements

//marketplace
export function fetchGetJobs(params){
    let {business_id,contractor_id,page,status_id} = params;
    let $page = page ? `?page=${page}` : '';
    let $business_id = business_id ? `&business_id=${business_id}` : '';
    let $contractor_id = contractor_id ? `&contractor_id=${contractor_id}` : '';
    let $status_id = '';
    status_id.map((id,i) => {
        return $status_id += `&status_id[${i}]=${id}`
    })
    return fetch(API_MARKETPLACE_URL + JOBS_PATH + `${$page}${$business_id}${$contractor_id}${$status_id}&limit=6`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        }
    })
}

export function fetchAttachFilesToComments(id,files){
    let formdata = new FormData();
    for (let x = 0; x < files.length; x++) {
        formdata.append("attachment[]", files[x]);
    }
    return fetch(API_MARKETPLACE_URL + COMMENTS_PATH + `/${id}` + ATTACHMENTS_PATH, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: formdata
    })
}

export function fetchGetBidComments(params){
    let {bid_id} = params;
    // let $page = page ? `&page=${page}` : '';
    return fetch(API_MARKETPLACE_URL + BIDS_PATH + `/${bid_id}` + COMMENTS_PATH, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        }
    })
}

export function fetchGetJobAttachments(job_id){
    return fetch(API_MARKETPLACE_URL + JOBS_PATH + `/${job_id}` + ATTACHMENTS_PATH, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        }
    })
}

export function fetchPostJob(data){
    return fetch(API_MARKETPLACE_URL + JOBS_PATH, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify(({...data}))
    })
}

export function fetchUpdateJob(data,job_id){
    return fetch(API_MARKETPLACE_URL + JOBS_PATH + `/${job_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify(({...data}))
    })
}

export function fetchUpdateJobStatus(status_id,job_id){
    return fetch(API_MARKETPLACE_URL + JOBS_PATH + `/${job_id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify(({status_id}))
    })
}

export function fetchDeleteDraftJob(job_id){
    return fetch(API_MARKETPLACE_URL + JOBS_PATH + `/${job_id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchGetJobItem(job_id){
    return fetch(API_MARKETPLACE_URL + JOBS_PATH + `/${job_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchAttachFiles(job_id,files){
    let formdata = new FormData();
    for (let x = 0; x < files.length; x++) {
        formdata.append("attachment[]", files[x]);
    }
    return fetch(API_MARKETPLACE_URL + JOBS_PATH + `/${job_id}` + ATTACHMENTS_PATH, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: formdata
    })
}

export function fetchAttachFilesToBid(bid_id,files){
    let formdata = new FormData();
    for (let x = 0; x < files.length; x++) {
        formdata.append("attachment[]", files[x]);
    }
    return fetch(API_MARKETPLACE_URL + BIDS_PATH + `/${bid_id}` + ATTACHMENTS_PATH, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: formdata
    })
}

export function fetchUpdateBidStatus(status_id,bid_id){
    return fetch(API_MARKETPLACE_URL + BIDS_PATH + `/${bid_id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify(({status_id}))
    })
}


export function fetchDeleteFile(job_id,file_id){
    return fetch(API_MARKETPLACE_URL + JOBS_PATH + `/${job_id}` + ATTACHMENTS_PATH + `/${file_id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchGetBids(params){
    let {job_id,contractor_id,page,status_id} = params;
    let $job_id = job_id ? `&job_id=${job_id}` : '';
    let $contractor_id = contractor_id ? `&contractor_id=${contractor_id}` : '';
    let $status_id = status_id ? `&status_id=${status_id}` : '';
    let $page = page ? `?page=${page}` : '';
    return fetch(API_MARKETPLACE_URL + BIDS_PATH + `${$page}${$job_id}${$contractor_id}${$status_id}&limit=6`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        }
    })
}

export function fetchGetBidItem(bid_id){
    return fetch(API_MARKETPLACE_URL + BIDS_PATH + `/${bid_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        }
    })
}

export function fetchGetOrders(params) {
  let { user_id, business_id, page} = params;
  let $user_id = user_id ? `&user_id=${user_id}` : "";
  let $business_id = business_id ? `&business_id=${business_id}` : "";
  let $page = page ? `?page=${page}` : "";
  return fetch(
    API_MARKETPLACE_URL +
      ORDERS_PATH +
      `${$page}${$user_id}${$business_id}&limit=6`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: setAuthorizationHeader(),
      },
    }
  );
}

export function fetchGetOrderItem(bid_id) {
         return fetch(API_MARKETPLACE_URL + BIDS_PATH + `/${bid_id}`, {
           method: "GET",
           headers: {
             "Content-Type": "application/json",
             Accept: "application/json",
             Authorization: setAuthorizationHeader(),
           },
         });
       }

export function fetchApproveBid(job_id,bid_id){
    return fetch(API_MARKETPLACE_URL + JOBS_PATH + `/${job_id}` + CONFIRM_BID_PATH + `/${bid_id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        }
    })
}

export function fetchDeclineBid(job_id,bid_id){
    return fetch(API_MARKETPLACE_URL + JOBS_PATH + `/${job_id}` + REJECT_BID_PATH + `/${bid_id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        }
    })
}

export function fetchCreateBid(data){
    return fetch(API_MARKETPLACE_URL + BIDS_PATH, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify(({...data}))

    })
}

export function fetchGetContractorInfo(contractor_id){
    return fetch(API_MARKETPLACE_URL + CONTRACTORS_PATH + `/${contractor_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchGetContractorPortfolio(params){
    let {contractor_id,page} = params;
    let $page = page ? `?page=${page}` : '';
    return fetch(API_MARKETPLACE_URL + CONTRACTORS_PATH + `/${contractor_id}` + PORTFOLIO_PATH + `${$page}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchCreateContractorPortfolioItem(contractor_id,data){
    let formdata = new FormData();
    formdata.append("attachment", data.file);
    formdata.append("description", data.description);

    return fetch(API_MARKETPLACE_URL + CONTRACTORS_PATH + `/${contractor_id}` + PORTFOLIO_PATH, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: formdata
    })
}

export function fetchDeleteContractorPortfolioItem(contractor_id,porfolio_id){
    return fetch(API_MARKETPLACE_URL + CONTRACTORS_PATH + `/${contractor_id}` + PORTFOLIO_PATH + `/${porfolio_id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchUploadContractorAvatar(contractor_id,avatar){
    let formdata = new FormData();
    formdata.append("avatar", avatar);
    return fetch(API_MARKETPLACE_URL + CONTRACTORS_PATH + `/${contractor_id}` + AVATAR_PATH, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: formdata
    })
}

export function fetchDeleteContractorAvatar(contractor_id){
    return fetch(API_MARKETPLACE_URL + CONTRACTORS_PATH + `/${contractor_id}` + AVATAR_PATH, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
    })
}

export function fetchUpdateContractorInfo(contractor_id,data){
    return fetch(API_MARKETPLACE_URL + CONTRACTORS_PATH + `/${contractor_id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify(({...data}))
    })
}

export function fetchCreateBidComment(bid_id,message){
    return fetch(API_MARKETPLACE_URL + BIDS_PATH + `/${bid_id}` + COMMENTS_PATH, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': setAuthorizationHeader()
        },
        body: JSON.stringify(({message}))
    })
}

export function fetchSignUpContractor(userData) {
    return fetch(API_MARKETPLACE_URL + '/register',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(userData)
    })
}

export function uploadUnityFile(id,  file) {
    return new Promise((resolve, reject) => {
    let formdata = new FormData();
    formdata.append("unity_file", file);
    formdata.append("business_id", id);
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        resolve(JSON.parse(xhr.responseText).data);
    };
    xhr.onerror = (err) => console.log(err, "err");
    xhr.open("POST", MOBILE_API_URL + `/storeUnityFile`);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Authorization", setAuthorizationHeader());
    xhr.send(formdata);
    });
}

export function getUnityFile(experience_id, business_id) {
  return fetch(MOBILE_API_URL + `/unityFile/` + business_id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: setAuthorizationHeader(),
    },
  });
}
//end marketplace
