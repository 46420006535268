import React from 'react';
import InputWithButtons from "../InputWithButtons";
import {useFormik} from "formik";
import {setExperienceSchema} from "../../store/validations";

function ExperienceName(props) {
    let hasName = props.experience_item && props.experience_item.translations && props.experience_item.translations[props.experience_language];
    let formik = useFormik({
        initialValues: {name: hasName ? props.experience_item.translations[props.experience_language].name : ''},
        enableReinitialize: true,
        validationSchema: setExperienceSchema(props.validation_rules,props.t)
    });
    let name_invalid_class = formik.errors.name && formik.touched.name ? 'invalid' : '';

    const onBlurSave = (error,value,name) => {
        if(!error){
            let language = props.experience_language;
            let {experience_item} = props;
            if (hasName && props.experience_item.translations[props.experience_language].name === value) return;
            let experience_updated = {
                    translations: {
                        ...experience_item.translations,
                        [language]:{
                            ...experience_item.translations[language],
                            [name]:value
                        }
                    }
                },
                id = experience_item.id;
            props.updateExperienceField(id, experience_updated, name,language)
        }
    };

    return(
        <form onSubmit={formik.handleSubmit} name="">
            <InputWithButtons handleSave={() => onBlurSave(formik.errors.name,formik.values.name,"name")}
                              handleDiscard={() => formik.resetForm()} wrapClasses="mb-0">
                <input
                    // disabled={props.experience_item.active}
                    id="name"
                    name="name"
                    placeholder={props.t("Experience name")}
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    // onBlur={() => onBlurSave(formik.errors.name,formik.values.name,"name")}
                    className={`form-control input-primary d-block ${name_invalid_class} input-borderless h-auto`}/>
                {formik.errors.name && formik.touched.name && (
                    <div className="input-feedback">{formik.errors.name}</div>
                )}
            </InputWithButtons>
        </form>
    )
}

export default ExperienceName;