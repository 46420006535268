import React from 'react';
import {NavLink} from "react-router-dom";
import {Trans} from "react-i18next";
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";
// import CommentsDropdown from "./CommentsDropdown";
// import NotificationsDropdown from "./NotificationsDropdown";

function TopSection () {
    return(
        <ErrorBoundaryContainer>
            <nav className="top-section">
                <div className="row">
                    <div className="col">
                        <NavLink exact to="/marketplace" className="top-section__link">
                            <span><Trans>My jobs</Trans></span>
                        </NavLink>
                    </div>
                    <div className="col d-flex flex-row justify-content-end align-items-center">
                        <NavLink exact to="/post-job">
                            <button className='btn btn-primary'><Trans>Post new job</Trans></button>
                        </NavLink>
                        {/*<NotificationsDropdown/>*/}
                        {/*<CommentsDropdown/>*/}
                    </div>
                </div>

            </nav>
        </ErrorBoundaryContainer>
    )
}

export default TopSection;
