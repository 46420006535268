//View degree content in resource edit section
import React from 'react';


function ResourceDegreeContentView(props) {
    return(
        <div className="col-md-8 form-group" onClick={this.handleClickOnScene}>
            <a-scene className="aframe-scene" id="aframe-scene" debug={true} position="0 0 0" rotation="0 0 0" embedded>
                <a-entity className="collidable" geometry="primitive: sphere" position="0 0 0">
                    <span className="hidden" id="clikable-el-for-play-video"/>
                    {props.degreeContent}
                    <a-entity id="camera-rig" position="0 0 0">
                        <a-camera id="a-camera" position="0 0 0"  cursor="rayOrigin: mouse;fuseTimeout: 0"/>
                    </a-entity>
                    <a-light type="ambient" color="#fff"/>
                </a-entity>
            </a-scene>
        </div>
    )
}

export default ResourceDegreeContentView;