import React, {Fragment} from 'react';
import DeleteElementsButton from "../element/DeleteElementsButton";
import CreateElementButton from "../element/CreateElementButton";

function ARButtons(props) {
    return (
      <Fragment>
        <DeleteElementsButton {...props} />
        <CreateElementButton {...props} />
      </Fragment>
    );
}

export default ARButtons;