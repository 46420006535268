import React from "react";
import InputWithButtons from "../../../../components/InputWithButtons";
import {Trans} from 'react-i18next';

function Availability(props) {
    const onChange = (e) => {
        let val = e.target.value.replace(/\D/g, '');
        props.formik.setFieldValue('availability',Number(val))

    }
    const onBlurSave = (error,value,name) => {
        if(!error && value){
            let res = Number(props.formik.values.availability) !== 0 ? Number(props.formik.values.availability) : null;
            props.handleFieldChange('availability',res)
        }
    }

    let availability_invalid_class = props.formik.errors.last_name && props.formik.touched.last_name ? 'invalid' : '';
    return(
        <div className="form-group">
            <div className="contractor-profile__title">
                <Trans>{props.title || 'Availability (h/week)'}</Trans></div>
            <InputWithButtons handleSave={() => onBlurSave(props.formik.errors.availability,props.formik.values.availability,"availability")}
                              handleDiscard={() => props.formik.resetForm()}>
                <input
                    disabled={false}
                    // size={props.formik.values.availability.length > 0 ? props.formik.values.availability.length +1 : props.t("Last Name").length +1}
                    id="availability"
                    name="availability"
                    placeholder={props.t("Enter availability")}
                    type="number"
                    value={props.formik.values.availability}
                    onChange={onChange}
                    // onBlur={(e) => {props.formik.handleBlur(e); onBlurSave(props.formik.errors.name,props.formik.values.name,"name")}}
                    className={`input-primary ${availability_invalid_class}`}/>
                {props.formik.errors.availability && props.formik.touched.availability && (
                    <div className="input-feedback">{props.formik.errors.availability}</div>
                )}
            </InputWithButtons>
        </div>
    )
}

export default Availability;
