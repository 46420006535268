import React from 'react';
import {Field} from "formik";
import FileUploader from "../../FileUploader";
import ReactSVG from "react-svg";
import UploadIcon from "../../../images/upload-icon.svg";
import TextEditor from "../../TextEditor";

function EditTextResource(props) {
    let {editMode, setFieldValue, errors, touched, handleBlur, values, t, handleChange, name_invalid_class,
        toggleEditMode, resetFile, updateFile,resource_item,onBlurSave} = props;
    return(
        <div className="row mb-xl-5 mb-4">
            <div className="col">
                <div className="form-group">
                    <input
                        id="name"
                        placeholder={t(`Enter resource name`)}
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={(e) => {handleBlur(e); onBlurSave(errors.name,values.name,"name")}}
                        className={`form-control input-primary d-block ${name_invalid_class}`}/>
                    {errors.name && touched.name && (
                        <div className="input-feedback">{errors.name}</div>
                    )}
                </div>
                {!editMode && <Field
                    language={values.language}
                    values={values}
                    text_content={values.text_content}
                    name="text_content"
                    component={TextEditor}
                    setFieldValue={setFieldValue}
                    errorMessage={errors["text_content"] ? errors["text_content"] : undefined}
                    touched={touched["text_content"]}
                    onBlur={(e) => {handleBlur(e); onBlurSave(errors.text_content,values.text_content,"text_content")}}
                    keyProp={'editor' + resource_item.id}
                />}
                <div className="edit-media-wrapper">
                    {editMode && <Field
                        name="file_text"
                        component={FileUploader}
                        setFieldValue={setFieldValue}
                        errorMessage={errors["file_text"] ? errors["file_text"] : undefined}
                        touched={touched["file_text"]}
                        onBlur={handleBlur}
                    />}
                    {!editMode && <button type="button" onClick={toggleEditMode}
                                          className="btn edit-media-wrapper__btn mt-3">
                        {<ReactSVG src={UploadIcon} className="edit-media-wrapper__btn-icon mr-2"/>}
                        Reupload file
                    </button>}
                    {editMode && <button type="button" onClick={() => resetFile(setFieldValue)}
                                         className="btn btn-outline mr-3">Reset</button>}
                    {editMode && values.file_text && !errors.file_text && <button type="button" className="btn btn-primary"
                                         onClick={() => updateFile(values,errors)}>Update</button>}
                </div>
            </div>
        </div>
    )
}

export default EditTextResource;