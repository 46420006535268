import React, {useEffect, useState} from "react";
import TagsInput from "./TagsInput";

function ElementTags(props) {
    let {element_item,isSubmitting,deleteTagsFromElement,addTagsToElement} = props;

    const [tagsState,setTagsState] = useState(0);

    useEffect(() => {
        setTagsState(props.tags)
    },[props.tags]);

    const handleTagsChange = (tags,changed) => {
        let newTagsLength = tags.length,
            oldTagsLength = tagsState.length,
            isDeleteChange = newTagsLength < oldTagsLength;
        setTagsState(tags)
        if(isDeleteChange){
            deleteTagsFromElement(element_item.id,changed)
        }else {
            addTagsToElement(element_item.id,tags)
        }
    }
    return(
        <TagsInput tags={tagsState || []} handleChange={(tags,changed) => handleTagsChange(tags,changed,props.formik)} disabled={isSubmitting || element_item.active} classes={'mb-0'}/>
    )
}

export default ElementTags
