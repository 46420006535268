import React, {useEffect, useState} from "react";
import EmptyPreview from "../element/ElementView/EmptyPreview";
import _ from "lodash";
import CheckIcon from '../../images/big_check.svg';
import ReactSVG from "react-svg";
import {refreshUnity2} from "./UnityFunctions";
import {notify} from "../../common";

function ElementItemInExperience(props){
    const [selected,setSelected] = useState(false);
    const [order,setOrder] = useState(undefined);
    const [elementInfo,setElementInfo] = useState(props.item_info);

    useEffect(() => {
        let attached_element = _.find(props.experience_item.elements, {id:props.item_info.id});
        let element = _.find(props.elements_for_experience, {id:props.item_info.id});
        if(!!attached_element){
            setElementInfo(element)
            setOrder(attached_element.frame_id)
            setSelected(!!attached_element)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        let selected_element = _.find(props.selectedItems, {element_id:props.item_info.id});
        setSelected(!!selected_element)
        if(!!selected_element) {
            setOrder(selected_element.frame_id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.item_info,props.countUpdates,props.experience_item.elements])

    let checkedClass = props.selected ? "checked" : '';
    let element_name = props.item_info.translations[props.main_content_language] ? props.item_info.translations[props.main_content_language].name : undefined;

    const renderPreview = () => {
        if(props.item_info && props.item_info.preview){
            return <img src={props.item_info.preview.url} alt="" className="element-item__preview"/>
        }else {
            return <EmptyPreview/>
        }
    }
    const handleClick = () => {
        if(props.selectedItems.length < 5){
            setSelected(!selected)
            props.onSelectElement(props.item_info.id,setOrder)
        }else {
            if(selected){
                setSelected(!selected)
                props.onSelectElement(props.item_info.id,setOrder)
            }else notify('Only 5 paintings are allowed.', 'warning')
        }
    }

    const onChangePreview = (e,id) => {
        let file = e.target.files[0];
        props.setElementImage(elementInfo.id, file).then((res) => {
            setElementInfo({
                ...props.item_info,
                preview: {...props.item_info.preview, url: res.url}})
            refreshUnity2(props.experience_item.id)
        })
    }

    const renderUploadButton = () => {
        let title = props.item_info && props.item_info.preview ? 'Edit' : 'Upload';
        return <>
            <label htmlFor={`upload-preview-button`+ props.item_info.id}
                   className="btn btn-primary element-item-mini__button">{title} VR image</label>
            <input
                type="file"
                id={`upload-preview-button`+ props.item_info.id}
                style={{ display: "none" }}
                onChange={onChangePreview}
            />
        </>
    }
    return(
        <div className="col-md-6 col-sm-6 col-12">
            <div className="element-item-mini__wrap">
                <div className={`element-item-mini ${checkedClass}`} onClick={handleClick}>
                    <div className={`element-item-mini__body`}>
                        {renderPreview()}
                        {element_name && <div className="element-item__name">{element_name}</div>}
                    </div>
                    {selected && <span className="item-order-number-wrap">
                        <span className="item-order-number">
                            {order && order}
                            {selected && <ReactSVG src={CheckIcon}/>}
                        </span>
                    </span>}
                </div>
                {renderUploadButton()}
            </div>
        </div>
    )
}

export default ElementItemInExperience;
