import React, {useState, useEffect} from 'react'
import {useDropzone} from 'react-dropzone'
import ReactSVG from "react-svg";
import UploadFileIcon from '../images/upload-file.svg'
import {getExtension, isImage, isZip} from "../common";
import videoPreview from "../images/audio-preview.png";
import {Trans} from "react-i18next";
import FormatIcon from "./FormatIcon";

function DropZone(props) {
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps,isDragActive,} = useDropzone({
        onDrop: acceptedFiles => {
            if(acceptedFiles.length > 1 || props.maxFiles > 1){
                setFiles(acceptedFiles.map(file => Object.assign(file, {
                    preview: !isZip(file) ? (isImage(file) ? URL.createObjectURL(file) : videoPreview) : undefined
                })));
                props.upload(acceptedFiles,setFiles)
            }else if(acceptedFiles.length === 1){
                setFiles(acceptedFiles.map(file => Object.assign(file, {
                    preview: !isZip(file) ? (isImage(file) ? URL.createObjectURL(file) : videoPreview) : undefined
                })));
                props.upload(acceptedFiles[0],setFiles)
            }
        },
        maxFiles: props.maxFiles ? props.maxFiles : 1
    });

    useEffect(() => () => {
        // Revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <div className={`${props.classes}`}>
            <div {...getRootProps()} className={`dropzone`}>
                <input {...getInputProps()} />
                {!props.field && (isDragActive ?
                    <div className="dropzone__title"><Trans>Drop the file here ...</Trans></div> :
                    <div className="dropzone__content">
                        <ReactSVG src={props.icon || UploadFileIcon} className="dropzone__icon"/>
                        <div className="dropzone__title"><Trans>{props.title}</Trans></div>
                        <div className="dropzone__subtitle">
                            <Trans i18nKey="dragndrop">Drag & drop or <span className='text-accent'>click to browse</span> your file</Trans>
                        </div>
                    </div>)
                }
                {props.field && ((props.field.length > 1 || props.maxFiles > 1) ?
                    <div className="d-flex">
                        {props.field.map((item) => {
                            return <FormatIcon format={item.type || getExtension(item)}
                                               supported_formats={props.supported_formats}
                                               className="mr-3"/>
                        })}
                    </div>
                    :
                    <FormatIcon format={props.field.type || getExtension(props.field)}
                            supported_formats={props.supported_formats}/>)}
            </div>
            {props.errorMessage && (
                <div className="input-feedback">{props.errorMessage}</div>
            )}
        </div>
    )
}

export default DropZone;
