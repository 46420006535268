import React from 'react';
import {Field} from "formik";
import FileUploader from "../../FileUploader";
import ReactSVG from "react-svg";
import UploadIcon from "../../../images/upload-icon.svg";
import RerecordIcon from "../../../images/rerecord-icon.svg";
import MediaRecorder from "../CreateResource/MediaRecorder";

function EditOtherKindOfResource(props) {
    let {editMode, setFieldValue, errors, touched, handleBlur, values, t, handleChange, name_invalid_class,
        toggleEditMode, resetFile, updateFile,onBlurSave,showFullSizeGallery,renderResourceMedia,setValues,type_id,resetPlayer,recordMode,
        toggleRecordMode,language} = props;
    let file = props.resource_item.files ? props.resource_item.files[language] : undefined,
        captureText = file ? 'Recapture' : 'Capture',
        uploadText = file ? 'Reupload' : 'Upload'
    return(
        <div className="row mb-xl-5 mb-4">
            <div className="col-xl-6 col-md-12">
                <div className="edit-media-wrapper">
                    {!editMode && !recordMode && <div className="fullsize-gallery-wrapper" onClick={() => showFullSizeGallery(true)}>
                        {renderResourceMedia()}
                    </div>}
                    {editMode && <Field
                        name="file"
                        component={FileUploader}
                        setFieldValue={setFieldValue}
                        errorMessage={errors["file"] ? errors["file"] : undefined}
                        touched={touched["file"]}
                        onBlur={handleBlur}
                    />}
                    {!editMode && !recordMode && <button type="button" onClick={toggleEditMode}
                                          className="btn edit-media-wrapper__btn mt-3">
                        {<ReactSVG src={UploadIcon} className="edit-media-wrapper__btn-icon mr-2"/>}
                        {uploadText} file
                    </button>}
                    {!editMode && !recordMode && <button type="button" onClick={toggleRecordMode}
                                          className="btn edit-media-wrapper__btn mt-3 ml-3">
                        {<ReactSVG src={RerecordIcon} className="edit-media-wrapper__btn-icon mr-2"/>}
                        {captureText}
                    </button>}
                    {<MediaRecorder setValues={setValues} media_type={type_id} values={values} resetPlayer={resetPlayer}
                                    hidden={!recordMode}/>}
                    {(editMode || recordMode) && <button type="button" onClick={() => resetFile(setFieldValue)}
                                         className="btn btn-outline mr-3 mt-3">Reset</button>}
                    {(editMode || recordMode) && values.file && !errors.file && <button type="button" className="btn btn-primary mt-3"
                                         onClick={() => updateFile(values,errors)}>Update</button>}
                </div>
            </div>

            <div className="col">
                <div className="form-group">
                    <input
                        id="name"
                        placeholder={t(`Enter resource name`)}
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={(e) => {handleBlur(e); onBlurSave(errors.name,values.name,"name")}}
                        className={`form-control input-primary d-block ${name_invalid_class}`}/>
                    {errors.name && touched.name && (
                        <div className="input-feedback">{errors.name}</div>
                    )}
                </div>
                {/*{!create_resources_mode && <TagsInput tags={this.state.tags || []} handleChange={this.handleTagsChange}/>}*/}
            </div>
        </div>
    )
}

export default EditOtherKindOfResource;