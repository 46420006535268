import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactSVG from "react-svg";
import FilterIcon from '../images/filter-icon.svg';
import {languages} from "../common";
import LanguageSelect from "./LanguageSelect";
import ShowPairedResourcesCheckbox from "./ShowPairedResourcesCheckbox";
import {Trans, withTranslation} from "react-i18next";
import * as _ from "lodash";

const ResourceFiltersDropdown = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [countFilter, setCountFilter] = useState(0);
    const [filters, setFilters] = useState([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {setter('language')},[props.language])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {setter('show_paired_resources')},[props.show_paired_resources,])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {setter('show_sub_linked_resources')},[props.show_sub_linked_resources,])

    function setter(name){
        let newFilters = filters;
        if(props[name]){
            if(!_.includes(filters, name)){
                newFilters.push(name)
            }
        }else newFilters = _.filter(filters, (filter) => filter !== name)
        setCountFilter(newFilters.length)
        setFilters(newFilters)
    }

    const toggle = () => setDropdownOpen(prevState => !prevState);

    function handleReset() {
        setCountFilter(0)
        setFilters([])
        props.handleResetFilters()
        toggle();
    }

    let activeFilterClass = (props.language || props.show_paired_resources) || props.show_sub_linked_resources ? 'filter-dropdown__btn_active' : '';
    return (
        <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle} className="filter-dropdown">
            <DropdownToggle className={`btn filter-dropdown__btn ${activeFilterClass} custom-tooltip custom-tooltip_bottom`}
                            data-tooltip={props.t("Filter")}>
                <ReactSVG src={FilterIcon}/>
                {countFilter > 0 && <span className="filter-dropdown__btn-count">{countFilter}</span>}
            </DropdownToggle>
            <DropdownMenu right positionFixed={true}>
                <DropdownItem header><Trans>Filters</Trans></DropdownItem>
                <LanguageSelect data={languages} disabled={false} value={props.language}
                                setFieldValue={props.handleLanguageChange}
                                name={'language'} hideHelp noDefaultValue/>
                <ShowPairedResourcesCheckbox handleInputChange={props.handleInputChange}
                                             name={'show_paired_resources'}
                                             checked={props.show_paired_resources}/>
                {props.sub_elements && props.sub_element && <ShowPairedResourcesCheckbox handleInputChange={props.handleInputChange}
                                             name={'show_sub_linked_resources'}
                                             checked={props.show_sub_linked_resources}
                                             title={'Show only sub linked resources'}/>}
                <button className="btn btn-primary btn-outline w-100" onClick={() => handleReset(props,toggle)}
                        type="button">
                    <Trans>Reset filters</Trans>
                </button>
            </DropdownMenu>
        </Dropdown>
    );
}

export default withTranslation('translations')(ResourceFiltersDropdown);
