import React, {useState} from "react";
import {userRoles} from "../../../../common";
import {withRouter} from "react-router-dom";
import RenderFile from "../../RenderFile";
import ReactSVG from "react-svg";
import CloseIcon from "../../../../images/close-icon.svg";

function PortfolioItem(props){
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;

    const [data,setData] = useState(props.item_info);
    const deleteFile = () => {
        setData(undefined)
        props.deleteContractorPortfolioItem(props.contractor_item.id,props.item_info.id)
    }
    if(!data) return null;

    return(
        <div className="col-xl-2 col-md-3 portfolio-item" key={props.item_info.id + 'portfolio-item'}>
            <RenderFile data={data} />
            {isContractor && <button type="button" className="btn btn-close avatar-delete"
                     onClick={deleteFile}>
                <ReactSVG src={CloseIcon}/>
            </button>}
        </div>
    )
}

export default withRouter(PortfolioItem);
