/* eslint-disable */
import React, { Component } from 'react';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import RecordRTC from 'recordrtc';
import 'videojs-record/dist/css/videojs.record.css';
import 'webrtc-adapter';
import Record from 'videojs-record/dist/videojs.record.js';
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
WaveSurfer.microphone = MicrophonePlugin;
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';


import {resourceTypes, mediaTypes} from "../../../common";
import {applyAudioWorkaround} from "./browserWorkaround";
import moment from "moment";
import * as _ from "lodash";

const videoOptions = {
    controls: true,
    width: 320,
    height: 240,
    plugins: {
      record: {
        maxLength: 60 * 10,
        debug: true,
        audio: true,
        video: { facingMode: "environment" },
        facingMode: "environment",
      },
    },
  },
  audioOptions = {
    controls: true,
    width: 600,
    height: 300,
    fluid: false,
    plugins: {
      wavesurfer: {
        backend: "WebAudio",
        waveColor: "#6C63FF",
        progressColor: "#6C63FF",
        debug: true,
        cursorWidth: 1,
        hideScrollbar: true,
        plugins: [
          // enable microphone plugin
          WaveSurfer.microphone.create({
            bufferSize: 4096,
            numberOfInputChannels: 1,
            numberOfOutputChannels: 1,
            constraints: {
              video: false,
              audio: true,
            },
          }),
        ],
      },
      record: {
        audio: true,
        video: false,
        image: false,
        maxLength: 60 * 10,
        debug: true,
      },
    },
  },
  photoOptions = {
    controls: true,
    width: 320,
    height: 240,
    fluid: false,
    controlBar: {
      volumePanel: false,
      fullscreenToggle: true,
    },
    plugins: {
      record: {
        image: { facingMode: "environment" },
        // image: true,
        debug: true,
        imageOutputType: "blob",
      },
    },
  };



class VideoRecorder extends Component {
  componentDidMount() {
    console.log("hello");
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, () => {
      // print version information at startup
      var version_info =
        "Using video.js " +
        videojs.VERSION +
        " with videojs-record " +
        videojs.getPluginVersion("record") +
        " and recordrtc " +
        RecordRTC.version;
      videojs.log(version_info);
    });

    // device is ready
    this.player.on("deviceReady", () => {
      console.log("device is ready!");
    });

    // user clicked the record button and started recording
    this.player.on("startRecord", () => {
      console.log("started recording!");
    });

    // user completed recording and stream is available
    this.player.on("finishRecord", () => {
      // recordedData is a blob object containing the recorded data that
      // can be downloaded by the user, stored on server etc.
      console.log("finished recording: ", this.player.recordedData);
    });

    // error handling
    this.player.on("error", (element, error) => {
      console.warn(error);
    });

    this.player.on("deviceError", () => {
      console.error("device error:", this.player.deviceErrorCode);
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }
  render() {
    return (
      <div data-vjs-player>
        <video
          id="myVideo"
          ref={(node) => (this.videoNode = node)}
          className="video-js vjs-default-skin"
          playsInline
        />
      </div>
    );
  }
}

export default VideoRecorder;
