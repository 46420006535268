import React from "react";
import {Trans} from 'react-i18next';
function DescriptionInput(props) {
    let title_invalid_class = props.formik.errors.description && props.formik.touched.description ? 'invalid' : '';
    return(
        <>
            <div className="sidebar__title sidebar__title_offset-free"><Trans>{props.title || 'Enter job description'}</Trans></div>
            <textarea
                id="description"
                rows={props.rows || "8"}
                name="description"
                placeholder={props.t(props.placeholder || `Enter job description`)}
                value={props.formik.values.description}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                className={`form-control input-primary d-block contractor-profile__description ${title_invalid_class} ${props.textareaClass}`}/>
            {props.formik.errors.description && props.formik.touched.description && (
                <div className="input-feedback">{props.formik.errors.description}</div>
            )}
        </>
    )
}

export default DescriptionInput;
