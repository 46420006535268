import React from 'react'
import CustomScrollBar from "../../CustomScrollBar";
import {Field, Formik} from "formik";
import {setResourceSchema} from "../../../store/validations";
import {languages} from "../../../common";
import {TabPane} from "reactstrap";
import TextEditor from "../../TextEditor";
import LanguageSelect from "../../LanguageSelect";
import FileUploader from "../../FileUploader";
import {Trans, withTranslation} from "react-i18next";
import * as _ from "lodash";

class CreateTextResourceTab extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            status: 0
        }
    }

    changeStatus = (status) => {
        this.setState({status})
    }

    handleResetForm = (resetForm) => {
        this.changeStatus(0)
        resetForm(this.props.initialValues)
    }

    selectResourceLanguage = (name,value) => {
        this.props.setResourceLanguage(_.find(languages,['id',value]).short_name)
    }

    render() {
        let {create_resources_mode,initialValues,validation_rules,handleSubmit,tabId,media_type,t} = this.props;
        let {status} = this.state;
        let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}}

        return(
            <TabPane tabId={tabId} className="tab-pane">
                <CustomScrollBar options={overflowOptions}>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                handleSubmit(values);
                                setSubmitting(false)
                            }, 500);
                        }}
                        validationSchema={setResourceSchema(create_resources_mode,true,validation_rules,false,false,media_type,false,t)}>
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                setValues,
                                resetForm
                            } = props;
                            let name_invalid_class = errors.name && touched.name ? 'invalid' : '';
                            return (
                                <form onSubmit={handleSubmit} name="create-resource-video">
                                    <div className="row">
                                        <div className="col">
                                            <LanguageSelect data={languages} disabled={false} value={values.language}
                                                            onBlur={languages} setFieldValue={(name,value) => this.props.selectResourceLanguage(name,value)}
                                                            name={'language'} isDark/>
                                            {<Field
                                                id="file-text"
                                                name="file_text"
                                                component={FileUploader}
                                                setFieldValue={setFieldValue}
                                                setValues={setValues}
                                                errorMessage={errors["file_text"] ? errors["file_text"] : undefined}
                                                touched={touched["file_text"]}
                                                onBlur={handleBlur}
                                                changeStatus={this.changeStatus}
                                                hidden={status === 2}
                                                status={status}
                                            />}
                                            {status === 0 && !values.text_content && <div className="note-text mb-3"><Trans>To use pre existing files as resources, please upload here...</Trans></div>}
                                            {status === 0 && !values.text_content && <div className="note-text-big mb-2"><Trans>Or create a resource from your device</Trans></div>}
                                            {<Field
                                                text_content={values.text_content}
                                                name="text_content"
                                                component={TextEditor}
                                                setFieldValue={setFieldValue}
                                                errorMessage={errors["text_content"] ? errors["text_content"] : undefined}
                                                touched={touched["text_content"]}
                                                onBlur={handleBlur}
                                                hidden={status === 1}
                                                changeStatus={this.changeStatus}
                                                values={values}
                                                language={values.language}
                                            />}
                                            <div className="form-group">
                                                <input
                                                    id="text-resource-name"
                                                    name="name"
                                                    placeholder={t(`Enter resource name`)}
                                                    type="text"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control input-primary d-block ${name_invalid_class}`}/>
                                                {errors.name && touched.name && (
                                                    <div className="input-feedback">{errors.name}</div>
                                                )}
                                            </div>
                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                <span className=""><Trans>Save</Trans></span>
                                            </button>
                                            {(values.file_text || values.text_content) && <button type="button" className="btn btn-outline ml-3" disabled={isSubmitting}
                                                                    onClick={() => this.handleResetForm(resetForm)}>
                                                <span className=""><Trans>Reset</Trans></span>
                                            </button>}

                                        </div>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </CustomScrollBar>
            </TabPane>
        )
    }
}

export default withTranslation('translations')(CreateTextResourceTab);