import React, { useEffect, useState} from 'react';
import { resourceTypes, sidebarTypes} from "../../../common";
import JobSectionHeader from "./JobSectionHeader";
import {useFormik} from "formik";
import {setJobSchema} from "../../../store/validations";
import FormWrapper from "../../../components/FormWrapper";
import TitleInput from "../TitleInput";
import DescriptionInput from "../DescriptionInput";
import CurrencyInput from "../CurrencyInput";
import DatePickerInput from "../DatePickerInput";
import Status from "../Status";
import ResourceType from "../ResourceType";
import JobLanguage from "../MultipleLanguageSelect";
import JobFiles from "./JobFiles";
import Button from "../../../components/buttons/Button";
import moment from 'moment';
import {Trans} from 'react-i18next';
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";

function PostJob (props) {
    let formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            resource_type_id: resourceTypes.image,
            required_languages: [],
            max_bid: 1,
            currency_code: 'CAD',
            due_at: moment().format("YYYY-MM-DD"),
            files: null,
        },
        enableReinitialize: true,
        validationSchema: setJobSchema(props.validation_rules,props.t),
        onSubmit: values => {
            handleSubmit(values)
        },
    });
    const [pending, setPending] = useState(false)
    useEffect(() => {
        props.setSidebar(sidebarTypes.none)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleSubmit = () => {
        setPending(true)
        let {title,description,resource_type_id,required_languages, max_bid,currency_code,due_at,files} = formik.values;
        let job_data = {
            title,
            description,
            resource_type_id,
            required_languages,
            max_bid,
            currency_code,
            due_at: moment(due_at).format("YYYY-MM-DD 00:00")
        }
        props.createNewJob(job_data).then((res) => {
            if(files){
                props.attachFilesToJob(res.id,files).then(() => {
                    setPending(false)
                    formik.resetForm()
                    //todo publish until redirect
                    props.history.push(`/marketplace`)
                })
            }else {
                setPending(false)
                formik.resetForm()
                props.history.push(`/marketplace`)
            }
        })
    }

    const onChangeCurrency = (e) => {
        let val = e.target.value.replace(/\D/g, '');
        formik.setFieldValue('max_bid',Number(val))
    }

    return(
        <ErrorBoundaryContainer>
            <FormWrapper formik={formik} formName={'edit-resource'}>
                <div className="marketplace-wrap">
                    <div className="post-job-section">
                        <JobSectionHeader history={props.history} t={props.t} title="Post a job"/>
                        <div className="row">
                            <div className="col-md-8">
                                <TitleInput formik={formik} t={props.t}/>
                                <DescriptionInput formik={formik} t={props.t} textareaClass="textarea-height"/>
                                <div className="row">
                                    {!process.env.REACT_APP_HIDE_JOB_BUDGET && <div className="col-md-6">
                                        <div className="sidebar__title sidebar__title_offset-free"><Trans>Enter a
                                            budget</Trans></div>
                                        <CurrencyInput formik={formik} t={props.t}
                                                       id="job-max_bid"
                                                       name="max_bid"
                                                       placeholder={props.t(`Enter job budget`)}
                                                       type="text"
                                                       value={formik.values.max_bid}
                                                       onChange={onChangeCurrency}
                                                       onBlur={formik.handleBlur}/>
                                    </div>}
                                    <div className="col-md-6">
                                        <div className="sidebar__title sidebar__title_offset-free">
                                            <Trans>Enter due date</Trans>
                                        </div>
                                        <DatePickerInput formik={formik} t={props.t}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="sidebar__title sidebar__title_offset-free"><Trans>Current status</Trans></div>
                                <Status isBig/>
                                <ResourceType formik={formik} t={props.t}/>
                                <JobLanguage formik={formik} {...props} name={'required_languages'}/>
                                <JobFiles formik={formik} t={props.t}/>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button className="btn btn-outline w-100 mr-3"><Trans>Reset</Trans></button>
                                    </div>
                                    <div className="col-md-6">
                                        <Button type="submit"
                                                className="btn btn-primary w-100"
                                                disabled={formik.isSubmitting}
                                                loading={pending || formik.isSubmitting}><Trans>Save</Trans></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        </ErrorBoundaryContainer>
    )
}

export default React.memo(PostJob);
