import * as api from "../api";
import {handleErrors} from "./errors";

const SET_JOBS = 'SET_JOBS';

const initialState = {
    comments: [],
};

export const getComments = (params) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetJobs(params)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setJobsAction(responseJson.data))
                    resolve(responseJson)
                })
        })
    }

export const attachFilesToComments = (comment_id,files) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchAttachFilesToComments(comment_id,files)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    // dispatch(setJobsAction(responseJson.data))
                    resolve(responseJson.data)
                })
        })
    }


const setJobsAction = (jobs) => ({
    type: SET_JOBS,
    jobs
});


const ACTION_HANDLERS = {
    [SET_JOBS]: (state, action) => ({
        ...state,
        jobs: [...state.jobs, ...action.jobs],
    }),
};


export default function comments(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
