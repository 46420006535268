import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactSVG from "react-svg";
import SearchIcon from "../images/search-icon.svg";
import SearchInput from "react-search-input";
import {Trans, withTranslation} from "react-i18next";

const ResourceFiltersDropdown = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    function handleReset() {
        props.searchUpdated('')
        toggle();
    }
    let activeSearchClass = props.searchTerm ? 'filter-dropdown__btn_active' : '';

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="filter-dropdown">
            <DropdownToggle className={`btn filter-dropdown__btn ${activeSearchClass} custom-tooltip custom-tooltip_bottom`} data-tooltip={props.t(`Search`)}>
                <ReactSVG className="svg-icon-wrap" src={SearchIcon} alt=""/>
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem header><Trans>Searching</Trans></DropdownItem>
                <SearchInput className={`form-control input-primary search-mini mb-3`}
                             onChange={props.searchUpdated} value={props.searchTerm} placeholder={props.t(`Search`)}/>
                <button className="btn btn-primary btn-outline w-100" onClick={() => handleReset(props,toggle)} type="button"><Trans>Reset search</Trans></button>
            </DropdownMenu>
        </Dropdown>
    );
}

export default withTranslation('translations')(ResourceFiltersDropdown);