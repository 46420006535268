import React from 'react';
import Status from "../Status";
import {getBidStatusModificator, getTimeline, userRoles} from "../../../common";
import ContractorInfo from "../contractor/ContractorInfo";
import Button from "../../../components/buttons/Button";
import CustomScrollBar from "../../../components/CustomScrollBar";
import {Link, withRouter} from "react-router-dom";
import {bidStatuses} from "./BidCard";
import {jobStatuses} from "../jobs/JobCard/JobCard";
import {Trans} from 'react-i18next';
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";

function BidDetails (props) {
    if(!props.data) return <div className="bids-section__inner-offset mb-3">Select bid to see details</div>;
    let {cover_letter,status_id,bid_amount,estimate_minutes,contractor,date_submitted,id} = props.data;
    let status = bidStatuses.find(status => status.id === status_id).name;
    let $completedStatus = jobStatuses.find(item => item.name === 'Completed').id;
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;

    const ApproveBid = () => {
        props.approveBid(props.item_info.id,id).then(() => {
            props.getJobItem(props.item_info.id)
        })
    }

    const DeclineBid = () => {
        props.declineBid(props.item_info.id,id).then(() => {
            props.getJobItem(props.item_info.id)
            props.getBidItem(props.bid_item.id)
        })
    }

    const ApproveJob = () => {
        props.updateJobStatus($completedStatus, props.item_info.id).then(() => {
            props.getJobItem(props.item_info.id)
        })
    }

    return(
        <ErrorBoundaryContainer>
            <CustomScrollBar className="bids-section__scroll">
                <div className="bids-section__inner-offset pb-4">
                    <div className="row">
                        <div className="col">
                            <div className="sidebar__title sidebar__title_offset-free"><Trans>Cover letter</Trans></div>
                        </div>
                        <div className="col d-flex justify-content-end">
                            <div className="bid-details__date-submitted">{date_submitted}</div>
                        </div>
                    </div>
                    <div className="bid-details__text flex-grow-1">{cover_letter}</div>

                    <div className="bid-details__group">
                        <div className="bid-details__group-item">
                            <div className="sidebar__title sidebar__title_offset-free"><Trans>Proposed timeline</Trans></div>
                            <div className="bid-details__text bid-details__text_bold">
                                {getTimeline(estimate_minutes)}
                            </div>
                        </div>
                        <div className="bid-details__group-item">
                            <div className="sidebar__title sidebar__title_offset-free"><Trans>Proposed budget</Trans></div>
                            <div className="bid-details__text bid-details__text_bold">${bid_amount}</div>
                        </div>
                        <div className="bid-details__group-item">
                            <div className="sidebar__title sidebar__title_offset-free"><Trans>Status</Trans></div>
                            <Status name={status} modificator={getBidStatusModificator(status_id)} isBig/>
                        </div>
                    </div>
                </div>
            </CustomScrollBar>
            {props.item_info && !isContractor && <div className="bids-section__inner-offset bid-details__footer mb-5">
                <div className="row">
                    <div className="col-6">
                        <Link to={`/user-${contractor.id}`}>
                            {!props.item_info.assigned_to && <ContractorInfo contractor={contractor} isSmall/>}
                        </Link>
                    </div>
                    {!props.item_info.accepted_bid_id &&
                    <div className="col-6 d-flex flex-row justify-content-between align-items-center">
                        <button type="button" className="btn btn-primary btn-decline w-100 mr-3" onClick={DeclineBid}>
                            <Trans>Decline</Trans>
                        </button>
                        <Button type="button" className="btn btn-primary btn-success w-100" onClick={ApproveBid}>
                            <Trans>Approve</Trans>
                        </Button>
                    </div>}
                    {props.item_info.accepted_bid_id && props.item_info.status_id !== $completedStatus &&
                    <div className="col-6 d-flex flex-row justify-content-end align-items-center">
                        {/*<Link className="btn btn-primary w-100 mr-3" to="/change-of-request-job-:3">*/}
                        {/*    Change of request*/}
                        {/*</Link>*/}
                        <Button type="button"
                                onClick={ApproveJob}
                                className="btn btn-primary btn-success w-100"><Trans>Approve job</Trans></Button>
                    </div>}
                </div>
            </div>}
        </ErrorBoundaryContainer>
    )
}

export default withRouter(BidDetails);
