import React from 'react';
import FormatIcon from "../../../components/FormatIcon";
import ReactSVG from "react-svg";
import AttachmentIcon from "../../../images/attachment-icon.svg";
import moment from 'moment';

function CommentItem (props) {
    if(!props.data || !props.user_info) return null;
    let {message,created_at,attachments,user} = props.data;
    let reverseClass = user.id === props.user_info.id ? 'comment-item_reverse' : '';
    return(
        <div className={`comment-item ${reverseClass}`}>
            <div className="comment-item__avatar">
                <img className="comment-item__avatar-image" src={user.avatar && user.avatar.url} alt=""/>
            </div>
            <div className="comment-item__box">
                <div className="comment-item__header">
                    <div className="comment-item__author-name">{user.full_name}</div>
                    <div className="comment-item__date-sent">{moment(created_at).fromNow()}</div>
                </div>
                <div className="comment-item__body">
                    {message}
                </div>
                <div className="comment-item__footer">
                    {attachments && attachments.length > 0 && <div className="comment-item__attachments-header">
                        <ReactSVG className="comment-item__footer-icon" src={AttachmentIcon}/>
                        <div className="comment-item__footer-title">Attached files</div>
                    </div>}
                    {attachments && attachments.map((item) => {
                        return <div className="comment-item__file">
                            <div className="comment-item__file-icon">
                                <FormatIcon format={'application/zip'}
                                            supported_formats={props.supported_formats}/>
                            </div>
                            <div className="comment-item__file-details">
                                <div className="comment-item__file-name">{item.filename}</div>
                                <a href={item.url} target="_blank" rel="noopener noreferrer" className="comment-item__file-download" download>
                                    Download
                                </a>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default CommentItem;
