import React, {useEffect} from 'react';
import {sidebarTypes} from "../../../common";
import {withRouter} from "react-router-dom";
import BidsList from "./BidsList";
// import BidEditSidebar from "./BidEditSidebar";
import TopSectionContractor from "../top-section/TopSectionContractor";
import BidViewSidebar from "./BidViewSidebar";
import {Trans} from 'react-i18next';
function MyBids (props) {
    useEffect(() => {
        props.setSidebar(sidebarTypes.none)
        return () => {
            props.clearBids()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    let classToggle = props.sidebar_toggled || props.sidebar_type === sidebarTypes.none ? '' : 'sidebar-wrap_toggled'

    return(
        <div className={`sidebar-wrap sidebar-wrap_wide ${classToggle}`}>
            <BidViewSidebar {...props} withoutFooter/>
            <div className="marketplace-wrap">
                <TopSectionContractor {...props}/>
                <div className="bids-section">
                    <div className="bids-section__inner-offset">
                        <div className="bids-section__header-inner">
                            <ul className="nav nav-pills btn-group btn-group-toggle mb-4" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link btn btn-group-toggle__item active" id="submitted-tab"
                                       data-toggle="tab" href="#submitted"
                                       role="tab" aria-controls="submitted" aria-selected="true"><Trans>Submitted</Trans></a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link btn btn-group-toggle__item" id="all-tab"
                                       data-toggle="tab" href="#all"
                                       role="tab" aria-controls="all" aria-selected="false"><Trans>All</Trans></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content d-flex flex-column flex-grow-1">
                        <div className="tab-pane active flex-grow-1" id="submitted" role="tabpanel"
                             aria-labelledby="submitted-tab">
                            <BidsList {...props} onlySubmitted/>

                        </div>
                        <div className="tab-pane flex-grow-1" id="all" role="tabpanel"
                             aria-labelledby="all-tab">
                            <BidsList {...props}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MyBids);
