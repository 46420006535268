/* eslint-disable */
import React, { Component } from "react";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import RecordRTC from "recordrtc";
import "videojs-record/dist/css/videojs.record.css";
// import 'webrtc-adapter';
import Record from "videojs-record/dist/videojs.record.js";
import WaveSurfer from "wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
WaveSurfer.microphone = MicrophonePlugin;
import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";
import Wavesurfer from "videojs-wavesurfer/dist/videojs.wavesurfer.js";

import { resourceTypes, mediaTypes } from "../../../common";
import { applyAudioWorkaround } from "./browserWorkaround";
import moment from "moment";
import * as _ from "lodash";

const videoOptions = {
    controls: true,
    width: 320,
    height: 240,
    plugins: {
      record: {
        maxLength: 60 * 10,
        debug: true,
        audio: true,
        video: { facingMode: "environment" },
        facingMode: "environment",
      },
    },
  },
  audioOptions = {
    controls: true,
    width: 600,
    height: 300,
    fluid: false,
    plugins: {
      wavesurfer: {
        backend: "WebAudio",
        waveColor: "#6C63FF",
        progressColor: "#6C63FF",
        debug: true,
        cursorWidth: 1,
        hideScrollbar: true,
        plugins: [
          // enable microphone plugin
          WaveSurfer.microphone.create({
            bufferSize: 4096,
            numberOfInputChannels: 1,
            numberOfOutputChannels: 1,
            constraints: {
              video: false,
              audio: true,
            },
          }),
        ],
      },
      record: {
        audio: true,
        video: false,
        image: false,
        maxLength: 60 * 10,
        debug: true,
      },
    },
  },
  photoOptions = {
    controls: true,
    width: 240,
    height: 240,
    fluid: true,
    //image:true
    // aspectRatio: "1:1",
    // responsive:true,
    // enableSmoothSeeking: true,
    preferFullWindow: true,
    controlBar: {
      volumePanel: false,
      fullscreenToggle: true,
    },
    plugins: {
      record: {
        image: {
          facingMode: "environment",
          width: { min: 240, ideal: 240, max: 1280 },
          height: { min: 240, ideal: 240, max: 920 },
        },
        //image: true,
        debug: true,
        imageOutputQuality: 5,
        imageOutputType: "blob",
        // // dimensions of captured video frames
        frameWidth: 240,
        frameHeight: 240,
      },
    },
  };

class MediaRecorder extends Component {
  componentDidMount() {
    // instantiate Video.js
    if (
      this.props.media_type === mediaTypes.audio ||
      this.props.media_type === resourceTypes.audio
    ) {
      applyAudioWorkaround();
    }
    this.player = videojs(this.videoNode, this.getOptions(), () => {
      // print version information at startup
      var version_info =
        "Using video.js " +
        videojs.VERSION +
        " with videojs-record " +
        videojs.getPluginVersion("record") +
        " and recordrtc " +
        RecordRTC.version;
      videojs.log(version_info);
    });

    this.props.resetPlayer(this.player);

    let player = this.player;
    // device is ready
    player.on("deviceReady", () => {
      //player.record().start();
      console.log("device is ready!");
    });

    // user clicked the record button and started recording
    player.on("startRecord", () => {
      console.log("started recording!");
    });

    //user completed recording and stream is available
    this.player.on("finishRecord", () => {
      console.log("finished recording: ", this.player.recordedData);
      this.getRecordedData(this.player.recordedData, this.props.media_type);
      //player.record().stopStream();
      //if(this.props.changeStatus) this.props.changeStatus(2)
      // let el = document.querySelector('.vjs-icon-replay:not(.vjs-icon-photo-camera)');
      // if(el){
      //     el.addEventListener("click", this.clickButton);
      // }
    });

    // error handling
    this.player.on("error", (element, error) => {
      console.error(error);
    });

    this.player.on("deviceError", () => {
      console.error("device error:", this.player.deviceErrorCode);
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
    let el = document.querySelector(
      ".vjs-icon-replay:not(.vjs-icon-photo-camera)"
    );
    if (el) {
      el.removeEventListener("click", this.clickButton);
    }
  }

  clickButton = (e) => {
    let hasClass = _.includes(e.target.className, "vjs-icon-replay");
    if (!hasClass && this.player) {
      this.player.record().getDevice();
      let el = document.querySelector(
        ".vjs-icon-replay:not(.vjs-icon-photo-camera)"
      );
      if (el) {
        el.removeEventListener("click", this.clickButton);
      }
    }
  };

  getRecordedData = (recorded_data, media_type) => {
    if (
      media_type === mediaTypes.video ||
      media_type === mediaTypes.audio ||
      media_type === resourceTypes.video ||
      media_type === resourceTypes.audio
    ) {
      let blobToFile = new File([recorded_data], `${recorded_data.name}`, {
        type: recorded_data.type,
      });
      this.props.setValues({
        ...this.props.values,
        file: blobToFile,
        name: recorded_data.name,
      });
    } else if (
      media_type === mediaTypes.image ||
      media_type === resourceTypes.image
    ) {
      let blobToFile = new File(
        [recorded_data],
        `${moment().format("X")}.png`,
        { type: recorded_data.type }
      );
      this.props.setValues({
        ...this.props.values,
        file: blobToFile,
        name: `${moment().format("X")}.png`,
      });
      this.props.setValues({
        ...this.props.values,
        files: [...this.props.values.files, blobToFile],
        name: `${moment().format("X")}.png`,
      });
    }
  };

  getOptions = () => {
    if (
      this.props.media_type === mediaTypes.image ||
      this.props.media_type === resourceTypes.image
    )
      return photoOptions;
    else if (
      this.props.media_type === mediaTypes.video ||
      this.props.media_type === resourceTypes.video
    )
      return videoOptions;
    else if (
      this.props.media_type === mediaTypes.audio ||
      this.props.media_type === resourceTypes.audio
    )
      return audioOptions;
  };
  render() {
    let { media_type, hidden } = this.props;
    let mediaContent;
    if (
      media_type === mediaTypes.image ||
      media_type === mediaTypes.video ||
      media_type === resourceTypes.image ||
      media_type === resourceTypes.video
    ) {
      mediaContent = (
        <video
          id="myVideo"
          ref={(node) => (this.videoNode = node)}
          className="video-js vjs-default-skin video-js-custom"
          //className="video-js vjs-1-1"
          playsInline
        />
      );
    } else if (
      media_type === mediaTypes.audio ||
      media_type === resourceTypes.audio
    ) {
      mediaContent = (
        <audio
          id="myAudio"
          style={{ width: "100%" }}
          ref={(node) => (this.videoNode = node)}
          className="video-js vjs-default-skin video-js-custom"
        />
      );
    }
    return (
      <div style={{ width: "100%", display: hidden ? "none" : "block" }}>
        <div
          data-vjs-player
          style={{ width: "100%", display: hidden ? "none" : "block" }}
          className={`video-js vjs-default-skin video-js-custom myVideo-dimensions vjs-record vjs-controls-enabled 
                 vjs-workinghover vjs-v7 vjs-live vjs-playing vjs-has-started vjs-user-active`}
        >
          {mediaContent}
        </div>
      </div>
    );
  }
}

export default MediaRecorder;
