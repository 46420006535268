import React, {useRef,useCallback} from 'react';
import { Trans } from 'react-i18next';
function InfiniteScrollContainer(props) {
    let {children,loadMore,hasMoreItems,classes,hasFilter} = props;

    const observer = useRef()
    const bottomRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreItems) {
                loadMore()
                if(hasFilter){
                    setTimeout(() => {
                        loadMore()
                    },1000)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [hasFilter,hasMoreItems, loadMore]);


    return (
      <div>
        <div className={classes}>{children}</div>
        {hasMoreItems && (
          <div className="load-more__container" ref={bottomRef}>
            <span>Loading</span>
            <div className="load-more-anim">
              <div className="circle" />
              <div className="circle" />
              <div className="circle" />
            </div>
          </div>
        )}
        {!hasMoreItems && (
          <div className="load-more__container">
            <Trans>No more data</Trans>
          </div>
        )}
      </div>
    );
}

export default InfiniteScrollContainer;