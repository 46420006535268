import React, {useEffect, useState} from 'react';
import ReactSVG from "react-svg";
import Arrow from '../../images/arrow.svg';

function onClick(setToggle,toggle,props) {
    props.setOpenedDropDown(props.title)
    setToggle(!toggle)
}

function DropdowMenu(props) {
    const [toggle, setToggle] = useState(false);
    useEffect(() => {
        if(props.openedDropDown && props.openedDropDown !== props.title){
            setToggle(false)
        }
    },[props.openedDropDown,props.title])
    let classToggle = toggle ? 'open' : '';
    let {title} = props;

    return(
        <div className={`sidebar-dropdown ${classToggle}`}>
            <button type="button" className="btn sidebar-dropdown__toggle"
                    onClick={() => onClick(setToggle,toggle,props)}>
                {title}
                <ReactSVG className="sidebar-dropdown__arrow" src={Arrow}/>
            </button>
            <div className={`sidebar-dropdown__menu ${classToggle}`}>
                {props.children}
            </div>
        </div>
    )
}

export default DropdowMenu;