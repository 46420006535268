import React from 'react';
import {bindActionCreators} from "redux";
import {setError} from "../store/errors";
import {connect} from "react-redux";

class ErrorBoundaryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined,
            errorInfo: undefined,

        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.props.setError(error, errorInfo);
    }

    render() {

        if (this.state.hasError) {
            return <div role="alert" className="alert-box m-5">
                             <p className="alert-text">Something went wrong</p>
                             <button className="btn btn-primary" onClick={() => window.location.reload()}>Try again</button>
                         </div>
        }

        return this.props.children;
    }
}

const mapStateToProps = state => ({
    error: state.errors.error,
    error_info: state.errors.error_info,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setError,
},dispatch);

export default (connect(mapStateToProps, mapDispatchToProps) (ErrorBoundaryContainer));
