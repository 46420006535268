//List of identifier data
import React from 'react'
import CustomScrollBar from "../CustomScrollBar";
import IdentifierItem from "./IdentifierItem";


function IdentifierDataList(props) {
    let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
    if(!props.element_item || !props.identifiers) return null;

    return (
        <CustomScrollBar options={overflowOptions}>
            <div className="identifiers">
                <div className="row">
                    {props.identifiers.map((item, i) => {
                        return <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6" key={i}>
                            <IdentifierItem item={item} key={i+ 'training-item'} percent={100} element_id={props.element_item.id}/>
                        </div>
                    })}
                </div>
            </div>
        </CustomScrollBar>
    );
}


export default IdentifierDataList;


