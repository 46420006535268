import React from "react";
import {Trans} from 'react-i18next';

function Status(props) {
    let bigClass = props.isBig ? 'status-label_big' : '';
    let modificatorClass = props.modificator ? props.modificator : 'status-label_draft';

    return(
        <div className={`status-label ${bigClass} ${modificatorClass}`}><Trans>{props.name || 'Draft'}</Trans></div>
    )
}

export default Status;
