//All reducers for validations
import * as api from "../api";
import {resourceTypes, checkVideoExtension, isImage, isVideo, elementTypes, isGlb, isGltf} from "../common";
import * as Yup from 'yup';
import {handleErrors} from "./errors";


const SET_VALIDATION_RULES = 'SET_VALIDATION_RULES';


const initialState = {
    validation_rules: {}
};

//error messages
const TOO_SHORT_ERROR = 'Too Short!',
    TOO_LONG_ERROR = 'Too Long!',
    REQUIRED_ERROR = 'Required',
    UNSUPPORTED_FORMAT_ERROR = 'Unsupported Format',
    PASSWORD_CONFIRM_REQUIRED_ERROR = 'Password confirm is required',
    PASSWORDS_DONT_MATCH_ERROR = 'Passwords don\'t match',
    TEXT_ANSWER_REQUIRED_ERROR = 'Text of answer is required',
    FILE_TOO_LARGE_ERROR = 'File too large';


//validate creating new scene
export function setSceneSchema(createMode,rules) {
    const {
        SCENE_NAME_MAX_SIZE,
        SCENE_NAME_MIN_SIZE,
        SCENE_DESCRIPTION_MAX_SIZE,
        SCENE_DESCRIPTION_MIN_SIZE
    } = rules;
    return Yup.object().shape({
        name: Yup.string()
            .min(SCENE_NAME_MIN_SIZE, TOO_SHORT_ERROR)
            .max(SCENE_NAME_MAX_SIZE, TOO_LONG_ERROR)
            .required(REQUIRED_ERROR),
        description: Yup.string()
            .min(SCENE_DESCRIPTION_MIN_SIZE, TOO_SHORT_ERROR)
            .max(SCENE_DESCRIPTION_MAX_SIZE, TOO_LONG_ERROR)
            .required(REQUIRED_ERROR),
        resource_id: createMode && Yup.number()
            .required(REQUIRED_ERROR)
    })
}

//validate creating new resource
export function setResourceSchema(createMode,isTextEditorMode,rules,isVR,isAnimation,media_type,editMode,t) {
    //t is a function from i18next for translation a string
    const {
        RESOURCE_NAME_MAX_LENGTH,
        RESOURCE_NAME_MIN_LENGTH,
        VIDEO_UPLOAD_MAX_SIZE_BYTES,
        AUDIO_UPLOAD_MAX_SIZE_BYTES,
        IMAGE_UPLOAD_MAX_SIZE_BYTES,
        RESOURCE_SUPPORTED_FORMATS,
        RESOURCE_SUPPORTED_FORMATS_VR,
        ANIMATION_RESOURCE_FORMATS,
        MULTIMEDIA_RESOURCE_FORMATS,
        ANIMATION_UPLOAD_MAX_SIZE_BYTES,
        RESOURCE_IMAGE_SUPPORTED_FORMATS,
        RESOURCE_VIDEO_SUPPORTED_FORMATS,
        RESOURCE_AUDIO_SUPPORTED_FORMATS,
        RESOURCE_TEXT_SUPPORTED_FORMATS,
        RESOURCE_URL_SUPPORTED_FORMATS,
        RESOURCE_MODEL_SUPPORTED_FORMATS,
    } = rules;

    let supported_formats = isVR ? RESOURCE_SUPPORTED_FORMATS_VR : RESOURCE_SUPPORTED_FORMATS;
    if(media_type) {
        switch (media_type) {
            case resourceTypes.image:
            case 'image': supported_formats = RESOURCE_IMAGE_SUPPORTED_FORMATS; break;
            case resourceTypes.video:
            case 'video': supported_formats = RESOURCE_VIDEO_SUPPORTED_FORMATS; break;
            case resourceTypes.audio:
            case 'audio': supported_formats = RESOURCE_AUDIO_SUPPORTED_FORMATS; break;
            case resourceTypes.text:
            case 'text': supported_formats = RESOURCE_TEXT_SUPPORTED_FORMATS; break;
            case resourceTypes.url:
            case 'url': supported_formats = RESOURCE_URL_SUPPORTED_FORMATS; break;
            case resourceTypes.zip:
            case 'zip': supported_formats = RESOURCE_MODEL_SUPPORTED_FORMATS; break;
            case resourceTypes.model:
            case 'model': supported_formats = RESOURCE_MODEL_SUPPORTED_FORMATS; break;
            case resourceTypes.animations:
            case 'animation': supported_formats = RESOURCE_MODEL_SUPPORTED_FORMATS; break;
            default : supported_formats = RESOURCE_SUPPORTED_FORMATS_VR;
        }
    }
    function checkType(type){
        if(isImage(type)){
            return IMAGE_UPLOAD_MAX_SIZE_BYTES
        }else if(isVideo(type)){
            return VIDEO_UPLOAD_MAX_SIZE_BYTES
        }else return AUDIO_UPLOAD_MAX_SIZE_BYTES
    }
    return Yup.object().shape({
        name: Yup.string()
            .min(RESOURCE_NAME_MIN_LENGTH, t(TOO_SHORT_ERROR))
            .max(RESOURCE_NAME_MAX_LENGTH, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        file: (createMode || editMode) && !isAnimation && !isTextEditorMode && Yup
            .mixed()
            .required(t(REQUIRED_ERROR))
            .test(
                "fileSize",
                t(FILE_TOO_LARGE_ERROR),
                value => value && value.size <= checkType(value)
            )
            .test(
                "fileFormat",
                t(UNSUPPORTED_FORMAT_ERROR),
                value => value && ( (value.type && supported_formats.includes(value.type)) || checkVideoExtension(value) || isGlb(value) || isGltf(value))
            ),
        file_text: (createMode || editMode) && !isAnimation && isTextEditorMode && Yup
            .mixed()
            .test("requiredFileText", t(REQUIRED_ERROR),function (value) {
                const { text_content } = this.parent;
                return !!text_content || !!value;
            })
            .test(
                "fileSize",
                t(FILE_TOO_LARGE_ERROR),
                function (value) {
                    const { text_content } = this.parent;
                    return !!text_content || (value && value.size <= checkType(value))
                }
            )
            .test(
                "fileFormat",
                t(UNSUPPORTED_FORMAT_ERROR),
                function (value) {
                    const { text_content } = this.parent;
                    return (text_content || (value && value.type && supported_formats.includes(value.type)))
                }
            ),
        gif: (createMode || editMode) && isAnimation && Yup
            .mixed()
            .required(t(REQUIRED_ERROR))
            .test(
                "fileSize",
                t(FILE_TOO_LARGE_ERROR),
                value => value && value.size <= ANIMATION_UPLOAD_MAX_SIZE_BYTES
            )
            .test(
                "fileFormat",
                t(UNSUPPORTED_FORMAT_ERROR),
                value => value && value.type && ANIMATION_RESOURCE_FORMATS.includes(value.type),
            ),
        multimedia: (createMode || editMode) && isAnimation && Yup
            .mixed()
            .required(t(REQUIRED_ERROR))
            .test(
                "fileSize",
                t(FILE_TOO_LARGE_ERROR),
                value => value && value.size <= AUDIO_UPLOAD_MAX_SIZE_BYTES
            )
            .test(
                "fileFormat",
                t(UNSUPPORTED_FORMAT_ERROR),
                value => value && value.type && MULTIMEDIA_RESOURCE_FORMATS.includes(value.type)
            ),
        text_content: createMode && isTextEditorMode && Yup.string()
            .test("required", t(REQUIRED_ERROR),function (value) {
                const { file_text } = this.parent;
                return !!file_text || !!value;
            })
    })
}

//validate login form
export function setLoginSchema(rules,isEmail,t) {
    const{
        AUTH_EMAIL_MAX_LENGTH,
        AUTH_PASSWORD_MAX_LENGTH,
        AUTH_PASSWORD_MIN_LENGTH,
        AUTH_USERNAME_MAX_LENGTH,
        AUTH_USERNAME_MIN_LENGTH
    } = rules;
    let emailValidation = Yup.string().email().max(AUTH_EMAIL_MAX_LENGTH, t(TOO_LONG_ERROR)).required(t(REQUIRED_ERROR)),
        usernameValidation = Yup.string().min(AUTH_USERNAME_MIN_LENGTH, t(TOO_SHORT_ERROR)).max(AUTH_USERNAME_MAX_LENGTH, t(TOO_LONG_ERROR)).required(t(REQUIRED_ERROR));
    return Yup.object().shape({
        email: isEmail ? emailValidation : usernameValidation,
        password: Yup.string()
            .min(AUTH_PASSWORD_MIN_LENGTH, t(TOO_SHORT_ERROR))
            .max(AUTH_PASSWORD_MAX_LENGTH, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR))
    })
}

//validate login form
export function setDeleteAccountSchema(rules, isEmail, t) {
         const {
           AUTH_EMAIL_MAX_LENGTH,
           AUTH_OTP_MAX_LENGTH,
           AUTH_OTP_MIN_LENGTH,
         } = rules;
         return Yup.object().shape({
           email: Yup.string()
             .email()
             .max(AUTH_EMAIL_MAX_LENGTH, t(TOO_LONG_ERROR))
             .required(t(REQUIRED_ERROR)),
           otp: Yup.string()
             .min(AUTH_OTP_MAX_LENGTH, t(TOO_SHORT_ERROR))
             .max(AUTH_OTP_MIN_LENGTH, t(TOO_LONG_ERROR))
             .required(t(REQUIRED_ERROR)),
         });
       }

//validate reset password form
export function setResetPasswordSchema(rules,t) {
    const {
        AUTH_EMAIL_MAX_LENGTH,
    } = rules;
    return Yup.object().shape({
        email: Yup.string()
            .email('Please enter a valid email')
            .max(AUTH_EMAIL_MAX_LENGTH, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR))
    })
}

export function setUpdatePasswordSchema(rules,t) {
    const {
        AUTH_EMAIL_MAX_LENGTH,
        AUTH_PASSWORD_MAX_LENGTH,
        AUTH_PASSWORD_MIN_LENGTH
    } = rules;
    return Yup.object().shape({
        email: Yup.string()
            .email('Please enter a valid email')
            .max(AUTH_EMAIL_MAX_LENGTH, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        password: Yup.string()
            .min(AUTH_PASSWORD_MIN_LENGTH, t(TOO_SHORT_ERROR))
            .max(AUTH_PASSWORD_MAX_LENGTH, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null],PASSWORDS_DONT_MATCH_ERROR)
            .required(t(PASSWORD_CONFIRM_REQUIRED_ERROR))
    })
}

//validate register form
export function setSignUpSchema(rules,t) {
    const {
        AUTH_USERNAME_MAX_LENGTH,
        AUTH_USERNAME_MIN_LENGTH,
        AUTH_EMAIL_MAX_LENGTH,
        AUTH_FIRST_NAME_MAX_LENGTH,
        AUTH_LAST_NAME_MAX_LENGTH,
        AUTH_PASSWORD_MAX_LENGTH,
        AUTH_PASSWORD_MIN_LENGTH
    } = rules;
    return Yup.object().shape({
      first_name: Yup.string().max(
        AUTH_FIRST_NAME_MAX_LENGTH,
        t(TOO_LONG_ERROR)
      ),
      last_name: Yup.string().max(AUTH_LAST_NAME_MAX_LENGTH, t(TOO_LONG_ERROR)),
      business_name: Yup.string()
        .min(AUTH_USERNAME_MIN_LENGTH, t(TOO_SHORT_ERROR))
        .max(AUTH_USERNAME_MAX_LENGTH, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      location: Yup.object().shape({
        longitude: Yup.number().required(),
        latitude: Yup.number().required(),
      }),
      email: Yup.string()
        .email()
        .max(AUTH_EMAIL_MAX_LENGTH, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      password: Yup.string()
        .min(AUTH_PASSWORD_MIN_LENGTH, t(TOO_SHORT_ERROR))
        .max(AUTH_PASSWORD_MAX_LENGTH, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], t(PASSWORDS_DONT_MATCH_ERROR))
        .required(t(PASSWORD_CONFIRM_REQUIRED_ERROR)),
      creator_type: Yup.string().required(t(REQUIRED_ERROR)),
      privacy: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
    });
}

//validate create new element
export function setElementSchema(rules,t) {
    const {
        ELEMENT_NAME_MAX_LENGTH,
        ELEMENT_NAME_MIN_LENGTH,
        ELEMENT_DESCRIPTION_MIN_LENGTH,
        ELEMENT_DESCRIPTION_MAX_LENGTH
    } = rules;
    return Yup.object().shape({
        name: Yup.string()
            .min(ELEMENT_NAME_MIN_LENGTH, t(TOO_SHORT_ERROR))
            .max(ELEMENT_NAME_MAX_LENGTH, t(TOO_LONG_ERROR))
            .nullable()
            // .required(t(REQUIRED_ERROR))
            ,
        description: Yup.string()
            .min(ELEMENT_DESCRIPTION_MIN_LENGTH, t(TOO_SHORT_ERROR))
            .max(ELEMENT_DESCRIPTION_MAX_LENGTH, t(TOO_LONG_ERROR))
            .nullable()
            // .required(t(REQUIRED_ERROR))
    })
}

export function setElementNullableSchema(rules,t) {
    const {
        ELEMENT_NAME_MAX_LENGTH,
        ELEMENT_NAME_MIN_LENGTH,
        ELEMENT_DESCRIPTION_MIN_LENGTH,
        ELEMENT_DESCRIPTION_MAX_LENGTH
    } = rules;
    return Yup.object().shape({
        name: Yup.string()
            .min(ELEMENT_NAME_MIN_LENGTH, t(TOO_SHORT_ERROR))
            .max(ELEMENT_NAME_MAX_LENGTH, t(TOO_LONG_ERROR))
            .nullable(),
        description: Yup.string()
            .min(ELEMENT_DESCRIPTION_MIN_LENGTH, t(TOO_SHORT_ERROR))
            .max(ELEMENT_DESCRIPTION_MAX_LENGTH, t(TOO_LONG_ERROR))
            .nullable()
    })
}
//validate identifier file
export function setIdentifierSchema(rules,data_type,t) {
    const {
        VIDEO_UPLOAD_MAX_SIZE_BYTES,
        IMAGE_UPLOAD_MAX_SIZE_BYTES,
        IDENTIFIER_3D_DATA_SUPPORTED_FORMATS,
        RESOURCE_VIDEO_SUPPORTED_FORMATS,
        IDENTIFIER_2D_DATA_SUPPORTED_FORMATS,
    } = rules;

    function checkSize(type) {
        if(isImage(type)){
            return IMAGE_UPLOAD_MAX_SIZE_BYTES
        }else if(isVideo(type)){
            return VIDEO_UPLOAD_MAX_SIZE_BYTES
        }
    }

    function checkType(type) { 
        if (type.includes('video')) {
            return RESOURCE_VIDEO_SUPPORTED_FORMATS;
        } else { 
            return IDENTIFIER_2D_DATA_SUPPORTED_FORMATS;
        }
    }
    const supportedFormats = data_type === elementTypes.element2d ? IDENTIFIER_2D_DATA_SUPPORTED_FORMATS : RESOURCE_VIDEO_SUPPORTED_FORMATS;
    return Yup.object().shape({
      file: Yup.mixed()
        .required(t(REQUIRED_ERROR))
        .test(
          "fileSize",
          t(FILE_TOO_LARGE_ERROR),
          (value) => value && value.size <= checkSize(value)
        )
        .test(
          "fileFormat",
          t(UNSUPPORTED_FORMAT_ERROR),
          (value) => value && checkType(value.type).includes(value.type)
        ),
    });
}

//validate identifier file
export function setDigitalPrintSchema(rules,data_type,t) {
    const {
        ELEMENT_NAME_MIN_LENGTH,
        ELEMENT_NAME_MAX_LENGTH,
        VIDEO_UPLOAD_MAX_SIZE_BYTES,
        IMAGE_UPLOAD_MAX_SIZE_BYTES,
        IDENTIFIER_3D_DATA_SUPPORTED_FORMATS,
        IDENTIFIER_2D_DATA_SUPPORTED_FORMATS,
    } = rules;

    function checkType(type){
        if(isImage(type)){
            return IMAGE_UPLOAD_MAX_SIZE_BYTES
        }else if(isVideo(type)){
            return VIDEO_UPLOAD_MAX_SIZE_BYTES
        }
    }
    const supportedFormats = data_type === elementTypes.element2d ? IDENTIFIER_2D_DATA_SUPPORTED_FORMATS : IDENTIFIER_3D_DATA_SUPPORTED_FORMATS;
    return Yup.object().shape({
      name: Yup.string()
        .min(ELEMENT_NAME_MIN_LENGTH, t(TOO_SHORT_ERROR))
        .max(ELEMENT_NAME_MAX_LENGTH, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      description: Yup.string()
        .min(ELEMENT_NAME_MIN_LENGTH, t(TOO_SHORT_ERROR))
        .max(ELEMENT_NAME_MAX_LENGTH, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      file: Yup.mixed()
        .required(t(REQUIRED_ERROR))
        .test(
          "fileSize",
          t(FILE_TOO_LARGE_ERROR),
          (value) => value && value.size <= checkType(value)
        )
        .test(
          "fileFormat",
          t(UNSUPPORTED_FORMAT_ERROR),
          (value) => value && supportedFormats.includes(value.type)
        ),
      price: Yup.string()
        .min(ELEMENT_NAME_MIN_LENGTH, t(TOO_SHORT_ERROR))
        .max(ELEMENT_NAME_MAX_LENGTH, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      quantity: Yup.string()
        .min(ELEMENT_NAME_MIN_LENGTH, t(TOO_SHORT_ERROR))
        .max(ELEMENT_NAME_MAX_LENGTH, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
    });
}

function answersSchema (rules,t) {
    const {
        QUIZ_ANSWER_MAX_LENGTH,
        QUIZ_ANSWER_MIN_LENGTH,
    } = rules;
    return Yup.object().shape({
        name: Yup.string()
            .min(QUIZ_ANSWER_MIN_LENGTH, t(TOO_SHORT_ERROR))
            .max(QUIZ_ANSWER_MAX_LENGTH, t(TOO_LONG_ERROR))
            .required(t(TEXT_ANSWER_REQUIRED_ERROR)),
        is_correct: Yup.boolean().required()
    })
}


export function setQuizSchema(rules,t) {
    const {
        QUIZ_QUESTION_MAX_LENGTH,
        QUIZ_QUESTION_MIN_LENGTH,
        QUIZ_DESCRIPTION_MAX_LENGTH,
        QUIZ_DESCRIPTION_MIN_LENGTH,
        QUIZ_MAX_COUNT_OF_ANSWERS,
        QUIZ_MIN_COUNT_OF_ANSWERS,
    } = rules;
    return Yup.object().shape({
        question: Yup.string()
            .min(QUIZ_QUESTION_MIN_LENGTH, t(TOO_SHORT_ERROR))
            .max(QUIZ_QUESTION_MAX_LENGTH, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        description: Yup.string()
            .min(QUIZ_DESCRIPTION_MIN_LENGTH, t(TOO_SHORT_ERROR))
            .max(QUIZ_DESCRIPTION_MAX_LENGTH, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        points: Yup.number()
            .typeError('Should be a number')
            .required(t(REQUIRED_ERROR)),
        answers: Yup.array().of(answersSchema(rules,t))
            .min(QUIZ_MIN_COUNT_OF_ANSWERS, `The quiz should have a minimum of ${QUIZ_MIN_COUNT_OF_ANSWERS} answers`)
            .max(QUIZ_MAX_COUNT_OF_ANSWERS, `The quiz should have a maximum of ${QUIZ_MAX_COUNT_OF_ANSWERS} answers`)
            .required(t(REQUIRED_ERROR))
            // .test('correct-error', 'One of the answers should be correct ', answers => {
            //     return answers.some(answer => answer.is_correct)
            // })

    })
}

//validate experience form
export function setExperienceSchema(rules,t) {
    const {
        EXPERIENCE_NAME_MAX_SIZE,
        EXPERIENCE_NAME_MIN_SIZE,
        EXPERIENCE_DESCRIPTION_MAX_SIZE,
        EXPERIENCE_DESCRIPTION_MIN_SIZE
    } = rules;
    return Yup.object().shape({
        name: Yup.string()
            .min(EXPERIENCE_NAME_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(EXPERIENCE_NAME_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR))
            .nullable(),
        description: Yup.string()
            .min(EXPERIENCE_DESCRIPTION_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(EXPERIENCE_DESCRIPTION_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR))
            .nullable(),
        type_id: Yup.number(),
        category_id: Yup.number()
    })
}

export function setExperienceNullableSchema(rules,t) {
    const {
        EXPERIENCE_NAME_MAX_SIZE,
        EXPERIENCE_NAME_MIN_SIZE,
        EXPERIENCE_DESCRIPTION_MAX_SIZE,
        EXPERIENCE_DESCRIPTION_MIN_SIZE
    } = rules;
    return Yup.object().shape({
        name: Yup.string()
            .min(EXPERIENCE_NAME_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(EXPERIENCE_NAME_MAX_SIZE, t(TOO_LONG_ERROR))
            .nullable(),
        description: Yup.string()
            .min(EXPERIENCE_DESCRIPTION_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(EXPERIENCE_DESCRIPTION_MAX_SIZE, t(TOO_LONG_ERROR))
            .nullable(),
        type_id: Yup.number(),
        category_id: Yup.number()
    })
}

export function setUserSchema(rules,t) {
    const {
        USER_FIRST_NAME_MAX_SIZE,
        USER_FIRST_NAME_MIN_SIZE,
        USER_LAST_NAME_MAX_SIZE,
        USER_LAST_NAME_MIN_SIZE,
        // AUTH_EMAIL_MIN_LENGTH,
        // AUTH_EMAIL_MAX_LENGTH,
    } = rules;
    return Yup.object().shape({
        first_name: Yup.string()
            .min(USER_FIRST_NAME_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(USER_FIRST_NAME_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        last_name: Yup.string()
            .min(USER_LAST_NAME_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(USER_LAST_NAME_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        // email: Yup.string()
        //     .min(AUTH_EMAIL_MIN_LENGTH, t(TOO_SHORT_ERROR))
        //     .max(AUTH_EMAIL_MAX_LENGTH, t(TOO_LONG_ERROR))
        //     .nullable(),
        // avatar: Yup.mixed(),
    })
}

export function setBusinessSchema_Backup(rules) {
    const {
        EXPERIENCE_NAME_MAX_SIZE,
        EXPERIENCE_NAME_MIN_SIZE,
        EXPERIENCE_DESCRIPTION_MAX_SIZE,
        EXPERIENCE_DESCRIPTION_MIN_SIZE
    } = rules;
    return Yup.object().shape({
        name: Yup.string()
            .min(EXPERIENCE_NAME_MIN_SIZE, TOO_SHORT_ERROR)
            .max(EXPERIENCE_NAME_MAX_SIZE, TOO_LONG_ERROR)
            .nullable(),
        location: Yup.string()
            .min(EXPERIENCE_DESCRIPTION_MIN_SIZE, TOO_SHORT_ERROR)
            .max(EXPERIENCE_DESCRIPTION_MAX_SIZE, TOO_LONG_ERROR)
            .nullable(),
        avatar: Yup.mixed(),
    })
}


export function setBusinessSchema(rules,t) {
    const {
        BUSINESS_NAME_MAX_SIZE,
        BUSINESS_NAME_MIN_SIZE,
        BUSINESS_DESCRIPTION_MAX_SIZE,
        BUSINESS_DESCRIPTION_MIN_SIZE,
        BUSINESS_EXTERNAL_URL_MAX_SIZE,
        BUSINESS_EXTERNAL_URL_MIN_SIZE,
        BUSINESS_POSTER_SUPPORTED_FORMATS,
        BUSINESS_POSTER_MAX_SIZE_BYTES,
    } = rules;

    return Yup.object().shape({
      name: Yup.string()
        .min(BUSINESS_NAME_MIN_SIZE, t(TOO_SHORT_ERROR))
        .max(BUSINESS_NAME_MAX_SIZE, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      description: Yup.string()
        .min(BUSINESS_DESCRIPTION_MIN_SIZE, t(TOO_SHORT_ERROR))
        .max(BUSINESS_DESCRIPTION_MAX_SIZE, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      external_url: Yup.string()
        .min(BUSINESS_EXTERNAL_URL_MIN_SIZE, t(TOO_SHORT_ERROR))
        .max(BUSINESS_EXTERNAL_URL_MAX_SIZE, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      logotype: Yup.mixed()
        .test(
          "fileSize",
          t(FILE_TOO_LARGE_ERROR),
          (value) => value && value.size <= BUSINESS_POSTER_MAX_SIZE_BYTES
        )
        .test(
          "fileFormat",
          t(UNSUPPORTED_FORMAT_ERROR),
          (value) =>
            value &&
            value.type &&
            BUSINESS_POSTER_SUPPORTED_FORMATS.includes(value.type)
        ),
    });
}
export function setJobSchema(rules,t) {
    //todo complete schema
    const {
        JOB_TITLE_MAX_SIZE,
        JOB_TITLE_MIN_SIZE,
        JOB_DESCRIPION_MAX_SIZE,
        JOB_DESCRIPION_MIN_SIZE,
        JOB_BUDGET_MAX_SIZE,
        JOB_BUDGET_MIN_SIZE,
    } = rules;

    return Yup.object().shape({
        title: Yup.string()
            .min(JOB_TITLE_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(JOB_TITLE_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        description: Yup.string()
            .min(JOB_DESCRIPION_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(JOB_DESCRIPION_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        max_bid: Yup.string()
            .min(JOB_BUDGET_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(JOB_BUDGET_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        resource_type_id: Yup.string()
            .required(t(REQUIRED_ERROR)),
        required_languages: Yup.string()
            .required(t(REQUIRED_ERROR)),
        due_at: Yup.string()
            .required(t(REQUIRED_ERROR)),
    })
}
export function setPortfolioItemSchema(rules,t) {
    const {
        PORTFOLIO_ITEM_DESCRIPION_MAX_SIZE,
        PORTFOLIO_ITEM_DESCRIPION_MIN_SIZE,
        PORTFOLIO_ITEM_MAX_SIZE_BYTES,
        PORTFOLIO_ITEM_SUPPORTED_FORMATS,
    } = rules;

    return Yup.object().shape({
        description: Yup.string()
            .min(PORTFOLIO_ITEM_DESCRIPION_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(PORTFOLIO_ITEM_DESCRIPION_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        file: Yup.mixed()
            .test(
                "fileSize",
                t(FILE_TOO_LARGE_ERROR),
                value => value && value.size <= PORTFOLIO_ITEM_MAX_SIZE_BYTES
            )
            .test(
                "fileFormat",
                t(UNSUPPORTED_FORMAT_ERROR),
                value => value && (value.type && PORTFOLIO_ITEM_SUPPORTED_FORMATS.includes(value.type))
            ),
    })
}

export function setBidSchema(rules,t) {
    const {
        BID_COVER_LETTER_MAX_SIZE,
        BID_COVER_LETTER_MIN_SIZE,
        JOB_BUDGET_MIN_SIZE,
        JOB_BUDGET_MAX_SIZE,
    } = rules;

    return Yup.object().shape({
        cover_letter: Yup.string()
            .min(BID_COVER_LETTER_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(BID_COVER_LETTER_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        bid_amount: Yup.string()
            .min(JOB_BUDGET_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(JOB_BUDGET_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        required_languages: Yup.string()
            .required(t(REQUIRED_ERROR)),
        estimate_minutes: Yup.string()
            .required(t(REQUIRED_ERROR)),
    })
}
export function setProfileSchema(rules,t) {
    //todo complete schema
    const {
        USER_FIRST_NAME_MAX_SIZE,
        USER_FIRST_NAME_MIN_SIZE,
        USER_LAST_NAME_MAX_SIZE,
        USER_LAST_NAME_MIN_SIZE,
        USER_DESCRIPTION_MAX_SIZE,
        USER_DESCRIPTION_MIN_SIZE,
        USER_AVAILABILITY_MAX_SIZE,
        AVATAR_MAX_SIZE_BYTES,
        AVATAR_SUPPORTED_FORMATS,
    } = rules;

    return Yup.object().shape({
        first_name: Yup.string()
            .min(USER_FIRST_NAME_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(USER_FIRST_NAME_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        last_name: Yup.string()
            .min(USER_LAST_NAME_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(USER_LAST_NAME_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        description: Yup.string()
            .min(USER_DESCRIPTION_MIN_SIZE, t(TOO_SHORT_ERROR))
            .max(USER_DESCRIPTION_MAX_SIZE, t(TOO_LONG_ERROR))
            .required(t(REQUIRED_ERROR)),
        availability: Yup.number()
            .max(USER_AVAILABILITY_MAX_SIZE)
            .nullable(true)
            .required(t(REQUIRED_ERROR)),
        languages: Yup.array()
            .required(t(REQUIRED_ERROR)),
        timezone: Yup.string()
            .required(t(REQUIRED_ERROR)),
        avatar: Yup.mixed()
            .test(
                "fileSize",
                t(FILE_TOO_LARGE_ERROR),
                value => value && value.size <= AVATAR_MAX_SIZE_BYTES
            )
            .test(
                "fileFormat",
                t(UNSUPPORTED_FORMAT_ERROR),
                value => value && (value.type && AVATAR_SUPPORTED_FORMATS.includes(value.type))
            ),

    })
}
export function setSignUpContractorSchema(rules,t) {
    const {
        AUTH_EMAIL_MAX_LENGTH,
        AUTH_FIRST_NAME_MAX_LENGTH,
        AUTH_LAST_NAME_MAX_LENGTH,
        AUTH_PASSWORD_MAX_LENGTH,
        AUTH_PASSWORD_MIN_LENGTH
    } = rules;
    return Yup.object().shape({
      first_name: Yup.string().max(
        AUTH_FIRST_NAME_MAX_LENGTH,
        t(TOO_LONG_ERROR)
      ),
      last_name: Yup.string().max(AUTH_LAST_NAME_MAX_LENGTH, t(TOO_LONG_ERROR)),
      email: Yup.string()
        .email()
        .max(AUTH_EMAIL_MAX_LENGTH, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      password: Yup.string()
        .min(AUTH_PASSWORD_MIN_LENGTH, t(TOO_SHORT_ERROR))
        .max(AUTH_PASSWORD_MAX_LENGTH, t(TOO_LONG_ERROR))
        .required(t(REQUIRED_ERROR)),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], t(PASSWORDS_DONT_MATCH_ERROR))
        .required(t(PASSWORD_CONFIRM_REQUIRED_ERROR))
    });
}
//getting rules from server
export const getValidationRules = (group_name) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchValidationRules(group_name)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    responseJson.data.map((rule_item) => {
                        return dispatch({
                            type: SET_VALIDATION_RULES,
                            [rule_item.name]:rule_item.rule
                        });
                    });
                    resolve(responseJson)
                })
                .catch((err) => reject(err))
        })
    };

//Actions for validations
const ACTION_HANDLERS = {
    [SET_VALIDATION_RULES]: (state, action) => ({
        ...state,
        ...action
    }),
};


export default function validations(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

