import React from "react";
import Status from "../../Status";
import {getJobStatusModificator} from "../../../../common";
import ContractorInfo from "../../contractor/ContractorInfo";
import {jobStatuses} from "./JobCard";
import {resourcesOptions} from "../../ResourceTypeSelect";
import {Trans} from 'react-i18next';

function JobCardHeader(props) {
    let {status_id,max_bid, required_languages,assigned_to,resource_type_id,has_bid} = props.item_info;
    let name = status_id ? jobStatuses.find(status => status.id === status_id).name : '';
    let resourceTitle = resource_type_id ? resourcesOptions.find(item => item.id === resource_type_id).name : '';
    let $openStatus = jobStatuses.find(item => item.name === 'Open for bidding').id;
    return (
        <div className="job-card__header">
            <div className="job-card__header-item">
                <div className="job-card__header-item-title"><Trans>Resource type required</Trans></div>
                <div className="job-card__header-item-content">{resourceTitle}</div>
            </div>
            <div className="job-card__header-item">
                <div className="job-card__header-item-title"><Trans>Languages</Trans></div>
                <div className="job-card__header-item-content">
                    {required_languages && required_languages.map((lng,i) =>
                        <span className="job-card__header-lng" key={`job-card__header-lng-${i}`}> {lng} </span>)}
                </div>
            </div>
            <div className="job-card__header-item">
                <div className="job-card__header-item-title"><Trans>Budget</Trans></div>
                <div className="job-card__header-item-content">${max_bid}</div>
            </div>
            <div className="job-card__header-item">
                <div className="job-card__header-item-title"><Trans>Status</Trans></div>
                <div className="job-card__header-item-content">
                    <Status name={(has_bid && $openStatus === status_id) ? 'Bid sent' : name} modificator={getJobStatusModificator(status_id)}/>
                </div>
            </div>
            {assigned_to && <div className="job-card__header-item">
                <div className="job-card__header-item-title"><Trans>Assigned to</Trans></div>
                <div className="job-card__header-item-content">
                    <ContractorInfo contractor={assigned_to} isMini/>
                </div>
            </div>}
        </div>
    )
}

export default JobCardHeader;
