//Switch between 2d and 3d type of element

import React from 'react'
import {notify} from "../common";
import {Trans} from "react-i18next";

const Switcher = props => (
    <label className={`custom-control switch justify-content-between d-inline-flex ${props.classes}`}
           key={props.keyProp}
           onClick={() => props.disabled && notify(`You can't change type of element after uploading training data`,"warning")}>{!props.withoutText && `Select type of element:`}
        <input type="checkbox" className="switch-control-input" onChange={props.onChange}
               name={props.name} defaultChecked={props.checked ? props.checked : false}
               disabled={props.disabled || false} id={props.id}/>
        <span className={`switch-control-indicator switch-control-indicator_with-icon ${props.shortTitleOnMobile && 'switch-control-indicator_small' }`}>
            <span className="switch-control-description switch-control-description_off">2D <span className='d-sm-inline-block d-none'><Trans>Element</Trans></span></span>
            <span className="switch-control-description switch-control-description_on">3D <span className="d-sm-inline-block d-none"><Trans>Element</Trans></span></span>
        </span>
    </label>
);
export default Switcher;

