import React from 'react';
import ResourcesIcons from "../ResourcesIcons";

function ContractorInfo (props) {
    if(!props.contractor) return null;
    let {contractor, isSmall,isMini} = props;
    let smallClass = isSmall ? 'contractor-info_small' : '';
    let miniClass = isMini ? 'contractor-info_mini' : '';
    return(
        <div className={`contractor-info ${smallClass} ${miniClass}`}>
            {contractor.avatar && <div className="contractor-info__avatar">
                <img className="contractor-info__avatar-image" src={contractor.avatar.url} alt=""/>
            </div>}
            <div className="contractor-info__details">
                <div className="contractor-info__name">{contractor.full_name}</div>
                {!isMini && contractor.resources && <div className="contractor-info__verification">
                    Verified for
                    <div className="contractor-info__verification-icons">
                        <ResourcesIcons resources={contractor.resources}/>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default ContractorInfo;
