//All reducers for resources
import * as api from "../api";
import {resourceTypes, notify} from "../common";
import {setAuthorizationHeader} from "./user";
import {FETCH_FILES_PATH} from "../api";
import {API_URL} from "../api";
import {FETCH_RESOURCES_PATH} from "../api";
import {FETCH_SUPPLEMENT_FILES_PATH} from "../api";
import {handleErrors} from "./errors";

const SET_RESOURCES_LIST = 'SET_RESOURCES_LIST',
    SET_CURRENT_RESOURCE = 'SET_CURRENT_RESOURCE',
    SET_SELECTED_RESOURCES = 'SET_SELECTED_RESOURCES',
    SET_SELECTED_DELETE_RESOURCES = 'SET_SELECTED_DELETE_RESOURCES',
    SET_DELETE_RESOURCES_MODE = 'SET_DELETE_RESOURCES_MODE',
    SET_RESOURCE_MODAL_STATUS = 'SET_RESOURCE_MODAL_STATUS',
    SET_DELETE_RESOURCE_MODAL_STATUS = 'SET_DELETE_RESOURCE_MODAL_STATUS',
    SET_DELETE_SINGLE_RESOURCE_MODAL_STATUS = 'SET_DELETE_SINGLE_RESOURCE_MODAL_STATUS',
    SET_CLICKED_PAIRED_RESOURCE_ID = 'SET_CLICKED_PAIRED_RESOURCE_ID',
    SET_RESOURCE_IMAGES_MODAL_STATUS = 'SET_RESOURCE_IMAGES_MODAL_STATUS',
    SET_FILE_PROGRESS = 'SET_FILE_PROGRESS',
    SET_RESOURCES_DEGREE_IMAGES_LIST = 'SET_RESOURCES_DEGREE_IMAGES_LIST',
    SET_RESOURCES_DEGREE_VIDEOS_LIST = 'SET_RESOURCES_DEGREE_VIDEOS_LIST',
    SET_RESOURCES_DEGREE_LIST = 'SET_RESOURCES_DEGREE_LIST',
    SET_RESOURCES_IMAGES_LIST = 'SET_RESOURCES_IMAGES_LIST',
    SET_NON_DEGREE_RESOURCES_LIST = 'SET_NON_DEGREE_RESOURCES_LIST',
    SHOW_RESOURCES_LIST_MODAL = 'SHOW_RESOURCES_LIST_MODAL',
    SET_ELEMENT_RESOURCES_LIST = 'SET_ELEMENT_RESOURCES_LIST',
    SET_RESOURCE_ANALYTICS = 'SET_RESOURCE_ANALYTICS',
    SHOW_FULLSIZE_GALLERY = 'SHOW_FULLSIZE_GALLERY',
    SET_REQUEST_CREATION_MODAL_STATUS = 'SET_REQUEST_CREATION_MODAL_STATUS',
    SET_RESOURCE_LANGUAGE = 'SET_RESOURCE_LANGUAGE',
    SET_FIELD_RESOURCE = 'SET_FIELD_RESOURCE',
    SET_CREATED_RESOURCE = 'SET_CREATED_RESOURCE',
    SET_DELETED_MULTIPLE_RESOURCES = 'SET_DELETED_MULTIPLE_RESOURCES',
    SET_CREATE_RESOURCES_MODE = 'SET_CREATE_RESOURCES_MODE';

const initialState = {
    resources: [],
    resources_degree_images: [],
    resources_images: [],
    resources_degree: [],
    resources_non_degree: [],
    resource_item: undefined,
    selected_resources: [],
    selected_delete_resources: [],
    delete_resources_mode:false,
    create_resources_mode:false,
    show_delete_resource_modal:false,
    show_delete_single_resource_modal:false,
    clicked_paired_resource_id:undefined,
    show_resource_modal:false,
    show_resource_images_modal:false,
    progress_percent: 0,
    resource_degree_videos:[],
    show_resources_list_modal:false,
    element_resources:[],
    analytics: undefined,
    show_fullsize_gallery: false,
    resource_language: 'en',
    created_resource: undefined,
    deleted_resource: undefined,
    deleted_multiple_resources: [],
    updated_resource: undefined,
};

export const getResources = (types,element_id,limit,page ) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetResources(types)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_RESOURCES_LIST,
                        resources: responseJson.data
                    });
                    resolve()
                })
        })
    };

export const getElementResources = (types,element_id,limit) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetResources(types,element_id,limit)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_ELEMENT_RESOURCES_LIST,
                        element_resources: responseJson.data
                    });
                    resolve()
                })
        })
    };

export const getResourcesImages = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetResources([resourceTypes.image])
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_RESOURCES_IMAGES_LIST,
                        resources_images: responseJson.data
                    });
                    resolve()
                })
        })
    };

export const getNonDegreeResources = (element_id,limit,page) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetResources([resourceTypes.image,resourceTypes.video,resourceTypes.audio,resourceTypes.text,resourceTypes.model,resourceTypes.zip,resourceTypes.animations,resourceTypes.url],element_id,limit,page)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_NON_DEGREE_RESOURCES_LIST,
                        resources_non_degree: responseJson.data
                    });
                    resolve(responseJson)
                })
        })
    };

export const getResourcesDegreeImages = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetResources([resourceTypes.degreeImage])
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_RESOURCES_DEGREE_IMAGES_LIST,
                        resources_degree_images: responseJson.data
                    });
                    resolve()
                })
        })
    };

export const getResourcesDegreeVideos = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetResources([resourceTypes.degreeVideo])
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_RESOURCES_DEGREE_VIDEOS_LIST,
                        resource_degree_videos: responseJson.data
                    });
                    resolve()
                })
        })
    };

export const getResourcesDegree = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetResources([resourceTypes.degreeImage,resourceTypes.degreeVideo])
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_RESOURCES_DEGREE_LIST,
                        resources_degree: responseJson.data
                    });
                    resolve()
                })
        })
    };

export const setCurrentResource = (resource_item) =>
    (dispatch) => {
        dispatch({
            type: SET_CURRENT_RESOURCE,
            resource_item
        });
    };


export const setDeleteResourceModalStatus = (status) =>
    (dispatch) => {
        dispatch({
            type: SET_DELETE_RESOURCE_MODAL_STATUS,
            show_delete_resource_modal:status
        });
    };

export const setDeleteSingleResourceModalStatus = (status) =>
    (dispatch) => {
        dispatch({
            type: SET_DELETE_SINGLE_RESOURCE_MODAL_STATUS,
            show_delete_single_resource_modal:status
        });
    };

export const setClickedPairedResourceId = (clicked_paired_resource_id) =>
    (dispatch) => {
        dispatch({
            type: SET_CLICKED_PAIRED_RESOURCE_ID,
            clicked_paired_resource_id
        });
    };

export const setResourceImagesModalStatus = (status) =>
    (dispatch) => {
        dispatch({
            type: SET_RESOURCE_IMAGES_MODAL_STATUS,
            show_resource_images_modal:status
        });
    };

export const updateResourceData = (id,resource_updated,field_name,language) =>
    (dispatch) => {
        return new Promise((resolve,reject) => {
            api.fetchUpdateResourceData(id,resource_updated)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_FIELD_RESOURCE,
                        name:[field_name],
                        value:responseJson.data.translations[language] ? responseJson.data.translations[language][field_name] : null,
                        language:[language],
                        updated_resource: responseJson.data
                    });
                    notify('Successfully Saved '+ (field_name ==='name'? 'Element Name': field_name ==='description'? 'Element Description': '') ,'success')
                    resolve()
                })
        })
    };

export const createResource = (resource_data) =>
    (dispatch) => {
        return new Promise((resolve,reject) => {
            api.fetchCreateResource(resource_data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
        })
    };

export const getResourceById = (resource_id) =>
    (dispatch) => {
        return new Promise((resolve,reject) => {
            api.fetchGetResourceById(resource_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CREATED_RESOURCE,
                        created_resource:responseJson.data,
                    });
                    resolve(responseJson.data)
                })
        })
    };

export const clearCreatedResource = () =>
    (dispatch) => {
        dispatch({
            type: SET_CREATED_RESOURCE,
            created_resource: initialState.created_resource
        });
    };

export const clearUpdatedResource = () =>
    (dispatch) => {
        dispatch({
            type: SET_CREATED_RESOURCE,
            updated_resource: initialState.updated_resource
        });
    };

export const addResourceImage = (file,resource_id) =>
    (dispatch) => {
        return new Promise((resolve,reject) => {
            api.fetchAddResourceImage(file,resource_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
        })
    };

export const addResourceImageXHR = (file,resource_id,isSupplement,type_id,language) =>
    (dispatch) => {
    let url = isSupplement ? API_URL + FETCH_RESOURCES_PATH + `/${resource_id}` + FETCH_SUPPLEMENT_FILES_PATH : FETCH_FILES_PATH;
        return new Promise((resolve,reject) => {
            let formdata = new FormData();
            formdata.append('file', file);
            formdata.append('type_id', type_id);
            !isSupplement && formdata.append('resource_id', resource_id);
            if(language) formdata.append('locale', language);
            var xhr = new XMLHttpRequest()
            xhr.onload = function() {
                resolve(JSON.parse(xhr.responseText).data);
                dispatch({
                    type: SET_FILE_PROGRESS,
                    progress_percent: 0
                });
            }
            xhr.onerror = (err) => console.log(err,'err')
            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    var percent = Math.round((event.loaded / event.total) * 100)
                    dispatch({
                        type: SET_FILE_PROGRESS,
                        progress_percent:percent
                    });
                }
            }
            xhr.open('POST', url)
            xhr.setRequestHeader('Accept', 'application/json')
            xhr.setRequestHeader('Authorization', setAuthorizationHeader())
            xhr.send(formdata)
        })
    };

export const setSelectedResources = (selected_resources) =>
    (dispatch) => {
        dispatch({
            type: SET_SELECTED_RESOURCES,
            selected_resources
        });
    };

export const setFileProgress = (progress_percent) =>
    (dispatch) => {
        dispatch({
            type: SET_FILE_PROGRESS,
            progress_percent
        });
    };

export const setSelectedDeleteResources = (selected_delete_resources) =>
    (dispatch) => {
        dispatch({
            type: SET_SELECTED_DELETE_RESOURCES,
            selected_delete_resources
        });
    };

export const setDeleteResourcesMode = (delete_resources_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_DELETE_RESOURCES_MODE,
            delete_resources_mode
        });
    };

export const setCreateResourcesMode = (create_resources_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_CREATE_RESOURCES_MODE,
            create_resources_mode
        });
    };

export const showResourceListModal = (show_resources_list_modal) =>
    (dispatch) => {
        dispatch({
            type: SHOW_RESOURCES_LIST_MODAL,
            show_resources_list_modal
        });
    };

export const showFullSizeGallery = (show_fullsize_gallery) =>
    (dispatch) => {
        dispatch({
            type: SHOW_FULLSIZE_GALLERY,
            show_fullsize_gallery
        });
    };

export const bindSelectedResources = (element_id, ids) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchBindResources(element_id, ids)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
        })
    };

export const unbindSelectedResources = (element_id, ids) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUnBindResources(element_id, ids)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
        })
    };

export const deleteSelectedResources = (selected_resources) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteResources(selected_resources)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_DELETED_MULTIPLE_RESOURCES,
                        deleted_multiple_resources: selected_resources
                    })
                    resolve(responseJson)
                })
        })
    };

export const clearDeletedMultipleResources = () =>
    (dispatch) => {
        dispatch({
            type: SET_DELETED_MULTIPLE_RESOURCES,
            deleted_multiple_resources: initialState.deleted_multiple_resources
        })
    };

export const deleteSingledResource = (resource_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteSingleResource(resource_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
        })
    };

export const addTagsToResource = (resource_id,names) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchAddHashtagsToResource(resource_id,names)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
        })

    };

export const deleteTagsFromResource = (resource_id,names) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteHashtagsFromResource(resource_id,names)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
        })

    };

export const getResourceAnalytics = (resource_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetResourceAnalytics(resource_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_RESOURCE_ANALYTICS,
                        analytics: responseJson.data
                    });
                    resolve()
                })
        })
    };

export const sendRequestCreationResource = (type_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchRequestCreationResource(type_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve()
                })
        })
    };

export const setRequestCreationModalStatus = (status) =>
    (dispatch) => {
        dispatch({
            type: SET_REQUEST_CREATION_MODAL_STATUS,
            show_request_creation_modal:status
        });
    };

export const setResourceLanguage = (resource_language) =>
    (dispatch) => {
        dispatch({
            type: SET_RESOURCE_LANGUAGE,
            resource_language
        });
    };


//Resources actions
const ACTION_HANDLERS = {
    [SET_RESOURCES_LIST]: (state, action) => ({
        ...state,
        resources: action.resources,
    }),
    [SET_ELEMENT_RESOURCES_LIST]: (state, action) => ({
        ...state,
        element_resources: action.element_resources,
    }),
    [SET_CURRENT_RESOURCE]: (state, action) => ({
        ...state,
        resource_item: action.resource_item,
    }),
    [SET_SELECTED_RESOURCES]: (state, action) => ({
        ...state,
        selected_resources: action.selected_resources,
    }),
    [SET_SELECTED_DELETE_RESOURCES]: (state, action) => ({
        ...state,
        selected_delete_resources: action.selected_delete_resources,
    }),
    [SET_DELETE_RESOURCES_MODE]: (state, action) => ({
        ...state,
        delete_resources_mode: action.delete_resources_mode,
    }),
    [SET_RESOURCE_MODAL_STATUS]: (state, action) => ({
        ...state,
        show_resource_modal: action.show_resource_modal,
    }),
    [SET_DELETE_RESOURCE_MODAL_STATUS]: (state, action) => ({
        ...state,
        show_delete_resource_modal: action.show_delete_resource_modal,
    }),
    [SET_DELETE_SINGLE_RESOURCE_MODAL_STATUS]: (state, action) => ({
        ...state,
        show_delete_single_resource_modal: action.show_delete_single_resource_modal,
    }),
    [SET_CLICKED_PAIRED_RESOURCE_ID]: (state, action) => ({
        ...state,
        clicked_paired_resource_id: action.clicked_paired_resource_id,
    }),
    [SET_RESOURCE_IMAGES_MODAL_STATUS]: (state, action) => ({
        ...state,
        show_resource_images_modal: action.show_resource_images_modal,
    }),
    [SET_FILE_PROGRESS]: (state, action) => ({
        ...state,
        progress_percent: action.progress_percent,
    }),
    [SET_CREATE_RESOURCES_MODE]: (state, action) => ({
        ...state,
        create_resources_mode: action.create_resources_mode,
    }),
    [SET_RESOURCES_DEGREE_IMAGES_LIST]: (state, action) => ({
        ...state,
        resources_degree_images: action.resources_degree_images,
    }),
    [SET_RESOURCES_DEGREE_VIDEOS_LIST]: (state, action) => ({
        ...state,
        resource_degree_videos: action.resource_degree_videos,
    }),
    [SET_RESOURCES_DEGREE_LIST]: (state, action) => ({
        ...state,
        resources_degree: action.resources_degree,
    }),
    [SET_RESOURCES_IMAGES_LIST]: (state, action) => ({
        ...state,
        resources_images: action.resources_images,
    }),
    [SET_NON_DEGREE_RESOURCES_LIST]: (state, action) => ({
        ...state,
        resources_non_degree: action.resources_non_degree,
    }),
    [SHOW_RESOURCES_LIST_MODAL]: (state, action) => ({
        ...state,
        show_resources_list_modal: action.show_resources_list_modal,
    }),
    [SET_RESOURCE_ANALYTICS]: (state, action) => ({
        ...state,
        analytics: action.analytics,
    }),
    [SHOW_FULLSIZE_GALLERY]: (state, action) => ({
        ...state,
        show_fullsize_gallery: action.show_fullsize_gallery,
    }),
    [SET_REQUEST_CREATION_MODAL_STATUS]: (state, action) => ({
        ...state,
        show_request_creation_modal: action.show_request_creation_modal,
    }),
    [SET_RESOURCE_LANGUAGE]: (state, action) => ({
        ...state,
        resource_language: action.resource_language,
    }),
    [SET_CREATED_RESOURCE]: (state, action) => ({
        ...state,
        created_resource: action.created_resource,
    }),
    [SET_DELETED_MULTIPLE_RESOURCES]: (state, action) => ({
        ...state,
        deleted_multiple_resources: action.deleted_multiple_resources,
    }),
    [SET_FIELD_RESOURCE]: (state, action) => ({
        ...state,
        resource_item: {
            ...state.resource_item,
            translations: {
                ...state.resource_item.translations,
                [action.language]:{
                    ...state.resource_item.translations[action.language],
                    [action.name]:action.value
                }
            }
        },
        updated_resource: action.updated_resource
    }),

};


export default function resources(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

