//element card with preview image, name, status(active) and count of paired resources

import React  from 'react'
import { Link } from 'react-router-dom';
import checkIcon from "../../images/check-icon.svg";
import {Trans} from "react-i18next";
import Tags from "../Tags";
import ResourcesList from "./ResourcesList";
import TrainingAsPreview from "./ElementView/TrainingAsPreview";
import EmptyPreview from "./ElementView/EmptyPreview";

class ElementCard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            checkMode: true,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(!nextProps.printMode && !nextProps.deleteMode){
            update.checked = false
        }
        return Object.keys(update).length ? update : null;
    }

    handleCheck = () => {
        const {checked} = this.state;
        const {element_item} = this.props;
        this.setState({checked: !checked})
        this.props.callbackCheck(element_item.id,!checked);
    }

    renderPreview(){
        if(this.props.element_item){
            if(process.env.REACT_APP_TRAININGS_AS_PREVIEW){
                return <TrainingAsPreview element_item={this.props.element_item}/>
            }else {
                let identifiers = this.props.element_item.identifiers[0];
                let { preview } = this.props.element_item;
                if (preview && preview.url) {
                  return (
                    <img
                      src={preview.url}
                      alt=""
                      className="element-item__preview"
                    />
                  );
                } else if (identifiers && identifiers.preview_url) {
                         return (
                           <img
                             src={identifiers.preview_url}
                             alt=""
                             className="element-item__preview"
                           />
                         );
                } else {
                    return <EmptyPreview />;
                }
            }
        }
    }

    render(){
        const {element_item,printMode,deleteMode} = this.props;
        let main_content_language=this.props.main_content_language;
        let element_name = element_item.translations[main_content_language] ? element_item.translations[main_content_language].name : undefined;
        const {checked} = this.state;
        let checkedClass = checked ? "checked" : '';
        let {identifiers} = element_item;
        let trainings_length = identifiers.length;
        return(
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 element-item__wrap">
                <div className={`element-item ${checkedClass}`} >
        {(!printMode && !deleteMode) && <Link className="element__item-click-area" to={{ pathname: `/element-${element_item.id}`, element_item }}/> }
                    <div className={`element-item__body ${(!identifiers || trainings_length === 0) ? "" : ""}`}>
                        {this.renderPreview()}
                        {element_name && <div className="element-item__name">{element_name}</div>}
                        <Tags tags={element_item.tags} classes={"element-item__tags"}/>
                    </div>
                    <div className="element-item__side-details">
                        <div className="element-item__title"><Trans>Resources</Trans></div>
                        <ResourcesList element_item={element_item}/>
                        <div className="element-item__title"><Trans>Identifiers</Trans></div>
                        <div className="element-item__count-text">{trainings_length}</div>

                        <div className="element-item__title"><Trans>Digital Prints</Trans></div>
                        <div className="element-item__count-text">{element_item.digital_print_count}</div>

                        <div className="element-item__title"><Trans>Status</Trans></div>
                        {element_item.active && <div className="element-item__status"><Trans>Published</Trans></div>}
                        {!element_item.active && <div className="element-item__status element-item__status_draft">
                            <Trans>Draft</Trans>
                        </div>}

                    </div>
                    {(printMode || deleteMode) && <span className="item-checkbox-wrap" onClick={this.handleCheck}>
                        <span className="item-checkbox">
                            {checked && <img className="item-checkbox__status-icon" src={checkIcon} alt=""/>}
                        </span>
                    </span>}
                </div>
            </div>
        )
    }

}

export default ElementCard;

