//dashboard screen
import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {canvasLanguages, languages} from "../../common";
import * as _ from "lodash";
import { withTranslation, Trans } from 'react-i18next';
import ElementInfo from "./ElementInfo";
import ResourceInfo from "./ResourceInfo";
import QuizInfo from "./QuizInfo";
import PublishElement from './PublishElement';

import {
  setMainContentLanguage,
  toggleUpgradePlanModal,
  updateBusinessInfo,
  updateUserInfo,
  updateCouponCodeForBusiness,
  setLoading,
  uploadBusinessPoster,
  getBusinessInfo,
  uploadUserAvatar,
  deleteUserAvatar,
  getDashboardInfo,
} from "../../store/user";
import Loading from "../../components/Loading";
import ErrorBoundaryContainer from "../../components/ErrorBoundaryContainer";

 class Home extends React.Component {
     constructor(props) {
         super(props);
         this.state = {
           language: 1,
           main_content_language: 1
         };
         props.setLoading(true)

     }

     componentDidMount() {
         this.props.getBusinessInfo()
         setTimeout(() => {
             this.props.setLoading(false)
         },3000)
     }

     static getDerivedStateFromProps(nextProps, prevState){
         let update = {};
         if(nextProps.user_info && nextProps.user_info.locale){
             update.language = _.find(canvasLanguages,['short_name',nextProps.user_info.locale]).id;
         }
         if(nextProps.main_content_language){
             update.main_content_language = _.find(languages,['short_name',nextProps.main_content_language]).id;
         }
         return Object.keys(update).length ? update : null;
     }

     handleLanguageChange = (name,value) => {
         this.setState({[name]:value})
         let newlang = _.find(canvasLanguages,['id',value]).short_name;
         this.props.i18n.changeLanguage(newlang);
         this.props.updateUserInfo({locale:newlang})
     }

     handleMainLanguageChange = (name,value) => {
         this.setState({[name]:value})
         let newlang = _.find(languages,['id',value]).short_name;
         this.props.setMainContentLanguage(newlang);
     }

     render() {
         return (
           <ErrorBoundaryContainer>
             <div className="profile-screen">
               <Loading small={true} classes="position-fixed" />
               <div className="row">
                   <ElementInfo
                     model_name="Total Number of Active Elements"
                     model="element"
                     dashboard_info={this.props.dashboard_info}
                   />
               </div>
               <br />
               <div className="row">
                 <div className="col-md-12 col-sm-12 profile-col">
                   <PublishElement dashboard_info={this.props.dashboard_info} />
                 </div>
               </div>
               <br />
               <div className="row">
                 <div className="col-md-12 col-sm-12 profile-col">
                   <ResourceInfo
                     dashboard_info={this.props.dashboard_info}
                   />
                 </div>
               </div>
               <br />
               <div className="row">
                 <div className="col-md-12 col-sm-12 profile-col">
                   <QuizInfo
                     {...this.props}
                     dashboard_info={this.props.dashboard_info}
                   />
                 </div>
               </div>
             </div>
           </ErrorBoundaryContainer>
         );
     }
 }

const mapStateToProps = (state) => ({
  user_info: state.user.user_info,
  business_info: state.user.business_info,
  main_content_language: state.user.main_content_language,
  validation_rules: state.validations,
  dark_mode: state.user.dark_mode,
  coupon_code_for_business: state.user.coupon_code_for_business,
  dashboard_info: state.user.dashboard_info,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleUpgradePlanModal,
      updateUserInfo,
      updateBusinessInfo,
      setMainContentLanguage,
      updateCouponCodeForBusiness,
      setLoading,
      uploadBusinessPoster,
      getBusinessInfo,
      uploadUserAvatar,
      deleteUserAvatar,
      getDashboardInfo
    },
    dispatch
  );

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Home));

