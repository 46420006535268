import React from "react";
import CardsList from "../../components/CardsList";
import CustomScrollBar from "../../components/CustomScrollBar";
import InfiniteScrollContainerNew from "../../components/InfiniteScrollContainerNew";
import ElementItemInExperience from "../experience/ElementItemInExperience";

function ElementsList(props) {
    let overflowVerticalOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
    const getItems = (newPage) => {
        let getItems = props.getElements2;
        return new Promise((resolve,reject) => {
            getItems({ page: newPage, vr_active:1})
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        })
    }

    return(
        <CustomScrollBar className="cards-list-offset p-0" options={overflowVerticalOptions}>
            <InfiniteScrollContainerNew getItems={getItems} clearList={props.clearElementsList}>
                <CardsList ItemComponent={ElementItemInExperience} items={props.elements_for_experience} {...props}
                           selectedItems={props.selectedItems} key={'ElementItemInExperience'}/>
            </InfiniteScrollContainerNew>
        </CustomScrollBar>
    )
}

export default ElementsList;
