//Button on vr experiences list screen for create new experience
//After clicking on the button will create experience with default data

import React from 'react'
import CreateButton from "../top-panel/CreateButton";


function CreateExperienceButton(props) {

    const onClick = () => {
        //props.createExperience().then((res) => {
            //props.history.push(`/experience-${res.id}`)
            props.history.push(`/experiences`);
        //})
    }
    return(
        <CreateButton title={'Create Experience'} onClick={onClick} {...props}/>
    )
}

export default CreateExperienceButton;
