import React from "react";
import Status from "../Status";
import {getBidStatusModificator, getTimeline} from "../../../common";
import {bidStatuses} from "./BidCard";
import {Trans} from 'react-i18next';

function BidCardHeader(props) {
    let {status_id, required_languages,bid_amount,estimate_minutes} = props.item_info;
    let status = bidStatuses.find(status => status.id === status_id).name;

    return (
        <div className="bid-card__header">
            <div className="bid-card__header-item">
                <div className="bid-card__header-item-title"><Trans>Proposed timeline</Trans></div>
                <div className="bid-card__header-item-content">
                    {getTimeline(estimate_minutes)}
                </div>
            </div>
            <div className="bid-card__header-item">
                <div className="bid-card__header-item-title"><Trans>Languages</Trans></div>
                <div className="bid-card__header-item-content">
                    {required_languages && required_languages.map((lng) =>
                        <span className="bid-card__header-lng" key={`lang-${lng}`}> {lng} </span>)}
                </div>
            </div>
            <div className="bid-card__header-item">
                <div className="bid-card__header-item-title"><Trans>Proposed budget</Trans></div>
                <div className="bid-card__header-item-content">${bid_amount}</div>
            </div>
            <div className="bid-card__header-item">
                <div className="bid-card__header-item-title"><Trans>Status</Trans></div>
                <div className="bid-card__header-item-content">
                    <Status name={status} modificator={getBidStatusModificator(status_id)}/>
                </div>
            </div>

        </div>
    )
}

export default BidCardHeader;
