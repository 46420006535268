//main app file with provider
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/app.scss';
import {Provider} from "react-redux";
import configureStore from './store/configureStore';
import 'react-toastify/dist/ReactToastify.css';
// import AFRAME from "aframe"; return in case we need aframe again
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js/dist/popper.min.js';
import Routes from "./Routes";
import {renew} from "./store/user";

const store = configureStore();

class App extends React.Component {

    componentDidMount() {
        renew()
    }
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <Routes/>
                </Router>
            </Provider>

        );
    }
}


export default App;
