import React from 'react';
import {NavLink} from "react-router-dom";
// import CommentsDropdown from "./CommentsDropdown";
// import NotificationsDropdown from "./NotificationsDropdown";
import {Trans} from 'react-i18next';
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";

function TopSection () {
    return(
        <ErrorBoundaryContainer>
            <nav className="top-section">
                <div className="row">
                    <div className="col">
                        <NavLink exact to="/marketplace" className="top-section__link mr-3">
                            <span><Trans>Jobs</Trans></span>
                        </NavLink>
                        <NavLink exact to="/bids" className="top-section__link">
                            <span><Trans>My bids</Trans></span>
                        </NavLink>
                    </div>
                    {/*<div className="col d-flex flex-row justify-content-end align-items-center">*/}
                    {/*    <NavLink exact to="/get-verified">*/}
                    {/*        <button className='btn btn-primary'>Get verified</button>*/}
                    {/*    </NavLink>*/}
                    {/*    <NotificationsDropdown/>*/}
                    {/*    <CommentsDropdown/>*/}
                    {/*</div>*/}
                </div>

            </nav>
        </ErrorBoundaryContainer>
    )
}

export default TopSection;
