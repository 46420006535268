import React from 'react';
import {Field} from "formik";
import FileUploader from "../../FileUploader";
import ReactSVG from "react-svg";
import UploadIcon from "../../../images/upload-icon.svg";
import MediaPlayer from "../../player/MediaPlayer";

function EditAnimationResource(props) {
    let {editMode, setFieldValue, errors, touched, handleBlur, values, t, handleChange, name_invalid_class,
        toggleEditMode, resetFile, updateFile,onBlurSave,showFullSizeGallery,resource_item,preview_url,language} = props;
    let supplement_files = resource_item.supplement_files[language];
    let uploadText = supplement_files ? 'Reupload' : 'Upload';
    return(
        <div className="row mb-xl-5 mb-4">
        {(!values.text_content) && <div className="col-xl-6 col-md-12">
            <div className="edit-media-wrapper">
                {!editMode && preview_url && <div className="fullsize-gallery-wrapper" onClick={() => showFullSizeGallery(true)}>
                    <div>
                        <div className="square-image-wrap">
                            {preview_url && <img src={preview_url} className="resource-item-view__image" alt=""/>}
                        </div>
                        {supplement_files && <MediaPlayer media_url={supplement_files.media_url} autoPlay loop hidden/>}
                    </div>
                </div>}
                {editMode && <Field
                    name="gif"
                    component={FileUploader}
                    setFieldValue={setFieldValue}
                    errorMessage={errors["gif"] ? errors["gif"] : undefined}
                    touched={touched["gif"]}
                    onBlur={handleBlur}
                />}
                {editMode && <Field
                    name="multimedia"
                    component={FileUploader}
                    setFieldValue={setFieldValue}
                    errorMessage={errors["multimedia"] ? errors["multimedia"] : undefined}
                    touched={touched["multimedia"]}
                    onBlur={handleBlur}
                />}
                {!editMode && <button type="button" onClick={toggleEditMode}
                        className="btn edit-media-wrapper__btn mt-3">
                    {<ReactSVG src={UploadIcon} className="edit-media-wrapper__btn-icon mr-2"/>}
                    {uploadText} file
                </button>}
                {editMode && <button type="button" onClick={() => resetFile(setFieldValue)}
                        className="btn btn-outline mr-3">Reset</button>}
                {editMode && values.multimedia && values.gif && !errors.gif && !errors.multimedia &&
                    <button type="button" className="btn btn-primary" onClick={() => updateFile(values,errors)}>Update</button>}
            </div>
        </div>}

        <div className="col">
            <div className="form-group">
                <input
                    id="name"
                    placeholder={t(`Enter resource name`)}
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={(e) => {handleBlur(e); onBlurSave(errors.name,values.name,"name")}}
                    className={`form-control input-primary d-block ${name_invalid_class}`}/>
                {errors.name && touched.name && (
                    <div className="input-feedback">{errors.name}</div>
                )}
            </div>
        </div>
    </div>
    )
}

export default EditAnimationResource;