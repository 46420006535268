//Setting file for congfigure store
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../store/index';

export default function configureStore(initialState) {
    const store = createStore(rootReducer, initialState, compose(
            applyMiddleware(thunk)
        ),
    );

    return store;
}
