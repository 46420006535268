import React from 'react';
import {
    resourceStatisticTypes,
    resourceTypes, languages,
    mediaTypes,
} from "../../../common";
import {getValidationRules, setResourceSchema} from "../../../store/validations";
import {Formik} from "formik";
import CloseIcon from "../../../images/close-icon.svg";
import {
    addResourceImage,
    addResourceImageXHR, addTagsToResource,
    createResource, deleteTagsFromResource, getResourceAnalytics,
    getResources, getResourcesDegreeImages,
    getResourcesImages, getNonDegreeResources, setResourceLanguage, setCreateResourcesMode, setCurrentResource, showFullSizeGallery,
    updateResourceData
} from "../../../store/resources";
import {setLoading, setMainContentLanguage} from "../../../store/user";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import MediaPlayer from "../../player/MediaPlayer";
// import SmallRoundButton from "../../buttons/SmallRoundButton";
// import InfoIcon from "../../../images/info-icon.svg";
import ReactSVG from "react-svg";
import ResourceStatisticItem from "./ResourceStatisticItem";
import CustomScrollBar from "../../CustomScrollBar";
import ResourceDegreeContentView from "./ResourceDegreeContentView";
import _ from "lodash";
import {Trans, withTranslation} from "react-i18next";
import EditTextResource from "./EditTextResource";
import EditUrlResource from './EditUrlResource';
import EditAnimationResource from "./EditAnimationResource";
import EditOtherKindOfResource from "./EditOtherKindOfResource";
import EditModelResource from "./EditModelResource";
import LanguageSelect from "../../LanguageSelect";
import {Progress} from "reactstrap";

class EditResource extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            preview_url: '',
            file:'',
            media_file:'',
            locale: '',
            image_url_invalid: false,
            name_invalid: false,
            tags:[],
            pending: false,
            text_content:'',
            type_id: undefined,
            editMode:false,
            recordMode:false,
            gif: undefined,
            multimedia: undefined,
            language: 'en',
            selected_language: 'en',
        };
    }

    componentDidMount() {
        this.props.setResourceLanguage(this.props.main_content_language);
        if(this.props.resource_item){
            this.props.getResourceAnalytics(this.props.resource_item.id)
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        let {translations,files} = nextProps.resource_item ? nextProps.resource_item : {};
        let selected_language = nextProps.resource_language;
        let language = nextProps.main_content_language;
        if(selected_language !== nextProps.main_content_language){
            language = selected_language
        }
        if(language !== prevState.language){
            update.language = language
        }
        if(translations){
            if(translations[language]){
                update.name = translations[language].name ? translations[language].name : '';
                update.text_content = translations[language].text_content ? translations[language].text_content : '';

            }else {
                update.name = '';
                update.text_content = '';
            }
        }
        if(files){
            if(files[language]){
                update.preview_url = files[language].preview_url ? files[language].preview_url : '';
                update.media_file = files[language].media_url ? files[language].media_url : '';
                update.filename = files[language].filename ? files[language].filename : '';
            }else {
                update.preview_url = '';
                update.media_file = '';
                update.filename = '';
            }
        }
        if(nextProps.resource_item){
            let {type_id} = nextProps.resource_item;
            update.type_id = type_id;
        }
        return Object.keys(update).length ? update : null;
    }

    componentWillUnmount() {
        this.closeEditResource()
    }


    renderResourceMedia = () => {
        const {preview_url, media_file} = this.state;
        let types = (typeof this.props.resource_item.type_id === 'string') ? mediaTypes : resourceTypes;
        let {type_id} = this.props.resource_item;
        if(type_id === types.image){
            if(!preview_url) return null;
        }else if (!media_file) return null;

        switch (type_id) {
            case types.image: return <div className="square-image-wrap"><img src={preview_url} className="resource-item-view__image" alt=""/></div>;
            case types.video: return <MediaPlayer media_url={media_file}/>;
            case types.audio: return <MediaPlayer media_url={media_file}/>;
            case types.degreeImage: return <ResourceDegreeContentView degreeContent={<a-sky src={media_file} className="modal-resource__degree-image"/>}/> ;
            case types.degreeVideo: return <ResourceDegreeContentView degreeContent={<a-videosphere src={media_file} className="modal-resource__video"/>}/> ;
            default: return null;
        }
    }

    closeEditResource = () => {
        this.props.setCurrentResource(undefined);
    }

    onBlurSave = (error,value,name) => {
        if(!error){
            let language = this.props.resource_language;
            let {resource_item} = this.props;
            if (this.state[name] === value) return;
            let resource_updated = {
                    translations: {
                        ...resource_item.translations,
                        [language]:{
                            ...resource_item.translations[language],
                            [name]:value
                        }
                    }
                },
                id = resource_item.id;
            if(this.state[name] === value) return;
            this.props.updateResourceData(id,resource_updated,name,language).then(() => {
                // this.props.getNonDegreeResources()
            })
        }
    }

    resetPlayer = (player) => {
        this.player = player;
    }

    updateFile = (values,errors) => {
        this.setState({pending:true})
        let file,
            isAnimation = this.state.type_id === resourceTypes.animations,
            isText = this.state.type_id === resourceTypes.text;
        if(isAnimation){
            file = values.gif;
        }else if(isText){
            file = values.file_text;

        }else {
            file = values.file;
        }
        this.props.addResourceImageXHR(file, this.props.resource_item.id,false,this.state.type_id, this.state.language)
            .then((res) => {
                if(isAnimation){
                    this.props.addResourceImageXHR(values.multimedia, this.props.resource_item.id,true,this.state.type_id,this.state.language)
                        .then((res) => {
                            this.props.getResources().then(() => {
                                this.setState({editMode:false,recordMode:false})
                                let created_resource =_.find( this.props.resources, ['id', this.props.resource_item.id]);
                                this.props.setCurrentResource(created_resource)
                                this.setState({pending:false})

                            })
                            // this.props.getNonDegreeResources()
                            this.props.getResourcesImages()
                            this.props.getResourcesDegreeImages()
                        })
                }else {
                    this.props.getResources().then(() => {
                        this.setState({editMode:false,recordMode:false})
                        let created_resource =_.find( this.props.resources, ['id', this.props.resource_item.id]);
                        this.props.setCurrentResource(created_resource)
                        this.setState({pending:false})

                    })
                    // this.props.getNonDegreeResources()
                    this.props.getResourcesImages()
                    this.props.getResourcesDegreeImages()
                }

            })
    }

    resetFile = (setFieldValue) => {
        this.setState({editMode:false,recordMode:false})
        setFieldValue('file','')
    }

    toggleEditMode = () => {
        this.setState({editMode:!this.state.editMode})
    }

    toggleRecordMode = () => {
        this.setState({recordMode:!this.state.recordMode})
    }

    selectResourceLanguage = (name,value) => {
        this.props.setResourceLanguage(_.find(languages,['id',value]).short_name)
    }

    render() {
        let {dark_mode,t,progress_percent} = this.props;
        let {type_id,editMode,language,pending} = this.state;
        let overflowOptions = {
            overflowBehavior: { x: "hidden", y: "scroll" },
          },
          isText = type_id === resourceTypes.text,
          isUrl = type_id === resourceTypes.url,
          isAnimation = type_id === resourceTypes.animations,
          isModel =
            type_id === resourceTypes.model || type_id === resourceTypes.zip;

        return(
            <div className="element-half-section">
                <div className="row subheader">
                    <div className="col-md-10 col-10 d-flex align-items-center">
                        <span className="subheader__title"><Trans>Edit Resource</Trans></span>
                        {/*<SmallRoundButton icon={InfoIcon} classes={'ml-4 btn-small-round_dark'}/>*/}
                    </div>
                    <div className="col-md-2 col-2 d-flex justify-content-end">
                        <button type="button" className="btn btn-close" onClick={this.closeEditResource}>
                            <ReactSVG className="btn-small-round__icon" src={CloseIcon}/>
                        </button>
                    </div>
                </div>
                {pending && progress_percent > 0  &&
                <div><p className="title progress-text mb-4"><Trans>Please wait, resource is uploading...</Trans></p>
                    <Progress animated value={progress_percent} />
                </div>}
                {!pending && <CustomScrollBar options={overflowOptions}>
                    <div>
                        <Formik
                            enableReinitialize
                            initialValues={this.state}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    this.handleSubmit(values)
                                    setSubmitting(false)
                                }, 500);
                            }}
                            validationSchema={setResourceSchema(false,isText,this.props.validation_rules,false,isAnimation,type_id, editMode,t)}
                        >
                            {props => {
                                const {
                                    touched,
                                    errors,
                                    handleSubmit,
                                    setFieldValue
                                } = props;
                                let name_invalid_class = errors.name && touched.name ? 'invalid' : '';
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <LanguageSelect data={languages} disabled={false}
                                                        onBlur={languages} setFieldValue={(name,value) => this.selectResourceLanguage(name,value)}
                                                        name={'language'} isDark hideHelp classes="mb-3"/>
                                        {isText && <EditTextResource {...props} {...this.props} editMode={editMode} t={t}
                                                           name_invalid_class={name_invalid_class} toggleEditMode={this.toggleEditMode} resetFile={this.resetFile}
                                                           updateFile={this.updateFile} onBlurSave={this.onBlurSave} setFieldValue={setFieldValue} language={language}/>}
                                        {isUrl && <EditUrlResource {...props} {...this.props} editMode={editMode} t={t}
                                                           name_invalid_class={name_invalid_class} toggleEditMode={this.toggleEditMode} resetFile={this.resetFile}
                                                           updateFile={this.updateFile} onBlurSave={this.onBlurSave} setFieldValue={setFieldValue} language={language}/>}

                                        {isAnimation && <EditAnimationResource {...props} {...this.props} editMode={editMode} t={t}
                                                           name_invalid_class={name_invalid_class} toggleEditMode={this.toggleEditMode} resetFile={this.resetFile}
                                                           updateFile={this.updateFile} onBlurSave={this.onBlurSave} renderResourceMedia={this.renderResourceMedia} {...this.state}
                                                                               language={language}/>}

                                        {isModel && <EditModelResource {...props} {...this.props} editMode={editMode} t={t}
                                                           name_invalid_class={name_invalid_class} toggleEditMode={this.toggleEditMode} resetFile={this.resetFile}
                                                           updateFile={this.updateFile} onBlurSave={this.onBlurSave} {...this.state} language={language}/>}

                                        {!isAnimation && !isText && !isModel && !isUrl && <EditOtherKindOfResource {...props} {...this.props} editMode={editMode} t={t}
                                                           name_invalid_class={name_invalid_class} toggleEditMode={this.toggleEditMode} toggleRecordMode={this.toggleRecordMode} resetFile={this.resetFile}
                                                           updateFile={this.updateFile} onBlurSave={this.onBlurSave} renderResourceMedia={this.renderResourceMedia} {...this.state} resetPlayer={this.resetPlayer} language={language}/>}
                                    </form>
                                );
                            }}
                        </Formik>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 d-flex">
                                <ResourceStatisticItem analytics={this.props.analytics} type={resourceStatisticTypes.daily} dark_mode={dark_mode}/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 d-flex">
                                <ResourceStatisticItem analytics={this.props.analytics} type={resourceStatisticTypes.hourly} dark_mode={dark_mode}/>
                            </div>
                        </div>
                    </div>
                </CustomScrollBar>}

            </div>
        );
    }
}


const mapStateToProps = state => ({
    resource_item: state.resources.resource_item,
    analytics: state.resources.analytics,
    resources: state.resources.resources,
    show_resource_modal: state.resources.show_resource_modal,
    progress_percent: state.resources.progress_percent,
    create_resources_mode: state.resources.create_resources_mode,
    validation_rules: state.validations,
    dark_mode: state.user.dark_mode,
    main_content_language: state.user.main_content_language,
    resource_language: state.resources.resource_language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    updateResourceData,
    getResources,
    getResourcesImages,
    createResource,
    addResourceImage,
    addResourceImageXHR,
    setCurrentResource,
    setLoading,
    setCreateResourcesMode,
    getValidationRules,
    addTagsToResource,
    deleteTagsFromResource,
    getResourcesDegreeImages,
    getNonDegreeResources,
    getResourceAnalytics,
    showFullSizeGallery,
    setResourceLanguage,
    setMainContentLanguage
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(EditResource));
