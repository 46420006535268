import React from 'react';
import {Dropdown, DropdownToggle,} from 'reactstrap';
import ReactSVG from "react-svg";
import QuizIcon from "../../../../images/quiz-icon.svg";
import ElementQuiz from "./ElementQuiz";
import {Trans} from "react-i18next";

class ElementQuizDropdown extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
        }
        this.dropdown = React.createRef();
    }

    toggle = (e) => {
        if (this.state.dropdownOpen && (e.target === this.dropdown.current || !this.dropdown.current.contains(e.target))) return;
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    close = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        return (
            <div ref={this.dropdown}>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="filter-dropdown statistic-dropdown">
                    <DropdownToggle className={`btn btn-small-round`}>
                        <ReactSVG className="btn-small-round__icon" src={QuizIcon}/>
                        <span className="btn-small-round__text pl-1 d-sm-inline-block d-none"><Trans>Gamification</Trans></span>
                    </DropdownToggle>
                    <ElementQuiz handleClose={this.close}/>
                </Dropdown>
            </div>
        );
    }



}


export default ElementQuizDropdown;
