import React from 'react';

function getName(resource_item,language) {
    let {translations} = resource_item;
    let name;
    if(translations){
        if(translations[language]){
            name = translations[language].name
        }else {
            Object.entries(translations).forEach(([k, v]) => {
                if(k !== language && v.name && !name){
                    name = v.name;
                }
            })
        }
    }
    return name;
}


function ResourceCardName(props) {
    let {resource_item,main_content_language} = props;
    return(
        <span className="resource__item-name">{getName(resource_item,main_content_language)}</span>
    )
}

export default ResourceCardName;