import React, {Fragment} from "react";
import Button from "../../../components/buttons/Button";
import {sidebarTypes} from "../../../common";
import {jobStatuses} from "./JobCard/JobCard";
import {Trans} from 'react-i18next';

function OpenJobFooter(props) {
    let $openStatus = jobStatuses.find(item => item.name === 'Open for bidding').id;
    const onDelete = () => {
        props.deleteDraftJob(props.job_id)
        props.setSidebar(sidebarTypes.none)
    }
    const onPublish = () => {
        props.updateJobStatus($openStatus,props.job_id)
        props.setPending(false)
    }
    return(
        <Fragment>
            <button type="button" className="btn btn-primary btn-decline w-100 mr-3"
            onClick={onDelete}><Trans>Delete</Trans></button>
            <Button type="button"
                    onClick={onPublish}
                    className="btn btn-primary w-100"
                    disabled={props.formik.isSubmitting}
                    loading={props.pending || props.formik.isSubmitting}><Trans>Publish</Trans></Button>
        </Fragment>
    )
}

export default OpenJobFooter;
