import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import WorkHistory from "../WorkHistory";
import TopSection from "../top-section/TopSection";
import ReactSVG from "react-svg";
import CloseIcon from "../../../images/close-icon.svg";
import Portfolio from "./contractor-profile/Portfolio";
import VerifiedResources from "./contractor-profile/VerifiedResources";
import moment from "moment";
import Avatar from "../../../components/Avatar";

function ContractorProfile (props) {
    useEffect(() => {
        props.getContractorInfo(props.match.params.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    if(!props.contractor_item) return null;
    let {languages,first_name,last_name} = props.contractor_item;
    return(
        <>
            <TopSection {...props}/>
            <div className={`contractor-profile`}>
                <button type="button" className="btn btn-close"
                        onClick={() => props.history.goBack()}>
                    <ReactSVG src={CloseIcon} />
                </button>
                <div className="contractor-info contractor-profile__header">
                    <Avatar {...props} readOnly/>
                    <div className="contractor-info__details w-100">
                        <div className="row">
                            <div className="col-12">
                                <div className="contractor-info__name">{first_name} {last_name}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col contractor-profile__col">
                                <VerifiedResources verified_resource_types={props.contractor_item.verified_resource_types}/>
                            </div>
                            <div className="col contractor-profile__col">
                                <div className="contractor-profile__title">Availability</div>
                                <div className="contractor-profile__text">
                                    {props.contractor_item.availability}
                                </div>
                            </div>
                            <div className="col contractor-profile__col">
                                <div className="contractor-profile__title">Location</div>
                                <div className="contractor-profile__text">
                                    {props.contractor_item.address_line}
                                </div>
                            </div>
                            <div className="col contractor-profile__col">
                                <div className="contractor-profile__title">Member since</div>
                                <div className="contractor-profile__text">
                                    {moment(props.contractor_item.created_at).format('MMMM DD, YYYY')}
                                </div>
                            </div>
                            <div className="col contractor-profile__col">
                                <div className="contractor-profile__title">Timezone</div>
                                <div className="contractor-profile__text">
                                    {props.contractor_item.timezone}
                                </div>
                            </div>
                            <div className="col contractor-profile__col">
                                <div className="contractor-profile__title">Languages</div>
                                <div className="d-flex">
                                    {languages && languages.map((lng) => <span className="contractor-profile__text mr-1"> {lng} </span>)}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="separator"/>
                <div className="contractor-profile__body">
                    <div className="contractor-profile__description mb-3">
                        {props.contractor_item.description}
                    </div>
                    <ul className="nav nav-pills btn-group btn-group-toggle mb-4" id="work-historyTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link btn btn-group-toggle__item active" id="portfolio-tab"
                               data-toggle="tab" href="#portfolio"
                               role="tab" aria-controls="portfolio" aria-selected="true">Portfolio</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link btn btn-group-toggle__item" id="work-history-tab"
                               data-toggle="tab" href="#work-history"
                               role="tab" aria-controls="work-history" aria-selected="false">Work history</a>
                        </li>
                    </ul>
                    <div className="tab-content d-flex flex-column flex-grow-1">
                        <div className="tab-pane active flex-grow-1" id="portfolio" role="tabpanel"
                             aria-labelledby="portfolio-tab">
                            <Portfolio {...props}/>
                        </div>
                        <div className="tab-pane flex-grow-1" id="work-history" role="tabpanel"
                             aria-labelledby="work-history-tab">
                            <WorkHistory {...props}/>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default withRouter(ContractorProfile)
