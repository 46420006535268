//Component for uploading files. Using in resource modal and in identifier data page

import React, { Component } from "react";
import {isImage, notify, validateFileFormat} from "../common";
import {withTranslation} from "react-i18next";


class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            preview_url: '',
            media_file: '',
            filename:'',
            image_url_invalid:false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(nextProps.status === 0){
            update.file = '';
            update.preview_url = '';
            update.media_file = '';
            update.filename = '';
        }
        if(nextProps.progress_percent !== prevState.progress_percent){
            if(nextProps.progress_percent > 0){
                update.file = '';
                update.preview_url = '';
                update.media_file = '';
                update.filename = '';

            }
        }
        return Object.keys(update).length ? update : null;
    }


    handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    preview_url: reader.result,
                    media_file: !isImage(file) ? reader.result : '',
                    filename: file.name
                });
            };
            reader.readAsDataURL(file);
            this.props.setFieldValue(this.props.field.name, file);
            this.props.changeStatus && this.props.changeStatus(1)
        }

        this.setState({
            file: '',
            preview_url: '',
            media_file: '',
            filename:''
        });

        if(!validateFileFormat(file)) return notify(this.props.t('Unsupported File Format'),'warning')

    }

    render() {
        const { errorMessage,classes,placeholder,hidden,t,id,marginClass } = this.props;
        const { onBlur } = this.props.field;
        let {filename} = this.state,
            labelText = filename ? filename : t('Choose File'),
            input_invalid_class = errorMessage ? 'invalid' : '';

        return (
            <div className={`modal-resource__image-uploader flex-grow-1 ${input_invalid_class} ${marginClass ? marginClass : 'mb-3'} ${hidden ? 'd-none' :''}`}>
                <div className="form-group custom-file w-auto">
                    <input
                        id={id}
                        placeholder={placeholder ? placeholder : t(`Enter resource name`)}
                        type="file"
                        onChange={this.handleImageChange}
                        onBlur={onBlur}
                        className={"input-primary custom-file-input"}
                    />
                    <label className={`custom-file-label ${classes}`} htmlFor="file">{labelText}</label>
                </div>
                {errorMessage && (
                    <div className="input-feedback">{errorMessage}</div>
                )}
            </div>
        );
    }
}

export default withTranslation('translations')(FileUploader);
