import React from 'react';
import {Field} from "formik";
import FileUploader from "../../FileUploader";
import ReactSVG from "react-svg";
import UploadIcon from "../../../images/upload-icon.svg";
import Resource3dModelView from "./Resource3dModelView";
import {resourceTypes} from "../../../common";

function EditModelResource(props) {
    let {editMode, setFieldValue, errors, touched, handleBlur, values, t, handleChange, name_invalid_class,
        toggleEditMode, resetFile, updateFile,onBlurSave,showFullSizeGallery,media_file,type_id,preview_url} = props;
    let isModel = type_id === resourceTypes.model;
    let isZipModel = type_id === resourceTypes.zip;
    return(
        <div className="row mb-xl-5 mb-4">
            <div className="col-xl-6 col-md-12">
                <div className="edit-media-wrapper">
                    {!editMode && <div className="fullsize-gallery-wrapper" onClick={() => showFullSizeGallery(true)}>
                        {isModel  && media_file && <Resource3dModelView file={media_file}/>}
                        {isZipModel && preview_url && <div className="square-image-wrap"><img src={preview_url} className="resource-item-view__image" alt=""/></div>}
                    </div>}
                    {editMode && <Field
                        name="file"
                        component={FileUploader}
                        setFieldValue={setFieldValue}
                        errorMessage={errors["file"] ? errors["file"] : undefined}
                        touched={touched["file"]}
                        onBlur={handleBlur}
                    />}
                    {!editMode && <button type="button" onClick={toggleEditMode}
                                          className="btn edit-media-wrapper__btn mt-3">
                        {<ReactSVG src={UploadIcon} className="edit-media-wrapper__btn-icon mr-2"/>}
                        Reupload file
                    </button>}
                    {editMode && <button type="button" onClick={() => resetFile(setFieldValue)}
                                         className="btn btn-outline mr-3">Reset</button>}
                    {editMode && values.file && !errors.file && <button type="button" className="btn btn-primary"
                                         onClick={() => updateFile(values,errors)}>Update</button>}
                </div>
            </div>

            <div className="col">
                <div className="form-group">
                    <input
                        id="name"
                        placeholder={t(`Enter resource name`)}
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={(e) => {handleBlur(e); onBlurSave(errors.name,values.name,"name")}}
                        className={`form-control input-primary d-block ${name_invalid_class}`}/>
                    {errors.name && touched.name && (
                        <div className="input-feedback">{errors.name}</div>
                    )}
                </div>
                {/*{!create_resources_mode && <TagsInput tags={this.state.tags || []} handleChange={this.handleTagsChange}/>}*/}
            </div>
        </div>
    )
}

export default EditModelResource;