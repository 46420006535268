import React from "react";
// import FullsizeWrapper from "../../components/resource/EditResource/FullsizeWrapper";
import ReactSVG from "react-svg";
import CloseIcon from "../../images/close-icon.svg";
import MediaPlayer from "../../components/player/MediaPlayer";

function ImageFile(props){
    //todo unify FullsizeWrapper
    return(
        // <FullsizeWrapper showFullSizeGallery={props.showFullSizeGallery}>
            <div className={`${props.classes}`}>
                <img src={props.url} alt="" className="resource-item-image"/>
            </div>
        // </FullsizeWrapper>

    )
}



function RenderFile(props) {
    let {mimetype,url,id} = props.data;
    let type = mimetype && mimetype.split('/')[0];
    let file;
    switch (type){
        case 'image': file = <ImageFile url={url} classes="image-file" showFullSizeGallery={props.showFullSizeGallery}/>; break;
        case 'video': file = <MediaPlayer {...props} media_url={url}/>; break;
        case 'audio': file = <MediaPlayer {...props} media_url={url}/>; break;
        default: file = 'test';
    }

    return (
        <div className="file-item">
            {props.deleteFile && !props.readOnly && <button type="button" className="btn btn-close file-item__delete"
                     onClick={() => props.deleteFile(id)}>
                <ReactSVG src={CloseIcon}/>
            </button>}
            {file}
        </div>
    )

}

export default RenderFile;
