//resource card component with preview image, name, date of creation, extension, delete and bind icon.
//By click will show resource modal.
//By click on delete button if the resource is pared will show confirm modal if not will resource will deleted.
//By click on bind icon the resource will pair to element.

import React from 'react'
import checkIcon from "../../../images/check-icon.svg";
import ViewIcon from "../../../images/view-mode-icon.svg";
import {
    bindSelectedResources,
    deleteSingledResource,
    getResources,
    getNonDegreeResources, getElementResources, setCreateResourcesMode,
    setClickedPairedResourceId,
    setCurrentResource,
    setDeleteResourceModalStatus, setDeleteSingleResourceModalStatus, unbindSelectedResources
} from "../../../store/resources";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import ReactSVG from "react-svg";
import DeleteIcon from "../../../images/delete-icon.svg";
import BindIcon from "../../../images/bind-icon.svg";
import UnBindIcon from "../../../images/unbind-icon.svg";
import _ from "lodash";
import { getLimit, languages, packageOptions} from "../../../common";
import {
    setCreateIdentifierMode,
    setCurrenteElement,
    getElementItemById,
    setIdentifier,
    linkResourceToSubElement, unlinkResourceFromSubElement, getSubElements
} from "../../../store/elements";
import ConfirmSingleResourceDeleteModal from "../../modals/ConfirmSingleResourceDeleteModal";
import {toggleUpgradePlanModal} from "../../../store/user";
import {Trans, withTranslation} from "react-i18next";
import ResourceCardPreviewImage from "./ResourceCardPreviewImage";
import ResourceCardName from "./ResourceCardName";
import ResourceCardFlags from "./ResourceCardFlags";


class ResourceCard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            paired: false,
            checkMode: true,
            pending:false,
            language: 'en',
            name:'',
            preview_url:'',
            link_resources_to_sub_elements_mode: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let {translations,files} = nextProps.resource_item ? nextProps.resource_item : {};
        let update = {};
        if(nextProps.bindMode){
            update.paired = this.checkPairedStatus()
        }else update.paired = false;

        let language = nextProps.main_content_language ? nextProps.main_content_language : 'en';
        let newLangId = _.find(languages,['short_name',language]).id;
        if(newLangId !== prevState.language){
            update.language = newLangId
        }
        if(translations){
            if(translations[language]){
                update.name = translations[language].name ? translations[language].name : '';
                update.preview_url = translations[language].preview_url ? translations[language].preview_url : '';
            }else {
                update.name = '';
                update.description = '';
                update.preview_url='';
            }
        }
        if(files){
            if(files[language]){
                update.preview_url = files[language].preview_url ? files[language].preview_url : '';
            }else {
                update.preview_url = '';
            }
        }
        if(!nextProps.delete_resources_mode){
            update.checked = false
        }

        if(nextProps.link_resources_to_sub_elements_mode !== prevState.link_resources_to_sub_elements_mode){
            update.link_resources_to_sub_elements_mode = nextProps.link_resources_to_sub_elements_mode
        }

        return Object.keys(update).length ? update : null;
    }

    handleCheck = () => {
        const {checked} = this.state;
        const {resource_item} = this.props;
        this.setState({checked: !checked})
        this.props.callbackCheck && this.props.callbackCheck(resource_item.id,!checked);
    }

    checkPairedStatus() {
        const {resource_item,element_item} = this.props;
        let paired = false;
        if(!element_item) return false;
        element_item.paired_resources && element_item.paired_resources.forEach((id) => {
            if (resource_item.id === id) paired = true;
        });
        return paired;
    }

    handleResourceClick = () => {
        this.props.setCurrentResource(undefined);
        const {resource_item} = this.props;
        if(!this.props.hotspotMode){
            this.props.setIdentifier(undefined)
            this.props.setCreateIdentifierMode(false)
            this.props.setCreateResourcesMode(false)
            setTimeout(() => this.props.setCurrentResource(resource_item))
        }else {
            this.props.handleSelectForHotspot(resource_item)
        }
    }

    deleteResource = () => {
        this.props.setCurrentResource(undefined);
        this.setState({pending:true})
        this.props.deleteSingledResource(this.props.resource_item.id).then(() => {
            this.props.getElementItemById(this.props.element_item.id).then(() => {
                this.setState({pending:false})
                this.props.deleteSingleResourceFromState(this.props.resource_item.id)
            })
        })
    }

    handleDeleteButton = (e) => {
        this.props.setClickedPairedResourceId(this.props.resource_item.id)
        this.props.setDeleteSingleResourceModalStatus(true)
    };

    handleBindResource = () => {
        const {element_item} = this.props;

        let {business_package} = this.props.user_info;
        let business_options = business_package.options;
        let maxResourcesPerElement = getLimit(business_options,packageOptions.resource),
            canBeAttached = element_item.paired_resources.length < maxResourcesPerElement || this.checkPairedStatus();

        let pairedResources = this.props.element_item.paired_resources || [];
        if(_.includes(pairedResources,this.props.resource_item.id)){
            this.setState({paired: false, pending:true })
            _.pull(pairedResources, this.props.resource_item.id);
            this.props.setCurrenteElement({...this.props.element_item,paired_resources:pairedResources})
            this.props.unbindSelectedResources(this.props.element_item.id,[this.props.resource_item.id]).then((res) => {
                this.props.getElementItemById(this.props.element_item.id)
                this.setState({pending:false})
            })
        }else {
            if(canBeAttached){
                this.setState({paired: true, pending:true})
                pairedResources.push(this.props.resource_item.id)
                this.props.setCurrenteElement({...this.props.element_item,paired_resources:pairedResources})
                this.props.bindSelectedResources(this.props.element_item.id,[this.props.resource_item.id]).then((res) => {
                    this.props.getElementItemById(this.props.element_item.id)
                    this.setState({pending:false})
                })
            }else this.props.toggleUpgradePlanModal(true)
        }
    }

    handleLinkToSubElement = () => {
        let {sub_element_id,sub_elements} = this.props;
        let sub_element = _.find(sub_elements, ['id',sub_element_id]);
        let pairedResources = sub_element.resources || [];

        if(pairedResources && _.find(pairedResources,['id',this.props.resource_item.id])){
            this.setState({pending:true})
            _.remove(pairedResources, resource => resource.id === this.props.resource_item.id);
            this.props.unlinkResourceFromSubElement(this.props.sub_element_id,this.props.resource_item.id).then(() => {
                this.props.getSubElements(this.props.element_item.id)
            })
            this.setState({pending:false})
        }else {
            this.setState({pending:true})
            pairedResources.push({id: this.props.resource_item.id})
            this.props.linkResourceToSubElement(this.props.sub_element_id,[this.props.resource_item.id]).then(() => {
                this.props.getSubElements(this.props.element_item.id)
            })
            this.setState({pending:false})
        }
    }


    renderLinkButton(){
        const {element_item} = this.props;
        if(!element_item) return;
        const {pending} = this.state;
        let pairedResources = this.props.element_item.paired_resources || [];

        if(this.checkPairedStatus()){
            return(
                <button type="button" className="btn resource__item-btn-link resource__item-btn-linked" onClick={this.handleBindResource}
                        disabled={pending}>
                    <ReactSVG className="svg-icon-wrap svg-icon-wrap__dark" src={UnBindIcon} alt=""/>
                    <Trans>Linked</Trans>
                </button>
            )
        }else{
            return (
                <button type="button" className="btn resource__item-btn-link" onClick={this.handleBindResource}
                        disabled={pending || pairedResources.length > 5}>
                    <ReactSVG className="svg-icon-wrap" src={BindIcon} alt=""/>
                    <Trans>Link</Trans>
                </button>
            )
        }
    }

    checkSubLinkedStatus(){
        let {sub_element_id,sub_elements} = this.props;
        let sub_element = _.find(sub_elements, ['id',sub_element_id]);
        let pairedResources = sub_element ? sub_element.resources : [];
        return pairedResources &&_.find(pairedResources,['id',this.props.resource_item.id])
    }

    renderLinkToSubElementButton(){
        const {pending} = this.state;
        if(this.checkSubLinkedStatus()){
            return(
                <button type="button" className="btn resource__item-btn-link resource__item-btn-linked" onClick={this.handleLinkToSubElement}
                        disabled={pending}>
                    <ReactSVG className="svg-icon-wrap svg-icon-wrap__dark" src={UnBindIcon} alt=""/>
                    <Trans>Sub linked</Trans>
                </button>
            )
        }else{
            return (
                <button type="button" className="btn resource__item-btn-link" onClick={this.handleLinkToSubElement}
                        disabled={pending}>
                    <ReactSVG className="svg-icon-wrap" src={BindIcon} alt=""/>
                    <Trans>Sub link</Trans>
                </button>
            )
        }
    }

    render() {
        const {resource_item,bindMode,delete_resources_mode,noMargin,selectedForHotspot,
            clicked_paired_resource_id,element_item,t,sub_element,link_resources_to_sub_elements_mode,main_content_language} = this.props;
        const {checked,pending} = this.state;
        let checkedClass = checked && delete_resources_mode ? "checked" : '';
        let $noMarginClass = noMargin ? "m-0" : '';
        let selectedForHotspotClass = selectedForHotspot && (selectedForHotspot.id === resource_item.id) ? 'checked' : '';
        let highlitedSubLinkedClass = this.checkSubLinkedStatus() ? 'resource__item_highlighted' : '';

        return (
          <div
            className={`resource__item ${checkedClass} ${selectedForHotspotClass} ${$noMarginClass} ${highlitedSubLinkedClass}`}
          >
            <div
              className="resource__item-click-area"
              onClick={this.handleResourceClick}
            />
            <ResourceCardPreviewImage
              resource_item={resource_item}
              main_content_language={main_content_language}
            />
            <div className="resource__item-content">
              <ResourceCardName
                resource_item={resource_item}
                main_content_language={main_content_language}
              />
              {
                <div className="resource__item-views">
                  <ReactSVG
                    className="resource__item-views__icon"
                    src={ViewIcon}
                    alt=""
                  />
                  <span>{`${resource_item.views} ${t("views")}`}</span>
                </div>
              }
              <div className="resource__item-buttons">
                {!link_resources_to_sub_elements_mode &&
                  this.renderLinkButton()}
                {!link_resources_to_sub_elements_mode && element_item && (
                  <button
                    type="button"
                    className="btn resource_item-btn-delete"
                    onClick={this.handleDeleteButton}
                    disabled={pending || element_item.active}
                  >
                    <ReactSVG
                      className="svg-icon-wrap"
                      src={DeleteIcon}
                      alt=""
                    />{" "}
                    <Trans>Delete</Trans>
                  </button>
                )}
                {link_resources_to_sub_elements_mode &&
                  sub_element &&
                  this.renderLinkToSubElementButton()}
              </div>
            </div>
            {(bindMode || delete_resources_mode || selectedForHotspotClass) && (
              <div className="item-checkbox-wrap" onClick={this.handleCheck}>
                <span className="item-checkbox">
                  {(checked || selectedForHotspotClass) && (
                    <img
                      className="item-checkbox__status-icon"
                      src={checkIcon}
                      alt=""
                    />
                  )}
                </span>
              </div>
            )}
            {clicked_paired_resource_id === resource_item.id && (
              <ConfirmSingleResourceDeleteModal
                handleDelete={this.deleteResource}
                paired={this.checkPairedStatus()}
              />
            )}
            <ResourceCardFlags resource_item={resource_item} />
          </div>
        );
    }
}

const mapStateToProps = state => ({
    delete_resources_mode: state.resources.delete_resources_mode,
    clicked_paired_resource_id: state.resources.clicked_paired_resource_id,
    element_item: state.elements.element_item,
    validation_rules: state.validations,
    user_info: state.user.user_info,
    main_content_language: state.user.main_content_language,
    link_resources_to_sub_elements_mode: state.elements.link_resources_to_sub_elements_mode,
    sub_element_id: state.elements.sub_element_id,
    sub_element: state.elements.sub_element,
    sub_elements: state.elements.sub_elements,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCurrentResource,
    deleteSingledResource,
    getResources,
    bindSelectedResources,
    unbindSelectedResources,
    getElementItemById,
    setCurrenteElement,
    getNonDegreeResources,
    setDeleteSingleResourceModalStatus,
    setClickedPairedResourceId,
    getElementResources,
    setDeleteResourceModalStatus,
    setIdentifier,
    setCreateIdentifierMode,
    setCreateResourcesMode,
    toggleUpgradePlanModal,
    linkResourceToSubElement,
    unlinkResourceFromSubElement,
    getSubElements
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ResourceCard));

