//Modal for confirmation of deleting paired resource,
//showing by click on delete button on resource card if the resource is paired to the element

import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import {
    createResource,
    setDeleteSingleResourceModalStatus,
    setCurrentResource,
    updateResourceData
} from "../../store/resources";
import {setLoading} from "../../store/user";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';

class ConfirmSingleResourceDeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };

    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(nextProps.show_delete_single_resource_modal !== prevState.show){
            update.show = nextProps.show_delete_single_resource_modal;
        }
        return Object.keys(update).length ? update : null;
    }


    toggle = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
        this.props.setDeleteSingleResourceModalStatus(!this.props.show_delete_single_resource_modal)
    }

    handleDelete = () => {
        this.props.handleDelete()
        this.setState({show:false})
        this.props.setDeleteSingleResourceModalStatus(false)
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.show} toggle={this.toggle} className="modal-resource-delete" centered>
                    <ModalHeader toggle={this.toggle}>{this.props.paired ? `Are you sure you want to delete paired resource?` : `Are you sure you want to delete resource?`}</ModalHeader>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleDelete}>Yes</Button>
                        <Button color="secondary" onClick={this.toggle}>No</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    resource_item: state.resources.resource_item,
    show_delete_single_resource_modal: state.resources.show_delete_single_resource_modal
});

const mapDispatchToProps = dispatch => bindActionCreators({
    updateResourceData,
    createResource,
    setCurrentResource,
    setLoading,
    setDeleteSingleResourceModalStatus
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSingleResourceDeleteModal);