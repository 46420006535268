//Search location with google map component
//Was removed, now not using
import React, { Component, Fragment } from 'react';
import isEmpty from 'lodash.isempty';

import Marker from './Marker';

import GoogleMap from './GoogleMap';
import AutoComplete from './AutoCompleteLocation';
import {connect} from "react-redux";


class Autocomplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mapApiLoaded: false,
            mapInstance: null,
            mapApi: null,
            places: [],
            isSearchFocused:false
        };
    }

    componentDidMount() {
        let map = document.getElementById('google-map');
        if(map) {
            map.addEventListener('mousedown', (e) => {
                e.preventDefault()
            })
        }

    }
    componentWillUnmount(){
        let map = document.getElementById('google-map');
        if(map){
            map.removeEventListener("mousedown",(e) => {
                e.preventDefault()
            });
        }
    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });
    };

    addPlace = (place,location) => {
        this.setState({ places: [place] });
        this.props.changePlace(location)

    };

    handleSearchFocus = (event) => {
        let eventType = event.type;
        let isSearchFocused = eventType === 'focus';
        this.setState({isSearchFocused})
    }

    render() {
        const {
            places, mapApiLoaded, mapInstance, mapApi,
        } = this.state;
        let coordinates = [34.0522, -118.2437];
        let {location} = this.props.experience_item || this.props.item || '';

        if(location){
            if(location.latitude && location.longitude){
                coordinates = [location.latitude, location.longitude]
            }
        }


        return (
            <Fragment>
                <div className="autocomplete-wrap">
                    {mapApiLoaded && (
                        <div className="form-group w-100">
                            <AutoComplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace}
                                          handleSearchFocus={this.handleSearchFocus} onBlurSave={this.props.onBlurSave}
                                          defaultValue={this.props.location ? this.props.location.address_line_1 : ''}/>
                        </div>
                    )}
                    <GoogleMap
                        defaultZoom={10}
                        defaultCenter={coordinates}
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_API_KEY,
                            libraries: ['places', 'geometry'],
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                        isSearchFocused={!this.props.hiddenMap && this.state.isSearchFocused}
                    >
                        {!isEmpty(places) &&
                        places.map(place => (
                            <Marker
                                key={place.id}
                                text={place.name}
                                lat={place.geometry.location.lat()}
                                lng={place.geometry.location.lng()}
                            />
                        ))}
                    </GoogleMap>
                </div>


            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    experience_item: state.experiences.experience_item,
});

export default connect(mapStateToProps, null)(Autocomplete);
