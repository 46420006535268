//main file where include all routes
import React from 'react';
import { Route } from 'react-router-dom';
import './styles/app.scss';
import Feedback from "./routes/Feedback";
import {
  getUserInfo,
  checkToken,
  signOut,
  checkSignedIn,
  getDarkMode,
  getBusinessInfo,
  getMainContentLanguage,
  getCouponCodeForBusiness,
  getDashboardInfo,
} from "./store/user";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomScrollBar from "./components/CustomScrollBar";
import {userRoles} from "./common";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
// import HelpButton from "./components/buttons/HelpButton";
import SidebarMenu from "./components/SidebarMenu";
import Menu from './images/navbar-icons/menu-icon.svg'
import ReactSVG from "react-svg";
import MarketplaceContainer from "./routes/marketplace/MarketplaceContainer";
import UpgradePlanModal from "./components/modals/UpgradePlanModal";
import FullSizeGallery from "./components/FullSizeGallery";
import {setHelpGamificationModalStatus} from "./store/elements";
import {getValidationRules} from "./store/validations";
import {withTranslation} from "react-i18next";
import ErrorBoundaryContainer from "./components/ErrorBoundaryContainer";
import {setError} from "./store/errors";
// import ReactTooltip from "react-tooltip";
import CanvasContainer from "./routes/CanvasContainer";
import UnauthorizedContainer from "./routes/Unauthorized/UnauthorizedContainer";
import {getContractorInfo} from "./store/contractors";
class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sidebarVisible: false,
            language: props.i18n.language,
        }
    }

    componentDidMount() {
        
        if (checkToken()) {
            this.props.getValidationRules('dashboard')
            this.props.getDarkMode()
            this.props.checkSignedIn(checkToken())
            this.props.getUserInfo().then((res) => {
                let isBusinessUser = this.props.user_info.role_id === userRoles.business_user;
                let isAdminUser = this.props.user_info.role_id === userRoles.admin;
                let isContractor = this.props.user_info && this.props.user_info.role_id === userRoles.contractor;
                let hasAccessToCanvas = isBusinessUser || isAdminUser;
                if (hasAccessToCanvas) {
                    this.props.getDashboardInfo();
                    this.props.getBusinessInfo()
                    this.props.getCouponCodeForBusiness()
                    this.props.getMainContentLanguage()
                }else if(isContractor) {
                    this.props.getContractorInfo(this.props.user_info.id)
                }
                if(res.data.locale){
                    this.props.i18n.changeLanguage(res.data.locale);
                }else this.props.i18n.changeLanguage("en");
            })
        }else {
            this.props.signOut()
        }
    }

    toggleSidebar = () => {
        this.setState({
            sidebarVisible: !this.state.sidebarVisible
        })
    }

    render() {
        let {vr_mode, signed_in,user_info,show_fullsize_gallery} = this.props;
        let {language} = this.state;
        let appClass = this.state.sidebarVisible ? 'sidebar-visible' : '';

        let classMobile = this.props.sidebar_toggled ? '' : 'sidebar-menu-toggle-button_mobile';

        //todo make container for common data, make code splitting
        return (
            <ErrorBoundaryContainer>
                <div className={`app app-flex ${appClass}`}>
                    {!vr_mode &&
                    <SidebarMenu sidebarVisible={this.state.sidebarVisible} toggleSidebar={this.toggleSidebar} language={language}
                                 user_info={user_info}/>}
                    {/*{signed_in && <div className={`action-section action-section_left action-section_help`}>*/}
                    {/*    <HelpButton sidebarVisible={this.state.sidebarVisible}/>*/}
                    {/*    <HelpGamificationlModal show={this.props.help_gamification_modal_status}*/}
                    {/*                            handleCloseModal={() => this.props.setHelpGamificationModalStatus(false)} items={slides}/>*/}
                    {/*</div>}*/}
                    <div className={signed_in ? `sidebar-menu-container-offset` : 'sidebar-menu-container'}>
                        {signed_in && <button className={`sidebar-menu-toggle-button ${classMobile}`} onClick={this.toggleSidebar}>
                            <ReactSVG className="sidebar-menu-toggle-button__icon" src={Menu}/>
                        </button>}
                        <CustomScrollBar>
                            <main className={signed_in ? `container-fluid-height print-control-block` : "container-fluid print-control-block"}>
                                <Route component={UnauthorizedContainer} {...this.props}/>
                                <Route component={CanvasContainer} {...this.props}/>
                                <Route component={MarketplaceContainer} />
                                <Route path="/feedback" component={Feedback}/>
                                {/*<Route path="/subscriptions" component={Subscriptions} />*/}
                                {user_info && <UpgradePlanModal user_info={user_info}/>}
                                {/*Floating error container*/}
                                <ToastContainer hideProgressBar position="bottom-center"/>
                            </main>
                        </CustomScrollBar>
                    </div>
                    {show_fullsize_gallery && <FullSizeGallery/>}
                </div>
            </ErrorBoundaryContainer>
        );
    }
}


const mapStateToProps = (state) => ({
  vr_mode: state.experiences.vr_mode,
  signed_in: state.user.signed_in,
  user_info: state.user.user_info,
  business_info: state.user.business_info,
  show_fullsize_gallery: state.resources.show_fullsize_gallery,
  error: state.errors.error,
  error_info: state.errors.error_info,
  sidebar_toggled: state.util.sidebar_toggled,
  dashboard_info: state.user.dashboard_info,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserInfo,
      signOut,
      checkSignedIn,
      getDarkMode,
      setHelpGamificationModalStatus,
      getBusinessInfo,
      getMainContentLanguage,
      getValidationRules,
      getCouponCodeForBusiness,
      setError,
      getContractorInfo,
      getDashboardInfo,
    },
    dispatch
  );

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(App));
