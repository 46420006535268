//Register screen with form
import React  from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import {setCurrentResource} from "../../store/resources";
import {checkExistEmail, getUserInfo, checkToken, signIn, signUpContractor} from "../../store/user";
import {getValidationRules, setSignUpContractorSchema} from "../../store/validations";
import {
  //Field, 
  Formik} from "formik";
import {Link, withRouter} from "react-router-dom";
import {Trans, withTranslation} from "react-i18next";
import bigOverlayImage from '../../images/big-overlay.png';


class SignUpContractor extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            first_name:'',
            last_name:'',
            username:'',
            email:'',
            password:'',
            password_confirmation:'',
            exist_username_error:'',
            exist_email_error:'',
            location:null,
            package_id:1,
        }
    }

    componentDidMount() {
        this.props.getValidationRules('dashboard')
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        return Object.keys(update).length ? update : null;
    }

    handleInputChange= (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangePlace = (location) => {
        this.setState({location})
    }

    handleSignUp = (values) => {
        let {first_name,last_name, package_id, email,password, password_confirmation} = values;
        let data = {first_name,last_name, package_id, email,password, password_confirmation,locale:this.props.i18n.language };
        this.props.checkExistEmail(email).then((emailMessage) => {
            let valid_email = emailMessage.code === 0;
            if(valid_email){
                this.setState({exist_email_error:''})
                this.props.signUpContractor(data)
                    .then((res) => {
                        if(checkToken()) this.props.getUserInfo().then((res) => {
                            if(res.data.locale){
                                this.props.i18n.changeLanguage(res.data.locale);
                            }
                            this.props.history.push('/')
                        })
                    })
            }else {
                this.setState({exist_email_error:emailMessage.description})
            }
        })

    }



    render(){
        let {exist_email_error} = this.state;
        let {t} = this.props;
        let language = this.props.i18n.language;
        return (
          <div className="row auth-wrap">
            <div className="px-5 col-md-4 order-md-1 col-12 order-2">
              <Formik
                initialValues={this.state}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    this.handleSignUp(values);
                  }, 500);
                }}
                validationSchema={setSignUpContractorSchema(
                  this.props.validation_rules,
                  t
                )}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  let email_invalid_class =
                      errors.email && touched.email ? "invalid" : "",
                    first_name_invalid_class =
                      errors.first_name && touched.first_name ? "invalid" : "",
                    last_name_invalid_class =
                      errors.first_name && touched.first_name ? "invalid" : "",
                    pass_invalid_class =
                      errors.password && touched.password ? "invalid" : "",
                    pass_confirm_invalid_class =
                      errors.password_confirmation &&
                      touched.password_confirmation
                        ? "invalid"
                        : "";
                  return (
                    <form onSubmit={handleSubmit} className="form-primary">
                      <div className="flex-grow-1 justify-content-center align-items-center d-flex flex-column w-100">
                        <div className="title-custom line-height-normal theme-aware-color">
                          <Trans>Getting started <br /> with RealMeta's Canvas</Trans>
                        </div>
                        <div className="subtitle">
                          <Trans></Trans>
                        </div>
                        <div className="form-group">
                          {errors.first_name && touched.first_name && (
                            <div className="input-feedback">
                              {errors.first_name}
                            </div>
                          )}
                          <input
                            id="first_name"
                            placeholder={t("First Name")}
                            type="text"
                            value={values.first_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-control input-primary d-block ${first_name_invalid_class}`}
                          />
                        </div>
                        <div className="form-group">
                          {errors.last_name && touched.last_name && (
                            <div className="input-feedback">
                              {errors.last_name}
                            </div>
                          )}
                          <input
                            id="last_name"
                            placeholder={t("Last Name")}
                            type="text"
                            value={values.last_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-control input-primary d-block ${last_name_invalid_class}`}
                          />
                        </div>
                        <div className="form-group">
                          {((errors.email && touched.email) ||
                            exist_email_error) && (
                            <div className="input-feedback">
                              {errors.email ? errors.email : exist_email_error}
                            </div>
                          )}
                          <input
                            id="email"
                            placeholder={t("Enter Email")}
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-control input-primary d-block ${email_invalid_class}`}
                          />
                        </div>
                        <div className="form-group">
                          {errors.password && touched.password && (
                            <div className="input-feedback">
                              {errors.password}
                            </div>
                          )}
                          <input
                            id="password"
                            placeholder={t("Enter Password")}
                            type="password"
                            value={values.password}
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-control input-primary d-block ${pass_invalid_class}`}
                          />
                        </div>
                        <div className="form-group">
                          {errors.password_confirmation &&
                            touched.password_confirmation && (
                              <div className="input-feedback">
                                {errors.password_confirmation}
                              </div>
                            )}
                          <input
                            id="password_confirmation"
                            placeholder={t("Repeat Password")}
                            type="password"
                            value={values.password_confirmation}
                            name="password_confirmation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-control input-primary d-block ${pass_confirm_invalid_class}`}
                          />
                        </div>
                        
                        {/* Add toggle to accept the contractor page as well */}
                        {/* <div className={`form-group ${privacy_invalid_class}`}>
                          <label style={{ color: "black" }}>
                            <Field className="note-text" type="checkbox" name="privacy" /> I have read and
                            agree to RealMeta's &nbsp;
                            <Link
                              to={`/${language}/term-of-service`}
                              className="text-accent d-inline-block"
                              target="_blank"
                            >
                              Term Of Service
                            </Link>{" "}
                            And &nbsp;
                            <Link
                              to={`/${language}/privacy-policy`}
                              className="text-accent d-inline-block"
                              target="_blank"
                            >
                              Privacy Policy
                            </Link>
                            {errors.privacy && touched.privacy && (
                              <div className="input-feedback">{errors.privacy}</div>
                            )}
                          </label>
                        </div> */}

                        <button type="submit" className="sign-in-button sign-in-button-bot-margin">
                          <Trans>Register</Trans>
                        </button>
                        <span className="note-text note-text_middle">
                          <Trans>Already have an account?</Trans>&nbsp;
                          <Link
                            to={`/${language}/login`}
                            className="text-accent d-inline-block"
                          >
                            <Trans>Login now!</Trans> &nbsp;
                          </Link>
                        </span>
                      </div>

                      <p className="copyright mb-0">
                        &copy; 2023 RealMeta <Trans>All Rights Reserved</Trans>
                      </p>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="col-md-8 col-12 order-md-2 order-1">
              <div className="auth__content auth__content_right">
                <div className="flex-grow-1 justify-content-center align-items-center d-flex flex-column">
                  <div className="big-overlay-right">
                    <img src={bigOverlayImage} draggable={false} alt="" />
                  </div>
                  <h1 className="title main-title text-center">
                    <Trans>
                    Let’s create your < br/> account
                    </Trans>
                  </h1>
                  <h2 className="subtitle mb-5 text-center">
                    <Trans>It’s only a few clicks away!</Trans>
                  </h2>
                  {/*<Link to="https://www.3rdilab.com/" target="_blank" className="btn btn-primary btn-outline">Learn more</Link>*/}
                </div>
                {/* <a
                  href="https://realmeta.ca"
                  className="link link-primary bottom-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Visit</Trans> www.realmeta.ca
                </a> */}
              </div>
            </div>
          </div>
        );
    }

}


const mapStateToProps = state => ({
    user_info: state.user.user_info,
    validation_rules: state.validations
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCurrentResource,
    signIn,
    getValidationRules,
    signUpContractor,
    checkExistEmail,
    getUserInfo
},dispatch);

export default withRouter(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SignUpContractor)));

