//Setting file for combine all reducers in one
//If you add new reducer you should add it to this file
import { combineReducers } from 'redux';
import user from './user';
import elements from './elements';
import resources from './resources';
import validations from './validations';
import experiences from './experiences';
import scenes from './scenes';
import hotspots from './hotspots';
import nodes from "./nodes";
import contractors from "./contractors";
import errors from "./errors";
import util from "./util";
import jobs from "./jobs";
import bids from "./bids";

const rootReducer = combineReducers({
    user,
    elements,
    resources,
    validations,
    experiences,
    scenes,
    hotspots,
    nodes,
    contractors,
    errors,
    util,
    jobs,
    bids,
});

export default rootReducer;
