import React, {useEffect} from 'react';
import TopSection from "../top-section/TopSection";
import {sidebarTypes} from "../../../common";
import JobsList from "./JobsList";
import JobEditSidebar from "./JobEditSidebar";
import {Trans} from "react-i18next";

function MyJobs (props) {
    useEffect(() => {
        props.setSidebar(sidebarTypes.none)
        return () => {
            props.clearJobs()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    let classToggle = props.sidebar_toggled || props.sidebar_type === sidebarTypes.none ? '' : 'sidebar-wrap_toggled'
    return(
        <div className={`sidebar-wrap sidebar-wrap_wide ${classToggle}`}>
            <JobEditSidebar {...props}/>
            <div className="marketplace-wrap">
                <TopSection {...props}/>
                <div className="jobs-section">
                    <div className="jobs-section__header">
                        <ul className="nav nav-pills btn-group btn-group-toggle mb-4" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link btn btn-group-toggle__item active" id="open-tab"
                                   data-toggle="tab" href="#open"
                                   role="tab" aria-controls="open" aria-selected="true"><Trans>Open</Trans></a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link btn btn-group-toggle__item" id="all-tab"
                                   data-toggle="tab" href="#all"
                                   role="tab" aria-controls="all" aria-selected="false"><Trans>All</Trans></a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content d-flex flex-column flex-grow-1">
                        <div className="tab-pane active flex-grow-1" id="open" role="tabpanel"
                             aria-labelledby="open-tab">
                            {props.business_info && <JobsList {...props} onlyOpen/>}

                        </div>
                        <div className="tab-pane flex-grow-1" id="all" role="tabpanel"
                             aria-labelledby="all-tab">
                            {props.business_info && <JobsList {...props}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(MyJobs);
