import React, {useState} from 'react';
import FormWrapper from "../../../../components/FormWrapper";
import {useFormik} from "formik";
import {setPortfolioItemSchema} from "../../../../store/validations";
import DescriptionInput from "../../DescriptionInput";
import DropZone from "../../../../components/DropZone";
import DirtyFooter from "../../jobs/DirtyFooter";
import ReactSVG from "react-svg";
import CloseIcon from "../../../../images/close-icon.svg";
import {Trans} from 'react-i18next';
import ErrorBoundaryContainer from "../../../../components/ErrorBoundaryContainer";

function CreatePortfolioItem(props) {
    let formik = useFormik({
        initialValues: {
            description: '',
            file: undefined,
        },
        enableReinitialize: true,
        validationSchema: setPortfolioItemSchema(props.validation_rules,props.t),
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = () => {
        setPending(true)
        let {file,description} = formik.values;
        let data = {
            description,
            file
        }
        props.createContractorPortfolioItem(props.contractor_item.id,data).then(res => {
            setPending(false)
            props.setCreatePortfolioMode(false)
        })
    }
    const [pending, setPending] = useState(false)

    let supported_formats = props.validation_rules && props.validation_rules.PORTFOLIO_ITEM_SUPPORTED_FORMATS;

    const upload = (file) => {
        formik.setFieldValue('file',file)
    }
    return(
        <ErrorBoundaryContainer>
            <FormWrapper formik={formik} formName={'create-portfolio'}>
                <div className="position-relative">
                    <div className="separator"/>
                    <h3 className="title"><Trans>Create portfolio item</Trans></h3>
                    <button type="button" className="btn btn-close"
                            onClick={() => props.setCreatePortfolioMode(false)}>
                        <ReactSVG src={CloseIcon}/>
                    </button>
                    <DescriptionInput formik={formik} {...props} placeholder={'Enter portfolio item description'} title={'Portfolio item description'} rows={3} textareaClass={"mb-4"}/>
                    <DropZone {...props}
                              formik={formik}
                              title={props.t("Upload a file")}
                              acceptedFormats={supported_formats}
                              upload={upload}
                              classes={"w-100 mb-3"}
                              errorMessage={formik.errors["file"] ? formik.errors["file"] : undefined}
                              supported_formats={supported_formats}
                              field={formik.values.file}
                              maxFiles={1}/>
                    <div className="d-flex flex-row">
                        <DirtyFooter formik={formik} pending={pending}/>
                    </div>
                </div>
            </FormWrapper>
        </ErrorBoundaryContainer>

    )
}

export default CreatePortfolioItem;
