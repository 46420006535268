import React from "react";
import OrderCardHeader from "./OrderCardHeader";
// import ContractorInfo from "../contractor/ContractorInfo";
// import moment from "moment";
// import {sidebarTypes, userRoles} from "../../../common";
import {Trans} from 'react-i18next';
export const orderStatuses = [
    {
        id: 1,
        name:'Draft'
    },
    {
        id: 2,
        name:'Submitted'
    },
    {
        id: 3,
        name:'Approved'
    },
    {
        id: 4,
        name:'Rejected'
    }
];

function OrderCard(props){
    let { total_price, username } = props.item_info;
    let user = username.split(",");;

    const onClick = () => {
        props.setCurrentBid(props.item_info)
        // if(isContractor){
        //     props.setSidebarToggle(false)
        //     props.setSidebar(sidebarTypes.view_bid)
        // }else props.getBidComments({bid_id: props.item_info.id})
    }
    

    return (
      <div className="col-12">
        <div className="bid-card" onClick={onClick}>
          <OrderCardHeader item_info={props.item_info} />
          <div className="bid-card__body">
            {/* {!isContractor && <ContractorInfo contractor={contractor} />}
            {job && <div className="job-card__name">{job.title}</div>} */}
          </div>
          <div className="bid-card__footer">
            <div className="bid-card__header-item-content">
              <Trans>Order By</Trans> : {user[0]}
            </div>
            <div className="bid-card__header-item-content">
              <Trans>Total Price</Trans> : $ {total_price}
            </div>
          </div>
        </div>
      </div>
    );
}

export default OrderCard;
