//Modal for confirmation of deleting paired resource,
//showing by click on delete button on resource card if the resource is paired to the element

import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import {sendEmailToUpgradePlan, setLoading, toggleUpgradePlanModal} from "../../store/user";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import ModalBody from "reactstrap/es/ModalBody";
import ElementPlan from "../../images/element-plan.svg";
import GalleryPlan from "../../images/gallery-plan.svg";
import MuseumPlan from "../../images/museun-plan.svg";
import CustomPlan from "../../images/custom-plan.svg";
import PackageItem from "../PackageItem";
import {withTranslation,Trans} from "react-i18next";

class UpgradePlanModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            pending:false,
            message:'One of our knowledgeable team members will assist you right away',
            sent:false
        };

    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(nextProps.show_upgrade_plan_modal !== prevState.show){
            update.show = nextProps.show_upgrade_plan_modal;
        }
        return Object.keys(update).length ? update : null;
    }


    toggle = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
        this.props.toggleUpgradePlanModal(!this.props.show_upgrade_plan_modal)
    }

    handleContact = () => {
        this.setState({pending:true})
        this.props.sendEmailToUpgradePlan().then(()=>{
            this.setState({sent:true})
        })
    }

    render() {
        let {
          //pending,
          sent,message} = this.state;
        let {t} = this.props;
        let {business_package} = this.props.user_info;
        if(!business_package) return null;
        let packages = [
          {
            id: 1,
            name: t("Artist Package"),
            description:
              "Natoque duis sed blandit vitae elementum. Gravida montes consectetur amet sed laoreet.",
            price: "$49.99",
            per: t("(USD) per month"),
            illustration_src: ElementPlan,
            wave_src: "./images/element-wave.svg",
            wave_class: "",
            footer_padding: "plan-item__footer-padding",
            benefits: [
              t("30 Days Free Trail"),
              t("Free augmentation for 25 Exhibits"),
              t("Object Recognition"),
              t("Free Training and Support"),
              t("Free Museum Migration"),
            ],
            link: "https://buy.stripe.com/cN2fZr18bgLA9WM146",
          },
          {
            id: 2,
            name: t("Emerging creator"),
            description:
              "Natoque duis sed blandit vitae elementum. Gravida montes consectetur amet sed laoreet.",
            price: "$199.99",
            per: t("(USD) per month"),
            illustration_src: GalleryPlan,
            wave_src: "./images/gallery-wave.svg",
            wave_class: "",
            footer_padding: "",
            benefits: [
              t("30 Days Free Trial"),
              t("Content Creation Tutorial"),
              t("Unlimited Augmented Immersive Experiences"),
              t("Unlimited Visitors on MetaVisitAR *"),
              t("Free Listing on MetaVisitAR"),
              t("Marketing on RealMeta Social Media"),
              t("Free Updates and Upgrades"),
              t("App Analytics"),
            ],
            link: "https://buy.stripe.com/bIY9B38ADgLA4Cs4gg",
          },
          {
            id: 3,
            name: t("Professional Creator"),
            description:
              "Natoque duis sed blandit vitae elementum. Gravida montes consectetur amet sed laoreet.",
            price: "$1499.99",
            per: "(USD) per Month + VR Setup",
            illustration_src: MuseumPlan,
            wave_src: "./images/museum-wave.svg",
            wave_class: "wave-mt",
            footer_padding: "",
            benefits: [
              t("Everything from Emerging Creator"),
              t("3D Model creation of up to 3 Gallery spaces"),
              t("Real MetaVerse Content Creation for VR"),
              t("Gift Shop Integration"),
              t("Collaborate with RealMeta's Grant Application Team **"),
              t("Minimum 1 Year Commitment"),
              t("Free Updates and Upgrades"),
              t("App Analytics"),
            ],
            link: "",
          },
          {
            id: 3,
            name: t("Established Creator"),
            description:
              "Natoque duis sed blandit vitae elementum. Gravida montes consectetur amet sed laoreet.",
            price: "Custom",
            per: "Based on a pre-qualification evaluation",
            illustration_src: CustomPlan,
            wave_src: "./images/element-wave.svg",
            wave_class: "",
            footer_padding: "",
            benefits: [
              t("Everything from Professional Creator"),
              t("Dedicated Project Management Team"),
              t("Access to RealMeta Digital Studio Team"),
              t("Scripting on Media Creation"),
              t("Ticketing Integration"),
              t("Pre-qualification for a minimum 3 Year Commitment"),
            ],
            link: "",
          },
        ];
        return (
            <div>
                {sent && <Modal isOpen={this.state.show} toggle={this.toggle} className="" centered>
                    {this.props.user_info && <ModalHeader className="justify-content-center pb-0" toggle={this.toggle}/>}
                    <p className="text-center px-5">{message}</p>
                    <ModalFooter className="d-flex flex-row justify-content-center">
                        <Button color="primary" onClick={this.toggle}>Ok</Button>
                    </ModalFooter>
                </Modal>}

               {!sent &&  <Modal isOpen={this.state.show} toggle={this.toggle} className="modal-packages" centered>
                    {this.props.user_info && <ModalHeader className="justify-content-center pb-0" toggle={this.toggle}/>}
                    <h2 className="text-center modal-text"><Trans>Level up your experience</Trans></h2>
                    <p className="text-center modal-text"><Trans>Upgrade now to get full access to eliAR and enhance your experiences</Trans></p>
                    <ModalBody>

                        <div className="row">
                            {packages.map((package_item,i) => {
                                return <PackageItem package_item={package_item} key={i+'package'}/>
                            })}
                        </div>
                    </ModalBody>
                    <ModalFooter className="d-flex flex-row justify-content-center modal-text">
                        {/* <div><Trans>Need help with selecting a package?</Trans> <Button color="primary" onClick={this.handleContact} disabled={pending}><Trans>Please contact us</Trans></Button></div> */}
                    </ModalFooter>
                </Modal>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    show_upgrade_plan_modal: state.user.show_upgrade_plan_modal
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setLoading,
    toggleUpgradePlanModal,
    sendEmailToUpgradePlan
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(UpgradePlanModal));