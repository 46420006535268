//Button for request creation of resource
import React from 'react'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {sendRequestCreationResource, setRequestCreationModalStatus} from "../../../store/resources";
import {Modal, ModalFooter, ModalHeader} from "reactstrap";
import {Trans, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";


class RequestCreationResourceButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirm: false
        }
    }


    sendRequest = () => {
        this.setState({confirm:true})
        this.props.sendRequestCreationResource(this.props.type_id)
    }

    toggle = () => {
        this.props.setRequestCreationModalStatus(!this.props.show_request_creation_modal)
    }

    render() {
        let {confirm} = this.state;
        let {t} = this.props;
        return (
            <div className="request-creation ml-3">
                <Modal isOpen={this.props.show_request_creation_modal} toggle={this.toggle} className="modal modal-resources-list" centered>
                    <ModalHeader toggle={this.toggle}>{!confirm ? t('Are you sure you want to request creation? ') : t('Our team will contact you soon.')}</ModalHeader>
                    <ModalFooter className="d-block">
                        <div className="row">
                            <div className="col-12 mb-md-0 mb-3 text-center">
                                {confirm && <button className="btn btn-primary btn-small" onClick={this.toggle}><Trans>Ok</Trans></button>}
                                {!confirm && <button className="btn btn-outline btn-small mr-3" onClick={this.toggle}><Trans>No</Trans></button>}
                                {!confirm && <button className="btn btn-primary btn-small" onClick={this.sendRequest}><Trans>Yes</Trans></button>}
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
                {/*<button type="button" className="btn btn-outline" onClick={this.toggle}>*/}
                {/*    <Trans>Request Creation</Trans>*/}
                {/*</button>*/}
                <Link to={"/marketplace"} className="btn btn-outline">
                    <Trans>Request Creation</Trans>
                </Link>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    show_request_creation_modal: state.resources.show_request_creation_modal,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    sendRequestCreationResource,
    setRequestCreationModalStatus
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(RequestCreationResourceButton));
