import React from 'react';
import FullsizeMap from "../../components/google-map/FullsizeMap";
import InputWithButtons from "../../components/InputWithButtons";
import { Formik} from "formik";
import {Trans} from "react-i18next";
// import InviteUser from "./InviteUser";
import {setBusinessSchema} from "../../store/validations";
import WithTranslateFormErrors from "../../components/WithTranslateFormErrors";
import ErrorBoundaryContainer from "../../components/ErrorBoundaryContainer";
import BusinessPoster from "./BusinessPoster";

class BusinessInfo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            address_line_1: '',
            latitude: 0,
            longitude: 0,
            poster: '',
            location:undefined,
            upload_image_status: false
        }

    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};

        if(nextProps.business_info){
            let {name,address_line_1,longitude,latitude,poster,description,external_url} = nextProps.business_info;
            update.poster = poster;
            update.name = name;
            update.description = description;
            update.external_url = external_url;
            update.longitude = longitude;
            update.latitude = latitude;
            update.address_line_1 = address_line_1;
            update.location = {
              longitude,
              latitude,
              address_line_1,
              description,
              external_url
            };
        }
        return Object.keys(update).length ? update : null;
    }


    onBlurSave = (error,value,name) => {
        if(!error){
            if (this.state[name] === value) return;
            let business_updated = {[name]:value};
            this.props.updateBusinessInfo(business_updated)
        }
    }

    handleChangePlace = (location) => {
        this.setState({location})
        this.props.updateBusinessInfo(location)
    }




    render() {
        let { user_info, validation_rules, t } = this.props;
        let {location} = this.state;
        if(!user_info) return null;
        return(
            <ErrorBoundaryContainer>
                <div className="profile-section overflow-hidden h-100 mb-0">
                    <div className="profile-section__header">
                        <div className="mb-sm-0 mb-2"><Trans>Business information</Trans></div>
                        {/* <InviteUser dark_mode={dark_mode}/> */}
                        {/*<CouponCode dark_mode={dark_mode} coupon_code_for_business={coupon_code_for_business} updateCouponCodeForBusinessProp={this.props.updateCouponCodeForBusiness}/>*/}
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={this.state}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                this.handleSubmit(values);
                                setSubmitting(false)
                            }, 500);
                        }}
                        validationSchema={setBusinessSchema(validation_rules,t)}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                resetForm,
                                setFieldTouched,
                            } = props;
                            
                            let name_invalid_class = errors.name && touched.name ? 'invalid' : '';
                            let external_url_invalid_class =
                              errors.name && touched.name ? "invalid" : "";
                            let description_invalid_class =
                              errors.description && touched.description
                                ? "invalid"
                                : "";
                            return (
                              <WithTranslateFormErrors
                                errors={errors}
                                touched={touched}
                                setFieldTouched={setFieldTouched}
                              >
                                <form
                                  onSubmit={handleSubmit}
                                  name=""
                                  className="h-100 w-100"
                                >
                                  <BusinessPoster
                                    formik={props}
                                    uploadBusinessPoster={
                                      this.props.uploadBusinessPoster
                                    }
                                  />
                                  <div className="profile-section__body">
                                    <InputWithButtons
                                      handleSave={() =>
                                        this.onBlurSave(
                                          errors.name,
                                          values.name,
                                          "name"
                                        )
                                      }
                                      handleDiscard={() => resetForm()}
                                    >
                                      <b>
                                        <Trans>Business Name</Trans>
                                      </b>
                                      <input
                                        disabled={false}
                                        size={
                                          values.name && values.name.length > 0
                                            ? values.name.length + 4
                                            : t("Business Name").length + 4
                                        }
                                        id="name"
                                        name="name"
                                        placeholder={t("Business Name")}
                                        type="text"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                        }}
                                        className={`input-borderless profile__business-name ${name_invalid_class}`}
                                      />
                                      {errors.name && touched.name && (
                                        <div className="input-feedback">
                                          {errors.name}
                                        </div>
                                      )}
                                    </InputWithButtons>
                                    <br></br>
                                    <br></br>
                                    <InputWithButtons
                                      handleSave={() =>
                                        this.onBlurSave(
                                          errors.description,
                                          values.description,
                                          "description"
                                        )
                                      }
                                      handleDiscard={() => resetForm()}
                                    >
                                      <b>
                                        <Trans>Business Description</Trans>
                                      </b>
                                      <textarea
                                        disabled={false}
                                        size="80"
                                        rows="5"
                                        id="description"
                                        name="description"
                                        placeholder={t("Business Description")}
                                        type="text"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                        }}
                                        className={`input-borderless profile__business-name ${description_invalid_class}`}
                                      />
                                      {errors.description &&
                                        touched.description && (
                                          <div className="input-feedback">
                                            {errors.description}
                                          </div>
                                        )}
                                    </InputWithButtons>
                                    <br></br>
                                    <br></br>
                                    <InputWithButtons
                                      handleSave={() =>
                                        this.onBlurSave(
                                          errors.external_url,
                                          values.external_url,
                                          "external_url"
                                        )
                                      }
                                      handleDiscard={() => resetForm()}
                                    >
                                      <b>
                                        <Trans>Business External Url</Trans>
                                      </b>
                                      <input
                                        disabled={false}
                                        size={
                                          values.external_url &&
                                          values.external_url.length > 0
                                            ? values.external_url + 40
                                            : t("Business External Url")
                                                .external_url + 4
                                        }
                                        id="external_url"
                                        name="external_url"
                                        placeholder={t("Business External Url")}
                                        type="text"
                                        value={values.external_url}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                        }}
                                        className={`input-borderless profile__business-name ${external_url_invalid_class}`}
                                      />
                                      {errors.external_url &&
                                        touched.external_url && (
                                          <div className="input-feedback">
                                            {errors.external_url}
                                          </div>
                                        )}
                                    </InputWithButtons>
                                    <br></br>
                                    <br></br>
                                    <div className="profile__business-location">
                                      {values.address_line_1}
                                    </div>
                                  </div>
                                  <div className="profile__map-wrap">
                                    <FullsizeMap
                                      changePlace={this.handleChangePlace}
                                      location={location}
                                    />
                                  </div>
                                </form>
                              </WithTranslateFormErrors>
                            );
                        }}
                    </Formik>
                </div>
            </ErrorBoundaryContainer>
        )
    }
}
export default BusinessInfo;
