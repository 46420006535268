import React, {useEffect, useState} from 'react';
import { sidebarTypes} from "../../../common";
import {withRouter} from "react-router-dom";
import TopSectionContractor from "../top-section/TopSectionContractor";
import ReactSVG from "react-svg";
import UploadFile from "../../../images/upload-file-icon.svg";
import {Trans} from "react-i18next";
import CloseIcon from "../../../images/close-icon.svg";
import BidCoverLetter from "./BidCoverLetter";
import {useFormik} from "formik";
import {setBidSchema} from "../../../store/validations";
import MinutesInput from "../MinutesInput";
import CurrencyInput from "../CurrencyInput";
import MultipleLanguageSelect from "../MultipleLanguageSelect";
import BidFiles from "./BidFiles";
import JobViewSidebar from "../jobs/JobViewSidebar";
import Button from "../../../components/buttons/Button";
import FormWrapper from "../../../components/FormWrapper";
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";

function CreateBid (props) {
    let formik = useFormik({
        initialValues: {
            cover_letter: '',
            bid_amount: 1,
            currency_code: "CAD",
            estimate_minutes: 100,
            required_languages: [],
            files: null,
        },
        enableReinitialize: true,
        validationSchema: setBidSchema(props.validation_rules,props.t),
        onSubmit: values => {
            handleSubmit(values)
        },
    });
    useEffect(() => {
        props.getValidationRules('marketplace')
        let job_id = props.match.params.id;
        props.getJobItem(job_id)
        props.getJobAttachments(job_id)
        props.setSidebar(sidebarTypes.view_job)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const [pending, setPending] = useState(false)

    const handleSubmit = () => {
        setPending(true)
        let {cover_letter,required_languages, bid_amount,currency_code,estimate_minutes,files} = formik.values;
        let bid_data = {
            job_id: props.job_item.id,
            status_id: 2, //for creating bid with submitted status instead of draft
            cover_letter,
            required_languages,
            bid_amount,
            currency_code,
            estimate_minutes
        }
        props.createBid(bid_data).then((res) => {
            if(files){
                props.attachFilesToBid(res.id,files).then(() => {
                    setPending(false)
                    formik.resetForm()
                    props.history.push(`/bids`)
                })
            }else {
                setPending(false)
                formik.resetForm()
                props.history.push(`/bids`)
            }
        })
    }

    let classToggle = props.sidebar_toggled || props.sidebar_type === sidebarTypes.none ? '' : 'sidebar-wrap_toggled'
    const onChangeCurrency = (e) => {
        let val = e.target.value.replace(/\D/g, '');
        formik.setFieldValue('bid_amount',Number(val))
    }

    return(
        <div className={`sidebar-wrap sidebar-wrap_wide ${classToggle}`}>
            <JobViewSidebar {...props} hideButton
                            withoutFooter/>
            <div className="marketplace-wrap">
                <TopSectionContractor {...props}/>
                <ErrorBoundaryContainer>
                    <FormWrapper formik={formik} formName={'create-bid'}>
                        <div className="bids-section pb-5">
                            <div className="bids-section__inner-offset">
                                <div className="bids-section__header">
                                    <div className="bids-section__header-inner">
                                        <ReactSVG src={UploadFile} className="bids-section__header-icon"/>
                                        <Trans>Bid details</Trans>
                                    </div>
                                    <button type="button" className="btn btn-close"
                                            onClick={() => props.history.goBack()}>
                                        <ReactSVG src={CloseIcon} />
                                    </button>
                                </div>
                                <div className="bids-section__body">
                                    <BidCoverLetter {...props} formik={formik}/>
                                    <div className="row">
                                        <div className="col-4">
                                            <MinutesInput {...props} formik={formik}/>
                                            <div className="form-group">
                                                <div className="sidebar__title sidebar__title_offset-free"><Trans>Proposed budget</Trans></div>
                                                <CurrencyInput formik={formik} t={props.t}
                                                               id="bid_amount"
                                                               name="bid_amount"
                                                               placeholder={props.t(`Enter proposed budget`)}
                                                               type="text"
                                                               value={formik.values.bid_amount}
                                                               onChange={onChangeCurrency}
                                                               onBlur={formik.handleBlur}/>
                                            </div>
                                            <MultipleLanguageSelect {...props} formik={formik} title="Language offered"
                                                                    name={'required_languages'}/>
                                        </div>
                                        <div className="col-8">
                                            <BidFiles formik={formik} t={props.t}/>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button type="button"
                                                            onClick={formik.resetForm}
                                                            className="btn btn-outline w-100 mr-3"><Trans>Reset</Trans></button>
                                                </div>
                                                <div className="col-md-6">
                                                    <Button type="submit"
                                                            className="btn btn-primary btn-success w-100"
                                                            disabled={formik.isSubmitting}
                                                            loading={pending || formik.isSubmitting}><Trans>Submit</Trans></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormWrapper>
                </ErrorBoundaryContainer>

            </div>
        </div>
    )
}

export default withRouter(CreateBid);
