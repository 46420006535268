import React from 'react';
import {elementTypes} from "../../../common";
import PublishButton from "../../PublishButton";
import SwitcherWithIcon from "../../SwitcherWithIcon";
// import InfoIcon from "../../../images/info-icon.svg";
// import SmallRoundButton from "../../buttons/SmallRoundButton";
import ElementStatistics from "./ElementStatistics";
import ElementQuizDropdown from "./quiz/ElementQuizDropdown";
import {Trans, withTranslation} from "react-i18next";
import VRPublishButton from "../../VRPublishButton";

function ElementHeader(props) {
    let {element_item,isSubmitting,active,handleSwitcher,handleSubmit,t,main_content_language} = props;
    if(!element_item) return null;
    let isGamificationDisabled = process.env.REACT_APP_DISABLE_ELEMENT_GAMIFICATION === 'true';
    let disablePublish = true;
    let element_name = element_item.translations[main_content_language] ? element_item.translations[main_content_language].name : null;
    let element_description = element_item.translations[main_content_language] ? element_item.translations[main_content_language].description : null;
    let hasIdentifier = (element_item.identifiers && element_item.identifiers.length === 0);
    if(element_item.active || isSubmitting){
        disablePublish = false;
    }else disablePublish = !!(hasIdentifier || !element_name || !element_description);
    let tooltipText = disablePublish ? `${t("Required for publishing an element")}: ${!element_name ? t('Element Name') : ''} ${!element_name && !element_description ? ',' :''} ${!element_description ? t('Element Description') : ''} ${(!element_name || !element_description) && !!hasIdentifier ? ',' :''} ${!!hasIdentifier ? t('Element Identifier') : ''}` : null;
    return(
        <div className="element-header row">
            <div className="col-lg-3 col-md-12 mb-lg-0 mb-md-4 mb-4 d-flex align-items-center align-items-center mr-auto">
                <span className="subheader__title"><Trans>Edit Element</Trans></span>
            </div>
            <div className="col-lg-9 col-md-12">
                <div className="row">
                    <div className="col-lg-9 col-md-8 d-flex flex-row justify-content-lg-end justify-content-start align-items-center col-sm-12 mb-lg-0 mb-4 flex-md-nowrap flex-wrap">
                        {/*<SmallRoundButton icon={InfoIcon} classes={'ml-md-4 ml-sm-0'}/>*/}
                        {!isGamificationDisabled  && <ElementQuizDropdown/>}
                        <ElementStatistics element_item={element_item}/>
                        <SwitcherWithIcon
                            withoutText
                            shortTitleOnMobile
                            name={'type'}
                            onChange={handleSwitcher}
                            classes={'ml-3'}
                            keyProp={element_item.id}
                            id={element_item.id}
                            checked={element_item.type_id === elementTypes.element3d}
                            disabled={element_item.active || (element_item.identifiers && element_item.identifiers.length > 0)}/>
                    </div>
                    <div className="col-lg col-md-8 col-sm">
                        <PublishButton disable={disablePublish} active={active}
                                       classes={`w-100 ${disablePublish ? 'custom-tooltip custom-tooltip_bottom' : ''}`} handleSubmit={handleSubmit}
                                       tooltipText={tooltipText}/>
                    </div>
                    <div className="col-lg col-md-8 col-sm">
                        <VRPublishButton disable={disablePublish} active={active}
                                       classes={`w-100 ${disablePublish ? 'custom-tooltip custom-tooltip_bottom' : ''}`} handleSubmit={handleSubmit}
                                       tooltipText={tooltipText} {...props}/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default withTranslation('translations') (ElementHeader);
