import React, {useEffect} from 'react';
import { sidebarTypes, userRoles} from "../../../common";
import {withRouter} from "react-router-dom";
import TopSectionContractor from "../top-section/TopSectionContractor";
import ReactSVG from "react-svg";
import CloseIcon from "../../../images/close-icon.svg";
import JobViewSidebar from "../jobs/JobViewSidebar";
import Comments from "../comments/Comments";
import TopSection from "../top-section/TopSection";
import BidDetails from "./BidDetails";
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";

function CreateBid (props) {
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;

    useEffect(() => {
        props.getValidationRules('marketplace')
        let job_id = props.match.params.id;
        props.getJobItem(job_id).then((res) => {
            res.accepted_bid_id && props.getBidItem(res.accepted_bid_id)
        })
        props.bid_item && props.getBidComments({bid_id:props.bid_item.id})
        props.getJobAttachments(job_id)
        props.setSidebar(sidebarTypes.view_job)
        return (() => {
            props.clearBidComments()
            props.setCurrentBid(undefined)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    let classToggle = props.sidebar_toggled || props.sidebar_type === sidebarTypes.none ? '' : 'sidebar-wrap_toggled'

    const onCreateComment = (message) => {
        return new Promise((resolve, reject) => {
            props.createBidComment(props.bid_item.id, message)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        })
    }

    return(
        <div className={`sidebar-wrap sidebar-wrap_wide ${classToggle}`}>
            {props.job_item && <JobViewSidebar {...props} hideButton
                             withoutFooter/>}
            <div className="marketplace-wrap">
                {isContractor ? <TopSectionContractor {...props}/> : <TopSection {...props}/>}
                <ErrorBoundaryContainer>
                    <div className="bids-section pb-5">
                        <div className="bids-section__inner-offset">
                            <div className="bids-section__header">
                                <div className="bids-section__header-inner">
                                    <ul className="nav nav-pills btn-group btn-group-toggle mb-4" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link btn btn-group-toggle__item active" id="bid-tab"
                                               data-toggle="tab" href="#bid"
                                               role="tab" aria-controls="bid" aria-selected="true">Bid details</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link btn btn-group-toggle__item" id="comments-tab"
                                               data-toggle="tab" href="#comments"
                                               role="tab" aria-controls="comments" aria-selected="false">Comments</a>
                                        </li>
                                    </ul>
                                </div>
                                <button type="button" className="btn btn-close"
                                        onClick={() => props.history.goBack()}>
                                    <ReactSVG src={CloseIcon} />
                                </button>
                            </div>
                            <div className="tab-content d-flex flex-column flex-grow-1">
                                <div className="tab-pane active flex-grow-1" id="bid" role="tabpanel"
                                     aria-labelledby="bid-tab">
                                    <div className="bids-section__body">
                                        <BidDetails {...props} data={props.bid_item} item_info={props.job_item}/>
                                    </div>
                                </div>
                                <div className="tab-pane" id="comments" role="tabpanel"
                                     aria-labelledby="comments-tab">
                                    <Comments comments={props.bid_comments}
                                              onCreateComment={onCreateComment}
                                              {...props}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </ErrorBoundaryContainer>

            </div>
        </div>
    )
}

export default withRouter(CreateBid);
