import React from 'react';
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import MyJobs from "./jobs/MyJobs";
import {setSidebar, setSidebarToggle} from "../../store/util";
import PostJob from "./jobs/PostJob";
import JobBids from "./bids/JobBids";
import AcceptedJob from "./jobs/AcceptedJob";
import ChangeOfRequest from "./jobs/ChangeOfRequest";
import { checkToken, updateUserInfo, getBusinessInfo } from "../../store/user";
import {
    attachFilesToJob, deleteJobAttachment,
    getJobAttachments, getJobItem,
    getJobs, getOnlyOpenJobs,
    createNewJob,
    setCurrentJob,
    updateJob, updateJobStatus, deleteDraftJob, clearJobs
} from "../../store/jobs";
import {getValidationRules} from "../../store/validations";
import {showFullSizeGallery} from "../../store/resources";
import {
    approveBid, attachFilesToBid, clearBidComments,
    clearBids,
    createBid, createBidComment, declineBid, getBidComments,
    getBidItem,
    getBids,
    getOnlySubmittedBids,
    setCurrentBid, updateBidStatus
    ,getOrderItem, getOrders
} from "../../store/bids";
//import { getOrders, getOrderItem } from "../../store/orders";
import ContractorProfile from "./contractor/contractor-profile/ContractorProfile";
import {
    clearContractorInfo,
    createContractorPortfolioItem, deleteContractorAvatar, deleteContractorPortfolioItem,
    getContractorInfo,
    getContractorPortfolio,
    updateContractorInfo, uploadContractorAvatar
} from "../../store/contractors";
import {userRoles} from "../../common";
import ContractorJobs from "./jobs/ContractorJobs";
import MyBids from "./bids/MyBids";
import CreateBid from "./bids/CreateBid";
import AcceptedBidDetails from "./bids/AcceptedBidDetails";
import {attachFilesToComments} from "../../store/comments";
import ViewUserProfile from "./contractor/ViewUserProfile";
import UnassignedBidDetails from "./bids/UnassignedBidDetails";
import PrivateRoute from "../PrivateRoute";
import Orders from "./orders/Orders";

class MarketplaceContainer extends React.PureComponent{
   
    componentDidMount() {
        this.props.getValidationRules('marketplace')
    }

    render() {
        let isContractor = this.props.user_info && this.props.user_info.role_id === userRoles.contractor;
        let HomePage = isContractor ? ContractorJobs : MyJobs;

        return (
          <Switch>
            <Route
              exact
              path="/"
              render={() =>
                isContractor ? (
                  <Redirect to={`/marketplace`} />
                ) : (
                  <Route exact path="/" />
                )
              }
            />
            <Route
              exact
              path="/profile"
              render={() =>
                isContractor ? (
                  <Redirect to={`/contractor-profile`} />
                ) : (
                  <Route exact path="/profile" />
                )
              }
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/orders"
              component={Orders}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/marketplace"
              component={HomePage}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/post-job"
              component={PostJob}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/create-bid-for-job-:id"
              component={CreateBid}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/bid-details-for-job-:id"
              component={AcceptedBidDetails}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/unassigned-bid-details-:id"
              component={UnassignedBidDetails}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/bids-job-item-:id"
              component={JobBids}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/bids"
              component={MyBids}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/job-item-:id"
              component={AcceptedJob}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/contractor-profile"
              component={ContractorProfile}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/user-:id"
              component={ViewUserProfile}
              componentProps={this.props}
            />
            <PrivateRoute
              exact
              authed={checkToken()}
              path="/change-of-request-job-:id"
              component={ChangeOfRequest}
              componentProps={this.props}
            />
          </Switch>
        );
    }
}

const mapStateToProps = (state) => ({
  business_info: state.user.business_info,
  user_info: state.user.user_info,
  job_item: state.jobs.job_item,
  job_attachments: state.jobs.job_attachments,
  jobs: state.jobs.jobs,
  open_jobs: state.jobs.open_jobs,
  bids: state.bids.bids,
  submitted_bids: state.bids.submitted_bids,
  bid_item: state.bids.bid_item,
  bid_comments: state.bids.bid_comments,
  submit_bid_mode: state.contractors.submit_bid_mode,
  validation_rules: state.validations,
  sidebar_type: state.util.sidebar_type,
  sidebar_toggled: state.util.sidebar_toggled,
  contractor_item: state.contractors.contractor_item,
  portfolio_items: state.contractors.portfolio_items,
  dark_mode: state.user.dark_mode,
  orders: state.bids.orders,
  //order_item: state.orders.order_item
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getBusinessInfo,
    setSidebar,
    setCurrentJob,
    setSidebarToggle,
    getJobs,
    getValidationRules,
    createNewJob,
    attachFilesToJob,
    getJobAttachments,
    showFullSizeGallery,
    updateJob,
    deleteJobAttachment,
    getBidComments,
    getJobItem,
    getOnlyOpenJobs,
    getBids,
    getOnlySubmittedBids,
    setCurrentBid,
    approveBid,
    updateJobStatus,
    deleteDraftJob,
    getContractorInfo,
    clearJobs,
    clearBids,
    getBidItem,
    createBidComment,
    createBid,
    attachFilesToBid,
    updateBidStatus,
    attachFilesToComments,
    updateContractorInfo,
    getContractorPortfolio,
    createContractorPortfolioItem,
    uploadContractorAvatar,
    deleteContractorAvatar,
    deleteContractorPortfolioItem,
    declineBid,
    clearContractorInfo,
    updateUserInfo,
    clearBidComments,
    getOrders,
    getOrderItem
},dispatch)

export default withTranslation('translations')(withRouter(connect(mapStateToProps,mapDispatchToProps)(MarketplaceContainer)));
