//Login screen with form
import React  from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import {setCurrentResource} from "../../store/resources";
import {checkExistEmail, getUserInfo, checkToken, signIn} from "../../store/user";
import {getValidationRules, setLoginSchema} from "../../store/validations";
import { Formik} from "formik";
import {Link, withRouter} from "react-router-dom";
import {versionNames} from "../../common";
import {Trans, withTranslation} from "react-i18next";
import bigOverlayImage from '../../images/big-overlay.png';
import loginImage from '../../images/login-page-user-icon.svg'


class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email:'',
            password:'',
            exist_email_error:'',
            correct_password_error:'',
            isEmail:false
        }
    }

    componentDidMount() {
        this.props.getValidationRules('dashboard')
    }

    handleInputChange= (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    signIn = (values) => {
        let {email,password} = values;
        let {t} = this.props;
        if(this.state.isEmail){
            this.props.checkExistEmail(email).then((emailMessage) => {
                let valid_email = emailMessage.code === 1;
                if(valid_email){
                    this.setState({exist_email_error:''})
                    this.props.signIn({username:email, password})
                        .then((res) => {
                            if(checkToken()) this.props.getUserInfo().then((res) => {
                                if(res.data.locale){
                                    this.props.i18n.changeLanguage(res.data.locale);
                                }
                                this.props.history.push('/')
                            })
                        })
                        .catch((err) =>{
                            this.setState({correct_password_error: t('Incorrect Password. Please try to input the correct password')})

                        })
                }else {
                    this.setState({exist_email_error: t('UserId or Email does not exist in our records')})
                }
            })
        }else {
            this.props.signIn({username:email, password})
                .then((res) => {
                    if(checkToken()) this.props.getUserInfo().then((res) => {
                        if(res.data.locale){
                            this.props.i18n.changeLanguage(res.data.locale);
                        }
                        this.props.history.push('/')
                    })
                })
                .catch((err) =>{
                    this.setState({correct_password_error: t('Incorrect Password. Please try to input the correct password')})

                })
        }

    }

    handleBlur(email){
        this.setState({isEmail: /@/.test(email)})
    }

    render(){
        let isVR = process.env.REACT_APP_VERSION_NAME === versionNames.vr;
        let {exist_email_error,correct_password_error} = this.state;
        let {t} = this.props;
        let language = this.props.i18n.language;
        return (
          <div className="body row auth-wrap">
            <div className="title-side col-md-8 col-sm-12">
              <div className="big-overlay-left">
                <img src={bigOverlayImage} draggable={false} alt="" />
              </div>
              <div className="auth__content">
                <div className="flex-grow-1 justify-content-center align-items-center d-flex flex-column">
                  <h1 className="title main-title text-center line-height-medium">
                    <Trans>Welcome to RealMeta's</Trans>
                  </h1>
                  <h2 className="bigger-subtitle text-center"> {/* Original: subtitle */}
                    <Trans>Content Creation Canvas!</Trans>
                  </h2>
                </div>
                <a
                  href="https://realmeta.ca/"
                  className="link link-primary bottom-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Visit</Trans> realmeta.ca
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 d-flex justify-content-center">
              <Formik
                initialValues={this.state}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    this.signIn(values);
                    setSubmitting(false);
                  }, 500);
                }}
                validationSchema={setLoginSchema(
                  this.props.validation_rules,
                  this.state.isEmail,
                  t
                )}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  } = props;
                  let email_invalid_class =
                      errors.email && touched.email ? "invalid" : "",
                    pass_invalid_class =
                      errors.password && touched.password ? "invalid" : "";
                  return (
                    <form onSubmit={handleSubmit} className="form-primary">
                      <div className="flex-grow-1 justify-content-center align-items-center d-flex flex-column w-90">
                        {" "}
                        {/* Took out: w-100 */}
                        <div className="user-image-container">
                          <img src={loginImage} alt="" />
                        </div>
                        {/* <div className="title">
                          <Trans></Trans>
                        </div>
                        <div className="subtitle">
                          <Trans></Trans>
                        </div> */}
                        <div className="p-0 m-0 w-100">
                          <div className="form-group">
                            {((errors.email && touched.email) || // Changed positions to render above the input field
                              exist_email_error) && (
                              <div className="input-feedback">
                                {errors.email
                                  ? errors.email
                                  : exist_email_error}
                              </div>
                            )}
                            <input
                              id="email"
                              placeholder={t("Enter Username or Email")}
                              type="text"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                this.handleBlur(values.email);
                              }}
                              className={` input-margin form-control input-primary input-up d-block ${email_invalid_class}`}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              id="password"
                              placeholder={t("Enter Password")}
                              type="password"
                              value={values.password}
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`input-margin form-control input-primary input-down d-block ${pass_invalid_class}`}
                            />

                            {((errors.password && touched.password) ||
                              correct_password_error) && (
                              <div className="input-feedback">
                                {errors.password
                                  ? errors.password
                                  : correct_password_error}
                              </div>
                            )}
                          </div>
                          <div className="forgot-password-container">
                            <Link
                              className="btn-link"
                              to={`/${language}/forgot-password`}
                            >
                              <Trans>Forgot Password?</Trans>
                            </Link>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="sign-in-button" // add custom button (btn btn-primary mb-3)
                          disabled={isSubmitting}
                        >
                          <Trans>Log In</Trans>
                        </button>
                        {!isVR && (
                          <span className="note-text note-text_middle note-text-login-bottom">
                            <Trans>Don’t have an account yet?</Trans> &nbsp;
                            <Link
                              //to={{ pathname: "https://realmeta.ca/pricing/" }}
                              to={`/${language}/register-museum`}
                              target="_blank"
                              className="text-accent d-inline-block"
                            >
                              {" "}
                              <Trans>Register now!</Trans>&nbsp;
                            </Link>{" "}
                          </span>
                        )}
                        {!isVR && (
                          <span className="note-text note-text_middle note-text-login-bottom">
                            <Trans>Unsubscribe from account</Trans> &nbsp;
                            <Link
                              to={{
                                pathname:
                                  "https://billing.stripe.com/p/login/00gcOp6yNf6qaqseUU",
                              }}
                              target="_blank"
                              className="text-accent d-inline-block"
                            >
                              {" "}
                              <Trans>Unsubscribe</Trans>&nbsp;
                            </Link>{" "}
                          </span>
                        )}
                      </div>

                      <p className="copyright mb-0">
                        &copy; 2024 RealMeta <Trans>All Rights Reserved</Trans>
                      </p>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        );
    }

}


const mapStateToProps = state => ({
    user: state.user.user,
    validation_rules: state.validations
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCurrentResource,
    signIn,
    getValidationRules,
    getUserInfo,
    checkExistEmail
},dispatch);

export default withRouter(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Login)));

