//Profile screen
import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {canvasLanguages, languages} from "../../common";
import LanguageSelect from "../../components/LanguageSelect";
import * as _ from "lodash";
import { withTranslation, Trans } from 'react-i18next';
import UserPersonalInfo from "./UserPersonalInfo";
import UserPackageInfo from './UserPackageInfo';
import {
    setMainContentLanguage,
    toggleUpgradePlanModal,
    updateBusinessInfo,
    updateUserInfo,
    updateCouponCodeForBusiness, setLoading, uploadBusinessPoster, getBusinessInfo, uploadUserAvatar, deleteUserAvatar,
} from "../../store/user";
import BusinessInfo from "./BusinessInfo";
import Loading from "../../components/Loading";
import ErrorBoundaryContainer from "../../components/ErrorBoundaryContainer";

 class Home extends React.Component {
     constructor(props) {
         super(props);
         this.state = {
             language: 1,
             main_content_language: 1,
         }
         props.setLoading(true)

     }

     componentDidMount() {
         this.props.getBusinessInfo()
         setTimeout(() => {
             this.props.setLoading(false)
         },3000)
     }

     static getDerivedStateFromProps(nextProps, prevState){
         let update = {};
         if(nextProps.user_info && nextProps.user_info.locale){
             update.language = _.find(canvasLanguages,['short_name',nextProps.user_info.locale]).id;
         }
         if(nextProps.main_content_language){
             update.main_content_language = _.find(languages,['short_name',nextProps.main_content_language]).id;
         }
         return Object.keys(update).length ? update : null;
     }

     handleLanguageChange = (name,value) => {
         this.setState({[name]:value})
         let newlang = _.find(canvasLanguages,['id',value]).short_name;
         this.props.i18n.changeLanguage(newlang);
         this.props.updateUserInfo({locale:newlang})
     }

     handleMainLanguageChange = (name,value) => {
         this.setState({[name]:value})
         let newlang = _.find(languages,['id',value]).short_name;
         this.props.setMainContentLanguage(newlang);
     }



     handleSubmit = () => {

     }


     render() {
         return(
             <ErrorBoundaryContainer>
                 <div className="profile-screen">
                     <Loading small={true} classes="position-fixed"/>
                     <div className="row">
                         <div className="col-md-4 col-sm-12 profile-col">
                             <UserPersonalInfo {...this.props}/>
                             <div className="profile-section">
                                 <div className="profile-section__header">
                                     <Trans>
                                         Canvas language
                                     </Trans>
                                 </div>
                                 <LanguageSelect data={canvasLanguages} disabled={false} value={this.state.language}
                                                 setFieldValue={this.handleLanguageChange}
                                                 name={'language'} hideHelp withoutLimits/>
                                 <div className="profile-section__header mt-2">
                                     <Trans>
                                         Element and Resources Primary language
                                     </Trans>
                                 </div>
                                 <LanguageSelect data={languages} disabled={false} value={this.state.main_content_language}
                                                 onBlur={languages} setFieldValue={this.handleMainLanguageChange}
                                                 name={'main_content_language'} hideHelp/>
                             </div>
                             <UserPackageInfo user_info={this.props.user_info} toggleUpgradePlanModal={this.props.toggleUpgradePlanModal}/>
                         </div>
                         <div className="col-md-8 col-sm-12 profile-col">
                             <BusinessInfo {...this.props}/>
                         </div>
                     </div>
                 </div>
             </ErrorBoundaryContainer>


         )
     }
 }

const mapStateToProps = state => ({
    user_info: state.user.user_info,
    business_info: state.user.business_info,
    main_content_language: state.user.main_content_language,
    validation_rules: state.validations,
    dark_mode: state.user.dark_mode,
    coupon_code_for_business: state.user.coupon_code_for_business,

});

const mapDispatchToProps = dispatch => bindActionCreators({
    toggleUpgradePlanModal,
    updateUserInfo,
    updateBusinessInfo,
    setMainContentLanguage,
    updateCouponCodeForBusiness,
    setLoading,
    uploadBusinessPoster,
    getBusinessInfo,
    uploadUserAvatar,
    deleteUserAvatar
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Home));

