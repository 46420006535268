//Loading animated component. Can be used for whole page or in a component with small class.

import React from 'react';
import {connect} from "react-redux";

class Loading extends React.Component {
    render() {
        let {activeSmallLoading,small,loading,isTransparent,classes,withText,fullscreen} = this.props;
        let smallClass = (small && !fullscreen) ? 'loading_small' : '';
        let smallFullscreenClass = fullscreen ? 'loading_small-fullscreen' : '';
        let transparentClass = isTransparent ? 'loading_transparent' : '';
        if(!loading && !activeSmallLoading) return null;
        return (
            (loading || activeSmallLoading) && <div id="loading" className={`loading ${smallClass} ${transparentClass} ${classes} ${smallFullscreenClass}`}>
                {!withText && <div className={small ? 'loading-wrap' : ''}>
                    <div className="loader-ring"/>
                    <div className="loader-ring"/>
                    <div className="loader-ring"/>
                    <div className="loader-ring"/>
                    <div className="loader-ring"/>
                    <div className="loader-ring"/>
                    <div className="loader-ring"/>
                    <div className="loader-ring"/>
                    <div className="loader-ring"/>
                </div>}
                {withText && <span className="loading__text">Loading...</span>}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.user.loading,
});


export default connect(mapStateToProps, null)(Loading);