//Screen with feedback form that will open by click on feedback in user menu
import React from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';

class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
    }


    render = () => {

        return (
            <div className="text-center">
                <iframe title="feedback-form"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSdD8K9YkqDpwQFBUba2WW9goA3kMo_VP0WcSu85j4LhZSlq4w/viewform?embedded=true"
                    width="100%" height="751" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                </iframe>
            </div>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);

