import React, {useRef, useCallback, useState, useEffect} from 'react';
import {Trans} from "react-i18next";
import ErrorBoundaryContainer from "./ErrorBoundaryContainer";

function InfiniteScrollContainerNew(props) {
    let {children,classes,hasFilter,getItems} = props;
    let page = 1;
    const observer = useRef()
    const bottomRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreItems) {
                loadMore()
                if(hasFilter){
                    setTimeout(() => {
                        loadMore()
                    },1000)
                }
            }
        })
        if (node) observer.current.observe(node)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFilter]);

    useEffect(() => {
        return(() => {
            props.clearList && props.clearList()
            setHasMoreItems(true)
        })
        //eslint-disable-next-line
    },[])

    const [hasMoreItems,setHasMoreItems] = useState(true);
    const loadMore = () => {
        getItems(page,setHasMoreItems)
            .then((res) => {
                setHasMoreItems(res.meta && res.meta.current_page < res.meta.last_page)
                page = res.meta.current_page + 1
            })
            .catch((err) => {
                setHasMoreItems(false)
            })
    }

    const renderText = () => {
        if(hasMoreItems){
            return <div className="load-more__container pb-2" ref={bottomRef}>
                <span><Trans>Loading</Trans></span>
                <div className="load-more-anim">
                    <div className="circle"/>
                    <div className="circle"/>
                    <div className="circle"/>
                </div>
            </div>
        } else return (
                 <div className="load-more__container pb-2">
                   <Trans>No more data</Trans>
                 </div>
               );
    }

    return(
        <ErrorBoundaryContainer>
            <div className="load-more__wrap">
                <div className={classes}>
                    {children}
                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 card-item-wrap d-flex flex-column justify-content-center">
                        {props.isHorizontal && renderText()}
                    </div>
                </div>
                {!props.isHorizontal && renderText()}
            </div>
        </ErrorBoundaryContainer>
    )
}

export default InfiniteScrollContainerNew;
