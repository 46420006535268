import React, {useEffect} from "react";
import JobSectionHeader from "./JobSectionHeader";
import TitleInput from "../TitleInput";
import DescriptionInput from "../DescriptionInput";
import CurrencyInput from "../CurrencyInput";
import DatePickerInput from "../DatePickerInput";
import Status from "../Status";
import ResourceType from "../ResourceType";
import JobLanguage from "../MultipleLanguageSelect";
import JobFiles from "./JobFiles";
import Button from "../../../components/buttons/Button";
import FormWrapper from "../../../components/FormWrapper";
import {useFormik} from "formik";
import {languages, resourceTypes} from "../../../common";
import {setJobSchema} from "../../../store/validations";

function ChangeOfRequest(props) {
    let formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            resource_type: resourceTypes.image,
            languages: languages[1]
        },
        enableReinitialize: true,
        validationSchema: setJobSchema(props.validation_rules,props.t),
    });
    useEffect(() => {
        // let job_id = props.match.params.id;
        props.setCurrentJob(props.jobs[2])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    // const [pending, setPending] = useState(false)


    return(
        <FormWrapper formik={formik} formName={'edit-resource'}>
            <div className="marketplace-wrap">
                <div className="post-job-section">
                    <JobSectionHeader history={props.history} t={props.t} title="Change of request"/>
                    <div className="row">
                        <div className="col-md-8">
                            <TitleInput formik={formik} t={props.t}/>
                            <DescriptionInput formik={formik} t={props.t} textareaClass="textarea-height"/>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="sidebar__title sidebar__title_offset-free">Enter a budget</div>
                                    <CurrencyInput/>
                                </div>
                                <div className="col-md-6">
                                    <div className="sidebar__title sidebar__title_offset-free">Enter due date</div>
                                    <DatePickerInput formik={formik} t={props.t}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="sidebar__title sidebar__title_offset-free">Current status</div>
                            <Status isBig/>
                            <ResourceType formik={formik} t={props.t}/>
                            <JobLanguage formik={formik} t={props.t} name={'languages'}/>
                            <JobFiles formik={formik} t={props.t}/>
                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btn btn-outline w-100 mr-3">Reset</button>
                                </div>
                                <div className="col-md-6">
                                    <Button type="submit"
                                            className="btn btn-primary w-100"
                                            disabled={formik.isSubmitting}
                                            loading={ formik.isSubmitting}>Save</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FormWrapper>
    )
}

export default ChangeOfRequest;
