//All reducers for scenes
import * as api from "../api";
import {handleErrors} from "./errors";

const SET_SCENE_ITEMS = 'SET_SCENE_ITEMS',
      SET_CURRENT_SCENE = 'SET_CURRENT_SCENE',
      SET_SCENE_INITIAL_VIEW = 'SET_SCENE_INITIAL_VIEW',
      SET_NEW_SCENE = 'SET_NEW_SCENE',
      SET_CREATE_SCENE_MODE = 'SET_CREATE_SCENE_MODE',
      SET_SCENE_LIST_MODAL = 'SET_SCENE_LIST_MODAL',
      SET_NAVIGATE_TO_NEW_SCENE_MODAL = 'SET_NAVIGATE_TO_NEW_SCENE_MODAL';

const initialState = {
    scenes: [],
    scene_item: undefined,
    created_scene_item: {},
    scene_initial_view:{rotation_x: 0, rotation_y: 0, rotation_z: 0},
    new_scene_data:{},
    create_scene_mode:false,
    show_navigate_to_new_scene_modal:false,
    show_scene_list_modal:false
};

export const getScenes = (experience_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetScenes(experience_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_SCENE_ITEMS,
                        scenes: responseJson.data
                    });
                    resolve(responseJson.data)
                })
                .catch( err => {
                    reject()
                })
        })

    };

export const createScene = (experience_id,scene) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCreateScene(experience_id,scene)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
        })

    };

export const getSceneItemById = (id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetSceneItem(id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CURRENT_SCENE,
                        scene_item: responseJson.data
                    });
                    resolve(responseJson.data)
                })
        })
    };

export const updateSceneData = (experience_id,scene_id,updated_scene) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateScene(experience_id,scene_id,updated_scene)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CURRENT_SCENE,
                        scene_item: responseJson.data
                    });
                    resolve(responseJson)
                })
        })
    };

export const deleteScenes = (experience_id,scenes_ids) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteScenes(experience_id,scenes_ids)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
        })
    };

export const setInitialViewForScene = (experience_id,scene_id,initial_view_data) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCreateSceneInitialView(experience_id,scene_id,initial_view_data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
        })
    };

export const getInitialViewForScene = (experience_id,scene_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetSceneInitialView(experience_id,scene_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_SCENE_INITIAL_VIEW,
                        scene_initial_view: responseJson.data
                    });
                    resolve(responseJson)
                })
        })
    };
//reorder scenes by drag and drop, using in scenes list modal
export const reorderScenes = (experience_id,ids) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchReorderScenes(experience_id,ids)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_SCENE_ITEMS,
                        scenes: responseJson.data
                    });
                    resolve(responseJson)
                })
        })
    };

export const setCurrentScene = (scene_item) =>
    (dispatch) => {
        dispatch({
            type: SET_CURRENT_SCENE,
            scene_item
        });
    };

export const setNewScene = (new_scene_data) =>
    (dispatch) => {
        dispatch({
            type: SET_NEW_SCENE,
            new_scene_data
        });
    };

export const setCreateSceneMode = (create_scene_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_CREATE_SCENE_MODE,
            create_scene_mode
        });
    };

export const setSceneListModal = (show_scene_list_modal) =>
    (dispatch) => {
        dispatch({
            type: SET_SCENE_LIST_MODAL,
            show_scene_list_modal
        });
    };

export const resetInitialSceneView = () =>
    (dispatch) => {
        dispatch({
            type: SET_SCENE_INITIAL_VIEW,
            scene_initial_view:initialState.scene_initial_view
        });
    };

export const setNavSceneModal = (show_navigate_to_new_scene_modal,created_scene_item) =>
    (dispatch) => {
        dispatch({
            type: SET_NAVIGATE_TO_NEW_SCENE_MODAL,
            show_navigate_to_new_scene_modal,
            created_scene_item
        });
    };

//Actions for scenes
const ACTION_HANDLERS = {
    [SET_SCENE_ITEMS]: (state, action) => ({
        ...state,
        scenes: action.scenes,
    }),
    [SET_CURRENT_SCENE]: (state, action) => ({
        ...state,
        scene_item: action.scene_item,
    }),
    [SET_SCENE_INITIAL_VIEW]: (state, action) => ({
        ...state,
        scene_initial_view: action.scene_initial_view,
    }),
    [SET_NEW_SCENE]: (state, action) => ({
        ...state,
        new_scene_data: action.new_scene_data,
    }),
    [SET_CREATE_SCENE_MODE]: (state, action) => ({
        ...state,
        create_scene_mode: action.create_scene_mode,
    }),
    [SET_SCENE_LIST_MODAL]: (state, action) => ({
        ...state,
        show_scene_list_modal: action.show_scene_list_modal,
    }),
    [SET_NAVIGATE_TO_NEW_SCENE_MODAL]: (state, action) => ({
        ...state,
        show_navigate_to_new_scene_modal: action.show_navigate_to_new_scene_modal,
        created_scene_item: action.created_scene_item,
    })

};


export default function scenes(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

