//Component for viewing tags list. Using on element card
import React  from 'react'
import {Trans} from "react-i18next";

class Tags extends React.Component {

    static renderHashTag(tag_item, i, length){
        if(i <= 2){
            return(
                <div className="element-item__tags-item" key={i}>#{tag_item}</div>
            )
        }
    }

    static renderHashTagMore(length){
        if(length > 2){
            return <div className="element-item__tags-item element-item__tags-item_underline">+{length -2} <Trans>more</Trans></div>
        }
    }

    render(){
        let {tags,classes} = this.props;
        return(
            <div className={`experience-item__tags ${classes}`}>
                {tags.map((tag_item, i) => Tags.renderHashTag(tag_item,i+1))}
                {Tags.renderHashTagMore(tags.length)}
            </div>
        )
    }

}

export default Tags;

