import React from 'react';
import {DropdownItem, DropdownMenu,} from 'reactstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    autoTranslateQuiz,
    clearQuiz,
    createElementQuiz,
    createQuizAnswers,
    deleteElementQuiz,
    deleteSingleQuizAnswer,
    getElementAnalytics,
    getElementQuiz,
    setQuizLanguage,
    updateElementQuiz,
    updateSingleQuizAnswer
} from "../../../../store/elements";
import {setQuizSchema} from "../../../../store/validations";
import {ErrorMessage, FieldArray, Formik} from "formik";
import QuizAnswer from "./QuizAnswer";
import * as _ from "lodash";
import LanguageSelect from "../../../LanguageSelect";
import {languages, notify} from "../../../../common";
import ReactSVG from "react-svg";
import CloseIcon from "../../../../images/close-icon.svg";
import CustomScrollBar from "../../../CustomScrollBar";
import HelpGamificationButton from "../../../buttons/HelpGamificationButton";
import {Trans, withTranslation} from "react-i18next";
// import AutoTranslate from "../../../AutoTranslate";
import Loading from "../../../Loading";


class ElementQuiz extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            question: '',
            description:'',
            answers: undefined,
            points: 1,
            id: undefined,
            is_pending: false,
            language: 1,
            loading:false
        }
    }

    componentDidMount() {
        if(this.props.main_content_language){
            this.props.setQuizLanguage(this.props.main_content_language)
        }
    }

    componentWillUnmount() {
        this.props.clearQuiz()
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let language = nextProps.quiz_language;
        let update = {};
        let newLangId = _.find(languages,['short_name',language]).id;
        if(newLangId !== prevState.language){
            update.language = newLangId
        }
        if(nextProps.quiz && nextProps.quiz.length > 0){
            let {translations,answers,points,id} = nextProps.quiz[0];
            if(answers){
                update.answers = answers.map((item) => {
                    return {
                        id: item.id,
                        is_correct: item.is_correct,
                        name: item.translations[language] ? item.translations[language].name : '',
                    }
                });
            }
            if(translations){
                update.question = translations[language]  ? translations[language].question : '';
                update.description = translations[language] ? translations[language].description : '';
            }
            update.id = id;
            update.points = points;
        }else if(nextProps.quiz){
            let {translations,answers,points,id} = nextProps.quiz;
            if(answers){
                update.answers = answers.map((item) => {
                    return {
                        id: item.id,
                        is_correct: item.is_correct,
                        name: item.translations[language] ? item.translations[language].name : '',
                    }
                });
            }
            if(translations){
                update.question = translations[language] ? translations[language].question : '';
                update.description = translations[language] ? translations[language].description : '';
            }
            update.id = id;
            update.points = points;
        }else {
            update.id = undefined;
            update.points = 1;
            update.question = '';
            update.description = '';
            update.answers = undefined;
        }

        return Object.keys(update).length ? update : null;
    }

    onChangeAnswer = (answer,index,answers,setFieldValue) => {
        if(answer.is_correct) return;
        let quiz_id = this.state.id;
        let answersArr = answers;
        answersArr.forEach((item,i) => {
            if(item.is_correct){
                item.is_correct = false;
            }
        })
        answersArr[index] = {...answer,is_correct:!answer.is_correct};
        setFieldValue(answers,answersArr)
        let newAnswers = _.filter(answers, (item) => {
            return !item.id;
        });
        if(answer.id && newAnswers.length === 0){
            let answer_data = {
                is_correct: !answer.is_correct,
            }
            this.props.updateSingleQuizAnswer(quiz_id,answer.id,answer_data).then(() => {
                this.props.getElementQuiz(this.props.element_item.id)
            })
        }
    }

    deleteAnswer = (answer,arrayHelpers,index) => {
        this.setState({is_pending:true})
        let quiz_id = this.state.id;
        !answer.is_correct && arrayHelpers.remove(index)
        if(answer.id  && quiz_id){
            this.props.deleteSingleQuizAnswer(quiz_id,answer.id).then(() => {
                this.props.getElementQuiz(this.props.element_item.id).then(()=>
                    this.setState({is_pending:false})
                )
            })
        }else {
            this.setState({is_pending:false})
        }
    }

    getNewAnswers(answers){
        let language = this.props.quiz_language;
        let newAnswers = _.filter(answers, (item) => {
            return !item.id;
        });
        return newAnswers.map((item) => {
            return {
                is_correct: item.is_correct,
                translations: {
                    [language]: {
                        name: item.name
                    }
                }
            }
        });
    }

    getExistingAnswers(answers){
        let language = this.props.quiz_language;
        let existingAnswers = _.filter(answers, (item) => {
            return item.id;
        });
        return existingAnswers.map((item) => {
            return {
                id: item.id,
                is_correct: item.is_correct,
                translations: {
                    [language]: {
                        name: item.name
                    }
                }
            }
        });

    }

    getMergedAnswers(answers){
        let quiz = this.props.quiz[0];
        let existingAnswers = this.getExistingAnswers(answers);
        return _.values(_.mergeWith({}, quiz.answers, existingAnswers, function (a, b) {
            if (_.isArray(a)) {
                return b.concat(a);
            }
        }))
    }

    handleSubmit = (values) => {
        let language = this.props.quiz_language;
        let {question,description,answers,points} = values;
        let element_id = this.props.element_item.id;
        let quiz_id = this.state.id;
        let newAnswers = this.getNewAnswers(answers);
        if(quiz_id){
            let quiz = this.props.quiz[0];
            let allLangExistingAnswers = this.getMergedAnswers(answers);
            if(newAnswers.length > 0){
                this.props.createQuizAnswers(quiz_id, newAnswers).then((res) => {
                    let newAnswersCreated = res.map((new_tem) => {
                        return {
                            id: new_tem.id,
                            is_correct: new_tem.is_correct,
                            translations:{
                                [language]:{
                                    name:new_tem.translations[language].name
                                }
                            }
                        }
                    });
                    let quiz_data = {
                        points,
                        translations: {
                            ...quiz.translations,
                            [language]: {
                                ...quiz.translations[language],
                                question, description
                            }
                        },
                        answers: [...allLangExistingAnswers, ...newAnswersCreated]
                    };
                    this.props.updateElementQuiz(element_id, quiz_id, quiz_data).then(() => {
                        this.props.getElementQuiz(element_id)
                    })
                })
            }else {
                let quiz_data = {
                    points,
                    translations: {
                        ...quiz.translations,
                        [language]: {
                            question, description
                        }},
                    answers: allLangExistingAnswers
                };
                this.props.updateElementQuiz(element_id,quiz_id,quiz_data).then(() => {
                    this.props.getElementQuiz(element_id)
                })

            }
        }else {
            let quiz_data = {points, translations: {[language]: {question,description}}, answers: newAnswers};
            this.props.createElementQuiz(element_id,quiz_data)
        }

        quiz_id ? notify('Quiz saved successfully','success') : notify('Quiz created successfully','success')

    }

    deleteQuiz = () => {
        this.setState({is_pending:true})
        let element_id = this.props.element_item.id;
        let quiz_id = this.state.id;
        !quiz_id && this.setState({is_pending:false})
        if(quiz_id){
            this.props.deleteElementQuiz(element_id,quiz_id).then(() =>
                this.setState({is_pending:false})
            )
            notify('Quiz deleted successfully','success')
        }

    }


    resetQuiz = () => {
        this.setState({
            question: '',
            description:'',
            answers: undefined,
            points: 1,
        })

        notify('Quiz reset successfully','success')

    }

    selectQuizLanguage = (name,value) => {
        this.props.setQuizLanguage(_.find(languages,['id',value]).short_name)
    }

    autoTranslateQuiz = () => {
        this.setState({loading:true})
        this.props.autoTranslateQuiz(this.props.quiz.id).then(() => {
            this.setState({loading:false})
        })
    }


    render() {
        let {validation_rules,t} = this.props;
        let {loading} = this.state;
        let quiz_id = this.state.id;
        if(!validation_rules) return null;
        let title = quiz_id ? t("Edit") : t("Create A");
        let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}}
        return (
            <DropdownMenu right>
                <Loading small={true} activeSmallLoading={loading} isTransparent/>
                <div className="d-flex align-items-center mb-3">
                    <DropdownItem className="dropdown-title dropdown-title_quiz" header>{title} <Trans>Quiz</Trans></DropdownItem>
                    <HelpGamificationButton closeDropdown={this.props.handleClose}/>
                    <button type="button" className="btn btn-close" onClick={this.props.handleClose}>
                        <ReactSVG className="btn-small-round__icon" src={CloseIcon}/>
                    </button>
                </div>
                <div className="dropdown-scroll-wrap">
                    <CustomScrollBar options={overflowOptions}>
                        <Formik
                            enableReinitialize
                            initialValues={this.state}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    this.handleSubmit(values)
                                    setSubmitting(false)
                                }, 500);
                            }}
                            validationSchema={setQuizSchema(this.props.validation_rules,t)}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue
                                } = props;
                                let question_invalid_class = errors.question && touched.question ? 'invalid' : '',
                                    desc_invalid_class = errors.description && touched.description ? 'invalid' : '',
                                    points_invalid_class = errors.points && touched.points ? 'invalid' : '';
                                return (
                                    <form onSubmit={handleSubmit} name="element-details">
                                        <div className="row">
                                            <div className="col-sm-6 col-12">
                                                <LanguageSelect data={languages} disabled={false}
                                                                onBlur={languages} setFieldValue={(name,value) => this.selectQuizLanguage(name,value)}
                                                                name={'language'} hideHelp/>
                                            </div>
                                            {/*<div className="col-sm-6 col-12 mb-sm-0 mb-4">*/}
                                            {/*    <AutoTranslate handleClick={this.autoTranslateQuiz}/>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                id="question"
                                                name="question"
                                                placeholder={t("Please enter question")}
                                                type="text"
                                                value={values.question}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`form-control input-primary d-block ${question_invalid_class}`}/>
                                            {errors.question && touched.question && (
                                                <div className="input-feedback">{errors.question}</div>
                                            )}
                                        </div>
                                        <div className="dropdown-subtitle"><Trans>Answers</Trans></div>
                                        <FieldArray
                                            name="answers"
                                            render={arrayHelpers => (
                                                <div>
                                                    {values.answers && values.answers.length > 0 && (
                                                        values.answers.map((answer, index) => (
                                                            <QuizAnswer index={index} arrayHelpers={arrayHelpers} values={values}
                                                                        answer={answer} key={index} quiz_id={quiz_id}
                                                                        onChangeAnswer={this.onChangeAnswer}
                                                                        deleteAnswer={this.deleteAnswer}
                                                                        setFieldValue={setFieldValue}/>
                                                        ))
                                                    )}
                                                    {(!values.answers || values.answers.length < 4) &&
                                                    <button type="button" className="btn btn-primary w-100 mb-3"
                                                            onClick={() => arrayHelpers.push({name:'',is_correct: !values.answers || values.answers.length === 0})}
                                                            disabled={this.state.is_pending}>
                                                        <Trans>Add new answer</Trans>
                                                    </button>}
                                                    {errors.answers && typeof errors.answers === 'string' &&
                                                    <ErrorMessage name={`answers`} render={(str) => <div className="input-feedback">{str}</div>} />}
                                                    <div>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        <div className="form-group">
                                        <textarea
                                            id="answer-description"
                                            name="description"
                                            placeholder={t("Please enter answer explanation")}
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={`form-control input-primary d-block ${desc_invalid_class}`}/>
                                            <small className="form-help-text"><Trans>Additional/ Detailed information about the correct answer, that you want the user to know</Trans></small>
                                            {errors.description && touched.description && (
                                                <div className="input-feedback">{errors.description}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                id="points"
                                                name="points"
                                                placeholder={t("Please enter points")}
                                                type="text"
                                                value={values.points}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`form-control input-primary d-block ${points_invalid_class}`}/>
                                            <small className="form-help-text"><Trans>Amount of the gems which user will earn after selecting correct answer</Trans></small>
                                            {errors.points && touched.points && (
                                                <div className="input-feedback">{errors.points}</div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                {quiz_id && <button type="button" className="btn btn-outline w-100"
                                                                    onClick={this.deleteQuiz}
                                                                    disabled={this.state.is_pending || isSubmitting}><Trans>Delete</Trans></button>}
                                                {!quiz_id && <button type="button" className="btn btn-outline w-100"
                                                                     onClick={this.resetQuiz}
                                                                     disabled={this.state.is_pending || isSubmitting}><Trans>Reset</Trans></button>}
                                            </div>
                                            <div className="col-6">
                                                <button type="submit" className="btn btn-primary w-100"
                                                        disabled={this.state.is_pending || isSubmitting}>{quiz_id ? t('Save') : t('Create')}</button>
                                            </div>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </CustomScrollBar>
                </div>


            </DropdownMenu>
        );
    }



}

const mapStateToProps = state => ({
    element_item: state.elements.element_item,
    quiz: state.elements.quiz,
    quiz_language: state.elements.quiz_language,
    validation_rules: state.validations,
    main_content_language: state.user.main_content_language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getElementAnalytics,
    setQuizSchema,
    createElementQuiz,
    deleteSingleQuizAnswer,
    getElementQuiz,
    updateElementQuiz,
    deleteElementQuiz,
    createQuizAnswers,
    updateSingleQuizAnswer,
    setQuizLanguage,
    clearQuiz,
    autoTranslateQuiz
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ElementQuiz));
