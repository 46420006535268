//element view screen showing by clicking on element card
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getExperienceItemById } from "../store/experiences";
import ExperienceVRContainer from "./ExperienceVRContainer";
import { UnityContext } from "react-unity-webgl";
//import { getAccessToken} from "../store/user";

const data = window.location.href.split("/")[3];
const business_id = data.split("-")[1];
const experience_id = data.split("-")[2];
const folder = data.split("-")[3];
const api_root = process.env.REACT_APP_API_END_POINT;
const vr_files_directory = business_id + "/unity_file/" + folder + "/Build/";
const build_name = "Build";

const unityContext = new UnityContext({
  loaderUrl: `${api_root}${vr_files_directory}${build_name}.loader.js`,
  dataUrl: `${api_root}${vr_files_directory}${build_name}.data`,
  frameworkUrl: `${api_root}${vr_files_directory}${build_name}.framework.js`,
  codeUrl: `${api_root}${vr_files_directory}${build_name}.wasm`,
});


const refreshUnity2 = () => {
  unityContext.send("StartupManager", "SetIsUser", 'False');
  // unityContext.send("GalleryManager", "ExperienceID", experience_id);
  // unityContext.send("GalleryManager", "AuthToken", getAccessToken());
  // unityContext.send("GalleryManager", "RefreshScene");
};

class ExperienceVR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business_id: 0,
      experience_id: 0,
      experience_status: false
    };
  }

  componentDidMount() {
    if (experience_id) {
      this.props
        .getExperienceItemById(experience_id)
        .then((res) => {
          //console.log(res);
          this.setState({
            experience_status: res.is_published,
          });
          refreshUnity2();
        })
        .catch((err) => {
          this.props.history.push("/");
        });
    }
  }

  render() {
    return (
      <div>
        <ExperienceVRContainer
          {...this.props}
          experience_status={this.state.experience_status}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  experience_id: experience_id,
  business_id: business_id,
  unityContext: unityContext,
  refreshUnity2: refreshUnity2
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getExperienceItemById
    },
    dispatch
  );

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(ExperienceVR)
);
