import {UnityContext} from "react-unity-webgl";
import {getAccessToken} from "../../store/user";

const api_root = process.env.REACT_APP_API_ROOT;


//only for beta engine
const vr_files_directory = '/public/VR/Build/';
const build_name = 'Build';

// const vr_files_directory = '/public/VR_FILES/Build/';
// const build_name = 'PaintingMVP_April30';
export const unityContext = new UnityContext({
    loaderUrl: `${api_root}${vr_files_directory}${build_name}.loader.js`,
    dataUrl: `${api_root}${vr_files_directory}${build_name}.data`,
    frameworkUrl: `${api_root}${vr_files_directory}${build_name}.framework.js`,
    codeUrl: `${api_root}${vr_files_directory}${build_name}.wasm`,
});

export const sendAuthTokenToUnity = () => {
    unityContext.send("GalleryManager", "AuthToken", getAccessToken());
}

export const sendExperienceIdToUnity = (experience_id) => {
    unityContext.send("GalleryManager", "ExperienceID", experience_id);
}

export const refreshUnity = () => {
    unityContext.send("GalleryManager", "RefreshScene");
}

export const refreshUnity2 = (experience_id) => {
    unityContext.send("GalleryManager", "ExperienceID", experience_id);
    unityContext.send("GalleryManager", "AuthToken", getAccessToken());
    unityContext.send("GalleryManager", "RefreshScene");
}
