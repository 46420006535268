import React from "react";

function EditUrlResource(props) {
  let {
    editMode,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    values,
    t,
    handleChange,
    name_invalid_class,
   // toggleEditMode,
    resetFile,
    updateFile,
    //resource_item,
    onBlurSave,
  } = props;
  return (
    <div className="row mb-xl-5 mb-4">
      <div className="col">
        <div className="form-group">
          <input
            id="name"
            placeholder={t(`Enter url`)}
            type="text"
            value={values.name}
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              onBlurSave(errors.name, values.name, "name");
            }}
            className={`form-control input-primary d-block ${name_invalid_class}`}
          />
          {errors.name && touched.name && (
            <div className="input-feedback">{errors.name}</div>
          )}
        </div>
        
        <div className="edit-media-wrapper">
          {editMode && (
            <button
              type="button"
              onClick={() => resetFile(setFieldValue)}
              className="btn btn-outline mr-3"
            >
              Reset
            </button>
          )}
          {editMode && values.file_text && !errors.file_text && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => updateFile(values, errors)}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditUrlResource;
