import React from 'react';
import _ from "lodash";
import {languages} from "../../../common";
import ReactSVG from "react-svg";
import {renderFlag} from "../../../store/user";

function ResourceCardFlags(props) {
    let {resource_item} = props;
    let flag_ids = [];
    if(resource_item.translations){
        Object.keys(resource_item.translations).forEach((key) => {
            let flag_obj = _.find(languages, ['short_name',key]);
            if(flag_obj){
                if(flag_obj.id) return flag_ids.push(flag_obj.id);
            }
        })
    }

    return <div className="resource__item-flags-list">
        {flag_ids.map((id,i) => {
            return <ReactSVG className="resource__item-flag"
                             src={renderFlag(id)} key={i}/>
        })}
    </div>
}

export default ResourceCardFlags;