import React from "react";
import ResourcesIcons from "../../ResourcesIcons";
import {Trans} from 'react-i18next';
function VerifiedResources(props) {

    return(
        props.verified_resource_types && props.verified_resource_types.length > 0 ?
            <div className="contractor-info__verification">
                <div className="contractor-profile__title"><Trans>Verified for</Trans></div>
                <div className="contractor-info__verification-icons">
                    <ResourcesIcons resources={props.verified_resource_types}/>
                </div>
            </div>
        : <div className="contractor-profile__title"><Trans>Not verified yet</Trans></div>
    )
}

export default VerifiedResources;
