import React from "react";
import CardsList from "../CardsList";
import JobCard, {jobStatuses} from "./JobCard/JobCard";
import CustomScrollBar from "../../../components/CustomScrollBar";
import InfiniteScrollContainerNew from "../../../components/InfiniteScrollContainerNew";
import {userRoles} from "../../../common";
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";

function JobsList(props) {
    let overflowVerticalOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;

    const getItems = (newPage) => {
        let getItems = props.onlyOpen ? props.getOnlyOpenJobs : props.getJobs;
        let $openStatusId = jobStatuses.find(job => job.name === 'Open for bidding').id;
        let contractor_id = (!props.onlyOpen && isContractor) && props.user_info.id;
        let business_id =  !isContractor && props.business_info.id;
        return new Promise((resolve,reject) => {
            getItems({contractor_id,business_id, page: newPage, status_id: props.onlyOpen ? [$openStatusId] : []})
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        })
    }

    return(
        <ErrorBoundaryContainer>
            <CustomScrollBar className="cards-list-offset" options={overflowVerticalOptions}>
                <InfiniteScrollContainerNew getItems={getItems}>
                    <CardsList ItemComponent={JobCard} items={props.onlyOpen ? props.open_jobs : props.jobs} {...props}/>
                </InfiniteScrollContainerNew>
            </CustomScrollBar>
        </ErrorBoundaryContainer>
    )
}

export default JobsList;
