//Custom select component using instead of default select tag
import React from 'react';
import Select  from "react-select";
import * as _ from "lodash";
import {themes} from "../../common";

export const resourcesOptions = [
    {
        id: 1,
        name: 'Image',
        type_id:1
    },
    {
        id: 2,
        name: 'Video',
        type_id:2
    },
    {
        id: 3,
        name: 'Audio',
        type_id:3
    },
    {
        id: 4,
        name: 'Text',
        type_id:6
    },
    {
        id: 5,
        name: 'Model',
        type_id:7
    },
    {
        id: 6,
        name: 'Animation',
        type_id:9
    },

];

function ResourceTypeSelect(props) {

    let {name,disabled,setFieldValue, value,menuPlacement,dark_mode,isDark,classes,t} = props;
    let theme = dark_mode ? themes['dark-mode'] : themes['light-mode']

    let optionsArray = [];
    resourcesOptions.map((item) => {
        return optionsArray.push({ value: item.id, label: t(item.name),type_id:item.type_id})
    })
    let currentValue = _.find(optionsArray, ['value', value]);

    return (
        <div className={`select-language__wrap ${classes}`}>
            <Select
                isMulti={props.isMulti}
                // defaultValue={!noDefaultValue ? (main_content_language ? _.find(optionsArray,['short_name',main_content_language]) : optionsArray[0]) : undefined}
                className="select-language"
                classNamePrefix="select-language"
                name={name}
                isDisabled={disabled}
                value={currentValue}
                onChange={option => setFieldValue(name, props.isMulti ? option : option.value)}
                options={optionsArray}
                placeholder={t('Select resource type')}
                styles={{
                    option: (provided, state) => ({
                        ...provided,
                        border: 'none',
                        color: state.isSelected ? theme.text_color : theme.lang_select_text_inactive,
                        padding: 8,
                        display: 'flex',
                        alignItems: 'center'
                    }),
                    singleValue: (provided, state) => ({
                        ...provided,
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.text_color
                    }),
                    valueContainer: (provided, state) => ({
                        ...provided,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        padding: '2px 16px',
                        display: 'flex',
                        alignItems: 'center'

                    }),
                    control: (provided, state) => ({
                        ...provided,
                        backgroundColor: isDark ? theme.lang_select_bg_dark : theme.lang_select_bg,
                        borderWidth: 0,
                        borderRadius: '25px',
                        outline: 'none',
                        minHeight: '36px',
                        boxShadow: 'none',
                        display: 'inline-flex',
                        alignItems: 'center',
                        minWidth: '200px'
                    }),
                    indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: 'none',
                    }),
                    dropdownIndicator: (provided, state) => ({
                        ...provided,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 0,
                        padding: '2px 16px',
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        width: 'auto',
                        minWidth: '200px',
                        backgroundColor: theme.lang_select_bg,
                    })
                }}
                menuPlacement={menuPlacement}
            />
        </div>
    )
}


export default ResourceTypeSelect;
