import React from 'react'
import CustomScrollBar from "../../CustomScrollBar";
import {Field, Formik} from "formik";
import {setResourceSchema} from "../../../store/validations";
import {languages} from "../../../common";
import {TabPane} from "reactstrap";
import LanguageSelect from "../../LanguageSelect";
import FileUploader from "../../FileUploader";
import RequestCreationResourceButton from "./RequestCreationResourceButton";
import {Trans, withTranslation} from "react-i18next";

function UploadFileResourceTab(props) {
    let {create_resources_mode,initialValues,validation_rules,handleSubmit,tabId,resource_type_id,media_type,t,selectResourceLanguage} = props;
    let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}}

    return(
        <TabPane tabId={tabId} className="tab-pane">
            <CustomScrollBar options={overflowOptions}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            handleSubmit(values);
                            setSubmitting(false)
                        }, 500);
                    }}
                    validationSchema={setResourceSchema(create_resources_mode,false,validation_rules,false,false, media_type,false,t)}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        } = props;
                        let name_invalid_class = errors.name && touched.name ? 'invalid' : '';
                        return (
                            <form onSubmit={handleSubmit} name="create-resource">
                                <div className="row">
                                    <div className="col">
                                        <LanguageSelect data={languages} disabled={false} value={values.language}
                                                        onBlur={languages} setFieldValue={(name,value) => selectResourceLanguage(name,value)}
                                                        name={'language'} isDark/>
                                        <Field
                                            id="any-type-file"
                                            name="file"
                                            component={FileUploader}
                                            setFieldValue={setFieldValue}
                                            errorMessage={errors["file"] ? errors["file"] : undefined}
                                            touched={touched["file"]}
                                            onBlur={handleBlur}
                                        />
                                        <div className="note-text mb-3"><Trans>To use pre existing files as resources, please upload here...</Trans></div>
                                        <div className="form-group">
                                            <input
                                                id="file-resource-name"
                                                name="name"
                                                placeholder={t(`Enter resource name`)}
                                                type="text"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`form-control input-primary d-block ${name_invalid_class}`}/>
                                            {errors.name && touched.name && (
                                                <div className="input-feedback">{errors.name}</div>
                                            )}
                                        </div>
                                        {/*{!create_resources_mode && <TagsInput tags={this.state.tags || []} handleChange={this.handleTagsChange}/>}*/}
                                        <div className="d-flex flex-row justify-content-start align-items-center">
                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                <span className=""><Trans>Save</Trans></span>
                                            </button>
                                            {resource_type_id && <RequestCreationResourceButton type_id={resource_type_id}/>}
                                        </div>

                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </CustomScrollBar>
        </TabPane>
    )
}

export default withTranslation('translations')(UploadFileResourceTab);