import React from "react";
// import Tags from "../../../../components/Tags";
import JobCardHeader from "./JobCardHeader";
import moment from 'moment';
import {userRoles} from "../../../../common";
import {Trans} from 'react-i18next';

function JobCardInner(props){
    if(!props.item_info) return null;
    let {title,created_at,bids_count,new_bids_count} = props.item_info;
    let $created_at = moment(created_at).fromNow();
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;
    let newBids = !isContractor && new_bids_count > 0 ? <span className="job-card__bids-count_new"> ({new_bids_count} new)</span> : '';
    return(
        <>
            <JobCardHeader item_info={props.item_info}/>
            <div className="job-card__body">
                <div className="job-card__name">{title}</div>
                {/*<Tags tags={tags} classes={"job-card__tags"}/>*/}
            </div>
            <div className="job-card__footer">
                <div className="job-card__date"><Trans>Posted {{$created_at}}</Trans></div>
                {!props.useAsHeader && <div className="job-card__bids-count">{bids_count} bids
                    {newBids}
                </div>}
            </div>
        </>
    )
}

export default JobCardInner;
