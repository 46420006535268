import React from 'react';
import {NavItem, NavLink} from "reactstrap";
import classnames from "classnames";
import ReactSVG from "react-svg";

function ResourceNavtab(props) {
    let {activeTab,tabId,handleToggle,icon,data_tooltip,type_id} = props;
    return(
        <NavItem>
            <NavLink className={classnames({ active: activeTab === tabId },'custom-tooltip custom-tooltip_bottom')}
                     onClick={() => { handleToggle(tabId,type_id); }}
                     data-tooltip={data_tooltip}>
                <ReactSVG className="btn-small-round__icon" src={icon}/>
            </NavLink>
        </NavItem>
    )
}

export default ResourceNavtab;
