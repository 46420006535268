//Register screen with form
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCurrentResource } from "../../store/resources";
import {
  checkExistEmail,
  getUserInfo,
  signIn,
  signUp,
  sendOtp,
  checkOtp,
} from "../../store/user";
import { getValidationRules, 
  //setSignUpSchema 
} from "../../store/validations";
import { Field, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";

class DeleteAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
    };
  }

  componentDidMount() {
    this.props.getValidationRules("dashboard");
  }

  getOtp = (values) => {
    let email = this.state.email;
    this.props.sendOtp(email).then((Message) => {
      if (Message.status.code === 400 && Message.data.email) {
        this.setState({ exist_email_error: Message.data.email.toString() });
      } else {
        this.setState({ exist_email_error: Message.message.description });
      }
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (values) => {
    
    let email = this.state.email;
    let otp = this.state.otp;

    this.props.checkOtp(email, otp).then((Message) => {
        if (Message.status.code === 400 && Message.data.email) {
          this.setState({ exist_email_error: Message.data.email.toString() });
        } else if (Message.status.code === 400 && Message.data.otp) {
          this.setState({ exist_email_error: Message.data.otp.toString() });
        } else {
          this.setState({ exist_email_error: Message.message.description });
        }   
      });
  };

  render() {
    let { exist_email_error } = this.state;
    // let { t } = this.props;
    // let language = this.props.i18n.language;

    return (
      <div className="row auth-wrap">
        <div className="col-md-4 order-md-1 col-12 order-2">
          <Formik
            initialValues={this.state}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                this.handleSubmit(values);
              }, 500);
            }}
            //validationSchema={setSignUpSchema(this.props.validation_rules, t)}
          >
            {(props) => {
              const {
                //values,
                touched,
                errors,
                handleSubmit,
              } = props;
              
              return (
                <form onSubmit={handleSubmit} className="form-primary">
                  <div className="flex-grow-1 justify-content-center align-items-center d-flex flex-column w-100">
                    <div className="title line-height-normal">
                      <Trans>Delete User Account</Trans>
                    </div>
                    <div className="subtitle"></div>
                    <div className="row">
                      {(exist_email_error) && (
                        <div className="input-feedback">
                          {exist_email_error}
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <Field
                          name="email"
                          className={`form-control input-primary d-block`}
                          placeholder="Enter User Email"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <input
                          type="button"
                          className="btn btn-primary mb-3"
                          style={{ "min-width": "132px", "max-width": "132px" }}
                          onClick={this.getOtp}
                          value="Get OTP"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <Field
                        name="otp"
                        className={`form-control input-primary d-block`}
                        placeholder="Enter Otp"
                        onChange={this.handleInputChange}
                      />
                      {errors.password && touched.password && (
                        <div className="input-feedback">{errors.password}</div>
                      )}
                    </div>
                    <button type="submit" className="btn btn-primary mb-3">
                      <Trans>Submit</Trans>
                    </button>
                  </div>

                  <p className="copyright mb-0">
                    &copy; 2023 RealMeta <Trans>All Rights Reserved</Trans>
                  </p>
                </form>
              );
            }}
          </Formik>
        </div>
        <div className="col-md-8 col-12 order-md-2 order-1">
          <div className="auth__content auth__content_right">
            <div className="flex-grow-1 justify-content-center align-items-center d-flex flex-column">
              <h1 className="title main-title text-uppercase text-center">
                <Trans>
                Let’s create your < br/> account
                </Trans>
              </h1>
              <h2 className="subtitle text-uppercase mb-5 text-center">
                <Trans>It’s only a few clicks away!</Trans>
              </h2>
              {/*<Link to="https://www.3rdilab.com/" target="_blank" className="btn btn-primary btn-outline">Learn more</Link>*/}
            </div>
            <a
              href="https://realmeta.ca/"
              className="link link-primary bottom-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Visit</Trans> www.realmeta.ca
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user_info: state.user.user_info,
  validation_rules: state.validations,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentResource,
      signIn,
      getValidationRules,
      signUp,
      checkExistEmail,
      getUserInfo,
      sendOtp,
      checkOtp
    },
    dispatch
  );

export default withRouter(
  withTranslation("translations")(
    connect(mapStateToProps, mapDispatchToProps)(DeleteAccount)
  )
);
