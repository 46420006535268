import React, {useEffect, useState} from 'react';
import CommentItem from "./CommentItem";
import CustomScrollBar from "../../../components/CustomScrollBar";
import SendIcon from "../../../images/send-icon.svg";
import ReactSVG from "react-svg";
import FormWrapper from "../../../components/FormWrapper";
import {useFormik} from "formik";
import AttachButton from "../AttachButton";
import {withTranslation} from 'react-i18next';
import {scrollToTop} from "../../../common";
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";

export const commentStatuses = {
    sent: {
        id: 0,
        name:'Draft'
    },
    delivered:{
        id: 1,
        name:'Open for bidding'
    },
    read:{
        id: 2,
        name:'Assigned'
    },
}

function Comments (props) {
    let formik = useFormik({
        initialValues: {
            message: '',
            files: null
        },
        enableReinitialize: true,
        // validationSchema: setJobSchema(props.validation_rules,props.t),
        onSubmit: (values, {resetForm}) => {
            handleSubmit(values,resetForm)
        },
    });

    useEffect(() => {
        scrollToTop('comments-box')
    }, [props.comments])

    const handleSubmit = () => {
        setPending(true)
        let {message,files} = formik.values;
        props.onCreateComment(message).then((res) => {
            if(files){
                props.attachFilesToComments(res.uuid,files).then(() => {
                    props.getBidComments({bid_id:props.bid_item.id})
                })
            }
            formik.resetForm()
            setPending(false)
        })
    }
    const [pending, setPending] = useState(false)
    return(
        <ErrorBoundaryContainer>
            <div className="comments-section">
                <div className="separator"/>
                <CustomScrollBar id={'comments-box'}>
                    <div className="comments-section__inner-offset">
                        {props.comments.map((comment_item) => {
                            return <CommentItem data={comment_item} user_info={props.user_info}/>
                        })}
                    </div>
                </CustomScrollBar>
                <FormWrapper formik={formik} formName={'edit-resource'}>
                    <div className="comments-section__footer">
                        <input className="input-primary comments-section__input"
                               type="text" placeholder={props.t("Type your comment")}
                               name="message"
                               id="message"
                               value={formik.values.message || ''}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}/>
                        <div className="comments-section__footer-buttons">
                            <AttachButton formik={formik}/>
                            <button type="submit" className="btn p-0" disabled={!formik.values.message || pending}>
                                <ReactSVG className="ml-2" src={SendIcon}/>
                            </button>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        </ErrorBoundaryContainer>
    )
}

export default withTranslation('translations')(Comments);
