//Identifier item for element. Using on identifier data page
import React from 'react';
import CustomScrollBar from "../CustomScrollBar";
import ReactSVG from "react-svg";
import CloseIcon from "../../images/close-icon.svg";
import {Field, Formik} from "formik";
import { getValidationRules, setIdentifierSchema } from "../../store/validations";
import MediaRecorder from "../resource/CreateResource/MediaRecorder";
import VideoRecorder from "../resource/CreateResource/VideoRecorder";
import FileUploader from "../FileUploader";
import {isImage, elementTypes} from "../../common";
import {setLoading} from "../../store/user";
import {
    addIdentifierToElement,
    clearIdentifiers, getElementItemById,
    getIdentifiers, setCreateIdentifierMode,
    setIdentifier
} from "../../store/elements";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import videoPreview from "../../images/audio-preview.png";
import {Nav, Progress} from "reactstrap";
import { Trans, withTranslation } from "react-i18next";
import { NavItem, NavLink } from "reactstrap";
import TakePhotoIcon from "../../images/resource-icon-tabs/take-photo-icon.svg";
import TakeVideoIcon from "../../images/resource-icon-tabs/take-video-icon.svg";
import classnames from "classnames";
import Modal from './Modal';

class CreateIdentifier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      file: undefined,
      preview_url: undefined,
      identifiers: [],
      files: [],
      emptyFileUploader: undefined,
      pending: false,
      status: 0,
      media_type: "image",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let update = {};
    if (nextProps.identifiers !== prevState.identifiers) {
      update.identifiers = nextProps.identifiers;
    }
    return Object.keys(update).length ? update : null;
  }

  handleSubmit = (values) => {
    let { element_item, addIdentifierToElement } = this.props,
      { file, files } = values;
    
    if (files.length > 1) {
      files.forEach((fi, i) => { 
          if (fi) {
            this.setState({ fi, pending: true });
            let reader = new FileReader();
            reader.onloadend = () => {
              this.setState({
                preview_url: isImage(fi) ? reader.result : videoPreview,
              });
            };
            reader.readAsDataURL(fi);
            addIdentifierToElement(element_item.id, fi).then(() => {
              let identifiers_state = this.state.identifiers;
              this.setState({
                identifiers: identifiers_state.unshift({
                  name: fi.name,
                  preview_url: this.state.preview_url,
                  format: fi.format,
                }),
                file: undefined,
                preview_url: undefined,
                pending: false,
              });
              this.props.getElementItemById(this.props.element_item.id);
              this.props.getIdentifiers(this.props.element_item.id);
            });
          }
      })
    } else { 
      if (file) {
        this.setState({ file, pending: true });
        let reader = new FileReader();
        reader.onloadend = () => {
          this.setState({
            preview_url: isImage(file) ? reader.result : videoPreview,
          });
        };
        reader.readAsDataURL(file);
        addIdentifierToElement(element_item.id, file).then(() => {
          let identifiers_state = this.state.identifiers;
          this.setState({
            identifiers: identifiers_state.unshift({
              name: file.name,
              preview_url: this.state.preview_url,
              format: file.format,
            }),
            file: undefined,
            preview_url: undefined,
            pending: false,
          });
          this.props.getElementItemById(this.props.element_item.id);
          this.props.getIdentifiers(this.props.element_item.id);
        });
      }
    }
  };

  closeCreateIdentifier = () => {
    this.props.setIdentifier(undefined);
    this.props.setCreateIdentifierMode(false);
  };

  changeStatus = (status) => {
    console.log(status);
    this.setState({ status });
  };

  handleResetForm = (resetForm) => {
    this.changeStatus(0);
    resetForm(this.props.initialValues);
    this.state.player.record().getDevice();
    if (this.state.media_type === "image") {
      this.setState({ media_type: "video" });
      setTimeout(() => this.setState({ media_type: "image" }), 200);
    } else {
      this.setState({ media_type: "image" });
      setTimeout(() => this.setState({ media_type: "video" }), 200);
    }
    
  };

  resetPlayer = (player) => {
    this.setState({ player });
  };

  render() {
    let overflowOptions = { overflowBehavior: { x: "hidden", y: "scroll" } };
    const { element_item, progress_percent, t } = this.props;
    const { pending } = this.state;
    let { status } = this.state;
    if (!element_item) return null;
    let is2dElement = element_item.type_id === elementTypes.element2d;
    let helpText = is2dElement
      ? "Supported Image Formats are .jpg / .jpeg and .png"
      : "Supported Video Formats are .mp4 and .mov";
    
    return (
      <div className="element-half-section">
        <div className="row subheader">
          <div className="col-md-10 d-flex align-items-center">
            <span className="subheader__title">
              <Trans>Add Identifier</Trans>
            </span>
            {/* <SmallRoundButton icon={InfoIcon} classes={'ml-md-4 ml-sm-0 btn-small-round_dark'}/> */}
          </div>
          <div className="col-md-2 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-close"
              onClick={this.closeCreateIdentifier}
            >
              <ReactSVG className="btn-small-round__icon" src={CloseIcon} />
            </button>
          </div>
        </div>
        {pending && progress_percent > 0 && (
          <div>
            <p className="title mb-4 progress-text">
              <Trans>Please wait, identifier is uploading...</Trans>
            </p>
            <Progress animated value={progress_percent} />
          </div>
        )}
        {!pending && (
          <CustomScrollBar options={overflowOptions}>
            <div className="row mb-5">
              <div className="col">
                <div className="training__details">
                  <p className="training__details-title">{helpText}</p>
                  {
                    <Formik
                      enableReinitialize
                      initialValues={this.state}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          this.handleSubmit(values);
                          setSubmitting(false);
                        }, 500);
                      }}
                      validationSchema={setIdentifierSchema(
                        this.props.validation_rules,
                        element_item.type_id,
                        t
                      )}
                    >
                      {(props) => {
                        const {
                          values,
                          touched,
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          setValues,
                          resetForm,
                        } = props;
                        let name_invalid_class =
                          errors.name && touched.name ? "invalid" : "";
                        return (
                          <form onSubmit={handleSubmit}>
                            <Field
                              name="file"
                              component={FileUploader}
                              setFieldValue={setFieldValue}
                              errorMessage={
                                errors["file"] ? errors["file"] : undefined
                              }
                              touched={touched["file"]}
                              onBlur={handleBlur}
                              classes={"text-lg-center text-left"}
                              progress_percent={progress_percent}
                              //changeStatus={this.changeStatus}
                            />
                            <div className="note-text mb-3">
                              <Trans>
                                To use pre existing files as identifier, please
                                upload here...
                              </Trans>
                            </div>
                            <div className="note-text-big mb-2">
                              <Trans>
                                Or create a identifier from your device
                              </Trans>
                            </div>
                            <Nav
                              tabs
                              className="nav nav-tabs tabs-primary tabs-round tabs-icon"
                            >
                              <NavItem>
                                <NavLink
                                  className={classnames(
                                    {
                                      active: this.state.media_type === "image",
                                    },
                                    "custom-tooltip custom-tooltip_bottom"
                                  )}
                                  onClick={() =>
                                    this.setState({ media_type: "image" })
                                  }
                                  data-tooltip="Take Photo"
                                >
                                  <ReactSVG
                                    className="btn-small-round__icon"
                                    src={TakePhotoIcon}
                                  />
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames(
                                    {
                                      active: this.state.media_type === "video",
                                    },
                                    "custom-tooltip custom-tooltip_bottom"
                                  )}
                                  onClick={() =>
                                    this.setState({ media_type: "video" })
                                  }
                                  data-tooltip="Take Video"
                                >
                                  <ReactSVG
                                    className="btn-small-round__icon"
                                    src={TakeVideoIcon}
                                  />
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <Modal
                              images={values.files}
                              setFieldValue={setFieldValue}
                            ></Modal>
                            <br />
                            {this.state.media_type === "image" && (
                              <MediaRecorder
                                setValues={setValues}
                                media_type="image"
                                values={values}
                                changeStatus={this.changeStatus}
                                resetPlayer={this.resetPlayer}
                              />
                            )}
                            {this.state.media_type === "video" && (
                              <MediaRecorder
                                setValues={setValues}
                                media_type="video"
                                values={values}
                                changeStatus={this.changeStatus}
                                resetPlayer={this.resetPlayer}
                              />
                            )}
                            <div className="form-group">
                              <input
                                id="media-file"
                                name="file"
                                placeholder={t(`Enter identifier name`)}
                                type="hidden"
                                value={values.files}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`form-control input-primary d-block`}
                              />
                              {errors.file && touched.file && (
                                <div className="input-feedback">
                                  {errors.file}
                                </div>
                              )}
                            </div>
                            {values.file && (
                              <div className="form-group">
                                <input
                                  id="media-resource-name"
                                  name="name"
                                  placeholder={t(`Enter identifier name`)}
                                  type="text"
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={`form-control input-primary d-block ${name_invalid_class}`}
                                />
                                {errors.name && touched.name && (
                                  <div className="input-feedback">
                                    {errors.name}
                                  </div>
                                )}
                              </div>
                            )}
                            {/* {values.file && ( */}
                            <br />
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              <Trans>Upload</Trans>
                            </button>
                            {/* )} */}
                            &nbsp;&nbsp;&nbsp;
                            {values.file && (
                              <button
                                type="button"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                                onClick={() => this.handleResetForm(resetForm)}
                              >
                                <span className="">
                                  <Trans>Reset</Trans>
                                </span>
                              </button>
                            )}
                          </form>
                        );
                      }}
                    </Formik>
                  }
                </div>
              </div>
            </div>
          </CustomScrollBar>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
    element_item: state.elements.element_item,
    validation_rules: state.validations,
    progress_percent: state.elements.progress_percent,
    identifiers: state.elements.identifiers,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setLoading,
    getValidationRules,
    addIdentifierToElement,
    getIdentifiers,
    clearIdentifiers,
    setIdentifier,
    setCreateIdentifierMode,
    getElementItemById
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(CreateIdentifier));