//List of resources card
import React from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import ResourceCard from "./ResourceCard/ResourceCard";
import {compare} from "../../common"

class ResourceList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render() {
        const {resources,bindMode,deleteSingleResourceFromState} = this.props;
        resources.sort(compare)
        return (
            resources.map((resource_item, i) => {
                    return (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resource__item-wrap" key={i}>
                            <ResourceCard resource_item={resource_item} callbackCheck={this.props.callbackCheck} bindMode={bindMode}
                                          deleteSingleResourceFromState={deleteSingleResourceFromState}/>
                        </div>
                    )
                })
        );
    }
}

const mapStateToProps = state => ({
    delete_resources_mode: state.resources.delete_resources_mode,
    selected_delete_resources: state.resources.selected_delete_resources,
});

const mapDispatchToProps = dispatch => bindActionCreators({
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResourceList);

