import React from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import moment from 'moment';

function DatePickerInput(props) {
    let due_at_invalid_class = props.formik && props.formik.errors.due_at && props.formik.touched.due_at ? 'invalid' : '';
    const dateChanged = day => props.formik.setFieldValue('due_at',day);

    return (
        <>
            <DayPickerInput
                formatDate={formatDate}
                parseDate={parseDate}
                onDayChange={dateChanged}
                value={moment(props.formik.values.due_at).format('DD/MM/YYYY')}
                inputProps={{
                    className:`input-primary w-100 ${due_at_invalid_class}`,
                    id: "job-due_at",
                    name:"due_at",
                    placeholder: props.t(`Enter job due date`),
                    type:"text",
                    onChange: event => dateChanged(event.target.value),
                    onBlur: props.formik.handleBlur
                }}
                // showOverlay //for styling
            />
            {props.formik && props.formik.errors.due_at && props.formik.touched.due_at && (
                <div className="input-feedback">{props.formik.errors.due_at}</div>
            )}
        </>

    );
}

export default DatePickerInput;
