import React, {useState} from "react";
import "../../styles/components/table.css";
import * as api from "../../api";
import { handleErrorsFromCatch } from "../../store/errors";

function Elements(props) {
  let { number, elements } = props;
  console.log(elements.name);
  let buttontext = elements.active === 1 ? "UnPublish" : "Publish";
  const [text, setText] = useState(buttontext);
  const handleChange = (element_id, text) => {
    console.log("element_id--->", element_id);
    let bol = (text === "UnPublish") ? false : true;
    let changetext = text === "UnPublish" ? "Publish" : "UnPublish";
      let element_updated = { active: bol };
      return new Promise((resolve, reject) => {
          api
            .fetchUpdateElementData(element_id, element_updated)
            .then((responseJson) => {
              resolve(responseJson);
              setText(changetext);
            })
            .catch((error) => handleErrorsFromCatch(error));
      });
  };

  return (
    <tr key={number}>
      <td>{number + 1}</td>
      <td>{elements.name}</td>
      <td>{elements.description}</td>
      <td>{elements.ide}</td>
      <td>
        {" "}
        <button
          className="btn btn-primary w-auto"
          disabled={
            !elements.name ||
            !elements.description ||
            elements.ide < 1 ||
            (elements.ide === 1 && elements.idt_delete !== null)
              ? true
              : false
          }
          onClick={() => handleChange(elements.id, text)}
        >
          {text}
        </button>
      </td>
    </tr>
  );
}

export default Elements;
