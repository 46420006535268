import * as api from "../api";
import {handleErrors} from "./errors";
import {addToList, deleteFromListById, updateListById} from "../common";
import {bidStatuses} from "../routes/marketplace/bids/BidCard";

const SET_BIDS = "SET_BIDS",
  SET_SUBMITTED_BIDS = "SET_SUBMITTED_BIDS",
  SET_BID_COMMENTS = "SET_BID_COMMENTS",
  SET_CURRENT_BID = "SET_CURRENT_BID",
  SET_ORDERS = "SET_ORDERS",
  SET_CURRENT_ORDER = "SET_CURRENT_ORDER";

const initialState = {
    bids: [],
    submitted_bids: [],
    bid_item: undefined,
    bid_comments: [],
    orders:[],
    order_item:[]
};

export const getBids = (params) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetBids(params)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setBidsAction([...getState().bids.bids,...responseJson.data]))
                    resolve(responseJson)
                })
        })
    }

export const getOnlySubmittedBids = (params) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetBids(params)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setSubmittedBidsAction([...getState().bids.submitted_bids,...responseJson.data]))
                    resolve(responseJson)
                })
        })
    }

export const attachFilesToBid = (bid_id,files) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchAttachFilesToBid(bid_id,files)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    // let currentAttachments = getState().jobs.job_attachments;
                    // let $attachments = [...currentAttachments, ...responseJson.data];
                    // dispatch(setJobAttachmentsAction($attachments))
                    resolve()
                })
        })
    }

export const getBidItem = (bid_id) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetBidItem(bid_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setCurrentBidAction(responseJson.data))
                    resolve(responseJson.data)
                })
        })
    }

export const approveBid = (job_id,bid_id) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchApproveBid(job_id,bid_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
        })
    }

export const declineBid = (job_id,bid_id) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchDeclineBid(job_id,bid_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
        })
    }

export const createBid = (data) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchCreateBid(data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
        })
    }

export const updateBidStatus = (status_id,bid_id) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchUpdateBidStatus(status_id,bid_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let $openStatus = bidStatuses.find(item => item.name === 'Submitted').id;
                    let $draftStatus = bidStatuses.find(item => item.name === 'Draft').id;
                    let bidsList = getState().bids.bids;
                    let submittedBidsList = getState().bids.submitted_bids;
                    if(status_id === $openStatus){
                        updateListById(bidsList,bid_id,responseJson)
                        addToList(submittedBidsList,responseJson)
                    }else if(status_id === $draftStatus){
                        deleteFromListById(submittedBidsList,bid_id)
                        updateListById(bidsList,bid_id,responseJson)
                    }
                    dispatch(setCurrentBidAction(responseJson.data))
                    dispatch(setBidsAction(bidsList))
                    dispatch(setSubmittedBidsAction(submittedBidsList))
                    resolve(responseJson.data)
                })
        })
    }

export const getBidComments = (params) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetBidComments(params)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setBidCommentsAction(responseJson.data))
                    resolve(responseJson)
                })
        })
    }


export const clearBidComments = () =>
    (dispatch) => {
        dispatch(setBidCommentsAction(initialState.bid_comments))
    }

export const createBidComment = (bid_id,message) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchCreateBidComment(bid_id,message)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setBidCommentsAction([...getState().bids.bid_comments,responseJson.data]))
                    resolve(responseJson.data)
                })
        })
    }

export const getOrders = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api
      .fetchGetOrders(params)
      .then((res) => handleErrors(res, true, dispatch))
      .then((responseJson) => {
        dispatch(
          setOrdersAction([...getState().bids.orders, ...responseJson.data])
        );
        resolve(responseJson);
      });
  });
};

export const getOrderItem = (bid_id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .fetchGetBidItem(bid_id)
      .then((res) => handleErrors(res, true, dispatch))
      .then((responseJson) => {
        dispatch(setCurrentOrderAction(responseJson.data));
        resolve(responseJson.data);
      });
  });
};
const setBidCommentsAction = (bid_comments) => ({
    type: SET_BID_COMMENTS,
    bid_comments
});

export const setCurrentBid = (bid_item) =>
    (dispatch) => {
        dispatch(setCurrentBidAction(bid_item));
    };

export const clearBids = () =>
    (dispatch) => {
        dispatch(setBidsAction(initialState.bids));
        dispatch(setSubmittedBidsAction(initialState.submitted_bids));
    };

const setBidsAction = (bids) => ({
        type: SET_BIDS,
        bids
});

const setSubmittedBidsAction = (submitted_bids) => ({
        type: SET_SUBMITTED_BIDS,
        submitted_bids
});

const setCurrentBidAction = (bid_item) => ({
        type: SET_CURRENT_BID,
        bid_item
});

const setOrdersAction = (orders) => ({
  type: SET_ORDERS,
  orders,
});

const setCurrentOrderAction = (order_item) => ({
  type: SET_CURRENT_ORDER,
  order_item,
});

const ACTION_HANDLERS = {
  [SET_CURRENT_BID]: (state, action) => ({
    ...state,
    bid_item: action.bid_item,
  }),
  [SET_BIDS]: (state, action) => ({
    ...state,
    bids: action.bids,
  }),
  [SET_SUBMITTED_BIDS]: (state, action) => ({
    ...state,
    submitted_bids: action.submitted_bids,
  }),
  [SET_BID_COMMENTS]: (state, action) => ({
    ...state,
    bid_comments: action.bid_comments,
  }),
  [SET_ORDERS]: (state, action) => ({
    ...state,
    orders: action.orders,
  }),
};


export default function bids(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
