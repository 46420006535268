import React, {Fragment} from "react";
import Button from "../../../components/buttons/Button";
import {Trans} from 'react-i18next';

function DirtyFooter(props) {
    return(
        <Fragment>
            <button type="button" className="btn btn-outline w-100 mr-3"><Trans>Reset</Trans></button>
            <Button type="submit"
                    className="btn btn-primary w-100"
                    disabled={props.formik.isSubmitting}
                    loading={props.pending || props.formik.isSubmitting}><Trans>Save</Trans></Button>
        </Fragment>
    )
}

export default DirtyFooter;
