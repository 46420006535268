import React from 'react';
import {Trans} from "react-i18next";
import "../../styles/components/table.css";

function ResourceInfo(props) {
  let { dashboard_info } = props;

  if (!dashboard_info) return null;
  let resources = dashboard_info.resource;
  let element_resource_count = dashboard_info.element_resource_count;
  
  return (
    <div className="profile-section profile-section__accent overflow-hidden">
      <div className="row">
        <div className="col-md-2 col-sm-12 profile-col">
          <div className="profile-section__title-bold">
            <Trans>Total Resources</Trans>{" "}
          </div>
          <div className="profile-section__body">
            <div
              className="profile-section__small-text"
              style={{ fontSize: "25px" }}
            >
              {resources}
            </div>
          </div>
        </div>
        <div className="col-md-10 col-sm-12 profile-col">
          <div className="profile-section__title-bold">
            <Trans>Element Wise Resources</Trans>{" "}
          </div>
          <div className="profile-section__body">
            <div className="profile-section__small-text">
              <div style={{ overflowX: "auto" }}>
                <table style={{ height: "150px" }}>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Element</th>
                      <th>Image</th>
                      <th>Video</th>
                      <th>Audio</th>
                      <th>360 Image</th>
                      <th>360 Video</th>
                      <th>Text</th>
                      <th>3D Model</th>
                      <th>Zip Files</th>
                      <th>Animation</th>
                      <th>Url</th>
                      <th>Total Count</th>
                    </tr>
                  </thead>
                  {element_resource_count.map((object, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{object.name}</td>
                      <td>{object.image}</td>
                      <td>{object.video}</td>
                      <td>{object.audio}</td>
                      <td>{object.D_Image}</td>
                      <td>{object.D_Video}</td>
                      <td>{object.Text}</td>
                      <td>{object.D_Model}</td>
                      <td>{object.Zip_Files}</td>
                      <td>{object.D_Multi_Media}</td>
                      <td>{object.Url}</td>
                      <td>{object.total_count}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceInfo;