import React from "react";
import {sidebarTypes, userRoles} from "../../../../common";
import JobCardInner from "./JobCardInner";
import {withRouter} from "react-router-dom";

export const jobStatuses = [
    {
        id: 1,
        name:'Draft'
    },
    {
        id: 2,
        name:'Open for bidding'
    },
    {
        id: 3,
        name:'Closed for bidding'
    },
    {
        id: 4,
        name:'Assigned'
    },
    {
        id: 5,
        name:'Feedback pending'
    },
    {
        id: 6,
        name:'Change acceptance pending'
    },
    {
        id: 7,
        name:'Pending others'
    },
    {
        id: 8,
        name:'Completed'
    },
    {
        id: 9,
        name:'Cancelled'
    },
    {
        id: 10,
        name:'Partially completed'
    },
    {
        id: 11,
        name:'Partially cancelled'
    },

]
function JobCard(props){
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;

    const onClick = () => {
        if(props.item_info) props.getJobAttachments(props.item_info.id)
        props.setCurrentJob(props.item_info)
        props.setSidebar(isContractor ? sidebarTypes.view_job : sidebarTypes.edit_job)
        props.setSidebarToggle(false)
    }

    return(
        <div className="col-12">
            <div className={`job-card`} onClick={onClick}>
                <JobCardInner {...props}/>
            </div>
        </div>
    )
}

export default withRouter(JobCard);
