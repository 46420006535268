import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute ({component: Component, authed,notAccessible,componentProps, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? (notAccessible ? <Redirect to={{pathname: '/'}}/> : <Component {...componentProps} />)
                : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
        />
    )
}
export default PrivateRoute;
