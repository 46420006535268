import React from "react";
import {languages} from "../../common";
import * as _ from "lodash";
import LanguageSelect from "../../components/LanguageSelect";
import {Trans, withTranslation} from 'react-i18next';

function MultipleLanguageSelect(props) {
    let field_name = props.name || 'required_languages'
    const setLanguages = (name,value) => {
        let $value = value ? value.map((item) => {
            return _.find(languages,['id',item.value]).short_name
        }) : [];
        props.formik.setFieldValue(name,$value)
        props.handleFieldChange && props.handleFieldChange(name,$value)
    }

    let $value = props.formik.values[field_name] && props.formik.values[field_name].length > 0 ? props.formik.values[field_name].map((item) => {
        return _.find(languages,['short_name',item]).id
    }) : [];

    return(
        <>
            <div className={props.titleClassName || "sidebar__title sidebar__title_offset-free"}>
                <Trans>{props.title || 'Select language'}</Trans>
            </div>
            <LanguageSelect data={languages} disabled={false}
                            onBlur={props.formik.handleBlur} setFieldValue={setLanguages}
                            name={props.name ? props.name : 'language'} isDark hideHelp classes="mb-4"
                            isMulti={true}
                            value={$value}
                            withoutLimits
                            {...props}/>
            {props.formik && props.formik.errors[field_name] && props.formik.touched[field_name] && (
                <div className="input-feedback">{props.formik.errors[field_name]}</div>
            )}
        </>
    )
}

export default withTranslation('translations')(MultipleLanguageSelect);
