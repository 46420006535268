import React from 'react';
import ReactSVG from "react-svg";

function SmallRoundButton(props) {
    let {icon,disabled,data_tooltip,activeStatus,activeClass} = props;
    return(
        <button type="button" className={`btn btn-small-round ${props.classes}`} onClick={props.onClick || null} disabled={disabled}
                data-tooltip={data_tooltip}>
            <ReactSVG className={`btn-small-round__icon ${activeStatus ? activeClass : ''}`} src={icon}/>
            {props.children}
        </button>
    );
}


export default SmallRoundButton;
