import React from 'react';
import CustomScrollBar from "../CustomScrollBar";
import {TabPane} from "reactstrap";
import InfiniteScrollContainer from "../InfiniteScrollContainer";

function ResourceTabPane(props) {
    let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}}
    return(
        <TabPane tabId={props.tabId} className="tab-pane resources-section__tab">
            <CustomScrollBar options={overflowOptions}>
                <InfiniteScrollContainer loadMore={props.loadMoreResources} hasMoreItems={props.hasMoreItems} classes="row"
                                         hasFilter={props.show_paired_resources || props.show_sub_linked_resources}>
                    {props.children}
                </InfiniteScrollContainer>
            </CustomScrollBar>
        </TabPane>
    )
}

export default ResourceTabPane;