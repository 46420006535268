//Button for multiple delete experiences
//After first click on the button delete_experiences_mode will active after second click will show confirm modal
//By click on close icon on the button delete_experiences_mode will inactive

import React from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import Modal from "./ConfirmExperienceDeleteModal";
import {
    deleteSelectedExperiences, getExperiences, setDeleteExperienceModalStatus,
    setDeleteExperiencesMode,
    setSelectedDeleteExperiences
} from "../../store/experiences";
import SmallRoundButton from "../buttons/SmallRoundButton";
import DeleteIcon from "../../images/delete-icon.svg";
import ReactTooltip from "react-tooltip";

class DeleteExperiencesButton extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentWillUnmount() {
        this.resetSelectedExperiences()
        document.removeEventListener("keydown", this.escFunction, false);

    }

    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if(event.keyCode === 27) {
            this.resetSelectedExperiences(event)
        }
    }

    handleDelete = (e) => {
        let { selected_delete_experiences,delete_experiences_mode} = this.props;
        if(!delete_experiences_mode){
            this.props.setSelectedDeleteExperiences([])
            this.props.setDeleteExperiencesMode(true)
            this.props.resetSelectedParentState()
        }else {
            selected_delete_experiences.length > 0 && this.props.setDeleteExperienceModalStatus(true)
        }
    };

    deleteExperiences = () => {
        let { selected_delete_experiences} = this.props;
        this.props.deleteSelectedExperiences(selected_delete_experiences).then((res) => {
            this.props.getExperiences()
            this.resetSelectedExperiences()
        })
    }

    resetSelectedExperiences = (e) => {
        if(e) e.stopPropagation()
        this.props.setDeleteExperiencesMode(false);
        this.props.setSelectedDeleteExperiences([])
        !!this.props.resetSelectedParentState && this.props.resetSelectedParentState()
    }

    render() {
        let { delete_experiences_mode,selected_delete_experiences,t} = this.props;
        let bigButtonClass = delete_experiences_mode ? 'btn-action_big' : '';
        return (
            <SmallRoundButton activeStatus={delete_experiences_mode} activeClass={'btn-small-round_delete_mode'} icon={DeleteIcon} classes={`mr-2 ml-2 ${bigButtonClass}`}
                              onClick={this.handleDelete} data_tip={(t(`Delete Experiences`))}>
                <ReactTooltip />
                {delete_experiences_mode && <span className={'btn-remove'} onClick={this.resetSelectedExperiences} />}
                <Modal handleDelete={this.deleteExperiences} isMultipleDelete={selected_delete_experiences.length > 1}/>
            </SmallRoundButton>
        );
    }
}

const mapStateToProps = state => ({
    experiences: state.experiences.experiences,
    selected_delete_experiences: state.experiences.selected_delete_experiences,
    delete_experiences_mode: state.experiences.delete_experiences_mode,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setDeleteExperiencesMode,
    getExperiences,
    setSelectedDeleteExperiences,
    setDeleteExperienceModalStatus,
    deleteSelectedExperiences
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeleteExperiencesButton);

