//Button on ar experiences list screen for create new element
//After clicking on the button will create element with default data

import React from 'react'
import {elementTypes} from "../../common";
import CreateButton from "../top-panel/CreateButton";

function CreateElementButton(props) {
    const onClick = () => {
        let elementData = {
            type_id: elementTypes.element2d
        }
        
        let total_count = props.total_count;
        let allowed_element = props.user_info.business_package.options[0].value;
        if (total_count < allowed_element) {
            props.createElement(elementData).then((res) => {
                props.history.push(`/element-${res.data.id}`)
            })
        } else {
            props.toggleUpgradePlanModal(true);
        }    
    }
    return(
        <CreateButton {...props} title={'Create Element'} onClick={onClick}/>
    )
}

export default CreateElementButton;
