import React from 'react';
import {resourceStatisticTypes, statisticViews} from "../../../common";
import StatisticsChart from "../../StatisticsChart";
import {Trans, withTranslation} from "react-i18next";

function ResourceStatisticItem(props) {
    let {type, analytics,dark_mode,t} = props;
    if(!analytics) return null;
    let {totalWeekCount,
        totalDayCount,
        dayDiff,
        weekDiff,
        thisWeek,
        thisDay} = statisticViews(analytics);

    if(type === resourceStatisticTypes.daily){
        return(
            <div className="statistic-item">
                <div className="statistic-item__title"><Trans>Daily views</Trans></div>
                <div className="statistic-item__description"><Trans>The resource was discovered {{totalWeekCount}} times last week.</Trans><br/>{weekDiff && `(${weekDiff}% ${t("from the previous week")})`}</div>
                <div className="statistic-item__chart">
                    <StatisticsChart data={thisWeek} interval={0} dark_mode={dark_mode}/>
                </div>
            </div>
        );
    }else if(type === resourceStatisticTypes.hourly){
        return(
            <div className="statistic-item">
                <div className="statistic-item__title"><Trans>Hourly views</Trans></div>
                <div className="statistic-item__description"><Trans>The resource was discovered {{totalDayCount}} times last day.</Trans> <br/>{dayDiff && `(${dayDiff}% ${t("from the previous day")})`}</div>
                <div className="statistic-item__chart">
                    <StatisticsChart data={thisDay} interval={3} dark_mode={dark_mode}/>
                </div>
            </div>
        );
    }
}


export default withTranslation('translations')(ResourceStatisticItem);
