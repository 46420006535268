//All reducers for errors
// import * as api from "../api";

import {notify} from "../common";
import {signOut} from "./user";

const SET_ERROR = 'SET_ERROR';


const initialState = {
    error: undefined,
    error_info: undefined,
    error_code: undefined,
    error_url: undefined,
};

export function handleErrors(response,withNotify,dispatch) {
    return new Promise((resolve,reject) => {
        if (!response.ok) {
            if(response.statusText === 'Unauthorized' || response.status === 401){
                if(dispatch) dispatch(signOut())
            }else if(response.statusText){
                if (withNotify) notify(response.statusText, 'error')
            }
            // if (document.location.pathname !== '/') {
            //     setTimeout(document.location.href = "/", 5000)
            // }
            response.json().then((err) => {
                dispatch(setError(response.statusText,err.message.description,err.status && err.status.code,response.url))
                reject(err)
            })
        }else resolve(response.json())
    })

}
export function handleErrorsFromCatch(error,withNotify,reject) {
    if(withNotify){
        if (error.response) {
            notify(error.response.data, 'error')
        } else if (error.request) {
            notify(error.request, 'error')
        } else if(error.message){
            if(error.message.description){
                notify(error.message.description, 'error')
            }else notify(error.message, 'error')
        }
    }
    reject && reject(error)
}

export const setError = (error,error_info,error_code,error_url) =>
    (dispatch) => {
        //todo add API call to save error on server
        dispatch({
            type: SET_ERROR,
            error,
            error_info,
            error_code,
            error_url
        });
    };

//Actions for errors
const ACTION_HANDLERS = {
    [SET_ERROR]: (state, action) => ({
        ...state,
        error: action.error,
        error_info: action.error_info,
        error_code: action.error_code,
        error_url: action.error_url,
    }),
};


export default function errors(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
