import React from 'react';
import ReactSVG from "react-svg";
import UploadFile from '../../../images/upload-file-icon.svg';
import {Trans} from "react-i18next";
import CloseIcon from "../../../images/close-icon.svg";

function JobSectionHeader(props) {
    return(
        <div className="post-job-section__header">
            <div className="post-job-section__header-inner">
                <ReactSVG src={UploadFile} className="post-job-section__header-icon"/>
                <Trans>{props.title}</Trans>
            </div>
            <button type="button" className="btn btn-close"
                    onClick={() => props.history.goBack()}>
                <ReactSVG src={CloseIcon} />
            </button>
        </div>
    )
}

export default JobSectionHeader;
