//Top panel on ar list  screen
import React, {useEffect,useState}   from 'react'
import SearchInput from "react-search-input";
import ReactSVG from "react-svg";
import SearchIcon from '../../images/search-icon.svg';
import ElementFiltersDropdown from "../element/ElementFiltersDropdown";
import ResourceSearchDropdown from "../ResourceSearchDropdown";
import ARButtons from "./ARButtons";
import VRButtons from "./VRButtons";
import {Trans} from "react-i18next";
import ErrorBoundaryContainer from "../ErrorBoundaryContainer";

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

function TopPanel(props) {
    let {searchUpdated,handleInputChange,handleResetFilters,show_without_resources,searchTerm,
        current_count,total_count,title} = props;

    const size = useWindowSize();

    return(
        <ErrorBoundaryContainer>
            <div className={`top-panel ${props.isVR && 'top-panel_absolute'}`}>
                <div className="row d-flex flex-row align-items-center h-100">
                    <div className="col-3">
                        {size.width > 768 && <div className="top-panel__search-wrap">
                            <div className="top-panel__search-icon">
                                <ReactSVG src={SearchIcon}/>
                            </div>
                            <SearchInput className="form-control input-primary top-panel__search" onChange={searchUpdated} placeholder={props.t("Search")} />
                        </div>}
                        {size.width <= 768 && <ResourceSearchDropdown searchUpdated={searchUpdated} searchTerm={searchTerm}/>}
                    </div>
                    {title && <div className="col text-center">
                        <div className="top-panel__title"><Trans>{title}</Trans></div>
                    </div>}
                    <div className="col d-flex flex-row justify-content-end align-items-center">
                        <span className="items-count-text"><Trans>Showing</Trans> <Trans>{{current_count}}</Trans> <Trans>items out of</Trans> <Trans>{{total_count}}</Trans></span>
                        <span className="items-count-text items-count-text_mobile"><Trans>{{current_count}} of {{total_count}}</Trans></span>
                        {!props.isVR && <ElementFiltersDropdown handleInputChange={handleInputChange}
                                                                handleResetFilters={handleResetFilters}
                                                                show_without_resources={show_without_resources}/>}
                        {!props.isVR ? <ARButtons {...props}/>
                            :
                            <VRButtons {...props}/>} 
                    </div>
                </div>


            </div>
        </ErrorBoundaryContainer>
    )
}

export default TopPanel;

