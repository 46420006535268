import React from "react";
import {Trans} from 'react-i18next';

function BidCoverLetter(props) {
    let title_invalid_class = props.formik.errors.cover_letter && props.formik.touched.cover_letter ? 'invalid' : '';
    return(
        <div className="form-group">
            <div className="sidebar__title sidebar__title_offset-free"><Trans>Cover letter</Trans></div>
            <textarea
                id="bid-cover-letter"
                name="cover_letter"
                rows="10"
                placeholder={props.t(`Enter cover letter`)}
                value={props.formik.values.cover_letter}
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                className={`form-control input-primary d-block ${title_invalid_class} ${props.textareaClass}`}/>
            {props.formik.errors.cover_letter && props.formik.touched.cover_letter && (
                <div className="input-feedback">{props.formik.errors.cover_letter}</div>
            )}
        </div>
    )
}

export default BidCoverLetter;
