//element view screen showing by clicking on element card
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  setCurrenteElement,
  updateElementData,
  getElementItemById,
  addTagsToElement,
  deleteTagsFromElement,
  getElements,
  getIdentifiers,
  setIdentifier,
  getDigitalPrints,
  setDigitalPrint,
  setCreateIdentifierMode,
  updateElementField,
  setElementPublishModalStatus,
  getElementAnalytics,
  getElementQuiz,
  setElementLanguage,
  autoTranslateElement,
  createSubElement,
  getSubElements,
  deleteSubElement,
  setLinkResourceToSubElementMode,
  updateSubElement,
  setSubElement,
} from "../store/elements";
import {
    setCurrentResource,
    setSelectedDeleteResources,
    getResourcesImages,
    getNonDegreeResources, setResourceLanguage, setResourceImagesModalStatus
} from '../store/resources';
import Loading from "../components/Loading";
import {setLoading, toggleUpgradePlanModal} from "../store/user";
import {resourceTypes, getLimit, isElementEmpty, languages, elementTypes, packageOptions} from "../common";
import {setElementSchema, getValidationRules, setElementNullableSchema} from "../store/validations";
import {Formik} from "formik";
import ElementHeader from "../components/element/ElementView/ElementHeader";
import ElementDetails from "../components/element/ElementView/ElementDetails";
import ResourcesAndIdentifiers from "../components/element/ElementView/ResourcesAndIdentifiers";
import EditResource from "../components/resource/EditResource/EditResource";
import EmptyHalfSection from "../components/element/ElementView/EmptyHalfSection";
import IdentifierItemView from "../components/identifier/IdentifierItemView";
import CreateResource from "../components/resource/CreateResource/CreateResource";
import CreateIdentifier from "../components/identifier/CreateIdentifier";
import CreateDigitalPrints from '../components/digitalprints/CreateDigitalPrints';
import {Modal, ModalFooter, ModalHeader} from "reactstrap";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

class ElementView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bindMode:false,
            delete_resources_mode:false,
            show_unpaired_resources:false,
            searchTerm: '',
            name: '',
            description: '',
            active: false,
            selectedResources:[],
            selecteddeleteResources:[],
            file: '',
            resourceType: resourceTypes.all,
            sortType:'date',
            tags:[],
            type: false,
            type_id:1,
            language: 'en',
            id: undefined,
            main_content_language: 'en',
            element_details_loading:false,
            annotations: [],
            annotation: {},
            annotation_type: '',
            sub_element_id: undefined,
            sub_elements: undefined,
        }
    }

    componentDidMount() {
        this.props.setLoading(true)
        this.props.getValidationRules('dashboard')
        let element_id = parseInt(this.props.match.params.id);
        if(element_id){
            this.props.getElementItemById(element_id).then(() => {
                let {translations} = this.props.element_item ? this.props.element_item : {};
                if(translations[this.props.element_language]){
                    this.setState({
                        name:translations[this.props.element_language].name ? translations[this.props.element_language].name : '',
                        description:translations[this.props.element_language].description ? translations[this.props.element_language].description : '',
                    })
                }else {
                    this.setState({
                        name:'',
                        description:'',
                    })
                }
                this.props.getIdentifiers(element_id)
                this.props.getDigitalPrints(element_id);
                this.props.getSubElements(element_id)
                this.props.getElementAnalytics(element_id)
                this.props.getElementQuiz(element_id)
                if(this.props.main_content_language){
                    this.props.setElementLanguage(this.props.main_content_language)
                }
            })
                .catch((err) => {
                    this.props.history.push('/');
                })
        }

    }

    static getDerivedStateFromProps(nextProps, prevState){
        let {tags,type_id,delete_resources_mode,active,translations,id} = nextProps.element_item ? nextProps.element_item : {};
        let update = {};
        if(nextProps.main_content_language !== prevState.main_content_language){
            update.main_content_language = nextProps.main_content_language
            nextProps.setElementLanguage(nextProps.main_content_language)
            nextProps.setResourceLanguage(nextProps.main_content_language)
        }
        let language = nextProps.element_language ? nextProps.element_language : "en";
        if(language !== prevState.language){
            update.language = language
            if(translations){
                if(translations[language]){
                    update.name = translations[language].name ? translations[language].name : '';
                    update.description = translations[language].description ? translations[language].description : '';
                }else {
                    update.name = '';
                    update.description = '';
                }
            }
        }
        update.id = id ? id : undefined;
        update.type_id = type_id ? type_id : 1;
        update.delete_resources_mode = delete_resources_mode ? delete_resources_mode : false;
        update.tags = tags ? tags : [];
        update.active = active ? active : false;
        update.type = type_id ? type_id === elementTypes.element3d : elementTypes.element2d;



        if (nextProps.element_item) {
            if (!isElementEmpty(nextProps.element_item)) {
                if (prevState.id !== nextProps.element_item.id) {
                    setTimeout(function () {
                        nextProps.setLoading(false)
                    }, 2000);
                }
            }
        }

        if (nextProps.delete_resources_mode) {
            update.selecteddeleteResources = [];
        }
        if(nextProps.sub_elements.length !== prevState.sub_elements){
            update.sub_elements = nextProps.sub_elements;
        }
        return Object.keys(update).length ? update : null;
    }

    componentWillUnmount() {
        this.props.setIdentifier(undefined)
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    searchUpdated = (term) => {
        this.setState({searchTerm: term})
    }

    handleSubmit = (values) => {
        let { active } = this.state,
            element_updated = { active:!active},
            id = this.props.element_item.id;
        let {business_package} = this.props.user_info;
        let business_options = business_package.options;
        let element_limit = getLimit(business_options,packageOptions.element);
        let activeElements = _.filter(this.props.elements, (element_item) => {
            return element_item.active
        });
        if(active){
            this.props.setLoading(true)
            this.props.updateElementData(id,element_updated).then(() => {
                this.props.getElements().then(() => {
                    this.props.setLoading(false)
                })
            })
        }else {
            if(activeElements.length < element_limit){
                this.props.setLoading(true)
                this.props.updateElementData(id,element_updated,true).then(() => {
                    this.props.setElementPublishModalStatus(true)
                    this.props.getElements().then(() => {
                        this.props.setLoading(false)
                    })
                })
            }else this.props.toggleUpgradePlanModal(true)
        }
    }

    //pair resources to element
    handleBind = () => {
        let { bindMode,selectedResources} = this.state;
        let { element_item} = this.props;
        if(!bindMode){
            this.setState({bindMode: true})
        }else {
            this.props.setCurrenteElement({...element_item,paired_resources:_.map(selectedResources,'id')})
            this.setState({bindMode: false})
            this.props.bindSelectedResources(element_item.id,_.map(selectedResources,'id')).then((res) => {
                this.props.getElementItemById(element_item.id)
                // this.props.getNonDegreeResources()
            })
        }
    }

    resetSelectedResources = (e) => {
        if(e) e.stopPropagation()
        this.setState({bindMode: false, selectedResources: [], selecteddeleteResources:[],delete_resources_mode:false})
    }

    //check resource
    callbackCheck = (id,checked) => {
        let {bindMode,selectedResources,selecteddeleteResources} = this.state;
        let {delete_resources_mode} = this.props;
        let items = bindMode ? selectedResources : selecteddeleteResources;
        var match = _.find(items, {id});
        if(checked && !match) {
            items.push({id})
            this.setState({selectedResources: items})
        }
        if(!checked && match){
            let index = _.indexOf(items, match);
            items.splice(index, 1);
            this.setState({selectedResources: items})
        }
        if(delete_resources_mode){
            this.props.setSelectedDeleteResources(_.map(selecteddeleteResources,'id'))
        }
    };

    handleTagsChange = (tags,changed) => {
        let newTagsLength = tags.length,
            oldTagsLength = this.state.tags.length,
            isDeleteChange = newTagsLength < oldTagsLength;
        this.setState({tags})
        if(isDeleteChange){
            this.props.deleteTagsFromElement(this.props.element_item.id,changed)
        }else {
            this.props.addTagsToElement(this.props.element_item.id,tags)
        }
    }

    handleSwitcherTypeChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let element_updated = { type_id: value ? elementTypes.element3d : elementTypes.element2d},
            id = this.props.element_item.id;
        this.props.updateElementField(id,element_updated,'type_id').then(()=>
            this.props.getElementItemById(id)
        )

    }

    onBlurSave = (error,value,name) => {
        if(!error){
            let language = this.props.element_language;
            let {element_item} = this.props;
            if (this.state[name] === value) return;
            let element_updated = {
                    translations: {
                        ...element_item.translations,
                        [language]:{
                            ...element_item.translations[language],
                            [name]:value
                        }
                    }
                },
                id = element_item.id;
            this.props.updateElementField(id, element_updated, name,language)
        }
    }

    handleOk = () => {
        this.props.setElementPublishModalStatus(!this.props.show_element_publish_modal)
        this.props.history.push("/")
    }

    closeViewIdentifier = () => {
        this.props.setIdentifier(undefined);
    }

    selectElementLanguage = (name,value) => {
        this.props.setElementLanguage(_.find(languages,['id',value]).short_name)
    }

    autoTranslateElement = () => {
        this.setState({element_details_loading:true})
        this.props.autoTranslateElement(this.props.element_item.id).then(() => {
            this.setState({element_details_loading:false})
        })
    }

    onChangeAnnotation = (annotation) => {
        this.setState({ annotation })
    }

    onSubmitAnnotation = (annotation) => {
        const { geometry, data } = annotation

        this.setState({
            annotation: {},
            annotations: this.state.annotations.concat({
                geometry,
                data: {
                    ...data,
                    id: Math.random()
                }
            })
        })
    }

    saveSubElement = (region) => {
        let language = this.props.element_language;

        return new Promise((resolve, reject) => {
            if(region){
                let identifier_id = this.props.training_item.id;
                let sub_element_data = {
                    coordinates: {
                        h:region.h,
                        w:region.w,
                        x:region.x,
                        y:region.y,
                    },
                }
                sub_element_data.translations = {
                    [language]: {
                        name: region.regionName ? region.regionName : ''
                    }
                }

                this.props.setLinkResourceToSubElementMode(false,undefined)
                let sub_element = _.find(this.props.sub_elements,['id',region.id]);
                if(sub_element){
                    if (sub_element.translations) {
                        sub_element_data = {
                            ...sub_element_data,
                            translations: {
                                ...sub_element.translations,
                                [language]:{
                                    name: region.regionName ? region.regionName : ''
                                }
                            }
                        }
                    }
                    this.props.updateSubElement(this.props.element_item.id,region.id,sub_element_data).then((res) => {
                        this.props.getSubElements(this.props.element_item.id)
                        resolve(res.id)
                    })
                }else {
                    this.props.createSubElement(this.props.element_item.id,identifier_id,sub_element_data).then((res) => {
                        this.props.getSubElements(this.props.element_item.id)
                        resolve(res.id)
                    })
                }
            }
        })

    }

    deleteSubElement = (sub_element_id) => {
        this.props.deleteSubElement(this.props.element_item.id,sub_element_id).then(() => {
            this.props.getSubElements(this.props.element_item.id)
        })
    }

    handleSwitchTab = (tab_id,sub_element_id) => {
        this.props.setLinkResourceToSubElementMode(true,sub_element_id)
    }

    handleDisableLinkSubelementsMode = () => {
        this.props.setLinkResourceToSubElementMode(false)
    }

    handleOpenSubElement = (sub_element) => {
        this.props.setSubElement(sub_element,sub_element.id)
    }

    handleCloseSubElement = () => {
        this.props.setSubElement(undefined,undefined)
    }


    isCreated = (region_id) => {
        return _.find(this.props.sub_elements, ['id', region_id])
    }

    onUpload = () => {

    }

    render() {
        const {element_item,resource_item,training_item,create_resources_mode,create_training_item_mode,digital_print,create_digital_print_mode,main_content_language,t} = this.props;
        let {active,element_details_loading,sub_elements} = this.state;
        let hiddenListClass = (resource_item || training_item || create_resources_mode || create_training_item_mode || digital_print || create_digital_print_mode) ? 'element-half-section_mobile-hidden' : '';
        return (
            <div>
                <div className="element-view">
                    <Modal isOpen={this.props.show_element_publish_modal} toggle={this.toggle} className="modal modal-resources-list" centered>
                        <ModalHeader toggle={this.toggle}>Please wait upto 24 hours for your experience to be available on the mobile app</ModalHeader>
                        <ModalFooter className="d-block">
                            <div className="row">
                                <div className="col-12 mb-md-0 mb-3 text-center">
                                    <button className="btn btn-primary btn-small btn-dark" onClick={this.handleOk}>Ok</button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Loading small={true} classes="position-fixed"/>
                    {element_item && <div >
                        <ElementHeader element_item={element_item} handleSwitcher={this.handleSwitcherTypeChange}
                                       active={active} handleSubmit={this.handleSubmit} main_content_language={main_content_language}
                                       {...this.props}/>

                        <Formik
                            enableReinitialize
                            initialValues={this.state}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    this.handleSubmit(values)
                                    setSubmitting(false)
                                }, 500);
                            }}
                            validationSchema={active ? setElementSchema(this.props.validation_rules,t) : setElementNullableSchema(this.props.validation_rules,t)}
                        >
                            {formik => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    resetForm,
                                    setFieldValue
                                } = formik;
                                return (
                                    <form onSubmit={handleSubmit} name="element-details">

                                        <ElementDetails {...this.props} values={values} touched={touched} errors={errors} handleChange={handleChange}
                                                        handleBlur={handleBlur} element_item={element_item} isSubmitting={isSubmitting} tags={this.state.tags}
                                                        onBlurSave={this.onBlurSave} handleTagsChange={this.handleTagsChange}
                                                        selectElementLanguage={(name,value) => this.selectElementLanguage(name,value)}
                                                        setFieldValue={setFieldValue} onUpload={this.onUpload}
                                                        resetForm={resetForm} initialValues={this.state} autoTranslateElement={this.autoTranslateElement}
                                                        loading={element_details_loading} formik={formik}/>

                                    </form>
                                );
                            }}
                        </Formik>
                    </div>}

                    <div className="row">
                        <div className={`col-xl-6 col-lg-12`}>
                            <ResourcesAndIdentifiers classes={hiddenListClass} handleDisableLinkSubelementsMode={this.handleDisableLinkSubelementsMode}/>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            {!resource_item && !training_item && !create_resources_mode && !create_training_item_mode && !digital_print && !create_digital_print_mode &&
                            <EmptyHalfSection classes={'d-md-none d-none'}/>}
                            {resource_item && <EditResource/>}
                            {create_resources_mode && <CreateResource/>}
                            {create_training_item_mode && <CreateIdentifier/>}
                            {create_digital_print_mode && <CreateDigitalPrints/>}
                            {training_item && <IdentifierItemView training_item={training_item} closeViewIdentifier={this.closeViewIdentifier}
                                                                  onChangeAnnotation={this.onChangeAnnotation}
                                                                  {...this.state} saveSubElement={this.saveSubElement}
                                                                  sub_elements={sub_elements} deleteSubElement={this.deleteSubElement}
                                                                  handleSwitchTab={this.handleSwitchTab} handleOpenSubElement={this.handleOpenSubElement}
                                                                  handleCloseSubElement={this.handleCloseSubElement}
                                                                  element_language={this.props.element_language} isCreated={this.isCreated}
                                                                  element_active={element_item.active}/>}
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}

const mapStateToProps = (state) => ({
  element_item: state.elements.element_item,
  resources: state.resources.resources_non_degree,
  resources_images: state.resources.resources_images,
  selected_resources: state.resources.selected_resources,
  selected_delete_resources: state.resources.selected_delete_resources,
  delete_resources_mode: state.resources.delete_resources_mode,
  show_element_publish_modal: state.elements.show_element_publish_modal,
  validation_rules: state.validations,
  resource_item: state.resources.resource_item,
  create_resources_mode: state.resources.create_resources_mode,
  training_item: state.elements.training_item,
  digital_print: state.elements.digital_print,
  create_training_item_mode: state.elements.create_training_item_mode,
  create_digital_print_mode: state.elements.create_digital_print_mode,
  user_info: state.user.user_info,
  elements: state.elements.elements,
  element_language: state.elements.element_language,
  sub_elements: state.elements.sub_elements,
  link_resources_to_sub_elements_mode:
    state.elements.link_resources_to_sub_elements_mode,
  main_content_language: state.user.main_content_language,
  progress_percent: state.elements.progress_percent,
  show_resource_images_modal: state.elements.show_resource_images_modal,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrenteElement,
      setCurrentResource,
      getNonDegreeResources,
      updateElementData,
      updateElementField,
      getElementItemById,
      setSelectedDeleteResources,
      setLoading,
      getValidationRules,
      getResourcesImages,
      addTagsToElement,
      deleteTagsFromElement,
      getElements,
      getIdentifiers,
      setIdentifier,
      getDigitalPrints,
      setDigitalPrint,
      setCreateIdentifierMode,
      setElementPublishModalStatus,
      toggleUpgradePlanModal,
      getElementAnalytics,
      getElementQuiz,
      setElementLanguage,
      setResourceLanguage,
      autoTranslateElement,
      createSubElement,
      updateSubElement,
      getSubElements,
      deleteSubElement,
      setLinkResourceToSubElementMode,
      setSubElement,
      setResourceImagesModalStatus,
    },
    dispatch
  );

export default withRouter(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ElementView)));
