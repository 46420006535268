//Register screen with form
import React  from 'react'
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter } from "react-router-dom";
class TermOfService extends React.Component {

  render() {
    return (
      <div className="row auth-wrap">
        <div className="col-md-12 order-md-1 col-12 order-2">
          <div className="ContractText">
            <div className="format-html">
              <div className="outputVersion1">
                {/* <div className=" header">
                  <span
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "Center",
                    }}
                    className="content"
                  >
                    Website Terms and Conditions
                  </span>
                  <span
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "Center",
                    }}
                    className="pageNumbers"
                  >
                    Page
                    <span className="currentPageNum"></span> of
                    <span className="totalPageNum"></span>
                  </span>
                </div> */}
                <div className=" footer"></div>
                <div className=" firstHeader"></div>
                <div className=" firstFooter">
                  <span
                    style={{ "line-height": "18.0pt" }}
                    className="pageNumbers"
                  >
                    <br />
                    <br />
                    <br />
                    <span className="currentPageNum"></span>
                    <span className="totalPageNum"></span>
                  </span>
                </div>
                <p
                  style={{
                    "line-height": "18.0pt",
                    "font-size": "12.0pt",
                    "font-family": "Times New Roman",
                    color: "#000000",
                    "text-align": "Center",
                  }}
                >
                  <span
                    style={{
                      "font-style": "normal",
                      "font-weight": "bold",
                      "text-decoration": "underline",
                    }}
                  >
                    TERMS AND CONDITIONS
                  </span>
                </p>
                <p
                  style={{
                    "line-height": "18.0pt",
                    "font-size": "12.0pt",
                    "font-family": "Times New Roman",
                    color: "#000000",
                    "text-align": "left",
                  }}
                >
                  These terms and conditions (the 'Terms and Conditions') govern
                  the use of
                  <span
                    style={{ "font-style": "normal", "font-weight": "bold" }}
                  >
                    realmeta.ca
                  </span>
                  (the 'Site'). This Site is owned and operated by RealMeta
                  Studios Incorporated. This Site is a software as a service.
                  <br />
                  <br />
                  By using this Site, you indicate that you have read and
                  understand these Terms and Conditions and agree to abide by
                  them at all times.
                  <br />
                  <br />
                  THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE
                  THAT IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE
                  READ IT CAREFULLY.
                </p>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Intellectual Property
                    </span>
                    <br />
                    All content published and made available on our Site is the
                    property of RealMeta Studios Incorporated and the Site's
                    creators. This includes, but is not limited to images, text,
                    logos, documents, downloadable files and anything that
                    contributes to the composition of our Site.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Acceptable Use
                    </span>
                    <br />
                    As a user of our Site, you agree to use our Site legally,
                    not to use our Site for illegal purposes, and not to:
                  </p>
                  <ul
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "list-style": "disc",
                    }}
                  >
                    <li style={{ "margin-bottom": "18.0pt" }} value="1">
                      <span>Harass or mistreat other users of our Site;</span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                    <li style={{ "margin-bottom": "18.0pt" }} value="2">
                      <span>
                        Violate the rights of other users of our Site;
                      </span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                    <li style={{ "margin-bottom": "18.0pt" }} value="3">
                      <span>
                        Violate the intellectual property rights of the Site
                        owners or any third party to the Site;
                      </span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                    <li style={{ "margin-bottom": "18.0pt" }} value="4">
                      <span>
                        Hack into the account of another user of the Site;
                      </span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                    <li style={{ "margin-bottom": "18.0pt" }} value="5">
                      <span>
                        Act in any way that could be considered fraudulent; or
                      </span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                    <li style={{ "margin-bottom": "0.0pt" }} value="6">
                      <span>
                        Post any material that may be deemed inappropriate or
                        offensive.
                      </span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    If we believe you are using our Site illegally or in a
                    manner that violates these Terms and Conditions, we reserve
                    the right to limit, suspend or terminate your access to our
                    Site. We also reserve the right to take any legal steps
                    necessary to prevent you from accessing our Site.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Accounts
                    </span>
                    <br />
                    When you create an account on our Site, you agree to the
                    following:
                  </p>
                  <ol
                    start="1"
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "list-style": "decimal",
                    }}
                  >
                    <li style={{ "margin-bottom": "18.0pt" }} value="1">
                      <span>
                        You are solely responsible for your account and the
                        security and privacy of your account, including
                        passwords or sensitive information attached to that
                        account; and
                      </span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                    <li style={{ "margin-bottom": "0.0pt" }} value="2">
                      <span>
                        All personal information you provide to us through your
                        account is up to date, accurate, and truthful and that
                        you will update your personal information if it changes.
                      </span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                  </ol>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    We reserve the right to suspend or terminate your account if
                    you are using our Site illegally or if you violate these
                    Terms and Conditions.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Sale of Goods And Services
                    </span>
                    <br />
                    These Terms and Conditions govern the sale of goods and
                    services available on our Site.
                  </p>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    The following goods are available on our Site:
                  </p>
                  <ul
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "list-style": "disc",
                    }}
                  >
                    <li style={{ "margin-bottom": "0.0pt" }} value="1">
                      <span>Digital Content.</span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    The following services are available on our Site:
                  </p>
                  <ul
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "list-style": "disc",
                    }}
                  >
                    <li style={{ "margin-bottom": "0.0pt" }} value="1">
                      <span>Digital Content Creation Services.</span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    The services will be paid for in full when the services are
                    ordered.
                  </p>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    These Terms and Conditions apply to all the goods and
                    services that are displayed on our Site at the time you
                    access it. This includes all products listed as being out of
                    stock. All information, descriptions, or images that we
                    provide about our goods and services are as accurate as
                    possible. However, we are not legally bound by such
                    information, descriptions, or images as we cannot guarantee
                    the accuracy of all goods and services we provide. You agree
                    to purchase goods and services from our Site at your own
                    risk.
                  </p>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    We reserve the right to modify, reject or cancel your order
                    whenever it becomes necessary. If we cancel your order and
                    have already processed your payment, we will give you a
                    refund equal to the amount you paid. You agree that it is
                    your responsibility to monitor your payment instrument to
                    verify receipt of any refund.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Subscriptions
                    </span>
                    <br />
                    Your subscription automatically renews and you will be
                    automatically billed until we receive notification that you
                    want to cancel the subscription.
                  </p>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    To cancel your subscription, please follow these steps:
                    Please email cancel@realmeta.ca to cancel your subscription.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Payments
                    </span>
                    <br />
                    We accept the following payment methods on our Site:
                  </p>
                  <ul
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "list-style": "disc",
                    }}
                  >
                    <li style={{ "margin-bottom": "0.0pt" }} value="1">
                      <span>Credit Card.</span>
                      <span style={{ color: "#000000" }}>
                        <br />
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    When you provide us with your payment information, you
                    authorize our use of and access to the payment instrument
                    you have chosen to use. By providing us with your payment
                    information, you authorize us to charge the amount due to
                    this payment instrument.
                    <br />
                    <br />
                    If we believe your payment has violated any law or these
                    Terms and Conditions, we reserve the right to cancel or
                    reverse your transaction.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Consumer Protection Law
                    </span>
                    <br />
                    Where the{" "}
                    <span
                      style={{
                        "font-style": "italic",
                        "font-weight": "normal",
                      }}
                    >
                      Consumer Protection Act
                    </span>
                    , or any other consumer protection legislation in your
                    jurisdiction applies and cannot be excluded, these Terms and
                    Conditions will not limit your legal rights and remedies
                    under that legislation. These Terms and Conditions will be
                    read subject to the mandatory provisions of that
                    legislation. If there is a conflict between these Terms and
                    Conditions and that legislation, the mandatory provisions of
                    the legislation will apply.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Limitation of Liability
                    </span>
                    <br />
                    RealMeta Studios Incorporated and our directors, officers,
                    agents, employees, subsidiaries, and affiliates will not be
                    liable for any actions, claims, losses, damages, liabilities
                    and expenses including legal fees from your use of the Site.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Indemnity
                    </span>
                    <br />
                    Except where prohibited by law, by using this Site you
                    indemnify and hold harmless RealMeta Studios Incorporated
                    and our directors, officers, agents, employees,
                    subsidiaries, and affiliates from any actions, claims,
                    losses, damages, liabilities and expenses including legal
                    fees arising out of your use of our Site or your violation
                    of these Terms and Conditions.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Applicable Law
                    </span>
                    <br />
                    These Terms and Conditions are governed by the laws of the
                    Province of Nova Scotia.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Dispute Resolution
                    </span>
                    <br />
                    Subject to any exceptions specified in these Terms and
                    Conditions, if you and RealMeta Studios Incorporated are
                    unable to resolve any dispute through informal discussion,
                    then you and RealMeta Studios Incorporated agree to submit
                    the issue first before a non-binding mediator and to an
                    arbitrator in the event that mediation fails. The decision
                    of the arbitrator will be final and binding. Any mediator or
                    arbitrator must be a neutral party acceptable to both you
                    and RealMeta Studios Incorporated. The costs of any
                    mediation or arbitration will be shared equally between you
                    and RealMeta Studios Incorporated.
                  </p>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    Notwithstanding any other provision in these Terms and
                    Conditions, you and RealMeta Studios Incorporated agree that
                    you both retain the right to bring an action in small claims
                    court and to bring an action for injunctive relief or
                    intellectual property infringement.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Severability
                    </span>
                    <br />
                    If at any time any of the provisions set forth in these
                    Terms and Conditions are found to be inconsistent or invalid
                    under applicable laws, those provisions will be deemed void
                    and will be removed from these Terms and Conditions. All
                    other provisions will not be affected by the removal and the
                    rest of these Terms and Conditions will still be considered
                    valid.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Changes
                    </span>
                    <br />
                    These Terms and Conditions may be amended from time to time
                    in order to maintain compliance with the law and to reflect
                    any changes to the way we operate our Site and the way we
                    expect users to behave on our Site. We will notify users by
                    email of changes to these Terms and Conditions or post a
                    notice on our Site.
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    <span
                      style={{
                        "font-style": "normal",
                        "font-weight": "bold",
                        "text-decoration": "underline",
                      }}
                    >
                      Contact Details
                    </span>
                    <br />
                    Please contact us if you have any questions or concerns. Our
                    contact details are as follows:
                    <br />
                    <br />
                    (902) 915-4766
                    <br />
                    connect@realmeta.ca
                    <br />
                    48, Dow Rd, Nova Scotia, B4N 3R5
                  </p>
                  <p
                    style={{
                      "line-height": "18.0pt",
                      "font-size": "12.0pt",
                      "font-family": "Times New Roman",
                      color: "#000000",
                      "text-align": "left",
                    }}
                  >
                    You can also contact us through the feedback form available
                    on our Site.
                  </p>
                </div>
                <p
                  style={{
                    "line-height": "18.0pt",
                    "font-size": "12.0pt",
                    "font-family": "Times New Roman",
                    color: "#000000",
                    "text-align": "right",
                  }}
                >
                  Effective Date: 1st day of December, 2023
                </p>
                <div className="LDCopyright">
                  <p>&copy;2002-2023 LawDepot.ca&reg;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
    user_info: state.user.user_info,
    validation_rules: state.validations
});


export default withRouter(
  withTranslation("translations")(
    connect(mapStateToProps)(TermOfService)
  )
);

