import React from 'react';
import InfiniteScrollContainerNew from "../../components/InfiniteScrollContainerNew";
import CardsList from "./CardsList";
import CustomScrollBar from "../../components/CustomScrollBar";
import WorkHistoryItem from "./WorkHistoryItem";
import {userRoles} from "../../common";
import {jobStatuses} from "./jobs/JobCard/JobCard";

function WorkHistory(props) {
    let overflowVerticalOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;

    const getItems = (newPage) => {
        let getItems = props.getJobs;
        let $completedStatusId = jobStatuses.find(job => job.name === 'Completed').id;
        let $assignedStatusId = jobStatuses.find(job => job.name === 'Assigned').id;
        let $partiallyCompletedStatusId = jobStatuses.find(job => job.name === 'Partially completed').id;
        let contractor_id = isContractor && props.user_info.id;
        let business_id =  !isContractor && props.business_info.id;
        return new Promise((resolve,reject) => {
            getItems({contractor_id,business_id, page: newPage, status_id: [$completedStatusId,$assignedStatusId,$partiallyCompletedStatusId]})
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        })
    }

    return(
        <CustomScrollBar className="cards-list-offset" options={overflowVerticalOptions}>
            <InfiniteScrollContainerNew getItems={getItems}>
                <CardsList classes={'work-history'} ItemComponent={WorkHistoryItem} items={props.jobs} {...props}/>
            </InfiniteScrollContainerNew>
        </CustomScrollBar>

    )
}

export default WorkHistory;
