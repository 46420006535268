import * as api from "../api";
import {handleErrors} from "./errors";
import * as _ from 'lodash';
import {jobStatuses} from "../routes/marketplace/jobs/JobCard/JobCard";
import {addToList, deleteFromListById, updateListById} from "../common";

const SET_CURRENT_JOB = 'SET_CURRENT_JOB',
    SET_JOBS = 'SET_JOBS',
    SET_OPEN_JOBS = 'SET_OPEN_JOBS',
    SET_JOB_ATTACHMENTS = 'SET_JOB_ATTACHMENTS';

const initialState = {
    jobs: [],
    open_jobs: [],
    job_item: undefined,
    job_attachments: [],
};

export const getJobs = (params) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetJobs(params)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setJobsAction([...getState().jobs.jobs, ...responseJson.data]))
                    resolve(responseJson)
                })
        })
    }

export const getOnlyOpenJobs = (params) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetJobs(params)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setOpenJobsAction([...getState().jobs.open_jobs,...responseJson.data]))
                    resolve(responseJson)
                })
        })
}

export const getJobAttachments = (job_id) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetJobAttachments(job_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setJobAttachmentsAction(responseJson.data))
                    resolve(responseJson)
                })
        })
    }

export const createNewJob = (data) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchPostJob(data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setCurrentJobAction(responseJson.data))
                    resolve(responseJson.data)
                })
        })
    }

export const updateJob = (data,job_id) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchUpdateJob(data,job_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let jobsList = getState().jobs.jobs;
                    let openJobsList = getState().jobs.open_jobs;
                    updateListById(jobsList,job_id,responseJson)
                    dispatch(setJobsAction(jobsList))
                    updateListById(openJobsList,job_id,responseJson)
                    dispatch(setOpenJobsAction(openJobsList))
                    dispatch(setCurrentJobAction(responseJson.data))
                    resolve(responseJson.data)
                })
        })
    }

export const updateJobStatus = (status_id,job_id) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchUpdateJobStatus(status_id,job_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let $openStatus = jobStatuses.find(item => item.name === 'Open for bidding').id;
                    let $draftStatus = jobStatuses.find(item => item.name === 'Draft').id;
                    let $completedStatus = jobStatuses.find(item => item.name === 'Completed').id;
                    let jobsList = getState().jobs.jobs;
                    let openJobsList = getState().jobs.open_jobs;
                    if(status_id === $openStatus){
                        updateListById(jobsList,job_id,responseJson)
                        addToList(openJobsList,responseJson)
                    }else if(status_id === $draftStatus){
                        deleteFromListById(openJobsList,job_id)
                        updateListById(jobsList,job_id,responseJson)
                    }else if (status_id === $completedStatus){
                        updateListById(jobsList,job_id,responseJson)
                    }
                    dispatch(setCurrentJobAction(responseJson.data))
                    dispatch(setJobsAction(jobsList))
                    dispatch(setOpenJobsAction(openJobsList))
                    resolve(responseJson.data)
                })
        })
    }

export const deleteDraftJob = (job_id) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchDeleteDraftJob(job_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let jobsList = getState().jobs.jobs;
                    let index = _.findIndex(jobsList, {id: job_id});
                    jobsList.splice(index, 1);
                    dispatch(setCurrentJobAction(undefined))
                    dispatch(setJobsAction(jobsList))
                    resolve(responseJson.data)
                })
        })
    }

export const getJobItem = (job_id) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetJobItem(job_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setCurrentJobAction(responseJson.data))
                    resolve(responseJson.data)
                })
        })
    }

export const attachFilesToJob = (job_id,files) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchAttachFiles(job_id,files)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let currentAttachments = getState().jobs.job_attachments;
                    let $attachments = [...currentAttachments, ...responseJson.data];
                    dispatch(setJobAttachmentsAction($attachments))
                    resolve()
                })
        })
    }

export const deleteJobAttachment = (job_id,file_id) =>
    (dispatch,getState) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteFile(job_id, file_id)
                .then((res) => handleErrors(res, true, dispatch))
                .then((responseJson) => {
                    let currentAttachments = getState().jobs.job_attachments;
                    let $attachments = currentAttachments.filter(item => item.id !== file_id)
                    dispatch(setJobAttachmentsAction($attachments))
                    resolve()
                })
        })
    }

export const setCurrentJob = (job_item) =>
    (dispatch) => {
        dispatch(setCurrentJobAction(job_item));
    };

export const clearJobs = () =>
    (dispatch) => {
        dispatch(setJobsAction(initialState.jobs));
        dispatch(setOpenJobsAction(initialState.open_jobs));
    };

const setCurrentJobAction = (job_item) => ({
        type: SET_CURRENT_JOB,
        job_item
});


const setJobsAction = (jobs) => ({
    type: SET_JOBS,
    jobs
});

const setOpenJobsAction = (open_jobs) => ({
    type: SET_OPEN_JOBS,
    open_jobs
});


const setJobAttachmentsAction = (job_attachments) => ({
    type: SET_JOB_ATTACHMENTS,
    job_attachments
});

const ACTION_HANDLERS = {
    [SET_CURRENT_JOB]: (state, action) => ({
        ...state,
        job_item: action.job_item,
    }),
    [SET_JOBS]: (state, action) => ({
        ...state,
        jobs: action.jobs,
    }),
    [SET_OPEN_JOBS]: (state, action) => ({
        ...state,
        open_jobs: action.open_jobs,
    }),
    [SET_JOB_ATTACHMENTS]: (state, action) => ({
        ...state,
        job_attachments: action.job_attachments,
    }),

};


export default function jobs(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
