import React, { useState, useEffect } from "react";
import Slider from "./Slider";
import "../../styles/model.css";
import ReactSVG from "react-svg";
import DeleteIcon from "../../images/delete-icon.svg";
import ViewIcon from "../../images/view-mode-icon.svg";

function Modal(props) {
    let { images, setFieldValue } = props;

    const [photos, setPhotos] = useState(images);
    const [show, setShow] = useState(false);
    const [children, setChildren] = useState(null);
    const [index, setIndex] = useState(0);

    useEffect(() => {
      setPhotos(images);
    }, [images]);

    const removeImage = (index) => {
      setPhotos((oldValues) => {
        return oldValues.filter((_, i) => i !== index);
      });
      setFieldValue("files", photos); 
      setShow(false);
    };

    const getImage = (x) => {
      return URL.createObjectURL(x);
    };

    const handleClick = (x, i) => {
      setChildren(x);
      setShow(true);
      setIndex(i);
    };

    const onClose = () => {
      setShow(false);
    };
    if (!photos) return null;

  
    return (
      photos && (
        <div className="identifiers">
          <div className="row">
            <Slider
              children={children}
              index={index}
              show={show}
              title="Lightbox"
              onClose={onClose}
              removeImage={removeImage}
              setFieldValue={setFieldValue}
              photos={photos}
            ></Slider>
            {photos.map((x, i) => (
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="identifier">
                  <div className="identifier__item-click-area" />
                  <div className="identifier-image-wrap">
                    <img
                      src={getImage(x)}
                      alt={`image` + i}
                      className="identifier-image"
                    />
                  </div>
                  <div className="identifier-content">
                    <span className="identifier-name">{x.name}</span>
                    <button
                      type="button"
                      className="btn identifier-btn-delete"
                      onClick={() => {
                        removeImage(i);
                      }}
                    >
                      <ReactSVG
                        className="svg-icon-wrap"
                        src={DeleteIcon}
                        alt=""
                      />{" "}
                      Delete
                    </button>
                    <span className="identifier-name"></span>
                    <button
                      type="button"
                      className="btn identifier-btn-delete"
                      onClick={() => {
                        handleClick(x, i);
                      }}
                    >
                      <ReactSVG
                        className="svg-icon-wrap"
                        src={ViewIcon}
                        alt=""
                      />{" "}
                      Show
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    );
}

export default Modal;
