//Button for publish experience.
import React from 'react'
import {getLimit, modalTypes, packageOptions} from "../../common";
import _ from "lodash";
import PublishModal from "../modals/PublishModal";
import ReactTooltip from "react-tooltip";

function PublishButton (props){
    let {classes,t,experience_item,main_content_language,isSubmitting} = props;
    let disable = true;
    if(!experience_item) return null;
    let experience_name = experience_item.translations && experience_item.translations[main_content_language] ? experience_item.translations[main_content_language].name : null;
    //todo length?
    let hasFile = (experience_item.file);
    if(experience_item.active || isSubmitting){
        disable = false;
    }else disable = !!(hasFile || !experience_name);
    let tooltipText = disable ? `${t("Required for publishing an experience")}: ${!experience_name ? t('Experience Name') : ''} ${!experience_name && !!hasFile ? ',' :''} ${!!hasFile ? t('Experience file') : ''}` : null;

    const handleSubmit = (values) => {
        let experience_updated = { active:!experience_item.active},
            id = props.experience_item.id;
        let {business_package} = props.user_info;
        let business_options = business_package.options;
        let experience_limit = getLimit(business_options,packageOptions.experience);
        let activeExperiences = _.filter(props.experiences, (experience_item) => {
            return experience_item.active
        });
        if(experience_item.active){
            props.setLoading(true)
            props.updateExperienceData(id,experience_updated).then(() => {
                props.setLoading(false)
            })
        }else {
            if(activeExperiences.length < experience_limit){
                props.setLoading(true)
                props.updateExperienceData(id,experience_updated,true).then(() => {
                    props.setModal(modalTypes.publish_modal)
                    props.setLoading(false)
                })
            }else props.toggleUpgradePlanModal(true)
        }
    }

    return (
        <button type={handleSubmit ? "button" : "submit"} disabled={disable} className={`btn btn-primary ${classes}`}
                onClick={handleSubmit} data-tip={tooltipText}>
            <ReactTooltip />
            <span>{experience_item.active ? t('Unpublish') : t('Publish')}</span>
            <PublishModal item_name={"experience"} {...props}/>
        </button>
    )
}



export default PublishButton;
