import React from 'react';
import CustomScrollBar from "../CustomScrollBar";
import ReactSVG from "react-svg";
import ArrowIcon from '../../images/arrow-sidebar-icon.svg';

function Sidebar(props) {
    let visibility = props.show ? 'visible' : 'hidden';
    let toggled = props.sidebar_toggled ? 'collapsed' : '';
    let withoutFooter = props.withoutFooter ? 'sidebar-scroll_without-footer' : '';
    let bigFooter = props.bigFooter ? 'sidebar-scroll_big-footer' : '';
    let wide = props.wide ? 'sidebar_wide' : '';

    return(
        <div className={`sidebar ${visibility} ${toggled} ${wide}`}>
            {props.header && <div className="sidebar__header">
                {props.header}
            </div>}
            <div className="sidebar-scroll-wrap">
                <CustomScrollBar options={props.scrollOptions}
                    className={`sidebar-scroll ${withoutFooter} ${bigFooter}`}>
                    {props.children}
                </CustomScrollBar>
            </div>
            {props.footer && <div className="sidebar__footer">
                {props.footer}
            </div>}
            <button className="btn sidebar-toggler-btn" onClick={() => props.setSidebarToggle(!props.sidebar_toggled)}>
                <ReactSVG src={ArrowIcon}/>
            </button>
        </div>
    )
}

export default Sidebar;
