import React, { useEffect, useState } from "react";
import { Field } from "formik";
import * as api from "../api";
import { handleErrorsFromCatch } from "../store/errors";
import FileUploader from "../components/FileUploader";
import Loading from "../components/Loading";
import { useHistory } from "react-router-dom";
import { Trans } from "react-i18next";

function ExperienceCreateContainer(props) {
  const { values, touched, errors, handleBlur, setFieldValue } = props.formik;
  const [uploadStatus, setUploadStatus] = useState(false);
  const [pending, setPending] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [experience_id, setExperienceId] = useState(0);
  const [folderPath, setFolderPath] = useState('');

  // const uploadImageStatus = () => {
  //   setUploadStatus(true);
  // };
    
  const history = useHistory();
  
  useEffect(() => {
    if (redirect) {
      history.push(
        "/evr-" + props.business_id + "-" + experience_id + "-" + folderPath
      );
      history.go(0);
    }
  }, [redirect]);

  const uploadImage = (values) => {
    setPending(true);
    let business_id = props.business_id;
    return new Promise((resolve, reject) => {
      api
        .uploadUnityFile(business_id, values.unity_file)
        .then((responseJson) => {
          resolve(responseJson);
          setUploadStatus(false);
          setPending(false);
          setExperienceId(responseJson.id);
          setFolderPath(responseJson.folder);
          setRedirect(true);
        })
        .catch((error) => handleErrorsFromCatch(error));
    });
  };

  let { poster } = values;

  return (
    <>
      <div className="profile__business-poster">
        {pending && (
          <Loading small activeSmallLoading={pending} isTransparent />
        )}
        <>
          {!uploadStatus && poster && (
            <img
              src={poster.url}
              className="profile__business-poster-image"
              alt=""
            />
          )}

          {(uploadStatus || !poster) && (
            <div className="profile__upload-poster-wrapper">
              <div className="profile-section__title">
                <Trans>Upload Unity Space File</Trans>
              </div>
              <Field
                name="unity_file"
                component={FileUploader}
                setFieldValue={setFieldValue}
                errorMessage={
                  errors["unity_file"] ? errors["unity_file"] : undefined
                }
                touched={touched["unity_file"]}
                onBlur={handleBlur}
              />
              <button
                type="button"
                className="btn btn-primary"
                disabled={pending}
                onClick={() => uploadImage(values)}
              >
                <Trans>Submit</Trans>
              </button>
            </div>
          )}
        </>
      </div>
    </>
  );
}

export default React.memo(ExperienceCreateContainer);
