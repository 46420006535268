//All util reducers

import {modalTypes, sidebarTypes} from "../common";

const SET_SIDEBAR = 'SET_SIDEBAR',
    SET_SIDEBAR_TOGGLE = 'SET_SIDEBAR_TOGGLE',
    SET_MODAL = 'SET_MODAL';

const initialState = {
    sidebar_type: sidebarTypes.element,
    modal_type: modalTypes.none,
    sidebar_toggled: false

};


export const setSidebar = (sidebar_type) =>
    (dispatch) => {
        dispatch({
            type: SET_SIDEBAR,
            sidebar_type
        });
    };

//todo place all common modals there
export const setModal = (modal_type) =>
    (dispatch) => {
        dispatch({
            type: SET_MODAL,
            modal_type
        });
    };

export const setSidebarToggle = (sidebar_toggled) =>
    (dispatch) => {
        dispatch({
            type: SET_SIDEBAR_TOGGLE,
            sidebar_toggled
        });
    };


//Actions for util
const ACTION_HANDLERS = {
    [SET_SIDEBAR]: (state, action) => ({
        ...state,
        sidebar_type: action.sidebar_type,
    }),
    [SET_MODAL]: (state, action) => ({
        ...state,
        modal_type: action.modal_type,
    }),
    [SET_SIDEBAR_TOGGLE]: (state, action) => ({
        ...state,
        sidebar_toggled: action.sidebar_toggled,
    }),
};


export default function util(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
