import React from 'react';
import {withRouter} from "react-router-dom";
import Avatar from "../../../../components/Avatar";

function ContractorAvatar (props) {
    const uploadAvatar = (setAvatar,file) => {
        props.uploadContractorAvatar(props.contractor_item.id,file).then(res => {
            setAvatar(res)
        })
    }

    const deleteAvatar = (setAvatar) => {
        props.deleteContractorAvatar(props.contractor_item.id).then(res => {
            setAvatar(undefined)
        })
    }
    return (
        <Avatar uploadAvatar={uploadAvatar} deleteAvatar={deleteAvatar} avatar={props.user_info.avatar}/>
    )
}

export default withRouter(ContractorAvatar);
