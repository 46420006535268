import React from 'react';
import ReactSVG from "react-svg";
import Help from "../images/help.svg";

function HelpIconTooltip(props) {
    return(
        <div className="help-icon custom-tooltip custom-tooltip_left"
                data-tooltip={props.help_message}>
            <ReactSVG src={Help} className="help-icon"/>
        </div>
    )
}

export default HelpIconTooltip;