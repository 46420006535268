import React from 'react';
import ReactSVG from "react-svg";
import PNG from "../images/format-icons/png-icon.svg";
import JPG from "../images/format-icons/jpg-icon.svg";
import JPEG from "../images/format-icons/jpeg-icon.svg";
import QTFF from "../images/format-icons/gtff-icon.svg";
import MP4 from "../images/format-icons/mp4-icon.svg";
import MOV from "../images/format-icons/mov-icon.svg";
import BMP from "../images/format-icons/bmp-icon.svg";
import TIFF from "../images/format-icons/tiff-icon.svg";
import WEBP from "../images/format-icons/webp-icon.svg";
import WEBM from "../images/format-icons/webm-icon.svg";
import MKV from "../images/format-icons/mkv-icon.svg";
import $3GPP from "../images/format-icons/3gpp-icon.svg";
import OGG from "../images/format-icons/ogg-icon.svg";
import FLAC from "../images/format-icons/flac-icon.svg";
import MP3 from "../images/format-icons/mp3-icon.svg";
import WAV from "../images/format-icons/wav-icon.svg";
import AAC from "../images/format-icons/aac-icon.svg";
import MPEG from "../images/format-icons/mpeg-icon.svg";
import M4A from "../images/format-icons/m4a-icon.svg";
import RTF from "../images/format-icons/rtf-icon.svg";
import TXT from "../images/format-icons/txt-icon.svg";
import HTML from "../images/format-icons/html-icon.svg";
import ZIP from "../images/format-icons/zip-icon.svg";
import GLTF from "../images/format-icons/gltf-icon.svg";
import GLB from "../images/format-icons/glb-icon.svg";
import GIF from "../images/format-icons/gif-icon.svg";
import Unknown from "../images/format-icons/unknown-icon.svg";
import {getFormatIcon} from "../common";

export const formats = [
    {
        name: 'image/png',
        icon: PNG
    },
    {
        name: 'image/jpeg',
        icon: JPEG
    },
    {
        name: 'image/jpg',
        icon: JPG
    },
    {
        name: 'image/bmp',
        icon: BMP
    },
    {
        name: 'image/tiff',
        icon: TIFF
    },
    {
        name: 'image/webp',
        icon: WEBP
    },
    {
        name: 'video/quicktime',
        icon: QTFF
    },
    {
        name: 'video/mp4',
        icon: MP4
    },
    {
        name: 'video/webm',
        icon: WEBM
    },
    {
        name: 'video/mkv',
        icon: MKV
    },
    {
        name: 'video/mov',
        icon: MOV
    },
    {
        name: 'video/3gpp',
        icon: $3GPP
    },
    {
        name: 'audio/ogg',
        icon: OGG
    },
    {
        name: 'audio/flac',
        icon: FLAC
    },
    {
        name: 'audio/mp3',
        icon: MP3
    },
    {
        name: 'audio/wav',
        icon: WAV
    },
    {
        name: 'audio/aac',
        icon: AAC
    },
    {
        name: 'audio/mpeg',
        icon: MPEG
    },
    {
        name: 'audio/x-m4a',
        icon: M4A
    },
    {
        name: 'audio/webm',
        icon: WEBM
    },
    {
        name: 'text/rtf',
        icon: RTF
    },
    {
        name: 'text/plain',
        icon: TXT
    },
    {
        name: 'text/html',
        icon: HTML
    },
    {
        name: 'application/zip',
        icon: ZIP
    },
    {
        name: 'model/gltf+json',
        icon: GLTF
    },
    {
        name: 'model/gltf-binary',
        icon: GLB
    },
    {
        name: 'gltf',
        icon: GLTF
    },
    {
        name: 'glb',
        icon: GLB
    },
    {
        name: 'image/gif',
        icon: GIF
    },
]

function FormatIcon(props) {
    let $format = props.format;
    let icon = getFormatIcon($format,formats);
    if($format && icon) return <ReactSVG src={icon} className={props.className}/>;
    return <ReactSVG src={Unknown} className={props.className}/>
}

export default FormatIcon;
