import React, { useEffect, useState } from "react";
import * as api from "../api";
import Unity from "react-unity-webgl";
import { handleErrorsFromCatch } from "../store/errors";
import { useHistory } from "react-router-dom";

function ExperienceVRContainer(props) {
  const [loaded, setLoaded] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const buttonValue = (props.experience_status === true) ? 'UnPublish' : 'Publish';
  const changeStatus = props.experience_status === true ? 0 : 1;
  
  useEffect(() => {
    if (loaded) {
      props.refreshUnity2(props.experience_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const history = useHistory();
  useEffect(() => {
    if (redirect) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  props.unityContext.on("error", (message) => {
    console.log(message, "message");
  });

  props.unityContext.on("loaded", (message) => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  });

  const publishVR = (changeStatus) => {
    let experience_id = props.experience_id;
    let statusData = { "is_published": changeStatus };
    return new Promise((resolve, reject) => {
      api
        .fetchUpdateExperienceStatus(experience_id, statusData)
        .then((responseJson) => {
          resolve(responseJson);
          setRedirect(true);
        })
        .catch((error) => handleErrorsFromCatch(error));
    });
  };

  return (
    <>
      <section className="experience-section">
        <Unity unityContext={props.unityContext} />
        <br />
        <div className="profile__upload-poster-wrapper">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => publishVR(changeStatus)}
          >
            {buttonValue}
          </button>
        </div>
      </section>
    </>
  );
}

export default ExperienceVRContainer;
