import React from "react";
import BidCardHeader from "./BidCardHeader";
import ContractorInfo from "../contractor/ContractorInfo";
import moment from "moment";
import {sidebarTypes, userRoles} from "../../../common";
import {Trans} from 'react-i18next';
export const bidStatuses = [
    {
        id: 1,
        name:'Draft'
    },
    {
        id: 2,
        name:'Submitted'
    },
    {
        id: 3,
        name:'Approved'
    },
    {
        id: 4,
        name:'Rejected'
    }
];

function BidList(props){
    let {created_at,contractor,job} = props.item_info;
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;

    const onClick = () => {
        props.setCurrentBid(props.item_info)
        if(isContractor){
            props.setSidebarToggle(false)
            props.setSidebar(sidebarTypes.view_bid)
        }else props.getBidComments({bid_id: props.item_info.id})
    }
    let $created_at = moment(created_at).fromNow()

    return(
        <div className="col-12">
            <div className="bid-card" onClick={onClick}>
                <BidCardHeader item_info={props.item_info}/>
                <div className="bid-card__body">
                    {!isContractor && <ContractorInfo contractor={contractor}/>}
                    {job && <div className="job-card__name">{job.title}</div>}
                </div>
                <div className="bid-card__footer">
                    <div className="bid-card__date"><Trans>Posted {{$created_at}}</Trans></div>
                </div>
            </div>
        </div>
    )
}

export default BidList;
