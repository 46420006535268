//Media player for playing audio and video. Using for resource content
import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {setCurrentResource,updateResourceData,createResource,addResourceImage} from "../../store/resources";
import { Media, Player, controls } from 'react-media-player';
import CustomPlayPause from './CustomPlayPause'
const {
    CurrentTime,
    SeekBar,
    Duration,
} = controls;

class MediaPlayer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }

    }

    render() {
        const {media_url,show_fullsize_gallery,isFullscreen,autoPlay,loop,hidden} = this.props;
        if(!isFullscreen && show_fullsize_gallery) return null;
        return (
            <Media>
                {mediaProps =>  {
                    let hasDuration = mediaProps.duration && isFinite(mediaProps.duration);
                    return (
                        <div className={`media ${hidden && 'media-hidden'}`}>
                            <div className="media-player">
                                <Player src={media_url} autoPlay={autoPlay} loop={loop}/>
                            </div>
                            <div className={`media-controls ${hidden && 'media-controls_hidden'}`}>
                                <CustomPlayPause/>
                                <CurrentTime />
                                <SeekBar className="media-controls__seekbar"/>
                                {hasDuration && <Duration />}
                            </div>
                        </div>
                    )
                }}
            </Media>
        )
    }

}
const mapStateToProps = state => ({
    resource_item: state.resources.resource_item,
    show_fullsize_gallery: state.resources.show_fullsize_gallery,
    dark_mode: state.user.dark_mode,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    updateResourceData,
    createResource,
    addResourceImage,
    setCurrentResource
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MediaPlayer);

