import React from 'react';
import WithTranslateFormErrors from "./WithTranslateFormErrors";
import PromptIfDirtyForm from "./PromptIfDirtyForm";

function FormWrapper(props) {
    let {formik} = props;
    return(
        <WithTranslateFormErrors errors={formik.errors} touched={formik.touched} setFieldTouched={formik.setFieldTouched}>
            <form onSubmit={formik.handleSubmit} name={props.formName}>
                {props.children}
            </form>
            <PromptIfDirtyForm formik={formik}/>
        </WithTranslateFormErrors>
    )
}

export default FormWrapper;
