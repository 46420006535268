import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AugmentedExperiencesList from "./AugmentedExperiencesList";
import VirtualExperiencesList from "./VirtualExperiencesList";
import { withRouter } from 'react-router-dom';
import {withTranslation} from "react-i18next";

 class Home extends React.Component {


     render() {
         return (
           <div className="split-screen">
             <AugmentedExperiencesList {...this.props} />
             {this.props.user_info.business_package.id === 5 ||
             this.props.user_info.business_package.id === 6 ? (
               <VirtualExperiencesList {...this.props} />
             ) : null}
           </div>
         );
     }
 }

const mapStateToProps = state => ({
    user: state.user.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
},dispatch);

export default withTranslation('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)));

