//Component for showing preview image of element.

import React from 'react';
import ReactSVG from "react-svg";
import Empty from "../../../images/empty.svg";

function EmptyPreview(props) {
    return <ReactSVG src={Empty} className="element-item__empty-preview"/>
}

export default EmptyPreview;

