//Identifier item for element. Using on identifier data page
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg'
import {
  deleteDigitalPrints,
  getElementItemById,
  getDigitalPrints,
  setConfirmDeleteDigitalPrintModal,
  setCreateDigitalPrintMode,
  setDigitalPrint,
} from "../../store/elements";
import {setLoading} from "../../store/user";
import DeleteIcon from "../../images/delete-icon.svg";
import {setCreateResourcesMode, setCurrentResource} from "../../store/resources";
import ConfirmDeleteDigitalPrintModal from "../modals/ConfirmDeleteDigitalPrintModal";
import {Trans} from "react-i18next";

class DigitalPrintsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: false,
    };
  }

  deleteDigitalPrints = (id) => {
    this.setState({ pending: true });
    this.props.deleteDigitalPrints(this.props.element_item.id, id).then(() => {
      this.setState({ pending: false });
      if (this.props.element_id) {
        this.props.getElementItemById(this.props.element_item.id);
        this.props.getDigitalPrints(this.props.element_id);
      }
    });
  };

  handleDeleteButton = (e) => {
    this.props.setConfirmDeleteDigitalPrintModal(true);
  };

  handleIdentifierClick = () => {
    this.props.setCreateDigitalPrintMode(false);
    this.props.setCurrentResource(undefined);
    this.props.setCreateResourcesMode(false);
    this.props.setDigitalPrint(this.props.item);
  };

  renderStatus(status) {
    switch (status) {
      case "init":
        return "Pending Approval";
      case "approved":
        return "Identifier Approved";
      case "rejected":
        return "Identifier Rejected";
      case "not_found":
        return "Identifier error";
      case "syncing":
        return "Processing";
      case "success":
        return "Success";
      case "failure":
        return "Failed";
      case "deleting":
        return "Deleting";
      case "deleted":
        return "Deleted";
      default:
        return "Pending Approval";
    }
  }

  render() {
    const { item, percent, preview_url, is_new, element_item } = this.props;
    const { pending } = this.state;

    if (!item) return null;
    return (
      <div className="identifier">
        {!is_new && (
          <div
            className="identifier__item-click-area"
            onClick={this.handleIdentifierClick}
          />
        )}
        <div className="identifier-image-wrap">
          <img
            src={item.preview_url ? item.preview_url : preview_url}
            alt=""
            className="identifier-image"
          />
        </div>
        <div className="identifier-content">
          {percent > 0 && is_new && (
            <div className="progress">
              <span className="progress__status">{`${percent}%`}</span>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${percent}%` }}
                aria-valuenow={percent}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          )}
          <span className="digital_print-name">
            <b>{`${item.print_name}`}</b>
          </span>
          <span className="digital_print-price">Price: {`${item.price}`}</span>
          <span className="digital_print-quantity">
            Quantity: {`${item.quantity}`}
          </span>
          <button
            type="button"
            className="btn identifier-btn-delete"
            //onClick={this.handleDeleteButton}
            onClick={() => this.deleteDigitalPrints(item.id)}
            disabled={pending || element_item.active}
          >
            <ReactSVG className="svg-icon-wrap" src={DeleteIcon} alt="" />{" "}
            <Trans>Delete</Trans>
          </button>
        </div>
        {
          <ConfirmDeleteDigitalPrintModal
            handleDelete={this.deleteDigitalPrints}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
    element_item: state.elements.element_item,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    deleteDigitalPrints,
    setLoading,
    getElementItemById,
    getDigitalPrints,
    setDigitalPrint,
    setCreateDigitalPrintMode,
    setCurrentResource,
    setCreateResourcesMode,
    setConfirmDeleteDigitalPrintModal
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DigitalPrintsItem);

