import React, {Fragment} from "react";
import {jobStatuses} from "./JobCard/JobCard";
// import Button from "../../../components/buttons/Button";
import {Trans} from 'react-i18next';
function OpenJobFooter(props) {
    let $draftStatus = jobStatuses.find(item => item.name === 'Draft').id;
    const UnpublishJob = () => {
        props.updateJobStatus($draftStatus,props.job_id)
    }
    return(
        <Fragment>
            <button type="button" className="btn btn-primary btn-decline w-100"
                onClick={UnpublishJob}><Trans>Unpublish</Trans></button>
            {/*todo requisitions logic on hold for now*/}
            {/*<Button type="submit"*/}
            {/*        className="btn btn-primary w-100"*/}
            {/*        disabled={props.formik.isSubmitting}*/}
            {/*        loading={props.pending || props.formik.isSubmitting}>Submit a requisition</Button>*/}
        </Fragment>
    )
}

export default OpenJobFooter;
