//Experience card with preview image, name, date of creation, status(active) and count of scenes
//By click will open experience view screen

import React  from 'react'
import { Link } from 'react-router-dom';
import checkIcon from "../../images/check-icon.svg";
import Tags from "../Tags";
import {Trans} from "react-i18next";
import moment from "moment";

class ExperienceCard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            checkMode: true,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(!nextProps.printMode && !nextProps.deleteMode) {
            update.checked = false
        }
        return Object.keys(update).length ? update : null;
    }

    handleCheck = () => {
        const {checked} = this.state;
        const {experience_item} = this.props;
        this.setState({checked: !checked})
        this.props.callbackCheck(experience_item.id,!checked);
    }

    render(){
        const {experience_item,deleteMode} = this.props;
        const {checked} = this.state;
        let checkedClass = checked ? "checked" : '';
        return (
          <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 card-item-wrap">
            <div className={`experience-item ${checkedClass}`}>
              {/* {!deleteMode && (
                <Link
                  className="experience__item-click-area"
                  to={{
                    pathname: `/experience-${experience_item.id}`,
                    experience_item,
                  }}
                  onClick={() =>
                    this.props.setCurrentExperience(experience_item)
                  }
                />
              )} */}
              {!deleteMode && (
                <Link
                  className="experience__item-click-area"
                  to={{
                    pathname: `/evr-${experience_item.business_id}-${experience_item.id}-${experience_item.folder}`,
                    experience_item,
                  }}
                  onClick={() =>
                    this.props.setCurrentExperience(experience_item)
                  }
                  target="_blank"
                />
              )}
              <div className="experience-item__details">
                <div className="experience-item__details-item">
                  <div className="experience-item__title">
                    <Trans>Created at</Trans>
                  </div>
                  <div className="experience-item__info">
                    {moment(experience_item.created_at).format("MMM DD, YYYY")}
                  </div>
                </div>
                <div className="experience-item__details-item">
                  <div className="experience-item__title">
                    <Trans>Updated at</Trans>
                  </div>
                  <div className="experience-item__info">
                    {moment(experience_item.updated_at).format("MMM DD, YYYY")}
                  </div>
                </div>
                <div className="experience-item__details-item">
                  <div className="experience-item__title">
                    <Trans>Status</Trans>
                  </div>
                  {experience_item.is_published && (
                    <div className="experience-item__status">
                      <Trans>Published</Trans>
                    </div>
                  )}
                  {!experience_item.is_published && (
                    <div className="experience-item__status experience-item__status_draft">
                      <Trans>Draft</Trans>
                    </div>
                  )}
                </div>
              </div>
              <div className="experience-item__body">
                <div className="experience-item__name">
                  {experience_item.name}
                </div>
                {experience_item.tags && <Tags tags={experience_item.tags} />}
              </div>
              {deleteMode && (
                <span className="item-checkbox-wrap" onClick={this.handleCheck}>
                  <span className="item-checkbox">
                    {checked && (
                      <img
                        className="item-checkbox__status-icon"
                        src={checkIcon}
                        alt=""
                      />
                    )}
                  </span>
                </span>
              )}
            </div>
          </div>
        );
    }

}

export default ExperienceCard;

