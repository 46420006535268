import React from 'react';
import UnityRender from "../UnityRender";

function ElementSectionView(props) {
    return(
        <section className="experience-section">
            {props.experience_item && <UnityRender {...props}/>}
        </section>
    )
}

export default ElementSectionView;
