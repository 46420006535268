import React from 'react';
import TextThumbnail from '../../../images/text-thumb.png';
import UrlThumbnail from "../../../images/text-url.png";
import {resourceTypes} from "../../../common";

function getPreview(resource_item,language) {
    let files = resource_item.files,
        preview;
    if(files){
        if(files[language]){
            preview =  files[language].preview_url
        }else {
            Object.entries(files).forEach(([k, v]) => {
                if(k !== language && v.preview_url && !preview){
                    preview = v.preview_url;
                }
            })
        }
    }
    return preview;
}


function ResourceCardPreviewImage(props) {
    let {resource_item,main_content_language} = props;
    let preview = '';
    if(resource_item.type_id === resourceTypes.text) {
        preview = TextThumbnail;
    } else if (resource_item.type_id === resourceTypes.url) {
        preview = UrlThumbnail;
    } else {
        preview = getPreview(resource_item, main_content_language);
    }
    
    return(
        <div className="resource__item-image-wrap">
            <img src={preview} alt="" className="resource__item-image"/>
        </div>
    )
}

export default ResourceCardPreviewImage;