//Connect react.js to html
import 'overlayscrollbars/css/OverlayScrollbars.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import OverlayScrollbars from 'overlayscrollbars';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import { combineReducers } from 'redux';

const videoJsOptions = {
    controls: true,
    width: 320,
    height: 240,
    fluid: false,
    plugins: {
        /*
        // wavesurfer section is only needed when recording audio-only
        wavesurfer: {
            src: 'live',
            waveColor: '#36393b',
            progressColor: 'black',
            debug: true,
            cursorWidth: 1,
            msDisplayMax: 20,
            hideScrollbar: true
        },
        */
        record: {
            audio: true,
            video: true,
            maxLength: 10,
            debug: true
        }
    }
};


ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <App {...videoJsOptions}/>
    </I18nextProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
OverlayScrollbars(document.body, {
    nativeScrollbarsOverlaid: {
        initialize: false
    }
});
export default combineReducers({})
