//Modal for confirmation of multiple deleting resources

import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import {
    createResource,
    setDeleteResourceModalStatus,
    setCurrentResource,
    updateResourceData
} from "../../store/resources";
import {setLoading} from "../../store/user";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import {Trans} from "react-i18next";

class ConfirmResourceDeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };

    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(prevState.show !== nextProps.show_delete_resource_modal) {
            update.show = nextProps.show_delete_resource_modal
        }
        return Object.keys(update).length ? update : null;
    }

    toggle = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
        this.props.setDeleteResourceModalStatus(!this.props.show_delete_resource_modal)
    }

    handleDelete = () => {
        this.props.handleDelete()
        this.setState({show:false})
        this.props.setDeleteResourceModalStatus(false)
    }

    render() {
        let {selected_delete_resources,t} = this.props;
        let title = selected_delete_resources && selected_delete_resources.length > 1 ? t('Are you sure you want to delete these resources?') : t('Are you sure you want to delete this resource?');
        return (
            <div>
                <Modal isOpen={this.state.show} toggle={this.toggle} className="modal-resource-delete" centered>
                    <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleDelete}><Trans>Yes</Trans></Button>
                        <Button color="secondary" onClick={this.toggle}><Trans>No</Trans></Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    resource_item: state.resources.resource_item,
    show_delete_resource_modal: state.resources.show_delete_resource_modal
});

const mapDispatchToProps = dispatch => bindActionCreators({
    updateResourceData,
    createResource,
    setCurrentResource,
    setLoading,
    setDeleteResourceModalStatus
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmResourceDeleteModal);