import React from 'react';
import InfiniteScrollContainerNew from "../../../../components/InfiniteScrollContainerNew";
import CardsList from "../../CardsList";
import CustomScrollBar from "../../../../components/CustomScrollBar";
import {userRoles} from "../../../../common";
import PortfolioItem from "./PortfolioItem";
import {withRouter} from "react-router-dom";

function Portfolio(props) {
    let overflowVerticalOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
    let isContractor = props.user_info && props.user_info.role_id === userRoles.contractor;

    const getItems = (newPage) => {
        let getItems = props.getContractorPortfolio;
        let contractor_id = isContractor ? props.user_info.id : props.match.params.id;
        return new Promise((resolve,reject) => {
            getItems({contractor_id,page: newPage})
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        })
    }

    return(
        <CustomScrollBar className="cards-list-offset" options={overflowVerticalOptions}>
            <InfiniteScrollContainerNew getItems={getItems}>
                {props.portfolio_items && <CardsList classes={''} ItemComponent={PortfolioItem} items={props.portfolio_items} {...props}/>}
            </InfiniteScrollContainerNew>
        </CustomScrollBar>

    )
}

export default withRouter(Portfolio);
