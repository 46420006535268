import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import ReactSVG from "react-svg";
import EditIcon from '../images/edit-icon.svg';
import DummyAvatar from '../images/dummy-avatar.svg';
import CloseIcon from "../images/close-icon.svg";

function Avatar (props) {
    useEffect(() => {
        setAvatar(props.avatar)
    },[props.avatar])

    const [avatar, setAvatar] = useState(undefined);

    const onChangeAvatar = (e) => {
        let file = e.target.files[0];
        props.uploadAvatar(setAvatar,file)
    }

    const deleteAvatar = () => {
        props.deleteAvatar(setAvatar)
    }
    return (
        <div className="position-relative">
            {avatar && !props.readOnly && <button type="button" className="btn btn-close avatar-delete"
                               onClick={deleteAvatar}>
                <ReactSVG src={CloseIcon}/>
            </button>}
            <div className="user-info__avatar">
                {avatar ?
                    <img className="user-info__avatar-image"
                         src={avatar.url} alt="dummy"/>
                    : <ReactSVG src={DummyAvatar} className={"user-info__avatar-dummy"}/>}
                {!props.readOnly && <label htmlFor="upload-button" className="btn btn-update-image mb-0">
                    <ReactSVG src={EditIcon}/>
                </label>}
                <input
                    type="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={onChangeAvatar}
                />

            </div>
        </div>

    )
}

export default withRouter(Avatar);
