import React from "react";
import CustomScrollBar from "../../CustomScrollBar";
import { Formik } from "formik";
import { setResourceSchema } from "../../../store/validations";
import { languages } from "../../../common";
import { TabPane } from "reactstrap";
//import TextEditor from "../../TextEditor";
import LanguageSelect from "../../LanguageSelect";
//import FileUploader from "../../FileUploader";
import { Trans, withTranslation } from "react-i18next";
import * as _ from "lodash";

class CreateUrlResourceTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
    };
  }

  changeStatus = (status) => {
    this.setState({ status });
  };

  handleResetForm = (resetForm) => {
    this.changeStatus(0);
    resetForm(this.props.initialValues);
  };

  selectResourceLanguage = (name, value) => {
    this.props.setResourceLanguage(_.find(languages, ["id", value]).short_name);
  };

  render() {
    let {
      initialValues,
      validation_rules,
      handleSubmit,
      tabId,
      media_type,
      t,
    } = this.props;
    
    let overflowOptions = { overflowBehavior: { x: "hidden", y: "scroll" } };

    return (
      <TabPane tabId={tabId} className="tab-pane">
        <CustomScrollBar options={overflowOptions}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                handleSubmit(values);
                setSubmitting(false);
              }, 500);
            }}
            validationSchema={setResourceSchema(
              false,
              false,
              validation_rules,
              false,
              false,
              media_type,
              false,
              t
            )}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
              } = props;
              
              let name_invalid_class =
                errors.name && touched.name ? "invalid" : "";
              return (
                <form onSubmit={handleSubmit} name="create-resource-video">
                  <div className="row">
                    <div className="col">
                      <LanguageSelect
                        data={languages}
                        disabled={false}
                        value={values.language}
                        onBlur={languages}
                        setFieldValue={(name, value) =>
                          this.props.selectResourceLanguage(name, value)
                        }
                        name={"language"}
                        isDark
                      />
                      <div className="form-group">
                        <input
                          name="url"
                          type="hidden"
                          value='add'
                        />
                        <input
                          id="text-resource-name"
                          name="name"
                          placeholder={t(`Enter url`)}
                          type="text"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control input-primary d-block ${name_invalid_class}`}
                        />
                        {errors.name && touched.name && (
                          <div className="input-feedback">{errors.name}</div>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        <span className="">
                          <Trans>Save</Trans>
                        </span>
                      </button>
                      {values.name && (
                        <button
                          type="button"
                          className="btn btn-outline ml-3"
                          disabled={isSubmitting}
                          onClick={() => this.handleResetForm(resetForm)}
                        >
                          <span className="">
                            <Trans>Reset</Trans>
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </CustomScrollBar>
      </TabPane>
    );
  }
}

export default withTranslation("translations")(CreateUrlResourceTab);
