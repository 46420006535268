//Checkbox for showing only unpaired resources.
import React from 'react'
import {Trans} from "react-i18next";

class ShowWithoutResourcesCheckbox extends React.Component {

    render(){
        const {handleInputChange,show_without_resources} = this.props;
        return(
            <div>
                <div className="form-group custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="without_resources_checkbox" name="show_without_resources"
                           onChange={handleInputChange} checked={show_without_resources}/>
                    <label className="custom-control-label checkbox-label" htmlFor="without_resources_checkbox">
                        <Trans>Elements without resources</Trans>
                    </label>
                </div>
            </div>
        )
    }
}

export default ShowWithoutResourcesCheckbox;


