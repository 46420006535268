import React from "react";

function CardsList(props) {
    let {items,ItemComponent,isFavorites, classes} = props;
    return(
        <div className={`row ${classes}`}>
            {items.map((item_info,i) => {
                if(isFavorites && !item_info.favorite) return null;
                return <ItemComponent item_info={item_info} key={i+ (props.key || 'ItemComponent')} {...props}/>
            })}
        </div>
    )
}

export default CardsList;
