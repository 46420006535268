import React from 'react';
import ReactSVG from "react-svg";
import LightMode from "../images/navbar-icons/light-mode.svg";

function ToggleColorMode(props) {
    return(
        <button className="btn sidebar-menu__button p-0" onClick={() => props.setDarkMode(!props.dark_mode)}>
            <ReactSVG className="sidebar-menu__icon dark-mode-icon" src={LightMode} alt="dark_mode"/>
        </button>
    )
}


export default ToggleColorMode;
