//Button for multiple delete resources
//After first click on the button delete_resources_mode will active after second click will show confirm modal
//By click on close icon on the button delete_resources_mode will inactive

import React from 'react'
import {
    deleteSelectedResources,
    getResources,
    setDeleteResourcesMode,
    setSelectedDeleteResources,
    setDeleteResourceModalStatus,
} from "../../store/resources";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import Modal from "../modals/ConfirmResourceDeleteModal";
import DeleteIcon from "../../images/delete-icon.svg";
import SmallRoundButton from "./SmallRoundButton";
import {withTranslation} from "react-i18next";


class DeleteResourcesButton extends React.Component {
    componentDidMount(){
        this.resetSelectedResources()
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        this.resetSelectedResources()
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if(event.keyCode === 27) {
            this.resetSelectedResources(event)
        }
    }

    handleDelete = (e) => {
        let { selected_delete_resources,delete_resources_mode} = this.props;
        if(!delete_resources_mode){
            this.props.setSelectedDeleteResources([])
            this.props.setDeleteResourcesMode(true)
        }else {
            selected_delete_resources.length > 0 && this.props.setDeleteResourceModalStatus(true)
        }
    };

    deleteResources = () => {
        let { selected_delete_resources,types} = this.props;
        this.props.deleteSelectedResources(selected_delete_resources,types).then((res) => {
            this.resetSelectedResources()
        })
    }

    resetSelectedResources = (e) => {
        if(e) e.stopPropagation()
        this.props.setDeleteResourcesMode(false);
        this.props.setSelectedDeleteResources([])
    }

    render() {
        let { delete_resources_mode,disabled,t,selected_delete_resources} = this.props;
        let bigButtonClass = delete_resources_mode ? 'btn-action_big' : '';
        return (
            <SmallRoundButton activeStatus={delete_resources_mode} activeClass={'btn-small-round_delete_mode'} icon={DeleteIcon} classes={`btn-small-round_dark mr-2 ${bigButtonClass} custom-tooltip custom-tooltip_bottom`}
                              onClick={this.handleDelete} disabled={disabled}
                              data_tooltip={t("Delete Resource")}>
                {delete_resources_mode && <span className={'btn-remove'} onClick={this.resetSelectedResources} />}
                <Modal handleDelete={this.deleteResources} selected_delete_resources={selected_delete_resources} t={t}/>
            </SmallRoundButton>

        );
    }
}

const mapStateToProps = state => ({
    resources: state.resources.resources,
    selected_delete_resources: state.resources.selected_delete_resources,
    delete_resources_mode: state.resources.delete_resources_mode,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getResources,
    deleteSelectedResources,
    setDeleteResourcesMode,
    setSelectedDeleteResources,
    setDeleteResourceModalStatus,
},dispatch);

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(DeleteResourcesButton));

