import React from 'react';
import { Button } from "reactstrap";
import ReactSVG from "react-svg";
import { Trans } from "react-i18next";

function PackageItem(props) {
    let {name,benefits,illustration_src,price,per,link} = props.package_item;
    return (
      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-items-start plan-item__wrap">
        <div className="plan-item">
          <div className="plan-item__header">
            <ReactSVG src={illustration_src} />
          </div>
          <div className="plan-item__body">
            <div className="plan-item__name">{name}</div>
            <ul className="plan-item__benefits">
              {benefits.map((benefit_item, i) => {
                return (
                  <li
                    className="plan-item__benefits-item"
                    key={i + "benefit_item"}
                  >
                    {benefit_item}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="plan-item__footer">
            <div className="plan-item__price mb-0">
              {price && <div className="plan-item__price-value">{price}</div>}
              {per && <div className="plan-item__price-period">{per}</div>}
              <br />
              {link && (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <Button color="primary">
                    <Trans>Upgrade</Trans>
                  </Button>
                </a>
              )}
              {!link && (
                  <Button color="primary">
                    <Trans>Contact Us</Trans>
                  </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default PackageItem;