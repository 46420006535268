import React, {useEffect, useState} from 'react';
import Unity  from "react-unity-webgl";
import {refreshUnity2, unityContext} from "./UnityFunctions";
import {withRouter} from "react-router-dom";

function UnityRender(props) {
    //todo put in validattion rules urls

    const [loaded,setLoaded] = useState(false);

    useEffect(() => {
        if(loaded){
            refreshUnity2(props.experience_item.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loaded])


    unityContext.on("error", message => {
        console.log(message,'message')
    });

    unityContext.on("loaded", message => {
        setTimeout(() => {
            setLoaded(true)
        },2000)
    });

    return(
        <Unity unityContext={unityContext}/>
    )
}

export default withRouter(UnityRender);
