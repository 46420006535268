//Resources icons with counts on element card
import React  from 'react'
import PhotoIcon from '../../images/resource-icon-tabs/take-photo-icon.svg';
import VideoIcon from '../../images/resource-icon-tabs/take-video-icon.svg';
import AudioIcon from '../../images/resource-icon-tabs/record-audio-icon.svg';
import TextIcon from '../../images/resource-icon-tabs/write-text-icon.svg';
import AnimationIcon from '../../images/resource-icon-tabs/create-animation-icon.svg';
import ModelIcon from '../../images/resource-icon-tabs/3d-model-icon.svg';
import ReactSVG from "react-svg";
import {resourceTypes} from "../../common";

function ResourcesList(props) {
    let {resources} = props.element_item;
    const filterByType = (resource_type) => {
        return resources.filter(resource => resource.type_id === resource_type).length
    }

    return(
        <ul className="element-item__resources-list">
            <li className="element-item__resources-list-item">
                <ReactSVG src={PhotoIcon} className="element-item__resources-icon"/>
                <span className="element-item__count-text">{filterByType(resourceTypes.image)}</span>
            </li>
            <li className="element-item__resources-list-item">
                <ReactSVG src={VideoIcon} className="element-item__resources-icon"/>
                <span className="element-item__count-text">{filterByType(resourceTypes.video)}</span>
            </li>
            <li className="element-item__resources-list-item">
                <ReactSVG src={AudioIcon} className="element-item__resources-icon"/>
                <span className="element-item__count-text">{filterByType(resourceTypes.audio)}</span>
            </li>
            <li className="element-item__resources-list-item">
                <ReactSVG src={TextIcon} className="element-item__resources-icon"/>
                <span className="element-item__count-text">{filterByType(resourceTypes.text)}</span>
            </li>
            <li className="element-item__resources-list-item">
                <ReactSVG src={ModelIcon} className="element-item__resources-icon"/>
                <span className="element-item__count-text">{filterByType(resourceTypes.zip)}</span>
            </li>
            <li className="element-item__resources-list-item">
                <ReactSVG src={AnimationIcon} className="element-item__resources-icon"/>
                <span className="element-item__count-text">{filterByType(resourceTypes.animations)}</span>
            </li>
        </ul>
    )
}

export default ResourcesList;

