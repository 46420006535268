//Button to show help carousel slides

import React from 'react'
import ReactSVG from "react-svg";
import helpIcon from "../../images/help.svg";
import {connect} from "react-redux";
import {setHelpGamificationModalStatus} from "../../store/elements";
import {bindActionCreators} from "redux";



class HelpGamificationButton extends React.Component{
    handleClick = () => {
        this.props.closeDropdown()
        this.props.setHelpGamificationModalStatus(true)
    }

    render() {
        return (
            <button className="btn help-button custom-tooltip btn-action_help"
                    data-tooltip={`Click to get help on Gamification`} onClick={this.handleClick}>
                <ReactSVG className="help-icon" src={helpIcon} alt=""/>
            </button>

        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setHelpGamificationModalStatus,
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HelpGamificationButton);

