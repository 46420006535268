//Modal for confirmation of multiple deleting elements

import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import {
    setDeleteElementModalStatus,
} from "../../store/elements";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';

class ConfirmElementDeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };

    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(prevState.show !== nextProps.show_delete_element_modal){
            update.show = nextProps.show_delete_element_modal
        }
        return Object.keys(update).length ? update : null;
    }

    toggle = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
        this.props.setDeleteElementModalStatus(!this.props.show_delete_element_modal)
    }

    handleDelete = () => {
        this.props.handleDelete()
        this.setState({show:false})
        this.props.setDeleteElementModalStatus(false)
    }

    render() {
        let message = this.props.isMultipleDelete ? 'Are you sure you want to delete these elements?' : 'Are you sure you want to delete this element?';
        return (
            <div>
                <Modal isOpen={this.state.show} toggle={this.toggle} className="modal-resource-delete" centered>
                    <ModalHeader toggle={this.toggle}>{message}</ModalHeader>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleDelete}>Yes</Button>
                        <Button color="secondary" onClick={this.toggle}>No</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    element_item: state.elements.element_item,
    show_delete_element_modal: state.elements.show_delete_element_modal
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setDeleteElementModalStatus
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmElementDeleteModal);