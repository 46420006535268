import React from 'react'
import CustomScrollBar from "../../CustomScrollBar";
import {Field, Formik} from "formik";
import {setResourceSchema} from "../../../store/validations";
import MediaRecorder from "./MediaRecorder";
import {languages} from "../../../common";
import {TabPane} from "reactstrap";
import LanguageSelect from "../../LanguageSelect";
import FileUploader from "../../FileUploader";
import {Trans, withTranslation} from "react-i18next";
import * as _ from "lodash";
import WithTranslateFormErrors from "../../WithTranslateFormErrors";

class CreateMediaResourceTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            status: 0,
            main_content_language: props.main_content_language ? _.find(languages,['short_name',props.main_content_language]).id : 1,
        }
    }

    changeStatus = (status) => {
        this.setState({status})
    }

    handleResetForm = (resetForm) => {
        this.changeStatus(0)
        resetForm(this.props.initialValues)
        this.state.player.record().getDevice()
    }

    resetPlayer = (player) => {
        this.setState({player})
    }

    render() {
        let {create_resources_mode,initialValues,validation_rules,media_type,handleSubmit,tabId,activeTab,t,id} = this.props;
        let {status} = this.state;
        let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
        let isActive = activeTab === tabId;

        return(
            <TabPane tabId={tabId} className="tab-pane">
                <CustomScrollBar options={overflowOptions}>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                handleSubmit(values);
                                setSubmitting(false)
                            }, 500);
                        }}
                        validationSchema={setResourceSchema(create_resources_mode,false,validation_rules,false,false, media_type,false,t)}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                setValues,
                                resetForm,
                                setFieldTouched
                            } = props;
                            let name_invalid_class = errors.name && touched.name ? 'invalid' : '';
                            if (!isActive) return <div className="create-resource__empty-media"/>
                            return (
                                <WithTranslateFormErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                                    <form onSubmit={handleSubmit} name="create-resource-video">
                                        <div className="row">
                                            <div className="col">
                                                <LanguageSelect data={languages} disabled={false}
                                                                onBlur={languages} setFieldValue={(name,value) => this.props.selectResourceLanguage(name,value)}
                                                                name={'language'} isDark/>
                                                <Field
                                                    id={id}
                                                    name="file"
                                                    component={FileUploader}
                                                    setFieldValue={setFieldValue}
                                                    errorMessage={errors["file"] ? errors["file"] : undefined}
                                                    touched={touched["file"]}
                                                    onBlur={handleBlur}
                                                    changeStatus={this.changeStatus}
                                                    hidden={status === 2}
                                                    status={status}
                                                />
                                                {status === 0 && <div className="note-text mb-3"><Trans>To use pre existing files as resources, please upload here...</Trans></div>}
                                                {status === 0 && <div className="note-text-big mb-2"><Trans>Or create a resource from your device</Trans></div>}
                                                <MediaRecorder setValues={setValues} media_type={media_type} values={values} changeStatus={this.changeStatus}
                                                               hidden={status === 1} resetPlayer={this.resetPlayer}/>
                                                {status !== 1 && <div className="form-group">
                                                    <input
                                                        id="media-file"
                                                        name="file"
                                                        placeholder={t(`Enter resource name`)}
                                                        type="hidden"
                                                        value={values.file}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control input-primary d-block`}/>
                                                    {errors.file && touched.file && (
                                                        <div className="input-feedback">{errors.file}</div>
                                                    )}
                                                </div>}
                                                {values.file && <div className="form-group">
                                                    <input
                                                        id="media-resource-name"
                                                        name="name"
                                                        placeholder={t(`Enter resource name`)}
                                                        type="text"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control input-primary d-block ${name_invalid_class}`}/>
                                                    {errors.name && touched.name && (
                                                        <div className="input-feedback">{errors.name}</div>
                                                    )}
                                                </div>}
                                                {values.file && <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                    <span className=""><Trans>Save</Trans></span>
                                                </button>}

                                                {values.file && <button type="button" className="btn btn-outline ml-3" disabled={isSubmitting}
                                                                        onClick={() => this.handleResetForm(resetForm)}>
                                                    <span className=""><Trans>Reset</Trans></span>
                                                </button>}
                                            </div>
                                        </div>
                                    </form>
                                </WithTranslateFormErrors>

                            )

                        }}
                    </Formik>
                </CustomScrollBar>
            </TabPane>
        )
    }
}

export default withTranslation('translations')(CreateMediaResourceTab);