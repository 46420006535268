//Input for creating new tags.
import React from 'react'
import TagsInput from "react-tagsinput";
import {withTranslation} from "react-i18next";

class AddTagsInput extends React.Component {


    renderTag = (props) => {
        let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props
        return (
            <span key={key} {...other}>
                <span className="tag-item__value">{getTagDisplayValue(tag)}</span>
                {!disabled &&
                <span className={classNameRemove} onClick={(e) => onRemove(key)} />
                }
            </span>
        )
    }

    renderLayout = (tagComponents, inputComponent) => {
        return (
            <span className="input-tag-inner">
                {inputComponent}
                <span className="tag__container">
                   {tagComponents}
                </span>
            </span>
        )
    }
    render() {
        let {classes,t} = this.props;
        return (
            <div className={`form-group ${classes}`}>
                <TagsInput
                    disabled={this.props.disabled}
                    renderLayout={this.renderLayout}
                    renderTag={this.renderTag}
                    className={`input-tag-wrapper`} focusedClassName="input-tag-wrapper_focused"
                    value={this.props.tags}
                    onChange={this.props.handleChange}
                    inputProps={{className: 'input-primary input-tag',placeholder: t('Add New tag')}} onlyUnique
                    tagProps={{className: 'tag-item', classNameRemove: 'tag-item__remove'}}/>
            </div>
        );
    }
}

export default withTranslation('translations')(AddTagsInput);

