//Text editor for editing and creating text resources
import React from 'react'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState,convertToRaw, ContentState,convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class TextEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: props.text_content ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(props.text_content)
                )
            ) : EditorState.createEmpty(),
            language: props.language ? props.language : 'en'
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(!nextProps.text_content){
            update.editorState = EditorState.createEmpty();
            update.editorState = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(' ')
                ))

        }
        if(nextProps.language !== prevState.language){
            update.language = nextProps.language;
            if(nextProps.values.text_content){
                update.editorState = EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(nextProps.values.text_content)
                    ))
            }

        }
        return Object.keys(update).length ? update : null;
    }

    onEditorStateChange = (editorState) => {
        let content = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        const currentContentTextLength = editorState.getCurrentContent().getPlainText().length;

        if (currentContentTextLength === 1) {
            // WORKAROUND: listens to input changes and focuses/moves cursor to back after typing in first character
            this.setState({
                editorState: EditorState.moveFocusToEnd(editorState),
            });
        } else {
            this.setState({
                editorState
            });
        }

        if(editorState.getCurrentContent().getPlainText()){
            this.props.setFieldValue(this.props.field.name, content);
            this.props.changeStatus && this.props.changeStatus(2) 
        }else {
            this.props.setFieldValue(this.props.field.name, undefined);
        }
    };


    render(){
        const {editorState} = this.state;
        const { errorMessage,keyProp,hidden } = this.props;
        const { name } = this.props.field;
        let input_invalid_class = errorMessage ? 'invalid' : '';
        return(
            <div className={`text-editor form-group ${hidden ? 'd-none' : ''}`} key={keyProp}>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbar"
                    wrapperClassName=""
                    editorClassName={`form-control input-primary ${input_invalid_class}`}
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                        options: ['image','blockType', 'fontSize','inline', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
                        inline: {
                            inDropdown: true,
                            options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                        },
                        blockType: {
                            inDropdown: true,
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                        },
                        list: {
                            inDropdown: false,
                            options: ['unordered', 'ordered'],
                        },
                        image: {
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            previewImage: true,
                        }
                    }}
                    onBlur={this.props.onBlur}
                    name={name}
                    id={name}
                />
                {errorMessage && (
                    <div className="input-feedback">{errorMessage}</div>
                )}
            </div>
        )
    }
}



export default TextEditor;


