import React from 'react';
import {Nav,TabContent} from "reactstrap";
import ResourceList from "./ResourceList";
import {resourceTypes, getFilteredResources, resourceTabsArray} from "../../common";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import _ from "lodash";
import {
    bindSelectedResources,
    deleteSelectedResources, setDeleteResourcesMode,
    setSelectedResources,
    setSelectedDeleteResources, getNonDegreeResources, clearCreatedResource, clearDeletedMultipleResources
} from "../../store/resources";
import ResourceTabPane from "./ResourceTabPane";
import ResourceNavTab from "./ResourceNavTab";
import { Trans } from "react-i18next";

class ResourcesTabs extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            activeTab:1,
            activeType:0,
            show_unpaired_resources:false,
            show_paired_resources:false,
            show_sub_linked_resources:false,
            resourceType: resourceTypes.all,
            sortType:'date',
            searchTerm: '',
            selectedResources:[],
            selecteddeleteResources:[],
            language: undefined,
            page: 0,
            hasMoreItems: true,
            resources: [],
            filteredResources: [],
            current_count: 0,
            total_count: 0,
        }
    }


    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(nextProps.selected_delete_resources.length === 0){
            update.selecteddeleteResources = nextProps.selected_delete_resources;
        }
        if(prevState.language !== nextProps.language){
            update.language = nextProps.language
        }
        if(nextProps.deleted_multiple_resources.length > 0){
            let toDelete = new Set(nextProps.deleted_multiple_resources)
            let updatedResourcesArray = prevState.resources.filter(resource => !toDelete.has(resource.id))
            update.resources = updatedResourcesArray
            update.total_count = prevState.total_count - nextProps.deleted_multiple_resources.length
            update.filteredResources = getFilteredResources(nextProps,prevState,prevState.activeType,false,false,false,updatedResourcesArray)
            update.current_count = update.filteredResources.length
            nextProps.clearDeletedMultipleResources()
        }
        if(nextProps.updated_resource){
            let $resources = prevState.resources;
            let index = _.findIndex($resources, {id: nextProps.updated_resource.id});
            $resources.splice(index, 1, nextProps.updated_resource);
            update.resources = $resources
            update.filteredResources = getFilteredResources(nextProps,prevState,prevState.activeType,false,false,false,$resources)
        }
        if(nextProps.created_resource && !_.find(prevState.resources,['id',nextProps.created_resource.id])){
            let updatedResourcesArray = [nextProps.created_resource,...prevState.resources]
            update.resources = updatedResourcesArray
            update.total_count = prevState.total_count +1
            update.filteredResources = getFilteredResources(nextProps,prevState,prevState.activeType,false,false,false,updatedResourcesArray)
            update.current_count = update.filteredResources.length
            nextProps.clearCreatedResource()
        }else {
            update.filteredResources = getFilteredResources(nextProps,prevState,prevState.activeType,false,false,false,prevState.resources)
            update.current_count = update.filteredResources.length
        }
        if(nextProps.show_paired_resources !== prevState.show_paired_resources && prevState.hasMoreItems){
            update.show_paired_resources = nextProps.show_paired_resources;
        }
        if(nextProps.show_sub_linked_resources !== prevState.show_sub_linked_resources){
            update.show_sub_linked_resources = nextProps.show_sub_linked_resources;
        }
        return Object.keys(update).length ? update : null;
    }


    //pair resources to element
    handleBind = () => {
        let { bindMode,selectedResources} = this.state;
        let { element_item} = this.props;
        if(!bindMode){
            this.setState({bindMode: true})
        }else {
            this.props.setCurrenteElement({...element_item,paired_resources:_.map(selectedResources,'id')})
            this.setState({bindMode: false})
            this.props.bindSelectedResources(element_item.id,_.map(selectedResources,'id')).then((res) => {
                this.props.getElementItemById(element_item.id)
                // this.props.getNonDegreeResources()
            })
        }
    }

    resetSelectedResources = (e) => {
        if(e) e.stopPropagation()
        this.setState({bindMode: false, selectedResources: [], selecteddeleteResources:[]})
        this.props.setDeleteResourcesMode(false)
        this.props.setSelectedDeleteResources([])
    }


    handleToggle = (activeTab,activeType) => {
        this.setState({activeTab,activeType})
    }

    callbackCheck = (id,checked) => {
        let {selecteddeleteResources} = this.state;
        let items = selecteddeleteResources;
        let match = _.find(items, {id});
        if(checked && !match) {
            items.push({id})
            if(this.props.delete_resources_mode){
                this.props.setSelectedDeleteResources(_.map(items,'id'))
            }
        }
        if(!checked && match){
            let index = _.indexOf(items, match);
            items.splice(index, 1);
            if(this.props.delete_resources_mode){
                this.props.setSelectedDeleteResources(_.map(items,'id'))
            }
        }
    };

    loadMoreResources = () => {
        let {page,resources} = this.state;
        let newPage = page +1;
        this.setState({page: newPage})
        this.props.getNonDegreeResources(undefined,undefined,newPage).then((res) => {
            // this.props.setLoading(false)
            let ids = res.data.map(o => o.id)
            let stateWithoutDuplicates = resources.filter(({id}) => !ids.includes(id))
            let updatedResourcesList = [...stateWithoutDuplicates,...res.data];
            if(res.meta){
                this.setState({current_count: updatedResourcesList.length,total_count: res.meta.total})
                if(res.meta.current_page < res.meta.last_page){
                    this.setState({hasMoreItems:true})
                } else this.setState({hasMoreItems:false})
            }
            this.setState({
                resources: updatedResourcesList,
                filteredResources: getFilteredResources(this.props,this.state,false,false,false,false,updatedResourcesList)
            })
        })
    }

    deleteSingleResourceFromState = (resource_id) => {
        let updatedResourcesArray = this.state.resources.filter(resource => resource.id !== resource_id)
        this.setState({
            resources: updatedResourcesArray,
            filteredResources: getFilteredResources(this.props,this.state,false,false,false,false,updatedResourcesArray),
            current_count: updatedResourcesArray.length,
            total_count: this.state.total_count -1
        })
    }



    render() {
        let {bindMode,activeTab,hasMoreItems,filteredResources,current_count,total_count,show_paired_resources,show_sub_linked_resources} = this.state;
        return(
            <div className="resources-section-wrap">
                <div className="row resources-section-wrap__header">
                    <div className="col-12 resources-section__nav-tabs-wrap">
                        <Nav tabs className='nav nav-tabs tabs-primary tabs-text'>
                            {resourceTabsArray.map(navTab => {
                                return <ResourceNavTab key={navTab.id} tabId={navTab.id} tabType={navTab.type} title={navTab.title}
                                                       handleToggle={this.handleToggle} activeTab={activeTab}/>
                            })}
                        </Nav>
                    </div>
                </div>
                <TabContent activeTab={activeTab} className="resources-section">
                    {resourceTabsArray.map((tab) => {
                        return <ResourceTabPane key={tab.id} tabId={tab.id} loadMoreResources={this.loadMoreResources}
                                                hasMoreItems={hasMoreItems} show_paired_resources={show_paired_resources}
                                                show_sub_linked_resources={show_sub_linked_resources}>
                            <ResourceList callbackCheck={this.callbackCheck} resources={filteredResources}
                                          bindMode={bindMode} deleteSingleResourceFromState={this.deleteSingleResourceFromState}/>
                        </ResourceTabPane>
                    })}
                </TabContent>
                <span className="items-count-text"><Trans>Showing</Trans> <Trans>{current_count}</Trans> <Trans>items out of</Trans>  <Trans>{total_count}</Trans></span>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    element_item: state.elements.element_item,
    resources: state.resources.resources_non_degree,
    created_resource: state.resources.created_resource,
    updated_resource: state.resources.updated_resource,
    resources_images: state.resources.resources_images,
    selected_resources: state.resources.selected_resources,
    selected_delete_resources: state.resources.selected_delete_resources,
    delete_resources_mode: state.resources.delete_resources_mode,
    deleted_multiple_resources: state.resources.deleted_multiple_resources,
    validation_rules: state.validations,
    sub_element: state.elements.sub_element,
    sub_elements: state.elements.sub_elements,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setSelectedResources,
    bindSelectedResources,
    deleteSelectedResources,
    setSelectedDeleteResources,
    setDeleteResourcesMode,
    getNonDegreeResources,
    clearCreatedResource,
    clearDeletedMultipleResources
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesTabs);
