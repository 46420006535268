import React from "react";
import { Media, Player } from "react-media-player";

function Tutorial({ children, onClose }) {
  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        {children}
        <Media>
          <div className="media">
            <div className="media-player">
              <iframe
                title="tutorials"
                id="Geeks23"
                width="350"
                height="250"
                src="https://www.youtube.com/embed/ijbu2XEzCl0?si=KHNt7jyO3XAu42xI"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </Media>
        <button style={styles.closeButton} onClick={onClose}>
          X
        </button>
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
  }
};

export default Tutorial;
