import React, {Fragment, useState} from "react";
import ReactSVG from "react-svg";
import CloseIcon from "../../../images/close-icon.svg";
import FormWrapper from "../../../components/FormWrapper";
import Sidebar from "../../../components/sidebar/Sidebar";
import {getJobStatusModificator, resourceTypes, sidebarTypes} from "../../../common";
import {useFormik} from "formik";
import {setJobSchema} from "../../../store/validations";
import CurrencyInput from "../CurrencyInput";
import DatePickerInput from "../DatePickerInput";
import TitleInput from "../TitleInput";
import DescriptionInput from "../DescriptionInput";
import JobFiles from "./JobFiles";
import ResourceType from "../ResourceType";
import Status from "../Status";
import BidsButton from "../bids/BidsButton";
import {jobStatuses} from "./JobCard/JobCard";
import moment from "moment";
// import {showFullSizeGallery} from "../../../store/resources";
import DirtyFooter from "./DirtyFooter";
import OpenJobFooter from "./OpenJobFooter";
import DraftJobFooter from "./DraftJobFooter";
import MultipleLanguageSelect from "../MultipleLanguageSelect";
import {Trans} from 'react-i18next';
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";

function JobEditSidebar(props) {
    let $openStatus = jobStatuses.find(item => item.name === 'Open for bidding').id;
    let $draftStatus = jobStatuses.find(item => item.name === 'Draft').id;
    let formik = useFormik({
        initialValues: {
            title: props.job_item ? props.job_item.title : '',
            description: props.job_item ? props.job_item.description : '',
            resource_type_id: props.job_item ? props.job_item.resource_type_id : resourceTypes.image,
            required_languages: props.job_item ? props.job_item.required_languages : [],
            max_bid: props.job_item ? props.job_item.max_bid : 1,
            currency_code: props.job_item ? props.job_item.currency_code : 'CAD',
            due_at: props.job_item ? moment(props.job_item.due_at).format("YYYY-MM-DD 00:00") : '',
            files: null
        },
        enableReinitialize: true,
        validationSchema: setJobSchema(props.validation_rules,props.t),
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const [pending, setPending] = useState(false)
    let name = props.job_item && props.job_item.status_id ? jobStatuses.find(status => status.id === props.job_item.status_id).name : '';

    const handleSubmit = () => {
        setPending(true)
        let {title,description,resource_type_id,required_languages, max_bid,currency_code,due_at,files} = formik.values;
        let job_data = {
            title,
            description,
            resource_type_id,
            required_languages,
            max_bid,
            currency_code,
            due_at: moment(due_at).format("YYYY-MM-DD 00:00"),
            status_id:props.job_item.status_id
        }
        updateJob(job_data,files);
    }

    const updateJob = (job_data,files) => {
        props.updateJob(job_data,props.job_item.id).then((res) => {
            if(files){
                props.attachFilesToJob(props.job_item.id,files).then(() => {
                    setPending(false)
                    formik.resetForm()
                })
            }else {
                setPending(false)
                formik.resetForm()
            }
        })
    }

    const onChangeCurrency = (e) => {
        let val = e.target.value.replace(/\D/g, '');
        formik.setFieldValue('max_bid',Number(val))
    }

    return(
        <ErrorBoundaryContainer>
            <FormWrapper formik={formik} formName={'edit-resource'}>
                <Sidebar show={props.sidebar_type === sidebarTypes.edit_job}
                         {...props}
                         header={<Fragment>
                             <span className="sidebar__header-title"><Trans>Edit job</Trans></span>
                             <div className="sidebar__header-buttons">
                                 <button type="button" className="btn btn-close" onClick={() => props.setSidebar(sidebarTypes.none)}>
                                     <ReactSVG src={CloseIcon} />
                                 </button>
                             </div>
                         </Fragment>}
                         footer={
                             <>
                                 {formik.dirty && <DirtyFooter formik={formik} pending={pending}/>}
                                 {!formik.dirty && props.job_item && props.job_item.status_id === $openStatus &&
                                 <OpenJobFooter formik={formik} pending={pending} updateJobStatus={props.updateJobStatus}
                                                job_id={props.job_item.id}/>}
                                 {!formik.dirty && props.job_item && props.job_item.status_id === $draftStatus &&
                                 <DraftJobFooter formik={formik} pending={pending} job_id={props.job_item.id}
                                                 deleteDraftJob={props.deleteDraftJob} setSidebar={props.setSidebar}
                                                 setPending={setPending} updateJobStatus={props.updateJobStatus}/>}
                             </>
                         }
                         wide
                >
                    {props.job_item && <div className="sidebar__inner-offset">
                        <div className="row">
                            <div className="col-6">
                                <div className="sidebar__title sidebar__title_offset-free"><Trans>Current status</Trans></div>
                                {props.job_item && <Status name={name} isBig
                                                           modificator={getJobStatusModificator(props.job_item.status_id)}/>}
                            </div>
                            <div className="col-6">
                                <BidsButton job_item={props.job_item}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <ResourceType formik={formik} t={props.t}/>
                            </div>
                            <div className="col">
                                <MultipleLanguageSelect formik={formik} {...props} name={'required_languages'}/>
                            </div>
                        </div>
                        <TitleInput formik={formik} t={props.t}/>
                        <DescriptionInput formik={formik} t={props.t}/>
                        <div className="form-group">
                            <div className="row">
                                {!process.env.REACT_APP_HIDE_JOB_BUDGET && <div className="col-6">
                                    <div className="sidebar__title sidebar__title_offset-free"><Trans>Enter a budget</Trans>
                                    </div>
                                    <CurrencyInput formik={formik} t={props.t}
                                                   id="job-max_bid"
                                                   name="max_bid"
                                                   placeholder={props.t(`Enter job budget`)}
                                                   type="text"
                                                   value={formik.values.max_bid}
                                                   onChange={onChangeCurrency}
                                                   onBlur={formik.handleBlur}/>
                                </div>}
                                <div className={!process.env.REACT_APP_HIDE_JOB_BUDGET ? "col-6" : "col-12"}>
                                    <div className="sidebar__title sidebar__title_offset-free"><Trans>Enter due date</Trans></div>
                                    <DatePickerInput formik={formik} t={props.t}/>
                                </div>
                            </div>
                        </div>
                        <JobFiles formik={formik} t={props.t} job_attachments={props.job_attachments}
                                  showFullSizeGallery={props.showFullSizeGallery} job_id={props.job_item.id}
                                  deleteJobAttachment={props.deleteJobAttachment}/>
                    </div>}
                </Sidebar>
            </FormWrapper>
        </ErrorBoundaryContainer>
    )
}

export default JobEditSidebar;
