import React, {useState} from "react";
import "../../styles/components/table.css";
import * as api from "../../api";
import { handleErrorsFromCatch } from "../../store/errors";

function Quiz(props) {
  let { number, quizdata } = props;
  let buttontext = quizdata.deleted_at === null ? "InActive" : "Active";
  const [text, setText] = useState(buttontext);
  const handleChange = (quiz_id, element_id, text) => {
      if (text === "InActive") {
          //props.deleteElementQuiz(element_id, quiz_id).then();
          return new Promise((resolve, reject) => {
              api
                  .fetchDeleteElementQuiz(element_id, quiz_id)
                  .then((responseJson) => {
                      resolve(responseJson);
                      setText("Active");
                  })
                  .catch((error) => handleErrorsFromCatch(error));
          });
      } else if (text === "Active") {
               return new Promise((resolve, reject) => {
                 api
                   .fetchActiveElementQuiz(quiz_id)
                   .then((responseJson) => {
                     resolve(responseJson);
                     setText("InActive");
                   })
                   .catch((error) => handleErrorsFromCatch(error));
               });
             }
  };

  return (
    <tr key={number}>
      <td>{number + 1}</td>
      <td>{quizdata.element_name}</td>
      <td>{quizdata.quiz_name}</td>
      <td>{quizdata.points}</td>
      <td>
        <button
          className="btn btn-primary w-auto"
          onClick={() => handleChange(quizdata.quiz_id, quizdata.element_id, text)}
        >
          {text}
        </button>
      </td>
    </tr>
  );
}

export default Quiz;
