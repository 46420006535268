//All reducers for elements
import * as api from "../api";
import {notify} from "../common";
import {setAuthorizationHeader} from "./user";
import {FETCH_IDENTIFIER_PATH} from "../api";
import { FETCH_DIGITALPRINT_PATH } from "../api";
import {FETCH_ELEMENTS_PATH} from "../api";
import {handleErrors, handleErrorsFromCatch} from "./errors";
import _ from "lodash";

const SET_ELEMENT_ITEMS = "SET_ELEMENT_ITEMS",
  SET_ELEMENT_ITEMS_FOR_EXPERIENCE = "SET_ELEMENT_ITEMS_FOR_EXPERIENCE",
  SET_CURRENT_ELEMENT = "SET_CURRENT_ELEMENT",
  SET_FIELD_ELEMENT = "SET_FIELD_ELEMENT",
  UPDATE_ELEMENT = "UPDATE_ELEMENT",
  SET_TRANSLATE_ELEMENT = "SET_TRANSLATE_ELEMENT",
  SET_TRANSLATE_QUIZ = "SET_TRANSLATE_QUIZ",
  SET_SELECTED_DELETE_ELEMENTS = "SET_SELECTED_DELETE_ELEMENTS",
  SET_DELETE_ELEMENTS_MODE = "SET_DELETE_ELEMENTS_MODE",
  SET_PRINT_ELEMENTS_MODE = "SET_PRINT_ELEMENTS_MODE",
  SET_SELECTED_PRINT_ELEMENTS = "SET_SELECTED_PRINT_ELEMENTS",
  SET_DELETE_ELEMENT_MODAL_STATUS = "SET_DELETE_ELEMENT_MODAL_STATUS",
  SET_IDENTIFIER_FILE_PROGRESS = "SET_IDENTIFIER_FILE_PROGRESS",
  SET_DIGITAL_PRINT_FILE_PROGRESS = "SET_DIGITAL_PRINT_FILE_PROGRESS",
  SET_STATUS_ML_LEARNING = "SET_STATUS_ML_LEARNING",
  SET_IDENTIFIER_DATA = "SET_IDENTIFIER_DATA",
  SET_DIGITALPRINT_DATA = "SET_DIGITALPRINT_DATA",
  SET_ELEMENTS_LIST_MODAL = "SET_ELEMENTS_LIST_MODAL",
  SET_ELEMENT_DETAILS_MODAL = "SET_ELEMENT_DETAILS_MODAL",
  SET_SCENE_ELEMENTS = "SET_SCENE_ELEMENTS",
  SET_CREATE_IDENTIFIER_ITEM_MODE = "SET_CREATE_IDENTIFIER_ITEM_MODE",
  SET_ELEMENT_PUBLISH_MODAL_STATUS = "SET_ELEMENT_PUBLISH_MODAL_STATUS",
  SET_ELEMENT_ANALYTICS = "SET_ELEMENT_ANALYTICS",
  SET_ELEMENT_QUIZ = "SET_ELEMENT_QUIZ",
  SET_QUIZ_LANGUAGE = "SET_QUIZ_LANGUAGE",
  SET_ELEMENT_LANGUAGE = "SET_ELEMENT_LANGUAGE",
  SET_CONFIRM_DELETE_IDENTIFIER_MODAL_STATUS =
    "SET_CONFIRM_DELETE_IDENTIFIER_MODAL_STATUS",
  SET_CONFIRM_DELETE_DIGITAL_PRINT_MODAL_STATUS =
    "SET_CONFIRM_DELETE_DIGITAL_PRINT_MODAL_STATUS",
  SET_SUB_ELEMENTS_PATH = "SET_SUB_ELEMENTS_PATH",
  SET_HELP_GAMIFICATION_MODAL_STATUS = "SET_HELP_GAMIFICATION_MODAL_STATUS",
  SET_IDENTIFIER_ITEM = "SET_IDENTIFIER_ITEM",
  SET_SUB_ELEMENT = "SET_SUB_ELEMENT",
  SET_LINK_RESOURCE_TO_SUB_ELEMENT = "SET_LINK_RESOURCE_TO_SUB_ELEMENT",
  SET_CREATE_DIGITAL_PRINT_MODE = "SET_CREATE_DIGITAL_PRINT_MODE",
  SET_DIGITAL_PRINT = "SET_DIGITAL_PRINT";

const initialState = {
  elements: [],
  elements_for_experience: [],
  element_item: undefined,
  selected_delete_elements: [],
  delete_elements_mode: false,
  print_elements_mode: false,
  selected_print_elements: [],
  show_delete_element_modal: false,
  progress_percent: 0,
  status_ml_learning: 0,
  identifiers: [],
  digital_prints: [],
  training_item_modal_show: false,
  show_elements_list_modal: false,
  show_elements_details_modal: false,
  show_element_publish_modal: false,
  scene_elements: [],
  create_training_item_mode: false,
  training_item: undefined,
  create_digital_print_mode: false,
  digital_print: undefined,
  quiz: undefined,
  quiz_language: "en",
  element_language: "en",
  show_confirm_delete_identifier_modal: false,
  show_confirm_delete_digital_print_modal: false,
  help_gamification_modal_status: false,
  link_resources_to_sub_elements_mode: false,
  sub_elements: [],
  sub_element_id: undefined,
  sub_element: undefined,
};

export const getElements = (relation,page) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetElements(relation,page)
                .then((res) => handleErrors(res,false,dispatch))
                .then((responseJson) => {
                    dispatch(setElementsAction(responseJson.data));
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })

    };

function setElementsAction(elements) {
    return {
        type: SET_ELEMENT_ITEMS,
        elements
    }
}

export const clearElementsList = () =>
    (dispatch) => {
        dispatch(setElementsAction(initialState.elements));
        dispatch(setElementsForExperienceAction(initialState.elements_for_experience));
    }

export const getElements2 = (params) =>
    (dispatch,getState) => {
        return new Promise((resolve, reject) => {
            api.fetchGetElements2(params)
                .then((res) => handleErrors(res,false,dispatch))
                .then((responseJson) => {
                    dispatch(setElementsForExperienceAction([...getState().elements.elements_for_experience, ...responseJson.data]))
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })

    };

const setElementsForExperienceAction = (elements_for_experience) => ({
    type: SET_ELEMENT_ITEMS_FOR_EXPERIENCE,
    elements_for_experience
});
export const getElementItemById = (id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetElementItem(id)
                .then((res) => handleErrors(res,false,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CURRENT_ELEMENT,
                        element_item: responseJson.data
                    });
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error,false,reject))
        })
    };

export const updateElementData = (id, element_updated) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateElementData(id, element_updated)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CURRENT_ELEMENT,
                        element_item: responseJson.data
                    });
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const updateElementField = (id, element_updated, field_name,language) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateElementData(id, element_updated)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_FIELD_ELEMENT,
                        name:[field_name],
                        value:responseJson.data.translations[language] ? responseJson.data.translations[language][field_name] : null,
                        language:[language],
                    });
                    notify('Successfully Saved '+ (field_name ==='name'? 'Element Name': field_name ==='description'? 'Element Description': '') ,'success')
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const addElementPreview = (id, element_updated) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateElementData(id, element_updated)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CURRENT_ELEMENT,
                        element_item: responseJson.data
                    });
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const addElementPreviewImage = (element_id,resource_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchAttachFirstResourceImageToElementPreview(element_id,resource_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const createElement = (element) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCreateElement(element)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CURRENT_ELEMENT,
                        element_item: responseJson.data
                    });
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })

    };

export const setElementLanguage = (element_language) =>
    (dispatch) => {
        dispatch({
            type: SET_ELEMENT_LANGUAGE,
            element_language
        });
    };

export const addTagsToElement = (element_id,names) =>
    (dispatch,getState) => {
        return new Promise((resolve, reject) => {
            api.fetchAddHashtags(element_id,names)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => {
                    dispatch({
                        type: SET_CURRENT_ELEMENT,
                        element_item: {
                            ...getState().elements.element_item,
                            tags:names
                        }
                    });
                    handleErrorsFromCatch(error,true,reject)
                })
        })

    };

export const deleteTagsFromElement = (element_id,names) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteHashtags(element_id,names)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })

    };


export const setCurrenteElement = element_item =>
    (dispatch) => {
        dispatch({
            type: SET_CURRENT_ELEMENT,
            element_item
        });
    };

export const setSelectedDeleteElements = (selected_delete_elements) =>
    (dispatch) => {
        dispatch({
            type: SET_SELECTED_DELETE_ELEMENTS,
            selected_delete_elements
        });
    };

export const setDeleteElementsMode = (delete_elements_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_DELETE_ELEMENTS_MODE,
            delete_elements_mode
        });
    };

export const deleteSelectedElements = (selected_elements) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteElements(selected_elements)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const deleteSingleElement = (element_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteSingleElement(element_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_CURRENT_ELEMENT,
                        element_item: undefined
                    });
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const deleteIdentifier = (element_id,identifier_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteIdentifier(element_id,identifier_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };


export const getIdentifiers = (element_id,page) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetIdentifiers(element_id,page)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_IDENTIFIER_DATA,
                        identifiers: responseJson.data
                    });
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const getDigitalPrints = (element_id, page) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .fetchGetDigitalPrints(element_id, page)
      .then((res) => handleErrors(res, true, dispatch))
      .then((responseJson) => {
        dispatch({
          type: SET_DIGITALPRINT_DATA,
          digital_prints: responseJson.data,
        });
        resolve(responseJson);
      })
      .catch((error) => handleErrorsFromCatch(error));
  });
};

export const addDigitalPrintToElement = (element_id, name, description, file, price, quantity) =>
    (dispatch) => {
        return new Promise((resolve,reject) => {
            let formdata = new FormData();
            formdata.append('files[]', file, file.name);
            formdata.append('element_id', element_id);
            formdata.append("type_id", 1);
            formdata.append("name", name);
            formdata.append("description", description);
            formdata.append("price", price);
            formdata.append("quantity", quantity);
            var xhr = new XMLHttpRequest()
            xhr.onload = function() {
                resolve(JSON.parse(xhr.responseText).data);
                dispatch({
                  type: SET_DIGITAL_PRINT_FILE_PROGRESS,
                  progress_percent: 0,
                });
            }
            xhr.onerror = function() {}
            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    var percent = Math.round((event.loaded / event.total) * 100)
                    dispatch({
                      type: SET_DIGITAL_PRINT_FILE_PROGRESS,
                      progress_percent: percent,
                    });
                }
            }
            console.log(
              FETCH_ELEMENTS_PATH + `/${element_id}` + FETCH_DIGITALPRINT_PATH
            );
            xhr.open('POST', FETCH_ELEMENTS_PATH + `/${element_id}` +  FETCH_DIGITALPRINT_PATH)
            xhr.setRequestHeader('Accept', 'application/json')
            xhr.setRequestHeader('Authorization', setAuthorizationHeader())
            xhr.send(formdata)
        })
    };

export const deleteDigitalPrints = (element_id, digital_print_id) => (
    dispatch
) => {
    return new Promise((resolve, reject) => {
    api
      .fetchDeleteDigitalPrint(element_id, digital_print_id)
      .then((res) => handleErrors(res, true, dispatch))
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => handleErrorsFromCatch(error));
    });
};

export const setElementImage = (id, file) =>
    (dispatch,getState) => {
        return new Promise((resolve, reject) => {
            api.fetchSetElementImage(id, file)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let list = getState().elements.elements_for_experience;
                    let newData = _.find(list, {id: id});
                    if(newData) newData.preview = {
                        url: responseJson.data.url
                    };
                    let index = _.findIndex(list, {id: id});
                    if(index >= 0) list.splice(index, 1, newData);
                    dispatch(setElementsForExperienceAction(list))
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };
export const startLearningOfML= () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchStartLearningOfML()
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const confirmPushIdetifiers = () =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchConfirmPushIdetifiers()
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const addIdentifierToElement = (element_id,file) =>
    (dispatch) => {
        return new Promise((resolve,reject) => {
            let formdata = new FormData();
            formdata.append('files[]', file, file.name);
            formdata.append('element_id', element_id);
            var xhr = new XMLHttpRequest()
            xhr.onload = function() {
                resolve(JSON.parse(xhr.responseText).data);
                dispatch({
                    type: SET_IDENTIFIER_FILE_PROGRESS,
                    progress_percent: 0
                });
            }
            xhr.onerror = function() {}
            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    var percent = Math.round((event.loaded / event.total) * 100)
                    dispatch({
                        type: SET_IDENTIFIER_FILE_PROGRESS,
                        progress_percent:percent
                    });
                }
            }
            xhr.open('POST', FETCH_ELEMENTS_PATH + `/${element_id}` +  FETCH_IDENTIFIER_PATH)
            xhr.setRequestHeader('Accept', 'application/json')
            xhr.setRequestHeader('Authorization', setAuthorizationHeader())
            xhr.send(formdata)
        })
    };

export const getElementsFromScene = (experience_id,scene_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchElementsFromScene(experience_id,scene_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_SCENE_ELEMENTS,
                        scene_elements: responseJson.data
                    });
                    resolve(responseJson)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const setPrintElementsMode = (print_elements_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_PRINT_ELEMENTS_MODE,
            print_elements_mode
        });
    };

export const setSelectedPrintElements = (selected_print_elements) =>
    (dispatch) => {
        dispatch({
            type: SET_SELECTED_PRINT_ELEMENTS,
            selected_print_elements
        });
    };

export const setDeleteElementModalStatus = (status) =>
    (dispatch) => {
        dispatch({
            type: SET_DELETE_ELEMENT_MODAL_STATUS,
            show_delete_element_modal:status
        });
    };

export const setCreateIdentifierMode = (status) =>
    (dispatch) => {
        dispatch({
            type: SET_CREATE_IDENTIFIER_ITEM_MODE,
            create_training_item_mode:status
        });
    };

export const setIdentifier = (training_item) =>
    (dispatch) => {
        dispatch({
            type: SET_IDENTIFIER_ITEM,
            training_item
        });
    };
export const setCreateDigitalPrintMode = (status) => (dispatch) => {
         dispatch({
           type: SET_CREATE_DIGITAL_PRINT_MODE,
           create_digital_print_mode: status,
         });
       };

export const setDigitalPrint = (digital_print) => (dispatch) => {
         dispatch({
           type: SET_DIGITAL_PRINT,
           digital_print,
         });
       };

export const clearIdentifiers = () =>
    (dispatch) => {
        dispatch({
            type: SET_IDENTIFIER_DATA,
            identifiers:[]
        });
    };

export const clearDigitalPrints = () => (dispatch) => {
  dispatch({
    type: SET_DIGITALPRINT_DATA,
    digital_prints: [],
  });
};

export const setElementsListModal = (show_elements_list_modal) =>
    (dispatch) => {
        dispatch({
            type: SET_ELEMENTS_LIST_MODAL,
            show_elements_list_modal
        });
    };

export const setElementsDetailsModal = (show_elements_details_modal) =>
    (dispatch) => {
        dispatch({
            type: SET_ELEMENT_DETAILS_MODAL,
            show_elements_details_modal
        });
    };

export const setElementPublishModalStatus = (status) =>
    (dispatch) => {
        dispatch({
            type: SET_ELEMENT_PUBLISH_MODAL_STATUS,
            show_element_publish_modal:status
        });
    };

export const setQuizLanguage = (quiz_language) =>
    (dispatch) => {
        dispatch({
            type: SET_QUIZ_LANGUAGE,
            quiz_language
        });
    };

export const setConfirmDeleteIdentifierModal = (show_confirm_delete_identifier_modal) =>
    (dispatch) => {
        dispatch({
            type: SET_CONFIRM_DELETE_IDENTIFIER_MODAL_STATUS,
            show_confirm_delete_identifier_modal
        });
    };

export const setConfirmDeleteDigitalPrintModal = (
         show_confirm_delete_digital_print_modal
       ) => (dispatch) => {
         dispatch({
           type: SET_CONFIRM_DELETE_DIGITAL_PRINT_MODAL_STATUS,
           show_confirm_delete_digital_print_modal,
         });
       };
export const setHelpGamificationModalStatus = (help_gamification_modal_status) =>
    (dispatch) => {
        dispatch({
            type: SET_HELP_GAMIFICATION_MODAL_STATUS,
            help_gamification_modal_status
        });
    };

export const setLinkResourceToSubElementMode = (link_resources_to_sub_elements_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_LINK_RESOURCE_TO_SUB_ELEMENT,
            link_resources_to_sub_elements_mode
        });
    };

export const setSubElement = (sub_element,sub_element_id) =>
    (dispatch) => {
        dispatch({
            type: SET_SUB_ELEMENT,
            sub_element,
            sub_element_id,
        });
    };

export const clearQuiz = () =>
    (dispatch) => {
        dispatch({
            type: SET_ELEMENT_QUIZ,
            quiz: undefined
        });
    };

export const getElementAnalytics = (element_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetElementAnalytics(element_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_ELEMENT_ANALYTICS,
                        element_analytics: responseJson.data
                    });
                    resolve()
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const getElementQuiz = (element_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetElementQuiz(element_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_ELEMENT_QUIZ,
                        quiz: responseJson.data.length > 0 ? responseJson.data : undefined
                    });
                    resolve()
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const createElementQuiz = (element_id,quiz_data) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCreateElementQuiz(element_id,quiz_data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_ELEMENT_QUIZ,
                        quiz: responseJson.data
                    });
                    resolve()
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };


export const deleteElementQuiz = (element_id,quiz_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteElementQuiz(element_id,quiz_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_ELEMENT_QUIZ,
                        quiz: undefined
                    });
                    resolve()
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const updateElementQuiz = (element_id,quiz_id,quiz_data) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateElementQuiz(element_id,quiz_id,quiz_data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve()
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const deleteSingleQuizAnswer = (quiz_id,answer_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteSingleQuizAnswer(quiz_id,answer_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve()
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const updateSingleQuizAnswer = (quiz_id,answer_id,answer_data) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUpdateSingleQuizAnswer(quiz_id,answer_id,answer_data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve()
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const createQuizAnswers = (quiz_id,answers) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCreateQuizAnswers(quiz_id,answers)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const autoTranslateElement = (element_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchAutoTranslateElement(element_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_TRANSLATE_ELEMENT,
                        element_item: responseJson.data
                    });
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const autoTranslateQuiz = (quiz_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchAutoTranslateQuiz(quiz_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_TRANSLATE_QUIZ,
                        quiz: responseJson.data
                    });
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };


//sub elements

export const createSubElement = (element_id,identifier_id,data) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchCreateSubElement(element_id,identifier_id,data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const updateSubElement = (element_id,sub_element_id,data) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchEditSubElement(element_id,sub_element_id,data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const getSubElements = (element_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchGetSubElements(element_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch({
                        type: SET_SUB_ELEMENTS_PATH,
                        sub_elements: responseJson.data
                    });
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const deleteSubElement = (element_id,sub_element_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchDeleteSubElement(element_id,sub_element_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const linkResourceToSubElement = (sub_element_id,ids) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchLinkResourceToSubElement(sub_element_id,ids)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };

export const setCurrentElement = element_item =>
    (dispatch) => {
        dispatch({
            type: SET_CURRENT_ELEMENT,
            element_item
        });
    };

export const unlinkResourceFromSubElement = (sub_element_id,resource_id) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            api.fetchUnLinkResourceFromSubElement(sub_element_id,resource_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    resolve(responseJson.data)
                })
                .catch(error => handleErrorsFromCatch(error))
        })
    };


//Actions for elements
const ACTION_HANDLERS = {
  [SET_ELEMENT_ITEMS]: (state, action) => ({
    ...state,
    elements: action.elements,
  }),
  [SET_ELEMENT_ITEMS_FOR_EXPERIENCE]: (state, action) => ({
    ...state,
    elements_for_experience: action.elements_for_experience,
  }),
  [SET_CURRENT_ELEMENT]: (state, action) => ({
    ...state,
    element_item: action.element_item,
  }),
  [SET_TRANSLATE_ELEMENT]: (state, action) => ({
    ...state,
    element_item: {
      ...state.element_item,
      ...action.element_item,
    },
  }),
  [SET_TRANSLATE_QUIZ]: (state, action) => ({
    ...state,
    quiz: {
      ...state.quiz,
      ...action.quiz,
    },
  }),
  [SET_FIELD_ELEMENT]: (state, action) => ({
    ...state,
    element_item: {
      ...state.element_item,
      translations: {
        ...state.element_item.translations,
        [action.language]: {
          ...state.element_item.translations[action.language],
          [action.name]: action.value,
        },
      },
    },
  }),
  [UPDATE_ELEMENT]: (state, action) => ({
    ...state,
    element_item: action.element_item,
  }),
  [SET_SELECTED_DELETE_ELEMENTS]: (state, action) => ({
    ...state,
    selected_delete_elements: action.selected_delete_elements,
  }),
  [SET_DELETE_ELEMENTS_MODE]: (state, action) => ({
    ...state,
    delete_elements_mode: action.delete_elements_mode,
  }),
  [SET_PRINT_ELEMENTS_MODE]: (state, action) => ({
    ...state,
    print_elements_mode: action.print_elements_mode,
  }),
  [SET_SELECTED_PRINT_ELEMENTS]: (state, action) => ({
    ...state,
    selected_print_elements: action.selected_print_elements,
  }),
  [SET_DELETE_ELEMENT_MODAL_STATUS]: (state, action) => ({
    ...state,
    show_delete_element_modal: action.show_delete_element_modal,
  }),
  [SET_IDENTIFIER_FILE_PROGRESS]: (state, action) => ({
    ...state,
    progress_percent: action.progress_percent,
  }),
  [SET_DIGITAL_PRINT_FILE_PROGRESS]: (state, action) => ({
    ...state,
    progress_percent: action.progress_percent,
  }),
  [SET_STATUS_ML_LEARNING]: (state, action) => ({
    ...state,
    status_ml_learning: action.status_ml_learning,
  }),
  [SET_IDENTIFIER_DATA]: (state, action) => ({
    ...state,
    identifiers: action.identifiers,
  }),
  [SET_CREATE_IDENTIFIER_ITEM_MODE]: (state, action) => ({
    ...state,
    create_training_item_mode: action.create_training_item_mode,
  }),
  [SET_IDENTIFIER_ITEM]: (state, action) => ({
    ...state,
    training_item: action.training_item,
  }),
  [SET_CREATE_DIGITAL_PRINT_MODE]: (state, action) => ({
    ...state,
    create_digital_print_mode: action.create_digital_print_mode,
  }),
  [SET_DIGITAL_PRINT]: (state, action) => ({
    ...state,
    digital_print: action.digital_print,
  }),
  [SET_DIGITALPRINT_DATA]: (state, action) => ({
    ...state,
    digital_prints: action.digital_prints,
  }),
  [SET_ELEMENTS_LIST_MODAL]: (state, action) => ({
    ...state,
    show_elements_list_modal: action.show_elements_list_modal,
  }),
  [SET_ELEMENT_DETAILS_MODAL]: (state, action) => ({
    ...state,
    show_elements_details_modal: action.show_elements_details_modal,
  }),
  [SET_SCENE_ELEMENTS]: (state, action) => ({
    ...state,
    scene_elements: action.scene_elements,
  }),
  [SET_ELEMENT_PUBLISH_MODAL_STATUS]: (state, action) => ({
    ...state,
    show_element_publish_modal: action.show_element_publish_modal,
  }),
  [SET_ELEMENT_ANALYTICS]: (state, action) => ({
    ...state,
    element_analytics: action.element_analytics,
  }),
  [SET_ELEMENT_QUIZ]: (state, action) => ({
    ...state,
    quiz: action.quiz,
  }),
  [SET_QUIZ_LANGUAGE]: (state, action) => ({
    ...state,
    quiz_language: action.quiz_language,
  }),
  [SET_ELEMENT_LANGUAGE]: (state, action) => ({
    ...state,
    element_language: action.element_language,
  }),
  [SET_CONFIRM_DELETE_IDENTIFIER_MODAL_STATUS]: (state, action) => ({
    ...state,
    show_confirm_delete_identifier_modal:
      action.show_confirm_delete_identifier_modal,
  }),
  [SET_HELP_GAMIFICATION_MODAL_STATUS]: (state, action) => ({
    ...state,
    help_gamification_modal_status: action.help_gamification_modal_status,
  }),
  [SET_SUB_ELEMENTS_PATH]: (state, action) => ({
    ...state,
    sub_elements: action.sub_elements,
  }),
  [SET_LINK_RESOURCE_TO_SUB_ELEMENT]: (state, action) => ({
    ...state,
    link_resources_to_sub_elements_mode:
      action.link_resources_to_sub_elements_mode,
  }),
  [SET_SUB_ELEMENT]: (state, action) => ({
    ...state,
    sub_element: action.sub_element,
    sub_element_id: action.sub_element_id,
  }),
};


export default function elements(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
