import React, { useState } from "react";
import {Trans} from "react-i18next";
import "../../styles/components/table.css";
import * as api from "../../api";
import { handleErrorsFromCatch } from "../../store/errors";
import Elements from "./Elements";

function PublishElement(props) {
  let { dashboard_info } = props;
  if (!dashboard_info) return null;
  let elements = dashboard_info.total_elements;
   let identifiers = dashboard_info.identifiers;
  let element_identifier_count = dashboard_info.element_identifier_count;
  let active_elements = element_identifier_count.filter((obj) => obj.active === 1)
    .length;

  return (
    <div className="profile-section profile-section__accent overflow-hidden">
      <div className="row">
        <div className="col-md-2 col-sm-12 profile-col">
          <div className="profile-section__title-bold">
            <Trans>Total Element</Trans>{" "}
          </div>
          <div className="profile-section__body">
            <div
              className="profile-section__small-text"
              style={{ fontSize: "25px" }}
            >
              {elements}
            </div>
          </div>
          <div className="profile-section__title-bold">
            <Trans>Active Element</Trans>{" "}
          </div>
          <div className="profile-section__body">
            <div
              className="profile-section__small-text"
              style={{ fontSize: "25px" }}
            >
              {active_elements}
            </div>
          </div>
          <div className="profile-section__title-bold">
            <Trans>Total Identifiers</Trans>{" "}
          </div>
          <div className="profile-section__body">
            <div
              className="profile-section__small-text"
              style={{ fontSize: "25px" }}
            >
              {identifiers}
            </div>
          </div>
        </div>
        <div className="col-md-8 col-sm-12 profile-col">
          <div className="profile-section__title-bold">
            <Trans>List Of Elements</Trans>{" "}
          </div>
          <div className="profile-section__body">
            <div className="profile-section__small-text">
              <div style={{ overflowX: "auto" }}>
                <table style={{ height: "320px", width: "950px" }}>
                  <tr>
                    <th>No.</th>
                    <th>Element</th>
                    <th>Description</th>
                    <th>Identifier Count</th>
                    <th>Action</th>
                  </tr>
                  {element_identifier_count.map((object, i) => (
                    <Elements elements={object} number={i} />
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishElement;