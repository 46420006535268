//Modal for confirmation of multiple deleting experiences

import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';

function ConfirmExperienceDeleteModal(props) {
    let message = props.isMultipleDelete ? 'Are you sure you want to delete these experiences?' : 'Are you sure you want to delete this experience?';

    const handleDelete = () => {
        props.handleDelete()
        props.setShowModal(!props.show)
    }

    return (
        <div>
            <Modal isOpen={props.show} toggle={() => props.setShowModal(!props.show)}
                   className="modal-resource-delete" centered>
                <ModalHeader toggle={() => props.setShowModal(!props.show)}>{message}</ModalHeader>
                <ModalFooter>
                    <Button color="primary" onClick={handleDelete}>Yes</Button>
                    <Button color="secondary" onClick={() => props.setShowModal(!props.show)}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ConfirmExperienceDeleteModal;
