//Checkbox for showing only unpaired resources.
import React from 'react'
import {Trans} from "react-i18next";

class ShowPairedResourcesCheckbox extends React.Component {

    render(){
        const {handleInputChange,checked,name,title} = this.props;
        let titleText = title ? title : 'Show only linked resources';
        return(
            <div>
                <div className="form-group custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id={name} name={name}
                           onChange={handleInputChange} checked={checked}/>
                    <label className="custom-control-label checkbox-label" htmlFor={name}>
                        <Trans>{titleText}</Trans>
                    </label>
                </div>
            </div>
        )
    }
}

export default ShowPairedResourcesCheckbox;


