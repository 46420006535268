//If user forget password he can send link for create new password, link will send to his email
import React  from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import {setCurrentResource} from "../../store/resources";
import {
    checkToken,
    checkTokenForResetPassword,
    getUserInfo,
    resetPassword,
    signIn,
    submitNewPassword
} from "../../store/user";
import {
    getValidationRules,
    setUpdatePasswordSchema
} from "../../store/validations";
import { Formik} from "formik";
import {Link, withRouter} from "react-router-dom";
import {notify} from "../../common";
import {Trans, withTranslation} from "react-i18next";


class ResetPassword extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email:'',
            password:'',
            password_confirmation:'',
            exist_email_error:'',
            correct_password_error:'',
            isEmail:false
        }
    }

    componentDidMount() {
        this.props.getValidationRules('dashboard')
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        update.email = nextProps.match.params.email;

        return Object.keys(update).length ? update : null;
    }

    handleInputChange= (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = (values) => {
        let {token,email} = this.props.match.params;

        let {password,password_confirmation} = values;
        if(token){
            this.props.checkTokenForResetPassword(token,email).then((res) => {
                this.props.submitNewPassword(token,email,password,password_confirmation)
                    .then((res) => this.signIn(email, password))
                    .catch((message) => notify(message || 'Please try again', 'error'))
            })
        }



    }

    signIn = (email,password) => {
        this.props.signIn({username:email, password})
            .then((res) => {
                this.props.getUserInfo().then(() => {
                    checkToken() && this.props.history.push('/')
                })
            })
            .catch((err) =>{
                this.setState({correct_password_error: 'Incorrect Password. Please try to input the correct password'})

            })

    }

    handleBlur(email){
        this.setState({isEmail: /@/.test(email)})
    }



    render(){
        let language = this.props.i18n.language;
        let {t} = this.props;
        return (
          <div className="row auth-wrap">
            <div className="col-md-8 col-sm-12">
              <div className="auth__content">
                <div className="flex-grow-1 justify-content-center align-items-center d-flex flex-column">
                  <h1 className="title main-title text-uppercase text-center line-height-normal">
                    Welcome back to{" "}
                  </h1>
                  <h2 className="subtitle text-uppercase text-center">
                    Content Creation platform
                  </h2>
                </div>
                <a
                  href="https://realmeta.ca/"
                  className="link link-primary bottom-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit realmeta.ca
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <Formik
                initialValues={this.state}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    this.handleSubmit(values);
                    setSubmitting(false);
                  }, 500);
                }}
                validationSchema={setUpdatePasswordSchema(
                  this.props.validation_rules,
                  t
                )}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  } = props;
                  let pass_invalid_class =
                      errors.password && touched.password ? "invalid" : "",
                    pass_confirm_invalid_class =
                      errors.password_confirmation &&
                      touched.password_confirmation
                        ? "invalid"
                        : "";
                  return (
                    <form onSubmit={handleSubmit} className="form-primary">
                      <div className="flex-grow-1 justify-content-center align-items-center d-flex flex-column w-100">
                        <div className="title">
                          <Trans>Reset password</Trans>
                        </div>
                        <div className="subtitle">
                          <Trans></Trans>
                        </div>
                        <div className="form-group">
                          <input
                            id="email"
                            placeholder={t("Enter Username or Email")}
                            type="text"
                            value={values.email}
                            readOnly
                            className={`form-control input-primary d-block`}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            id="password"
                            placeholder={t("Enter Password")}
                            type="password"
                            value={values.password}
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-control input-primary d-block ${pass_invalid_class}`}
                          />
                          {errors.password && touched.password && (
                            <div className="input-feedback">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            id="password_confirmation"
                            placeholder={t("Repeat Password")}
                            type="password"
                            value={values.password_confirmation}
                            name="password_confirmation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-control input-primary d-block ${pass_confirm_invalid_class}`}
                          />
                          {errors.password_confirmation &&
                            touched.password_confirmation && (
                              <div className="input-feedback">
                                {errors.password_confirmation}
                              </div>
                            )}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary mb-3"
                          disabled={isSubmitting}
                        >
                          <Trans>Submit</Trans>
                        </button>
                        <div>
                          <Link className="btn-link" to={`/${language}/login`}>
                            <Trans>Back To Login</Trans>
                          </Link>
                        </div>
                      </div>

                      <p className="copyright mb-0">
                        &copy; 2023 RealMeta <Trans>All Rights Reserved</Trans>
                      </p>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        );
    }

}


const mapStateToProps = state => ({
    user: state.user.user,
    validation_rules: state.validations
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setCurrentResource,
    signIn,
    getValidationRules,
    resetPassword,
    checkTokenForResetPassword,
    submitNewPassword,
    getUserInfo
},dispatch);

export default withRouter(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ResetPassword)));

