import React from 'react';
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Switch, withRouter} from "react-router-dom";
import Profile from "./profile/Profile";
import dashboard from './dashboard/dashboard';
import PrivateRoute from "./PrivateRoute";
import {checkToken} from "../store/user";
import VRList from "./VirtualExperiencesList";
import ARList from "./AugmentedExperiencesList";
import ElementView from "./ElementView";
import {userRoles, versionNames} from "../common";
import Home from "./Home";
import ExperienceViewContainer from "./ExperienceViewContainer";
import ExperienceVR from "./ExperienceVR";
import ExperienceCreate from "./ExperienceCreate";
import ElementList from "./ElementList";
import { getDashboardInfo } from "../store/user";

class CanvasContainer extends React.Component{
  
  render() {
        if(!this.props.user_info) return null;
        let isAR = process.env.REACT_APP_VERSION_NAME === versionNames.ar,
            isVR = process.env.REACT_APP_VERSION_NAME === versionNames.vr,
            isCombined = process.env.REACT_APP_VERSION_NAME === versionNames.combined;
            isCombined = true;
        let HomePage = isCombined ? Home : (isAR ? ARList : VRList);
        let isBusinessUser = this.props.user_info && this.props.user_info.role_id === userRoles.business_user;
        let isAdminUser = this.props.user_info && this.props.user_info.role_id === userRoles.admin;
        let notAccessible = !isBusinessUser && !isAdminUser;

        return (
          <Switch>
            <PrivateRoute
              notAccessible={notAccessible}
              authed={checkToken()}
              path="/dashboard"
              component={dashboard}
              componentProps={this.props}
            />
            <PrivateRoute
              notAccessible={notAccessible}
              exact
              authed={checkToken()}
              path="/"
              component={HomePage}
              componentProps={this.props}
            />
            <PrivateRoute
              notAccessible={notAccessible}
              authed={checkToken()}
              path="/profile"
              component={Profile}
              componentProps={this.props}
            />
            {!isAR && (
              <PrivateRoute
                notAccessible={notAccessible}
                authed={checkToken()}
                path="/virtual-experiences"
                component={VRList}
                componentProps={this.props}
              />
            )}
            {!isVR && (
              <PrivateRoute
                notAccessible={notAccessible}
                authed={checkToken()}
                path="/augmented-experiences"
                component={ARList}
                componentProps={this.props}
              />
            )}
            <PrivateRoute
              notAccessible={notAccessible}
              authed={checkToken()}
              path={`/element-:id`}
              component={ElementView}
              componentProps={this.props}
            />
            <PrivateRoute
              notAccessible={notAccessible}
              authed={checkToken()}
              path="/experience-:id"
              component={ExperienceViewContainer}
              componentProps={this.props}
            />
            <PrivateRoute
              notAccessible={notAccessible}
              authed={checkToken()}
              path="/experiences"
              component={ExperienceCreate}
              componentProps={this.props}
            />
            <PrivateRoute
              notAccessible={notAccessible}
              authed={checkToken()}
              path="/evr-:id-:eid"
              component={ExperienceVR}
              componentProps={this.props}
            />
            <PrivateRoute
              notAccessible={notAccessible}
              authed={checkToken()}
              path="/element-list"
              component={ElementList}
              componentProps={this.props}
            />
          </Switch>
        );
    }
}

const mapStateToProps = (state) => ({
  user_info: state.user.user_info,
  dashboard_info: state.user.dashboard_info,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDashboardInfo,
    },
    dispatch
  );

export default withTranslation('translations')(withRouter(connect(mapStateToProps,mapDispatchToProps)(CanvasContainer)));
