//Search location input
import React, {Component} from 'react';
import {connect} from "react-redux";
import {getState,getCity,getCountry} from '../../common';
import {withTranslation} from "react-i18next";


class AutoCompleteLocation extends Component {
    constructor(props) {
        super(props);
        this.clearSearchBox = this.clearSearchBox.bind(this);
        this.state = {
            defaultValue: props.defaultValue || '',
            value: props.defaultValue || '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let update = {};
        if(nextProps.defaultValue !== prevState.defaultValue){
            update.defaultValue = nextProps.defaultValue;
            update.value = nextProps.defaultValue;
        }
        return Object.keys(update).length ? update : null;
    }

    componentDidMount({ map, mapApi } = this.props) {
        const options = {
        };

        this.autoComplete = new mapApi.places.Autocomplete(
            this.searchInput,
            options,
        );
        this.autoComplete.addListener('place_changed', this.onPlaceChanged);
        this.autoComplete.bindTo('bounds', map);
    }

    componentWillUnmount({ mapApi } = this.props) {
        mapApi.event.clearInstanceListeners(this.searchInput);
    }



    onPlaceChanged = ({ map, addplace } = this.props) => {
        const place = this.autoComplete.getPlace();
        if (!place.geometry) return;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }
        let location = {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            address_line_1: this.searchInput.value,
            country: getCountry(place),
            state: getState(place),
            city: getCity(place),
        }
        addplace(place,location);

        this.searchInput.focus();
    };

    clearSearchBox() {
        this.searchInput.value = '';
    }

    updateSearchBox = (e) => {
        let value = e.target.value;
        this.setState({value})
    }

    render() {
        // let {experience_item,defaultValue} = this.props;
        let {value} = this.state;
        // let hasLocation = experience_item.location && experience_item.location.address ? experience_item.location.address : defaultValue;
        return (
            <input
                className="input-primary w-100"
                ref={(ref) => {
                    this.searchInput = ref;
                }}
                type="text"
                onFocus={this.props.handleSearchFocus}
                onBlur={this.props.handleSearchFocus}
                placeholder={this.props.t("Enter A Location")}
                value={value}
                onChange={this.updateSearchBox}
            />
        );
    }
}

const mapStateToProps = state => ({
    experience_item: state.experiences.experience_item,
});

export default withTranslation('translations')(connect(mapStateToProps, null)(AutoCompleteLocation));
