import React, {useEffect, useState} from "react";
import CheckIcon from '../../images/big_check.svg';
import ReactSVG from "react-svg";
import {refreshUnity2} from "./UnityFunctions";
import _ from 'lodash';

function TextureItem(props){
    const [selected,setSelected] = useState(false);
    let checkedClass = props.selected ? "checked" : '';
    useEffect(() => {
        let item = _.find(props.experience_item.textures, {id: props.data.id});
        setSelected(!!item)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.experience_item.textures]);

    useEffect(() => {
        setSelected(props.activeTexture === props.data.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.activeTexture]);

    const handleClick = () => {
        if(props.pendingAttaching) return;
        props.setPendingAttaching(true)
        props.setActiveTexture(props.data.id)
        props.attachSingleTextureToExperience(props.experience_item.id,props.data.id)
            .then(() => {
                refreshUnity2(props.experience_item.id)
                props.getExperienceItemById(props.experience_item.id)
                    .then(() => props.setPendingAttaching(false))
            })
    }
    return(
        <div className="col-md-4 col-sm-4 col-12">
            <div className={`element-item-mini texture-card ${checkedClass}`} onClick={handleClick}>
                <div className={`element-item-mini__body`}>
                    <img className="element-item__preview texture__preview" src={props.data.url} alt=""/>
                </div>
                {selected && <span className="item-order-number-wrap">
                        <span className="item-order-number">
                            {selected && <ReactSVG src={CheckIcon}/>}
                        </span>
                    </span>}
            </div>
        </div>
    )
}

export default TextureItem;
