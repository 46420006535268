import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import logo from "../images/logo.svg";
// import logoDark from "../images/logo-dark.svg";
import { userRoles, versionNames } from "../common";
import ReactSVG from "react-svg";
import Home from "../images/navbar-icons/home.svg";
// import AR from "../images/navbar-icons/ar.svg";
// import  VR from "../images/navbar-icons/vr.svg";
import Profile from "../images/navbar-icons/Profile.svg";
import Marketplace from "../images/navbar-icons/marketplace.svg";
// import Language from "../images/navbar-icons/language.svg";
import Logout from "../images/navbar-icons/logout.svg";

import Help from "../images/navbar-icons/help.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setDarkMode,
  signOut,
  toggleSignUPConfirmationModal,
} from "../store/user";
import ToggleColorMode from "./ToggleColorMode";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";
import { Trans, withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { clearContractorInfo } from "../store/contractors";
import { Media, Player } from "react-media-player";
import Tutorial from "./Tutorial";
import order from "../images/navbar-icons/order.svg";
import dashboard from "../images/stats-icon.svg";
class SidebarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      tutorial: false,
      isModalOpen: true,
      seenPopUp: localStorage.getItem("seenPopUp"),
    };
  }

  signOut = () => {
    localStorage.setItem("seenPopUp", false);
    this.props.history.push("/");
    this.props.signOut();
    this.props.toggleSidebar();
    this.toggle();
    this.props.clearContractorInfo();
  };

  toggle = () => {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
    this.props.toggleSignUPConfirmationModal(
      !this.props.sign_out_confirmation_modal
    );
  };

  toggleTutorial = () => {
    this.setState((prevState) => ({
      tutorial: !prevState.tutorial,
    }));
  };
  setModalOpen = (value) => {
    this.setState({ isModalOpen: value });
    localStorage.setItem("seenPopUp", true);
  };

  render() {
    let { signed_in, toggleSidebar, setDarkMode, dark_mode, t } = this.props;
    let positionClass = !signed_in ? "sidebar-menu_guest" : "";
    let isContractor =
      this.props.user_info &&
      this.props.user_info.role_id === userRoles.contractor;

    return (
      <nav className={`sidebar-menu ${positionClass}`}>
        {signed_in && !this.state.seenPopUp && this.state.isModalOpen && (
          <Tutorial onClose={() => this.setModalOpen(false)}>
            <h2 style={{ color: "black" }}>Guide Tutorial</h2>
          </Tutorial>
        )}

        <Modal
          isOpen={this.state.show}
          toggle={this.toggle}
          className="modal-resource-delete"
          centered
        >
          <ModalHeader toggle={this.toggle}>
            <Trans>Are you sure you want to sign out?</Trans>
          </ModalHeader>
          <ModalFooter>
            <Button color="primary" onClick={this.signOut}>
              <Trans>Yes</Trans>
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              <Trans>No</Trans>
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          style={{ width: "100%", position: "fixed" }}
          isOpen={this.state.tutorial}
          className="modal-resource-delete"
          centered
        >
          <ModalHeader toggle={this.toggleTutorial}>
            <button
              onClick={this.toggleTutorial}
              style={{ float:"right" }}
            >
              X
            </button>
            <Media>
              <div className="media-body">
                <div className="media-player">
                  <iframe
                    title="tutorial"
                    id="Geeks3"
                    width="350"
                    height="250"
                    src="https://www.youtube.com/embed/ijbu2XEzCl0?si=KHNt7jyO3XAu42xI"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </Media>
          </ModalHeader>
        </Modal>
        <Link to="/" className="navbar-brand">
          <ReactSVG src={logo} className="logo" />
          {/*<ReactSVG src={logoDark} className="logo"/>*/}
        </Link>
        {signed_in && (
          <div className="sidebar-menu__items">
            <ReactTooltip />
            <ul className="navbar-nav" style={{ marginBottom: "200px;" }}>
              <li className="nav-item" data-tip={t(`Dashboard`)}>
                <NavLink
                  exact
                  to="/dashboard"
                  className="nav-link"
                  onClick={toggleSidebar}
                >
                  <ReactSVG
                    className="sidebar-menu__icon"
                    src={dashboard}
                    alt="Dashboard"
                  />
                </NavLink>
              </li>
              <li className="nav-item" data-tip={t(`Help`)}>
                <button
                  className="btn sidebar-menu__button p-0"
                  onClick={this.toggleTutorial}
                >
                  <ReactSVG
                    className="sidebar-menu__icon"
                    src={Help}
                    alt="help"
                  />
                </button>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/orders"
                  className="nav-link"
                  onClick={toggleSidebar}
                >
                  <ReactSVG
                    className="sidebar-menu__icon"
                    src={order}
                    alt="Orders"
                  />
                </NavLink>
              </li>
              {process.env.REACT_APP_VERSION_NAME !== versionNames.vr &&
                !isContractor && (
                  <li className="nav-item" data-tip={t(`Home`)}>
                    <NavLink
                      exact
                      to="/"
                      className="nav-link"
                      onClick={toggleSidebar}
                    >
                      <ReactSVG className="sidebar-menu__icon" src={Home} />
                    </NavLink>
                  </li>
                )}
              {/*{process.env.REACT_APP_VERSION_NAME !== versionNames.ar && <li className="nav-item">*/}
              {/*    <NavLink exact to="/virtual-experiences" className="nav-link" onClick={toggleSidebar}>*/}
              {/*        <ReactSVG className="sidebar-menu__icon" src={VR} />*/}
              {/*    </NavLink>*/}
              {/*</li>}*/}
              <li className="nav-item" data-tip={t(`Profile`)}>
                <NavLink
                  exact
                  to="/profile"
                  className="nav-link"
                  onClick={toggleSidebar}
                >
                  <ReactSVG
                    className="sidebar-menu__icon"
                    src={Profile}
                    alt="user avatar"
                  />
                </NavLink>
              </li>
              <li className="nav-item" data-tip={t(`Theme`)}>
                <ToggleColorMode
                  setDarkMode={setDarkMode}
                  dark_mode={dark_mode}
                />
              </li>
              <li className="nav-item mb-0" data-tip={t(`Logout`)}>
                <button
                  className="btn sidebar-menu__button p-0"
                  onClick={this.toggle}
                >
                  <ReactSVG
                    className="sidebar-menu__icon"
                    src={Logout}
                    alt="user avatar"
                  />
                </button>
              </li>
            </ul>
            {/*<ul className="navbar-nav navbar-nav__with-separator">*/}
            {/*    /!*<li className="nav-item">*!/*/}
            {/*    /!*    <ReactSVG className="sidebar-menu__icon" src={Language} alt="user avatar"/>*!/*/}
            {/*    /!*</li>*!/*/}
            {/*    <li className="nav-item mb-0" onClick={this.signOut}>*/}
            {/*        <ReactSVG className="sidebar-menu__icon" src={Logout} alt="user avatar"/>*/}
            {/*    </li>*/}
            {/*</ul>*/}
          </div>
        )}
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  signed_in: state.user.signed_in,
  dark_mode: state.user.dark_mode,
  sign_out_confirmation_modal: state.user.sign_out_confirmation_modal,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
      setDarkMode,
      toggleSignUPConfirmationModal,
      clearContractorInfo,
    },
    dispatch
  );

export default withRouter(
  withTranslation("translations")(
    connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
  )
);
