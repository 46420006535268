import React, { useState, useRef } from "react";
import { Trans } from "react-i18next";
import "../../styles/model.css";
import ReactSVG from "react-svg";
import DeleteIcon from "../../images/delete-icon.svg";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import moment from "moment";

import "react-image-crop/src/ReactCrop.scss";

function Slider(props) {
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(16 / 9)
  let {
    children,
    index,
    show,
    onClose,
    removeImage,
    photos,
    setFieldValue,
  } = props;


  async function onDownloadCropClick() {
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }
    blobUrlRef.current = URL.createObjectURL(blob)
    const new_crop_file = new File([blob], `${moment().format("X")}.png`, {
      type: blob.type,
    });
    setFieldValue("file", new_crop_file);
    const ret = photos.slice(0);
    ret[index] = new_crop_file;
    setFieldValue("files", ret); 
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )
  
  
     const getImage = (x) => {
       return URL.createObjectURL(x);
     };

    return (
      show && (
        <>
          <div className="modal-backdrop" onClick={onClose} />
          <div className="modal-wrapper">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title" style={{ color: "#000" }}>
                  Clicked Identifier Image
                </div>
                <div onClick={onClose} className="modal-close">
                  X
                </div>
              </div>
              <div className="modal-body">
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  // circularCrop
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={getImage(children)}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                  />
                </ReactCrop>
                {!!completedCrop && (
                  <>
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: "1px solid black",
                          objectFit: "contain",
                          width: completedCrop.width,
                          height: completedCrop.height,
                        }}
                      />
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn identifier-btn-delete"
                        onClick={() => {
                          onDownloadCropClick();
                          onClose();
                        }}
                        style={{
                          backgroundColor: "gray",
                          height: "30px",
                          width: "186px",
                          alignSelf: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        Save Crop Image
                      </button>
                    </div>
                  </>
                )}
              </div>
              <button
                type="button"
                className="btn identifier-btn-delete"
                onClick={() => {
                  removeImage(index);
                }}
                style={{
                  backgroundColor: "gray",
                  height: "28px",
                  width: "100px",
                  alignSelf: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                <ReactSVG className="svg-icon-wrap" src={DeleteIcon} alt="" />{" "}
                Delete
              </button>
            </div>
          </div>
        </>
      )
    );
}

export default Slider;