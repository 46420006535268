import React from "react";
import {Link} from "react-router-dom";
import {jobStatuses} from "../jobs/JobCard/JobCard";
import {Trans} from 'react-i18next';

function BidsButton(props) {
    let $openStatusId = jobStatuses.find(job => job.name === 'Open for bidding').id;
    if(!props.job_item || !props.job_item.bids_count || props.job_item.status_id !== $openStatusId){
        return <Link to={{pathname: `bids-job-item-${props.job_item.id}`, job_item: props.job_item}}>
            <button type="button" className='btn btn-primary mt-5'><Trans>See details</Trans></button>
        </Link>;
    }
    return(
        <>
            <div className="sidebar__title sidebar__title_offset-free"><Trans>Bids</Trans></div>
            <Link to={{pathname: `bids-job-item-${props.job_item.id}`, job_item:props.job_item}}>
                <button type="button" className='btn btn-primary'>
                    {props.job_item.bids_count} &nbsp;
                    {props.job_item.new_bids_count && props.job_item.new_bids_count.length > 0 && <span className="text-bold">
                            ({props.job_item.new_bids_count} new)
                        </span>}
                    bid{props.job_item.bids_count.length > 0  && 's'}
                </button>
            </Link>
        </>
    )
}

export default BidsButton;
