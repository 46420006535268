import React from 'react';
import ReactSVG from "react-svg";
import SpinIcon from '../../images/tail-spin.svg';

function Button(props) {
    return(
        <button {...props}>
            {props.children}
            {props.loading && <ReactSVG src={SpinIcon} className="spin-icon"/>}
        </button>
    )
}

export default Button;