//element view screen showing by clicking on element card
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
//import { getAccessToken} from "../store/user";


class ElementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business_id: 0,
      experience_id: 0,
      experience_status: false
    };
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(ElementList)
);
