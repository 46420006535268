import React, {Fragment, useEffect, useState} from 'react';
import {sidebarTypes} from "../../common";
import Sidebar from "./Sidebar";
import {Trans} from "react-i18next";
import ReactSVG from "react-svg";
import CloseIcon from "../../images/close-icon.svg";
import Button from "../buttons/Button";
import ElementsList from "../element/ElementsList";
import _ from "lodash";
import {refreshUnity2} from "../experience/UnityFunctions";

function ElementsListSidebar(props) {
    let $items = [];
    useEffect(() => {
        if(props.experience_item && props.experience_item.elements){
            props.experience_item.elements.forEach((element) => {
                $items.push({element_id:element.id,frame_id:element.frame_id})
                setItems($items)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.experience_item])

    const [items,setItems] = useState([]);
    const [countUpdates,setCountUpdates] = useState(0);
    const onSelectElement = (id,setOrder) => {
        let index = _.findIndex(items, {element_id: id});
        let updatedItems = items;
        if(index >=0){
            updatedItems.splice(index, 1);
        }else {
            updatedItems.push({element_id:id})
        }
        updatedItems.map((item,i) => {
            let order = i +1;
            setOrder(order)
            setCountUpdates(countUpdates +1)
            return item.frame_id = order;
        })
        setItems(updatedItems)
    }

    const handleSave = () => {
        props.mappingElementsForExperience(props.experience_item.id,items)
            .then(() => {
                refreshUnity2(props.experience_item.id)
                props.getExperienceItemById(props.experience_item.id)
                props.setSidebar(sidebarTypes.experience)
            })
    }

    // const handleChangeOrder = () => {
    //     props.setSidebar(sidebarTypes.change_elements_order)
    // }
        return(
            <Sidebar show={props.sidebar_type === sidebarTypes.elements_list} {...props}
                header={<Fragment>
                    <div className="d-flex justify-content-start align-items-center">
                        <Trans>Select elements</Trans>
                    </div>
                    <button type="button" className="btn btn-close py-0" onClick={() => props.setSidebar(sidebarTypes.experience)}>
                        <ReactSVG className="btn-small-round__icon" src={CloseIcon}/>
                    </button>
                </Fragment>}
                footer={<Fragment>
                    <Button
                        className={`btn btn-primary w-100`}
                        onClick={handleSave}
                        >Save</Button>
                    {/*{items.length > 0 && <Button className={`btn btn-primary w-100`}*/}
                    {/*        onClick={handleChangeOrder}>Change order</Button>}*/}
                </Fragment>}
                wide
            >
                <div className="sidebar__inner-offset">
                    <ElementsList {...props} onSelectElement={onSelectElement} selectedItems={items} countUpdates={countUpdates}/>
                </div>

            </Sidebar>
    )
}

export default ElementsListSidebar;
