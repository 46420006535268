import React from "react";
import Autocomplete from "../../../../components/google-map/Autocomplete";
import {Trans} from 'react-i18next';

function LocationInput(props) {
    let {formik,contractor_item} = props;

    const onChange = (location) => {
        formik.setFieldValue('location', location.address_line_1)
        let data = {
            longitude: location.longitude, latitude: location.latitude, address_line: location.address_line_1
        }
        props.updateContractorInfo(contractor_item.id,data)
    }
    return(
        <>
            <div className="contractor-profile__title"><Trans>Location</Trans></div>
            <Autocomplete
                name="location"
                changePlace={onChange}
                errorMessage={formik.errors["location"] ? formik.errors["location"] : undefined}
                touched={formik.touched["location"]}
                onBlur={formik.handleBlur}
                hiddenMap
                item={contractor_item}
                location={formik.values.location}
            />
        </>
    )
}

export default LocationInput;
