import React from 'react';
import {languages} from "../../../common";
import LanguageSelect from "../../LanguageSelect";
import InputWithButtons from "../../InputWithButtons";
import {withTranslation} from "react-i18next";
import ElementTags from "../../ElementTags";

function ElementDetails(props) {

    let {element_item,values,errors,handleChange,touched,handleBlur,onBlurSave,selectElementLanguage,resetForm,t,} = props;
    let name_invalid_class = errors.name && touched.name ? 'invalid' : '',
        desc_invalid_class = errors.description && touched.description ? 'invalid' : '';
    if(!element_item) return;

    return(
        <div className="element-details">
            <div className="row">
                <div className="col-lg-5 col-md-12 element-details__left-col">
                    {/*<ElementPreview {...props}/>*/}
                    <div className="row">
                        <div className="col-md-5 col-sm-4">
                            <LanguageSelect data={languages} disabled={false}
                                            onBlur={languages} setFieldValue={selectElementLanguage}
                                            name={'language'} isDark hideHelp classes="mb-3"/>
                        </div>
                        {/*<div className="col-md-7 col-sm-8 mb-sm-0 mb-4">*/}
                        {/*    <AutoTranslate handleClick={props.autoTranslateElement}/>*/}
                        {/*</div>*/}
                    </div>
                    <ElementTags {...props}/>
                </div>
                <div className="col-lg-7 col-md-12 element-details__right-col">
                    {/*<div className="row">*/}
                    {/*    <div className="col-md-5 col-sm-4">*/}
                    {/*        <LanguageSelect data={languages} disabled={false}*/}
                    {/*                        onBlur={languages} setFieldValue={selectElementLanguage}*/}
                    {/*                        name={'language'} isDark hideHelp classes="mb-3"/>*/}
                    {/*    </div>*/}
                    {/*    /!*<div className="col-md-7 col-sm-8 mb-sm-0 mb-4">*!/*/}
                    {/*    /!*    <AutoTranslate handleClick={props.autoTranslateElement}/>*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*</div>*/}
                    <InputWithButtons handleSave={() => onBlurSave(errors.name,values.name,"name")}
                                      handleDiscard={() => resetForm()}>
                        <input
                            disabled={element_item.active}
                            id="name"
                            name="name"
                            placeholder={t("Enter Name")}
                            type="text"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={(e) => {handleBlur(e)}}
                            className={`form-control input-primary d-block ${name_invalid_class}`}/>
                        {errors.name && touched.name && (
                            <div className="input-feedback">{errors.name}</div>
                        )}
                    </InputWithButtons>
                    <InputWithButtons handleSave={() => onBlurSave(errors.description,values.description,"description")}
                                      handleDiscard={() => resetForm()} wrapClasses={'mb-0'}>
                        <textarea
                            disabled={element_item.active}
                            id="description"
                            name="description"
                            rows="7"
                            placeholder={t("Enter Description")}
                            value={values.description}
                            onChange={handleChange}
                            onBlur={(e) => {handleBlur(e);}}
                            className={`form-control input-primary textarea-primary d-block ${desc_invalid_class}`}/>
                        {errors.description && touched.description && (
                            <div className="input-feedback">{errors.description}</div>
                        )}
                    </InputWithButtons>
                </div>
            </div>
        </div>
    );
}

export default withTranslation('translations') (ElementDetails);
