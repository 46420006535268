import React from "react";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";
import bigOverlayImage from '../../images/big-overlay.png';

function SignUp(props) {
    let {language} = props.i18n;

    return (
      <div className="body row auth-wrap">
        <div className="px-5 col-md-4 order-md-1 col-12 order-2 d-flex flex-column justify-content-center align-items-center">
          <div className="w-100 p-3 m-0">
            <h2 className="title text-black-50 mb-5 text-center">Select your role:</h2>
            <Link
              to={`/${language}/register-museum`}
              className="sign-in-button mb-4 mw-90"
            >
              Creator
            </Link>
            <Link
              to={`/${language}/register-contractor`}
              className="sign-in-button mw-90"
            >
              Contractor
            </Link>
          </div>

          <div className="footer-container">
            <p className="copyright mb-0">
              &copy; 2023 RealMeta <Trans>All Rights Reserved</Trans>
            </p>
          </div>

        </div>
        <div className="col-md-8 col-12 order-md-2 order-1">
          <div className="auth__content auth__content_right">
            <div className="flex-grow-1 justify-content-center align-items-center d-flex flex-column">
              <div className="big-overlay-right">
                <img src={bigOverlayImage} draggable={false} alt="" />
              </div>
              <h1 className="title main-title text-center">
                <Trans>
                  Let’s create your < br/> account
                </Trans>
              </h1>
              <h2 className="subtitle mb-5 text-center">
                <Trans>It’s only a few clicks away!</Trans>
              </h2>
              {/*<Link to="https://www.3rdilab.com/" target="_blank" className="btn btn-primary btn-outline">Learn more</Link>*/}
            </div>
            {/* <a
              href="https://realmeta.ca/"
              className="link link-primary bottom-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Visit</Trans> www.realmeta.ca
            </a> */}
          </div>
        </div>
      </div>
    );
}

export default SignUp;
