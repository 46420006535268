import React, {useEffect} from "react";
import JobDetailsSection from "./JobDetailsSection";
import TopSection from "../top-section/TopSection";
import {sidebarTypes} from "../../../common";
import {withRouter} from "react-router-dom";

function AcceptedJob(props) {
    useEffect(() => {
        let job_id = props.match.params.id;
        props.setCurrentJob(job_id)
        props.getJobItem(job_id)
        // props.getBidItem(1)
        props.getBidComments({bid_id:props.bid_item.id})
        props.setSidebar(sidebarTypes.none)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <>
            <TopSection {...props}/>
            <JobDetailsSection {...props}/>
        </>
    )
}

export default withRouter(AcceptedJob);
