//List of identifier data
import React from 'react'
import CustomScrollBar from "../CustomScrollBar";
import DigitalPrintsItem from "./DigitalPrintsItem";


function DigitalPrintsList(props) {
    let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}};
    if(!props.element_item || !props.digital_prints) return null;

    return (
      <CustomScrollBar options={overflowOptions}>
        <div className="digital_prints">
          <div className="row">
            {props.digital_prints.map((item, i) => {
              return (
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6" key={i}>
                  <DigitalPrintsItem
                    item={item}
                    key={i + "digital-print-item"}
                    percent={100}
                    element_id={props.element_item.id}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </CustomScrollBar>
    );
}


export default DigitalPrintsList;


