import React from "react";
import ReactSVG from "react-svg";
import Photo from "../../images/resource-icon-tabs/take-photo-icon.svg";
import Video from "../../images/resource-icon-tabs/take-video-icon.svg";
import Audio from "../../images/resource-icon-tabs/record-audio-icon.svg";
import Model from "../../images/resource-icon-tabs/3d-model-icon.svg";
import Text from "../../images/resource-icon-tabs/write-text-icon.svg";
import Animation from "../../images/resource-icon-tabs/create-animation-icon.svg";
import {resourceTypes} from "../../common";

function getIcon(id){
    switch (id){
        case resourceTypes.image: return Photo;
        case resourceTypes.video: return Video;
        case resourceTypes.audio: return Audio;
        case resourceTypes.model: return Model;
        case resourceTypes.text: return Text;
        case resourceTypes.animations: return Animation;
        default: return null;
    }
}

function ResourcesIcons(props) {
    if(!props.resources) return null;
    return(
        <>
            {props.resources.map((item) => {
                return <ReactSVG className="verification-icon" src={getIcon(item)} key={`icon-${item}`}/>
            })}
        </>
    )
}

export default ResourcesIcons;
