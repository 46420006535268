import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Trans } from "react-i18next";
import WithTranslateFormErrors from "../components/WithTranslateFormErrors";
import ErrorBoundaryContainer from "../components/ErrorBoundaryContainer";
import ExperienceCreateContainer from "./ExperienceCreateContainer";
import { getBusinessInfo } from "../store/user";
import { withTranslation } from "react-i18next";
import { getUnityFile } from "../store/experiences";

class ExperienceCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      unity_data:[],
      unity_file: '',
      experience_id: 0,
      business_id:0
    };
  }

  componentDidMount() {
    this.props.getBusinessInfo().then((res) => {
      this.setState({
        business_id: res.data.id,
      });
      // this.props.getUnityFile(1, res.data.id).then((res1) => {
      //   if (res1.length !== 0) {
      //     this.props.history.push("/evr-" + res.data.id + "-1");
      //     this.props.history.go(0);
      //   }
      // });
    });
  }

  render() {
    return (
      <ErrorBoundaryContainer>
        <div className="profile-section overflow-hidden h-100 mb-0">
          <div className="profile-section__header">
            <div className="mb-sm-0 mb-2">
              <Trans>Create Experience</Trans>
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={this.state}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                this.handleSubmit(values);
                setSubmitting(false);
              }, 500);
            }}
            //validationSchema={setBusinessSchema(validation_rules, t)}
          >
            {(props) => {
              const {
                touched,
                errors,
                handleSubmit,
                setFieldTouched,
              } = props;
              return (
                <WithTranslateFormErrors
                  errors={errors}
                  touched={touched}
                  setFieldTouched={setFieldTouched}
                >
                  <form onSubmit={handleSubmit} name="" className="h-100 w-100">
                    <ExperienceCreateContainer
                      formik={props}
                      uploadBusinessPoster={this.props.uploadBusinessPoster}
                      business_id={this.state.business_id}
                    />
                  </form>
                </WithTranslateFormErrors>
              );
            }}
          </Formik>
        </div>
      </ErrorBoundaryContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  unity_data:state.unity_data,
  business_id:state.business_id
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBusinessInfo,
      getUnityFile,
    },
    dispatch
  );

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(ExperienceCreate)
);

