import React, {useState} from 'react';
import DropdowMenu from "../sidebar/DropdowMenu";
import TextureItem from "./TextureItem";
import CustomScrollBar from "../CustomScrollBar";


function SelectToolDropdown(props) {

    const [pending,setPending] = useState(false);
    const [pendingAttaching,setPendingAttaching] = useState(false);
    const [activeTexture,setActiveTexture] = useState(undefined);

    const setOpenedDropDown = (title) => {
        setPending(true)
        props.setOpenedDropDown(title)
        props.getUnityTextures(props.type).then(res => {
            setPending(false);
        })
    }
    let overflowOptions = {overflowBehavior : {x : "hidden", y : "scroll"}}

    return(
        <DropdowMenu title={props.t(props.title)}
                     openedDropDown={props.openedDropDown}
                     setOpenedDropDown={setOpenedDropDown}>
            <CustomScrollBar options={overflowOptions}>
                {!pending && <div className="row">
                    {props.unity_textures && props.unity_textures.map((texture) => {
                        return (
                            <TextureItem data={texture}
                                         experience_item={props.experience_item}
                                         attachSingleTextureToExperience={props.attachSingleTextureToExperience}
                                         getExperienceItemById={props.getExperienceItemById}
                                         setActiveTexture={setActiveTexture}
                                         activeTexture={activeTexture}
                                         setPendingAttaching={setPendingAttaching}
                                         pendingAttaching={pendingAttaching}/>
                        )
                    })}
                </div>}
            </CustomScrollBar>
        </DropdowMenu>
    )
}

export default SelectToolDropdown;
