// import * as api from "../api";

import * as api from "../api";
import {handleErrors} from "./errors";
import {deleteFromListById} from "../common";

const SET_CURRENT_CONTRACTOR = 'SET_CURRENT_CONTRACTOR',
    SET_CONTRACTORS_LIST = 'SET_CONTRACTORS_LIST',
    SET_REQUISITION_MODE = 'SET_REQUISITION_MODE',
    SET_BID_MODE = 'SET_BID_MODE',
    SET_CURRENT_CONTRACTOR_PORTFOLIO = 'SET_CURRENT_CONTRACTOR_PORTFOLIO',
    SET_CURRENT_BID = 'SET_CURRENT_BID';

const initialState = {
    contractors: [],
    contractor_item: undefined,
    portfolio_items: [],
    requisition_item: undefined,
    submit_requisition_mode: false,
    submit_bid_mode: false,
};


export const getContractorInfo = (contractor_id) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetContractorInfo(contractor_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setCurrentContractorAction(responseJson.data))
                    resolve(responseJson)
                })
        })
    }

export const clearContractorInfo = () =>
    (dispatch) => {
        dispatch(setCurrentContractorAction(initialState.contractor_item))
    }

export const updateContractorInfo = (contractor_id,data) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchUpdateContractorInfo(contractor_id,data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setCurrentContractorAction(responseJson.data))
                    resolve(responseJson)
                })
        })
    }


export const getContractorPortfolio = (params) =>
    (dispatch) => {
        return new Promise((resolve,reject) =>{
            api.fetchGetContractorPortfolio(params)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    dispatch(setCurrentContractorPortfolioAction(responseJson.data))
                    resolve(responseJson)
                })
        })
    }

export const createContractorPortfolioItem = (contractor_id,data) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchCreateContractorPortfolioItem(contractor_id,data)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let items = [...getState().contractors.portfolio_items,responseJson.data];
                    dispatch(setCurrentContractorPortfolioAction(items))
                    resolve(responseJson.data)
                })
        })
    }

export const deleteContractorPortfolioItem = (contractor_id,porfolio_id) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchDeleteContractorPortfolioItem(contractor_id,porfolio_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let items = getState().contractors.portfolio_items;
                    deleteFromListById(items,porfolio_id)
                    dispatch(setCurrentContractorPortfolioAction(items))
                    resolve(responseJson.data)
                })
        })
    }

export const uploadContractorAvatar = (contractor_id,avatar) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchUploadContractorAvatar(contractor_id,avatar)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let contractor_item = getState().contractors.contractor_item;
                    contractor_item.avatar = responseJson.data;
                    dispatch(setCurrentContractorAction(contractor_item))
                    resolve(responseJson.data)
                })
        })
    }

export const deleteContractorAvatar = (contractor_id) =>
    (dispatch,getState) => {
        return new Promise((resolve,reject) =>{
            api.fetchDeleteContractorAvatar(contractor_id)
                .then((res) => handleErrors(res,true,dispatch))
                .then((responseJson) => {
                    let contractor_item = getState().contractors.contractor_item;
                    contractor_item.avatar = undefined;
                    dispatch(setCurrentContractorAction(contractor_item))
                    resolve(responseJson.data)
                })
        })
    }

export const setCurrentContractorAction = (contractor_item) =>
    ({
        type: SET_CURRENT_CONTRACTOR,
        contractor_item
    })

export const setCurrentContractorPortfolioAction = (portfolio_items) =>
    ({
        type: SET_CURRENT_CONTRACTOR_PORTFOLIO,
        portfolio_items
    })

export const setCurrentContractor = (contractor_item) =>
    (dispatch) => {
        dispatch({
            type: SET_CURRENT_CONTRACTOR,
            contractor_item
        });
    };


export const setRequisitionMode = (submit_requisition_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_REQUISITION_MODE,
            submit_requisition_mode
        });
    };

export const setBidMode = (submit_bid_mode) =>
    (dispatch) => {
        dispatch({
            type: SET_BID_MODE,
            submit_bid_mode
        });
    };

export const setCurrentBid = (bid_item) =>
    (dispatch) => {
        dispatch({
            type: SET_CURRENT_BID,
            bid_item
        });
    };


//Contractors actions
const ACTION_HANDLERS = {
    [SET_CONTRACTORS_LIST]: (state, action) => ({
        ...state,
        contractors: action.contractors,
    }),
    [SET_CURRENT_CONTRACTOR]: (state, action) => ({
        ...state,
        contractor_item: action.contractor_item,
    }),
    [SET_CURRENT_CONTRACTOR_PORTFOLIO]: (state, action) => ({
        ...state,
        portfolio_items: action.portfolio_items,
    }),
    [SET_REQUISITION_MODE]: (state, action) => ({
        ...state,
        submit_requisition_mode: action.submit_requisition_mode,
    }),
    [SET_BID_MODE]: (state, action) => ({
        ...state,
        submit_bid_mode: action.submit_bid_mode,
    }),
    [SET_CURRENT_BID]: (state, action) => ({
        ...state,
        bid_item: action.bid_item,
    }),
};


export default function contractors(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
