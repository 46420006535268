//Identifier item for element. Using on identifier data page
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg'
import {
    deleteIdentifier,
    getElementItemById,
    getIdentifiers, setConfirmDeleteIdentifierModal, setCreateIdentifierMode,
    setIdentifier,
} from "../../store/elements";
import {setLoading} from "../../store/user";
import DeleteIcon from "../../images/delete-icon.svg";
import {setCreateResourcesMode, setCurrentResource} from "../../store/resources";
import ConfirmDeleteIdentifierModal from "../modals/ConfirmDeleteIdentifierModal";
import { Trans } from "react-i18next";
import MKVIcon from "../../images/video-icon.png";

class IdentifierItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: false,
    };
  }

  deleteIdentifier = (id) => {
    this.setState({ pending: true });
    this.props.deleteIdentifier(this.props.element_item.id, id).then(() => {
      this.setState({ pending: false });
      if (this.props.element_id) {
        this.props.getElementItemById(this.props.element_item.id);
        this.props.getIdentifiers(this.props.element_id);
      }
    });
  };

  handleDeleteButton = (e) => {
    this.props.setConfirmDeleteIdentifierModal(true);
  };

  handleIdentifierClick = () => {
    this.props.setCreateIdentifierMode(false);
    this.props.setCurrentResource(undefined);
    this.props.setCreateResourcesMode(false);
    this.props.setIdentifier(this.props.item);
  };

  renderStatus(status) {
    switch (status) {
      case "init":
        return "Pending Approval";
      case "approved":
        return "Identifier Approved";
      case "rejected":
        return "Identifier Rejected";
      case "not_found":
        return "Identifier error";
      case "syncing":
        return "Processing";
      case "success":
        return "Success";
      case "failure":
        return "Failed";
      case "deleting":
        return "Deleting";
      case "deleted":
        return "Deleted";
      default:
        return "Pending Approval";
    }
  }

  render() {
    const { item, percent, preview_url, is_new, element_item } = this.props;
    const { pending } = this.state;

    if (!item) return null;
    return (
      <div className="identifier">
        {!is_new && (
          <div
            className="identifier__item-click-area"
            onClick={this.handleIdentifierClick}
          />
        )}
        <div className="identifier-image-wrap">
          <img
            src={
              item.preview_url && !(item.preview_url).includes('video-thumb') ? item.preview_url : MKVIcon
            }
            alt=""
            className="identifier-image"
          />
        </div>
        <div className="identifier-content">
          {percent > 0 && is_new && (
            <div className="progress">
              <span className="progress__status">{`${percent}%`}</span>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${percent}%` }}
                aria-valuenow={percent}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          )}
          <span className="identifier-name">{`${item.name}`}</span>
          {/*<div className="identifier-status">Status: {this.renderStatus(item.status)}</div>*/}
          <button
            type="button"
            className="btn identifier-btn-delete"
            //onClick={this.handleDeleteButton}
            onClick={() => this.deleteIdentifier(item.id)}
            disabled={pending || element_item.active}
          >
            <ReactSVG className="svg-icon-wrap" src={DeleteIcon} alt="" />{" "}
            <Trans>Delete</Trans>
          </button>
        </div>
        {<ConfirmDeleteIdentifierModal handleDelete={this.deleteIdentifier} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
    element_item: state.elements.element_item,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    deleteIdentifier,
    setLoading,
    getElementItemById,
    getIdentifiers,
    setIdentifier,
    setCreateIdentifierMode,
    setCurrentResource,
    setCreateResourcesMode,
    setConfirmDeleteIdentifierModal
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IdentifierItem);

