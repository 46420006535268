import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import React from "react";
import {themes} from "../common";


const CustomizedAxisTick = (props) => {
    const {x, y, payload,theme} = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill={theme.text_color} fontSize="8" fontFamily="Montserrat,sans-serif">{payload.value}</text>
        </g>
    );
}

function StatisticsChart(props) {
    let {data,interval,dark_mode} = props;
    let theme = dark_mode ? themes['dark-mode'] : themes['light-mode']


    return(
        <ResponsiveContainer width={'100%'} height={100} minHeight={100}>
            <AreaChart
                data={data}
            >
                <XAxis dataKey="date" tick={<CustomizedAxisTick theme={theme}/>} interval={interval} padding={{ left: 20, right: 20 }} />
                <Tooltip contentStyle={{color: '#332C51'}} itemStyle={{color: '#332C51'}}
                         formatter={(name, value, props) => [name,'Views' ]}/>
                <Area type="monotone" dataKey="count" stroke="transparent" fill="#6C63FF"
                      dot={{strokeWidth: 0, width: 1, height: 1, fill: theme.text_color,fillOpacity: 1, r: 1}}
                      activeDot={{strokeWidth: 0, width: 3, height: 3, fill: theme.text_color,fillOpacity: 1, r: 3}}
                />
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default StatisticsChart;