//Button for create identifier item

import React from 'react'
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import CreateIcon from "../../images/create-icon.svg";
import SmallRoundButton from "./SmallRoundButton";
import {
    setIdentifier,
    setCreateIdentifierMode,
    setCreateDigitalPrintMode,
    setDigitalPrint
} from "../../store/elements";
import {setCreateResourcesMode, setCurrentResource} from "../../store/resources";
import {withTranslation} from "react-i18next";



class CreateDigitalPrintButton extends React.Component {

    handleClick = () => {
        this.props.setIdentifier(undefined)
        this.props.setCreateResourcesMode(false)
        this.props.setCurrentResource(undefined)
        this.props.setCreateIdentifierMode(false)
        this.props.setDigitalPrint(undefined);
        this.props.setCreateDigitalPrintMode(true);
    }

    render() {
        let {element_item,t} = this.props;
        return (
          <SmallRoundButton
            icon={CreateIcon}
            classes={
              "btn-small-round_dark custom-tooltip custom-tooltip_bottom"
            }
            onClick={this.handleClick}
            disabled={element_item.active}
            data_tooltip={t("Create Digital Print")}
          />
        );
    }
}

const mapStateToProps = state => ({
    training_item: state.elements.training_item,
    create_training_item_mode: state.elements.create_resources_mode,
    element_item: state.elements.element_item,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setIdentifier,
    setCreateIdentifierMode,
    setDigitalPrint,
    setCreateDigitalPrintMode,
    setCreateResourcesMode,
    setCurrentResource
},dispatch);

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(CreateDigitalPrintButton)
);

