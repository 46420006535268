import React, {Fragment} from "react";
import ReactSVG from "react-svg";
import CloseIcon from "../../../images/close-icon.svg";
import Sidebar from "../../../components/sidebar/Sidebar";
import {
    getBidStatusModificator,
    getTimeline,
    sidebarTypes
} from "../../../common";
import Status from "../Status";
import {bidStatuses} from "./BidCard";
import {Link} from "react-router-dom";
// import {showFullSizeGallery} from "../../../store/resources";
import {Trans} from 'react-i18next';
import ErrorBoundaryContainer from "../../../components/ErrorBoundaryContainer";

function BidEditSidebar(props) {
    let $approvedStatus = bidStatuses.find(item => item.name === 'Approved').id;
    let $draftStatus = bidStatuses.find(item => item.name === 'Draft').id;
    if(!props.bid_item) return null;
    let name = props.bid_item && props.bid_item.status_id ? bidStatuses.find(status => status.id === props.bid_item.status_id).name : '';

    const onSubmitBid = () => {
        props.updateBidStatus(2,props.bid_item.id)
    }

    return(
        <ErrorBoundaryContainer>
            <Sidebar show={props.sidebar_type === sidebarTypes.view_bid}
                     {...props}
                     header={<Fragment>
                         <span className="sidebar__header-title"><Trans>View bid</Trans></span>
                         <div className="sidebar__header-buttons">
                             <button type="button" className="btn btn-close" onClick={() => props.setSidebar(sidebarTypes.none)}>
                                 <ReactSVG src={CloseIcon} />
                             </button>
                         </div>
                     </Fragment>}
                     footer={
                         <>
                             {props.bid_item && $draftStatus === props.bid_item.status_id &&
                             <button type="button" className="btn btn-primary"
                                     onClick={onSubmitBid}>Submit</button>}
                             {props.bid_item && props.bid_item.job && $approvedStatus === props.bid_item.status_id &&
                             <Link to={`bid-details-for-job-${props.bid_item.job.id}`} className="btn btn-primary"><Trans>View details</Trans></Link>
                             }
                             {props.bid_item && $approvedStatus !== props.bid_item.status_id &&
                             <Link to={`unassigned-bid-details-${props.bid_item.id}`} className="btn btn-primary"><Trans>View details</Trans></Link>
                             }
                         </>
                     }
                     wide
            >
                {props.bid_item && <div className="sidebar__inner-offset">
                    <div className="row">
                        <div className="col-6">
                            <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free"><Trans>Current status</Trans></div>
                            {props.bid_item && <Status name={name} isBig
                                                       modificator={getBidStatusModificator(props.bid_item.status_id)}/>}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free"><Trans>Languages</Trans></div>
                            {props.bid_item.required_languages && props.bid_item.required_languages.map((lng) =>
                                <span className="bid-card__header-lng" key={`lang-${lng}`}> {lng} </span>)}
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free"><Trans>Title</Trans></div>
                        <div className="sidebar__text">{props.bid_item.title}</div>
                    </div>
                    <div className="form-group mb-3">
                        <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free"><Trans>Cover letter</Trans></div>
                        <div className="sidebar__text">{props.bid_item.cover_letter}</div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-6">
                                <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free"><Trans>Proposed budget</Trans></div>
                                <div className="bid-card__header-item-content">{props.bid_item.bid_amount}</div>
                            </div>
                            <div className="col-6">
                                <div className="sidebar__title sidebar__title_bold sidebar__title_offset-free"><Trans>Proposed timeline</Trans></div>
                                <div className="bid-card__header-item-content">
                                    {getTimeline(props.bid_item.estimate_minutes)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </Sidebar>
        </ErrorBoundaryContainer>
    )
}

export default BidEditSidebar;
