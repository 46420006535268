//Custom select component using instead of default select tag
import React from 'react';
import Select,{ components } from "react-select";
import * as _ from "lodash";
import ArrowDown from "../images/arrow-down.svg";
import ReactSVG from "react-svg";
import {renderFlag, toggleUpgradePlanModal} from "../store/user";
import HelpIconTooltip from "./HelpIconTooltip";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {themes} from "../common";
import {withTranslation} from "react-i18next";

const { Option,SingleValue,DropdownIndicator } = components;

const IconOption = (props) => {
    return (
        <div onClick={(e)=> props.data.optionClick(e,props.data)}>
            <Option {...props}>
                <ReactSVG className="select-language__option-icon" src={renderFlag(props.data.value)}/>
                <span className="select-language__option-text">{props.data.label}</span>
            </Option>
        </div>
    )

};




const CustomSingleValue = ({ children, ...props }) => (
    <SingleValue {...props}>
        <ReactSVG className="select-language__option-icon" src={renderFlag(props.data.value)}/>
        <div className='select-language__value-text'>{children}</div>
    </SingleValue>
);

const CustomDropdownIndicator = ({ ...props }) => (
    <DropdownIndicator {...props}>
        <ReactSVG src={ArrowDown}/>
    </DropdownIndicator>
);



class LanguageSelect extends React.Component {

    optionClick = (e,data) => {
        if(data.isDisabled){
            this.props.toggleUpgradePlanModal(true)
        }
    }

    render() {
        let {name,disabled,setFieldValue, value, data,menuPlacement,hideHelp,dark_mode,isDark,classes,withoutLimits,t,main_content_language,noDefaultValue} = this.props;
        let theme = dark_mode ? themes['dark-mode'] : themes['light-mode']

        let optionsArray = [];
        let {user_info} = this.props;
        if(!user_info) return null;
        let {business_package} = user_info;
        let languagesOptions = business_package && !withoutLimits ? _.find(business_package.options,['name','Multilanguage']).value : [];
        data.map((item) => {
            let isDisabled = !withoutLimits ? !_.includes(languagesOptions,item.short_name) : false;
            return optionsArray.push({ value: item.id, label: t(item.name), isDisabled,optionClick: this.optionClick,short_name:item.short_name})
        })
        let currentValue = Array.isArray(value) ? value.map((item) => {
            return _.find(optionsArray, ['value', item])
        }): _.find(optionsArray, ['value', value]);

        return (
            <div className={`select-language__wrap ${classes}`}>
                <Select
                    // menuIsOpen
                    isMulti={this.props.isMulti}
                    defaultValue={!noDefaultValue ? (main_content_language ? _.find(optionsArray,['short_name',main_content_language]) : optionsArray[0]) : undefined}
                    className="select-language"
                    classNamePrefix="select-language"
                    name={name}
                    isDisabled={disabled}
                    value={currentValue}
                    onChange={option => setFieldValue(name, this.props.isMulti ? option : option.value)}
                    options={optionsArray}
                    placeholder={t('Select language')}
                    styles={{
                        option: (provided, state) => ({
                            ...provided,
                            border: 'none',
                            color: state.isSelected ? theme.text_color : theme.lang_select_text_inactive,
                            padding: 8,
                            display: 'flex',
                            alignItems: 'center'
                        }),
                        singleValue: (provided, state) => ({
                            ...provided,
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.text_color
                        }),
                        valueContainer: (provided, state) => ({
                            ...provided,
                            backgroundColor: 'transparent',
                            borderWidth: 0,
                            padding: '2px 16px',
                            display: 'flex',
                            alignItems: 'center'

                        }),
                        control: (provided, state) => ({
                            ...provided,
                            backgroundColor: isDark ? theme.lang_select_bg_dark : theme.lang_select_bg,
                            borderWidth: 0,
                            borderRadius: '25px',
                            outline: 'none',
                            minHeight: '36px',
                            boxShadow: 'none',
                            display: 'inline-flex',
                            alignItems: 'center',
                            minWidth: '200px'
                        }),
                        indicatorSeparator: (provided, state) => ({
                            ...provided,
                            display: 'none',
                        }),
                        dropdownIndicator: (provided, state) => ({
                            ...provided,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 0,
                            padding: '2px 16px',
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            width: 'auto',
                            minWidth: '200px',
                            backgroundColor: theme.lang_select_bg,
                        })
                    }}
                    menuPlacement={menuPlacement}
                    components={{
                        Option: IconOption,
                        SingleValue: CustomSingleValue,
                        DropdownIndicator: CustomDropdownIndicator
                    }}
                />
                {!hideHelp && <HelpIconTooltip help_message={t("Create resource in this particular language.")}/>}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    user_info: state.user.user_info,
    dark_mode: state.user.dark_mode,
    main_content_language: state.user.main_content_language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    toggleUpgradePlanModal
},dispatch);

export default withTranslation('translations') (connect(mapStateToProps, mapDispatchToProps)(LanguageSelect));
