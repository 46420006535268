import React from 'react';
import {Trans} from "react-i18next";

function UserPackageInfo(props) {
    let {user_info,toggleUpgradePlanModal} = props;
    let pacakge_name = user_info.business_package.name;
    if(!user_info) return null;

    return (
      <div className="profile-section profile-section__accent overflow-hidden">
        <div className="profile-section__title-bold">
          <Trans>{user_info.business_package.name}</Trans>{" "}
          <Trans>package</Trans>
        </div>
        <div className="profile-section__body">
          <div className="profile-section__small-text">
            <Trans>There is your current package</Trans> : {pacakge_name} <br />
            {/*You can upgrade to Museum or Custom*/}
          </div>
          <button
            className="btn btn-primary w-auto"
            onClick={() => toggleUpgradePlanModal(true)}
          >
            <Trans>Upgrade</Trans>
          </button>
        </div>
      </div>
    );
}

export default UserPackageInfo;